import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Filter } from '../shared/filter'
import * as query from './survey.query'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { User } from '../user/user.model'
import { Survey } from './survey.model'

@Injectable()
export class SurveyService {
  constructor(private apollo: Apollo) {}

  saveAnswers(answers, managerEmail, currentDay,surveyId?) {
    // this.answersFromOptions(answers)
    let answerData;
    if (answers && answers?._data) {
      answers._data.surveyId = surveyId;
      answerData = JSON.stringify(answers._data);
    } else {
      answerData = JSON.stringify(answers);
    }
    return this.apollo
      .mutate({
        mutation: query.answerSurvey,
        variables: {
          answersJson: answerData,
          managerEmail: managerEmail,
          currentDay: currentDay,
        },
      })
      .pipe(
        map((result) => {
          console.log("result", result);
          return result;
        })
      );
  }

  answersFromOptions(options) {
    for (let key in options) {
      options[key] = {
        id: options[key]["id"],
        text: options[key]["text"],
      };
    }
  }
}
