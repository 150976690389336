import { Component, Input, Output, EventEmitter, OnInit, OnChanges, ViewChild, ElementRef } from '@angular/core'
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload'
import { environment } from '../../../environments/environment'

const DEFAULT_URL = environment.apiBaseUrl + '/files'
const DEFAULT_MAX_FILE_SIZE = 50 * 1024 * 1024 // 2 MB
// Not used
const DEFAULT_ALLOWED_MIME_TYPES = [
  'image/png',
  'image/gif',
  'image/jpeg',
]

class UploadConfig {
  url?: string = DEFAULT_URL
  maxFileSize?: number = DEFAULT_MAX_FILE_SIZE
  allowedMimeTypes?: string[] = DEFAULT_ALLOWED_MIME_TYPES
}

@Component({
  selector: 'admin-attachment-uploader',
  templateUrl: './attachment-uploader.component.html',
  styleUrls: ['./attachment-uploader.component.scss']
})
export class AttachmentUploaderComponent implements OnInit {
  @Input() config: UploadConfig = new UploadConfig()
  @Input() icon: string = 'glyphicon-paperclip'
  @Input() fileUrl: string
  @Output() fileUrlChange = new EventEmitter<string>()


  public uploaded: Boolean = false
  public loading: Boolean = false
  public uploader: FileUploader

  constructor() { }

  ngOnInit() {
    this.uploader = new FileUploader({
      url: this.config.url,
      //allowedMimeType: this.config.allowedMimeTypes,
      maxFileSize: this.config.maxFileSize,
      autoUpload: true,
      authTokenHeader: "Authorization",
      authToken: `${localStorage.getItem('token')}`,
    })
    this.uploader.onBeforeUploadItem = (fileItem: FileItem) => {
      this.loading = true
      this.icon = 'glyphicon-refresh'
    }
    this.uploader.onCompleteItem = (item: FileItem, response: any, status: any, headers: any) => {
      response = JSON.parse(response)
      this.fileUrlChange.emit(response['data']['url'])
      this.loading = false
      this.uploaded = true
      this.icon = 'glyphicon-ok'
    }
    //this.uploader.onSuccessItem = (item:FileItem, response:string, status:number, headers:ParsedResponseHeaders) => {}
    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      console.log(item, response, headers)
      //let error = JSON.parse(response); //error server response
    }
  }

  reset() {
    this.loading = false
    this.uploaded = false
    this.icon = 'glyphicon-paperclip'
  }
}
