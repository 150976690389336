import gql from 'graphql-tag'

export const inoutScreenFields = gql`
fragment inoutScreenFields on InoutScreen {
    id
    startDay
    endDay
    preScreens
    postScreens
    homepageTitle
    homepageSubtext
    createdAt
    updatedAt
    enabled
    stime
    etime
    emailTeaser
    libraryImage
    tag
}
`
