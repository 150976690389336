import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController, PopoverController } from '@ionic/angular';
import { Apollo } from 'apollo-angular';
import { AppState } from '../app.service';
import { Client } from '../client/client.model';
import { UserPublicProfileComponent } from '../components/user-public-profile/user-public-profile.component';
import { CordovaService } from '../cordova.service';
import { MobileChatServiceService } from '../pages/mobile-chat-board/mobile-chat-service.service';
import { Reply } from '../reply/reply.model';
import { ReplyService } from '../reply/reply.service';
import { SessionService } from '../session/session.service';
import { ToasterService } from '../toaster.service';
import { User } from '../user/user.model';
import { HomeUiService } from '../new-design/home-ui.service';
import { I18n } from '../i18n.service';

@Component({
  selector: 'instep-replies',
  templateUrl: './instep-replies.component.html',
  styleUrls: ['./instep-replies.component.scss'],
})
export class InstepRepliesComponent implements OnInit {
  @ViewChild('disableListener') disableListener: ElementRef
  @Input() index: number
  @Input() reply: Reply
  @Input() replyToId: number
  @Input() repliesEnabled: boolean
  @Input() inverted = false
  @Input() isChatboardReply = false
  @Input() isShowLikes = false
  @Input() voteCounts: number
  @Input() postId: number
  showMenu = false;

  @Output() replyTo = new EventEmitter<InstepRepliesComponent>()

  user: User
  client: Client
  selected: boolean = false
  voteLoading: boolean = false
  state: boolean = false;
  animation=['bounceIn'];

  isMobile: boolean

  constructor(
    private appState: AppState,
    private replyService: ReplyService,
    private cdRef: ChangeDetectorRef,
    public sessionService: SessionService,
    private cordovaService: CordovaService,
    private mobileService:MobileChatServiceService,
    private toasterService:ToasterService,
    private i18n: I18n,
    private apollo : Apollo,
    public modalCtrl: ModalController,
    public popoverController: PopoverController,
    public homeUIService: HomeUiService,
    private sanitizer:DomSanitizer
  ) {
    this.user = this.appState.get('currentUser')
    this.client = this.appState.get('currentClient')
    this.isMobile = this.appState.get('isMobile')
    
  }

  async openuserInfo(ev, userData): Promise<void> {
    const popover = await this.popoverController.create({
      component: UserPublicProfileComponent,
      event: ev,
      mode:'ios',
      translucent: false,
      componentProps: {'user': userData}
    });
    await popover.present();
   
  }
  transform(html) {
    return this.sanitizer.bypassSecurityTrustStyle(html);
  }

  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  ngOnInit() {
    if(this.isChatboardReply){
      this.select()
    }else{
      this.selected = this.reply.id === this.replyToId
    }
    this.cdRef.detectChanges()
  }

  toggleVote() {
    this.bounceAnimation()
    if (this.reply.user.id === this.user.id) {
      return false
    }
    this.voteLoading = true
    this.reply.hasVoted = !this.reply.hasVoted
    this.reply.votesCount += this.reply.hasVoted ? 1 : -1
    this.cdRef.detectChanges()
    this.replyService.voteReply(this.reply,'').subscribe(
      status => {
        this.voteLoading = false
        this.cdRef.detectChanges()
      },
      error => {},
      () => {
        this.voteLoading = false
        this.cdRef.detectChanges()
      }
    )
  }

  select() {
    this.selected = true
    this.replyTo.emit(this)
    this.cdRef.detectChanges()
  }

  infoComment(){
    this.selected = true
    this.replyTo.emit(this)  }

  unselect() {
    this.selected = false
    this.replyTo.emit(null)
    this.cdRef.detectChanges()
  }

  public getIcon( fileName ) {
    const extension = fileName.split( '.' ).pop()
    let icon = ''

    switch ( extension ) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        icon = 'glyphicon-picture'
        break
      case 'mp4':
      case '3gp':
      case 'mpg':
      case 'mpeg':
      case 'avi':
      case 'flv':
        icon = 'glyphicon-film'
        break
      case 'mp3':
        icon = 'glyphicon-headphones'
        break
      case '7z':
      case 'rar':
      case 'zip':
        icon = 'glyphicon-folder-open'
        break
      default:
        icon = 'glyphicon-file'
    }

    return icon
  }

  public open(url) {
    window.open(url)
  }


  @HostListener('click', ['$event'])
  onLinkClick(e) {
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        window.open(e.target.href, '_blank')
      }
    }
  }

  bounceAnimation(){
    this.state = false;
    setTimeout(() => {
      this.state = true;
    }, 1);
  }

  deletePost(id){

    this.mobileService.deletePost(id).subscribe(
      response => {
          console.log(response);

          var test = this.appState.get('currentPath')
          this.toasterService.success(this.i18n.t('Comment deleted successfully'))
          this.appState.set('refreshCommentList', true)
          this.cdRef.detectChanges()
          
      },
      error =>{
        console.log(error);
      }

    )

  }


  copyMessage(val: string,comment_id: number){

    try {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.toasterService.success(this.i18n.t("Copied to clipboard!"));
      this.deletePost(comment_id)
    }catch{
      this.toasterService.warning(this.i18n.t("Not able to copy and delete!"));
    }
    
  }

  refresh() {
    return new Promise(resolve => {
      // here to fetch the data and rerender the contents.
      this.appState.refreshMainComponent.emit()
      setTimeout(resolve, 0)
      
    })
  }

  async close() {
    const closeModal: string = "Modal Closed";
    await this.modalCtrl.dismiss(closeModal);
  }
@HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.disableListener?.nativeElement.contains(event.target)) {
      this.showMenu = !this.showMenu;
    }
    else {
      this.showMenu =false;
    }
  }
}
