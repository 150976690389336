import gql from 'graphql-tag'

export const rewardFields = gql`
fragment rewardFields on Reward {
  id
  name
  description
  imageUrl
  actionPoints
  enabled
}
`

export const updateReward = gql`
mutation updateReward($reward: RewardInput!) {
  updateReward(rewardInput: $reward) {
    ...rewardFields
  }
}
${rewardFields}
`

export const findReward = gql`
query findReward($id: Int!) {
  reward(id: $id) {
    ...rewardFields
  }
}
${rewardFields}
`

export const listRewards = gql`
query listRewards($filter: FilterInput) {
  rewards(filter: $filter) {
    ...rewardFields
    createdAt
  }
}
${rewardFields}
`

export const listPathRewards = gql`
query listPathRewards($filter: FilterInput) {
  pathRewards(filter: $filter) {
    id
    pathId
    starts
    expires
    enabled
    reward {
      ...rewardFields
    }
    createdAt
  }
}
${rewardFields}
`
