import { Base } from "../shared/base"

export class ChecklistItem extends Base {
  init() {
    this._fields = ['id', 'checklistId', 'text', 'actionPoints', 'isCompleted', 'enabled']
  }

  public id: number  
  public checklistId: number
  public text: string  
  public actionPoints: number  
  public isCompleted: boolean
  public enabled: boolean  
}