import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AppState } from '../app.service';
import { UserPublicProfileComponent } from '../components/user-public-profile/user-public-profile.component';
import { Path } from '../path/path.model';
import { SpinnerService } from '../spinner.service';
import { StatService } from '../stat/stat.service';
import { User } from '../user/user.model';
import { WalletService } from '../wallet/wallet.service';
import { HomeUiService } from '../new-design/home-ui.service';

@Component({
  selector: 'leaderboard-card',
  templateUrl: './leaderboard-card.component.html',
  styleUrls: ['./leaderboard-card.component.scss'],
})
export class LeaderboardCardComponent implements OnInit {
  // @Input() stats: any
  @Input() leaderboardPosition: any
  @Input() isThisPath: boolean
  @Input() isThisWeek: boolean
  @Input() statByWeek: any
  @Input() isAllTime: boolean

  currentUser: User
  path: Path
  isMobile: boolean
  @Input() stats: any

  @Input() thisPathUser: any
  thisPathUserRank: any
  thisWeekUser: any
  thisWeekUserRank: any
  @Input() allTimeUser: any
  allTImeUserRank: any

  constructor(
    private appState: AppState,
    private statService: StatService,
    private spinnerService: SpinnerService,
    public walletService: WalletService,
    private popoverController: PopoverController,
    public homeUiService: HomeUiService
  ) {
    this.currentUser = appState.get('currentUser')
    this.path = this.appState.get('currentPath')
    this.isMobile = this.appState.get('isMobile')
   // this.getStats(this.path.id)
  }

  ngOnInit() {
     }

  get position() {
    if (!this.leaderboardPosition) { return }
    return this.leaderboardPosition.replace(/[^\d]/g, '')
  }

  /**
   * From Shopify:
   *
   * https://stackoverflow.com/a/31615643
   */
  private ordinal(n) {
    const s = ['th', 'st', 'nd', 'rd'], v = n % 100
    return (s[(v - 20) % 10] || s[v] || s[0])
  }
  async openUserInfo(ev, userData): Promise<void> {
    const popover = await this.popoverController.create({
      component: UserPublicProfileComponent,
      event: ev,
      mode:'ios',
      translucent: false,
      componentProps: {'user': userData}
    });
    await popover.present();
   
  }
  getStats(pathId){
    this.statService.getList(pathId)
    .then(response => {
      this.stats = response
      this.getStatsByWeek(pathId,this.stats)
    })
    .catch(error => {
      this.stats = error
    })
  }

  getStatsByWeek(pathId:number, stats:any=null){    
    const requestData = {
      'path_id': pathId
    }
    if(!requestData){
    }else{
      this.statService.getListStatsByWeek(requestData).subscribe(
        (response) => {
          this.statByWeek = response.data
          this.getCurrentUser(stats)
        },
        (error) => {
          console.log('error for stats by week', error);
        }
      )
    }
  }

  getCurrentUser(stats: any){
    try {
      this.thisPathUser = stats.leaderboard.current_path_user
      this.allTimeUser = stats.leaderboard.users
    } catch (error) {
      console.log('error catch', error); 
    }  
  }

}
