export const names = {
  'header_journal': 'Journal',
  'header_documents': 'Documents',
  'header_chat_board': 'Social',
  'header_stats': 'Dashboard',
  'header_achievements': 'Achievements',
  'header_profile': 'Profile',
  'header_settings': 'Settings',
  'header_action_points': 'Action Points',
  'h_tc': 'Today’s content',
  'h_ts': 'Complete all available content to unlock the next step at midnight.',
  'h_sc': 'Snoozed challenges',
  'h_ss': 'Below are the challenges you still need to complete. Tap on challenge once completed.',
  'qc_sc': 'Questions',
  'sc_sc': 'Score',
  'ssc_sc': 'Survey Score',

  // LUMBA-1320
  'j_s':  'Share a learning or event that occurred, to help you ' +
    'turn it over in your noggin and take the most from it',

  // LUMBA-1302
  'h_ds_d': 'Watch / read',
  'h_ds_q': 'Share your thoughts',
  'h_ds_p': 'Scenario / poll',
  'h_ds_s': 'Survey',
  'h_ds_h': 'Today\'s challenge',
  'p_tq': 'Today’s question',
  's_ts': 'Today’s survey',
  'h_syt': 'Share your thoughts',
  'h_syt_st': 'These are questions we’ve asked you up to this point, ' +
    'please continue to evolve your perspective and share your thoughts with your peers.',
  'h_tai': 'Team Action Item',
  'h_dp': 'Daily Participation',
  'h_p': 'Progress',
  'h_p_st': 'Complete your few actions for the day. It should only take you 8-10 mins. ' +
    'Staying committed everyday helps to keep your momentum.',
  'd_gi': 'Get Inspired',
  'h_awp': 'Take action',
  'h_awp_st': 'Every day, think about doing one or all of these actions. \n Each day, mark the ones you have completed.',
  'h_tdl': 'To Do List',
  'h_tdl_st': 'Below is a checklist every manager should complete \n as a part of this year’s All Employee Survey work.',
  'p_hypa': 'How Your Peers Answered',
  'q_cb': 'Chat Board',
  'q_cb_st': 'Post ideas, learnings or begin a discussion with your peers. \n ' +
    'You can also share articles, links, videos that help others along the path.',
  'q_nqt': 'There is no question for today',  // LUMBA-1306
  'd_wywa': 'While You Were Away',
  'd_gd': 'Go Deeper',
  'd_r': 'Resources',
  'd_m': 'More',
  's_s': 'Stats',
  's_l': 'Leaderboard',
  's_a': 'Activities',
  's_a_st_1': 'Total small steps taken along the path',
  's_a_st_2': 'Each bar represents a small step - any action \n ( social-post, poll, action with purpose etc. )',
  's_pq': 'Daily question',
  's_awp': 'Building habits',
  's_sp': 'Share your thoughts',
  's_r': 'Daily reading',
  's_c': 'Apply SYS Learnings',
  's_cs': 'Content surveys',
  's_cs_st': 'Content surveys subtitle',
  's_sp_st': 'Share your learning and insights',
  's_r_st': 'Get Inspired',
  's_awp_st': 'Learning through experience',
  's_yd': 'Your Deliverables',
  's_yd_st': 'As you check off your deliverables, you will fill in the bar chart. \n ' +
    'Your goal is to complete all the deliverables by the end of the path.',
  's_t': 'Total:',
  's_g': 'Goal:',
  's_tn': 'Topn',
  'b_b': 'Badges',
  'c_b_title': 'Special awards',
  'b_subtext': 'These are all of the badges it’s possible to earn as part of your participation. See how many you can get!',
  'b_small_steps': 'Take Action',
  'b_question_answers': 'Sharing With The Community',
  'b_small_steps_perfect_weeks': 'Perfect weeks',
  'r_r': 'Rewards',
  'st_s': 'Settings',
  'st_su': 'Sign up'
}
