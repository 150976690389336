import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-wrong-code',
  templateUrl: './alert-wrong-code.component.html',
  styleUrls: ['./alert-wrong-code.component.scss'],
})
export class AlertWrongCodeComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
