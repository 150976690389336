import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { HomeUiService } from '../new-design/home-ui.service';

@Component({
  selector: 'app-dynamic-circle',
  templateUrl: './dynamic-circle.component.html',
  styleUrls: ['./dynamic-circle.component.scss'],
})
export class DynamicCircleComponent implements OnInit {
  @Input() percentage: number;
  @Input() parentComponentName;
  @Input() isLightStroke = false;
  constructor(
    public homeUIService: HomeUiService,
    private cdRef: ChangeDetectorRef,
  ) { 
    
  }

  ngOnInit() {
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  getCircumference(): number {
    return 2 * Math.PI * 45; // Circumference formula: 2 * π * radius
  }

  getStrokeOffset(): number {
    const circumference = this.getCircumference();
    return circumference - (circumference * this.percentage) / 100;
  }
}
