import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, PickerController } from '@ionic/angular';
import { any } from 'underscore';

import { AppState } from '../app.service';
import { CordovaService } from '../cordova.service';
import { I18n } from '../i18n.service';
import { Path } from '../path/path.model';
import { SessionService } from '../session/session.service';
import { TimePickerComponent } from '../time-picker/time-picker.component';
import { ToasterService } from '../toaster.service';
import { PathRegistration } from '../user/path-registration.model';
import { User } from '../user/user.model';
import { UserService } from '../user/user.service';
import { HomeUiService } from '../new-design/home-ui.service';
import { NotificationsSettingsComponent } from '../pages/notifications-settings/notifications-settings.component';
import { UpdatePathRegistration } from '../common-services/utility.service';



declare var FCMPlugin: any
const defaultColumnOptions = [
  [
    "00:00", "00:30", "01:00","01:30",
    "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00",
    "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30",
    "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00",
    "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", 
    "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30", "24:00"
  ]
]

@Component({
  selector: 'day-part-select',
  templateUrl: './day-part-select.component.html',
  styleUrls: ['./day-part-select.component.scss'],
})
export class DayPartSelectComponent implements OnInit {
  currIndex : number = 0;

  @ViewChild("timePicker") timePicker: TimePickerComponent;
  selectedTime: any

  confirmedTime: string;
  selectedTimeOption: string = null;
  morningTime: boolean;
  lunchTime: boolean;
  atWorkTime: boolean;
  homeTime: boolean;
  bedTime: boolean
  isTimePickerCard: boolean;
  isTimeSelectCard: boolean;

  pushNotificationsEnabled: boolean
  currentPath: Path
  currentUser: User
  loading: boolean = false
  itemPublish: number = 22 ;
  // afterWakingUpTime: any = new Date().toISOString();
  customPickerOptions: { buttons: { text: string; handler }[]; };
  dailyRoutine = [{ title: this.i18n.t('After waking up'), time: null },
  { title: this.i18n.t('When starting work'), time: null },
  { title: this.i18n.t('Lunch time'), time: null },
  { title: this.i18n.t('When finishing work'), time: null },
  { title: this.i18n.t('Before bed'), time: null }];
  afterWakingUpTime : any = "07:00";
  defaultTime : String;
  isMobile: boolean
  pathRegistration
  public updatePathRegistration: UpdatePathRegistration;

  constructor(private cordovaService: CordovaService,
    private appState: AppState,
    private router: Router,
    private modalController: ModalController,
    private userService: UserService,
    private homeUIService: HomeUiService,
    private toastrService: ToasterService,
    public i18n: I18n,
    private pickerController: PickerController) { 
      this.currentUser = this.appState.get('currentUser')
      this.currentPath = this.appState.get('currentPath')
    this.isMobile = this.appState.get('isMobile')
    this.pathRegistration = this.appState.get('pathRegistration')
      if (localStorage.getItem('notificationsEnabled')) {
        this.pushNotificationsEnabled = true
      }
      this.afterWakingUpTime=localStorage.getItem('learningTime')
      if(localStorage.getItem('currIndex')!=null){
        console.log(parseInt(localStorage.getItem('currIndex')))
        this.dailyRoutine[parseInt(localStorage.getItem('currIndex'))].time = this.afterWakingUpTime
      }
    this.isTimePickerCard = false;
    this.isTimeSelectCard = true;

    this.customPickerOptions = {
      buttons: [
          {
              text: 'Cancel',
              handler: (time) => {
                   return true;
              }
          },
          {
              text: 'Confirm',
              handler: (time)=> {
                console.log('time', time);
                this.afterWakingUp(time)
              }
          }
      ]
  }

  }

 ngOnInit() {
  console.log("dailyRute", this.dailyRoutine);
 }
 
 test(index)
{
  console.log("dailyRute in test", this.dailyRoutine);
  console.log(index)
  switch (index) {
    case 0:
      this.defaultTime = "07:00";
      break;
    case 1:
      this.defaultTime = "09:00";
      break;
    case 2:
      this.defaultTime = "12:00";
      break;
    case 3:
      this.defaultTime = "17:00";
      break;
    case 4:
      this.defaultTime = "22:00";
      break;
    default:
      this.defaultTime = "22:00";
  }
  // if(localStorage.getItem('learningTime')!=null && this.currIndex==index)
  // this.afterWakingUpTime=localStorage.getItem('learningTime')
  this.currIndex=index;
}
  afterWakingUp(time) {
   console.log(time.hour.text + ':' + time.minute.text + " " + time.ampm.text);
let time12h = time.hour.text + ':' + time.minute.text + " " + time.ampm.text;
const [tim, modifier] = time12h.split(' ');
let [hours, minutes] = tim.split(':');
if (hours === '12') {
    hours = '00';
  }
if (modifier === 'PM') {
    let i = parseInt(hours,10) + 12;
    hours=i.toString();
  }
    this.selectedTime = hours + ':' + minutes
    console.log("this.selectedTime",this.selectedTime);
    for (let index = 0; index < this.dailyRoutine.length; index++) {
      if(index==this.currIndex){
        this.dailyRoutine[this.currIndex].time= this.selectedTime
        localStorage.setItem("currIndex",index+"")
        console.log("ss",this.dailyRoutine[this.currIndex].time)
      }else{
        this.dailyRoutine[index].time=null
      }
    }
    this.afterWakingUpTime=this.selectedTime
    this.confirmTime()
 }

  selectTime(option) {
    if (this.confirmedTime) {
      return;
    }
    let time = "";
    switch (option) {
      case "AFTER_WAKING_UP":
        time = "07:00";
        this.morningTime = true;
        this.atWorkTime = false;
        this.lunchTime = false;
        this.homeTime = false;
        this.bedTime = false;
        this.itemPublish=14;
        break;
      case "AFTER_ARRIVING_AT_WORK":
        time = "09:00";
        this.atWorkTime = true;
        this.morningTime = false;
        this.lunchTime = false;
        this.homeTime = false;
        this.bedTime = false;
        this.itemPublish=18;
        break;
      case "AFTER_LUNCH":
        time = "12:00";
        this.lunchTime = true;
        this.atWorkTime = false;
        this.morningTime = false;
        this.homeTime = false;
        this.bedTime = false;
        this.itemPublish=24;
        break;
      case "AFTER_ARRIVING_HOME":
        time = "17:00";
        this.atWorkTime = false;
        this.morningTime = false;
        this.lunchTime = false;
        this.homeTime = true;
        this.bedTime = false;
        this.itemPublish=34;
        break;
      case "BEFORE_BED":
        time = "22:00";
        this.atWorkTime = false;
        this.morningTime = false;
        this.lunchTime = false;
        this.homeTime = false;
        this.bedTime = true;
        this.itemPublish=44;
        break;
      default:
        this.itemPublish=22;
    }
    console.log(option, time);
    this.openPicker()
    this.selectedTimeOption = option;
 
  }

  async openPicker(numColumns = 1, numOptions = 49, columnOptions = defaultColumnOptions) {
    let defaultHour = parseInt(this.defaultTime.split(':')[0]);
    const defaultMinute = parseInt(this.defaultTime.split(':')[1].split(' ')[0]);
    let defaultAmPm = this.defaultTime.split(' ')[1];
    // Convert 24-hour format to 12-hour format
    if (defaultHour >= 12) {
      defaultHour -= 12;
      defaultAmPm = 'PM';
    }
    // Handle 12:00 PM case to display as 12:00 PM instead of 0:00 PM
    if (defaultHour === 0) {
      defaultHour = 12;
    }
    const picker = await this.pickerController.create({
      // columns: this.getColumns(numColumns, numOptions, columnOptions),
      columns: [
        {
          name: 'hour',
          options: this.generateHourOptions(), // For hours in 12-hour format
          selectedIndex: defaultHour - 1,       
        },
        {
          name: 'minute',
          options: this.generateColumnOptions(), // For minutes
          selectedIndex: defaultMinute === 0 ? 0 : 1,
        },
        {
          name: 'ampm',
          options: [
            { text: 'AM', value: 'AM' },
            { text: 'PM', value: 'PM' },
          ],
          selectedIndex: defaultAmPm === 'AM' ? 0 : 1,
        },
      ],
      buttons: [
        {
          text: this.i18n.t('Cancel'),
          role: 'cancel'
        },
        {
          text: this.i18n.t('Confirm'),
          handler: (value) => {
            this.afterWakingUp(value)
   }
        }
      ]
    });

    await picker.present();
  }
  generateHourOptions() {
    const options = [];
    for (let i = 1; i <= 12; i++) {
      options.push({
        text: i.toString(),
        value: i.toString().padStart(2, '0'),
      });
    }
    return options;
  }
  generateColumnOptions() {
    const options = [
      { text: '00', value: '00' },
      { text: '30', value: '30' },
    ];
    return options;
  }
  getColumns(numColumns, numOptions, columnOptions) {
    let columns = [];
    for (let i = 0; i < numColumns; i++) {
       columns.push({
        name: `time`,
        options: this.getColumnOptions(i, numOptions, columnOptions),
        selectedIndex: this.itemPublish
      });
    }

    return columns;
  }
  prevScreen() {
  
  this.modalController.dismiss({
    selectedTime: this.confirmedTime
  });

  }
  getColumnOptions(columnIndex, numOptions, columnOptions) {
    let options = [];
    for (let i = 0; i < numOptions; i++) {
      options.push({
        text: columnOptions[columnIndex][i % numOptions],
        value: i
            })
    }

    return options;
  }

  confirmTime() {
    this.confirmedTime = this.selectedTime
    console.log("confirm time ",this.confirmedTime);
    localStorage.setItem('learningTime', this.confirmedTime)
    this.save()
  }
  unconfirmTime() {
    const previousTime = this.confirmedTime;
    this.confirmedTime = null;
    // window.requestAnimationFrame(() => {
    //   this.timePicker.setActiveTimeByTime(previousTime);
    // });
  }

  toggleTimePicker() {
    if (this.isTimePickerCard) {
      this.isTimePickerCard = false;
      this.isTimeSelectCard = true;
    } else {
      this.isTimePickerCard = true;
      this.isTimeSelectCard = false;
    }
  }

  save() {
    if (localStorage.getItem('notificationsEnabled')) {
      this.pushNotificationsEnabled = true
    }
     let deviceId;
     if (
       this.isMobile &&
       (localStorage.getItem("deviceToken") !== null ||
         localStorage.getItem("deviceToken") != undefined)
     ) {
       deviceId = localStorage.getItem("deviceToken");
     } else {
       deviceId = this.pathRegistration.pathData.deviceRegistrationId;
     }
    // TODO: Add Cordova push API
    const pathRegistration = new PathRegistration({
      userId: this.currentUser.id,
      pathId: this.currentPath.id,
      pathData: {
        loginReminderEnabled: true,
        loginReminderTime: this.confirmedTime,
        deviceRegistrationId: deviceId,
        emailReminderEnabled: true,
        pushReminderEnabled: true,
        socialReminderEnabled: true,
        socialEmailEnabled: true,
      },
    });

    if (this.cordovaService.isIOS() && typeof FCMPlugin !== "undefined") {
      FCMPlugin.requestPermissionOnIOS();
    }

    this.userService.savePathRegistration(pathRegistration).subscribe(
      (response) => {
        console.log("Server push Notifications", response);
         this.updatePathRegistration = response.data;
         this.appState.set(
           "pathRegistration",
           this.updatePathRegistration.updatePathRegistration
         );
        this.prevScreen()

      },
      (error) => {
        console.log("Server push Notifi error", error);
      },
      () => {

      }
    );
  }

  close(){
    this.modalController.dismiss();
  }
  
}
