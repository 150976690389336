import { Reply } from '../reply/reply.model'
import { RoutineAnswerReply } from '../reply/routine-answer-reply.model'
import { Base } from '../shared/base'
import { RoutineLevel } from './routine-level.model'

export interface RoutineAnswer {
  routineInfoId: number
  routineType: string
  day: string[]
  typeValue: string
  typeLevel: string
  isUnlocked: boolean
  isMandatory: boolean
  routineTime: string
  activityTime: string
  commitCount: number
  routineDescription: string
  routineDate: string[]
}

export class RoutineInfo extends Base {
  public id: number
  public path_id: number
  public title: string

  public isMandatory: boolean
  public actionPoints: number
  public startDay: number
  public endDay: number

  public enabled: boolean
  public maxLength: number
  public screenOrder: number
  public draft: boolean

  public isCompleted: boolean
  public votesCounts: number
  public levels: RoutineLevel[]
  public routine_info_id: string
  public routineAnswer : RoutineAnswer[]
  public replies: Reply[]
  public repliesTree: Reply[]
  public repliesIdsMap: Map<number, number>
  public user: any
  public routineAnswerList: RoutineAnswerReply[]
  

  init() {
    this._fields = [
      'id',
      'title',
      'startDay',
      'endDay',
      'enabled',
      'screenOrder',
      'levels',
      'actionPoints',
      'isMandatory',
      'routineAnswer',
      'replies',
      'votesCounts',
      'user',
      'routineAnswerList',
      'isCompleted'
      
    ]
    this.screenOrder =  0

    this._nestedFields = {
      levels: RoutineLevel
    }
    this.levels = []

    this._nestedFields = {
      replies: Reply
    }
    this.replies = []
    this.repliesTree = []
    this.repliesTree = []

  }

  afterInit() {
    this.repliesIdsMap = new Map<number, number>()
    this.repliesTree = []
    this.replies.forEach(reply => this.insertReply(reply)) 
    if (this.routineAnswer) {
      this.isCompleted = true 
    }  
    
  }


  insertReply(reply: Reply, onTop = false) {
    if (reply.repliableId) {
      let index = this.repliesIdsMap.get(reply.repliableId)
      if (this.repliesTree[index] && this.repliesTree[index].replies) {
        this.repliesTree[index].replies.unshift(reply)
      }
    } else {
      if (onTop) {
        this.repliesTree.unshift(reply)
        this.repliesIdsMap.forEach((value, key, map) => { map.set(key, value + 1) })
        this.repliesIdsMap.set(reply.id, 0)
      } else {
        const index = this.repliesTree.length
        this.repliesTree.push(reply)
        this.repliesIdsMap.set(reply.id, index)
      }
    }
  }

  replaceReply(id: number, reply: Reply) {
    if (reply.repliableId) {
      let index = this.repliesIdsMap.get(reply.repliableId)
      let j = this.repliesTree[index].replies.findIndex(el => { return el.id === id })
      this.repliesTree[index].replies[j] = reply
    } else {
      let index = this.repliesIdsMap.get(id)
      this.repliesTree[index] = reply
      this.repliesIdsMap.set(reply.id, index)
    }
  }

  sortRepliesBy(key = 'timestamp') {
    // Universal sort number or date
    this.repliesIdsMap = new Map<number, number>()
    this.repliesTree.sort((a, b) => a[key] > b[key] ? -1 : a[key] < b[key] ? 1 : 0)
    this.repliesTree.forEach((reply, index) => { this.repliesIdsMap.set(reply.id, index) })
  }

}
