import { Component, HostListener, Input } from '@angular/core'
import { ModalController, Platform } from '@ionic/angular';
import { AppState } from '../app.service';
import { CordovaService } from "../cordova.service";
import { Habit } from '../habit/habit.model'
import { HabitService } from '../habit/habit.service'
import { Path } from '../path/path.model';
import { AnalyticsServiceService } from '../services/analytics-service.service';
import { SpinnerService } from '../spinner.service';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { environment } from 'src/environments/environment';
import mixpanel from 'mixpanel-browser';
import { HomeUiService } from '../new-design/home-ui.service';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
@Component({
  selector: 'first90-habit-v2',
  templateUrl: './habit-v2.component.html',
  styleUrls: ['./habit-v2.component.scss']
})
export class HabitV2Component {
  @Input() habit: Habit
  @Input() isPreview: boolean
  @Input() isYesterday = false
  @Input() inverted = false

  loading: boolean
  path: Path;
  isMobile: any;
  presignedUrl: any
  pdfSrc: any;

  constructor(
    private socialSharing: SocialSharing,
    public modalCtrl: ModalController,
    private habitService: HabitService,
    private spinnerService: SpinnerService,
    private cordovaService: CordovaService,
    private appState: AppState,
    private analyticsService: AnalyticsServiceService,
    public platform: Platform,
    public homeUIService: HomeUiService,
    private transfer: FileTransfer, 
    private file: File,  ) {
    this.path = this.appState.get('currentPath')
    this.analyticsService.setScreen(AnalyticsServiceService.HABIT)
    this.presignedUrl = environment.preSignedUrl
  }

  toggle() {
    if (this.habit.isCompleted) {
      return false
    }
    if (this.isPreview) {
      return false
    }
    this.loading = true
    this.habitService.toggleHabit(this.habit.id).subscribe(
      response => {
      },
      error => {
      },
      () => {
        this.loading = false
      })
  }

  scheduleLater(habit) {
    if (habit.scheduledLater) {
      return false
    }
    this.loading = true
    this.habitService.scheduleLater(habit.id).subscribe(
      response => {
      },
      error => {
      },
      () => {
        this.loading = false
      })
  }

  confirm(habit) {
    this.loading = true
    this.habitService.confirm(habit.id).subscribe(
      response => {
      },
      error => {
      },
      () => {
        this.loading = false
      })
  }

  share(event,habit) {
    mixpanel.track('In Steps-Export button clicked', {'id': habit.id, 'startDay': habit.startDay, 'title': habit.title, 'text': habit.text
  })
    var arrayOfUrls = []
    var div = document.createElement("DIV");
    div.innerHTML = habit.text;
    let result = div.textContent || div.innerText || ''  
    let cleanText = div.innerText;

    var uri_pattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/ig;
    arrayOfUrls = habit.text.match(uri_pattern);
    let urlSort = "";

    try {
      arrayOfUrls.forEach(element => {
        urlSort = urlSort + element + "\n\n";
      });
    } catch (error) {}

    event.stopPropagation()
    var urlDiv = document.createElement("DIV");
    urlDiv.innerHTML = result+`\n\n`+arrayOfUrls
    let habitBody = urlDiv.textContent || urlDiv.innerText || ''

    var habitDataDiv = document.createElement("DIV");
    habitDataDiv.innerHTML = habitBody
    let habitData = habitDataDiv.textContent || habitDataDiv.innerText || ''
    this.isMobile = this.appState.get("isMobile");
    
    if (this.isMobile) {
      if (arrayOfUrls) {
        try {
          this.downloadFile(result)
        } catch (error) {
          console.log('error :', error);
        }
      }
    }
    else {
      if (arrayOfUrls) {
        this.openMailTo(this.path.userGroup.name + ': ' + habit.title, result + `    ` + arrayOfUrls);  //habitData
      } else {
        this.openMailTo(this.path.userGroup.name + ': ' + habit.title, result)
      }
    }
    
  }

  downloadFile(url) {
    if (url.includes(".pdf")) {
      const transfer = this.transfer.create();
      let path = null;
      const name = url.substr(url.lastIndexOf("/") + 1);
      const fileName = name.split("?")[0];
      if (this.platform.is("ios")) {
        path = this.file.documentsDirectory;
      } else {
        path = this.file.dataDirectory;
      }
      transfer.download(url, this.file.dataDirectory + fileName).then((entry) => {
        this.pdfSrc = entry.toURL()
        console.log('this.pdfSrc :', this.pdfSrc);
        if (this.pdfSrc) {
          this.socialSharing.share("", "", this.pdfSrc, "")
            .then((entries) => {
              console.log('success ' + JSON.stringify(entries));
            })
            .catch((error) => {
              console.log('error ' + JSON.stringify(error));
            });
        }
      }, (error) => {
        console.log('download failed: ', error);
      });
    } else {
      this.socialSharing.share("", "", "", url)
        .then((entries) => {
          console.log('success ' + JSON.stringify(entries));
        })
        .catch((error) => {
          console.log('error ' + JSON.stringify(error));
        });
    }
  }

  openMailTo(subject, body) {
    let isPresignedURL = body.includes(this.presignedUrl)
    if (isPresignedURL) {
      window.location.href = 'mailto:%20' +
        '?subject=' + encodeURIComponent(subject) +
        '&body=' + encodeURIComponent(body + ' \n \n Note: This link will expire in 7 days.')

      this.cordovaService.openMail('mailto:%20' +
        '?subject=' + encodeURIComponent(subject) +
        '&body=' + encodeURIComponent(body + ' \n \n Note: This link will expire in 7 days.'))

      return true
    } else {
      window.location.href = 'mailto:%20' +
        '?subject=' + encodeURIComponent(subject) +
        '&body=' + encodeURIComponent(body)

      this.cordovaService.openMail('mailto:%20' +
        '?subject=' + encodeURIComponent(subject) +
        '&body=' + encodeURIComponent(body))

      return true
    }
    
  }


  linkify(text) {
    var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(urlRegex, function(url) {
        return url;
    });
}



  @HostListener('click', ['$event'])
  onLinkClick(e) {
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        window.open(e.target.href, '_blank')
      }
    }
  }
}
