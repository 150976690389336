import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppState } from '../app.service';

@Component({
  selector: 'app-alert-opt-routine',
  templateUrl: './alert-opt-routine.component.html',
  styleUrls: ['./alert-opt-routine.component.scss']
})
export class AlertOptRoutineComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AlertOptRoutineComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private appState: AppState) { }

  ngOnInit() {
  }

}
