import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core'
import * as _ from 'lodash'
import {NamesPipe} from '../names.pipe'

@Component({
  selector: 'first90-badge-category',
  templateUrl: './badge-category.component.html',
  styleUrls: ['./badge-category.component.scss']
})
export class BadgeCategoryComponent implements OnInit, OnChanges {
  @Input() title: any
  @Input() subtext: any
  @Input() pathBadge: any
  @Input() userBadges: any
  badgeSteps: number[]
  userSteps: number[]

  constructor(
    private namesPipe: NamesPipe, // LUMBA-1302
  ) {
    this.badgeSteps = [1, 2, 3, 4, 5]
    this.subtext = this.namesPipe.transform('b_subtext')
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.userBadges) return

    this.userSteps = this.userBadges.map(ub => +ub.step)
  }

  public getOrdinal(n) {
    let s=["th","st","nd","rd"]
    let v=n%100
    return n+(s[(v-20)%10]||s[v]||s[0])
  }
}
