import gql from 'graphql-tag'
import { pollFields } from '../poll/poll.query'
import { contentSurveyFields } from '../content-survey/content-survey.query'
import { documentFields } from '../document/document.query'
import { questionFields } from '../question/question.query'
import { rewardFields } from '../reward/reward.query'
import { pathBadgeFields } from '../badge/badge.query'
import { habitFields } from '../habit/habit.query'
import { inoutScreenFields } from '../inout-screen/inout-screen.query'
import { routineFields } from '../routine-v2/routine-info.query'
import { reflectionFields } from '../my-library/full-screen-reflection/reflection.query'

export const onboardingStepsFields = gql`
fragment onboardingStepsFields on OnboardingSteps {
  id
  isMandatory
  startDay
  endDay
  enabled
  isDeleted
  dayPartImage
  dayPartText
  dayPartHeader
  confirmedIntroText
  confirmationIntroHeader
  learningPreScreen
  learningPostScreen
  learningScreenOrder
  learningActionPoints
  notificationIntroMethodText
  accountHeaderImage
  accountText
  accountHeader
  accountPreScreen
  accountPostScreen
  accountScreenOrder
  accountActionPoints
  managerHeaderImage
  managerEmailText
  managerHeader
  managerPreScreen
  managerPostScreen
  managerScreenOrder
  managerActionPoints
  profilePhotoText
  profileHeader
  profilePreScreen
  profilePostScreen
  profileScreenOrder
  profileActionPoints
  stepTypes
  createdAt
  updatedAt
}
`

export const pathFields = gql`
fragment pathFields on Path {
  id
  userGroup {
    id
    name
  }
  pathGroup {
    id
    name
    allowMultipleLogin
    enabledAttributes
  }
  name
  daysOfTheWeek
  logoImage
  signupEmail
  daysOfContent
  starts
  ends
  colours
  names
  enabledAttributes
  language
  createdAt
  updatedAt
  enabled
  pathImage
  isPathCompleted
  onboardingSurveys {
    id
    question
    enabled
    ptype
    lowerlabel
    higherlabel
    lowerscale
    higherscale
    benchLabel
    benchmarkType
    rationale
    benchmarkNumber
    options {
      id
      text
      enabled
    }
    imageUrl
  }
  closeoutSurveys {
    id
    question
    enabled
    ptype
    lowerlabel
    higherlabel
    lowerscale
    higherscale
    options {
      id
      text
      enabled
    }
    rationale
  }
  polls (isDeleted : false){
    ...pollFields
  }
  contentSurveys(isDeleted : false) {
    ...contentSurveyFields
  }
  documents(isDeleted : false) {
    ...documentFields
    isCompleted
  }
  questions(isDeleted : false) {
    ...questionFields
    isCompleted
  }
  inoutScreens(isDeleted:false) {
    ...inoutScreenFields
  }
  badges {
    ...pathBadgeFields
  }
  rewards {
    ...rewardFields
  }
  habits(isDeleted : false) {
    ...habitFields
    isCompleted
    scheduledLater
    confirmation
  }
  routineInfo (isDeleted : false){
    ...routineFields
    isCompleted
  }
  reflections(isDeleted: false){
    ...reflectionFields
  }
  dailySteps
  onboardingSteps(isDeleted: false){
    ...onboardingStepsFields
  }
}
${pollFields}
${contentSurveyFields}
${documentFields}
${questionFields}
${inoutScreenFields}
${rewardFields}
${habitFields}
${routineFields}
${pathBadgeFields}
${reflectionFields}
${onboardingStepsFields}
`

export const updatePath = gql`
mutation updatePath($path: PathInput!) {
  updatePath(pathInput: $path) {
    ...pathFields
  }
}
${pathFields}
`

export const findPath = gql`
query findPath($id: Int!) {
  path(id: $id) {
    ...pathFields
  }
}
${pathFields}
`

export const findPathForLogin = gql`
query findPathForLogin($pathGroupName: String!, $userGroupName: String!) {
  pathForLogin(pathGroupName: $pathGroupName, userGroupName: $userGroupName) {
    id
    client {
      id
      name
      imageUrl
      headerFontUrl
      bodyFontUrl
      tutorialUrl
      subDomain
      backgroundImageUrl
    }
    userGroup {
      id
      name
    }
    pathGroup {
      id
      name
    }
    starts
    ends
    colours
    names
    enabledAttributes
    createdAt
  }
}
`

export const listPaths = gql`
query listPaths($filter: FilterInput) {
  paths(filter: $filter) {
    ...pathFields
  }
}
${pathFields}
`

export const listPathUsers = gql`
query listPathUsers($id: Int!) {
  path(id: $id) {
    id
    name
    users {
      id
      firstName
      lastName
    }
  }
}
`
