import { Component, OnInit } from '@angular/core';
import { AppState } from '../app.service';
import { SessionService } from '../session/session.service';

@Component({
  selector: 'app-alert-rating-routine',
  templateUrl: './alert-rating-routine.component.html',
  styleUrls: ['./alert-rating-routine.component.scss']
})
export class AlertRatingRoutineComponent implements OnInit {

  postText:any = ''
  formRating1:any = 0
  formRating2:any = 0
  complexItems = this.appState.get("userlist")

  namesData : any[] = new Array()  
  tempValue = 0
  index = 0
  
  constructor(private appState: AppState,private sessionService: SessionService) { }

  ngOnInit() {
  }

  onSumbit(){

    localStorage.setItem('feedbackText',this.postText);

    localStorage.setItem('routineRating1',JSON.stringify(this.formRating1));

    localStorage.setItem('routineRating2',JSON.stringify(this.formRating2));


  }

  
  
  format(item:any) {
    return item['username'].toUpperCase()
  }

}
