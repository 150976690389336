import { Component, OnInit, Input } from '@angular/core'
import { HomeUiService } from '../new-design/home-ui.service'

interface User {
  imageUrl?: string
  advanceColour?: string
}

@Component({
  selector: 'first90-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  @Input() user: User
  @Input() size: string = ''

  advanceColour = ''

  // S3 bucket for images
//  defaultAvatar = 'https://s3.amazonaws.com/first90-api-images/required_images/avatar.png'

defaultAvatar = 'https://img.icons8.com/cotton/94/000000/name--v2.png'


  constructor(public homeUiService: HomeUiService) { }

  ngOnInit() {
    if (this.user) {
      this.advanceColour = this.user.advanceColour || ''
    }
  }

}
