import { environment } from '../../environments/environment'
import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Filter } from '../shared/filter'
import * as query from './poll.query'
import { Poll } from './poll.model'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'

import { DefaultResponse } from '../shared/base.service'
import { AppState } from '../app.service'
import gql from 'graphql-tag';
import { WalletService } from '../wallet/wallet.service'
import { invalidateCache } from '../utils.service'
import { homePageQuery } from '../pages/home/home-page.query';
import { HomePageService } from '../pages/home/home-page.service';

const API_URL: string = environment.apiBaseUrl
const POLLS_URL: string = '/polls'
const FORCE_RANK: string = "/force_rank_answer"
const FORCE_RANK_ANSWERS: string = "/users_force_answers_v2"
const LINEAR_SCALE: string = "/linear_rank_answers_v2"
const LINEAR_SCALE_ANSWERS: string = "/users_linear_answers"
// const ONBOARDING_FORCE_RANK: string = "/onboarding_survey_answer"
const ONBOARDING_LINEAR_SCALE: string = "/users_onboarding_linear_answers_v2"
const ONBOARDING_FORCE_RANK: string = "/onboarding_survey_answer_v2"

export interface AnswerPoll{
  [answerPoll: string]: any
}


@Injectable()
export class PollService {

  public pollAnswers: AnswerPoll 

  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private appState: AppState,
    private homePageService: HomePageService,
    public walletService: WalletService,
  ) { }

  // getList(filter: Filter = null): Observable<Poll[]> {
  //   return this.apollo
  //     .watchQuery<any>({ query: query.listPolls, variables: { filter: filter } })
  //     .valueChanges
  //     .pipe(
  //       map(response => {
  //         let list: Poll[] = []
  //         response.data.polls.forEach(element => { list.push(new Poll(element)) })
  //         return list
  //       })
  //     )
  // }

  getList(pathId) {
    return this.http
      .get<DefaultResponse>(API_URL + POLLS_URL, { params: new HttpParams().set('path_id', pathId) })
      .toPromise()
      .then(response => {
        return response.data
      })
  }

  getPoll(id: number): Observable<Poll> {
    return this.apollo
      .watchQuery<any>({ query: query.findPoll, variables: { id: id } })
      .valueChanges
      .pipe(
        map(response => {
          return new Poll(response.data.poll)
        })
      )
  }

  save(poll: Poll) {
    return this.apollo.mutate({
      mutation: query.updatePoll,
      variables: {
        poll: poll.input()
      }
    })
  }

  answerPoll(poll: Poll, optionId: number) {
    return this.apollo.mutate({
        mutation: query.answerPoll,
        variables: {
          optionId: optionId
        },
        optimisticResponse: {
          __typename: 'Mutation',
          answerPoll: {
            answer: null,
            actionPoints: null
          },
        },
        update: (proxy, { data: { answerPoll } }) => {
          this.homePageService.updateCompletions(proxy, `Poll:${poll.id}`)
          proxy.writeFragment({
            id: `Poll:${poll.id}`,
            fragment: gql`
              fragment answer on Poll {
                answer {
                  optionId
                  actionPoints
                  isCorrect
                  stats
                }
              }
            `,
            data: {answer: answerPoll.answer, __typename: 'Poll'}
          })
          let obj = this.appState.get('currentPath').update('polls', poll.id, {isCompleted: true, answer: answerPoll.answer, time: new Date()})
          obj.afterInit()
          this.walletService.update(answerPoll.actionPoints)
          invalidateCache(proxy, 'ROOT_QUERY.dailyParticipation')
        }
      }).pipe(
        map(response => {
          this.pollAnswers = response.data
          return this.pollAnswers.answerPoll
        })
      )
  }

  public setForceRank(data): Observable<any>{
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));  
    return this.http.post(API_URL + FORCE_RANK,data,{headers})
  }

  public setLinearScale(data): Observable<any>{
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));  
    return this.http.post(API_URL + LINEAR_SCALE,data,{headers})
  }

  public getForceRankAnswers(data): Observable<any>{
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
      const params = new HttpParams().append('user_id', data.user_id)
                                      .append('path_id', data.path_id)
                                      .append('force_question_id', data.force_question_id);
    return this.http.get(API_URL + FORCE_RANK_ANSWERS,{headers,params})
  }

  public getLinearScaleAnswers(data): Observable<any>{
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
      const params = new HttpParams().append('user_id', data.user_id)
                                      .append('path_id', data.path_id)
                                      .append('linear_question_id', data.linear_question_id);
    return this.http.get(API_URL + LINEAR_SCALE_ANSWERS,{headers,params})
  }

  public setOnboardingForceRank(data): Observable<any>{
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));  
    return this.http.post(API_URL + ONBOARDING_FORCE_RANK,data,{headers})
  }

  public setOnboardingLinearScale(data): Observable<any>{
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));  
    return this.http.post(API_URL + ONBOARDING_LINEAR_SCALE,data,{headers})
  }

}
