import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router'
import { AuthService } from './auth.service'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import mixpanel from 'mixpanel-browser';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    public auth: AuthService,
    public router: Router
  ) { }

  canActivate(): Observable<boolean> {
    return this.canActivateChild()
  }

  canActivateChild(): Observable<boolean> {
    let pathGroupName = localStorage.getItem('pathGroupName')
    return this.auth.isAuthenticated().pipe(map(result => {
      mixpanel.track("mixpanel event during authentication to login", {result: result})
      if (result) return true
      if (pathGroupName) {
        // this.router.navigate(['login', pathGroupName])
      } else {
        // this.router.navigate(['login'])
      }
      return false
    }))
  }
}
