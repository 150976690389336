import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppState } from '../app.service';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import * as query from './routine-info.query'
import { HomePageService } from '../pages/home/home-page.service';
import { invalidateCache } from '../utils.service';
import { Path } from '../path/path.model';

export interface AnswerRoutine{
  [answerRoutine: string]: any
}

@Injectable()
export class RoutineV2Service {

  API_URL: string = environment.apiBaseUrl
  path: any;
  logrep = '/routine_commit'
  ROUTINE_LIST = '/getAllRoutineinfo'
  routine_feedback = '/createRoutineFeedback'
  edit_routine = '/routine_edit'
  notRightNow = '/routine_later'
  optedRoutines = '/all_routine_list'
  editHabitLevel='/edit_habit_level'
  routineDetails = '/routine_details'
  all_post_replies = '/get_posts_replies'
  appendReps = '/append_routine_reps'
  deleteAction = '/users/deleteUserAction'
  repeatAction = '/users/recommitUserAction'
  TIMINGS_30_MINUTE : string[] = ['12:00 AM', '12:30 AM', '01:00 AM', '01:30 AM', '02:00 AM', '02:30 AM', '03:00 AM',
  '03:30 AM', '04:00 AM', '04:30 AM', '05:00 AM', '05:30 AM', '06:00 AM', '06:30 AM',
  '07:00 AM', '07:30 AM', '08:00 AM', '08:30 AM', '09:00 AM', '09:30 AM', '10:00 AM',
  '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM', '01:00 PM', '01:30 PM',
  '02:00 PM', '02:30 PM', '03:00 PM', '03:30 PM', '04:00 PM', '04:30 PM', '05:00 PM',
  '05:30 PM', '06:00 PM', '06:30 PM', '07:00 PM', '07:30 PM', '08:00 PM', '08:30 PM', 
  '09:00 PM', '09:30 PM', '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM'];

  subRoutineAnswer: any
  routineInfo: Path;
  public routineAnswer: AnswerRoutine

  constructor(private httpclient: HttpClient,
    private appState: AppState,
    private apollo: Apollo,
    private homePageService: HomePageService,) {

    this.path = this.appState.get('currentPath')

   }

  public replog(data): Observable<any>{
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));  
    return this.httpclient.post(this.API_URL+this.logrep,data,{headers})
  }

  public routineFeedback(data): Observable<any>{
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));  
    return this.httpclient.post(this.API_URL+this.routine_feedback,data,{headers})
  }

  commitRoutine(routineInfoId: number, pathId: number, userId: number, isMandatory: boolean, 
    enabled: boolean, routineType: string, typeValue: string, typeLevel: string, isUnlocked: boolean,
    routineTime: string[], activityTime: string, day: any, routineDescription: string, routineColor: string, scheduleDay: number,
    isOpted: boolean, routineDate: string[], levelIds: number[]) {
    return this.apollo.mutate({
      mutation: query.answerRoutine,
      fetchPolicy: 'no-cache',
      variables: {
        routineInfoId: routineInfoId,
        pathId: pathId,
        userId: userId,
        isMandatory: isMandatory,
        enabled: enabled,
        routineType: routineType,
        typeValue: typeValue,
        typeLevel: typeLevel,
        isUnlocked: isUnlocked,
        routineTime: routineTime,
        activityTime: activityTime,
        day: day,
        routineDescription: routineDescription,
        routineColor: routineColor,
        scheduleDay: scheduleDay,
        isOpted: isOpted,
        routineDate: routineDate,
        levelIds: levelIds
      },
      optimisticResponse: {
        __typename: 'Mutation',
        answerRoutine: {
          answer: null,
          actionPoints: null
        },
      },
      update: (proxy, { data: { answerRoutine } }) => {
        this.homePageService.updateCompletions(proxy, `RoutineInfo:${routineInfoId}`)
        proxy.writeFragment({
          id: `RoutineInfo:${routineInfoId}`,
          fragment: query.ROUTINE_COMPLETION_FRAGMENT,
          data: { isCompleted: true }
        })
        this.appState.get('currentPath').update('routineInfo', routineInfoId, {isCompleted: true})
        invalidateCache(proxy, 'ROOT_QUERY.dailyParticipation')
      }
    }).pipe(
      map(response => {
        this.routineAnswer = response.data
        return this.routineAnswer.answerRoutine
      })
    )
  }

   commitRoutineLevelsOrder(routineInfoId: number, pathId: number, userId: number, isMandatory: boolean, 
    enabled: boolean, scheduleDay: number, levelIds: number[]) {
    return this.apollo.mutate({
      mutation: query.saveRoutineLevelsOrder,
      fetchPolicy: 'no-cache',
      variables: {
        routineInfoId: routineInfoId,
        pathId: pathId,
        userId: userId,
        isMandatory: isMandatory,
        enabled: enabled,
        scheduleDay: scheduleDay,
        levelIds: levelIds
      },
      optimisticResponse: {
        __typename: 'Mutation',
        saveRoutineLevelsOrder: {
          answer: null,
          actionPoints: null
        },
      },
      update: (proxy, { data: { saveRoutineLevelsOrder } }) => {
        invalidateCache(proxy, 'ROOT_QUERY.dailyParticipation')
      }
    }).pipe(
      map(response => {
        let routineAnswer = response.data
        return routineAnswer.saveRoutineLevelsOrder
      })
    )
  }
  public getAllRoutines(data): Observable<any>{
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
    const params = new HttpParams()
    .append('user_id',data.user_id);
    
    return this.httpclient.get(this.API_URL+this.ROUTINE_LIST,{headers,params});
  }

  public editRoutine(data): Observable<any>{
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));  
    return this.httpclient.put(this.API_URL+this.edit_routine,data,{headers})
  }

  public appendRepsCount(data): Observable<any> {
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));  
    return this.httpclient.put(this.API_URL+this.appendReps,data,{headers})
  }

  public innerHtmlToRoutinesData(habit:string=''){

    var arrayOfUrls = []
    var divHabitData = document.createElement("DIV");
    divHabitData.innerHTML = habit;
    let cleanText = divHabitData.innerText;

    var uri_pattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/ig;
    arrayOfUrls = habit.match(uri_pattern);

    return cleanText+"\n\n After you have completed this action, mark it complete in the 1st90 app by clicking here: https://stage1st90.page.link/d6o5";
  }

  getDayFromDate(day){
    switch (day) {
      case 0:
        day = "Sunday";
        break;
      case 1:
        day = "Monday";
        break;
      case 2:
        day = "Tuesday";
        break;
      case 3:
        day = "Wednesday";
        break;
      case 4:
        day = "Thursday";
        break;
      case 5:
        day = "Friday";
        break;
      case 6:
        day = "Saturday";
    }

    return day;
  }
  
  formatTimeForFrontEnd(timeArray) {
    // Convert time from "H:mm AM/PM" to "HH:mm AM/PM"
    let modifiedTime = [];
  
    timeArray?.forEach((time:string)=> {
      const [hour, minutePart] = time?.split(':');
       const hourNumber = parseInt(hour, 10);
       const updateTime= `${hourNumber}:${minutePart}`;
       modifiedTime.push(updateTime)
    })
    return modifiedTime;
   }
  
  createCommitEvent(routine: any= null,level: any, typeValue: any = null, activityCueTime: any = null, routineTime: any = null, 
    routineDay: any = null, commitType: any = null, makeCommitment: any = null, selectedDaysArray: any = null) {
    if (commitType === 'dayTime') {
      if (routineDay) {
        this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " "+selectedDaysArray.toLowerCase()+ ". "
      }else{
        this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " on "+selectedDaysArray.toLowerCase()+ ". "
      }
    } else if (commitType === 'Location' && makeCommitment === 'yes') {
      if (routineDay) {
        this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " when I am "+ typeValue + " "+selectedDaysArray.toLowerCase()+ ". "
      }else{
        this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " when I am "+ typeValue + " on "+selectedDaysArray.toLowerCase()+ ". "
      }
    } else if (commitType === 'Person' && makeCommitment === 'yes') {
      if (routineDay){
        this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " when I encounter "+ typeValue + " "+selectedDaysArray.toLowerCase()+ ". "
      }else{
        this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " when I encounter "+ typeValue + " on "+selectedDaysArray.toLowerCase()+ ". "
      }
    } else if (commitType === 'Activity' && makeCommitment === 'yes') {
      if (routineDay){
        this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " "+ activityCueTime+ " I " + typeValue + " "+selectedDaysArray.toLowerCase()+ ". "
      }else{
        this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " "+ activityCueTime+ " I " + typeValue + " on "+selectedDaysArray.toLowerCase()+ ". "
      }
    } else if (commitType === 'Other' && makeCommitment === 'yes') {
      if (routineDay){
        this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " "+ typeValue + " "+selectedDaysArray.toLowerCase()+ ". "
      }else{
        this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " "+ typeValue + " on "+selectedDaysArray.toLowerCase()+ ". "
      }
    } else if (commitType === 'Location' && makeCommitment === 'no') {
      console.log('true', commitType);
      this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " when I am "+ typeValue + ". "     
    } else if (commitType === 'Person' && makeCommitment === 'no') {
      this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " when I encounter "+ typeValue + ". "     
    } else if (commitType === 'Activity' && makeCommitment === 'no') {
      this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " "+ activityCueTime+ " I " + typeValue + ". "     
    } else if (commitType === 'Other' && makeCommitment === 'no') {
      this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " when "+ typeValue+ ". "
    }
    
    return this.subRoutineAnswer;
  }


  public notNow(data): Observable<any>{
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));  
    return this.httpclient.post(this.API_URL+this.notRightNow,data,{headers})
  }

  public getOptedRoutines(data): Observable<any>{
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
    const params = new HttpParams()
    .append('user_id',data.user_id);
    
    return this.httpclient.get(this.API_URL+this.optedRoutines,{headers,params});
  }
  
  
  public appendOptedRoutineLevels(data): Observable<any>{
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
    const params = new HttpParams()
    .append('user_id',data.user_id);
    
    return this.httpclient.put(this.API_URL+this.editHabitLevel,data,{headers,params});
  }
  public getRoutineDetails(data): Observable<any>{
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
    const params = new HttpParams()
    .append('id',data.id).append('user_id',data.user_id).append('routine_answer_id', data.routine_answer_id).append('mode',data.mode);
    
    return this.httpclient.get(this.API_URL+this.routineDetails,{headers,params});
  }

   public getAllPostsRepliyDetails(data): Observable<any>{
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
    let params = new HttpParams()
      .append("id", data.id)
      .append("mode", data.mode)
      .append("routine_answer_id", data.routine_answer_id)
      .append("page", data.page)
      .append("path_id", data.path_id);
    if (data?.reply_id && data?.reply_id !=undefined) {
      params = new HttpParams()
      .append("id", data.id)
      .append("mode", data.mode)
      .append("routine_answer_id", data.routine_answer_id)
      .append("page", data.page)
      .append("path_id", data.path_id)
      .append("reply_id",data?.reply_id )
    }
    return this.httpclient.get(this.API_URL+this.all_post_replies,{headers,params});
  }
  public getHabitDetails(data): Observable<any>{
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
    const params = new HttpParams()
    .append('id',data.id).append('user_id',data.user_id).append('path_id',data.path_id).append('mode',data.mode);

    return this.httpclient.get(this.API_URL+this.routineDetails,{headers,params});
  }

  public getChatBoardDetails(data): Observable<any>{
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
    const params = new HttpParams()
    .append('user_id',data.user_id).append('id',data.id).append('path_id',data.path_id).append('mode',data.mode);

    return this.httpclient.get(this.API_URL+this.routineDetails,{headers,params});
  }

  setRoutinePathDetails(data) {
    this.routineInfo =data;
  }
  getRoutinePathDetails() {
    return this.routineInfo;
  }

  public deleteUserAction(data): Observable<any>{
    const headers = new HttpHeaders();
    headers.set('Authorization', localStorage.getItem('token'));
    return this.httpclient.put(this.API_URL+this.deleteAction, data,{headers});
  }

  public repeatUserAction(data): Observable<any>{
    const headers = new HttpHeaders();
    headers.set('Authorization', localStorage.getItem('token'));
    return this.httpclient.put(this.API_URL+this.repeatAction, data,{headers});
  }

  deleteActionFromPath(action,habits){
    var currentHabit = this.findHabitbyId(habits, action.id)
    currentHabit.scheduledLater = false
    currentHabit.confirmed = false
    currentHabit.isCompleted = false
  }
  
  findHabitbyId(collection: any, id: any): any {
    return collection.find(
      item => item.id === id
    )
  }
}
