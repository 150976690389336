import { environment } from '../../environments/environment'
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Apollo } from 'apollo-angular'

import { DefaultResponse } from '../shared/base.service'
import { map } from 'rxjs/operators'
import * as query from './stat.query'
import { AppState } from '../app.service'
import { Observable } from 'rxjs/internal/Observable'

const API_URL: string = environment.apiBaseUrl
const STATS_URL: string = '/stats'
const STATS_UPDATED_URL: string ='/stats_v2'
const STATS_BY_PATH: string = '/stats_path'
const STATS_BY_WEEK: string = '/weekly_leaderboard'


@Injectable()
export class StatService {

  userList:any[]

  constructor (private http: HttpClient, private apollo: Apollo,private appState: AppState) { }

  public getList(pathId) {
    return this.http
      .get<DefaultResponse>(API_URL + STATS_URL, { params: new HttpParams().set('path_id', pathId) })
      .toPromise()
      .then(response => {
        return response.data
      })
  }

  public findFirstSurvey(collection: any, scheduleDay: number): any {
    return collection.find(item => item.isCompleted && item.startDay <= scheduleDay);
  }

  findFirstCompletedSurvey(collection: any): any {
    return collection.reverse().find(item => item.isCompleted);
    //return collection.find(item => item.isCompleted);
  }

  findFinalCompletedSurvey(collection: any): any {
    return collection.find(item => item.isCompleted);
    //return collection.reverse().find(item => item.isCompleted);
  }


  setUserData(list){
    var filteredUserList = list.map(this.mapTheobject)
    this.appState.set("userlist",filteredUserList)
  }

  mapTheobject(value){
    const data = {
      'username':value.first_name + " " + value.last_name,
      'id':value.id
    }
    return data;
  }

  public getListStatsByPath(data): Observable<any>{
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
    const params = new HttpParams()
    .append('path_id', data.path_id)
    
    return this.http.get(API_URL+STATS_BY_PATH,{headers,params});
  }

  cutParticipationData(response) {
    let labels = response.labels; // LUMBA-1343
    let data = response.data; // LUMBA-1343
    const firstDayToRender = response.greenDays && response.greenDays.length > 0 ? response.greenDays[0] - 1 : 0; // LUMBA-1368
    if (labels.length > 90) {
      labels = labels.slice(firstDayToRender, firstDayToRender + 90); // LUMBA-1343
      data = data.slice(firstDayToRender, firstDayToRender + 90); // LUMBA-1343
      if (data[response.scheduleDay - 1] === null) {
        data[response.scheduleDay - 1] = 0;
      }
    } else {
      if (data[firstDayToRender + 1] === null) {
        data[firstDayToRender + 1] = 0;
      }
    }
    return {labels, data}
  }

  public getDailyParticipation() {
    return this.apollo
    .watchQuery<any>({ query: query.dailyParticipationQuery, fetchPolicy: 'no-cache' })
    .valueChanges
    .pipe(
      map(response => {
        // const completions = response.data.dailyParticipation.completions || {}

        this.setUserData(response.data.dailyParticipation.userlist)

        return {
          leaderboardPosition: response.data.dailyParticipation.leaderboardPosition,
          badgesCount: response.data.dailyParticipation.badgesCount,
          labels: response.data.dailyParticipation.labels,
          data: response.data.dailyParticipation.data,
          greenDays: response.data.dailyParticipation.greenDays,
          sum: response.data.dailyParticipation.sum,
          skip: response.data.dailyParticipation.skip,
          scheduleDay: response.data.dailyParticipation.scheduleDay,
          realDay: response.data.dailyParticipation.realDay,
          totaldaysofcontent : response.data.dailyParticipation.totaldaysofcontent,
          totalscheduleday : response.data.dailyParticipation.totalscheduleday,
        }
      })
    )
  }

  public getListStatsByWeek(data): Observable<any>{
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
    const params = new HttpParams()
    .append('path_id', data.path_id)
    
    return this.http.get(API_URL+STATS_BY_WEEK,{headers,params});
  }
}
