import { Component, Input, OnInit } from "@angular/core";
import { AppState } from "src/app/app.service";
import { I18n } from "src/app/i18n.service";
import { Path } from "src/app/path/path.model";

@Component({
    selector: 'top-header',
    templateUrl: './top-header.component.html',
    styleUrls: ['./top-header.component.scss'],
})
export class TopHeaderComponent implements OnInit {

    makeActive: boolean = false

    currentPath: Path

    constructor(private appState: AppState, private i18n: I18n){
        this.currentPath = this.appState.get('currentPath')
    }

    ngOnInit(){}

    setClass(value){
        this.makeActive = value
        if(value){
            this.appState.set("isAllPaths", true);
        }
        else {
            this.appState.set("isAllPaths", false);

        }
    }

    getClass(){
        return this.makeActive
    }
}