import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Filter } from '../shared/filter'
import * as query from './user.query'
import { User } from './user.model'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { SessionService } from '../session/session.service'
import { DefaultResponse } from '../shared/base.service'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { PathRegistration } from './path-registration.model'
import {ReplyTranslation} from '../translation/translation.service'
import {PathGroup} from '../path-group/path-group.model'
import {Path} from '../path/path.model'

const API_URL: string = environment.apiBaseUrl
const FORGOT_PASSWORD_URL: string = '/users/forgot_password'
const RESEND_VERIFICATION_EMAIL_URL: string = '/users/resend_verification_email'
const EARNED_BADGES: string = '/earned_badges'
const LOGOUT: string = '/mutating_notification'
const POST_PROFILE_PIC: string = '/files'
const APP_NAME: string = '/users/update_user_app_name'

@Injectable()
export class UserService {
  constructor(private http: HttpClient, private apollo: Apollo, public sessionService: SessionService) { }

  getList(email: string): Observable<User> {
    return this.apollo
      .watchQuery<any>({ query: query.listUsers, variables: { email: email }, fetchPolicy: 'no-cache', })
      .valueChanges
      .pipe(
        map(response => {
          return response.data.userEmail
        })
      )
  }

  getUser(id: number): Observable<User> {
    return this.apollo
      .watchQuery<any>({ query: query.findUser, variables: { id: id } })
      .valueChanges
      .pipe(
        map(response => {
          return new User(response.data.user)
        })
      )
  }

  save(user: User) {
    return this.apollo.mutate({
      mutation: query.updateUser,
      variables: {
        user: user.input()
      }
    })
  }

  savePathRegistration(pathRegistration: PathRegistration) {
    return this.apollo.mutate({
      mutation: query.updatePathRegistration,
      variables: {
        pathRegistration: pathRegistration.input()
      }
    })
  }

  updateScreensViewed(pathRegistration: PathRegistration) {
    return this.apollo.mutate({
      mutation: query.updateScreensViewed,
      variables: {
        pathRegistration: pathRegistration.input()
      }
    })
  }

  signUp(user: User) {
    return this.apollo.mutate({
      mutation: query.signUpUser,
      variables: {
        user: user.input()
      }
    }).pipe(
      map(response => {
        this.removeLocalStorageData()
        return response
      })
    )
  }

  removeLocalStorageData() {
    const exclude = ["token", "deviceToken"];
    const localStorageLength = localStorage.length;
    const keys = [];
    for (let i = 0; i < localStorageLength; i++) {
      const key = localStorage.key(i);
      if (exclude.indexOf(key) === -1) {
        keys.push(key);
      }
    }
    keys.forEach((key) => localStorage.removeItem(key));
  }

  deleteUser(user: User) {
    return this.apollo.mutate({
      mutation: query.deleteAccount,
      variables: {
        id: user.id
      },
      update: (proxy) => {
      }
    })
  }

  // LUMBA-982
  pathGroupsByClientEmail(email: string, clientId?: string, clientSubDomain?: string, fromMobile?: boolean) {
    return this.apollo.watchQuery<any>({ query: query.pathGroupsByClientEmail, variables: {
      email: email, clientId: clientId, clientSubDomain: clientSubDomain, fromMobile: fromMobile
    }, fetchPolicy: 'no-cache' })
    .valueChanges
    .pipe(
      map(response => {
        const list: PathGroup[] = []
        response.data.pathGroupsByClientEmail.pathGroups.forEach(element => { list.push(new PathGroup(element)) })
        return {clientPathGroups: list, errors: response.data.pathGroupsByClientEmail.errors}
      })
    )
  }

  pathGroupsBySignupCode(signupCode: string){
    return this.apollo.watchQuery<any>({query: query.pathGroupsBySignupCode, variables: {
      signupCode: signupCode
    } })
    .valueChanges
    .pipe(
      map(response => {
        const list: PathGroup[] = []
        response.data.pathGroupsBySignupCode.pathGroups.forEach(element => { list.push(new PathGroup(element)) })
        return {clientPathGroups: list, errors: response.data.pathGroupsBySignupCode.errors}
      })
    )  
  }

  pathsBySignupCode(signupCode: string){
    return this.apollo.watchQuery<any>({query: query.pathsBySignupCode, variables: {
      signupCode: signupCode
    } })
    .valueChanges
    .pipe(
      map(response => {
        const list: any = []
        response.data.pathsBySignupCode.paths.forEach(element => { list.push(element) })
        return {paths: list, errors: response.data.pathsBySignupCode.errors}
      })
    )  
  }
  // LUMBA-982
  pathGroupsByToken(token: string) {
    return this.apollo.watchQuery<any>({ query: query.pathGroupsByToken, variables: { token: token} })
      .valueChanges
      .pipe(
        map(response => {
          const list: PathGroup[] = []
          response.data.pathGroupsByToken.forEach(element => { list.push(new PathGroup(element)) })
          return list
        })
      )
  }

  sendForgotPassword(email) {
    return this.http
      .post<any>(API_URL + FORGOT_PASSWORD_URL, { 'email': email })
      .toPromise()

  }

  resendVerificationEmail(email) {
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));  
    return this.http
      .put<any>(API_URL + RESEND_VERIFICATION_EMAIL_URL, { 'email': email,headers })
      .toPromise()

  }

  public getEarnedBadges(data): Observable<any>{
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
      const params = new HttpParams().append('user_id', data.user_id)
    return this.http.get(API_URL + EARNED_BADGES,{headers,params})
  }

  public postProfilePic(body): Observable<any>{
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
    //const params = new HttpParams().append('user_id', data.user_id)
    return this.http.post<any>(API_URL + POST_PROFILE_PIC, body,{headers});
  }

  public logOut(data): Observable<any>{
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
      const params = new HttpParams()
      .append('user_id', data.user_id)
      .append('path_id', data.path_id)
      .append('enabled', data.enabled)
    return this.http.get(API_URL + LOGOUT,{headers,params})
  }

  public setAppName(data): Observable<any>{
    const headers = new HttpHeaders();
    headers.set('Authorization', localStorage.getItem('token'));
    return this.http.put(API_URL+APP_NAME, data,{headers});
  }
  
}
