import { Component, OnInit } from '@angular/core';
import mixpanel from 'mixpanel-browser';

@Component({
  selector: 'app-alert-play-intro',
  templateUrl: './alert-play-intro.component.html',
  styleUrls: ['./alert-play-intro.component.scss'],
})
export class AlertPlayIntroComponent implements OnInit {

  constructor() { }

  ngOnInit() {}
  tourStart(value: any) {
    if(value === 'start') {
      mixpanel.track('QS Tour-Tour Start', {'isTourStart': true})
    }else {
      mixpanel.track('QS Tour-Tour Skipped', {'isSkipped': true})
    }
  }
}
