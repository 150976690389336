import { Injectable } from '@angular/core'
import { AppState } from '../../app.service'
import { Apollo } from 'apollo-angular'
import { Filter } from '../../shared/filter'
import { Observable } from 'rxjs/internal/Observable'
import * as query from './journal.query'
import { map } from 'rxjs/operators'
import { JournalPost } from './journal-post.model'

@Injectable()
export class JournalService {

  constructor(
    private appState: AppState,
    private apollo: Apollo,
  ) {
  }

  getList(): Observable<JournalPost[]> {
    return this.apollo
      .watchQuery<any>({
        query: query.listJournalPosts, 
        fetchPolicy: 'no-cache',
        variables: {filter: this.getFilter()}
      })
      .valueChanges
      .pipe(
        map(response => {
          const list: JournalPost[] = []
          response.data.journalPosts.forEach(element => {
            list.push(new JournalPost(element))
          })
          return list
        })
      )
  }

  post(text: string) {
    const optimisticId = 1000000000 + Math.round(Math.random() * 1000000000)

    return this.apollo.mutate({
      mutation: query.postToJournal,
      variables: {
        journalPost: {
          text: text
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        postToJournal: {
          __typename: 'PostToJournalPayload',
          optimistic: true,
          status: true,
          journalPost: {
            id: optimisticId,
            text: text,
            userId: null,
            pathId: null,
            isPinned: false,
            emailedAt: null,
            createdAt: (new Date()).toString(),
            updatedAt: null,
            __typename: 'JournalPost'
          }
        }
      },
      update: (proxy, {data: {postToJournal}}) => {
        console.log(postToJournal)
        const data: any = proxy.readQuery({query: query.listJournalPosts, variables: {filter: this.getFilter()}})
        data.journalPosts = [...data.journalPosts, postToJournal.journalPost]

        proxy.writeQuery({query: query.listJournalPosts, variables: {filter: this.getFilter()}, data})
      }
    })
  }

  sendPdf() {
    const path = this.appState.get('currentPath')

    return this.apollo.mutate({
        mutation: query.sendJournalPdf,
        variables: {
          pathId: path.id
        }
      }
    )
  }

  toggleJournalPostPin(postId: number = null, pin = false) {
    return this.apollo.mutate({
        mutation: query.toggleJournalPostPin,
        variables: {
          postId: postId,
          pin: pin
        },
        optimisticResponse: {
          __typename: 'Mutation',
          toggleJournalPostPin: {
            __typename: 'ToggleJournalPostPinPayload',
            optimistic: true,
            pin: pin
          }
        },
        update: (proxy, {data: {toggleJournalPostPin}}) => {
          const data: any = proxy.readQuery({query: query.listJournalPosts, variables: {filter: this.getFilter()}})
          const i = data.journalPosts.findIndex(p => p.id === postId)
          data.journalPosts[i].isPinned = toggleJournalPostPin.pin
          proxy.writeQuery({query: query.listJournalPosts, variables: {filter: this.getFilter()}, data})
        }
      }
    )
  }

  private getFilter() {
    const filter = new Filter()
    filter.perPage = 500
    return filter
  }
}
