import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { AppState } from '../app.service';
import { Client } from '../client/client.model';
import { UserPublicProfileComponent } from '../components/user-public-profile/user-public-profile.component';
import { CordovaService } from '../cordova.service';
import { MobileChatServiceService } from '../pages/mobile-chat-board/mobile-chat-service.service';
import { ReplyService } from '../reply/reply.service';
import { SessionService } from '../session/session.service';
import { User } from '../user/user.model';
import { I18n } from '../i18n.service';
import { HomeUiService } from '../new-design/home-ui.service';

@Component({
  selector: 'question-comment',
  templateUrl: './question-comment.component.html',
  styleUrls: ['./question-comment.component.scss'],
})
export class QuestionCommentComponent {
  @ViewChild('disableListener') disableListener: ElementRef;
  @Input() inverted = false
  @Input() comment: Comment
  @Input() voteCounts: number
  @Input() postId: number
  isMobile: boolean
  user: User
  client: Client
  showMenu = false;
  selected: boolean = false
  voteLoading: boolean = false
  state: boolean = false;

  constructor(private appState: AppState, 
    public sessionService:SessionService,
    private cordovaService: CordovaService,  
    private cdRef: ChangeDetectorRef,
    private replyService: ReplyService,
    private mobileService:MobileChatServiceService,
    private toasterService:ToastrService,
    private i18n: I18n,
    private apollo: Apollo,
    private homeUIService: HomeUiService,
    private popoverController:PopoverController
    ) {
    this.client = this.appState.get('currentClient')
    this.user = this.appState.get('currentUser')
    this.isMobile = this.appState.get('isMobile')
    

  }
  async openUserInfo(ev, userData): Promise<void> {
    const popover = await this.popoverController.create({
      component: UserPublicProfileComponent,
      event: ev,
      mode:'ios',
      translucent: false,
      componentProps: {'user': userData}
    });
    await popover.present();
   
  }
  public getIcon( fileName ) {
    const extension = fileName.split( '.' ).pop()
    let icon = ''

    switch ( extension ) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        icon = 'glyphicon-picture'
        break
      case 'mp4':
      case '3gp':
      case 'mpg':
      case 'mpeg':
      case 'avi':
      case 'flv':
        icon = 'glyphicon-film'
        break
      case 'mp3':
        icon = 'glyphicon-headphones'
        break
      case '7z':
      case 'rar':
      case 'zip':
        icon = 'glyphicon-folder-open'
        break
      default:
        icon = 'glyphicon-file'
    }

    return icon
  }
  ngAfterViewInit(){
    try {
      if (!this.isMobile) {
        document.getElementById('social-icons').classList.add('desktop');
      }
    } catch (error) { }
  }

  getUserName(user){
    try {
          return user.firstName+" "+user.lastName 
    } catch (error) {
      
    }
  }

  toggleVote(comment) {    
    if (comment.user.id === this.user.id) {
      return false
    }
    this.voteLoading = true
    comment.hasVoted = !comment.hasVoted
    comment.votesCount += comment.hasVoted ? 1 : -1
    this.cdRef.detectChanges()
    this.replyService.voteReply(comment,'').subscribe(
      status => {
        this.voteLoading = false
        this.cdRef.detectChanges()
      },
      error => {},
      () => {
        this.voteLoading = false
        this.cdRef.detectChanges()
      }
    )
  }


  @HostListener('click', ['$event'])
  onLinkClick(e) {
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        window.open(e.target.href, '_blank')
      }
    }
  }

  deletePost(id){
    this.mobileService.deletePost(id).subscribe(
      response => {
          var test = this.appState.get('currentPath')
          this.appState.set('commentDeleted', true)
         this.appState.set("refreshCommentList", true);
         // this.refresh()
         this.cdRef.detectChanges();
          this.toasterService.success(this.i18n.t('Comment deleted successfully'));
      },
      error =>{
        console.log(error);
      }
    )
  }

  refresh() {
    this.appState.refreshMainComponent.emit();
  }


  copyMessage(val: string,comment_id: number){
    try {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.toasterService.success(this.i18n.t("Copied to clipboard!"));
      this.deletePost(comment_id)
    }catch{
      this.toasterService.warning(this.i18n.t("Not able to copy and delete!"));
    }
  }
@HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.disableListener?.nativeElement.contains(event.target)) {
      this.showMenu = !this.showMenu;
    }
    else {
      this.showMenu =false;
    }
  }
}
