import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-board',
  templateUrl: './test-board.component.html',
  styleUrls: ['./test-board.component.scss']
})
export class TestBoardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
