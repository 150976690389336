import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from 'src/app/app.service';
import { HomeV5Service } from 'src/app/pages/home-v5/home-v5.service';
import { LibraryService } from '../library.service';
import { I18n } from 'src/app/i18n.service';
import { ToasterService } from 'src/app/toaster.service';
import { ModalController } from '@ionic/angular';
import { HomeUiService } from 'src/app/new-design/home-ui.service';
import { AnimationsService } from 'src/app/shared/animations.service';
import { DailyStepsContentModalComponent } from 'src/app/new-design/daily-steps-content-modal/daily-steps-content-modal.component';

@Component({
  selector: 'app-full-screen-reflection',
  templateUrl: './full-screen-reflection.component.html',
  styleUrls: ['./full-screen-reflection.component.scss'],
})
export class FullScreenReflectionComponent implements OnInit {

  reflection: any
  journalPost: any
  isMobile: boolean
  currentPath: any
  stepNumber: number
  currentUser: any
  isonboardingcompleted: any;
  isBackAllowed: boolean

  constructor(private appState: AppState,
    public i18n: I18n,
    private route: Router,
    private modalController: ModalController,
    private homeUIService: HomeUiService,
    private homeService: HomeV5Service,
    private animationService: AnimationsService) {
    this.isMobile = this.appState.get('isMobile')
    this.journalPost = this.appState.get('journalPost')
    this.currentPath = this.appState.get('currentPath')
    this.currentUser = this.appState.get('currentUser')
    this.isBackAllowed = this.appState.get('isBackAllowed')
    console.log('journalPost :', this.journalPost);
    if (this.journalPost) {
      if(this.journalPost?.reflections[0]){
        this.stepNumber = this.journalPost?.reflections[0].startDay
      }
    }
    this.reflection = this.journalPost?.reflections[0]
  }

  ngOnInit() {}

  openInStep(){
    this.appState.set('screenId', 'daily-content-screen-reflection')
    this.homeUIService.dismissAllModals().then(()=>{
      console.log("modal dismiss", this.modalController.getTop())
      // this.modalController.dismiss().then(()=>{

    
      if (this.journalPost.pathId === this.currentPath.id) {
        this.appState.set('currentScheduleDay', this.stepNumber);
        if (this.isMobile) {
          setTimeout(()=>{
            this.route.navigate(['/content', this.stepNumber]) 
          },700)
        } else {
          this.homeUIService.closeAllModals(this.stepNumber)
        }
        this.appState.set('isRouteToLibrary', true) 
      }else{
        var data = {
          pathId: this.currentPath.id,
          userId: this.journalPost.userId
        }
        this.appState.set('prevPathData', data)
        setTimeout(() => {
          this.changePath(this.journalPost.pathId, this.journalPost.userId, this.stepNumber)
        }, 600);
      }
    });
    // })
  }

  
  changePath(pathId, userId, stepNumber) {
    const request = {
      'user_id': userId,
      'active_path_id': pathId
    }

    this.homeService.subpathchange(request).subscribe(
      (response) => {
        let data = response["data"];
      this.homeUIService.dismissAllModals().then(()=>{
        try {
          let isOnboardContentPresent = data.onboardingSurvey || data.isIntroVideo;
          if (data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted?.length > 0 && isOnboardContentPresent) {
            let value = data['isonboardingcompleted'][0];
            this.isonboardingcompleted = value["isonboardingcompleted"];
            if ((this.isonboardingcompleted === false || this.isonboardingcompleted === null) && isOnboardContentPresent) {
              setTimeout(()=>{
              this.route.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          
              },600);
            } else if (this.isonboardingcompleted === true) {
              if (this.isMobile) {
                 setTimeout(()=>{
              this.route.navigate(['/content', stepNumber])
              },600);
              }
              else {
                 setTimeout(()=>{
                   this.homeUIService.openDailyStepsComponent(DailyStepsContentModalComponent,{currentDay: stepNumber},'web-daily-steps-custom-modal')
                 },1500);            
              }
             
            }
          } else if(data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted.length===0 && isOnboardContentPresent){
            setTimeout(()=>{
              this.route.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          
            },600);
          } else {
            this.homeUIService.checkSubpathStatus();
            if (this.isMobile) {
               setTimeout(()=>{
            this.route.navigate(['/content', stepNumber])
            },600);
            }
            else {
                setTimeout(()=>{
                   this.homeUIService.openDailyStepsComponent(DailyStepsContentModalComponent,{currentDay: stepNumber},'web-daily-steps-custom-modal')
                 },1500);
            }
           
          }
        } catch (error) {
          setTimeout(()=>{
          this.route.navigate(['/main']);
          },600);
        }
      });
        this.refresh()
      }, (error) => {
        console.log('error :', error);
      }
    )
  }

  refresh() {
    return new Promise(resolve => {
      this.appState.refreshMainComponent.emit()
      setTimeout(resolve, 0)
    })
  }

  close(){
    if (this.isBackAllowed) {
      this.appState.set('isBackAllowed', undefined)
    }
    this.appState.set('dontRefresh', true)
   // window.history.back()
   this.modalController.dismiss();
  }

}
