import { Component, OnInit } from '@angular/core';
import { AppState } from '../app.service';
import { SessionService } from '../session/session.service';

@Component({
  selector: 'app-alert-rep-log',
  templateUrl: './alert-rep-log.component.html',
  styleUrls: ['./alert-rep-log.component.scss']
})
export class AlertRepLogComponent implements OnInit {

  postText:any = ''
  formRating1:any = 0
  formRating2:any = 0
  complexItems = this.appState.get("userlist")

  namesData : any[] = new Array()  
  tempValue = 0
  index = 0
  
  constructor(private appState: AppState,private sessionService: SessionService) { }

  ngOnInit() {
  }

  onSumbit(){

    localStorage.setItem('repLogText',this.postText);

  }

  
  
  format(item:any) {
    return item['username'].toUpperCase()
  }

}
