import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StreamingMedia, StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';
import { environment } from 'src/environments/environment';
import { AlertEditNoteComponent } from '../alert-edit-note/alert-edit-note.component';
import { AppState } from '../app.service';
import { CordovaService } from '../cordova.service';
import { LibraryService } from '../my-library/library.service';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { SpinnerService } from '../spinner.service';
import { ToasterService } from '../toaster.service';
import { YoutubeVideoPlayer } from '@awesome-cordova-plugins/youtube-video-player/ngx';
import { SessionService } from '../session/session.service';
import { AlertController, AlertInput, ModalController, Platform } from '@ionic/angular';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { I18n } from '../i18n.service';
import { HomeUiService } from '../new-design/home-ui.service';
import { VideoDetailsComponent } from '../video-details/video-details.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-full-screen-video',
  templateUrl: './full-screen-video.component.html',
  styleUrls: ['./full-screen-video.component.scss'],
})
export class FullScreenVideoComponent implements OnInit {
  @Input() data: any
  libraryPosts: any
  presignedUrl: any
  isMobile: any
  pathName: any;
  journalNote: string
  isEditNote: boolean
  stepNumber: number
  isCasualDocument: boolean
  journalPosts;  pdfSrc: any;
  singleDocument;
  playYTVideo = false;
  youtubeVideoId;
  constructor(private youtubePlayer: YoutubeVideoPlayer, private route: Router,
    private socialSharing: SocialSharing,
    private appState: AppState,
    private spinnerService: SpinnerService,
    private cdRef: ChangeDetectorRef,
    private toasterService: ToasterService,
    private cordovaService: CordovaService, private dialog: MatDialog, private libraryService: LibraryService,
    private router: Router, private streamingMedia: StreamingMedia,private platform: Platform,
    private transfer: FileTransfer, 
    private alertController: AlertController,
    private i18n: I18n,
    private file: File,    
    private HomeUiService: HomeUiService,
    private modalController: ModalController,
    private sanitizer: DomSanitizer,
    private sessionService: SessionService) {
    this.libraryPosts = this.appState.get('journalPost')
    this.isCasualDocument = this.appState.get('isCasualDocument')
     this.journalPosts = this.data;
    //  this.router.getCurrentNavigation().extras.state.data;
    console.log(this.journalPosts); // should log out 'bar'
    this.isMobile = this.appState.get('isMobile');
    this.presignedUrl = environment.preSignedUrl
    this.singleDocument = this.appState.get('singleDocument')
    console.log("presignedt", this.presignedUrl);
    this.pathName = this.appState.get('journalPathName')
    if (this.libraryPosts) {
      if (this.libraryPosts?.questions[0]) {
        this.stepNumber = this.libraryPosts?.questions[0].startDay
      } else if (this.libraryPosts?.documents[0]) {
        this.stepNumber = this.libraryPosts?.documents[0].startDay
      } else if (this.libraryPosts?.contentSurveys[0]) {
        this.stepNumber = this.libraryPosts?.contentSurveys[0].startDay
      } else if (this.libraryPosts?.habits[0]) {
        this.stepNumber = this.libraryPosts?.habits[0].startDay
      } else if (this.libraryPosts?.inoutScreens[0]) {
        this.stepNumber = this.libraryPosts?.inoutScreens[0].startDay
      }
    }
  }

  ngOnInit() {
    this.journalPosts = this.data;
    if (this.sessionService.confirmYoutubeUrl(this.journalPosts.link)) {
      this.playYTVideo = true
      let videoId=  this.journalPosts.link.split('v=')[1]
      this.youtubeVideoId = this.getSafeUrl(`https://www.youtube.com/embed/${videoId}`)
      console.log('Youtube video :',this.youtubeVideoId);

    }
  }
   ionViewWillEnter() {
    this.ngOnInit();
  }
  // Method to sanitize and return a trusted URL
  getSafeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  close() {
    this.appState.set('singleDocNote', undefined)
    localStorage.removeItem('journalNote')
    this.appState.set('isDocumentClosed',true);
    console.log('this.isCasualDocument :', this.isCasualDocument);
    if (this.isCasualDocument) {
      this.appState.set('isCasualDocument', undefined)
    }
    this.modalController.dismiss();
  }

  playWebRemoteVideo() {
    this.route.navigate(['/video'], { state: { data: this.journalPosts } })
  }

  playRemoteVideo() {
    if (this.sessionService.confirmYoutubeUrl(this.journalPosts.link)) {
      console.log('Youtube video :');
      // if (this.platform.is('ios')) {
      //   try {
      //     // Try to run this code
      //     console.log("in try block");
      //     this.youtubePlayer.openVideo(this.sessionService.getYouTubeVideoIdFromUrl(this.journalPosts.link));
      //   }
      //   catch(err) {
      //     // if any error, Code throws the error
      //     console.log("catch_block", err);
      //   }
      // } else {
       //window.open(this.journalPosts.link, "_blank");
       this.playYTVideo =true
        this.cordovaService.openBrowserLinkVideo(this.journalPosts.link);
     // }
    } else if(this.platform.is('android')){
      this.cordovaService.openBrowserLinkVideo(this.journalPosts.link);
    }
    else {
      console.log('Uploaded Video :' );
      if (!this.isMobile) {
       // this.route.navigate(['/video'], { state: { data: this.journalPosts } })
        this.HomeUiService.openNextComponent(VideoDetailsComponent,{ data: this.journalPosts } , 'desktop-library-custom')
      }
      else {
        let options: StreamingVideoOptions = {
          successCallback: () => { console.log('Video played') },
          errorCallback: (e) => { console.log(e) },
          orientation: 'portrait',
          shouldAutoClose: true,
          controls: true
        };
        console.log('this.journalPosts.link :', this.journalPosts.link);
        console.log('options :', options);
        this.streamingMedia.playVideo(this.journalPosts.link, options);
      }
    }
  }
  getNoteContent(post){
    if (post) {
      let note = post
      var b = JSON.stringify(note);
      let testNote = b.replace(/\\/g, '');
      let testNote1 = testNote.replace(/[\[\]']+/g, '')
      this.journalNote = testNote1.replace(/"/g, '');
      console.log('journalNote',this.journalNote);
    }
  }

  async openNotesModal() {
    if (localStorage.getItem('journalNote')) {
      this.appState.set('singleDocNote', localStorage.getItem('journalNote'))
    } else {
      this.appState.set('singleDocNote', this.journalPosts?.notes)
    }

    let note = this.appState.get('singleDocNote')
    this.getNoteContent(note);  
    const alert =await this.alertController.create({
      header: this.i18n.t("Notes"),
      inputs:[
        {
          type:'textarea',
          name:'textareaValue',
          value:this.journalNote,
          cssClass:'alert-textarea'
        },
      
      ],
      buttons: [
        {
          text:this.i18n.t('Save'),
          cssClass:'custom-alert',
          handler:(alertData: AlertInput)=>{
            let noteText;
            noteText = alertData['textareaValue'];

            localStorage.setItem('journalNote', alertData['textareaValue']);
            this.appState.set('singleDocNote', noteText)
            this.journalNote = noteText
            this.saveNote()
          }
        }
      ]
    })
    await alert.present();
  }

  share(event, document) {
    event.stopPropagation()
    let isPresignedURL = document.link.includes(this.presignedUrl)
    if(this.isMobile){
      try {
        this.downloadFile(document.link)
      } catch (error) {
        console.log('error :', error);
      }
    }else{
      this.openMailTo(isPresignedURL, document.title, document.link)
    }
  }

  downloadFile(url) {
    if (url.includes(".pdf")) {
      const transfer = this.transfer.create();
      let path = null;
      const name = url.substr(url.lastIndexOf("/") + 1);
      const fileName = name.split("?")[0];
      if (this.platform.is("ios")) {
        path = this.file.documentsDirectory;
      } else {
        path = this.file.dataDirectory;
      }
      transfer.download(url, this.file.dataDirectory + fileName).then((entry) => {
        this.pdfSrc = entry.toURL()
        console.log('this.pdfSrc :', this.pdfSrc);
        if (this.pdfSrc) {
          this.socialSharing.share("", "", this.pdfSrc, "")
            .then((entries) => {
              console.log('success ' + JSON.stringify(entries));
            })
            .catch((error) => {
              console.log('error ' + JSON.stringify(error));
            });
        }
      }, (error) => {
        console.log('download failed: ', error);
      });
    } else {
      this.socialSharing.share("", "", "", url)
        .then((entries) => {
          console.log('success ' + JSON.stringify(entries));
        })
        .catch((error) => {
          console.log('error ' + JSON.stringify(error));
        });
    }
  }

  openMailTo(isPresignedURL, subject, body) {
    if (isPresignedURL) {
      location.href = 'mailto:%20' +
        '?subject=' + encodeURIComponent(subject) +
        '&body=' + encodeURIComponent(body + ' \n \n Note: This link will expire in 7 days.')

      this.cordovaService.openMail('mailto:%20' +
        '?subject=' + encodeURIComponent(subject) +
        '&body=' + encodeURIComponent(body + ' \n \n Note: This link will expire in 7 days.'))

      return true
    } else {
      location.href = 'mailto:%20' +
        '?subject=' + encodeURIComponent(subject) +
        '&body=' + encodeURIComponent(body)

      this.cordovaService.openMail('mailto:%20' +
        '?subject=' + encodeURIComponent(subject) +
        '&body=' + encodeURIComponent(body))

      return true
    }
  }

  editNote() {
    this.isEditNote = true
  }

  saveNote() {
    this.isEditNote = false
    let postId
    if (this.isCasualDocument) {
      postId = this.journalPosts.id
      const requestData = {
        'text': [this.journalNote],
        'pathId': null,
        'contentSurveyId': null,
        'habitId': null,
        'pollId': null,
        'documentId': postId,
        'questionId': null,
        'inoutScreenId': null,
        'routineInfoId': null,
        'screenId': null
      }
      this.libraryService.save(requestData).subscribe((response) => {
        console.log('response :', response);
        this.toasterService.success(this.i18n.t('Note saved.'))
      },
        (error) => {
          console.log('error :', error);
        })
    } else {
      // postId = this.libraryPosts.id
      if(this.journalPosts || this.libraryPosts){
        postId = this.journalPosts?this.journalPosts?.id: this.libraryPosts.id

      }
      else {
        postId = this.singleDocument?.id
      }
      const requestData = {
        id: postId,
        text: this.journalNote
      }
      this.libraryService.editNote(requestData).subscribe(
        (response) => {
          console.log('response :', response);
          this.toasterService.success(this.i18n.t('Note saved.'))
        },
        (error) => {
          console.log('Error for feedback ', error)
        }
      )
    }

  }
}
