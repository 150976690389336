import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'

@Component({
  selector: 'mtr-toggle',
  templateUrl: './mtr-toggle.component.html',
  styleUrls: ['./mtr-toggle.component.scss']
})
export class MtrToggleComponent implements OnInit {

  checkedValue: boolean
  @Output() checkedChange = new EventEmitter()

  @Input()
  get checked() {
    return this.checkedValue
  }

  set checked(value: boolean) {
    this.checkedValue = value
    this.checkedChange.emit(value)
  }

  constructor() { }

  ngOnInit() {
  }

  toggle() {
    this.checked = !this.checked
  }

}
