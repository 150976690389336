import { Base } from '../shared/base'
import { Client } from '../client/client.model'
import { hexToRgb, shadeColor } from '../utils.service'
import { Theme } from '../theme/theme.model'

export interface ColoursAttributes {
  [name: string]: string
}
export interface NamesAttributes {
  [name: string]: string
}
export interface EnabledAttributes {
  [section: string]: boolean
}

export interface UserGroup {
  id?: number
  pathId?: number
  pathGroupId?: number
  name?: string
}

interface PathSummary {
  id?: number
  name?: string
  pathDescription?: string
  pathImage?: string
}

export class PathGroup extends Base {
  init() {
    this._fields = ['id', 'clientId', 'name', 'daysOfTheWeek', 'logoImage', ' signupCode',
      'signupEmail', 'daysOfContent', 'starts', 'ends', 'userGroups', 'language', 'allowMultipleLogin',
      'colours', 'names', 'enabledAttributes', 'client', 'enabled', 'userGroup', 'paths']

    this._nestedFields = {
      client: Client,
    }

    this.names = {}
    this.colours = {
      themeheader: '#FFFFFF',
      highlightheader: '#FFFFFF',
      header: '#FFFFFF',
      theme: '#54BDAF',
      highlight: '#54BDAF',
      stat1: '#575757',
      stat2: '#C8FA0A',
      stat3: '#00A0DC',
      stat4: '#6E1E78',
      stat5: '#E63264'
    }
    this.enabledAttributes = {}
    this.userGroups = []
    this.daysOfContent = 0
    this.client = new Client
    this.daysOfTheWeek = 0
  }

  afterInit() {
    try {
      this.colours['highlight-faded'] = hexToRgb(this.colours['highlight'], .5)
      this.colours['stat1-shaded'] = shadeColor(this.colours['stat1'], -.2)
      this.colours['stat2-shaded'] = shadeColor(this.colours['stat2'], -.2)
      this.colours['stat3-shaded'] = shadeColor(this.colours['stat3'], -.2)
      this.colours['stat4-shaded'] = shadeColor(this.colours['stat4'], -.2)
      this.colours['stat5-shaded'] = shadeColor(this.colours['stat5'], -.2)
    } catch (e) {}
    this.setTheme()
  }

  public id: number
  public clientId: number
  public name: string
  public daysOfTheWeek: number
  public logoImage: string // LUMBA-982
  public signupEmail: string
  public signupCode: string
  public daysOfContent: number
  public starts: string
  public ends: string
  public language: string
  public colours: ColoursAttributes
  public names: NamesAttributes
  public enabledAttributes: EnabledAttributes
  public client: Client
  public userGroups: UserGroup[]
  public paths: PathSummary[]
  public enabled: boolean
  public theme: Theme


  public getCustomText(identifier: string): string {
    return this.names && this.names[identifier] ? this.names[identifier] : ''
  }

  // TODO: That could be extracted as mixin
  // https://www.typescriptlang.org/docs/handbook/mixins.html
  public setTheme() {
    const variables = {}
    if (this.colours) {
      for (const variableName of Object.keys(this.colours)) {
        variables[`--${variableName}-color`] = this.colours[variableName]
      }
    }

    const fonts = []
    if (this.client && this.client.bodyFontUrl) {
      const bodyFontName = `client${this.client.id}BodyFont`
      fonts.push({name: bodyFontName, url: this.client.bodyFontUrl})
      variables['--body-font'] = bodyFontName
    }
    if (this.client && this.client.headerFontUrl) {
      const headerFontName = `client${this.client.id}HeaderFont`
      fonts.push({name: headerFontName, url: this.client.headerFontUrl})
      variables['--header-font'] = headerFontName
    }

    if (this.client && this.client.backgroundImageUrl) {
      variables['--client-background'] = `url(${this.client.backgroundImageUrl})`
    } else { // LUMBA-1389
      variables['--client-background'] = 'url(assets/images/default-background.jpg)'
    }

    this.theme = new Theme(variables, fonts)
  }

  public getTheme(): Theme {
    return this.theme
  }
}
