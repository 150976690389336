import { Component, OnInit } from '@angular/core';
import { AppState } from '../app.service';
import { Client } from '../client/client.model';
import { Path } from '../path/path.model';
import { User } from '../user/user.model';

@Component({
  selector: 'side-nav-bar',
  templateUrl: './side-nav-bar.component.html',
  styleUrls: ['./side-nav-bar.component.scss']
})
export class SideNavBarComponent implements OnInit {

  client: Client
  currentUser: User
  path: Path
  isMobile: boolean

  constructor(private appState: AppState) { 
    this.init()
  }

  init(){
    this.client = this.appState.get('currentClient')
    this.currentUser = this.appState.get('currentUser')
    this.path = this.appState.get('currentPath')
    this.isMobile = this.appState.get('isMobile')
  }

  ngOnInit() {
  }


 openNav() {
    document.getElementById("mySidenav").style.width = "300px";
  }
  
 closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

}
