import { Component, Input, OnInit } from '@angular/core';
import { AppState } from '../app.service';
import { ModalController } from '@ionic/angular';
import { HomeUiService } from '../new-design/home-ui.service'
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';

@Component({
  selector: 'app-notification-confirmation-popup',
  templateUrl: './notification-confirmation-popup.component.html',
  styleUrls: ['./notification-confirmation-popup.component.scss'],
})
export class NotificationConfirmationPopupComponent implements OnInit {
  isMobile;
  @Input() time;
  confirmTime;
  pathRegistration;
  permissionStatus;
  constructor(private appState: AppState,public homeUIService: HomeUiService, private modalController: ModalController,
    private firebaseX: FirebaseX
  ) {
    this.isMobile = this.appState.get('isMobile');
    this.pathRegistration = this.appState.get("pathRegistration");
   }

  ngOnInit() {
    this.getNotificationStatus();
    setTimeout(() => {
      let time =this.time?.split(':')[0]
      let time1 = this.time?.split(":")[1];
      console.log("time and time1", time, time1);
      time = parseInt(time)
      time1 = parseInt(time1)
      if (time1 == 0) {
        time1 ="00"
      }
      if (time > 12) {
        this.confirmTime = time - 12+":"+time1+" " + "PM";
      } else if (time == 12 && time1 == 0) {
        this.confirmTime = time + ":" + "00" + " " + "PM";
      } else if (time == 12 && time1 == 30) {
        this.confirmTime = time + ":" + time1 + " " + "PM";
      } else {
        this.confirmTime = time + ":" + time1 + " " + "AM";
      }
   }, 100);
   }
  
  async getNotificationStatus() {
    this.permissionStatus = await this.firebaseX.hasPermission();
  }
  enableNotfications() {
   this.modalController.dismiss({enablePush:true});
  }
  close() {
    this.modalController.dismiss();
  }
}
