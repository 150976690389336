import { Component, Input, OnInit } from '@angular/core'
import { AppState } from '../app.service'
import { User } from '../user/user.model'
import { WalletService } from '../wallet/wallet.service'

@Component({
  selector: 'first90-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})

export class LeaderboardComponent implements OnInit {
  @Input() stats: any
  @Input() leaderboardPosition: any

  currentUser: User
  isMobile: boolean

  constructor(
    private appState: AppState,
    public walletService: WalletService
  ) {
    this.currentUser = appState.get('currentUser')
    console.log('stats',this.stats);
    this.isMobile = this.appState.get('isMobile')
    
  }

  ngOnInit() {
     }

  get position() {
    if (!this.leaderboardPosition) { return }
    return this.leaderboardPosition.replace(/[^\d]/g, '')
  }

  /**
   * From Shopify:
   *
   * https://stackoverflow.com/a/31615643
   */
  public ordinal(n) {
    const s = ['th', 'st', 'nd', 'rd'], v = n % 100
    return (s[(v - 20) % 10] || s[v] || s[0])
  }
}
