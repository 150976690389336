import { Component, OnInit } from "@angular/core";
import { MobileChatServiceService } from "../pages/mobile-chat-board/mobile-chat-service.service";

import { ToastrService } from "ngx-toastr";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { SpinnerService } from "../spinner.service";
import { ModalController, PopoverController } from "@ionic/angular";
import { UserPublicProfileComponent } from "../components/user-public-profile/user-public-profile.component";
import { I18n } from "../i18n.service";
import { HomeUiService } from "../new-design/home-ui.service";

@Component({
  selector: "app-alert-user-like-list",
  templateUrl: "./alert-user-like-list.component.html",
  styleUrls: ["./alert-user-like-list.component.scss"],
})
export class AlertUserLikeListComponent implements OnInit {
  mobileService: any;
  listOfResponse: any;
  isLoading: boolean = true;
  message: string;
  isError: boolean = false;

  constructor(
    mobileService: MobileChatServiceService,
    private spinnerService: SpinnerService,
    private toasterService: ToastrService,
    private modalController: ModalController,
    private i18n: I18n,
    public homeUiService: HomeUiService,
    private popoverController: PopoverController
  ) {
    this.mobileService = mobileService;
  }

  ngOnInit() {
    if (this.mobileService.getIsLikeList()) {
      this.getUserLikeList();
    } else {
      this.getListofUserComments();
    }
  }
  async openUserInfo(ev, userData): Promise<void> {
    const popover = await this.popoverController.create({
      component: UserPublicProfileComponent,
      event: ev,
      mode: "ios",
      translucent: false,
      componentProps: { user: userData },
    });
    await popover.present();
  }
  getUserLikeList() {
    this.mobileService
      .getUserLikeList(
        this.mobileService.getPostId(),
        this.mobileService.getMode()
      )
      .subscribe(
        (response: any) => {
          this.listOfResponse = response["data"];
          this.isLoading = false;

          if (
            typeof this.listOfResponse === "undefined" ||
            (typeof this.listOfResponse !== "undefined" &&
              this.listOfResponse.length === 0)
          ) {
            this.message = "No users found";
            this.isError = true;
          }
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.isError = true;
          this.message =
            "Server or connection issue. Please check your internet connection.";
          this.toasterService.error(
            this.i18n.t(
              "Server or connection issue. Please check your internet connection."
            )
          );
        }
      );
  }
  close() {
    this.modalController.dismiss();
  }

  getListofUserComments() {
    this.mobileService
      .getUserCommentList(this.mobileService.getRequestUserList())
      .subscribe(
        (response) => {
          this.isLoading = false;
          this.listOfResponse = response["data"];
          if (
            typeof this.listOfResponse === "undefined" ||
            (typeof this.listOfResponse !== "undefined" &&
              this.listOfResponse.length === 0)
          ) {
            this.message = "No users found";
            this.isError = true;
          }
        },
        (error) => {
          this.isLoading = false;
          this.isError = true;
          this.toasterService.error(
            this.i18n.t(
              "Server or connection issue. Please check your internet connection."
            )
          );
          this.message =
            "Server or connection issue. Please check your internet connection.";
        }
      );
  }
}
