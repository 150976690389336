import { Base } from "../shared/base"

const DEFAULT_ACTION_POINTS = '0'
const DEFAULT_IMAGE_URL = ''

export class Badge extends Base {
  init() {
    this._fields = ['id', 'badgeId', 'stringId', 'position', 'title', 'imageUrls', 'actionPoints', 'cappedSteps', 'isCapped', 
    'enabled','step']
  }

  public id: number  
  public badgeId: number  
  public stringId: string
  public position: number  
  public title: string
  public imageUrls: string
  public actionPoints: string
  public cappedSteps: string
  public isCapped: boolean
  public selectedForPath: boolean
  public step: number

  public enabled: boolean  

  imageUrlForStep(step: number) {
    if (this.imageUrls[step-1]) return this.imageUrls[step-1]
    return DEFAULT_IMAGE_URL
  }

  actionPointsForStep(step: number) {
    if (this.actionPoints[step-1]) return this.actionPoints[step-1]
    return DEFAULT_ACTION_POINTS
  }
}