import { Component, Input, Output, EventEmitter, OnInit, OnChanges, ViewChild, ElementRef, SimpleChanges } from '@angular/core'
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload'
import { environment } from '../../environments/environment'
import { AppState } from '../app.service'
import { I18n } from '../i18n.service'
import { User } from '../user/user.model'
import { UserService } from '../user/user.service'

const DEFAULT_URL = environment.apiBaseUrl + '/files'
const DEFAULT_MAX_FILE_SIZE = 10 * 1024 * 1024 // 10 MB - Accept raw images from the phone's camera
const DEFAULT_ALLOWED_MIME_TYPES = null // Workaround: Android issue with comparing types
// const DEFAULT_ALLOWED_MIME_TYPES = [
//  'image/png',
//  'image/gif',
//  'image/jpeg',
//]

class UploadConfig {
  url?: string = DEFAULT_URL
  maxFileSize?: number = DEFAULT_MAX_FILE_SIZE
  allowedMimeTypes?: string[] = DEFAULT_ALLOWED_MIME_TYPES
}

@Component({
  selector: 'picture-uploader',
  templateUrl: './picture-uploader.component.html',
  styleUrls: ['./picture-uploader.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class PictureUploaderComponent implements OnInit, OnChanges {
  @Input() config: UploadConfig = new UploadConfig()
  @Input() label: string
  @Input() type: string
  @Input() labelSelected: string = ''
  @Input() fileUrl: string
  @Output() fileUrlChange = new EventEmitter<string>()

  @ViewChild('imageContainer') imageContainer: ElementRef;
  @ViewChild('photoContainer') photoContainer: ElementRef;

  currentUser: User

  public uploader: FileUploader
  isMobileResolution: boolean
  isMobile: boolean

  constructor(
    private userService: UserService,
    private appState: AppState,
    private i18n: I18n,) { 

     this.isMobile = this.appState.get('isMobile')


    }

    onResize(event){
      console.log("inner width",window.innerWidth);
      if (window.innerWidth < 768) {
        
        this.isMobileResolution = true;
      } else {
        this.isMobileResolution = false;
      }
    }

  ngOnInit() {

    if (this.fileUrl) {
      this.setBackground(this.fileUrl)
    }
    this.uploader = new FileUploader({
      url: this.config.url,
      allowedMimeType: null,
      maxFileSize: this.config.maxFileSize,
      autoUpload: true,
      authTokenHeader: "Authorization",
      authToken: `${localStorage.getItem('token')}`,
    })
    
    this.uploader.onCompleteItem = (item: FileItem, response: any, status: any, headers: any) => {
      response = JSON.parse(response)
      this.setBackground(response['data']['url'])
      this.fileUrlChange.emit(response['data']['url'])
      console.log("item, response, headers",response)
    }
    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      console.log(item, response, headers)
    }
  }

  ngOnChanges() {
    if (this.fileUrl) {
      this.setBackground(this.fileUrl)
    }
  }

  private setBackground(url) {
    console.log('photoContainer', this.photoContainer);
    
    this.imageContainer.nativeElement.style.backgroundImage = `url('${url}')`
    console.log('profile',url);
    this.photoContainer.nativeElement.style.backgroundImage = `url('${url}')`
  }
}
