import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Filter } from '../shared/filter'
import * as query from './checklist.query'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'

import { Checklist } from './checklist.model'
import { PathChecklist } from '../path/path-checklist.model'
import { WalletService } from '../wallet/wallet.service'
import { invalidateCache } from '../utils.service'
import { ChecklistItem } from './checklist-item.model'
import { HomePageService } from '../pages/home/home-page.service'

export interface  ToggleChecklistItem{
  [toggleChecklistItem: string]: any
}

@Injectable()
export class ChecklistService {

  public toggleItem: ToggleChecklistItem

  constructor(
    private apollo: Apollo, 
    private homePageService: HomePageService,
    public walletService: WalletService) { }

  getPathList(filter: Filter = null, userId: number = null): Observable<PathChecklist[]> {
    return this.apollo
      .watchQuery<any>({ query: query.listPathChecklists, variables: { filter: filter, userId: userId } })
      .valueChanges
      .pipe(
        map(response => {
          let list: PathChecklist[] = []
          response.data.pathChecklists.forEach(element => { list.push(new PathChecklist(element)) })
          return list
        })
      )
  }

  getList(filter: Filter = null): Observable<Checklist[]> {
    return this.apollo
      .watchQuery<any>({ query: query.listChecklists, variables: { filter: filter } })
      .valueChanges
      .pipe(
        map(response => {
          let list: Checklist[] = []
          response.data.checklists.forEach(element => { list.push(new Checklist(element)) })
          return list
        })
      )
  }

  getChecklist(id: number): Observable<Checklist> {
    return this.apollo
      .watchQuery<any>({ query: query.findChecklist, variables: { id: id } })
      .valueChanges
      .pipe(
        map(response => {
          return new Checklist(response.data.checklist)
        })
      )
  }

  save(checklist: Checklist) {
    return this.apollo.mutate({
      mutation: query.updateChecklist,
      variables: {
        checklist: checklist.input()
      }
    })
  }

  toggleChecklistItem(checklistItem: ChecklistItem) {
    return this.apollo.mutate({
        mutation: query.toggleChecklistItem,
        variables: {
          checklistItemId: checklistItem.id
        },
        update: (proxy) => {
          this.homePageService.updateCompletions(proxy, `Checklist:${checklistItem.checklistId}`)
          invalidateCache(proxy, 'ROOT_QUERY.dailyParticipation')
        }
      }).pipe(
        map(response => { 
          this.toggleItem = response.data
          this.walletService.update(this.toggleItem.toggleChecklistItem.actionPoints)
          return this.toggleItem.toggleChecklistItem
        })
      )
  }
}
