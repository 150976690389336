import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'home-content',
    templateUrl: './home-content.component.html',
    styleUrls: ['./home-content.component.scss'],
})
export class HomeContentComponent implements OnInit {

    makeActive: boolean

    constructor(){}

    ngOnInit(){}

}