import { ChangeDetectorRef, Component, DoCheck, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SpinnerService } from 'src/app/spinner.service';
import { ToasterService } from 'src/app/toaster.service';
import { AlertRepLogComponent } from '../../alert-rep-log/alert-rep-log.component';
import { AppState } from '../../app.service';
import { HabitService } from '../../habit/habit.service';
import { HomeV5Service } from '../../pages/home-v5/home-v5.service';
import { Path } from '../../path/path.model';
import { RoutineInfo } from '../../routine-v2/routine-info.model';
import { RoutineV2Service } from '../../routine-v2/routine-v2.service';
import { User } from '../../user/user.model';
import { MyCommitmentsComponent } from '../my-commitments.component';
import { ModalController } from '@ionic/angular';
import { I18n } from 'src/app/i18n.service';

@Component({
  selector: 'sub-routine-info',
  templateUrl: './sub-routine-info.component.html',
  styleUrls: ['./sub-routine-info.component.scss']
})
export class SubRoutineInfoComponent implements OnInit {

  routineLevel: any
  currentPath: Path;
  currentUser: User;
  scheduledDay: number
  routineList: any
  subRoutine: any
  repCount: any
  isMobile: boolean

  constructor(private route: ActivatedRoute, 
    private appState: AppState,
    private routineService: RoutineV2Service, 
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private homeService:HomeV5Service,
    private toasterService:ToasterService,
    private i18n: I18n,
    private modalController: ModalController,
    private habitService: HabitService,
    private spinnerService: SpinnerService) {

      this.routineLevel = this.appState.get('levelData')
      this.currentPath = this.appState.get("currentPath");
      this.currentUser = this.appState.get("currentUser");
      this.scheduledDay = this.appState.get("scheduleDayForCurrentStep")
      this.subRoutine = this.appState.get('subRoutine')
      this.isMobile = this.appState.get('isMobile')
     }

  ngOnInit() {
    this.repCount = this.routineLevel.reps_commit.filter(item => item.is_completed = true)
  }

  getRoutineList(){
    this.routineList = this.currentPath.routineInfo
  }

  logaRep(level: any){
    const dialogAction = this.dialog.open(AlertRepLogComponent)
    dialogAction.afterClosed().subscribe((result) => {
      if (result == 'confirm') {
        const requestData = {
          'user_id': this.currentUser.id,
          'path_id': this.currentPath.id,
          'routine_info_id': this.subRoutine.id,
          'level_id': level.id,
          'schedule_day': this.scheduledDay,
          'feedback': localStorage.getItem('repLogText'),
        }

        this.spinnerService.on()
          this.routineService.replog(requestData).subscribe(
            (response) => {
              this.toasterService.success(this.i18n.t('Rep Logged!'))
              console.log('Response for feedback ', response)
              this.cdRef.detectChanges()
              this.getRoutineList()
              this.spinnerService.off()
            },
            (error) => {
              this.spinnerService.off()
              this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."));
              console.log('Error for feedback ', error)
            }
          )
          
      }
    })
  }
  close() {
    this.modalController.dismiss();
  }
}
