import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import mixpanel from 'mixpanel-browser';
import { AlertOptRoutineComponent } from '../alert-opt-routine/alert-opt-routine.component';
import { AppState } from '../app.service';
import { HabitService } from '../habit/habit.service';
import { HomeV5Service } from '../pages/home-v5/home-v5.service';
import { Path } from '../path/path.model';
import { RoutineV2Service } from '../routine-v2/routine-v2.service';
import { SpinnerService } from '../spinner.service';
import { ToasterService } from '../toaster.service';
import { User } from '../user/user.model';
import { HomeUiService } from '../new-design/home-ui.service';
import { AlertController, ModalController } from '@ionic/angular';
import { AnimationsService } from '../shared/animations.service';
import { I18n } from '../i18n.service';

@Component({
  selector: 'app-edit-routine',
  templateUrl: './edit-routine.component.html',
  styleUrls: ['./edit-routine.component.scss']
})
export class EditRoutineComponent implements OnInit {

  routineList: any
  currentPath: Path;
  currentUser: User;
  routineLevels: any
  isExpanded: boolean = false
  routineId: number
  form: UntypedFormGroup;
  addedLevelIds = []
  removedLevelIds = []
  isonboardingcompleted: any;
  isChecked: boolean
  levelId: any
  isMobile: boolean

  constructor(
    private routineService: RoutineV2Service,
    private cdRef: ChangeDetectorRef,
    private appState: AppState,
    private dialog: MatDialog,
    private homeService: HomeV5Service,
    private tostrService: ToasterService,
    private habitService: HabitService,
    private spinnerService: SpinnerService,
    private animationService: AnimationsService,
    private homeUiService: HomeUiService,
    public modalController: ModalController,
    public alertController: AlertController,
    private route: Router,
    private router: ActivatedRoute,
    private i18n: I18n,
    private fb: UntypedFormBuilder
  ) {
    this.isMobile = this.appState.get('isMobile')
    this.currentPath = this.appState.get("currentPath");
    this.currentUser = this.appState.get("currentUser");
    this.appState.set('isFromEdit', false)
    console.log('isFromEdit', true);
    this.levelId = this.appState.get('LevelNumber')
    if (this.levelId) {
      // console.log('appState levelId', this.levelId);
    } else {
      this.levelId = 1
      console.log('start levelId', this.levelId);
    }

    this.getOptedRoutines(this.currentUser.id)
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: this.fb.array([])
    });
  }
 async checkBoxChange(completed:boolean, routine) {
  const alert = await this.alertController.create({
    header: this.i18n.t('Are you sure?'),
    subHeader: completed?this.i18n.t('Do you want to commit to this habit?'):this.i18n.t('Do you want to stop building this habit?'),
    buttons: [
      {
        text: "No",
        cssClass:'custom-alert-textcase',
        handler:()=>{
        if(completed){
          this.getOptedRoutines(routine.user_id)
        }
        else {
          this.isChecked = true
          this.getOptedRoutines(routine.user_id)
        }
        },
        role: 'cancel',
      },
      {
        text: "Yes",
        cssClass: 'custom-alert',
        handler: () => {
         if(completed) {
          console.log('routine.schedule_day', routine.schedule_day);
                localStorage.removeItem(
                  `Toggle:Day:${routine.schedule_day}:LevelNumber:${1}:true`
                )
                if (this.currentPath.id != routine.path_id) {
                  this.appState.set('isRoutineEdited', true)
                  this.spinnerService.on()
                  this.changeSubPath(routine)
                } else {
                  this.appState.set('isHamburgerOpen',false);
                  this.homeUiService.dismissAllModals().then(()=>{
        
               
                  this.appState.set('levelUpdated', undefined)
                  this.appState.set('routine_schedule_day', routine.schedule_day)
                  this.appState.set('isRoutineEdited', true)
                  this.appState.set('LevelNumber', 1)
                  this.appState.set('isFromEdit', true)
                  this.appState.set('hidePrevScreen', true);
                  this.appState.set("allowScreenCount", undefined)
                  setTimeout(()=>{
                    this.route.navigate(['/content', routine.schedule_day], { queryParams: { startFromScreen: 'daily-content-screen-routine-levels' } })
                  },550);
                })
                }
         }
         else {
          const requestData = {
                    // 'level_id': null,
                    'user_id': routine.user_id,
                    'path_id': routine.path_id,
                    'is_reset': true,
                    'routine_info_id': routine.routine_info_id
                  }
          
                  if (requestData.user_id == 0) {
                    return true;
                  } else {
                    this.routineService.editRoutine(requestData).subscribe(
                      (response) => {
                        console.log('status', response);
                        this.cdRef.detectChanges();
                        this.getOptedRoutines(routine.user_id)
                      },
                      (error) => {
                        console.log('Error for edit routines', error)
                      })
                  }
         }
        },
      },
     
    ]  });

  await alert.present();
}


  getOptedRoutines(userId) {
    const requestData = {
      'user_id': userId
    }

    if (requestData.user_id == 0) {
      return true;
    } else {
      this.spinnerService.on()
      this.routineService.getOptedRoutines(requestData).subscribe(
        (response) => {
          this.cdRef.detectChanges();
          this.spinnerService.off()
          this.routineList = this.removeDuplicates(response.data, "routine_info_id");
          console.log('opted routines', this.routineList);
        },
        (error) => {
          console.log('Error for routines ', error)
          this.tostrService.error(this.i18n.t('No record found'))
        })
    }
  }

  newremoveDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  removeDuplicates(originalArray, prop) {
    console.log('originalArray', originalArray)
    var tempArray = [];

    tempArray = originalArray.filter(function (el) {
      return el.routine_info_id,
        el.is_opted;
    });
    console.log('newArray', tempArray)
    try{
      for (let i = 0; i < originalArray.length; i++) {
        for (let j = 0; j < tempArray.length; j++) {
          if ((tempArray[j].routine_info_id == originalArray[i].routine_info_id) && !originalArray[i].is_opted) {
            originalArray.splice(i, 1);
          }
        }
      }
      console.log('originalArray', originalArray)
      originalArray = this.newremoveDuplicates(originalArray, prop)
      console.log('originalArray', originalArray)
      return originalArray;
    }catch (o_O){
      return tempArray;
    }

  }
  private changeSubPath(routine: any) {
    const request = {
      'user_id': routine.user_id,
      'active_path_id': routine.path_id
    }

    console.log("Sub path change ", request)
    this.homeService.subpathchange(request).subscribe(
      (response) => {
        console.log("Response of path changed ", response)

        let data = response["data"];
        console.log('isOnBOARDING', this.isonboardingcompleted)
        this.homeUiService.dismissAllModals().then(()=>{
          try {
          let isOnboardContentPresent = data.onboardingSurvey || data.isIntroVideo;
          if (data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted?.length > 0 && isOnboardContentPresent) {
            let value = data['isonboardingcompleted'][0];
              this.isonboardingcompleted = value["isonboardingcompleted"];
            if ((this.isonboardingcompleted === false || this.isonboardingcompleted === null) && isOnboardContentPresent) {
              this.route.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          
              } else if (this.isonboardingcompleted === true) {
                this.appState.set('isRoutineEdited', true)
                console.log('currentDay & level', routine.schedule_day, this.levelId)
               
                this.spinnerService.off()
                this.appState.set("allowScreenCount", undefined)
                this.route.navigate(['/content', routine.schedule_day], { queryParams: { startFromScreen: 'daily-content-screen-routine-levels' } })
              }
          } else if(data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted.length===0 && isOnboardContentPresent){
              this.route.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          
            } else {
              //this.route.navigate(['/main']);
              this.appState.set('isRoutineEdited', true)
              console.log('currentDay & level', routine.schedule_day, this.levelId)
              // localStorage.removeItem(
              //   `Toggle:Day:${routine.schedule_day}:LevelNumber:${this.levelId + 1}:true`
              // )
              this.spinnerService.off()
            this.appState.set("allowScreenCount", undefined)
             this.homeUiService.checkSubpathStatus();
              this.route.navigate(['/content', routine.schedule_day], { queryParams: { startFromScreen: 'daily-content-screen-routine-levels' } })
            }
          } catch (error) {
            this.route.navigate(['/main']);
            this.tostrService.error(this.i18n.t('Server or connection issue. Please check your internet connection.'))
            console.log('onboarding video profile', error)
          }
        })
      
        this.refresh()
      }, (error) => {
        console.log("Response of path changed ", error)
        this.tostrService.error(this.i18n.t('Server or connection issue. Please check your internet connection.'))
      }
    )
  }

  refresh() {
    return new Promise(resolve => {
      // here to fetch the data and rerender the contents.
      this.appState.refreshMainComponent.emit()
      setTimeout(resolve, 0)

    })

  }
}
