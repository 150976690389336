import {Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef, HostListener, Renderer2, ViewChild, ElementRef} from '@angular/core'

import { AppState } from '../app.service'
import { Reply } from './reply.model'
import { Client } from '../client/client.model'
import { User } from '../user/user.model'
import { ReplyService } from './reply.service'
import { ToastrService } from 'ngx-toastr'
import { SessionService } from '../session/session.service'
import { CordovaService } from '../cordova.service'
import { bounceInAnimation, expandOnEnterAnimation } from "angular-animations";
import { MobileChatServiceService } from '../pages/mobile-chat-board/mobile-chat-service.service'
import { Apollo } from "apollo-angular";
import { ToasterService } from '../toaster.service'
import { ModalController, PopoverController } from '@ionic/angular'
import { UserPublicProfileComponent } from '../components/user-public-profile/user-public-profile.component'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { DomSanitizer } from '@angular/platform-browser'
import { CommentsListWebComponent } from '../components/commitments/comments-list-web/comments-list-web.component'
import { HabitCommentListWebComponent } from '../components/commitments/habit-comment-list-web/habit-comment-list-web.component'
import { I18n } from '../i18n.service'
import { HomeUiService } from '../new-design/home-ui.service'

@Component({
  selector: 'first90-reply',
  templateUrl: './reply.component.html',
  styleUrls: ['./reply.component.scss'],
  animations: [ bounceInAnimation(),
  expandOnEnterAnimation({ duration: 120 }), 
  ]
})
export class ReplyComponent implements OnInit {

  @Input() index: number
  @Input() reply: Reply
  @Input() replyToId: number
  @Input() repliesEnabled: boolean
  @Input() inverted = false
  @Input() isChatboardReply = false
  @Input() isShowLikes = false
  @Input() voteCounts: number
  @Input() postId: number
  @Input() mode;
  @Input() check =false;
  @Input() isPreview;
  @Input() repliesTree
  @ViewChild('btn') btn: ElementRef
  isCommentPosted:boolean;
  @ViewChild('textContainer') textContainer: ElementRef;
  @Output() replyTo = new EventEmitter<ReplyComponent>()
  @Output() replyFunction = new EventEmitter<ReplyComponent>();
  @ViewChild("textareaForFeeds") txtarea_post: ElementRef;
  @ViewChild('disableListener') disableListener: ElementRef;
  showMenu=false;
  maxHeight: number = 45;
  textareaContentLength=0;
  //set these to false to get the height of the expended container 
  public isCollapsed: boolean = false;
  public isCollapsable: boolean = false;

  user: User
  client: Client
  selected: boolean = false
  voteLoading: boolean = false
  state: boolean = false;
  animation=['bounceIn'];
  replyText: string = "";
  isMobile: boolean
  isLoaded=true;
  isMobileLoaded =true;
  pathRegistration;
  @Input() isHomeRoute: boolean;
  constructor(
    private appState: AppState,
    private replyService: ReplyService,
    private cdRef: ChangeDetectorRef,
    public sessionService: SessionService,
    private cordovaService: CordovaService,
    private mobileService:MobileChatServiceService,
    private toasterService:ToasterService,
    private i18n: I18n,
    private apollo : Apollo,
    public modalCtrl: ModalController,
    public popoverController: PopoverController,
    private sanitizer:DomSanitizer,
    private render: Renderer2,
    private elementRef: ElementRef,
    public homeUiService: HomeUiService
  ) {
    this.user = this.appState.get('currentUser')
    this.client = this.appState.get('currentClient')
    this.isMobile = this.appState.get('isMobile')
    this.isCommentPosted=  this.appState.get('isAnimationApplied')
    this.pathRegistration = this.appState.get("pathRegistration");
  }

  async openuserInfo(ev, userData): Promise<void> {
    const popover = await this.popoverController.create({
      component: UserPublicProfileComponent,
      event: ev,
      mode:'ios',
      translucent: false,
      componentProps: {'user': userData}
    });
    await popover.present();
   
  }
  transform(html) {
    return this.sanitizer.bypassSecurityTrustStyle(html);
  }

  checkTextareaLength() {
    let len = this.replyText?.trim();
   this.textareaContentLength =len.length;
}
  ngOnInit() {
    // if(this.check) {
    //   this.render.removeClass(this.textContainer.nativeElement, 'reply-texts');
    // }
   // this.isLoaded =false;
  
    if(this.isChatboardReply){
      this.select()
    }else{
      this.selected = this.reply.id === this.replyToId
    }
    this.isCommentPosted=  this.appState.get('isAnimationApplied')
    if(!this.isMobile) {
      this.replyService.propertyValue$.subscribe(value => {
        if(value) {
          this.selected = false;
        }
        
      });
    }
    if(this.appState.get('isReplyAnimationApplied')) {
      this.isLoaded =true;
      this.isMobileLoaded =true;
    }
    this.cdRef.detectChanges()
  }
  ngDoCheck() {
    if(this.appState.get('isMobileReply')) {
      this.isMobileLoaded =false;
     }
    if(this.appState.get('isReplyAnimationApplied')) {
      this.isLoaded =true;
      this.isMobileLoaded =true;
    }
  }
  ngAfterViewInit() {
    //  if(!this.check) {
      setTimeout(() => {
        let currentHeight = this.textContainer?.nativeElement?.offsetHeight;
        //collapsable only if the contents make container exceed the max height
         if (currentHeight > this.maxHeight) {
             this.isCollapsed = true;
             this.isCollapsable = true;
             let el = document.getElementsByClassName('text')[0]
             this.render.addClass(this.textContainer.nativeElement, 'reply-texts');
             //document.getElementById('desktopComment').classList.remove('animation-class');
             
             //el?.setAttribute("style","overflow:hidden;text-overflow: ellipsis;height:`this.maxHeight`+'px';display: -webkit-box;-webkit-line-clamp: 2; -webkit-box-orient: vertical")
         }
         if(this.isCommentPosted){
          document.getElementById('desktopComment').classList.add('animation-class');
    
          this.appState.set('isAnimationApplied',false)
        }
        },1);
  // }
    // else {
    //   this.render.removeClass(this.textContainer.nativeElement, 'reply-texts');
    //   if(this.isCommentPosted){
    //     console.log("animation-class testing");
    //     document.getElementById('desktopComment').classList.add('animation-class');
  
    //     console.log("animation-class testing",document.getElementById('desktopComment'));
    //     this.appState.set('isAnimationApplied',false)
    //   }
    // }
  

    setTimeout(() => {
        if(this.isCommentPosted){
          document.getElementById('desktopComment').classList.remove('animation-class');
      }
    },1500);
}
complexItems = this.appState.get("userlist")

format(item: any) {
  var data = item['id']
  setTimeout(() => {
    SessionService.setData(data)
    //  this.tagName_id = item['id'];
  }, 100);
  return `@${item['username'].toUpperCase()}`;
  //return item['username'].toUpperCase()
}

mentionConfig: any = {
  mentions: [
    {
      items: this.complexItems,
      labelKey: 'username',
      mentionSelect: this.format,
      dropUp: true
    }
  ]
};

// scrollTo(event) {
//   if (this.cordovaService.onCordova) {
//     console.log("click event");
//     console.log(this.replyBlock?.nativeElement.offsetTop);

//     setTimeout(() => {
//       window.scrollTo({
//         top: this.replyBlock?.nativeElement.offsetTop - 160,
//         behavior: "smooth",
//       });
//     }, 10);
//   }
// }


textExpand(val) {
  if(val){
    this.render.removeClass(this.textContainer.nativeElement, 'reply-texts');
    this.isCollapsed = false;
    this.isCollapsable = true;
  }
  else {
    this.render.addClass(this.textContainer.nativeElement, 'reply-texts');
    this.isCollapsed = true;
    this.isCollapsable = true;
  }
  this.cdRef.detectChanges();
}
  toggleVote() {
    if (this.reply.user.id === this.user.id) {
      return false
    }
    this.voteLoading = true
    
    this.bounceAnimation()      
    
    this.reply.hasVoted = !this.reply.hasVoted
    this.reply.votesCount += this.reply.hasVoted ? 1 : -1
    this.cdRef.detectChanges()
    this.replyService.voteReply(this.reply,'').subscribe(
      status => {
        this.voteLoading = false
        this.cdRef.detectChanges()
      },
      error => {},
      () => {
        this.voteLoading = false
        this.cdRef.detectChanges()
      }
    )
  }

  select() {
    this.replyService.setPropertyValue(null);
    this.selected = ! this.selected  
    if(this.selected) {
      setTimeout(()=>
      {this.txtarea_post?.nativeElement.focus();
      },500);    
      this.replyTo.emit(this)
      this.cdRef.detectChanges()
    }
    else {
      this.replyTo.emit(null)
    this.cdRef.detectChanges()
    }
  
  }

  infoComment(){
    this.selected = true
    this.replyTo.emit(this)  }

  unselect() {
    this.selected = false
    this.replyTo.emit(null)
    this.cdRef.detectChanges()
  }

  public getIcon( fileName ) {
    const extension = fileName.split( '.' ).pop()
    let icon = ''

    switch ( extension ) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        icon = 'glyphicon-picture'
        break
      case 'mp4':
      case '3gp':
      case 'mpg':
      case 'mpeg':
      case 'avi':
      case 'flv':
        icon = 'glyphicon-film'
        break
      case 'mp3':
        icon = 'glyphicon-headphones'
        break
      case '7z':
      case 'rar':
      case 'zip':
        icon = 'glyphicon-folder-open'
        break
      default:
        icon = 'glyphicon-file'
    }

    return icon
  }

  public open(url) {
    window.open(url)
  }


  @HostListener('click', ['$event'])
  onLinkClick(e) {
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        window.open(e.target.href, '_blank')
      }
    }
  }

  replyFun() {
    this.selected =false;
    this.isLoaded =false;
      this.replyFunction.emit(this);
      
  }

  bounceAnimation(){
    this.render.addClass(this.btn.nativeElement, 'bounce');
    if(this.btn.nativeElement.querySelector('ion-icon').getAttribute("name") == 'star-outline') {
      this.render.setAttribute(this.btn.nativeElement.querySelector('ion-icon'),"name", 'star')
    }
    else {
      this.render.setAttribute(this.btn.nativeElement.querySelector('ion-icon'),"name", 'star-outline')

    }
    setTimeout(() => {
      this.render.removeClass(this.btn.nativeElement, 'bounce');
    }, 200);
  }

  deletePost(id){
   console.log("repliesTree before deleting post", this.repliesTree);
    this.mobileService.deletePost(id).subscribe(
      response => {
          console.log(response);

          var test = this.appState.get('currentPath')
        this.toasterService.success(this.i18n.t('Comment deleted successfully.'))
        this.appState.set('commentDeleted', true)
        this.appState.set("deletedPostId", id);
        this.appState.set('repliesTreeObject', this.repliesTree)
           // this.refresh()
         // this.cdRef.detectChanges()
          
      },
      error =>{
        console.log(error);
      }

    )

  }


  copyMessage(val: string,comment_id: number){

    try {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.toasterService.success(this.i18n.t("Copied to clipboard!"));
      this.deletePost(comment_id)
    }catch{
      this.toasterService.warning(this.i18n.t("Not able to copy and delete!"));
    }
    
  }

  refresh() {
    return new Promise(resolve => {
      // here to fetch the data and rerender the contents.
      this.appState.refreshMainComponent.emit()
      setTimeout(resolve, 0)
      
    })
  }

  async close() {
    const closeModal: string = "Modal Closed";
    await this.modalCtrl.dismiss(closeModal);
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.disableListener?.nativeElement.contains(event.target)) {
      this.showMenu = !this.showMenu;
    }
    else {
      this.showMenu =false;
    }
  }

}
