import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SessionService } from 'src/app/session/session.service';

@Component({
  selector: 'app-post-community',
  templateUrl: './post-community.component.html',
  styleUrls: ['./post-community.component.scss'],
})
export class PostCommunityComponent implements OnInit {
  @Input() item: any;
  @Output() onShowCommentList = new EventEmitter<any>();
  @Output() onTextClick = new EventEmitter<any>();
  @Output() onOpenPost = new EventEmitter<any>();
  @Output() onToggleLike = new EventEmitter<any>();
  @Output() onShowLikeList = new EventEmitter<any>();
  @Output() onComment = new EventEmitter<any>();
  constructor(
    public sessionService: SessionService
  ) { }

  ngOnInit() { }
  showCommentList(clickItem) {
    this.onShowCommentList.emit({
      id: clickItem.id
    })
  }
  clickOnText(event, clickItem) {
    this.onTextClick.emit({
      item: clickItem,
      evn: event
    })
  }
  doLikeUnlike() {
    this.onToggleLike.emit({
      item: this.item
    })
  }
  emitShowLikeList(){
    this.onShowLikeList.emit(this.item.id);
  }
  emitComment(event){
    this.onComment.emit({
      item: this.item,
      evn: event
    })
  }
  openPost(evn){
    console.log('event', evn);
    this.onOpenPost.emit({
      item: this.item,
      evn: evn
    })
  }
}
