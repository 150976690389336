import { environment } from '../../environments/environment'
import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import * as query from './content-survey.query'
import { ContentSurvey } from './content-survey.model'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { HttpClient, HttpParams } from '@angular/common/http'

import { DefaultResponse } from '../shared/base.service'
import { AppState } from '../app.service'
import gql from 'graphql-tag';
import { WalletService } from '../wallet/wallet.service'
import { invalidateCache } from '../utils.service'
import { HomePageService } from '../pages/home/home-page.service'

const API_URL: string = environment.apiBaseUrl
const SURVEYS_URL: string = '/content-surveys'

export interface AnswerContentSurvey{
  [answerContentSurvey: string]: any
}
@Injectable()
export class ContentSurveyService {

  public answerSurvey: AnswerContentSurvey
  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private appState: AppState,
    private homePageService: HomePageService,
    public walletService: WalletService
  ) { }

  getList(pathId) {
    return this.http
      .get<DefaultResponse>(API_URL + SURVEYS_URL, { params: new HttpParams().set('path_id', pathId) })
      .toPromise()
      .then(response => {
        return response.data
      })
  }

  getContentSurvey(id: number): Observable<ContentSurvey> {
    return this.apollo
      .watchQuery<any>({ query: query.findContentSurvey, variables: { id: id } })
      .valueChanges
      .pipe(
        map(response => {
          return new ContentSurvey(response.data.contentSurvey)
        })
      )
  }

  save(contentSurvey: ContentSurvey) {
    return this.apollo.mutate({
      mutation: query.updateContentSurvey,
      variables: {
        contentSurvey: contentSurvey.input()
      }
    })
  }

  answerContentSurvey(contentSurvey: ContentSurvey, questionId: number[], optionId) {
    return this.apollo.mutate({
      mutation: query.answerContentSurvey,
      variables: {
        optionIds: optionId
      },
      optimisticResponse: {
        __typename: 'Mutation',
        answerContentSurvey: {
          answer: null,
          actionPoints: null
        },
      },
      update: (proxy, { data: { answerContentSurvey } }) => {
        this.homePageService.updateCompletions(proxy, `ContentSurvey:${contentSurvey.id}`)
        proxy.writeFragment({
          id: `ContentSurvey:${contentSurvey.id}`,
          fragment: gql`
            fragment answer on ContentSurvey {
              answer {
                optionId
                correctOptionId
                isCorrect
                stats
              }
            }
          `,
          data: {answer: answerContentSurvey?.answer, __typename: 'ContentSurvey'}
        });
        const questions = contentSurvey.contentSurveyQuestions
        const obj = this.appState.get('currentPath').update('contentSurveys', contentSurvey.id, {contentSurveyQuestions: questions})
           this.walletService.update(this.walletService.actionPoints.getValue() + obj.actionPoints)
           invalidateCache(proxy, 'ROOT_QUERY.dailyParticipation')
        obj.afterInit()
      }}).pipe(
        map(response => {
          this.answerSurvey = response.data
          return this.answerSurvey.answerContentSurveyV2
        })
      )
  }
}
