import {ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CordovaService } from '../cordova.service';
import * as moment from 'moment'
import * as AddToCalendar from '../../../vendor/add-to-calendar-buttons/add-to-calendar.js'
import { HomeV5Service } from '../pages/home-v5/home-v5.service';
import { AppState } from '../app.service';
import { ModalController } from '@ionic/angular';
import { HomeUiService } from '../new-design/home-ui.service';
declare var $: any


export interface CalendarParams {
  title: String,
  startDate: Date
  endDate: Date,
  eventLocation: String,
  notes: String
}


@Component({
  selector: 'app-alert-webaction-calendar',
  templateUrl: './alert-webaction-calendar.component.html',
  styleUrls: ['./alert-webaction-calendar.component.scss']
})
export class AlertWebactionCalendarComponent implements OnInit {

  @ViewChild ('calendarButtonsContainer') calendarButtonsContainer: ElementRef


  hasNativeCalendar: boolean  // LUMBA-1421
  cloudCalendarExpanded: boolean // LUMBA-1421
  cloudCalendarSet: boolean // LUMBA-1421
  calendarParams: CalendarParams
  currentPath: any;

  constructor(private cordovaService: CordovaService, private modalController: ModalController,
    private homeService: HomeV5Service,private appState: AppState, public homeUIService: HomeUiService,
    private cdRef: ChangeDetectorRef, ) {
    
    const w: any = window
    this.hasNativeCalendar = this.cordovaService.onCordova && w && w.plugins && w.plugins.calendar
    this.currentPath = this.appState.get("currentPath");

  }

  ngOnInit() {
    this.initCalendarButton()
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  initCalendarButton() {
    
    
    if (this.hasNativeCalendar) {
      return
    }
    this.calendarParams = this.homeService.getCalendarData()
    const daysCount = this.currentPath.daysOfContent * 2 
    const endDateTime = moment(new Date(), 'HH:mm').add(daysCount, 'day').toDate()
    const calendarButtons = AddToCalendar.addToCalendar({
      options: {
        class: 'desktop-schedule-icon',
        id: 'add-calendar-buttons-id'
      },
      data: {
        title: this.calendarParams.title,
        start: this.calendarParams.startDate,
        // timezone: America/Los_Angeles,					// converts the time to the IANA timezone
        end: this.calendarParams.endDate,
        // duration: 120,                            // Event duration (IN MINUTES)
        // allday: true,
        endDate: endDateTime,
        // Override end time, duration and timezone, triggers 'all day'
        address: this.calendarParams.eventLocation,
        description: this.calendarParams.notes
      }
    })
    document.querySelector('.calendar').appendChild(calendarButtons);

  }

  close() {
    this.modalController.dismiss();
  }


  prepareCalendarParams(title:any,msg: String) {
    const start = moment(new Date(), 'HH:mm')
    const end = moment(new Date(), 'HH:mm').add(10, 'minute')
    this.calendarParams = {
      startDate: start.toDate(),
      endDate: end.toDate(),
      title: title, //updated title
      eventLocation: '',
      notes: msg
    }


}

}

