import { Component, Input, OnInit} from '@angular/core'

import { AppState } from '../app.service'
import { Path } from '../path/path.model'
import { PathQuestion } from '../path/path-question.model'
import { QuestionService } from './question.service'
import { Filter } from '../shared/filter'

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'first90-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.scss']
})
export class QuestionListComponent implements OnInit {

  @Input() inHome: boolean = false

  regularQuestions: PathQuestion[] = []
  pinnedQuestions:  PathQuestion[] = []

  path: Path
  enabled: boolean
  nameSYT: string
  nameTAI: string

  constructor(
    private questionService: QuestionService,
    private appState: AppState
  ) {
    this.path = this.appState.get('currentPath')
    this.isEnabled()
    this.initNames()
  }

  ngOnInit() {
    this.questionService.getPathList(this.getFilter()).subscribe(pathQuestions => {
      this.regularQuestions = pathQuestions.filter(pathQuestion => pathQuestion.question.isPinned === false)
      this.pinnedQuestions  = pathQuestions.filter(pathQuestion => pathQuestion.question.isPinned === true)
    })
  }

  private isEnabled() {
    this.enabled = this.path.enabledAttributes['questions']
  }

  private initNames() {
    this.nameSYT = this.path.getCustomText('h_syt')
    this.nameTAI = this.path.getCustomText('h_tai')
  }

  private getFilter(): Filter {
    const filter = new Filter()
    filter.q = { path_id_eq: this.path.id }
    return filter
  }
}
