import { Base } from '../shared/base'
import { Schedulable } from '../shared/schedulable'
import { TextScreen } from '../text-screen/text-screen.model'
import { ContentSurveyQuestion } from './content-survey-question.model'

export interface ContentSurveyStats {
  correctAnswersCount: number
  answersCount: number
  questionsCount: number
  percentage?: number
  ids: number[]
  labels: string[]
  data: number[]
}


export interface ContentSurveyQuestionAnswer {
  optionId: number
  correctOptionId: number
  isCorrect: boolean
  stats: ContentSurveyStats
}

export class ContentSurvey extends Base implements Schedulable {

  init() {
    this._fields = ['id', 'title', 'rationale',
      'startDay', 'endDay', 'isMandatory', 'enabled', 'actionPoints',
      'preScreens', 'postScreens',
      'contentSurveyQuestions', 'status', 'answer', 'screenOrder', 'isCompleted']

    this._nestedFields = {
      contentSurveyQuestions: ContentSurveyQuestion
    }

    this.contentSurveyQuestions = []
    this.preScreens = []
    this.postScreens = []
    this.screenOrder =  0
  }

  afterInit() {
    this.preScreens = this.preScreens || []
    this.postScreens = this.postScreens || []
    const stats = this.getStats()
    if (this.isMandatory && stats.answersCount === stats.questionsCount) {
      this.isCompleted = true
    } else if (!this.isMandatory && stats.answersCount > 0) {
      this.isCompleted = true
    }
  }

  public id: number
  public title: string
  public rationale: string
  public contentSurveyQuestions: ContentSurveyQuestion[]
  public startDay: number
  public endDay: number
  public screenOrder: number
  public isMandatory: boolean
  public isCompleted: boolean
  public enabled: boolean
  public actionPoints: number
  public stats: any
  public status: string
  public answer: ContentSurveyQuestionAnswer
  public preScreens: TextScreen[]
  public postScreens: TextScreen[]

  getTitle() {
    return 'Daily Survey'
  }

  getTitleKey() {
    return 's_pq'
  }

  getDescription() {
    return this.title
  }

  getColorClass() {
    return 'stat-color-1'
  }

  completeBy() {
    return 'ByType'
  }

  getStats() {
    const questions =  this.contentSurveyQuestions
    const stats = {
      answersCount: questions.filter(item => item.answer).length,
      correctAnswersCount: questions.filter(item => item.answer && item.answer.isCorrect).length,
      questionsCount: questions.length,
      percentage: 0
    }
    stats.percentage = Math.floor( stats.correctAnswersCount * 100 / stats.questionsCount)
    return stats
  }
}
