import { Component, DoCheck, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'underscore';
import { AppState } from '../app.service';
import { ContentSurvey, ContentSurveyStats } from '../content-survey/content-survey.model';
import { CordovaService } from '../cordova.service';
import { RoutineService } from '../daily-content-v2/routine.service';
import { Habit } from '../habit/habit.model';
import { HabitService } from '../habit/habit.service';
import { Path } from '../path/path.model';
import { Poll } from '../poll/poll.model';
import { Question } from '../question/question.model';
import { StatService } from '../stat/stat.service';
import { User } from '../user/user.model';
import { SnoozedHabit } from './daily-steps.model';
import { DailyStepsService } from './daily-steps.service';
import { SwiperOptions } from 'swiper';
import { HomeV5Service } from '../pages/home-v5/home-v5.service';
import { MatDialog } from '@angular/material/dialog';
import { InoutScreen } from '../inout-screen/inout-screen.model';
import { Document } from '../document/document.model';
import { ToasterService } from '../toaster.service';
import { I18n } from '../i18n.service';
import { RoutineInfo } from '../routine-v2/routine-info.model';

export interface CurrentPathRegistration {
  [pathRegistration: string]: any;
}

declare var $: any

@Component({
  selector: 'app-daily-steps',
  templateUrl: './daily-steps.component.html',
  styleUrls: ['./daily-steps.component.scss'],

})
export class DailyStepsComponent implements OnInit, DoCheck {

  public swiperConfig: SwiperOptions = {
    slidesPerView: 1.25,
    spaceBetween: 0,
    navigation: true,
    centeredSlides: true,
  }


  @ViewChild('modalSnoozed') modalSnoozed: ElementRef
  @Input() 'visible': boolean // LUMBA-1312
  @Input() 'showReplayButton': boolean // LUMBA-1312
  @Input() showUnlockButton: boolean
  @Input() showPrev: boolean // LUMBA-1312

  currentPath: Path
  currentUser: User
  currentDay: number
  maxAllowedScheduleDay: number  // LUMBA-1301
  poll: Poll
  survey: ContentSurvey
  document: Document
  question: Question
  habit: Habit
  routine: RoutineInfo
  dailySteps = []
  firstSurveyStats: ContentSurveyStats
  finalSurveyStats: ContentSurveyStats
  modalSnoozedHabit: SnoozedHabit
  messageDailySteps = 0;
  messageDailyStepsTotal = 0
  index: number
  messageTotalActionPoints = 0
  titleOne: string
  titleTwo: string
  titleThree: string
  titleOfTask: string
  scheduledStep: any
  routineList: any[]
  routineVersion: boolean
  enabledAttributes: any
  isMobile: boolean
  inoutScreens: any
  dayHasContent: boolean
  activeDay: number
  scheduleDays: number[]
  lengthOfSnoozed: number
  dayNumber: number
  showCloseoutWelcomeScreen: boolean
  activeCloseoutWelcomeScreen: boolean // LUMBA-1312
  scheduleDay: number
  currentDayStarted: boolean
  dayHasIncompleteMandatory: boolean
  currentDayCompleted: boolean
  showContinueButton: boolean
  showBeginButton: boolean
  showCompletedMessage: boolean
  currentDayTitle: string
  currentDaySubtext: string
  allContentCompleted: boolean

  mainTitle: string
  subTitle: string
  step1: string
  step2: string
  step3: string
  step4: string
  step5: string
  questionData: Question[]
  documentData: Document[]
  pollData: any[]
  surveyData: any[]
  hadbitData: Habit[]
  inoutScreenData: InoutScreen[]
  stepsComplete = 0
  stepsCompleteTotal = 0
  emailTeaser: any
  stepsArray: any
  currentStep: any
  totalSteps: any
  pathRegistration: any;
  durationOfTask: any
  inoutScreen: InoutScreen[]

  constructor(
    private appState: AppState,
    private router: Router,
    private habitService: HabitService,
    private statService: StatService,
    private cordovaService: CordovaService, // LUMBA-1384
    private dailyStepsService: DailyStepsService,
    public route: ActivatedRoute,
    private dialog: MatDialog,
    private homev5Service: HomeV5Service,
    private routineService: RoutineService,
    private toasterService: ToasterService,
    private i18n: I18n
  ) {

    this.maxAllowedScheduleDay = this.appState.get('daysOfContent')
    this.init()
  }

  ngDoCheck() {
    let screensViewed = this.appState.get('screensViewed')
    if (screensViewed) {
      screensViewed = this.appState.get('screensViewed')
      let steps = screensViewed.screensViewed
      this.stepsArray = this.homev5Service.removeDuplicates(steps, "step");
      this.calculateSteps(this.stepsArray)
    } else {
      let steps = this.pathRegistration.screensViewed
      this.stepsArray = this.homev5Service.removeDuplicates(steps, "step");
      this.calculateSteps(this.stepsArray)
    }


  }

  init() {
    this.currentUser = this.appState.get('currentUser')
    this.currentPath = this.appState.get('currentPath')
    this.currentDay = this.appState.get('currentScheduleDay')
    this.scheduleDay = +this.appState.get('currentScheduleDay')
    this.pathRegistration = this.appState.get('pathRegistration')
    this.activeDay = this.scheduleDay
    this.scheduledStep = this.appState.get('scheduleDayForCurrentStep')
    this.isMobile = this.appState.get('isMobile')

    if (!this.pathRegistration.screensViewed) {
      let steps = this.appState.get('stepsArray')
      this.stepsArray = this.homev5Service.removeDuplicates(steps, "step");
      this.calculateSteps(this.stepsArray)
    } else {
      let steps = this.pathRegistration.screensViewed
      this.stepsArray = this.homev5Service.removeDuplicates(steps, "step");
      this.calculateSteps(this.stepsArray)
    }
    // this.showPrev = !!this.homev5Service.checkPrevDayCompleted(
    //   this.pathRegistration,
    //   this.currentDay - 1,
    //   this.currentUser,
    //   this.currentPath
    // )

    this.enabledAttributes = this.appState.get("routineVersion")
    if (this.enabledAttributes.routineVersion) {
      this.routineVersion = this.enabledAttributes.routineVersion
    } else {
      this.routineVersion = false
    }

    this.currentDayStarted = !!localStorage.getItem(
      `Toggle:User:${this.currentUser.id}:DayProgress:${this.currentPath.id}:${this.scheduleDay}`
    )

    this.currentDayCompleted = !!localStorage.getItem(
      `Toggle:User:${this.currentUser.id}:DayCompleted:${this.currentPath.id}:${this.scheduleDay}`
    )

    this.dayHasIncompleteMandatory = this.currentPath.checkMandatoryForDay(
      this.scheduleDay
    ) // LUMBA-1317
    this.showCloseoutWelcomeScreen = this.isLastCurrentDayCompleted

    this.currentDayTitle = this.currentPath.getHomepageTitleForDay(this.scheduleDay)
    this.currentDaySubtext = this.currentPath.getHomepageSubtextForDay(
      this.scheduleDay
    )
    this.dayHasContent = this.currentPath.checkContentForDay(this.scheduleDay)
    // Instead of maxAllowedScheduled we added scheduledStep cause maxAllowedScheduleDay is nothing but total day of content

    // Check whether localdata is present or not if not then check how many steps are completed
    if (!this.currentDayStarted || !this.currentDayCompleted) {
      let stepsArray = this.homev5Service.removeDuplicates(this.pathRegistration.screensViewed, "step");
      stepsArray.forEach(element => {
        if (element.step == this.scheduleDay && element.visitedScreens < element.totalScreens) {
          this.currentDayStarted = true
        } else if (element.step == this.scheduleDay && element.visitedScreens == element.totalScreens) {
          this.currentDayStarted = true
          this.currentDayCompleted = true
        }
      });
    }

    if (this.scheduleDay === this.scheduledStep) {
      if (this.showCloseoutWelcomeScreen) {
        if (this.scheduleDay < this.currentPath.daysOfContent) {
          // LUMBA-1435
          this.activeDay = this.currentPath.daysOfContent + 1
        } else {
          this.activeDay = this.scheduleDay + 1
        }
        this.activeCloseoutWelcomeScreen = true
      }
      this.showCompletedMessage =
        !this.dayHasIncompleteMandatory && this.dayHasContent

        this.showContinueButton = this.homev5Service.checkScreenCount(
          this.pathRegistration,
          this.scheduleDay
        )
        this.showReplayButton = this.homev5Service.checkReplayForNonMand(
          this.pathRegistration,
          this.scheduleDay
        )
        this.showBeginButton = this.homev5Service.checkPrevDayCompletedForNonMand(
          this.pathRegistration,
          this.scheduleDay,
          this.currentUser,
          this.currentPath,
          this.currentDayStarted
        )

    } else {
      this.showCompletedMessage = this.dayHasContent
      this.showContinueButton = false
      this.showReplayButton = this.dayHasContent
      this.showBeginButton = false
    }

    this.poll = this.findForCurrentDay(this.currentPath.polls)
    this.survey = this.findForCurrentDay(this.currentPath.contentSurveys)
    this.inoutScreen = this.findForCurrentDay(this.currentPath.inoutScreens)
    const firstSurvey = this.statService.findFirstSurvey(this.currentPath.contentSurveys, this.currentDay);
    const finalSurvey = this.statService.findFinalCompletedSurvey(this.currentPath.contentSurveys);
    if (firstSurvey) {
      this.firstSurveyStats = firstSurvey.getStats()
    }
    if (finalSurvey) {
      this.finalSurveyStats = finalSurvey.getStats()
    }
    this.document = this.findForCurrentDay(this.currentPath.gridDocuments)
    this.question = this.findForCurrentDay(this.currentPath.gridQuestions)
    this.habit = this.findForCurrentDay(this.currentPath.habits)
    this.routine = this.findForCurrentDay(this.currentPath.routineInfo)

    this.inoutScreens = this.currentPath.inoutScreens;

    this.dailySteps = [
      {
        kind: 'poll',
        title: 'h_ds_p',
        icon: 'glyphicon glyphicon-question-sign'
      },
      {
        kind: 'document',
        title: 'h_ds_d',
        icon: 'glyphicon glyphicon-list'
      },
      {
        kind: 'question',
        title: 'h_ds_q',
        icon: 'glyphicon glyphicon-comment'
      },
      {
        kind: 'survey',
        title: 'h_ds_s',
        icon: 'glyphicon glyphicon-comment'
      },
      {
        kind: 'habit',
        title: 'h_ds_h',
        icon: 'glyphicon glyphicon glyphicon-list'
      },
      {
        kind: 'routine',
        title: 'h_ds_r',
        icon: 'glyphicon glyphicon glyphicon-list'
      }
    ]
    this.dailySteps.forEach(s => this.fillParams(s))
    this.dailySteps.sort((a, b) => a.order - b.order)
    this.setSnoozedHabits()
    this.checkCurrentDayCompleted()
    this.checkUnlockCurrentDayCompleted();
    this.checkAllNonMandatory();
    this.taskProgress()
    this.subStepsCompleted()

  }

  calculateSteps(stepsArray: any[]) {
    if (stepsArray && stepsArray.length >= 1) {
      try {
        stepsArray.forEach(element => {
          if (element.step === this.currentDay) {
            this.currentStep = element.visitedScreens
            this.totalSteps = element.totalScreens
            throw new Error("steps error");
          } else {
            this.currentStep = 0
            this.totalSteps = 0
          }
        });
      } catch (error) { }
    }
  }


  getTodaysTask() {
    if (!_.isUndefined(this.currentPath.inoutScreens[this.currentDay - 1])) {
      this.titleOfTask = this.currentPath.inoutScreens[this.currentDay - 1].homepageSubtext.text;
      return this.titleOfTask;
    }
  }

  getTodaysEmailTeaser() {
    if (!_.isUndefined(this.currentPath.inoutScreens[this.currentDay - 1])) {
      this.emailTeaser = this.currentPath.inoutScreens[this.currentDay - 1].emailTeaser;
      return this.emailTeaser;
    }
  }

  getTaskDurations() {
    if (!_.isUndefined(this.inoutScreens[this.currentDay - 1])) {

      if (this.inoutScreens[this.currentDay - 1].stime && this.inoutScreens[this.currentDay - 1].etime) {
        this.durationOfTask = this.inoutScreens[this.currentDay - 1].stime + " - " + this.currentPath.inoutScreens[this.currentDay - 1].etime;
        return this.durationOfTask;
      } else if (!this.inoutScreens[this.currentDay - 1].stime && this.inoutScreens[this.currentDay - 1].etime) {
        this.durationOfTask = this.inoutScreens[this.currentDay - 1].etime;
        return this.durationOfTask;
      } else {
        return "5 - 10";
      }
    } else {
      return "5 - 10";
    }
  }

  fillParams(item: any) {

    const source = this[item.kind]
    if (source) {
      item.exists = true
      item.isCompleted = source.isCompleted
      item.order = source.screenOrder
      item.isMandatory = source.isMandatory
      item.actionPoints = source.actionPoints ? source.actionPoints : source.answer ? source.answer.actionPoints : 0
      item.screenId = `daily-content-screen-${item.kind}`
    } else {
      item.exists = false
      item.isCompleted = false
      item.isMandatory = false
      item.order = 0
      item.actionPoints = 0
    }
    return item
  }


  taskProgress() {
    this.messageTotalActionPoints = 0
    for (let val of this.dailySteps) {
      if (val.isCompleted) {
        this.messageDailySteps += 1
      }
      if (val.actionPoints) {
        this.messageTotalActionPoints += val.actionPoints
      }
    }
    this.messageDailyStepsTotal = this.dailySteps.length
  }


  checkCurrentDayCompleted() {
    if (this.dailySteps.filter(s => s.exists && !s.isCompleted).length) {

      localStorage.removeItem(`Toggle:User:${this.currentUser.id}:DayCompleted:${this.currentPath.id}:${this.currentDay}`)
    } else {

      localStorage.setItem(`Toggle:User:${this.currentUser.id}:DayCompleted:${this.currentPath.id}:${this.currentDay}`, 'yes')
    }
  }

  checkUnlockCurrentDayCompleted() {

    let isMandatoryArray = this.dailySteps.filter(s => s.exists && s.isMandatory);
    let isMandCompletedArray = this.dailySteps.filter(s => s.exists && s.isMandatory && s.isCompleted);
    if (isMandatoryArray.length > 0 && (isMandatoryArray.length == isMandCompletedArray.length)) {
      localStorage.setItem(`Toggle:User:${this.currentUser.id}:DayUnlockCompleted:${this.currentPath.id}:${this.currentDay}`, 'yes')
    } else {
      localStorage.removeItem(`Toggle:User:${this.currentUser.id}:DayUnlockCompleted:${this.currentPath.id}:${this.currentDay}`)
    }
  }

  checkAllNonMandatory() {
    let isNonMandatoryArray = this.dailySteps.filter(s => s.exists && !s.isMandatory);
    let isAllTaskArray = this.dailySteps.filter(s => s.exists);

    if (isNonMandatoryArray.length === isAllTaskArray.length) {
      localStorage.setItem(`Toggle:User:${this.currentUser.id}:checkNonMandatory:${this.currentPath.id}:${this.currentDay}`, 'yes')
    } else {
      localStorage.removeItem(`Toggle:User:${this.currentUser.id}:checkNonMandatory:${this.currentPath.id}:${this.currentDay}`)
    }
  }

  setSnoozedHabits() {
    this.currentPath.habits.sort((a, b) => a.endDay - b.endDay)
    this.dailyStepsService.setSnoozedSteps(
      this.currentPath.habits
        .filter(item => item.scheduledLater
          // hided the this condition which show the snooz action above the completed day
          //  && item.endDay < this.currentDay
          && item.confirmation < 1) // LUMBA-1360, // LUMBA-1445
        .map(habit => {
          return {
            id: habit.id,
            dayNum: habit.startDay, // LUMBA-1307
            title: habit.getTitle(),
            text: habit.text,
            actionPoints: habit.actionPoints,
            screenId: 'daily-content-screen-habit'
          }
        }
        )
    )
  }

  getSnoozedSteps() {
    return this.dailyStepsService.getSnoozedSteps()
  }

  removeFromSnoozed(habit_id) {
    this.dailyStepsService.removeFromSnoozed(habit_id)
  }

  findForCurrentDay(collection: any): any {
    return collection.find(item => item.startDay <= this.currentDay && this.currentDay <= item.endDay)
  }
  findFirstSurvey(collection: any): any {
    return collection.find(item => item.isCompleted && item.startDay <= this.currentDay)
  }

  findFinalSurvey(collection: any): any {
    return collection.reverse().find(item => item.isCompleted)
  }

  openDailyContent(step) {
    if (!this.showUnlockButton) {
      localStorage.removeItem(`Toggle:User:${this.currentUser.id}:DayProgress:${this.currentPath.id}:${this.currentDay}`)
      this.router.navigate(['/content', this.currentDay], { queryParams: { startFromScreen: step.screenId } })
    }
  }

  openSnoozedContent(step) {
    this.modalSnoozedHabit = step
    $(this.modalSnoozed.nativeElement).modal('show')
  }

  markCompleted(habit_id: number) {
    this.habitService.confirm(habit_id).subscribe(
      response => {
      },
      error => {
      },
      () => {
        localStorage.setItem(`Toggle:User:${this.currentUser.id}:HabitConfirmed:${habit_id}`, 'yes')
        localStorage.removeItem(`Toggle:User:${this.currentUser.id}:HabitScheduled:${habit_id}`)
        this.removeFromSnoozed(habit_id)
        $(this.modalSnoozed.nativeElement).modal('hide')
      })
  }

  ngOnInit() {
    this.routineList = this.appState.get('routines')
    if (!this.routineVersion && !this.routineList || this.routineList && this.routineList.length === 0) {
      this.routineService.getList(this.currentUser.id, this.currentPath.id)
    }
  }

  ngAfterViewInit() {

    try {
      if (!this.isMobile) {
        document.getElementById('mainDailyStep').classList.add('desktop');
      }
      $(this.modalSnoozed.nativeElement).on('shown.bs.modal', () => {
        $('.modal-backdrop').click(() => { $(this.modalSnoozed.nativeElement).modal('hide') }) // LUMBA-1426
      })
    } catch (o_O) {

    }

  }


  // LUMBA-1384
  @HostListener('click', ['$event'])
  onLinkClick(e) {
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        window.open(e.target.href, '_blank')
      }
    }
  }

  setDataToAllTask() {
    this.mainTitle = this.currentPath.names.h_tc
    this.subTitle = this.currentPath.names.h_ts

    this.step1 = this.currentPath.names.h_ds_p
    this.step2 = this.currentPath.names.h_ds_d
    this.step3 = this.currentPath.names.h_ds_q
    this.step4 = this.currentPath.names.h_ds_h
    this.step5 = this.currentPath.names.h_ds_s

    this.questionData = this.currentPath.gridQuestions
    this.hadbitData = this.currentPath.habits
    this.documentData = this.currentPath.gridDocuments
    this.pollData = this.currentPath.polls
    this.inoutScreenData = this.currentPath.inoutScreens
    this.surveyData = this.currentPath.contentSurveys

  }


  sortArrayObject() {
    this.pollData.sort((a, b) =>
      a.startDay < b.startDay ? -1 : a.startDay > b.startDay ? 1 : 0
    )
    this.hadbitData.sort((a, b) =>
      a.startDay < b.startDay ? -1 : a.startDay > b.startDay ? 1 : 0
    )
    this.documentData.sort((a, b) =>
      a.startDay < b.startDay ? -1 : a.startDay > b.startDay ? 1 : 0
    )
    this.questionData.sort((a, b) =>
      a.startDay < b.startDay ? -1 : a.startDay > b.startDay ? 1 : 0
    )

    this.surveyData.sort((a, b) =>
      a.startDay > b.startDay ? -1 : a.startDay < b.startDay ? 1 : 0
    )

  }

  get isLastCurrentDayCompleted(): boolean {

    const lastDayOfContent = this.currentPath.lastDayOfContent()
    var value = !!this.homev5Service.checkPrevDayCompleted(
      this.pathRegistration,
      lastDayOfContent,
      this.currentUser,
      this.currentPath
    )

    return value
  }

  subStepsCompleted() {

    this.stepsComplete = 0;
    this.dailySteps.forEach(element => {
      if ((element.isCompleted && !element.isMandetory) || (element.isCompleted && element.isMandetory)) {
        this.stepsComplete++
      }
    });
    this.stepsCompleteTotal = this.dailySteps.length
  }

}
