import { Component, Input, HostListener, ChangeDetectorRef, Renderer2, ViewChild, ElementRef, OnInit } from '@angular/core'
import { AppState } from '../app.service'
import { Comment } from './comment.model'
import { Client } from '../client/client.model'
import { User } from '../user/user.model'
import { SessionService } from '../session/session.service'
import { CordovaService } from '../cordova.service'
import { ReplyService } from '../reply/reply.service'
import { MobileChatServiceService } from '../pages/mobile-chat-board/mobile-chat-service.service'
import { ToastrService } from 'ngx-toastr'
import { Apollo } from 'apollo-angular'
import { UserPublicProfileComponent } from '../components/user-public-profile/user-public-profile.component'
import { PopoverController } from '@ionic/angular'
import { MatMenu, MatMenuTrigger } from '@angular/material/menu'
import { I18n } from '../i18n.service'

@Component({
  selector: 'first90-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {
  @Input() inverted = false
  @Input() comment: Comment
  @Input() voteCounts: number
  @Input() repliesTree;
  @Input() postId: number
  @Input() check;
  @ViewChild('btn') btn: ElementRef;
  @ViewChild('textContainer') textContainer: ElementRef;
  @ViewChild('disableListener') disableListener: ElementRef

  isMobile: boolean
  user: User
  client: Client
  isCommentPosted
  showMenu =false;
  selected: boolean = false
  voteLoading: boolean = false
  state: boolean = false;
  maxHeight: number = 45;

  //set these to false to get the height of the expended container 
  public isCollapsed: boolean = false;
  public isCollapsable: boolean = false;
  constructor(private appState: AppState, public sessionService:SessionService,private cordovaService: CordovaService,  private cdRef: ChangeDetectorRef,private replyService: ReplyService,
    private mobileService:MobileChatServiceService,
    private toasterService:ToastrService,
    private apollo:Apollo,
    private popoverController:PopoverController,
    private render: Renderer2,
    private i18n: I18n,
    private elementRef: ElementRef
    ) {
    this.client = this.appState.get('currentClient')
    this.user = this.appState.get('currentUser')
    this.isMobile = this.appState.get('isMobile')
    this.isCommentPosted=  this.appState.get('isReplyAnimationApplied')
    
   
  }
  ngOnInit(): void {
    // if(this.check) {
    //   let ele =document.getElementsByClassName('comment-box')[0];
    //   console.log("ELELEELLE", ele);
    //   ele.setAttribute("style","display:block !important;");

    // }
    this.isCommentPosted=  this.appState.get('isReplyAnimationApplied')
  }
  async openUserInfo(ev, userData): Promise<void> {
    const popover = await this.popoverController.create({
      component: UserPublicProfileComponent,
      event: ev,
      mode:'ios',
      translucent: false,
      componentProps: {'user': userData}
    });
    await popover.present();
   
  }
  public getIcon( fileName ) {
    const extension = fileName.split( '.' ).pop()
    let icon = ''

    switch ( extension ) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        icon = 'glyphicon-picture'
        break
      case 'mp4':
      case '3gp':
      case 'mpg':
      case 'mpeg':
      case 'avi':
      case 'flv':
        icon = 'glyphicon-film'
        break
      case 'mp3':
        icon = 'glyphicon-headphones'
        break
      case '7z':
      case 'rar':
      case 'zip':
        icon = 'glyphicon-folder-open'
        break
      default:
        icon = 'glyphicon-file'
    }

    return icon
  }
  ngAfterViewInit(){
    try {
      if (!this.isMobile) {
        document.getElementById('social-icons').classList.add('desktop');
      }
    } catch (error) { }
    
    setTimeout(() => {
      
      // let currentHeight = this.textContainer?.nativeElement?.offsetHeight;
      // //collapsable only if the contents make container exceed the max height
      // console.log("currentHeight in comment",currentHeight,this.textContainer.nativeElement,this.textContainer.nativeElement.innerHTML, this.textContainer?.nativeElement?.offsetHeight)
      //  if (currentHeight > this.maxHeight) {
      //      this.isCollapsed = true;
      //      this.isCollapsable = true;
      //      let el = document.getElementsByClassName('text')[0]
      //      console.log('eleme:',el);
      //      this.render.addClass(this.textContainer.nativeElement, 'reply-text');
      //      //document.getElementById('desktopComment').classList.remove('animation-class');
           
      //      //el?.setAttribute("style","overflow:hidden;text-overflow: ellipsis;height:`this.maxHeight`+'px';display: -webkit-box;-webkit-line-clamp: 2; -webkit-box-orient: vertical")
      //  }
      
       if(this.isCommentPosted){
        // console.log("animation-class testing");
        document.getElementById('desktopComment').classList.add('reply-animation-class');
  
        // console.log("animation-class testing",document.getElementById('desktopComment'));
        this.appState.set('isReplyAnimationApplied',false)
      }
      },1);
      setTimeout(() => {
          if(this.isCommentPosted){
            document.getElementById('desktopComment').classList.remove('reply-animation-class');
        }
      },1500);
    // console.log("comment", this.comment);
  }
  textExpand(val) {
    if(val){
      this.render.removeClass(this.textContainer.nativeElement, 'reply-text');
      this.isCollapsed = false;
      this.isCollapsable = true;
    }
    else {
      this.render.addClass(this.textContainer.nativeElement, 'reply-text');
      this.isCollapsed = true;
      this.isCollapsable = true;
    }
    this.cdRef.detectChanges();
  }
  doLikeUnlike() {
    
    this.render.addClass(this.btn.nativeElement, 'bounce');
    if (this.btn.nativeElement.querySelector('ion-icon').getAttribute("name") == 'star-outline') {
      this.render.setAttribute(this.btn.nativeElement.querySelector('ion-icon'), "name", 'star')
    }
    else {
      this.render.setAttribute(this.btn.nativeElement.querySelector('ion-icon'), "name", 'star-outline')

    }
    setTimeout(() => {
      this.render.removeClass(this.btn.nativeElement, 'bounce');
    }, 200);
  
  // this.onToggleLike.emit({
  //   item: this.comment
  // })
}
// emitShowLikeList(){
//   this.onShowLikeList.emit(this.comment.id);
// }
  getUserName(user){
    try {
          return user.firstName+" "+user.lastName 
    } catch (error) {
      
    }
  }

  toggleVote(comment) {
    console.log('comment', comment);
    if (comment.user.id === this.user.id) {
      return false
    }
    
      this.onBounce();
   
    this.voteLoading = true
    comment.hasVoted = !comment.hasVoted
    comment.votesCount += comment.hasVoted ? 1 : -1
    this.cdRef.detectChanges()
    this.replyService.voteReply(comment,'').subscribe(
      status => {
        this.voteLoading = false
        this.cdRef.detectChanges()
      },
      error => {},
      () => {
        this.voteLoading = false
        this.cdRef.detectChanges()
      }
    )
  }

onBounce() {
  this.render.addClass(this.btn.nativeElement, 'bounce');
  if(this.btn.nativeElement.querySelector('ion-icon').getAttribute("name") == 'star-outline') {
    this.render.setAttribute(this.btn.nativeElement.querySelector('ion-icon'),"name", 'star')
  }
  else {
    this.render.setAttribute(this.btn.nativeElement.querySelector('ion-icon'),"name", 'star-outline')

  }
  setTimeout(() => {
    this.render.removeClass(this.btn.nativeElement, 'bounce');
  }, 200);
}
  @HostListener('click', ['$event'])
  onLinkClick(e) {
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        console.log('cordova click')
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        window.open(e.target.href, '_blank')
      }
    }
  }

  deletePost(id){
    this.mobileService.deletePost(id).subscribe(
      response => {
          console.log(response);
          var test = this.appState.get('currentPath')
          console.log("deleted",test);
        this.appState.set('commentDeleted', true)
        this.appState.set('deletedPostId', id);
        // this.refresh()
        this.appState.set("repliesTreeObject", this.repliesTree);
          this.cdRef.detectChanges()
          this.toasterService.success(this.i18n.t('Comment deleted successfully.'))
      },
      error =>{
        console.log(error);
      }
    )
  }

  refresh() {
    this.appState.refreshMainComponent.emit();
  }


  copyMessage(val: string,comment_id: number){
    try {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.toasterService.success(this.i18n.t("Copied to clipboard!"));
      this.deletePost(comment_id)
    }catch{
      this.toasterService.warning(this.i18n.t("Not able to copy and delete!"));
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    console.log('ev',event.target, this.showMenu)
    if(this.disableListener?.nativeElement.contains(event.target)) {
      this.showMenu = !this.showMenu;
    }
    else {
      this.showMenu =false;
    }
  }
}
