import {ChangeDetectorRef, AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AppState } from '../app.service';
import { Platform } from '@ionic/angular';
import { Reflection } from '../my-library/full-screen-reflection/reflection.model';
import { User } from '../user/user.model';
import { Path } from '../path/path.model';
import { ReflectionService } from '../my-library/full-screen-reflection/reflection.service';
import { ToasterService } from '../toaster.service';
import mixpanel from 'mixpanel-browser';
import { CordovaService } from '../cordova.service';
import { SessionService } from '../session/session.service';
import { HomeUiService } from '../new-design/home-ui.service';
import { I18n } from '../i18n.service';
import { Keyboard } from "@awesome-cordova-plugins/keyboard/ngx";


@Component({
  selector: 'first90-reflection',
  templateUrl: './reflection.component.html',
  styleUrls: ['./reflection.component.scss'],
})
export class ReflectionComponent implements OnInit, AfterViewInit {
  @ViewChild('disableListener') disableListener: ElementRef
  @Input() reflection: Reflection
  @Input() currentDay: number
  reflectionAnswer: string
  currentUser: User
  currentPath: Path
  savedReflection: string
  isMobile: boolean
  @ViewChild('textareaElement', { static: false }) textareaElement: ElementRef;
  @ViewChild('textAreaContainer', { static: false }) textAreaContainer: ElementRef;
  showMenu = false;
  isEdit = false;
  isMobileWebIos = false;
  isMobileWeb=false;
  hideActionButtons = false
  
  constructor(private appState: AppState,
    private reflectionService: ReflectionService,
    private toasterService: ToasterService,
    private renderer: Renderer2,
    private i18n: I18n,
    private cordovaService: CordovaService,
    private sessionService: SessionService,
    private platform: Platform,
    public homeUIService: HomeUiService,
    public keyboard: Keyboard,
    private cdRef: ChangeDetectorRef,) {
      this.currentUser = this.appState.get('currentUser')
      this.currentPath = this.appState.get('currentPath')
      this.isMobile = this.appState.get('isMobile')
   }

  ngAfterViewInit() {
    if(!this.reflection.text){
      this.appState.set('textareaElement', this.textareaElement)
      this.appState.set('textAreaContainer', this.textAreaContainer)
    }
  }

  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    this.resizeHeight();
    this.restoreResizeHeight();
    console.log("this.isMobileWeb value", this.isMobileWeb);
    this.savedReflection = localStorage.getItem(
      `Reflection:User:${this.currentUser.id}:DayProgress:${this.currentPath.id}:${this.currentDay}`
    )
  }

  resizeHeight(){
    this.keyboard.onKeyboardDidShow().subscribe((info) => {
         const mobileLoginScreens = document.querySelector('.card.reflection-min-height');
         mobileLoginScreens.setAttribute('style', `height: calc(100vh - ${info.keyboardHeight}px); min-height: unset`);
       });
  }

  restoreResizeHeight(){
    this.keyboard.onKeyboardDidHide().subscribe(() => {
      const mobileLoginScreens = document.querySelector('.card.reflection-min-height');
      mobileLoginScreens.setAttribute('style', `min-height: 530px`);
    })
  }

  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  findForCurrentDay(collection: any): any {
    return collection.find(
      item => item.startDay <= this.currentDay && this.currentDay <= item.endDay
    )
  }
  extractTextFromHTML(htmlResponse: string): string {
    const tempElement = this.renderer.createElement('div');
    tempElement.innerHTML = htmlResponse;
    return tempElement.textContent
  }

  postAnswer(){
    this.textareaElement.nativeElement.style.setProperty(
      'color',
      `#808080`
    );
    try {
      const requestData = {
        'user_id': this.currentUser.id,
        'path_id': this.currentPath.id,
        'text': this.reflectionAnswer,
        'schedule_day': this.currentDay,
        'is_mandatory': true,
        'enabled': true,
        'reflection_id': this.reflection.id
      }

      if (!this.reflectionAnswer) {
        this.toasterService.warning(this.i18n.t('Please enter a comment'))
      } else {
        this.reflectionService.saveReflectionAnswer(requestData).subscribe(
          (response) => {
            this.isEdit = false
            this.appState.get('currentPath').update('reflections', this.reflection.id, { isCompleted: true })
            this.savedReflection = response.data.text
            this.reflectionAnswer = ""
            let promptData = this.findForCurrentDay(this.currentPath.reflections)
            let modifiedPromptData = this.extractTextFromHTML(promptData.prompt)
            mixpanel.track('In Steps-Reflection Post', {'id': response.data.id, 'pathId': response.data.path_id, 'post-text': response.data.text, 'reflection-id': response.data.reflection_id, 'user-id': response.data.user_id, 'prompt-text': modifiedPromptData})
            localStorage.setItem(
              `Reflection:User:${this.currentUser.id}:DayProgress:${this.currentPath.id}:${this.currentDay}`,response.data.text
            )
          },
          (error) => {
            console.log('saveReflectionAnswer error :', error);
            this.toasterService.error(this.i18n.t('Server or connection issue. Please check your internet connection.'))
          }
        )       
      }

    } catch (o_O) {
      console.log(o_O);
    }
  }

  editAnswer(){
    this.isEdit = true
    this.hideActionButtons = false
    this.onTypingStarted()
    setTimeout(() => {
      this.appState.set('textareaElement', this.textareaElement)
      this.appState.set('textAreaContainer', this.textAreaContainer)
    }, 10);
    if (this.savedReflection) {
      this.reflectionAnswer = this.savedReflection
    } else {
      this.reflectionAnswer = this.reflection.text 
    }
  }

  @HostListener('click', ['$event'])
  onLinkClick(e) {
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        window.open(e.target.href, '_blank')
      }
    }
  }

  onTypingStarted(){
    setTimeout(() => {
      this.textareaElement.nativeElement.classList.add('text-area-expansion')
    }, 10);
  }

  onTextAreaClick(){
    this.hideActionButtons = false
    this.textareaElement.nativeElement.classList.add('text-area-expansion')
  }

  cancel(){
    this.hideActionButtons = true
    setTimeout(() => {
      this.textareaElement.nativeElement.blur()
    }, 10);
    this.textareaElement.nativeElement.classList.remove('text-area-expansion')
    this.isEdit = false
  }
@HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.disableListener?.nativeElement.contains(event.target)) {
      this.showMenu = !this.showMenu;
    }
    else {
      this.showMenu =false;
    }
  }
}
