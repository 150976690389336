import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppState } from '../app.service';
import { HomeUiService } from '../new-design/home-ui.service';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-alert-routine-anim',
  templateUrl: './alert-routine-anim.component.html',
  styleUrls: ['./alert-routine-anim.component.scss']
})
export class AlertRoutineAnimComponent implements OnInit {

  isMobile: boolean
  @Input() level;
  constructor( @Optional() public dialogRef: MatDialogRef<AlertRoutineAnimComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private modalController: ModalController,
    private appState: AppState,
    public homeUIService: HomeUiService,
    ) { 

      this.isMobile = this.appState.get('isMobile')
    }

  ngOnInit() {
  }
  close() {
    this.modalController.dismiss();
  }
}
