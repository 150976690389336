import { ChangeDetectorRef, Component, DoCheck, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertActionCompleteComponent } from '../alert-action-complete/alert-action-complete.component';
import { AlertGiveFeedbackCommitmentComponent } from '../alert-give-feedback-commitment/alert-give-feedback-commitment.component';
import { AppState } from '../app.service';
import { RoutineService } from '../daily-content-v2/routine.service';
import { HabitListItem } from '../habit-list-modal/habit-list-modal.model';
import { Habit } from '../habit/habit.model';
import { HabitService } from '../habit/habit.service';
import { HomeV5Service } from '../pages/home-v5/home-v5.service';
import { Path } from '../path/path.model';
import { RatingAlertBoxComponent } from '../rating-alert-box/rating-alert-box.component';
import { RoutineV2Service } from '../routine-v2/routine-v2.service';
import { User } from '../user/user.model';
import * as confetti from 'canvas-confetti';
import { RoutineInfo } from '../routine-v2/routine-info.model';
import { SpinnerService } from '../spinner.service';
import { ToasterService } from '../toaster.service';
import { AlertController, IonItemSliding, ModalController, NavController } from '@ionic/angular';
import { Client } from '../client/client.model';
import { AlertSupportComponent } from '../alert-support/alert-support.component';
import { NativeService } from '../services/native.service';
import { InoutScreen } from '../inout-screen/inout-screen.model';
import { I18n } from '../i18n.service';
import { DailyStepsService } from '../daily-steps/daily-steps.service';
import mixpanel from 'mixpanel-browser';
import { MyCommitmentsService } from './my-commitments.service';
import { collapseAnimation, collapseOnLeaveAnimation, expandOnEnterAnimation, fadeInLeftAnimation, slideOutRightAnimation } from 'angular-animations';
import { HomeUiService } from '../new-design/home-ui.service';
import { EditRoutineComponent } from '../edit-routine/edit-routine.component';
import { CompletedReviewModalComponent } from '../completed-review-modal/completed-review-modal.component';
import { FullScreenAudioComponent } from '../full-screen-audio/full-screen-audio.component';
import { FullScreenDocumentComponent } from '../my-library/full-screen-document.component';
import { FullScreenVideoComponent } from '../full-screen-video/full-screen-video.component';
import { AlertWebactionCalendarComponent } from '../alert-webaction-calendar/alert-webaction-calendar.component';
import * as moment from 'moment';
import { CalendarService } from '../calendar.service';
import { CordovaService } from '../cordova.service';
import { AnimationsService } from '../shared/animations.service';
import { SubRoutineInfoComponent } from './sub-routine-info/sub-routine-info.component';
import { FeedbackFormComponent } from '../components/commitments/feedback-form/feedback-form.component';
import { DailyStepsContentModalComponent } from '../new-design/daily-steps-content-modal/daily-steps-content-modal.component';
import { trigger, transition, style, animate } from '@angular/animations';

export interface CalendarParams {
  title: String,
  startDate: Date
  endDate: Date,
  eventLocation: String,
  notes: String,
  endEventTime: any,
  frequency: string
}
@Component({
  selector: 'first90-my-commitments',
  templateUrl: './my-commitments.component.html',
  styleUrls: ['./my-commitments.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    slideOutRightAnimation({duration: 500}),
    collapseAnimation(),
    expandOnEnterAnimation({ duration: 400 }),
    collapseOnLeaveAnimation({ duration: 400 })
  ]
})
export class MyCommitmentsComponent implements OnInit, DoCheck {
  videoFormats = ['.mp4', '.mov', '.avi', '.avchd', '.f4v', '.mkv', '.wmv','youtube.com', 'webm', 'html5', 'HTML5', 'watch'];
  audioFormats = [".mp3"];
  isEditRoutineEnabled: boolean = false;
  client: Client;
  currentPath: Path;
  currentUser: User;
  habits: HabitListItem[];
  calendarParams: CalendarParams;
  habit: Habit
  currentDay: number
  routineList: any
  editRoutineList;
  isCheckedAll: boolean = false
  routineLevels: any
  isExpanded: boolean = false
  routineId: number
  scheduledDay: number
  actionList;
  currentRoutine: any
  isNextScreen: boolean = false
  makeHabit: boolean
  canvas: ElementRef
  levelId: any
  routine: RoutineInfo
  levelNumber: number
  routineDay: number
  isMobile: boolean
  showTab: boolean;
  showAction: boolean;
  currentTab: number;
  isTabChange = false;
  @Input() isHomeRoute: boolean;
  myHabitLabel: string
  myActionLabel: string
  inoutScreenData: InoutScreen[]
  isonboardingcompleted: any;
  makeActive: boolean = false;
  indexOfLevel: number
  selectedLevels = []
  hasNativeCalendar;
  inoutScreen: InoutScreen;
  screensIds = []
  screensEnabled = {}
  isUncommitedRoutineClicked= [];
  uncommitedRoutine: boolean[] = [];;
  options: [
    {
      label: 'Sliding Entrances',
      animations: ['slideOutRight']
    },
    {
      label: 'Other',
      animations: ['expandCollapse']    
    },
  ]
  animationState = false;
  web_todoLevelsList =[];
  web_completedLevelsList=[]
  habitCommitmentIndex;
  actionCommitmentIndex;
  @ViewChildren('slidingItem') slidingItem: IonItemSliding[];
  actionId: number
  checked: boolean = false
  expandCompleted = false
  expandNotCommitted = false
  scheduleDay: any
  showEditOptions = false
  showEditHabits = false
  @Output() onLogaRep: any = new EventEmitter<any>();
  completedActions: any
  isActionExpanded: boolean[] = [];
  isItemExpanded: boolean[] = [];
  isRoutineExpanded: boolean[] = [];
  ncAction: boolean[] = [];
  ncAction1: boolean[] = [];
  completedRoutine: boolean[] = [];
  isUncompletedRoutineClicked= [];
  routineClicked = []
  actionClicked = []
  completedActionClicked = []
  noncompletedActionClicked = []
  noncompletedActionClicked1 = []
  showActionCheckbox: boolean
  completedHabitsList: any
  unCommittedActionsList: any
  isDataLoaded = false;
  constructor(
    private routineService: RoutineV2Service,
    private routeService: RoutineService,
    private dailyStepService: DailyStepsService,
    private cdRef: ChangeDetectorRef,
    private appState: AppState,
    private dialog: MatDialog,
    private calendarService: CalendarService,
    private modalController: ModalController,
    private homeService: HomeV5Service,
    private tostrService: ToasterService,
    private habitService: HabitService,
    public spinnerService: SpinnerService,
    public myCommitmentService: MyCommitmentsService,
    private route: Router,
    private nav: NavController,
    private matDialog: MatDialog,
    private alertController: AlertController,
    private native: NativeService,
    private homeUIService: HomeUiService,
    private i18n: I18n,
    private cordovaService: CordovaService,
    private animationService: AnimationsService,
    private toasterService: ToasterService,
    private homev5Service: HomeV5Service,
    private commitService: MyCommitmentsService) {
      const w: any = window
      this.hasNativeCalendar = this.cordovaService.onCordova && w && w.plugins && w.plugins.calendar
      
      if (localStorage.getItem('currentDayAfterScreenView')) {
        this.scheduleDay = localStorage.getItem('currentDayAfterScreenView')
      } else {
        let currentScheduleDay = this.appState.get('currentScheduleDay')
        this.scheduleDay = currentScheduleDay > 1 ? currentScheduleDay - 1 : currentScheduleDay
      }
      
  }
  editRoutineStatus() {
    const requestData = {
      'user_id': this.currentUser.id
    }

    if (requestData.user_id == 0) {
      return true;
    } else {
       this.closeOpenItems();
      this.routineService.getOptedRoutines(requestData).subscribe(
        (response) => {
          this.cdRef.detectChanges();
          this.editRoutineList = this.removeDuplicates(response.data, "routine_info_id");
          console.log('not committed routines :', this.editRoutineList);
          this.getRoutines(this.currentUser?.id, this.currentPath.id);
          if(this.editRoutineList && this.editRoutineList.length>0 && this.currentTab ==0) {
            this.isEditRoutineEnabled =true;
          }
          //  if(this.unCommittedActionsList.length > 0 || this.habits?.length>0 || this.editRoutineList?.length>0){
          //    this.expandNotCommitted = true
          //  }
          //  else {
          //    this.expandNotCommitted = false
          // }
        },
        (error) => {
          console.log('Error for routines ', error)
          this.tostrService.error(this.i18n.t('No record found'))
        })
    }
  }
  removeDuplicates(originalArray, prop) {
    var tempArray = [];

    tempArray = originalArray.filter(function (el) {
      return el.routine_info_id,
        el.is_opted;
    });
    try{
      for (let i = 0; i < originalArray.length; i++) {
        for (let j = 0; j < tempArray.length; j++) {
          if ((tempArray[j].routine_info_id == originalArray[i].routine_info_id) && !originalArray[i].is_opted) {
            originalArray.splice(i, 1);
          }
        }
      }
      originalArray = this.newremoveDuplicates(originalArray, prop)
      return originalArray;
    }catch (o_O){
      return tempArray;
    }

  }
  newremoveDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }
  ngOnInit() {
    this.init();
    this.myCommitmentService.setLoader(true)
    this.appState.set("isCommitmentsViewed", true)
    this.editRoutineStatus();
    let levelSaveData = this.appState.get('levelSaveData')
    if (levelSaveData) {
      localStorage.setItem(
        `Toggle:Day:${levelSaveData.currentDay}:LevelNumber:${levelSaveData.levelId}:true`,
        'true'
      )
      this.appState.set('showUpdatedScreens', undefined)
      this.appState.set('levelSaveData', undefined)
    }

  }
  init() {
    this.myHabitLabel = this.i18n.t('MY HABITS')
    this.myActionLabel = this.i18n.t('MY ACTIONS')
    this.client = this.appState.get('currentClient');
    this.currentPath = this.appState.get("currentPath");
    this.inoutScreenData = this.currentPath.inoutScreens;
    this.currentUser = this.appState.get("currentUser");
    this.scheduledDay = this.appState.get("scheduleDayForCurrentStep")
    this.currentDay = this.appState.get('currentScheduleDay')
    this.levelNumber = this.appState.get('levelId')
    this.isMobile = this.appState.get('isMobile')
    this.appState.set('page', 1)
    this.setActionsList();
    // this.getRoutines(this.currentUser?.id, this.currentPath.id);
  
  }

  ngDoCheck() {

  }
  goToQuestion() {
    var supportDialog = this.matDialog.open(AlertSupportComponent);
    supportDialog.afterClosed().subscribe()
  }
  changeTab(evn) {
    this.currentTab = evn.index;
    let tabValue = this.currentTab == 0 ? this.myHabitLabel : this.myActionLabel
    mixpanel.track('Commitments-Tab Selected', {tab: tabValue})
    this.isTabChange = true;
    localStorage.setItem('commitmentTab', this.currentTab.toString());
    this.showAction = true;
    if (this.currentTab != 0) {
      this.isEditRoutineEnabled = false;
    } else {
      this.routineList.forEach(element => {
        if (element.routine_info.path_id === this.currentPath.id) {
          this.currentTab = 0;
          this.isEditRoutineEnabled = true;
        }
      });
      this.editRoutineStatus();
    }
  }

  textToSpeech() {
    this.native.textToSpeech();
  }

  showAnimation() {
    const myConfetti = confetti.create(this.canvas, {
      resize: true // will fit all screen sizes
    });
    myConfetti();

  }

  findHabitbyId(collection: any, id: any): any {
    return collection.find(
      item => item.id === id
    )
  }

  getRoutines(userId: number, pathId: number,actionId?) {
    const requestData = {
      'user_id': userId,
      'path_id': pathId
    }
    this.dailyStepService.getActionsList(requestData).subscribe((response) => {
      this.isItemExpanded.push(false);
      this.showTab = true      
      this.routineList = this.homev5Service.removeDuplicates(response.data[0].routine, "routine_info_id");
      console.log("this.routineList...",response.data[0].routine, this.routineList);
      this.completedHabitsList = this.routineList.filter(item => item.is_routine_complted === true)
      this.actionList = response.data[0].action;
      this.unCommittedActionsList = response.data[0].completed_actions.filter(item => item.confirmation === 0 && item.enabled === false);
      this.completedActions = response.data[0].completed_actions.filter(item => item.confirmation === 1 && item.enabled === true);
      if(this.completedActions.length > 0 || this.routineList.length> 0){
        this.expandCompleted = true
      }
      else {
        this.expandCompleted =false
      }
     
      this.setTab();
      this.appState.set('todoRoutines',this.homev5Service.removeDuplicates(response.data[0].routine, "routine_info_id"))
      this.appState.set('todoActions', response.data[0].action)
      this.isDataLoaded = true;
      this.cdRef.detectChanges();
    }, (error) => {
      this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."));
      console.log('error :', error);
    })
  }

  openDocument(post) {
    let posts;
    console.log('post before...',post)
    posts= post?.document
    let doc = this.appState.get('singleDocument');
    if(posts?.id == doc?.id) {
      posts =this.appState.get('singleDocument')
    }
    
    this.appState.set('singleDocument', posts)
    this.appState.set('journalPost', undefined)
    console.log("post",post);
    if (posts.isrecommended && posts.isrecommended === true) {
      this.appState.set('isCasualDocument', true)
    }
  
    this.appState.set('journalPathName', this.currentPath.userGroup.name)
    this.appState.set('journalStepNumber', posts.startDay)
    const url = posts.link;
    if (this.audioFormats.some(el => url.includes(el))) {
      console.log('Audio File');
      mixpanel.track('Commitments-Open Documents', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Audio File','post id': post.id})
      this.homeUIService.openNextComponent(FullScreenAudioComponent, { data: posts })
    } else if (this.videoFormats.some(el => url.includes(el))) {
      console.log('Video File');
      mixpanel.track('Commitments-Open Documents', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Video File','post id': post.id})
      this.homeUIService.openNextComponent(FullScreenVideoComponent, { data: posts })
    } else {
      console.log('Document');
      mixpanel.track('Commitments-Open Documents', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Document File','post id': post.id})
      this.homeUIService.openNextComponent(FullScreenDocumentComponent)
    }
  }

  async markAsDoneInMobile(habits) {
    mixpanel.track("Commitments-Mark Habit Complete", {'title': habits.title, 'startDay': habits.startDay, 'screenId': habits.screenId,'pathName': habits.pathName,'isConvertedToRoutine': habits.isConvertedToRoutine, 'id': habits.id,'casualDocumentId': habits.casualDocumentId})
    if (this.scheduledDay < habits.dayNum) {
      this.tostrService.warning(this.i18n.t("Please unlock step from homepage!"))
    } else {
      var habit = this.findHabitbyId(this.currentPath.habits, habits.id)
      if (!habit.isCompleted) {
        this.habitService.toggleHabit(habit.id).subscribe(
          response => {
            console.log('commit response', response)
          },
          error => {
            console.log('commit error', error)
          },
          () => { }
        )
      }

      try {
        this.habitService.confirm(habit.id).subscribe(res => {
          // LUMBA-1344
          console.log("Habit confirmed ", res);
        })
      } catch (o_0) {
        console.log('Errors ', o_0)
      }

      try {
        if (this.currentPath.enabledAttributes.feedback && habit.isCompleted) {
          this.initModal(habits);
          console.log("initModal")
        } else {
          console.log("not-initModal")
          this.setActionsList();
          this.cdRef.detectChanges()
          this.spinnerService.off()
          // this.goToBack()
        }
      } catch (o_O) {
        console.log(o_O);
      }
    }

  }
  async reschedule(habits) {
    let posts, docLink;
    posts= habits?.document
   
    let doc = this.appState.get('singleDocument');
    if(posts?.id == doc?.id) {
      posts =this.appState.get('singleDocument')
    }
    
    this.appState.set('singleDocument', posts)
    docLink= posts?.editedDocumentUrl?posts?.editedDocumentUrl:posts?.link;
    mixpanel.track("Commitments-Reschedule Habit", {'id': habits.id, 'pathName': habits.pathName, 'isConvertedToRoutine': habits.isConvertedToRoutine, 'screenId': habits.screenId, 'title': habits.title, 'startDay': habits.startDay})
    this.prepareCalendarParams(habits.title, this.routeService.innerHtmlToMobileAction(habits.text,docLink))
    if (this.hasNativeCalendar) {
      this.getRoutines(this.currentUser?.id, this.currentPath.id);
      this.createCalendarEvent()
    } else {
      this.calendarParams.notes = this.routeService.innerHtmlToWebAction(habits.text,docLink)
      this.homeService.setCalendarData(this.calendarParams)
     
    const modal = await this.modalController.create({
      component: AlertWebactionCalendarComponent,
      animated: false,
      cssClass: this.isMobile?'custom-modal-size':'web-action-calender-modal'
    
    });
      await modal.present() 
      modal.onDidDismiss().then(() => {
          localStorage.setItem(
      `Toggle:User:${this.currentUser.id}:HabitScheduled:${habits.id}`,
      'yes'
    )

    // this.spinnerService.on()
    this.habitService.scheduleLater(habits.id).subscribe(res => {
      // LUMBA-1344
      // this.spinnerService.off()
      this.getRoutines(this.currentUser?.id, this.currentPath.id);
       this.cdRef.detectChanges()
      //this.close()

    })
      })
    }

  }

  createCalendarEvent() {
    const success = function (message) { console.log('Success: ' + JSON.stringify(message)) }
    const error = function (message) { console.error('Error: ' + message) }

    // create an event interactively
    const w: any = window
    const calOptions = w.plugins.calendar.getCalendarOptions()
    const calendarList = w.plugins.calendar.listCalendars(success, error);
    const showAlertPrompt=true
    const daysCount = this.currentPath.daysOfContent * 2  // LUMBA-1399
    // calOptions.recurrence = 'daily' // supported are: daily, weekly, monthly, yearly
    calOptions.recurrenceInterval = 1
    calOptions.firstReminderMinutes = 0
    calOptions.recurrenceEndDate = moment(new Date(), 'HH:mm').add(daysCount, 'day').toDate()

    this.calendarService.scheduleEvents(
      this.calendarParams.title,
      this.calendarParams.eventLocation,
      this.calendarParams.notes,
      this.calendarParams.startDate,
      this.calendarParams.endDate,
      calOptions,
      success,
      error,
      showAlertPrompt
    );
  }
  setInScreen() {
    this.inoutScreen = this.findForCurrentDay(this.currentPath.inoutScreens)
    if (
      this.inoutScreen &&
      this.inoutScreen.preScreens &&
      this.inoutScreen.preScreens.length > 0
    ) {
      this.inoutScreen.preScreens.forEach((screen, i) =>
        this.addScreen('daily-content-screen-intro-' + i)
      )
    }
  }
  addScreen(id) {
    this.screensIds.push(id)
    this.screensEnabled[id] = true
  }
  findForCurrentDay(collection: any): any {
    console.log(collection)
    return collection.find(
      item => item.startDay <= this.currentDay && this.currentDay <= item.endDay
    )
  }
  innerHTMLToMainTitle() {
    // habits.text
    var divMainTitle = document.createElement("DIV");
    divMainTitle.innerHTML = this.inoutScreen?.homepageSubtext.text;
    let tv_mainTitle = divMainTitle.innerText;
    return tv_mainTitle;
  }

  prepareCalendarParams(title: any, msg: String, routinelen: any = null, eventlen: string = null) {
    const start = moment(new Date(), 'HH:mm')
    const end = moment(new Date(), 'HH:mm').add(10, 'minute')
    var titleOfEvent = "Daily Learning Reminder"

    if (this.currentPath.names && this.currentPath.names.titleOfEvent) {
      titleOfEvent = this.currentPath.names.titleOfEvent;
    } else {
      titleOfEvent = "Daily Learning Reminder"
    }

    this.calendarParams = {
      startDate: start.toDate(),
      endDate: end.toDate(),
      title: title, //updated title
      eventLocation: '',
      notes: msg,
      endEventTime: routinelen,
      frequency: eventlen
    }
  }

  async initModal(habit) {
    // this.appState.set('currentDay', habit.dayNum)
    habit.isCompleted = true;
    habit.scheduledLater = false;
    const modal = await this.modalController.create({
      component: CompletedReviewModalComponent,
      componentProps: { currentHabit: habit },
      cssClass: this.homeUIService.isDark() ? 'dark-theme-review-modal mobile-custom-to_do_list-forms' : 'light-theme mobile-custom-to_do_list-forms',
    });

    modal.onDidDismiss().then((modalDataResponse) => {
      if (modalDataResponse !== null) {
        this.cdRef.detectChanges()
        this.setActionsList()
      }
    });
    return await modal.present();
  }
  setActionsList() {
    let scheduleDay = this.appState.get("scheduleDayForCurrentStep")
    this.habits = new Array(this.currentPath.daysOfContent)
      .fill(0)
      .map((value, index) => {
        const dayNum = index + 1;
        const habit = this.currentPath.habits.find(
          item => item.startDay <= dayNum && dayNum <= item.endDay
        );
        if (habit) {
          return {
            id: habit.id,
            dayNum: habit.startDay, // LUMBA-1307
            title: habit.title,
            text: habit.text,
            casualDocumentId: habit?.casualDocumentId,
            documents: habit?.documents,
            scheduledLater: habit.confirmation < 1 && habit.scheduledLater > 0,
            confirmed: habit.confirmation > 0,
            isConvertedToRoutine: habit.isConvertedToRoutine,
            isCompleted: habit.isCompleted,
            screenId: "daily-content-screen-habit",
            startDay: habit.startDay,
            endDay: habit.endDay,
            pathName: this.currentPath.userGroup.name
          };
        }
        return {
          id: null,
          dayNum: dayNum,
          title: null,
          text: null,
          casualDocumentId: null,
          documents:null,
          scheduledLater: false,
          confirmed: false,
          isConvertedToRoutine: false,
          isCompleted: false,
          screenId: "",
          startDay: 0,
          endDay: 0
        };
      });

    this.habits =this.habits.filter(
      item => item.isCompleted !== true && (item?.dayNum<=scheduleDay && item?.startDay>0 && item?.startDay<=scheduleDay)
    )
    console.log('after path actions :', this.habits, this.habits?.length);
    this.unCommittedActionsList = this.habits.filter(item => !item.confirmed && !item.scheduledLater && scheduleDay > item.dayNum)
    console.log('data :', this.unCommittedActionsList);
    if(this.appState.get('expandNotCommittedActions') ){
      this.expandNotCommitted = true
    }
  }
  setTab() {
    if (localStorage.getItem('commitmentTab') === "null" || localStorage.getItem('commitmentTab') === null) {
      //
      if(this.routineList?.length>0){
      this.routineList.forEach(element => {
        if (element.routine_info.path_id === this.currentPath.id) {
          this.currentTab = 0;
          this.isEditRoutineEnabled = true;
        }
      })
      }
      else {
        this.currentTab = 1;
      }

    
    } 
    else if(this.routineList.length ==0) {
      this.currentTab =1;
      this.isEditRoutineEnabled = false;
    }
    else {
      this.currentTab = Number(localStorage.getItem('commitmentTab'));
      this.isEditRoutineEnabled = false;
    }
    if(this.routineList.length>0 && this.currentTab==0){
      this.isEditRoutineEnabled = true;
    }
    
  }

  onChange(event) {
    this.isCheckedAll = event.checked
  }

  testDone($event) {
  }
  changeActionTab(event) {
  }

  markAsDone(habits) {
    if (this.scheduledDay < habits.dayNum) {
      this.tostrService.warning(this.i18n.t("Please unlock step from homepage!"))
    } else {
      const dialogAction = this.dialog.open(AlertActionCompleteComponent)
      dialogAction.afterClosed().subscribe((result) => {
        if (result == 'confirm') {
          var habit = this.findHabitbyId(this.currentPath.habits, habits.id)

          if (!habit.isCompleted) {
            this.habitService.toggleHabit(habit.id).subscribe(
              response => {
                console.log('commit response', response)
              },
              error => {
                this.spinnerService.off()
                this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."));
                console.log('commit error', error)
              },
              () => { }
            )
          }

          try {
            this.habitService.confirm(habit.id).subscribe(res => {
              // LUMBA-1344
              console.log("Habit confirmed ", res);
            })
          } catch (o_0) {
            this.spinnerService.off()
            this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."));
            console.log('Errors ', o_0)
          }

          try {
            if (this.currentPath.enabledAttributes.feedback && habit.isCompleted) {
              const dialogRef = this.dialog.open(RatingAlertBoxComponent,{
                data: habit
              })

              dialogRef.afterClosed().subscribe((result) => {

                if (result == 'confirm') {
                  const requestData = {
                    'user_id': this.currentUser.id,
                    'habit_id': habit.id,
                    'rating': JSON.parse(localStorage.getItem('rating1')),
                    'bizrating': JSON.parse(localStorage.getItem('rating2')),
                    'rcomment': localStorage.getItem('postText'),
                    'userIds': this.appState.get("tags_ids")
                  }

                  if (requestData.rcomment == '' && requestData.bizrating == 0 && requestData.rating == 0) {
                    this.spinnerService.on()
                    this.setActionsList()
                    this.spinnerService.off()
                  } else {
                    this.spinnerService.on()
                    this.homeService.giveFeedback(requestData).subscribe(
                      (response) => {
                        this.tostrService.success(this.i18n.t('Action Completed!'))
                        this.cdRef.detectChanges()
                        this.setActionsList()
                        this.spinnerService.off()
                      },
                      (error) => {
                        this.spinnerService.off()
                        this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."));
                        console.log('Error for feedback ', error)
                      }
                    )
                  }
                }
              })
            } else {
              this.setActionsList();
              this.cdRef.detectChanges()
              this.spinnerService.off()
            }
          } catch (o_O) {
            console.log(o_O);
          }
        }

      })
    }

  }

  getLevels(routine) {
    this.routineLevels = routine.routine
    this.routineId = routine.routine_info_id
    this.isExpanded = true
  }

  hideLevels() {
    this.isExpanded = false
  }

  getReps(item) {
    return item.reps_commit
  }

  logaRep(routine: any, level: any, index: any) {
    let repsCommitArray = routine.routine.filter(item => item.reps_commit)
    let repsArray = repsCommitArray.map(this.getReps)
    let totalRepsArray = Array.prototype.concat.apply([], repsArray);
    let totalLevelRepsArray = level.reps_commit.filter(item => item.is_completed === false)

    if (level.is_unlocked === false) {
      this.tostrService.warning(this.i18n.t('Please complete previous level.'))
    } else {
      this.spinnerService.on();
      this.myCommitmentService.setLoader(false);
      const requestData = {
        'user_id': this.currentUser.id,
        'path_id': routine.path_id,
        'routine_info_id': routine.routine_info_id,
        'level_id': level.id,
        'schedule_day': this.scheduledDay,
        'feedback': localStorage.getItem('repLogText'),
        'step': index + 1
      }

      // this.getRoutines(this.currentUser?.id, this.currentPath.id);
      this.routineService.replog(requestData).subscribe(
        (response) => {
          let object = null
          this.spinnerService.off();
          this.myCommitmentService.setLoader(true);
          // add reply to the local path routine_info
          if (response && response.data && response.data.routine_info_id) {
            object = this.appState.get('currentPath').routineInfo.find(q => q.id === response.data.routine_info_id)
          }

          this.tostrService.success(this.i18n.t('Rep Logged!'))
          console.log('Response for feedback ', response)
          this.getRoutines(this.currentUser?.id, this.currentPath.id);
          this.cdRef.detectChanges()

          this.indexOfLevel = this.appState.get('indexOfLevel')
          var isLastRepLogged = level.reps_commit.filter(item => item.is_completed === true)

          const last = routine.routine[routine.routine.length - 1];
          this.appState.set('isTodoReps', undefined)
          if (level.reps_commit.length == isLastRepLogged.length + 1 && last.id === level.id) {
            this.checkLastRep(routine)
          } else if (totalLevelRepsArray.length -1 === 0) {
            this.routineDay = routine.schedule_day
            this.appState.set('LevelNumber', level.level + 1)
            this.appState.set('levelUpdated', true)
            this.getCurrentRoutine(routine,level)
            this.appState.set('showUpdatedScreens', true)
            if (routine.path_id === this.currentPath.id) {
              this.appState.set('isLevelCommited',false);
              this.appState.set("allowScreenCount", undefined)
              // TODO: differentiate between mobile and web for routing
              if (this.isMobile) {
                this.route.navigate(['/content', this.routineDay], { queryParams: { startFromScreen: 'daily-content-screen-routine' } })
              } else {
                this.commitService.closeModals()
                setTimeout(() => {
                  this.homeUIService.openNextComponent(DailyStepsContentModalComponent,{currentDay: this.routineDay,startFromScreen: 'daily-content-screen-routine'},'web-daily-steps-custom-modal')                  
                }, 1100);
              }
              
            } else {
              this.changeSubPath(routine.path_id, this.routineDay)
            }
          } else {
            console.log('inside else:',);
          }

        },
        (error) => {
          this.spinnerService.off()
          this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."));
          console.log('Error for feedback ', error)
        }
      )
    }
  }

  getCurrentRoutine(routine, level) {
    let unCheckedLevels = []

    this.currentPath.routineInfo.forEach(currentRoutine => {
      if (currentRoutine.id === routine.routine_info_id) {
        currentRoutine.levels.forEach(routineLevel => {
          routine.routine.forEach(myLevel => {
            const match =routine.routine.filter((level)=>level.id === routineLevel.id)
            if (myLevel.id !== routineLevel.id && (match.length===0)) {
              unCheckedLevels.push({ 'id': routineLevel.id, 'index': currentRoutine.levels.indexOf(routineLevel) })
              unCheckedLevels =[...new Set(unCheckedLevels)];
            }
          });
        });
      }

      this.appState.set('unCheckedLevels',[...new Set(unCheckedLevels)] )
      let unselectedIndex = routine.routine.findIndex(item => item.id === level.id);
      this.appState.set('indexOfLevel', unselectedIndex + 1)
      this.appState.set('levelUpdated', true)
    });

    routine.routine.forEach(element => {
      this.selectedLevels.push({ 'id': element.id, 'index': routine.routine.indexOf(element) })
    });
    this.selectedLevels=[...new Set(this.selectedLevels)]
    this.appState.set('selectedLevelIds', this.selectedLevels)
  }

  nextScreen($event) {
    this.isNextScreen = true
    this.appState.set("levelData", $event.level)
    this.appState.set("subRoutine", $event.routine)
    this.homeUIService.openNextComponent(SubRoutineInfoComponent);
  }

  async showSingleHabit($event) {
    console.log('event in on clik', $event);
    this.appState.set("singleHabit", $event.item);
    // this.route.navigate(['/single-habit']).then(() => {
    //   console.log('back done....')
    // })
    this.getRoutines(this.currentUser?.id, this.currentPath.id);
    let actionItem;
    if(this.actionList.length>0){
      
    }
   
    actionItem = $event.item
        console.log("this.actionList", this.actionList);
    if (this.isMobile) {
      console.log("Action list", this.actionList, actionItem)
  
    const alert = await this.alertController.create({
      cssClass: 'custom-alert-action',
      header: this.i18n.t('Action'),
      message: actionItem?.text ,
      
       buttons: [
         {
           text: this.i18n.t('Reschedule'),
           
           cssClass: actionItem?.scheduledLater?'action-alert-enable':'action-alert-disable',
             handler: () => {
              this.reschedule(actionItem)
             }
         },
         {
          text: this.i18n.t('Mark Complete'),
          cssClass: actionItem?.scheduledLater?'action-alert-enable':'action-alert-disable',
          handler: () => {
             this.markAsDoneInMobile(actionItem);
            }
        },
        {
          text: actionItem?.casualDocumentId?this.i18n.t('Open tool'):'',
          cssClass: actionItem?.casualDocumentId?'action-alert-enable':'',         
            handler: () => {
              if(actionItem?.casualDocumentId) {
                this.openDocument(actionItem)
              }
            }
        },
        ],
     });
     await alert.present();
    } else {
      this.makeActive = true;
    }

  }

  closeSingleHbit(eventData: { title: boolean }) {
    this.makeActive = eventData.title;
  }

  close() {
    this.makeActive = false;
    this.ngOnInit()
  }

  getClass() {
    return this.makeActive
  }

  checkLastRep(routine?: any) {
    this.showAnimation()
    this.tostrService.success(this.i18n.t('Habit Complete!'))
    this.appState.set('LevelNumber', undefined)
    this.appState.set("allLevelsCompleted", true);
    setTimeout(async () => {
 
      const alert = await this.alertController.create({
         cssClass: 'custom-alert-action',
         message: this.i18n.t('Has this routine,')+" "+ routine?.routine_info?.title + " "+ this.i18n.t(", become a habit for you?"),
         
          buttons: [
            {
              text: this.i18n.t("No"),
              cssClass:'custom-alert-textcase',
              handler:()=>{
                this.makeHabit = false
                this.openAlertGiveFeedbackCommitmentComponent(routine);
              }
            },
            {
              text: this.i18n.t("Yes"),
              cssClass:'custom-alert',
              handler:()=>{
                this.makeHabit = true
                this.openAlertGiveFeedbackCommitmentComponent(routine);
              }
            }
           ],
        });
        await alert.present();
    }, 1500);

  }

  async openAlertGiveFeedbackCommitmentComponent(routine) {
    const modal= await this.modalController.create({
      component: AlertGiveFeedbackCommitmentComponent,
      cssClass: this.isMobile ? 'custom-alert-give-feedback-modal' : 'web_routine_feedback_modal',
      enterAnimation: this.animationService.slideInLeftEnterAnimation,
      leaveAnimation: this.animationService.SlideOutRightLeaveAnimation
    })
    this.homeUIService.setModalStack(modal);
    await modal.present();
   modal.onDidDismiss().then((res)=>{
     if(res){
      this.giveFeedback(routine)
     }
   })
  }
  giveFeedback(routine: any) {
    var step_rating = localStorage.getItem("commitmentRating1")
    var step_comment = localStorage.getItem("commitmentFeedback")
    try {
      const requestData = {
        'user_id': this.currentUser.id,
        'path_id': this.currentPath.id,
        'step_score': step_rating,
        'step_comment': step_comment,
        'routine_info_id': routine.routine_info_id
      }
      this.spinnerService.on()
      if (requestData.step_comment == '' && requestData.step_score == '') {
        this.spinnerService.off();
      } else {
        this.routineService.routineFeedback(requestData).subscribe(
          async (response) => {
            console.log('respose', response)
            this.getRoutines(this.currentUser?.id, this.currentPath.id);
            this.cdRef.detectChanges()
            this.spinnerService.off();
            if (this.makeHabit === false) {
             
              const alert = await this.alertController.create({
               
                 cssClass: 'custom-alert-action',
               
                 message: this.i18n.t('Do you want to repeat all or part of this habit to continue building the habit of')+" "+routine?.routine_info?.title,
                 
                  buttons: [
                    {
                      text: this.i18n.t("No"),
                      cssClass:'custom-alert-textcase',
                      handler:()=>{
                       
                      }
                    },
                    {
                      text: this.i18n.t("Yes"),
                      cssClass:'custom-alert',
                      handler:()=>{
                        this.appState.set('levelEdit', true)
                        this.onSubmit(routine);
                      }
                    }
                   ],
                });
                await alert.present();
            }
          },
          (error) => {
            this.spinnerService.off()
            this.tostrService.error(this.i18n.t('Server or connection issue. Please check your internet connection.'))
          }
        )
      }

    } catch (o_O) {
      console.log(o_O);
    }

  }

  findLevelId(routine: any, checkedLevel: any) {
    console.log('routine :', routine);
    let someArray = []
    let level_ids =[];
    routine.routine.forEach(element => {
      if (element.id != checkedLevel.id) {
        someArray.push(element)
        level_ids.push(element.id)
      }
    });
    this.appState.set('LevelNumber', someArray[0].level)
    this.appState.set('indexOfLevel', undefined)    
    this.appState.set('currentLevel', someArray[0])
    const repsRequestData= {  
      user_id:routine.user_id,
      is_reset:true,
      level_id:JSON.stringify(level_ids),
      routine_info_id:routine.routine_info_id
    }
   
    if(repsRequestData.user_id==0){
      return true;
    }else{
      this.routineService.appendRepsCount(repsRequestData).subscribe((res)=>{
        console.log("response for reps", res);
      })
     this.appState.set('levelUpdated', undefined)
      this.routineService.editRoutine(repsRequestData).subscribe(
        (response) => {
          this.appState.set('levelUpdated', undefined)
          this.cdRef.detectChanges();
        },
        (error) => {
          console.log('Error for edit routines', error)
        })
    }

  }

  onSubmit(routine) {
    // this.appState.set("allowScreenCount", true)
    this.appState.set('showUpdatedScreens', true)
      this.appState.set('LevelNumber', 1) 
      this.findLevelId(routine,1)
      this.appState.set('hidePrevScreen', true);
      this.appState.set("allowScreenCount", undefined)
      console.log("entered onsubmit function");
      if (this.isMobile) {
        this.homeUIService.dismissAllModals().then(()=>{
          this.route.navigate(['/content', routine.schedule_day], { queryParams: { startFromScreen: 'daily-content-screen-routine-levels' } })
        })  
      } else {
        this.homeUIService.dismissAllModals().then(()=>{
          this.homeUIService.openNextComponent(DailyStepsContentModalComponent,{currentDay: routine.schedule_day,startFromScreen: 'daily-content-screen-routine-levels'},'web-daily-steps-custom-modal')
        })  
      }
      
  }


  private changeSubPath(pathId: number, routineDay: number) {

    const request = {
      'user_id': this.currentUser.id,
      'active_path_id': pathId
    }

    this.homev5Service.subpathchange(request).subscribe(
      (response) => {
        this.appState.set('isAllPaths', false)
        this.appState.set("screensViewed", undefined);
        this.appState.set('stepsArray', undefined)
        this.appState.set('stepObject', undefined)
        this.refresh()
        let data = response["data"];
        try {
          let isOnboardContentPresent = data.onboardingSurvey || data.isIntroVideo;
          if (data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted?.length > 0 && isOnboardContentPresent) {
            let value = data['isonboardingcompleted'][0];
            this.isonboardingcompleted = value["isonboardingcompleted"];
            if ((this.isonboardingcompleted === false || this.isonboardingcompleted === null) && isOnboardContentPresent) {
              this.route.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          
            } else if (this.isonboardingcompleted === true) {
              this.appState.set("allowScreenCount", undefined)
              if (this.isMobile) {
                this.route.navigate(['/content', routineDay], { queryParams: { startFromScreen: 'daily-content-screen-routine' } })                
              } else {
                this.commitService.closeModals()
                setTimeout(() => {
                  this.homeUIService.openNextComponent(DailyStepsContentModalComponent, { currentDay: routineDay, startFromScreen: 'daily-content-screen-routine' }, 'web-daily-steps-custom-modal')
                }, 1100);
              }
            }
          } else if(data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted.length===0 && isOnboardContentPresent){
              this.route.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          
          } else {
            console.log('remove', this.isonboardingcompleted)
            this.appState.set("allowScreenCount", undefined)
            this.homeUIService.checkSubpathStatus();
            if (this.isMobile) {
              this.route.navigate(['/content', routineDay], { queryParams: { startFromScreen: 'daily-content-screen-routine' } })                
            } else {
              this.commitService.closeModals()
              setTimeout(() => {
                this.homeUIService.openNextComponent(DailyStepsContentModalComponent, { currentDay: routineDay, startFromScreen: 'daily-content-screen-routine' }, 'web-daily-steps-custom-modal')
              }, 1100);
            }
          }
        } catch (error) {
          this.route.navigate(['/main']);
        }
        this.refresh()
      }, (error) => {
        console.log("Response of path changed ", error)
      }
    )
  }

  refresh() {
    return new Promise(resolve => {
      // here to fetch the data and rerender the contents.
      this.appState.refreshMainComponent.emit()
      setTimeout(resolve, 0)
    })
  }

  gotoEditroutine() {
    mixpanel.track("Commitments-Edit Habit Clicked")
    this.homeUIService.openNextComponent(EditRoutineComponent)
  }

  getRoutineLevelInfo(routine,index)
  {
    this.web_todoLevelsList[index] = this.homeUIService.getRoutineLevel(routine)
    if(this.web_todoLevelsList[index]) {
      return true
    }
    else {
      return false;
    }
  }

  getCompletedRoutineLevels(routine, index) {
    this.web_completedLevelsList[index] = this.homeUIService.getCompletedRoutines(routine)
    if (this.web_completedLevelsList[index]) {
      return true
    }
    else {
      return false;
    }
  }

  openOptions(item: IonItemSliding,index,type) {
    this.closeOpenItems(index);
    if(type=='action'){
      this.actionCommitmentIndex =index;
    }
    else {
      this.habitCommitmentIndex =index;
    }
    item.open('start');
  }

  closeOpenItems(index?) {
    if(this.slidingItem){
      this.slidingItem.forEach((item)=>{
       item.closeOpened();
       this.actionCommitmentIndex =null;
       this.habitCommitmentIndex = null;
      });
    }   
  }

  getTitle(title){
    return title?.slice(15,-1)
  }

  getLevelTitle(level){
    return level?.title
  }

  openDocbyTool(post,event) {
    console.log('post :', post);
    console.log('event.target.id :', event.target.id);
    const docLink = document.getElementById(event.target.id);
    console.log('docLink :', docLink);
    if ((docLink.contains(event.target as Node))) {
      this.homeUIService.openDocument(post,this.dialog, this.currentPath.userGroup.name)
    }
  }

  async openModal(event: Event, action){
    //event.stopPropagation();
    this.actionId = action.id
    this.checked = true
    this.habitConfirm_condensed(action);
  }

  habitConfirm_condensed(habit) {
    var isHabitComplete = habit.confirmation
    try {
      // this.spinnerService.on()
      this.habitService.confirm(habit.id).subscribe(res => {
        // this.spinnerService.off()
        habit.confirmed = true
        habit.scheduledLater = false
        this.cdRef.detectChanges()
      })
    } catch (o_0) {
      console.log('Errors ', o_0)
    }
    try {
      if (this.currentPath.enabledAttributes.feedback && isHabitComplete == 0) {
        this.inithabitModal(habit)
      }
      else {
         this.getRoutines(this.currentUser?.id, this.currentPath.id);
         this.cdRef.detectChanges()
      }
    } catch (o_O) {
      console.log(o_O);
    }
  }

  async inithabitModal(habit) {
    // this.appState.set('currentDay', habit.startDay)
    const modal = await this.modalController.create({
      component: CompletedReviewModalComponent,
       componentProps: { currentHabit: habit },
      cssClass: this.isMobile?'mobile-custom-to_do_list-forms': 'web_feedbackForm_modal'
    });
    modal.onDidDismiss().then((modalDataResponse) => {
      if (modalDataResponse !== null) {
        this.appState.set("isCommented", false)
      }
      this.appState.set('updateActionPoints',true)
      this.closeOpenItems();
      this.getRoutines(this.currentUser.id, this.currentPath.id)
      this.setActionsList();
    });
    return await modal.present();
  }

  expandCompletedActions(){
    setTimeout(() => {
      this.expandCompleted = !this.expandCompleted;
    }, 10);
  }

  expandNotCommittedActions(){
    setTimeout(() => {
      this.expandNotCommitted = !this.expandNotCommitted;
      this.appState.set('expandNotCommittedActions', this.expandNotCommitted);
    }, 10);
  }

  expandEdit(){
    this.showEditOptions = true
    this.showEditHabits = true
  }

  cancelEdit(){
    this.showEditOptions = false
    this.showEditHabits = false
  }

  deleteAction(event: Event, action){
    event.stopPropagation();
    const requestData = {
      habit_id: action.id,
      user_id: this.currentUser.id
    }
    this.routineService.deleteUserAction(requestData).subscribe(
      (response) => {
        this.cdRef.detectChanges();
        this.getRoutines(this.currentUser?.id, this.currentPath.id);
        this.routineService.deleteActionFromPath(action,this.habits)
      },
      (error) => {
        console.log('Error for routines ', error)
      })
  }

  deleteHabit(event: Event, habit){
    console.log('routine :', habit);
    event.stopPropagation();
  }

  async showFeedbackForm(subItem) {
    this.levelId = subItem?.id
    let currentRoutine = this.homeUIService.getRoutineByLevel(subItem, this.routineList)
    let repsIndex = subItem?.reps_commit.findIndex((item) => item.is_completed === false);    
    if (repsIndex > -1) {
      const modal = await this.modalController.create({
        component: FeedbackFormComponent,
        componentProps: {
          mainItem: currentRoutine,
          subItem: subItem,
          index: repsIndex
        },
        cssClass: this.isMobile?'mobile-custom-to_do_list-forms': 'web_feedbackForm_modal'
      });
      modal.onDidDismiss().then((modelData) => {
        this.closeOpenItems();
        if (modelData !== null && modelData.data !== undefined) {
          this.onLogaRep.emit(modelData.data);
          this.logaRep(currentRoutine,subItem,repsIndex)
        }
      });
      return await modal.present();
    } else {
      this.cdRef.detectChanges()
      console.log('Complted level')
    }
  }

  repeatAction(event: Event, action){
    console.log('repeat action')
   // event.stopPropagation();
    this.reschedule(action)
    const requestData = {
      habit_id: action.id,
      user_id: this.currentUser.id
    }
    this.routineService.repeatUserAction(requestData).subscribe(
      (response) => {
        this.showActionCheckbox = true
        this.cdRef.detectChanges();
        // this.getRoutines(this.currentUser?.id, this.currentPath.id,action.id);
      },
      (error) => {
        console.log('Error for routines ', error)
      })
  }

  toggleExpandAction(index: number) {
    if (this.actionClicked[index]) {
      setTimeout(() => {
        this.actionClicked[index] = !this.actionClicked[index]
      }, 350);
    }
    else {
          this.actionClicked[index] = !this.actionClicked[index]
    }
    this.isItemExpanded[index] = !this.isItemExpanded[index];
  }
  toggleExpandRoutine(index: number) {
     if (this.routineClicked[index]) {
      setTimeout(() => {
        this.routineClicked[index] = !this.routineClicked[index]
      }, 350);
    }
    else {
          this.routineClicked[index] = !this.routineClicked[index]
    }
    this.isRoutineExpanded[index] = !this.isRoutineExpanded[index];
  }
  toggleCompletedAction(index: number) {
     if (this.completedActionClicked[index]) {
      setTimeout(() => {
        this.completedActionClicked[index] = !this.completedActionClicked[index]
      }, 350);
    }
    else {
          this.completedActionClicked[index] = !this.completedActionClicked[index]
    }
    this.isActionExpanded[index] = !this.isActionExpanded[index];
  }
  toggleExpandncAction(index: number) {
      if (this.noncompletedActionClicked[index]) {
      setTimeout(() => {
        this.noncompletedActionClicked[index] = !this.noncompletedActionClicked[index]
      }, 350);
    }
    else {
          this.noncompletedActionClicked[index] = !this.noncompletedActionClicked[index]
    }
    this.ncAction[index] = !this.ncAction[index];
  }
  toggleExpandncAction1(index: number) {
      if (this.noncompletedActionClicked1[index]) {
      setTimeout(() => {
        this.noncompletedActionClicked1[index] = !this.noncompletedActionClicked1[index]
      }, 350);
    }
    else {
          this.noncompletedActionClicked1[index] = !this.noncompletedActionClicked1[index]
    }
    this.ncAction1[index] = !this.ncAction1[index];
  }
  toggleExpandUnCommitedRoutine(index) {
      if (this.isUncommitedRoutineClicked[index]) {
      setTimeout(() => {
        this.isUncommitedRoutineClicked[index] = !this.isUncommitedRoutineClicked[index]
      }, 350);
    }
    else {
          this.isUncommitedRoutineClicked[index] = !this.isUncommitedRoutineClicked[index]
    }
    this.uncommitedRoutine[index] = !this.uncommitedRoutine[index];
  }
  toggleExpandCompletedRoutine(index) {
      if (this.isUncompletedRoutineClicked[index]) {
      setTimeout(() => {
        this.isUncompletedRoutineClicked[index] = !this.isUncompletedRoutineClicked[index]
      }, 350);
    }
    else {
          this.isUncompletedRoutineClicked[index] = !this.isUncompletedRoutineClicked[index]
    }
    this.completedRoutine[index] = !this.completedRoutine[index];
  }
  recommitcompletedHabit(event, routine) {
     const requestData = {
                'user_id': routine.user_id,
                'path_id': routine.path_id,
                'is_reset': true,
                'routine_info_id': routine.routine_info_id
              }
              if (requestData.user_id == 0) {
                return true;
              } else {
                this.routineService.editRoutine(requestData).subscribe(
                  (response) => {
                    console.log('status', response);
                    this.cdRef.detectChanges();
                    this.editRoutineStatus()
                    this.commitUncompleteHabit(event, routine);
                  },
                  (error) => {
                    console.log('Error for edit routines', error)
                  })
              }
  }
  commitUncompleteHabit(event: Event, routine){
    // this.commitService.closeModals()
    //    setTimeout(() => {
    //       this.homeUIService.openNextComponent(
    //         DailyStepsContentModalComponent,
    //         {currentDay: routine.schedule_day, startFromScreen: 'daily-content-screen-routine'},
    //         'web-daily-steps-custom-modal')
    //     }, 1100);
              localStorage.removeItem(
                `Toggle:Day:${routine.schedule_day}:LevelNumber:${1}:true`
              )
              this.closeOpenItems();
              if (this.currentPath.id != routine.path_id) {
                this.appState.set('isRoutineEdited', true)
                // this.spinnerService.on()
                this.homeUIService.changeSubPath(routine.path_id,routine.schedule_day)
              } else {
                this.homeUIService.dismissAllModals().then(() => {
                  this.appState.set('levelUpdated', undefined)
                  this.appState.set('routine_schedule_day', routine.schedule_day)
                  this.appState.set('isRoutineEdited', true)
                  this.appState.set('LevelNumber', 1)
                  this.appState.set('isFromEdit', true)
                  this.appState.set('hidePrevScreen', true);
                  this.appState.set("allowScreenCount", undefined)
                  
                  if (this.isMobile) {
                    setTimeout(() => {
                      this.route.navigate(['/content', routine.schedule_day], { queryParams: { startFromScreen: 'daily-content-screen-routine-levels' } })
                    }, 550);
                  } else {
                    this.commitService.closeModals()
                    setTimeout(() => {
                      this.homeUIService.openNextComponent(DailyStepsContentModalComponent,{currentDay: routine.schedule_day,startFromScreen: 'daily-content-screen-routine-levels'},'web-daily-steps-custom-modal')                                          
                    }, 1100);
                  }
                })
              }
      
  }

  async editHabitDetails(completed: boolean, routine) {
    const alert = await this.alertController.create({
      header: this.i18n.t('Are you sure?'),
      subHeader: completed ? this.i18n.t('Do you want to commit to this habit?') : this.i18n.t('Do you want to stop building this habit?'),
      buttons: [
        {
          text: "No",
          cssClass: 'custom-alert-textcase',
          handler: () => {
            if (completed) {
              this.editRoutineStatus()
            }
            else {
              this.editRoutineStatus()
            }
          },
          role: 'cancel',
        },
        {
          text: "Yes",
          cssClass: 'custom-alert',
          handler: () => {
            if (completed) {
              console.log('routine.schedule_day', routine.schedule_day);
              localStorage.removeItem(
                `Toggle:Day:${routine.schedule_day}:LevelNumber:${1}:true`
              )
              this.closeOpenItems();
              if (this.currentPath.id != routine.path_id) {
                this.appState.set('isRoutineEdited', true)
                // this.spinnerService.on()
                this.homeUIService.changeSubPath(routine.path_id,routine.schedule_day)
              } else {
                this.homeUIService.dismissAllModals().then(() => {
                  this.appState.set('levelUpdated', undefined)
                  this.appState.set('routine_schedule_day', routine.schedule_day)
                  this.appState.set('isRoutineEdited', true)
                  this.appState.set('LevelNumber', 1)
                  this.appState.set('isFromEdit', true)
                  this.appState.set('hidePrevScreen', true);
                  this.appState.set("allowScreenCount", undefined)
                  
                  if (this.isMobile) {
                    setTimeout(() => {
                      this.route.navigate(['/content', routine.schedule_day], { queryParams: { startFromScreen: 'daily-content-screen-routine-levels' } })
                    }, 550);
                  } else {
                    this.commitService.closeModals()
                    setTimeout(() => {
                      this.homeUIService.openNextComponent(DailyStepsContentModalComponent,{currentDay: routine.schedule_day,startFromScreen: 'daily-content-screen-routine-levels'},'web-daily-steps-custom-modal')                                          
                    }, 1100);
                  }
                })
              }
            }
            else {
              const requestData = {
                'user_id': routine.user_id,
                'path_id': routine.path_id,
                'is_reset': true,
                'routine_info_id': routine.routine_info_id
              }
              if (requestData.user_id == 0) {
                return true;
              } else {
                this.routineService.editRoutine(requestData).subscribe(
                  (response) => {
                    console.log('status', response);
                    this.cdRef.detectChanges();
                    this.editRoutineStatus()
                  },
                  (error) => {
                    console.log('Error for edit routines', error)
                  })
              }
            }
          },
        },
      ]
    });
    await alert.present();
  }
  //  @HostListener('document:click', ['$event'])
  // onClick(e) {
  //    const target = e.target as HTMLElement;
  //    console.log("target in community", target, e.target.target, e.target.tagName.toLowerCase());
  //     if (target && target.classList && target.classList.contains('web-todo-name')) {
  //      // Click was on the child link, prevent parent's click event
  //        if (e.target.tagName.toLowerCase() == 'a' && e.target.target == '_blank') {
  //       console.log("called");
  //          e.stopPropagation()
  //          e.preventDefault()
  //        }
  //     }
  // }
}