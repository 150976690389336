import { Base } from "../shared/base"
import { ChecklistItem } from "./checklist-item.model"
import { Schedulable } from "../shared/schedulable"

export enum ChecklistDisplay {
  Daily,
  Fixed
}

export class Checklist extends Base implements Schedulable {
  init() {
    this._fields = ['id', 'display', 'text', 'items', 'startDay', 'endDay', 'status', 'enabled']
    this._nestedFields = {
      items: ChecklistItem
    }
    this.items = []
    this.isMandatory = false
  }

  afterInit() {
    this.isCompleted = this.items.some(item => { return item.isCompleted })
  }

  public id: number  
  public text: string  
  public display: ChecklistDisplay
  public items: ChecklistItem[]
  public startDay: number  
  public endDay: number  
  public status: string  
  public isMandatory: boolean
  public isCompleted: boolean
  public enabled: boolean  

  getTitle() {
    return 'Apply SYS Learnings'
  }

  getTitleKey() {
    return 's_c'
  }
  
  getDescription() {
    return this.text
  }

  getColorClass() {
    return 'stat-color-5'
  }

  completeBy() {
    return 'ByType'
  }
}