import { Component, Inject, OnInit, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fadeInLeftAnimation, fadeInRightAnimation, slideInDownAnimation, slideInUpAnimation } from 'angular-animations';
import { AppState } from '../app.service';
import { DailyStepsContentModalComponent } from '../new-design/daily-steps-content-modal/daily-steps-content-modal.component';
import { ModalController } from '@ionic/angular';
import { HomeUiService } from '../new-design/home-ui.service';

@Component({
  selector: 'app-alert-unlock-level',
  templateUrl: './alert-unlock-level.component.html',
  styleUrls: ['./alert-unlock-level.component.scss'],
  animations: [
    fadeInLeftAnimation({ duration: 300 }),
    fadeInRightAnimation({ duration: 300 }),
    slideInUpAnimation({ duration: 300 }),
    slideInDownAnimation({ duration: 300 })
  ],
})
export class AlertUnlockLevelComponent implements OnInit {
  levelUpdateData: any;
  @Input() nextLevelData;
  isMobile;
  // constructor(public dialogRef: MatDialogRef<AlertUnlockLevelComponent>,
  //   @Optional() @Inject(MAT_DIALOG_DATA) public data: any,) { }
  constructor(private router: Router, private homeUIService: HomeUiService,
    private appState: AppState,private modalController: ModalController) {
    this.isMobile = this.appState.get('isMobile');
    if(this.isMobile) {
      this.levelUpdateData = this.router.getCurrentNavigation().extras.state.data;
    }
  }

  ngOnInit() { 
    setTimeout(()=>{
      if(!this.isMobile) {
        this.levelUpdateData =this.nextLevelData
      }
    },200)
  }

  nextScreen() {
    this.appState.set("allowScreenCount", undefined)
    this.appState.set('levelUpdated', true)
    if(this.isMobile) {
      this.router.navigate(['/content', this.levelUpdateData], { queryParams: { startFromScreen: 'daily-content-screen-routine' } })
    }
    else {
      this.modalController.dismiss({isAlertUnlockClosed:true,currentDay:this.levelUpdateData});
    }
  }

}
