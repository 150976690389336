import { Injectable } from "@angular/core";

@Injectable()
export class NotificationService {
  _navigator: any;

  constructor() {
    this._navigator = navigator;
  }

  public alert(message, title = null, options = null) {
    console.log("error Alert main");
    if (
      this._navigator &&
      this._navigator.notification &&
      this._navigator.notification.alert
    ) {
      // We are at cordova
      console.log("We are at cordova", this._navigator);
      this._navigator.notification.alert(message, () => {}, title);
    } else {
      console.log("error Alert ");
      //this.toastrService.error(message, title, options);
    }
  }

  public successAlert(message, title = null, options = null) {
    console.log("success Alert Main");

    if (
      this._navigator &&
      this._navigator.notification &&
      this._navigator.notification.alert
    ) {
      // We are at cordova
      console.log("We are at cordova", this._navigator);
      this._navigator.notification.alert(message, () => {}, title);
    } else {
      console.log("success Alert ");

      //this.toastrService.success(message, title, options);
    }
  }

  public unlocksuccessAlert(message, title = null, options = null, buttonName):boolean{
    let isConfirm = false;
    console.log("success Alert Main");
    if (
      this._navigator &&
      this._navigator.notification &&
      this._navigator.notification.confirm
    ) {
      // We are at cordova
      console.log("We are at cordova", this._navigator);
      this._navigator.notification.confirm(
        message,
        confirmCallback,
        title,
        buttonName
      );
    } else {
      console.log("success Alert ");

      //this.toastrService.success(message, title, options);
    }
    function confirmCallback(buttonIndex) {
      console.log("Button index clicked !",buttonIndex);
     if (buttonIndex==2) {
        isConfirm = true;
        }
        console.log("Is confirm ",isConfirm);
      }
    return isConfirm;
  }

  public confirm(message, title = null) {
    console.log("confirm Alert main");

    if (
      this._navigator &&
      this._navigator.notification &&
      this._navigator.notification.confirm
    ) {
      // We are at cordova
      console.log("We are at cordova", this._navigator);
      this._navigator.notification.confirm(message, () => {}, title);
    } else {
      console.log("confirm Alert");
      confirm(message);
    }
  }
}
