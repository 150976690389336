import { Base } from '../shared/base'
import { SurveyOption } from './survey-option.model'
export enum ServeyBenchmarkType {
  PathAverage,
  Custom
}
export class Survey extends Base {
  init() {
    this._fields = ['id', 'question', 'isMandatory', 'actionPoints', 'startDay', 'endDay', 'enabled', 'options', 
    'ptype', 'lowerlabel', 'higherlabel', 'lowerscale', 'higherscale','benchLabel','benchmarkType','rationale','benchmarkNumber', 'isCompleted', 'imageUrl']
    this._nestedFields = {
      options: SurveyOption
    }
  }

  public id: number
  public question: string
  public options: SurveyOption[]

  public isMandatory: boolean
  public actionPoints: number
  public startDay: number
  public endDay: number

  public enabled: boolean

  public ptype: string
  public lowerlabel: string
  public higherlabel: string
  public lowerscale: number
  public higherscale: number
  public isCompleted: boolean
  public benchLabel: string;
  public rationale: string;
  public benchmarkType: ServeyBenchmarkType
  public benchmarkNumber: string
  public imageUrl: string
}
