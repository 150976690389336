import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core'
import { EventManager } from '@angular/platform-browser'

const IS_BROWSER = typeof window !== 'undefined'
const WINDOW: any = IS_BROWSER ? window : {}
const IS_TOUCH_DEVICE = IS_BROWSER ? 'ontouchstart' in WINDOW.document.documentElement : false


@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit, AfterViewInit {
  @ViewChild('timelineContainer') timelineContainer: ElementRef
  @ViewChild('timelineElement') timelineElement: ElementRef
  containerHeight: number
  itemHeight: number
  timeline: string[] = []
  meridian = false
  status: string
  state: any = {}
  private zeroPosition: number
  private activeTimeId: number
  private lineHeight: number

  constructor(private eventManager: EventManager) {

  }

  ngOnInit() {
    this.fillTimeline()
  }

  ngAfterViewInit() {
    const el = this.timelineContainer.nativeElement
    this.containerHeight = el.offsetHeight
    if (IS_TOUCH_DEVICE) {
      this.eventManager.addEventListener(el, 'touchstart', this.onPointerDown.bind(this))
      this.addEventHandler('touchmove', this.onPointerMove)
      this.addEventHandler('touchend', this.onPointerUp)
      this.addEventHandler('touchcancel', this.onPointerUp)
    } else {
      this.eventManager.addEventListener(el, 'mousedown', this.onPointerDown.bind(this))
      this.addEventHandler('mousemove', this.onPointerMove)
      this.addEventHandler('mouseup', this.onPointerUp)
    }
    const ticksList = this.timelineElement.nativeElement
    this.lineHeight = ticksList.offsetHeight
    this.itemHeight = ticksList.children[0].offsetHeight
    this.zeroPosition = Math.floor(this.containerHeight / 2 - this.itemHeight / 2)
    this.setActiveTimeByTime('11:00')
  }

  addEventHandler(event, handler) {
    this.eventManager.addGlobalEventListener('document', event, handler.bind(this))
  }

  onPointerDown(event) {
    const {target} = event
    event.preventDefault()

    this.status = 'pulling'
    this.state = {
      startY: event.changedTouches ? event.changedTouches[0].pageY : event.pageY,
      offsetY: 0,
      top: this.timelineElement.nativeElement.offsetTop
    }

  }

  onPointerMove(event) {
    const {target} = event
    if (this.status !== 'pulling') {
      return
    }
    event.preventDefault()
    event.stopPropagation()
    const endY = event.changedTouches ? event.changedTouches[0].pageY : event.pageY
    const offsetY = this.state.top + (endY - this.state.startY)
    // TODO: Check for bounds
    this.timelineElement.nativeElement.style.top = `${offsetY}px`

    return false
  }

  onPointerUp(event) {
    const {target} = event
    if (this.status !== 'pulling') {
      return
    }
    event.preventDefault()
    event.stopPropagation()

    this.status = 'released'
    this.state = null

    if (this.timelineElement.nativeElement.offsetTop > this.zeroPosition) {
      this.timelineElement.nativeElement.style.top = `${this.zeroPosition}px`
    }

    if (this.timelineElement.nativeElement.offsetTop + this.lineHeight < this.zeroPosition + this.itemHeight) {
      this.timelineElement.nativeElement.style.top = `${this.zeroPosition + this.itemHeight - this.lineHeight}px`
    }
    const point = -1 * this.timelineElement.nativeElement.offsetTop +
      Math.floor(this.containerHeight / 2)
    const n = Math.ceil(point / this.itemHeight)
    const i = n - 1

    this.setActiveTimeByIndex(i)

    return false
  }

  fillTimeline(step = 5) {
    let currentTime = 0
    let stopTime = this.meridian ? 12 * 60 : 24 * 60
    while (currentTime <= stopTime) {
      this.timeline.push(this.minutesToStr(currentTime))
      currentTime += 30
    }
  }

  minutesToStr(minutes) {
    let hours = Math.floor(minutes / 60)
    let mins = minutes % 60
    return (hours < 10 ? '0' + hours : hours) + ':' +
      (mins < 10 ? '0' + mins : mins)
  }

  setActiveTimeByTime(time: string) {
    this.removeActiveClass()
    let index = this.timeline.indexOf(time)
    this.timelineElement.nativeElement.children[index].classList.add('active')
    this.timelineElement.nativeElement.style.top = `${this.zeroPosition - index * this.itemHeight}px`
    this.activeTimeId = index
  }

  setActiveTimeByIndex(index: number) {
    this.removeActiveClass()
    this.timelineElement.nativeElement.children[index].classList.add('active')
  if(index<=20){
    this.timelineElement.nativeElement.style.top = `${this.zeroPosition - (index * this.itemHeight)}px`
  }
  else  if(index<30){
      this.timelineElement.nativeElement.style.top = `${this.zeroPosition -5 - (index * this.itemHeight)}px`
    }else{
      this.timelineElement.nativeElement.style.top = `${this.zeroPosition-10 - (index * this.itemHeight)}px`
    }

    // this.timelineElement.nativeElement.style.top = `${this.timelineElement.nativeElement.style.top - 100}px`
    this.activeTimeId = index
  }

  removeActiveClass() {
    if (this.activeTimeId == null) {
      return
    }
    this.timelineElement.nativeElement.children[this.activeTimeId].classList.remove('active')
  }

  getTime() {
    if (this.activeTimeId == null) {
      return 'N/A'
    }
    return this.timeline[this.activeTimeId]
  }

}
