import { Component, DoCheck, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AppState } from '../app.service';
import { CordovaService } from '../cordova.service';
import { HomeV5Service } from '../pages/home-v5/home-v5.service';
import { Path } from '../path/path.model';
import { User } from '../user/user.model';
import { RoutineInfo } from './routine-info.model';
import { HomeUiService } from '../new-design/home-ui.service';
import { RoutineV2Service } from './routine-v2.service';
import { I18n } from '../i18n.service';

@Component({
  selector: 'first90-routine-v2',
  templateUrl: './routine-v2.component.html',
  styleUrls: ['./routine-v2.component.scss']
})
export class RoutineV2Component implements OnInit, DoCheck, OnDestroy {

  @Input() routine: RoutineInfo
  @Input() isTitleScreen: boolean
  @Input() todoRoutines;
  @Input() isSubLevelScreen: boolean
  @Input() isImInCompleted: any
  @Input() inverted = false
  @Input() currentDay;
  selectedLevels = []
  unCheckedLevels = []
  imInCompleted: boolean
  islevelUpdated: boolean
  flag=false;
  isLoaded=true;
  unCheckedRoutineLevels;
  currentPath: Path;
  routineData: RoutineInfo;
  routineList;
  currentUser: User;
  comitRoutine;
  constructor(private cordovaService: CordovaService,
    private appState: AppState, 
    private routineV2Service: RoutineV2Service,
    private i18n: I18n,
    public homeUIService: HomeUiService,
    private homeService: HomeV5Service)
     {
      this.currentPath = this.routineV2Service.getRoutinePathDetails();
    }
    getRoutines() {
     this.todoRoutines?.forEach((routine)=>{
      if(routine.routine_info_id ==this.routineData?.id) {
          this.comitRoutine =routine;
      }
     })
     if(this.comitRoutine?.routine.length != this.routineData?.levels.length) {

     
     this.comitRoutine?.routine.forEach((level)=>{
      this.routineData?.levels.forEach((routineLevel)=>{
        let status =false;
        this.comitRoutine?.routine.forEach((level)=>{
          if(level.id==routineLevel.id){
             status =true
          }
        })
        if(level.id == routineLevel.id || status) {
          routineLevel.enabled =true;
          this.isLoaded=false;
        }
        else {
          routineLevel.enabled =false;
          this.isLoaded=false;

        }
      })
     })
    }
      if(!this.isLoaded) {
        this.isLoaded =true;
      }
    }
   UncheckedLevelsStatus() {
    this.unCheckedRoutineLevels =[];
     this.routineData?.levels.forEach((routineLevel)=>{
      this.unCheckedLevels.forEach((level)=>{
        if(routineLevel.id== level.id) {
          this.unCheckedRoutineLevels.push(routineLevel)
          this.unCheckedRoutineLevels =[...new Set(this.unCheckedRoutineLevels)]
        }
      })
     })
    
     if( this.unCheckedRoutineLevels && this.unCheckedRoutineLevels.length>0) {
      this.unCheckedRoutineLevels.forEach((level)=>{
        level.enabled=false;
        this.routine?.levels?.push(level);

      })
      this.routine.levels= [...new Set(this.routine.levels)]
      this.routine.levels.sort((a,b)=>a.id-b.id);
     }
  }

    findForCurrentDay(collection: any): any {
      return collection.find(
        item => item.startDay <= this.currentDay && this.currentDay <= item.endDay
      )
    }
  
  ngOnInit() {
    
    if(this.appState.get("isLoggedIn")) {
      this.isLoaded =false;
    }
     this.unCheckedLevels =this.appState.get('unCheckedLevels');
     this.unCheckedLevels = this.homeService.removeDuplicates(this.unCheckedLevels, "index");
     this.unCheckedLevels=[...new Set(this.unCheckedLevels)];
     if(this.unCheckedLevels ==undefined || this.unCheckedLevels ==null) {
      this.unCheckedLevels =[];
     }
     this.selectedLevels =this.appState.get('selectedLevelIds');
     this.selectedLevels = this.homeService.removeDuplicates(this.selectedLevels, "index");
     this.selectedLevels=[...new Set(this.selectedLevels)];
     if(this.selectedLevels ==undefined || this.selectedLevels ==null) {
      this.selectedLevels =[];
     }
  }

  ngOnChanges() {
    this.unCheckedLevels =this.appState.get('unCheckedLevels');
    this.unCheckedLevels = this.homeService.removeDuplicates(this.unCheckedLevels, "index");
    this.unCheckedLevels=[...new Set(this.unCheckedLevels)];

    this.selectedLevels =this.appState.get('selectedLevelIds');
    this.selectedLevels = this.homeService.removeDuplicates(this.selectedLevels, "index");
    this.selectedLevels=[...new Set(this.selectedLevels)];
   
  }
  ngDoCheck(){
    this.routineData = this.findForCurrentDay(this.currentPath.routineInfo);
    this.islevelUpdated = this.appState.get('levelUpdated')
    if(this.islevelUpdated) {
      this.appState.set("isLoggedIn", undefined);
      this.isLoaded =true;
    }
    if(this.appState.get("isLoggedIn"))  {
      this.getRoutines();
    }
    
    if(this.islevelUpdated) {
      this.appState.set("isLoggedIn", undefined);
      this.isLoaded =true;
      this.routine?.levels.forEach((level)=>{
        if(!level.enabled) {
          let originalIndex =  this.routine?.levels.findIndex(item => item.id === level.id);
          this.routine?.levels.splice(originalIndex,1);
          this.routine.levels= [...new Set(this.routine.levels)];

        }
      })
      this.routine.levels= [...new Set(this.routine.levels)];
      this.routine.levels.sort((a,b)=>a.id-b.id);
    }
   
    else {
      this.UncheckedLevelsStatus();
    }
    if(this.selectedLevels && this.selectedLevels.length>0) {
      this.routine?.levels.forEach((element)=>{
        if(element.id=== this.selectedLevels[0].id)
        {
          this.flag =true;
        }
      })
    if(!this.flag) {
      this.unCheckedLevels =[]
      this.setData();
    }
    if(this.unCheckedLevels && this.unCheckedLevels.length>0){
      this.routine?.levels.forEach(element => {
         this.unCheckedLevels.forEach((level)=>{
          if(level.id !==element.id && !(this.unCheckedLevels.filter((level)=>level.id ==element.id)))
          {
              this.selectedLevels.push({'id':element.id, 'index':this.routine.levels.indexOf(element)})
              this.selectedLevels=[...new Set(this.selectedLevels)];

          }
         })
        
      });
    }
    else {
      this.selectedLevels =this.appState.get('selectedLevelIds')
      this.routineData?.levels.forEach(element => {
        if(!element.enabled) {
          this.unCheckedLevels.push({'id':element.id, 'index':this.routine.levels.indexOf(element)})
          this.unCheckedLevels = this.homeService.removeDuplicates(this.unCheckedLevels, "index");
          this.unCheckedLevels=[...new Set(this.unCheckedLevels)];
          this.appState.set('unCheckedLevels', this.unCheckedLevels)
        }
      });
    }
  }
    else if(this.unCheckedLevels===undefined || this.unCheckedLevels==null || (this.unCheckedLevels && this.unCheckedLevels.length==0)){
      this.selectedLevels =this.appState.get('selectedLevelIds');      
      if((this.selectedLevels && this.selectedLevels.length==0 )|| this.selectedLevels ==undefined || this.selectedLevels ==null) {
       this.setData();
      }
    }
    else if((this.selectedLevels && this.selectedLevels.length==0 )|| this.selectedLevels ==undefined || this.selectedLevels ==null) {
      this.setData();
     }
    this.selectedLevels = this.homeService.removeDuplicates(this.selectedLevels, "index");
    this.selectedLevels=[...new Set(this.selectedLevels)];
    this.appState.set('selectedLevelIds', this.selectedLevels)
    this.unCheckedLevels = this.homeService.removeDuplicates(this.unCheckedLevels, "index");
    this.unCheckedLevels=[...new Set(this.unCheckedLevels)];
    this.appState.set('unCheckedLevels', this.unCheckedLevels)
    this.imInCompleted = this.appState.get('isImInCompleted') 

  }

  setData() {
    this.selectedLevels =[]
    this.routine?.levels.forEach(element => {
     if(element.enabled) {
      this.selectedLevels.push({'id':element.id, 'index':this.routine.levels.indexOf(element)})
      this.selectedLevels = this.homeService.removeDuplicates(this.selectedLevels, "index");
      this.selectedLevels=[...new Set(this.selectedLevels)];
      this.appState.set('selectedLevelIds', this.selectedLevels)
     }  
     else {
      this.unCheckedLevels.push({'id':element.id, 'index':this.routine.levels.indexOf(element)})
      this.unCheckedLevels = this.homeService.removeDuplicates(this.unCheckedLevels, "index");
      this.unCheckedLevels=[...new Set(this.unCheckedLevels)];
      this.appState.set('unCheckedLevels', this.unCheckedLevels)
     }
    });
  }
  getCheckedIds($event,level, index){

    let isChecked = $event.detail.checked
    if (isChecked === false) {
      this.appState.set("isLoggedIn", undefined);
      this.unCheckedLevels.push({'id':level.id, 'index':this.routine?.levels.indexOf(level)})
      let originalIndex = this.selectedLevels.findIndex(item => item.id === level.id);
      this.selectedLevels.splice(originalIndex,1)
      this.UncheckedLevelsStatus();
    }else if(isChecked === true){
      this.appState.set("isLoggedIn", undefined);
      this.selectedLevels.push({'id':level.id, 'index':this.routine?.levels.indexOf(level)>=0?this.routine?.levels.indexOf(level):this.routineData?.levels.indexOf(level)})

      this.unCheckedLevels =[...new Set(this.unCheckedLevels)];
      let originalIndex = this.unCheckedLevels.findIndex(item => item.id === level.id);
      this.unCheckedLevels.splice(originalIndex,1)
      this.AppendLevelsToRoutine();
    }

    if (this.selectedLevels) {
      this.selectedLevels.sort(function (a, b) {
        return a.index - b.index;
      });
      this.selectedLevels = this.homeService.removeDuplicates(this.selectedLevels, "index");
      this.appState.set('selectedLevelIds', this.selectedLevels)
      this.appState.set('indexOfLevel', this.selectedLevels.length>0?this.selectedLevels[0].index:undefined)
    }

    if (this.unCheckedLevels) {
      this.unCheckedLevels.sort(function (a, b) {
        return a.index - b.index;
      });
      let unCheckedLevels = this.homeService.removeDuplicates(this.unCheckedLevels, "index");
      unCheckedLevels = [...new Set(unCheckedLevels)]
      this.unCheckedLevels = unCheckedLevels;
      this.appState.set('unCheckedLevels', unCheckedLevels)
    }
    else {
      this.unCheckedLevels =[];
      this.appState.set('unCheckedLevels', undefined)
    }
  }

  AppendLevelsToRoutine() {
    if(this.selectedLevels){
      function getUnique(arr) {
        const unique = arr.filter((id,index)=>{
          return arr.indexOf(id)===index
        })
        return unique;
      }

      let finalCheckedLevels = []
      finalCheckedLevels = getUnique(this.selectedLevels) 
      finalCheckedLevels =[...new Set(finalCheckedLevels)];
      finalCheckedLevels.forEach(checkedLevel => {
        this.routine?.levels.forEach(routineLevel => {
          if ( checkedLevel.id != routineLevel.id) {
            this.routineData?.levels.forEach((level)=>{
              if(level.id== checkedLevel.id) {
               level.enabled=true;
               let originalIndex =  this.routine?.levels.findIndex(item => item.id === level.id);
                this.routine?.levels.splice(originalIndex,1);
                this.routine.levels.push(level);
                this.routine.levels= [...new Set(this.routine.levels)]
                this.routine.levels.sort((a,b)=>a.id-b.id);
              

              }
            });
            this.routine.levels =[... new Set(this.routine.levels)]
          }
        });
      })
      this.routine.levels.forEach((level)=>{
        if(level.enabled) {
          this.selectedLevels.push({'id':level.id, 'index':this.routine?.levels.indexOf(level)})
          this.selectedLevels=[...new Set(this.selectedLevels)];
        }
        else {
          this.unCheckedLevels.push({'id':level.id, 'index':this.routine?.levels.indexOf(level)})
          this.unCheckedLevels=[...new Set(this.unCheckedLevels)];
        }
      })
      this.selectedLevels = this.homeService.removeDuplicates(this.selectedLevels, "id");
      this.unCheckedLevels = this.homeService.removeDuplicates(this.unCheckedLevels, "id");
      this.appState.set('unCheckedLevels', this.unCheckedLevels)
      this.appState.set('selectedLevelIds', this.selectedLevels)
    }
  }
 ngOnDestroy() {
  this.appState.set("isLoggedIn", undefined);

 }
}
