import { Component, Input, OnInit } from '@angular/core';
import { AppState } from '../app.service';

@Component({
  selector: 'profile-badge',
  templateUrl: './profile-badge.component.html',
  styleUrls: ['./profile-badge.component.scss']
})
export class ProfileBadgeComponent implements OnInit {
  @Input() badgeId: string
  @Input() greyedOut = false
  @Input() colour: string

  constructor(public appState: AppState) {

   }

  ngOnInit() {
  }

}
