import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-foozi-ai-library-popup',
  templateUrl: './foozi-ai-library-popup.component.html',
  styleUrls: ['./foozi-ai-library-popup.component.scss'],
})
export class FooziAiLibraryPopupComponent implements OnInit {

  constructor(private modalController: ModalController) { }

  ngOnInit() {}
  close(val?) {
    if (val) {
      this.modalController.dismiss({ isMsgSaved: val });
    }
    else {
      this.modalController.dismiss({ isMsgSaved: val });
    }
  }
}
