import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment'

@Injectable()
export class MobileChatServiceService {

  GETUSERSLIKELIST = '/getUserLikeList'
  USERCOMMENTLIST = '/postUserList'
  DELETEPOST = '/deletePost'
  TRANSLATE = '/post_translate'
  API_URL: string = environment.apiBaseUrl
  postId: any;
  requestUserList: any;
  isLikeList: any=true;
  mode: any 

  constructor(private httpclient: HttpClient,) { 

  }


  public getUserLikeList(id,mode): Observable<any>{
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
    const params = new HttpParams().append('id', id)
    .append('mode', mode);
    return this.httpclient.get(this.API_URL+this.GETUSERSLIKELIST,{headers,params});
  }


  public getUserCommentList(data): Observable<any>{
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
    const params = new HttpParams({fromObject:data})
    return this.httpclient.get(this.API_URL+this.USERCOMMENTLIST,{headers,params});
  }

  public deletePost(id):Observable<any>{
    const headers = new HttpHeaders();
    headers.set('Authorization', localStorage.getItem('token'));
    const params = new HttpParams().append('id', id);
    return this.httpclient.delete(this.API_URL+this.DELETEPOST,{headers,params})
  }

  public translateContent(requestData): Observable<any>{
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));  
    return this.httpclient.post(this.API_URL+this.TRANSLATE,requestData,{headers})
  }


  public getPostId(){
    return this.postId
  }

  public setPostId(postId){
    this.postId = postId
  }

  public getMode(){
    return this.mode
  }

  public setMode(mode){
    this.mode = mode
  }
  

  public getRequestUserList(){
    return this.requestUserList
  }

  public setRequestUserList(requestUserList){
    this.requestUserList = requestUserList
  }

  public getIsLikeList(){
    return this.isLikeList
  }

  public setIsLikeList(isLikeList){
    this.isLikeList = isLikeList
  }

 


}
