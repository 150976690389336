import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class WalletService {
  public actionPoints: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  
  constructor() { }

  update(actionPoints: any) { // Number or String
    this.actionPoints.next(Number(actionPoints))
  }
}
