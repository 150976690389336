import {Injectable} from '@angular/core'
import {SnoozedHabit} from '../daily-steps/daily-steps.model'
import {AppState} from '../app.service'
import { Poll } from '../poll/poll.model'
import { ContentSurvey } from '../content-survey/content-survey.model'
import { Question } from '../question/question.model'
import { Habit } from '../habit/habit.model'
import { Observable } from 'rxjs'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { HomeV5Service } from '../pages/home-v5/home-v5.service'

@Injectable()
export class DailyStepsService {

  API_URL: string = environment.apiBaseUrl
  actionList = '/getAllActionInfo'
  
  public snoozedSteps: SnoozedHabit[]
  dailySteps: any
  currentUser: any
  currentPath: any
  currentDay: any
  poll: Poll
  survey: ContentSurvey
  document: Document
  question: Question
  habit: Habit

  constructor(private appState: AppState, private httpclient: HttpClient, 
    private homev5Service: HomeV5Service) {

    this.currentUser = this.appState.get('currentUser')
    this.currentPath = this.appState.get('currentPath')
    this.currentDay = this.appState.get('currentScheduleDay')

    if (this.poll) {
      this.poll = this.findForCurrentDay(this.currentPath.polls) 
    }
    if (this.survey) {
      this.survey = this.findForCurrentDay(this.currentPath.contentSurveys)      
    }
    if (this.document) {
      this.document = this.findForCurrentDay(this.currentPath.gridDocuments)
    }
    if (this.question) {
      this.question = this.findForCurrentDay(this.currentPath.gridQuestions)
    }
    if (this.habit) {
      this.habit = this.findForCurrentDay(this.currentPath.habits)
    }
    

    this.dailySteps = [
      { kind: 'poll',
        title: 'h_ds_p',
        icon: 'glyphicon glyphicon-question-sign'
      },
      { kind: 'document',
        title: 'h_ds_d',
        icon: 'glyphicon glyphicon-list'
      },
      { kind: 'question',
        title: 'h_ds_q',
        icon: 'glyphicon glyphicon-comment'
      },
      { kind: 'survey',
        title: 'h_ds_s',
        icon: 'glyphicon glyphicon-comment'
      },
      { kind: 'habit',
        title: 'h_ds_h',
        icon: 'glyphicon glyphicon glyphicon-list'
      }
    ]
    this.dailySteps.forEach(s => this.fillParams(s))
    this.dailySteps.sort((a, b) => a.order - b.order)

  }

  init() {

    if (this.poll) {
      this.poll = this.findForCurrentDay(this.currentPath.polls) 
    }
    if (this.survey) {
      this.survey = this.findForCurrentDay(this.currentPath.contentSurveys)      
    }
    if (this.document) {
      this.document = this.findForCurrentDay(this.currentPath.gridDocuments)
    }
    if (this.question) {
      this.question = this.findForCurrentDay(this.currentPath.gridQuestions)
    }
    if (this.habit) {
      this.habit = this.findForCurrentDay(this.currentPath.habits)
    }

    this.dailySteps = [
      { kind: 'poll',
        title: 'h_ds_p',
        icon: 'glyphicon glyphicon-question-sign'
      },
      { kind: 'document',
        title: 'h_ds_d',
        icon: 'glyphicon glyphicon-list'
      },
      { kind: 'question',
        title: 'h_ds_q',
        icon: 'glyphicon glyphicon-comment'
      },
      { kind: 'survey',
        title: 'h_ds_s',
        icon: 'glyphicon glyphicon-comment'
      },
      { kind: 'habit',
        title: 'h_ds_h',
        icon: 'glyphicon glyphicon glyphicon-list'
      }
    ]
    this.dailySteps.forEach(s => this.fillParams(s))
    this.dailySteps.sort((a, b) => a.order - b.order)
    this.checkUnlockCurrentDayCompleted();
    
  }

  public getSnoozedSteps() {
    return this.snoozedSteps
  }

  public setSnoozedSteps(steps: SnoozedHabit[]) {
    this.snoozedSteps = steps
  }

  public removeFromSnoozed(habit_id) {
    if (!this.snoozedSteps?.length) {
      return
    }
    this.setSnoozedSteps(this.snoozedSteps.filter(step => step?.id !== habit_id))
  }


  public fillParams(item: any) {

    const source = this[item.kind]
    if (source) {
      item.exists = true
      item.isCompleted = source.isCompleted
      item.order = source.screenOrder
      item.isMandatory = source.isMandatory
      item.actionPoints = source.actionPoints ? source.actionPoints : source.answer ? source.answer.actionPoints : 0
      item.screenId = `daily-content-screen-${item.kind}`
    } else {
      item.exists = false
      item.isCompleted = false
      item.isMandatory = false
      item.order = 0
      item.actionPoints = 0
    }
    return item
  }

  checkUnlockCurrentDayCompleted() {    

    let isMandatoryArray = this.dailySteps.filter(s => s.exists && s.isMandatory);
    let isMandCompletedArray = this.dailySteps.filter(s => s.exists && s.isMandatory && s.isCompleted);

    if(isMandatoryArray.length == isMandCompletedArray.length){
      localStorage.setItem(`Toggle:User:${this.currentUser.id}:DayUnlockCompleted:${this.currentPath.id}:${this.currentDay}`, 'yes') 
    }else{
      localStorage.removeItem(`Toggle:User:${this.currentUser.id}:DayUnlockCompleted:${this.currentPath.id}:${this.currentDay}`)
    }
  }

  findForCurrentDay(collection: any): any {
    return collection.find(item => item.startDay <= this.currentDay && this.currentDay <= item.endDay)
  }

  public getActionsList(data): Observable<any> {
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
    const params = new HttpParams().append('user_id', data.user_id)
      .append('path_id', data.path_id);
    return this.httpclient.get(this.API_URL + this.actionList, { headers, params });
  }

  getActions(userId: number, pathId: number) {
    const requestData = {
      'user_id': userId,
      'path_id': pathId
    }
    this.getActionsList(requestData).subscribe((response) => {
      this.appState.set('todoRoutines',this.homev5Service.removeDuplicates(response.data[0].routine, "routine_info_id"))
      this.appState.set('todoActions',response.data[0].action)
    }, (error) => {
      console.log('getActions error :', error);
    })
  }


}
