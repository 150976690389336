import { Component, OnInit } from '@angular/core'
import { environment } from '../../../environments/environment'
import { AppState } from '../../app.service'
import { Client } from '../../client/client.model'

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  environment: any
  currentClient: Client
  showPathSelect: boolean = false // LUMBA-982

  constructor(private appState: AppState) {
    this.environment = environment
    this.currentClient = this.appState.get('currentClient')
  }

  ngOnInit() {
    // LUMBA-982
    const clientPathCount = localStorage.getItem('clientPathCount')
    if (clientPathCount && parseInt(clientPathCount, 10) > 1) {
      this.showPathSelect = true
    }
  }

  onShowTutorialBtnClick () {
    this.appState.showTutorialBtnClick.emit()
  }
}
