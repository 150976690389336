import gql from 'graphql-tag'

export const dailyParticipationQuery = gql`
query dailyParticipationQuery {
  dailyParticipation {
    labels
    data
    greenDays
    sum
    skip
    scheduleDay
    realDay
    leaderboardPosition
    badgesCount
    totaldaysofcontent
    totalscheduleday
    userlist
  }
}
`
