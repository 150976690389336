import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { EventManager } from '@angular/platform-browser'
import { environment } from '../../../environments/environment'

const DEFAULT_URL = environment.apiBaseUrl + '/files'

@Component({
  selector: 'ya-file-uploader',
  templateUrl: './ya-file-uploader.component.html',
  styleUrls: ['./ya-file-uploader.component.scss']
})
export class YaFileUploaderComponent implements OnInit, AfterViewInit {
  @Input() fileUrl: string
  @Output() fileUrlChange = new EventEmitter<string>()

  @ViewChild('input') input: ElementRef

  public xhr: XMLHttpRequest
  public inputElement: HTMLInputElement
  public loading: boolean
  public succeed: boolean

  constructor(private eventManager: EventManager) {
    this.xhr = new XMLHttpRequest()
    this.xhr.responseType = 'json'
    this.xhr.addEventListener('readystatechange', this.checkIfCompleted.bind(this))
    this.xhr.addEventListener('error', this.errorHandler.bind(this))
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.inputElement = this.input.nativeElement
    this.eventManager.addEventListener(this.inputElement, 'change', this.sendFile.bind(this))
  }

  reset() {
    this.succeed = false
    this.resetInput()
  }

  sendFile() {
    const file = this.inputElement.files[0]
    console.log('send')
    if (!file) return false
    const formData = new FormData()
    formData.append('file', file)
    this.xhr.open('POST', DEFAULT_URL, true)
    this.xhr.setRequestHeader('Authorization', localStorage.getItem('token'))
    this.xhr.send(formData)
    this.loading = true
  }

  checkIfCompleted(event) {
    if (this.xhr.readyState !== XMLHttpRequest.DONE) return false
    this.loading = false
    const response = this.xhr.response
    if (response && response.success) {
      this.successHandler(response)
    }
  }

  successHandler(response) {
    if (!response.data || !response.data.url) return false
    this.fileUrlChange.emit(response.data.url)
    this.succeed = true
  }

  errorHandler(event) {
    console.error('file upload error:', event)
  }

  private resetInput() {
    this.inputElement.value = ''
    this.inputElement.type = ''
    this.inputElement.type = 'file'
  }
}
