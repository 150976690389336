import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AppState } from "../app.service";

const API_URL: string = environment.apiBaseUrl
const COHORT_URL: string = '/all_cohorts'

@Injectable()
export class CohortService {

    constructor(
        private appState: AppState,
        private http: HttpClient,
    ) {

    }

    public getCohort(data): Observable<any> {
        const headers = new HttpHeaders();
         headers.set("Authorization", localStorage.getItem("token"));
        const params = new HttpParams().append('client_id', data);
        return this.http.get(API_URL + COHORT_URL, { headers, params })
    }

}