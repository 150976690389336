import { Component, DoCheck, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AppState } from '../app.service';
import { CordovaService } from '../cordova.service';
import { HomeV5Service } from '../pages/home-v5/home-v5.service';
import { Path } from '../path/path.model';
import { User } from '../user/user.model';
import { RoutineInfo } from './routine-info.model';
import { HomeUiService } from '../new-design/home-ui.service';
import { RoutineV2Service } from './routine-v2.service';
import { I18n } from '../i18n.service';
import { ToasterService } from '../toaster.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
// import { IonItem, IonList, IonToggle } from '@ionic/angular';

@Component({
  selector: 'first90-routine-v2',
  templateUrl: './routine-v2.component.html',
  styleUrls: ['./routine-v2.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1, height: 'auto' })),
      transition(':enter', [
        style({ opacity: 0, height: 0 }),
        animate('0.25s linear', style({ opacity: 1, height: '50px' }))
      ]),
      transition(':leave', [
        animate('0.25s linear', style({ opacity: 0, height: 0 }))
      ])
    ]),
  ]
})
export class RoutineV2Component implements OnInit, DoCheck, OnDestroy {

  @Input() routine: RoutineInfo
  @Input() isTitleScreen: boolean
  @Input() todoRoutines;
  @Input() isSubLevelScreen: boolean
  @Input() isImInCompleted: any
  @Input() inverted = false
  @Input() currentDay;
  finalizedLevelIds = [];
  selectedLevels = []
  unCheckedLevels = []
  imInCompleted: boolean
  islevelUpdated: boolean
  flag=false;
  isLoaded=true;
  unCheckedRoutineLevels;
  currentPath: Path;
  routineData: RoutineInfo;
  routineList;
  currentUser: User;
  comitRoutine;
  // isReorderDisabled = false;
  isReorderDisabled = [];
  checkedRoutineLevels = [];
  deactivepaddingtop: boolean = false;
  activepaddingbottom: boolean = false;
  constructor(private cordovaService: CordovaService,
    private appState: AppState, 
    private routineV2Service: RoutineV2Service,
    private i18n: I18n,
    private tostrService: ToasterService,
    public homeUIService: HomeUiService,
    private homeService: HomeV5Service)
     {
      this.currentPath = this.routineV2Service.getRoutinePathDetails();
      this.currentUser = this.appState.get('currentUser')
      this.checkIsLevelsShuffled();
    }
  
   checkIsLevelsShuffled() {
      const requestData = {
      'user_id': this.currentUser.id
    }
     this.routineV2Service.getOptedRoutines(requestData).subscribe(
        (response) => {
          let editRoutineList = this.homeService.removeDuplicates(response.data, "routine_info_id");
          let routine = this.findForCurrentDay(this.currentPath.routineInfo)
         let updated_routine = []
          editRoutineList?.forEach((optRoutine) => {
            this.currentPath?.routineInfo?.forEach((routine) => {
             updated_routine =[]
            if (optRoutine?.routine_info_id == routine?.id) {
              if (optRoutine?.routine?.length != routine?.levels?.length) {
                routine?.levels?.forEach((level) => {
                  let matchedLevel = optRoutine?.routine?.filter((optLevel) => optLevel.level == level.level)
                  let unMatchLevelIndex = optRoutine?.routine?.findIndex((optLevel) => optLevel.enabled == level.enabled)
                  if (matchedLevel?.length == 0) {
                    updated_routine = optRoutine?.routine
                    let changedLevel = level
                    changedLevel.enabled =false
                    updated_routine.push(changedLevel)
                    //updated_routine?.sort((a, b) => a?.level - b?.level);
                    let originalIndex = this.routine.levels.findIndex(item => item.id === changedLevel.id);
                    this.routine.levels[originalIndex].enabled = false
                    let index = this.routineData?.levels.findIndex(item => item.id === changedLevel.id)
                   // this.routineData.levels[index].enabled = false
                  }
                  else {
                     updated_routine = optRoutine?.routine
                    let changedLevel = level
                    changedLevel.enabled = optRoutine?.routine[unMatchLevelIndex]?.enabled 
                    let originalIndex = this.routine.levels.findIndex(item => item.id === changedLevel.id);
                    this.routine.levels[originalIndex].enabled = optRoutine?.routine[unMatchLevelIndex]?.enabled ;
                    let index = this.routineData?.levels.findIndex(item => item.id === changedLevel.id)
                    //this.routineData.levels[index].enabled =optRoutine[unMatchLevel]?.level?.enabled;
                  }
                
                })

                this.routine.levels = [...new Set(this.routine?.levels)]
                let levelsOrder =[]
                optRoutine?.routine?.forEach((level,index) => {
                  levelsOrder.push({id:level?.id, index:index})
                })
                levelsOrder =[...new Set(levelsOrder)]
                this.routine.levels= this.sortArrayByReference(levelsOrder, this.routine?.levels);
                this.shuffleOrderOfLevels();
              }
            }
          })
                   
          })   

        },
        (error) => {
          console.log('Error for routines ', error)
          this.tostrService.error(this.i18n.t('No record found'))
       })

   }
   sortArrayByReference(arr1: any[], arr2: any[]): any[] {
    const indexMap = new Map(arr1.map((item, index) => [item.id, index]));
    return arr2.sort((a, b) => {
        const indexA = indexMap.get(a.id) ?? Number.MAX_VALUE;
        const indexB = indexMap.get(b.id) ?? Number.MAX_VALUE;
        return indexA - indexB;
    });
}
    getRoutines() {
     this.todoRoutines?.forEach((routine)=>{
      if(routine.routine_info_id ==this.routineData?.id) {
          this.comitRoutine =routine;
      }
     })
     if(this.comitRoutine?.routine.length != this.routineData?.levels.length) {

     
     this.comitRoutine?.routine.forEach((level)=>{
      this.routineData?.levels.forEach((routineLevel)=>{
        let status =false;
        this.comitRoutine?.routine.forEach((level)=>{
          if(level.id==routineLevel.id){
             status =true
          }
        })
        if(level.id == routineLevel.id || status) {
          routineLevel.enabled =true;
          this.isLoaded=false;
        }
        else {
          routineLevel.enabled =false;
          this.isLoaded=false;

        }
      })
     })
    }
      if(!this.isLoaded) {
        this.isLoaded =true;
      }
    }
   UncheckedLevelsStatus(subLevel) {
    this.unCheckedRoutineLevels =[];
     this.routineData?.levels.forEach((routineLevel)=>{
       this.unCheckedLevels.forEach((level)=>{
       if (routineLevel?.id == subLevel?.id) {
         routineLevel.enabled = false;
           let originalIndex =  this.routine?.levels.findIndex(item => item.id === routineLevel.id);
            this.routine?.levels.splice(originalIndex,1);
            this.routine.levels = [routineLevel,...this.routine?.levels];
            this.routine.levels= [...new Set(this.routine.levels)]
         this.unCheckedRoutineLevels= [routineLevel, ...this.unCheckedRoutineLevels]
          this.unCheckedRoutineLevels =[...new Set(this.unCheckedRoutineLevels)]
        }
       else {
         if (routineLevel?.id == level?.id) {
           routineLevel.enabled = false;
           let originalIndex = this.routine?.levels.findIndex(item => item.id === level.id);
           this.routine.levels[originalIndex].enabled = false;
           this.routine.levels = [...new Set(this.routine.levels)]
          this.unCheckedRoutineLevels .push(routineLevel)
          this.unCheckedRoutineLevels = [...new Set(this.unCheckedRoutineLevels)]
         }
       }
       })
              })
  
     this.routine.levels = [...new Set(this.routine.levels)]
     
     this.shuffleOrderOfLevels();
   }
  
  shuffleOrderOfLevels() {
    const enabledArray = this.routine.levels.filter(item => item.enabled);
    const disabledArray = this.routine.levels.filter(item => !item.enabled);
    this.routine.levels = [...enabledArray, ...disabledArray];
  }

    findForCurrentDay(collection: any): any {
      return collection.find(
        item => item.startDay <= this.currentDay && this.currentDay <= item.endDay
      )
    }
  
  ngOnInit() {
    
    if(this.appState.get("isLoggedIn")) {
      this.isLoaded =false;
    }
     this.unCheckedLevels =this.appState.get('unCheckedLevels');
     this.unCheckedLevels = this.homeService.removeDuplicates(this.unCheckedLevels, "index");
     this.unCheckedLevels=[...new Set(this.unCheckedLevels)];
     if(this.unCheckedLevels ==undefined || this.unCheckedLevels ==null) {
      this.unCheckedLevels =[];
     }
     this.selectedLevels =this.appState.get('selectedLevelIds');
     this.selectedLevels = this.homeService.removeDuplicates(this.selectedLevels, "index");
     this.selectedLevels=[...new Set(this.selectedLevels)];
     if(this.selectedLevels ==undefined || this.selectedLevels ==null) {
      this.selectedLevels =[];
     }
  }

  ngOnChanges() {
    this.unCheckedLevels =this.appState.get('unCheckedLevels');
    this.unCheckedLevels = this.homeService.removeDuplicates(this.unCheckedLevels, "index");
    this.unCheckedLevels=[...new Set(this.unCheckedLevels)];

    this.selectedLevels =this.appState.get('selectedLevelIds');
    this.selectedLevels = this.homeService.removeDuplicates(this.selectedLevels, "index");
    this.selectedLevels=[...new Set(this.selectedLevels)];
   
  }
  ngDoCheck(){
    this.routineData = this.findForCurrentDay(this.currentPath.routineInfo);
    this.islevelUpdated = this.appState.get('levelUpdated')
    if(this.islevelUpdated) {
      this.appState.set("isLoggedIn", undefined);
      this.isLoaded =true;
    }
    if(this.appState.get("isLoggedIn"))  {
      this.getRoutines();
    }
    
    if(this.islevelUpdated) {
      this.appState.set("isLoggedIn", undefined);
      this.isLoaded =true;
      this.routine?.levels.forEach((level)=>{
        if(!level.enabled) {
          let originalIndex =  this.routine?.levels.findIndex(item => item.id === level.id);
          //this.routine?.levels.splice(originalIndex,1);
          level.enabled = false;
          this.routine.levels= [...new Set(this.routine.levels)];

        }
      })
      this.routine.levels = [...new Set(this.routine.levels)];
      const indexMap = new Map(this.selectedLevels?.map(item => [item.id, item.index]));
      this.routine.levels.sort((a, b) => {
        const indexA = indexMap.has(a.id) ? indexMap.get(a.id) : Infinity;
        const indexB = indexMap.has(b.id) ? indexMap.get(b.id) : Infinity;
        return indexA - indexB;
      })
      this.shuffleOrderOfLevels();
     //this.routine.levels= this.routine?.levels?.sort((a, b) => this.selectedLevels.indexOf(a.id) - this.selectedLevels.indexOf(b.id));
     // this.routine.levels.sort((a,b)=>a.id-b.id);
    }
   
    // else {
    //   setTimeout(() => {
    //     this.UncheckedLevelsStatus();
    //   },300)
      
    // }
    if(this.selectedLevels && this.selectedLevels.length>0) {
      this.routine?.levels.forEach((element)=>{
        if(element.id=== this.selectedLevels[0].id)
        {
          this.flag =true;
        }
      })
    if(!this.flag) {
      this.unCheckedLevels =[]
      this.setData();
    }
    if(this.unCheckedLevels && this.unCheckedLevels.length>0){
      this.routine?.levels.forEach(element => {
         this.unCheckedLevels.forEach((level)=>{
          if(level.id !==element.id && !(this.unCheckedLevels.filter((level)=>level.id ==element.id)))
          {
              this.selectedLevels.push({'id':element.id, 'index':this.routine.levels.indexOf(element)})
              this.selectedLevels=[...new Set(this.selectedLevels)];

          }
         })
        
      });
    }
    else {
      this.selectedLevels =this.appState.get('selectedLevelIds')
      this.routineData?.levels.forEach(element => {
        if(!element.enabled) {
          this.unCheckedLevels.push({'id':element.id, 'index':this.routine.levels.indexOf(element)})
          this.unCheckedLevels = this.homeService.removeDuplicates(this.unCheckedLevels, "index");
          this.unCheckedLevels=[...new Set(this.unCheckedLevels)];
          this.appState.set('unCheckedLevels', this.unCheckedLevels)
        }
      });
    }
  }
    else if(this.unCheckedLevels===undefined || this.unCheckedLevels==null || (this.unCheckedLevels && this.unCheckedLevels.length==0)){
      this.selectedLevels =this.appState.get('selectedLevelIds');      
      if((this.selectedLevels && this.selectedLevels.length==0 )|| this.selectedLevels ==undefined || this.selectedLevels ==null) {
       this.setData();
      }
    }
    else if((this.selectedLevels && this.selectedLevels.length==0 )|| this.selectedLevels ==undefined || this.selectedLevels ==null) {
      this.setData();
     }
    this.selectedLevels = this.homeService.removeDuplicates(this.selectedLevels, "index");
    this.selectedLevels=[...new Set(this.selectedLevels)];
    this.appState.set('selectedLevelIds', this.selectedLevels)
    this.unCheckedLevels = this.homeService.removeDuplicates(this.unCheckedLevels, "index");
    this.unCheckedLevels=[...new Set(this.unCheckedLevels)];
    this.appState.set('unCheckedLevels', this.unCheckedLevels)
    this.imInCompleted = this.appState.get('isImInCompleted') 
    this.checkedRoutineLevels = this.routine.levels?.filter(level => level?.enabled== true)
    this.unCheckedRoutineLevels = this.routine.levels?.filter(level => level?.enabled == false)
    //this.saveUpdatedOrder();
     // this.checkedRoutineLevels = checkedRoutineLevels.sort((a, b) => this.selectedLevels.indexOf(a.id) - this.selectedLevels.indexOf(b.id));
      //this.unCheckedRoutineLevels =unCheckedRoutineLevels.sort((a, b) => this.unCheckedLevels.indexOf(a.id) - this.unCheckedLevels.indexOf(b.id));
    
  }

  setData() {
    this.selectedLevels =[]
    this.routine?.levels.forEach(element => {
     if(element.enabled) {
      this.selectedLevels.push({'id':element.id, 'index':this.routine.levels.indexOf(element)})
      this.selectedLevels = this.homeService.removeDuplicates(this.selectedLevels, "index");
      this.selectedLevels=[...new Set(this.selectedLevels)];
      this.appState.set('selectedLevelIds', this.selectedLevels)
     }  
     else {
      this.unCheckedLevels.push({'id':element.id, 'index':this.routine.levels.indexOf(element)})
      this.unCheckedLevels = this.homeService.removeDuplicates(this.unCheckedLevels, "index");
      this.unCheckedLevels=[...new Set(this.unCheckedLevels)];
      this.appState.set('unCheckedLevels', this.unCheckedLevels)
     }
    });
  }
  getCheckedIds($event,level, index){
  //  this.isReorderDisabled[index] = true;
    let isChecked = $event.detail.checked;

    this.appState.set('isLevelsReshuffled',true);
    if (isChecked === false) {
       this.isReorderDisabled[index] = true;
      this.appState.set("isLoggedIn", undefined);
     setTimeout(() => {
      level.enabled = isChecked
      
        this.deactivepaddingtop = true;
        this.unCheckedLevels.push({'id':level.id, 'index':this.routine?.levels.indexOf(level)})
      let originalIndex = this.selectedLevels.findIndex(item => item.id === level.id);
      this.selectedLevels.splice(originalIndex,1)
   
     setTimeout(() => {
      this.UncheckedLevelsStatus(level);
      this.isReorderDisabled[index] = false;
      this.deactivepaddingtop = false;
    },300)
      },400)
    }else if(isChecked === true){
      this.isReorderDisabled[index] = true;
      this.appState.set("isLoggedIn", undefined);
    
        setTimeout(() => {
      level.enabled = isChecked
      
          this.activepaddingbottom = true;
          this.selectedLevels.push({'id':level.id, 'index':this.routine?.levels.indexOf(level)>=0?this.selectedLevels?.length+1:this.routineData?.levels.indexOf(level)})
      this.unCheckedLevels =[...new Set(this.unCheckedLevels)];
      let originalIndex = this.unCheckedLevels.findIndex(item => item.id === level.id);
      this.unCheckedLevels.splice(originalIndex, 1);
      setTimeout(() => {
        this.AppendLevelsToRoutine(level);
        this.isReorderDisabled[index] = false;
        this.activepaddingbottom = false;
      },300)
       },400)
    }

    if (this.selectedLevels) {
      // this.selectedLevels.sort(function (a, b) {
      //   return a.index - b.index;
      // });
      this.selectedLevels = this.homeService.removeDuplicates(this.selectedLevels, "index");
      this.appState.set('selectedLevelIds', this.selectedLevels)
      console.log("this.selectedlevelids", this.selectedLevels)
      this.appState.set('indexOfLevel', this.selectedLevels.length>0?0:undefined)
    }

    if (this.unCheckedLevels) {
      // this.unCheckedLevels.sort(function (a, b) {
      //   return a.index - b.index;
      // });
      let unCheckedLevels = this.homeService.removeDuplicates(this.unCheckedLevels, "index");
      unCheckedLevels = [...new Set(unCheckedLevels)]
      this.unCheckedLevels = unCheckedLevels;
      this.appState.set('unCheckedLevels', unCheckedLevels)
    }
    else {
      this.unCheckedLevels =[];
      this.appState.set('unCheckedLevels', undefined)
    }
   //this.isReorderDisabled =false
  }

  AppendLevelsToRoutine(sublevel) {
    if(this.selectedLevels){
      function getUnique(arr) {
        const unique = arr.filter((id,index)=>{
          return arr.indexOf(id)===index
        })
        return unique;
      }

      let finalCheckedLevels = []
      finalCheckedLevels = getUnique(this.selectedLevels) 
      finalCheckedLevels =[...new Set(finalCheckedLevels)];
      // finalCheckedLevels.forEach(checkedLevel => {
        // this.routine?.levels.forEach(routineLevel => {
        //   if ( sublevel.id != routineLevel.id) {
            this.routineData?.levels.forEach((level)=>{
              if(sublevel.id== level.id) {
               level.enabled=true;
               let originalIndex =  this.routine?.levels.findIndex(item => item.id === level.id);
                this.routine?.levels.splice(originalIndex,1);
                this.routine.levels.push(level);
                this.routine.levels= [...new Set(this.routine.levels)]
               // this.routine.levels.sort((a,b)=>a.id-b.id);
              

              }
            });
            this.routine.levels =[... new Set(this.routine.levels)]
         // }
        // });
      // })
      this.shuffleOrderOfLevels();
      this.selectedLevels = []
      this.unCheckedLevels =[]
      this.routine.levels.forEach((level)=>{
        if(level.enabled) {
          this.selectedLevels.push({'id':level.id, 'index':this.routine?.levels.indexOf(level)})
          this.selectedLevels=[...new Set(this.selectedLevels)];
        }
        else {
          this.unCheckedLevels.push({'id':level.id, 'index':this.routine?.levels.indexOf(level)})
          this.unCheckedLevels=[...new Set(this.unCheckedLevels)];
        }
      })
      this.selectedLevels = this.homeService.removeDuplicates(this.selectedLevels, "index");
      this.unCheckedLevels = this.homeService.removeDuplicates(this.unCheckedLevels, "index");
      this.appState.set('unCheckedLevels', this.unCheckedLevels)
      this.appState.set('selectedLevelIds', this.selectedLevels)
    }
  }
 ngOnDestroy() {
  this.appState.set("isLoggedIn", undefined);

 }

  handleReorder(event) {
    console.log("eventt", event);
    let len = this.unCheckedRoutineLevels?.length;
    const itemMove = this.routine.levels.splice(event.detail.from, 1)[0];
    this.routine.levels.splice(event.detail.to, 0, itemMove);
    this.appState.set('isLevelsReshuffled',true)
    event.detail.complete();
    this.setData();
    this.saveUpdatedOrder();
  }
  saveUpdatedOrder() {  
   let idsArray = []
    this.routine.levels.forEach(element => {
      if (element.enabled) {
        idsArray.push(element)
      }
    });

    this.finalizedLevelIds = idsArray.map(item => item.id)
    console.log("finialized reorder ids", this.finalizedLevelIds);
    console.log('New order saved');
  }

}
