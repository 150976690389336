import gql from 'graphql-tag'
import { documentFields } from '../../document/document.query'
import { pollFields } from '../../poll/poll.query'
import { habitFields } from '../../habit/habit.query'
import { questionFields } from '../../question/question.query'

export const homePageQuery = gql`
query homePageQuery {
  content {
    id
    poll {
      ...pollFields
    }
    document {
      ...documentFields
      isCompleted
    }
    habit {
      ...habitFields
      isCompleted
    }
    question {
      ...questionFields
      isCompleted
    }
    scheduleDay
    completions
  }
}
${pollFields}
${documentFields}
${habitFields}
${questionFields}
`