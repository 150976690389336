declare var require: any;

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ChangeDetectionStrategy,
  HostListener,
  Inject,
  DoCheck,
  Input,
  OnDestroy,
  Renderer2
} from '@angular/core'
import { AppState } from '../app.service'
import { ActivatedRoute, Router } from '@angular/router'
import { ThemeService } from '../theme/theme.service'
import { Document } from '../document/document.model'
import { Path } from '../path/path.model'
import { Client } from '../client/client.model'
import { Poll } from '../poll/poll.model'
import { Habit } from '../habit/habit.model'
import { Question } from '../question/question.model'
import { InoutScreen } from '../inout-screen/inout-screen.model'
import { SessionService } from '../session/session.service'
import { DailyStepsService } from '../daily-steps/daily-steps.service'
import { User } from '../user/user.model'
import * as moment from 'moment'
import { HabitService } from '../habit/habit.service' // LUMBA-1344
import { DocumentV2Component } from '../document-v2/document-v2.component'
import { ContentSurvey } from '../content-survey/content-survey.model' // LUMBA-1344
import { CordovaService } from '../cordova.service'
import { MatDialog } from '@angular/material/dialog'
import { RatingAlertBoxComponent } from '../rating-alert-box/rating-alert-box.component'
import { HomeV5Service } from '../pages/home-v5/home-v5.service'
import { HabitListItem } from '../habit-list-modal/habit-list-modal.model';
import { AlertIamInComponent } from '../alert-iam-in/alert-iam-in.component';
import { RoutineService } from './routine.service';
import { AlertRatingRoutineComponent } from '../alert-rating-routine/alert-rating-routine.component';
import { AlertWebCalendarComponent } from '../alert-web-calendar/alert-web-calendar.component';
import { AlertWebactionCalendarComponent } from '../alert-webaction-calendar/alert-webaction-calendar.component';
import { WINDOW } from '../window.service';
import { WalletService } from '../wallet/wallet.service';
import { PollService } from '../poll/poll.service';
import { LinearScaleComponent } from '../linear-scale/linear-scale.component';
import { RoutineInfo } from '../routine-v2/routine-info.model';
import { AlertAskRoutineComponent } from '../alert-ask-routine/alert-ask-routine.component';
import { filter } from 'rxjs/operators';
import { RoutineV2Service } from '../routine-v2/routine-v2.service';
import { expandOnEnterAnimation, fadeInLeftAnimation, fadeInRightAnimation, slideInDownAnimation, slideInUpAnimation } from 'angular-animations';
import { AppConstantsService } from '../app-constants.service';
import { RoutineCalendarComponent } from '../routine-v2/routine-calendar/routine-calendar/routine-calendar.component';
import { AlertCommitRoutineComponent } from '../alert-commit-routine/alert-commit-routine.component';
import { AlertRoutineAnimComponent } from '../alert-routine-anim/alert-routine-anim.component';
import { SpinnerService } from '../spinner.service';
import { ToasterService } from '../toaster.service';
import { CalendarService } from '../calendar.service';
import { CompletedReviewModalComponent } from '../completed-review-modal/completed-review-modal.component';
import { AlertController, IonFab, ModalController, NavController, Platform } from '@ionic/angular';
import { UtilityService } from '../common-services/utility.service';
import { Location } from '@angular/common';
import { CurrentPathRegistration } from '../daily-steps/daily-steps.component';
import { AlertAddNotesComponent } from '../alert-add-notes/alert-add-notes.component';
import { LibraryService } from '../my-library/library.service';
import { Library } from '../my-library/library.model';
import { AlertDeletePostComponent } from '../alert-delete-post/alert-delete-post.component';
import { TodoListModel } from '../to-do-list/todo-list.model';
import { browserRefresh } from '../app.component';
import mixpanel from 'mixpanel-browser';
import { Reflection } from '../my-library/full-screen-reflection/reflection.model';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ContentSurveyComponent } from '../content-survey/content-survey.component';
import { HomeUiService } from '../new-design/home-ui.service';
import { FullScreenReflectionComponent } from '../my-library/full-screen-reflection/full-screen-reflection.component';
import { MyCommitmentsComponent } from '../my-commitments/my-commitments.component';
import { YourPathComponent } from '../new-design/your-path/your-path.component';
import { EditRoutineComponent } from '../edit-routine/edit-routine.component';
import { FullScreenAudioComponent } from '../full-screen-audio/full-screen-audio.component';
import { FullScreenVideoComponent } from '../full-screen-video/full-screen-video.component';
import { FullScreenDocumentComponent } from '../my-library/full-screen-document.component';
import { I18n } from '../i18n.service';
import { OnboardingLearningNotificationComponent } from '../onboarding-learning-notification/onboarding-learning-notification.component';
import { QuestionV2Component } from '../question-v2/question-v2.component';

declare var $: any

export interface CalendarParams {
  title: String,
  startDate: Date
  endDate: Date,
  eventLocation: String,
  notes: String,
  endEventTime: any,
  frequency: string
}

export interface RoutineListItem {
  id: number,
  title: string,
  routineRepLogs: any[]
}

export interface JournalPostItem {
  [journalPostItem: string]: any;
}



@Component({
  selector: 'app-daily-content',
  templateUrl: './daily-content-v2.component.html',
  styleUrls: ['./daily-content-v2.component.scss'],
  animations: [
    fadeInLeftAnimation({ duration: 300 }),
    fadeInRightAnimation({ duration: 300 }),
    slideInUpAnimation({ duration: 300 }),
    expandOnEnterAnimation({ duration:400}),
    slideInDownAnimation({ duration: 300 }),
    trigger('collapseAnimation', [
      state('open', style({ height: '*', opacity: '1',bottom:'76px' })),
      state('closed', style({ height: '0', overflow:'hidden', opacity: '0',bottom:'76px' })),
      transition('open <=> closed', animate('0.2s ease-in-out')),
    ]),
  ],

  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DailyContentV2Component implements OnInit, AfterViewInit, DoCheck, OnDestroy {
  @Input() mode: string = 'question'
  @ViewChildren('dailyContentScreen') dailyContentScreens: QueryList<
    ElementRef
  >
  @ViewChildren('fabComponent,fabComponent1,fabComponent2,fabComponent3,fabComponent4,fabComponent5,fabComponent6,fabComponent7,fabComponent8,fabComponent9,fabComponent10,fabComponent11,fabComponent12,fabComponent13,fabComponent14,fabComponent15,fabComponent16,fabComponent17,fabComponent18') fabComponents: QueryList<IonFab>;
  @ViewChild('documentV2Component') documentV2Component: DocumentV2Component
  @ViewChild('questionV2Component') questionV2Component: QuestionV2Component
  @ViewChild('contentSurveyComponent') contentSurveyComponent: ContentSurveyComponent
  @ViewChild('calendarButtonsContainer') calendarButtonsContainer: ElementRef
  @ViewChildren('disableListener') disableListener: ElementRef[]
  @ViewChild("onboardLearningComponent") onboardLearningComponent: OnboardingLearningNotificationComponent; 
  benchLinearScale:string;
  selectedQuestionStep;
  FromChildData: string;
  @ViewChild(LinearScaleComponent) child;
  learningTime;
  currentDay: number
  startFromScreen: string
  currentPath: Path
  currentUser: User
  actionList
  currentClient: Client
  isMobileWeb=false;
  poll: Poll
  document: Document
  inoutScreen: InoutScreen
  habit: Habit
  routine: RoutineInfo;
  calendarParams: CalendarParams
  routineClendarParams: CalendarParams
  question: Question
  currentScreenId: string
  currentScreenIndex = 0
  screensIds = []
  screensEnabled = {}
  yesterdayHabit: Habit
  backTo: string
  contentSurvey: ContentSurvey
  hasNativeCalendar: boolean
  loading: boolean
  recurrenceInterval: string
   flag= false;
  isDisabled = false;
  subTitleName: string
  noteText: String
  browserRefresh;
  showAnimation:boolean;
  isRoutine: boolean = true
  public toggleButtonValue = "routines";
  habits: HabitListItem[];
  previousSkippedScreenIndex = -1
  routineSkipped = false;
  multipleOnboardingSettings;
  learningScreenData;
  managerEmailScreenData;
  profileScreenData;
  accountabilityEmailData;
  isHabit: boolean
  routines: any;
  routineData: RoutineListItem[];

  routineList: any;
  checkCurrentDay: boolean
  isUpdated: boolean
  cloudCalendarExpanded: boolean;
  cloudCalendarSet: boolean;
  isMobile: boolean;
  @ViewChild('scrollContainer', { static: false }) scrollContainerRef: ElementRef;
  @ViewChild('questionHeading')
  questionHeading: ElementRef;
  @ViewChildren('disableCard') disableCard: ElementRef[];
  @ViewChildren('disableCardHeader') disableCardHeader: ElementRef[];
  @ViewChild('onboardingLearningModal') onboardingLearningModal: OnboardingLearningNotificationComponent;
  isScrolledBottom: boolean = false
  isScheduleLater
  lastDayOfContent: any
  questionType: string
  showRoutine: boolean = false
  responseData: any
  scaleData: any
  isImInCompleted: boolean
  linearScale;
  isLevelCompleted: boolean
  buttonsArray = ['Day(s) & time', 'Location', 'Activity', 'Person', 'Other']
  selectedCueOption: string
  isScreenViewed: boolean
  location: string
  activity: string
  typeValue: string
  commitData: any
  levelId: any
  commitType: any
  routineDay: any
  routineTime: any
  activityCueTime: any
  makeCommitment: any
  localRoutineData: any
  subRoutineAnswer: any
  scheduledDay: number
  colorArray = []
  isEventCreated: boolean
  selectedTimings = [];
  dayTimeArray: any
  routineVersion: boolean
  enabledAttributes: any
  isRoutineEdited: boolean
  isonboardingcompleted: any;
  convertedTimings = [];
  selectedDates: string[]
  isQuestionCompleted: boolean
  modalDataResponse: any;
  isCommented: boolean
  allowNextScreen: boolean
  hidePreviousScreen: boolean;
  leftSwipe: boolean = false;
  rightSwipe: boolean = false;
  animation = ['fadeInLeft', 'fadeInRight'];
  allowScreenCount: boolean
  allowForceRankButton: boolean
  currentStep: number;
  totalSteps: number;
  public pathRegistration: CurrentPathRegistration
  library: Library
  savedLibraryPosts: any
  postToJournal: JournalPostItem
  recentSavedPost: any
  savedScreenId: string
  isAlreadyPresent= undefined;
  isDocumentOnly: boolean
  prevPathData: any
  selectedLevels = []
  isLevelsSelected: boolean
  unCheckedLevels: any
  indexOfLevel: number
  levelUpdated: any
  toDos: TodoListModel
  showUpdatedScreens: boolean
  pathAverage;
  showBackdrop =false;
  dailyStepsArray;
  todoRoutines: any
  todoActions: any
  hideDropDown=false;
  appStateTodo: any
  videoFormats = ['.mp4', '.mov', '.avi', '.avchd', '.f4v', '.mkv', '.wmv','youtube.com', 'webm', 'html5', 'HTML5', 'watch'];
  audioFormats = [".mp3"];  
  startTime: any
  endTime: any
  surveyStats: any
  reflection: Reflection
  textareaElement: any
  textareaContainer: any
  IsOptionSelected: boolean;
  enableBlur=false;
  showScrollbar = false;
  multiplePolls: any;
  isMobileWebIos = false;
  addedManagerEmail;
  accountabilityEmails = []
  isPhotoUploaded: boolean;
  stepActionPointInfo = [];
  constructor(
    private appState: AppState,
    private router: Router,
    public route: ActivatedRoute,
    private themeService: ThemeService,
    private sessionService: SessionService,
    private spinnerService: SpinnerService,
    private dailyStepsService: DailyStepsService,
    private habitService: HabitService,
    private cdRef: ChangeDetectorRef,
    private cordovaService: CordovaService,
    private homeService: HomeV5Service,
    private dialog: MatDialog,
    private i18n:I18n,
    private tostrService: ToasterService,
    private routineService: RoutineService,
    private walletService: WalletService,
    private calendarService: CalendarService,
    private pollService: PollService,
    private routinev2Service: RoutineV2Service,
    private appConstantService: AppConstantsService,
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    public utilService: UtilityService,
    public loc: Location,
    public homeUIService: HomeUiService,
    public modalController: ModalController,
    private homev5Service: HomeV5Service,
    private libraryService: LibraryService,
    private toasterService: ToasterService,
    private renderer: Renderer2,
    private keyboard: Keyboard,
    private platform: Platform,
    private alertController: AlertController,
    @Inject(WINDOW) private window: Window
  ) {
    const w: any = window
    this.hasNativeCalendar = this.cordovaService.onCordova && w && w.plugins && w.plugins.calendar

    this.isUpdated = this.appState.get('isUpdatedMyCommitments')
    if (this.isUpdated) {
      this.appState.set('isUpdatedMyCommitments', false)
      this.refresh()
    }
    this.addedManagerEmail = this.appState.get("managerEmail");
    this.accountabilityEmails = this.appState.get("accountabilityEmails");
    console.log(" this.accountabilityEmails", this.accountabilityEmails,this.accountabilityEmails?.length);
    this.init();
    this.hidePreviousScreen =this.appState.get('hidePrevScreen');
  }

  init() {
    this.currentUser = this.appState.get('currentUser')
    this.currentPath = this.appState.get('currentPath')
    this.currentClient = this.appState.get('currentClient')
    this.scheduledDay = this.appState.get("scheduleDayForCurrentStep")
    this.isMobile = this.appState.get('isMobile')
    this.isRoutineEdited = this.appState.get('isRoutineEdited')
    this.enabledAttributes = this.appState.get("routineVersion")
    this.pathRegistration = this.appState.get('pathRegistration')
    this.prevPathData = this.appState.get('prevPathData')
    this.selectedLevels = this.appState.get('selectedLevelIds')
    this.levelUpdated = this.appState.get('levelUpdated')
    this.appStateTodo = this.appState.get('toDos')
    this.todoRoutines = this.appState.get('todoRoutines')
    this.surveyStats = this.appState.get('surveyStats')
    this.todoActions = this.appState.get('todoActions')
    this.textareaElement = this.appState.get('textareaElement')
    this.textareaContainer = this.appState.get('textAreaContainer')
    let learningTime = localStorage.getItem("learningTime");
    if (learningTime) {
      this.learningTime = learningTime;
    } else {
      if (this.pathRegistration && this.pathRegistration.pathData) {
        this.learningTime = this.pathRegistration.pathData.loginReminderTime;
      }
    }    
    if (this.enabledAttributes.routineVersion) {
      this.routineVersion = this.enabledAttributes.routineVersion
    } else {
      this.routineVersion = false
    }

    this.themeService.setTheme(this.currentPath.getTheme())
    this.currentDay = +this.route.snapshot.paramMap.get('day')
    this.multiplePolls = this.currentPath.polls.filter(item=> item.startDay === this.currentDay)
    this.multiplePolls = this.multiplePolls.slice().sort((a, b) => a.screenOrder - b.screenOrder);
    console.log('this.multiplePolls :', this.multiplePolls);
    this.startFromScreen = this.route.snapshot.queryParamMap.get(
      'startFromScreen'
    )
    this.routineList = this.appState.get('routines')
    this.backTo = this.route.snapshot.queryParamMap.get('backTo')

    this.lastDayOfContent = this.currentPath.lastDayOfContent()

    this.levelId = this.appState.get('LevelNumber')
    
    if (!this.levelId) {
      this.levelId = 1
    }
    this.startTime = new Date();
    this.setContent()
    this.showActions()
    this.appState.set('oldActionPoints', this.walletService.actionPoints.value)
    this.enableForceRank(this.poll);

    if (!this.appState.get('indexOfLevel')) {
      this.indexOfLevel = 0
    } else {
      this.indexOfLevel = this.appState.get('indexOfLevel')
    }
    
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }

  ngAfterViewInit() {
    let lastScreenId = localStorage.getItem(
      `Toggle:User:${this.currentUser.id}:DayProgress:${this.currentPath.id}:${this.currentDay}`
    );
    let sid = -1;
    let screenscount = this.appState.get('screensViewed')
    let screenStatus = this.appState.get('screenStatus');
    let screensViewed;
    let routineScreenCount = 6;
    if (this.pathRegistration?.screensViewed) {
        screensViewed =this.pathRegistration?.screensViewed[this.pathRegistration?.screensViewed?.length-1]
    }
    else if (screenscount) {          
      screensViewed = screenscount[screenscount?.length-1]
    }
   if (screenStatus === 2) {
     this.goToScreenAsPerVisited(screenStatus)
   } else {
     if (this.startFromScreen && this.screensIds?.indexOf(this.startFromScreen)) {
       sid = this.screensIds.indexOf(this.startFromScreen)
     // } else if (lastScreenId) {
     //   sid = this.screensIds.indexOf(lastScreenId)  
     }
     else if(screensViewed?.visitedScreens) {
       if (screensViewed?.step == this.currentDay && this.dailyStepsArray[this.currentDay - 1]?.stepStatus != 'Review') {
         sid = screensViewed?.visitedScreens - 1;
       
         if (screensViewed?.totalScreens == this.screensIds?.length) {
           lastScreenId = this.screensIds[sid]
         }
         else if (this.screensIds?.length + routineScreenCount == screensViewed?.totalScreens) {
           sid = sid - routineScreenCount;
           lastScreenId = this.screensIds[sid]
         }
       }
      }
      if ((lastScreenId || this.startFromScreen) && sid !== -1) {
        this.currentScreenIndex = sid
        this.currentScreenId = this.screensIds[sid]
      } else {
        this.currentScreenIndex = 0
        this.currentScreenId = this.screensIds[0]
      }
      this.showCurrentScreen()
      this.cdRef.detectChanges()
    }
  }
  saveSurvey(survey) {
    this.contentSurveyComponent.saveSurvey(survey);
  }

  toggleBackdrop(val) { 
    if(!val){
      this.showBackdrop = true;
      this.appState.set('isFabClicked',true)
    }   
  }
  
  contentClick(event: Event) {
  
   if (this.appState.get('isFabClicked')==false && this.showBackdrop) {
       this.fabComponents.toArray()?.forEach((ele)=>{
        this.showBackdrop = false;
        if(ele?.activated){
          ele?.close();
        }
    })
  }   
  if(this.appState.get('isFabClicked')) {
    this.appState.set('isFabClicked',false)
  }
   
  }
  
  @HostListener('window:scroll')
  onWindowScroll() {
    
  }
  toggleScrollbar(): void {
  
    if (this.isMobile) {
      let hasScrollbar;
     setTimeout(()=>{

    
     this.dailyContentScreens.forEach((ele)=>{
      console.log("element", ele.nativeElement,ele.nativeElement?.offsetHeight);
      hasScrollbar = ele.nativeElement.scrollHeight >= this.window.innerHeight
      console.log('hasScrollbar',hasScrollbar,ele.nativeElement.scrollHeight,this.window.innerHeight,window.innerHeight, this.showScrollbar);
      if (hasScrollbar && !this.showScrollbar) {
        this.renderer.addClass(ele.nativeElement, 'show-scrollbar');
        this.showScrollbar = true;
      } else if (!hasScrollbar && this.showScrollbar) {
        this.renderer.removeClass(ele.nativeElement, 'show-scrollbar');
        this.showScrollbar = false;
      }
     })
    },500);
 
    }
    
  }
  async goToScreenAsPerVisited(screenStatus) {
    if (!this.pathRegistration.screensViewed) {
      let steps = this.appState.get('stepsArray')
      let stepsArray = this.homev5Service.removeDuplicates(steps, "step");
      await this.calculateSteps(stepsArray, screenStatus)
    } else {
      let steps = this.pathRegistration.screensViewed
      let stepsArray = this.homev5Service.removeDuplicates(steps, "step");
      await this.calculateSteps(stepsArray, screenStatus)
    }
  }
  blurCard(enable, linearScale?,pathAverage?,event?,index?) {
    let pollObj = {
      poll: event.poll,
      pollIndex: index
    }
    this.appState.set('singlePoll',pollObj)
    this.enableBlur =enable;
    this.linearScale =linearScale;
    if(!pathAverage){
      this.pathAverage= localStorage.getItem('pathAverage')
    }else{
      this.pathAverage= pathAverage;
    }
    let id = document.getElementsByClassName('back-available')[index]
    if(event.poll?.ptype =='linear'){
      if(enable ==true){
        this.disableCard?.forEach(element => {
          this.renderer.addClass(element?.nativeElement, 'container-blur');
        });
        this.disableCardHeader.forEach(element => {
          this.renderer.addClass(element?.nativeElement, 'container-blur');
        });
        if(!this.isMobile) {
          id?.setAttribute('style','display:grid !important;')
        }
  
      }
      else if(enable ==false) {
        this.disableCard?.forEach(element => {
          this.renderer.removeClass(element?.nativeElement, 'container-blur');
        });
        this.disableCardHeader.forEach(element => {
          this.renderer.removeClass(element?.nativeElement, 'container-blur');
        });
  
        if(!this.isMobile) {
          id?.setAttribute('style','display:block !important;')
        }
      }
    }
    else if(event.poll?.ptype =='mcq' || !event.poll?.ptype) {
      if(enable ==true && event.poll?.rationale){
        this.disableCard?.forEach(element => {
          this.renderer.addClass(element?.nativeElement, 'container-blur');
        });
        this.disableCardHeader.forEach(element => {
          this.renderer.addClass(element?.nativeElement, 'container-blur');
        });

        
        if(!this.isMobile) {
          id?.setAttribute('style','display:grid !important;')
        }
  
      }
      else if(enable ==false) {
        this.disableCard?.forEach(element => {
          this.renderer.removeClass(element?.nativeElement, 'container-blur');
        });
        this.disableCardHeader.forEach(element => {
          this.renderer.removeClass(element?.nativeElement, 'container-blur');
        });
  
        if(!this.isMobile) {
          id?.setAttribute('style','display:block !important;')
        }
      }
    }
   
  }
  setScreenNav(screenStatus) {
    this.currentScreenIndex = 0
    this.currentScreenId = this.screensIds[0]
    let sid = this.currentStep;
    if (screenStatus == 2) {
      this.currentScreenIndex = sid
      this.currentScreenId = this.screensIds[sid]
    }
    this.showCurrentScreen()
    this.cdRef.detectChanges()
  }
  ngOnDestroy() {
    // if (this.appState.get('isLevelCommited') !== false && this.routine?.path_id == this.currentPath?.id) {
    //   if (this.appState.get('IsRepsAppendAfterReoptCommit') == true) {
    //     this.homeUIService.closingModalOnClickingBackground();
    //   }
    //   if (this.appState.get('isLevelClosedBeforeCommit') == true) {
    //     this.homeUIService.closingModalOnClickingBackgroundForUnLockedCommit();
    //   }
    // }
    if (this.routine && (this.appState.get('isLevelClosedBeforeCommit') == true || this.appState.get('IsRepsAppendAfterReoptCommit') == true)) {
        
      let openedRoutineInfo = {
        routine: this.routine,
        level: this.routine?.levels[this.indexOfLevel]
      }
      this.appState.set('routinneLevelInfo', openedRoutineInfo)
    }
    this.appState.set('screenStatus', null);
    this.appState.set('hidePrevScreen', false)
     this.appState.set('prevRoutineTime', undefined)
    this.appState.set('prevdays', undefined)
    this.appState.set('prevTimings', undefined)
    this.scheduledDay = this.appState.get("scheduleDayForCurrentStep")
    if(!this.flag) {
      if (this.allowScreenCount && this.currentDay ==this.scheduledDay && this.dailyStepsArray[this.scheduledDay-1]?.stepStatus!='Review'){
        this.utilService.setScreenData(this.currentScreenIndex, this.currentDay, this.screensIds)
        this.utilService.setScreenCount()
      }
      if (this.appState.get('routine_schedule_day')) {
        this.appState.set('routine_schedule_day', null)
      }
      this.sessionService.removeAppStateOnContentClose()
      let prevPathData = this.appState.get('prevPathData')
      if (prevPathData) {
        this.sessionService.changePathToLibrary(prevPathData)
        this.appState.set('prevPathData',undefined)
      }
    }

  }
  calculateSteps(stepsArray: any[], screenStatus) {
    if (stepsArray && stepsArray.length >= 1) {
      try {
        stepsArray.forEach(element => {
          if (element.step === this.currentDay) {
            this.currentStep = element.visitedScreens - 1;
            this.totalSteps = element.totalScreens;
          }
        });
        if (this.currentStep !== this.totalSteps) {
          this.setScreenNav(screenStatus);
        } else {
          this.setScreenNav(null);
        }
      } catch (error) { }
    }
  }

  enableForceRank(poll: any) {
    if (poll) {
      if ((poll.isCompleted && !poll.isMandatory) || (poll.isCompleted && poll.isMandatory) || (poll.ptype === 'forceRank' && poll.options.length === 1) || (!poll.isCompleted && !poll.isMandatory)) {
        this.allowForceRankButton = true
      } else {
        this.allowForceRankButton = false
      }
    }
  }

  ngDoCheck() {
    if (
      this.currentUser?.imageUrl?.endsWith("avatar.png") ||
      this.currentUser?.imageUrl === "nil"
    ) {
      this.isPhotoUploaded = false;
    } else {
      this.isPhotoUploaded = true;
    }    
    let id = document.getElementsByClassName("question-screen-min");
    let id1 = document.getElementsByClassName("mobile-screen");
    if (this.questionV2Component?.isKeyboardVisible == true) {
      if (id) {
        id[0]?.setAttribute("style", "height:100% !important");
      }
      if (id1) {
        id1[0]?.setAttribute("style", "min-height: calc(100vh - 126px) !important");
      }
    } else if (this.questionV2Component?.isKeyboardVisible == false) {
      if (id) {
        id[0]?.setAttribute("style", "height: auto !important;");
      }
      if (id1) {
        id1[0]?.setAttribute("style", "min-height: calc(100vh - 256px) !important");
      }
      
    }
    this.todoRoutines = this.appState.get('todoRoutines')
    this.todoActions = this.appState.get('todoActions')
    this.isScreenViewed = this.appState.get('isScreenViewed')
    this.isImInCompleted = this.appState.get('isImInCompleted')
    this.addedManagerEmail = this.appState.get("managerEmail");  
    this.accountabilityEmails = this.appState.get("accountabilityEmails");
    let learningTime = localStorage.getItem("learningTime");
    if (learningTime) {
      this.learningTime = learningTime;
    } else {
      if (this.pathRegistration && this.pathRegistration.pathData) {
        this.learningTime = this.pathRegistration.pathData.loginReminderTime;
      }
    }
    this.getLocalRoutineData()
    this.cdRef.detectChanges()
    this.isQuestionCompleted = this.appState.get('isQuestionCompleted')
    this.isCommented = this.appState.get('isCommented')
    this.allowScreenCount = this.appState.get('allowScreenCount');
    this.savedLibraryPosts = this.appState.get('savedPosts')
    this.recentSavedPost = this.appState.get('recentSavedStep')
    this.allowNextScreen = this.appState.get('allowNext')
    this.surveyStats = this.appState.get('surveyStats')
    this.textareaElement = this.appState.get('textareaElement')
    this.textareaContainer = this.appState.get('textAreaContainer')
    if (this.textareaElement && this.screensIds[this.currentScreenIndex] === "daily-content-screen-reflection") {
      this.textareaElement.nativeElement.focus()
      this.utilService.enableHeight(this.textareaContainer, this.isMobile, this.platform, this.keyboard)
      this.appState.set('textareaElement', undefined)
    }
    if (this.allowNextScreen) {
      this.appState.set('allowNext', false)
      this.nextScreen()
    }
    this.enableForceRank(this.poll)
    this.selectedLevels =   [...new Set(this.appState.get('selectedLevelIds'))];
    this.levelUpdated = this.appState.get('levelUpdated')
    this.showUpdatedScreens = this.appState.get('showUpdatedScreens')
    this.unCheckedLevels = [...new Set(this.appState.get('unCheckedLevels'))]
    if (this.levelUpdated && this.todoRoutines) {
      this.todoRoutines.forEach(todoRoutine => {              
        if(this.unCheckedLevels && this.unCheckedLevels?.length>0){
          function getUnique(arr) {
            const unique = arr.filter((id,index)=>{
              return arr.indexOf(id)===index
            })
            return unique;
          }
  
          let finalUncheckedLevels = []
          finalUncheckedLevels = getUnique(this.unCheckedLevels) 
          this.routine?.levels.forEach(routineLevel => {
            finalUncheckedLevels.forEach(unCheckedLevel => {
              if (routineLevel.id === unCheckedLevel.id) {
                let originalIndex = this.routine.levels.findIndex(item => item.id === unCheckedLevel.id);
                this.routine.levels.splice(originalIndex, 1)
                this.cdRef.detectChanges()
              }
            });
          })
        }
            
        if (this.selectedLevels?.length > 0 && this.selectedLevels?.length !== this.routine?.levels?.length) {
          let isLevelAvailable, unavailableLevel;
          this.selectedLevels?.forEach((level) => {
            isLevelAvailable = this.routine?.levels?.filter((subLevel) => subLevel?.id == level?.id)
            if (isLevelAvailable?.length == 0) {
              unavailableLevel = this.routine?._data['levels']?.filter((subLev) => subLev?.id == level?.id)
              if (unavailableLevel?.length > 0) {
                this.routine?.levels?.push(unavailableLevel[0])
              }
            }
          })
        }
      });
    }
    if(this.allowScreenCount && this.currentDay ==this.scheduledDay && this.dailyStepsArray[this.scheduledDay-1]!='Review') {
    this.appState.set('currentScreenIndexAfterScreenView', this.currentScreenIndex)
    this.appState.set('currentDayAfterScreenView', this.currentDay)
    this.appState.set('screensIdsAfterScreenView', this.screensIds)
    if(this.currentScreenIndex){
      localStorage.setItem('currentScreenIndexAfterScreenView', JSON.stringify(this.currentScreenIndex));
    }
    localStorage.setItem('currentDayAfterScreenView', JSON.stringify(this.currentDay));
    localStorage.setItem('screensIdsAfterScreenView', JSON.stringify(this.screensIds));
    localStorage.setItem('screenStatus', JSON.stringify(this.appState.get('screenStatus')));
    }
    if(this.appState.get('toDos')){
      localStorage.setItem('toDosWhenRefreshed', JSON.stringify(this.appState.get('toDos')));
    }

    if (!this.appState.get('indexOfLevel')) {
      this.indexOfLevel = 0
    }else{
      this.indexOfLevel = this.appState.get('indexOfLevel')
    }
    // if (this.routine?.levels?.length === this.selectedLevels?.length && !this.levelUpdated) {
    //   this.indexOfLevel = 0
    // }
    
  }
  getScaleAnswers(userId: number, pathId: number, questionId: number){
    let higherScale;
    const requestData = {
      'user_id': userId,
      'path_id': pathId,
      'linear_question_id': questionId
    }

    if(!requestData){
      // this.spinnerService.off();
    }else{
      console.log("requestdata",requestData)
      this.pollService.getLinearScaleAnswers(requestData).subscribe(
        (response) => {
          let answerList = response.data
          
          for (let linear of answerList ) {
            higherScale =linear?.question_higherscale?linear?.question_higherscale:10;
            this.benchLinearScale = linear.linear_rank.linear_rating+'/'+higherScale;
            this.selectedQuestionStep = linear.linear_rank.schedule_day
            localStorage.setItem('pathAverage',linear.path_average)
          }
          this.cdRef.detectChanges()
        },
        (error) => {
          this.tostrService.error(this.i18n.t('Something went wrong..! Try Again'))
        }
      )
    }
  }
  showCurrentScreen() {
    let currentScreen = this.appState.get('screenId')
    if (currentScreen) {
      this.dailyContentScreens.map(item => {
        const {
          nativeElement: { classList }
        } = item
        if (currentScreen) {
          this.currentScreenId = currentScreen
          this.appState.set('screenId', undefined)
          this.currentScreenIndex = 0
          if (this.currentScreenId === this.screensIds[0]) {
            classList.add('hide-back-btn')
          }
          classList.add('show')
          this.appState.set('screenId', undefined)
        }
      })
    }

    this.dailyContentScreens.map(item => {
      const {
        nativeElement: { classList }
      } = item

      if (item.nativeElement.id === this.currentScreenId) {
        localStorage.setItem(
          `Toggle:User:${this.currentUser.id}:DayProgress:${this.currentPath.id}:${this.currentDay}`,
          this.currentScreenId
        )
        if (this.currentScreenId === this.screensIds[0]) {
          classList.add('hide-back-btn')
        }else{
          classList.remove('hide-back-btn')
        }

        if (this.currentScreenIndex === 0) {
          this.savedScreenId = this.currentScreenId
          this.appState.set('savedScreenId', this.savedScreenId)
          localStorage.setItem(
            `PathProgress:User:${this.currentUser.id}:DayProgress:${this.currentPath.id}:${this.currentDay}`,
            this.currentScreenId
          )
        }

        classList.add('show')
      } else {
        classList.remove('show')
      }
    })
    this.cdRef.detectChanges() // LUMBA-1375
  }

  openDocument(posts, id) {
    let doc =this.appState.get('singleDocument');
    console.log('casual post :', posts, this.habit);
    let post;
    posts?.forEach((doc)=>{
      if(doc.id ==id){
        post =doc;
      }
    })
    if(doc?.id ==post?.id) {
       post= doc;
    }
    this.appState.set('singleDocument', post)
    this.appState.set('journalPost', undefined)
    if ((post?.isrecommended && post?.isrecommended === true) || (post?.isRecommended && post?.isRecommended ==true)) {
      this.appState.set('isCasualDocument', true)
    }
    this.dialog.closeAll();
    this.appState.set('journalPathName', this.currentPath.userGroup.name)
    this.appState.set('journalStepNumber', post.startDay)
    const url = post.link;
    if (this.audioFormats.some(el => url.includes(el))) {
      console.log('Audio File');
      this.homeUIService.openNextComponent(FullScreenAudioComponent,{ data: post })
    } else if (this.videoFormats.some(el => url.includes(el))) {
      console.log('Video File');
      this.homeUIService.openNextComponent(FullScreenVideoComponent, { data: post })

    } else {
      console.log('Document');
      this.homeUIService.openNextComponent(FullScreenDocumentComponent)
    }
  }
  close() {
    let scheduledStep = this.appState.get("scheduleDayForCurrentStep");
   // if (this.appState.get('isLevelCommited') !== false) {
      if (this.appState.get('IsRepsAppendAfterReoptCommit') == true) {
      this.homeUIService.closingModalOnClickingBackground();
    }
    if (this.appState.get('isLevelClosedBeforeCommit') == true) {
      this.homeUIService.closingModalOnClickingBackgroundForUnLockedCommit();
    }
    this.flag=true;
    if (this.allowScreenCount && this.currentDay ==scheduledStep && this.dailyStepsArray[scheduledStep-1]?.stepStatus!='Review'){
      this.utilService.setScreenData(this.currentScreenIndex, this.currentDay, this.screensIds)
      this.utilService.setScreenCount()
    }
    if (this.appState.get('routine_schedule_day')) {
      this.appState.set('routine_schedule_day', null)
    }
    this.sessionService.removeAppStateOnContentClose()
    let prevPathData = this.appState.get('prevPathData')
    if (prevPathData) {
      this.sessionService.changePathToLibrary(prevPathData)
      this.appState.set('prevPathData',undefined)
    }
    if (this.backTo) {
      this.router.navigate([this.backTo])
      this.appState.set('isHomeScreen', true)
    } else {
      this.router.navigate(['/main'])
      this.appState.set('isHomeScreen', true)
    }
    if (this.appState.get('isRouteToLibrary')) {
      this.router.navigate(['/main'])
   
      this.appState.set('isRouteToLibrary', undefined)
    }
    if(this.appState.get('isYourPathPrevious')) {
       this.appState.set('isYourPathPrevious',false);
       setTimeout(()=>{
        this.homeUIService.openNextComponent(YourPathComponent);
       },600);
    }
  //}
  // else {
  //   this.toasterService.warning(this.i18n.t('Please commit habit level before closing step'));
  // }
   
  }

  skipPreviousScreens(block = false) {
    if (block) { return false; }
      if (this.previousSkippedScreenIndex >= 0 && this.previousSkippedScreenIndex < this.screensIds.length) {
      this.currentScreenIndex = this.previousSkippedScreenIndex;
    } else {
      for (let i = 0; i < 7; i++) {
        if (this.currentScreenIndex <= 0) {
          break;
        }
        this.currentScreenIndex--;
      }
    }
  
    if (this.allowScreenCount&& this.currentDay ==this.scheduledDay && this.dailyStepsArray[this.scheduledDay-1]!='Review') {
      this.appState.set('currentScreenIndexAfterScreenView', this.currentScreenIndex);
      localStorage.setItem('currentScreenIndexAfterScreenView', JSON.stringify(this.currentScreenIndex));
    }
  
    this.currentScreenId = this.screensIds[this.currentScreenIndex];
    this.showCurrentScreen();
  }
  prevScreen(block = false) {
    if (block) {
      return false
    }
    this.checkLibraryStatus(this.poll, this.contentSurvey, this.document, this.question, this.habit, this.reflection)
    this.currentScreenIndex--
    this.currentScreenId = this.screensIds[this.currentScreenIndex]
    this.showCurrentScreen()
    this.selectedCueOption = 'Day(s) & time'
    this.getLocalRoutineData()
    this.sessionService.removeAppStateOnContentPrev()
    if (this.routineSkipped && this.screensIds[this.currentScreenIndex] === "daily-content-screen-commit-routine") {
    this.skipPreviousScreens()
    }
    if (this.isScreenViewed && this.screensIds[this.currentScreenIndex] === "daily-content-screen-habit-loop") {
      this.prevScreen()
    }
    if (this.makeCommitment === 'no' && this.screensIds[this.currentScreenIndex] === "daily-content-screen-options") {
      this.prevScreen()
    }
    if (this.screensIds[this.currentScreenIndex] === "daily-content-screen-commit-routine") {
      console.log('1 :',);
      this.prevScreen()
      if (this.screensIds[this.currentScreenIndex] === "daily-content-screen-options") {
        console.log('2 :',);
        this.prevScreen()
      }
    }
    if (this.screensIds[this.currentScreenIndex] === "daily-content-screen-options") {
      console.log('3 :',);
      this.prevScreen()
    }
    this.leftSwipeAnimation()
    this.showKeyboard()
    this.utilService.stopEmbeddedVideo()
  }
  
  nextScreen(block = false) {
    this.endTime = new Date();
    let timeDiff = this.endTime - this.startTime; // time difference in milliseconds
    let seconds = Math.floor(timeDiff / 1000); // convert to seconds
    let minutes = Math.floor(seconds / 60); // convert to minutes
    let hours = Math.floor(minutes / 60); // convert to hours
    let timeSpent = hours + ":" + minutes + ":" + seconds; // format the time spent
    if (block) {
      return false
    }
    this.checkLibraryStatus(this.poll, this.contentSurvey, this.document, this.question, this.habit, this.reflection)
    mixpanel.track(`In Steps-${this.currentDay}-${this.currentScreenId}`, {'timeSpent': timeSpent})
    this.currentScreenIndex++

    if (this.currentScreenIndex >= this.screensIds.length) {
      return this.lastScreenAction()
    }

    this.currentScreenId = this.screensIds[this.currentScreenIndex]
    this.showCurrentScreen()
    this.appState.set('isRoutineEdited', false)
    if (this.isScreenViewed && this.screensIds[this.currentScreenIndex] === "daily-content-screen-habit-loop") {
      this.nextScreen()
    }
    this.rightSwipeAnimation()

    if (this.allowScreenCount && this.currentDay ==this.scheduledDay && this.dailyStepsArray[this.scheduledDay-1]!='Review') {
      this.utilService.setScreenData(this.currentScreenIndex, this.currentDay, this.screensIds)
    }
    if (this.isQuestionCompleted && this.screensIds[this.currentScreenIndex] === "daily-content-screen-question") {
      this.appState.set('isQuestionCompleted', false)
    }
    if (this.screensIds[this.currentScreenIndex] === "daily-content-screen-todo") {
      this.todoRoutines = this.appState.get('todoRoutines')
      this.todoActions = this.appState.get('todoActions')
    }
    let isNewStepUnlocked = this.appState.get('isNewStepUnlocked')
    if (isNewStepUnlocked) {
      this.appState.set('allowScreenCount', true)
      this.appState.set('isNewStepUnlocked', undefined)
    }
    this.showKeyboard()
    this.utilService.stopEmbeddedVideo()
  }

  lastScreenAction() {
    localStorage.setItem(
      `Toggle:User:${this.currentUser.id}:DayCompleted:${this.currentPath.id}:${this.currentDay}`,
      'yes'
    )

    localStorage.setItem(`Toggle:User:${this.currentUser.id}:DayUnlockCompleted:${this.currentPath.id}:${this.currentDay}`, 'yes')
    this.appState.set('prevRoutineTime', undefined)
    this.appState.set('prevdays', undefined)
    this.appState.set('prevTimings', undefined)
    if (this.isLastDayCompleted) {
      console.log('this.scheduledDay :', this.scheduledDay);
      if(this.currentPath.inoutScreens.length < this.scheduledDay){
        const dayCompleted = this.scheduledDay + 1
        console.log('dayCompleted :', dayCompleted);
        localStorage.setItem('stepCompleted',dayCompleted.toString())
      }
      this.sendPathCompletionEmail(this.currentUser.id)
      if ( this.appState.get("hideCloseout")) {
        this.router.navigate(['/main']);
      }
      else if (this.currentPath.closeoutSurveys.length > 0 ) {
        this.router.navigate(['/closeout']);
      } 
      else {
        this.router.navigate(['/closeout-welcome']);
      }
    } else {
      let isPollIndex;
      this.isAlreadyPresent = undefined
      this.appState.set('isNewStep', true)
      this.appState.set('currentStepScreenIds', this.screensIds)
      isPollIndex = this.stepActionPointInfo?.findIndex((item) => item?.type === "poll");
      if (isPollIndex > -1) {
        this.stepActionPointInfo.splice(isPollIndex, 1);
        this.eOSPollData();
      }
      else {
        this.appState.set("stepScreenIds", this.stepActionPointInfo);
      }
      if ((this.dailyStepsArray[this.currentDay - 1]?.stepStatus == null || this.dailyStepsArray[this.currentDay - 1]?.stepStatus == undefined || this.dailyStepsArray[this.currentDay - 1]?.stepStatus=='Resume') && this.dailyStepsArray[this.currentDay - 1]?.stepStatus!='Review') {
        this.appState.set('showInitialWeldone',true)
      }
      if (this.isMobile) {
        this.router.navigate(['/mobileweldone'])
      } else {
        this.router.navigate(['/weldone'])
      }

    }
  }

  eOSPollData() {
    let actionPoints =0
    this.currentPath = this.appState.get('currentPath');
     this.multiplePolls = this.currentPath.polls.filter(item=> item.startDay === this.currentDay)
    this.multiplePolls = this.multiplePolls.slice().sort((a, b) => a.screenOrder - b.screenOrder);
    this.multiplePolls.forEach(item => {
      if (!item) {
        return
      }
      if (item?.actionPoints != null && !item?.isCompleted) {
        actionPoints = actionPoints + item?.actionPoints;
      }
    });
    if (this.multiplePolls && actionPoints!=0) {
      this.stepActionPointInfo.push({ type: 'poll', actionPoints: actionPoints })
    }
    this.appState.set("stepScreenIds", this.stepActionPointInfo);
  }
  get isLastDayCompleted(): boolean {
    let dailySteps =this.appState.get("DailyStepsData")
    const scheduleDay = +this.appState.get('currentScheduleDay')
    if(this.isMobile) {
      return scheduleDay >= this.lastDayOfContent
    }
    else {
      return scheduleDay >= this.lastDayOfContent
    }
  }

  setContent() {
    this.setYesterdayHabit()

    // this.setInScreen()

    // We need to display screen in order:
    // 1. Find content for today
    this.poll = this.findForCurrentDay(this.currentPath.polls)
    this.contentSurvey = this.findForCurrentDay(this.currentPath.contentSurveys)
    this.document = this.findForCurrentDay(this.currentPath.gridDocuments)
    this.question = this.findForCurrentDay(this.currentPath.gridQuestions)
    this.habit = this.findForCurrentDay(this.currentPath.habits)
    this.onbordingSettingsScreenOrder()
    this.routine = this.findForCurrentDay(this.currentPath.routineInfo)
    this.reflection = this.findForCurrentDay(this.currentPath.reflections)
    if (this.appStateTodo) {
      this.toDos = this.findForCurrentDay(this.appStateTodo)
    }
    else {
      if(localStorage.getItem('toDosWhenRefreshed')){
        this.appStateTodo= JSON.parse(localStorage.getItem('toDosWhenRefreshed'));
      }
      if (this.appStateTodo) {
        this.toDos = this.findForCurrentDay(this.appStateTodo)
      }    
    }
   
    if (this.poll && this.poll.ptype) { this.questionType = this.poll.ptype }

    if (this.poll) {
      this.appState.set("pollPoints", this.poll.actionPoints)
    }
    if (this.habit) {
      this.appState.set("habitPoints", this.habit.actionPoints)
    }
    if (this.question) {
      this.appState.set("questionPoints", this.question.actionPoints)
    }
    if (this.document) {
      this.appState.set("documentPoints", this.document.actionPoints)
    }
    if (this.contentSurvey) {
      this.appState.set("surveyPoints", this.contentSurvey.actionPoints)
    }
    if (this.reflection) {
      this.appState.set("reflectionPoints", this.reflection.actionPoints)
    }

    this.checkLibraryStatus(this.poll, this.contentSurvey, this.document, this.question, this.habit, this.reflection)

    this.isScheduleLater = this.currentPath.habits.filter(item => item.scheduledLater)
    if (this.isScheduleLater) {
      this.showRoutine = true
    }

    this.showUpdatedScreens = this.appState.get('showUpdatedScreens')
    this.setInScreen()

    this.todoRoutines = this.appState.get('todoRoutines')
    this.todoActions = this.appState.get('todoActions')
    let isAnyLevelCompleted;
    this.routine?.levels?.forEach((level)=>{
      if((level?.isCompleted || level?.isLevelCompleted)){
        isAnyLevelCompleted =true;
      }
    })
    // 2. Wrap screen setter functions into sortable array
    if ((this.routineVersion && !isAnyLevelCompleted) || this.isRoutineEdited || this.showUpdatedScreens ) {    //&& !this.isLevelCompleted
      let sortableCalls
      if (this.homeUIService.unlockedLevelsList(this.todoRoutines) > 0 || this.todoActions?.length > 0) {
        sortableCalls = [
          //{ order: (this.habit ? this.habit.screenOrder : 0),       screenSetter: () => { this.setHabit('daily-content-screen-commitments') } },
          { order: (this.poll ? this.poll.screenOrder : 0), screenSetter: () => { this.setPoll('daily-content-screen-poll') } },
          { order: (this.contentSurvey ? this.contentSurvey.screenOrder : 0), screenSetter: () => { this.setContentSurvey('daily-content-screen-survey') } },
          { order: (this.document ? this.document.screenOrder : 0), screenSetter: () => { this.setDocument('daily-content-screen-document') } },
          { order: (this.question ? this.question.screenOrder : 0), screenSetter: () => { this.setQuestion('daily-content-screen-question') } },
          { order: (this.habit ? this.habit.screenOrder : 0), screenSetter: () => { this.setHabit('daily-content-screen-habit') } },
          { order: (this.routine ? this.routine.screenOrder : 0), screenSetter: () => { this.setRoutine('daily-content-screen-routine') } },
          { order: (this.routine ? this.routine.screenOrder : 0), screenSetter: () => { this.setRoutine('daily-content-screen-routine-levels') } },
          { order: (this.routine ? this.routine.screenOrder : 0), screenSetter: () => { this.setRoutine('daily-content-screen-routine-sub-level') } },
          { order: (this.routine ? this.routine.screenOrder : 0), screenSetter: () => { this.setRoutine('daily-content-screen-habit-loop') } },
          { order: (this.routine ? this.routine.screenOrder : 0), screenSetter: () => { this.setRoutine('daily-content-screen-meet-cue') } },
           { order: (this.routine ? this.routine.screenOrder : 0), screenSetter: () => { this.setRoutine('daily-content-screen-options') } },
          //{ order: (this.routine ? this.routine.screenOrder : 0), screenSetter: () => { this.setRoutine('daily-content-screen-commit-routine') } },
          { order: (this.toDos ? this.toDos.screenOrder : 0), screenSetter: () => { this.setTodo('daily-content-screen-todo') } },
          { order: (this.reflection ? this.reflection.screenOrder : 0), screenSetter: () => { this.setReflection('daily-content-screen-reflection') } },
          { order: (this.learningScreenData ? this.learningScreenData.learningScreenOrder : 0), screenSetter: () => { this.setLearningScreen(this.learningScreenData,'daily-content-screen-learning-settings') } },
          { order: (this.managerEmailScreenData ? this.managerEmailScreenData.managerScreenOrder : 0), screenSetter: () => { this.setManagerScreen(this.managerEmailScreenData, 'daily-content-screen-manager-email') } },
          { order: (this.accountabilityEmailData ? this.accountabilityEmailData.accountScreenOrder : 0), screenSetter: () => { this.setAccountabilityManagerScreeen(this.accountabilityEmailData, 'daily-content-screen-accountability-email') } },
          { order: (this.profileScreenData ? this.profileScreenData.profileScreenOrder : 0), screenSetter: () => { this.setProfilePhotoScreeen(this.profileScreenData,'daily-content-screen-profile-photo') } },
        ]
        console.log('sortableCalls 1:', sortableCalls);
      }else{
        sortableCalls = [
          //{ order: (this.habit ? this.habit.screenOrder : 0),       screenSetter: () => { this.setHabit('daily-content-screen-commitments') } },
          { order: (this.poll ? this.poll.screenOrder : 0), screenSetter: () => { this.setPoll('daily-content-screen-poll') } },
          { order: (this.contentSurvey ? this.contentSurvey.screenOrder : 0), screenSetter: () => { this.setContentSurvey('daily-content-screen-survey') } },
          { order: (this.document ? this.document.screenOrder : 0), screenSetter: () => { this.setDocument('daily-content-screen-document') } },
          { order: (this.question ? this.question.screenOrder : 0), screenSetter: () => { this.setQuestion('daily-content-screen-question') } },
          { order: (this.habit ? this.habit.screenOrder : 0), screenSetter: () => { this.setHabit('daily-content-screen-habit') } },
          { order: (this.routine ? this.routine.screenOrder : 0), screenSetter: () => { this.setRoutine('daily-content-screen-routine') } },
          { order: (this.routine ? this.routine.screenOrder : 0), screenSetter: () => { this.setRoutine('daily-content-screen-routine-levels') } },
          { order: (this.routine ? this.routine.screenOrder : 0), screenSetter: () => { this.setRoutine('daily-content-screen-routine-sub-level') } },
          { order: (this.routine ? this.routine.screenOrder : 0), screenSetter: () => { this.setRoutine('daily-content-screen-habit-loop') } },
          { order: (this.routine ? this.routine.screenOrder : 0), screenSetter: () => { this.setRoutine('daily-content-screen-meet-cue') } },
          { order: (this.routine ? this.routine.screenOrder : 0), screenSetter: () => { this.setRoutine('daily-content-screen-options') } },
         // { order: (this.routine ? this.routine.screenOrder : 0), screenSetter: () => { this.setRoutine('daily-content-screen-commit-routine') } },
          { order: (this.reflection ? this.reflection.screenOrder : 0), screenSetter: () => { this.setReflection('daily-content-screen-reflection') } },
          { order: (this.learningScreenData ? this.learningScreenData.learningScreenOrder : 0), screenSetter: () => { this.setLearningScreen(this.learningScreenData, 'daily-content-screen-learning-settings') } },
          { order: (this.managerEmailScreenData ? this.managerEmailScreenData.managerScreenOrder : 0), screenSetter: () => { this.setManagerScreen(this.managerEmailScreenData, 'daily-content-screen-manager-email') } },
          { order: (this.accountabilityEmailData ? this.accountabilityEmailData.accountScreenOrder : 0), screenSetter: () => { this.setAccountabilityManagerScreeen(this.accountabilityEmailData,'daily-content-screen-accountability-email') } },
          { order: (this.profileScreenData ? this.profileScreenData.profileScreenOrder : 0), screenSetter: () => { this.setProfilePhotoScreeen(this.profileScreenData,'daily-content-screen-profile-photo') } },

        ]
        console.log('sortableCalls 2:', sortableCalls);
      }
      // 3. Sort and run
      sortableCalls.sort((a, b) => a.order - b.order)
      sortableCalls.forEach(c => c.screenSetter())
    } else {
      console.log("entered else");
      if (this.routineList && this.routineList.length > 0) {
        this.addScreen('daily-content-screen-commitments')
      } else {
        console.log('No commitments yet')
      }
      let sortableCalls
      if (this.homeUIService.unlockedLevelsList(this.todoRoutines) > 0 || this.todoActions?.length > 0) {
        sortableCalls = [
          { order: (this.poll ? this.poll.screenOrder : 0), screenSetter: () => { this.setPoll('daily-content-screen-poll') } },
          { order: (this.contentSurvey ? this.contentSurvey.screenOrder : 0), screenSetter: () => { this.setContentSurvey('daily-content-screen-survey') } },
          { order: (this.document ? this.document.screenOrder : 0), screenSetter: () => { this.setDocument('daily-content-screen-document') } },
          { order: (this.question ? this.question.screenOrder : 0), screenSetter: () => { this.setQuestion('daily-content-screen-question') } },
          { order: (this.habit ? this.habit.screenOrder : 0), screenSetter: () => { this.setHabit('daily-content-screen-habit') } },
          { order: (this.toDos ? this.toDos.screenOrder : 0), screenSetter: () => { this.setTodo('daily-content-screen-todo') } },
          { order: (this.reflection ? this.reflection.screenOrder : 0), screenSetter: () => { this.setReflection('daily-content-screen-reflection') } },
          { order: (this.learningScreenData ? this.learningScreenData.learningScreenOrder : 0), screenSetter: () => { this.setLearningScreen(this.learningScreenData, 'daily-content-screen-learning-settings') } },
          { order: (this.managerEmailScreenData ? this.managerEmailScreenData.managerScreenOrder : 0), screenSetter: () => { this.setManagerScreen(this.managerEmailScreenData, 'daily-content-screen-manager-email') } },
          { order: (this.accountabilityEmailData ? this.accountabilityEmailData.accountScreenOrder : 0), screenSetter: () => { this.setAccountabilityManagerScreeen(this.accountabilityEmailData,'daily-content-screen-accountability-email') } },
          { order: (this.profileScreenData ? this.profileScreenData.profileScreenOrder : 0), screenSetter: () => { this.setProfilePhotoScreeen(this.profileScreenData,'daily-content-screen-profile-photo') } },

        ]
        console.log('sortableCalls 3:', sortableCalls);
      }else{
        sortableCalls = [
          { order: (this.poll ? this.poll.screenOrder : 0), screenSetter: () => { this.setPoll('daily-content-screen-poll') } },
          { order: (this.contentSurvey ? this.contentSurvey.screenOrder : 0), screenSetter: () => { this.setContentSurvey('daily-content-screen-survey') } },
          { order: (this.document ? this.document.screenOrder : 0), screenSetter: () => { this.setDocument('daily-content-screen-document') } },
          { order: (this.question ? this.question.screenOrder : 0), screenSetter: () => { this.setQuestion('daily-content-screen-question') } },
          { order: (this.habit ? this.habit.screenOrder : 0), screenSetter: () => { this.setHabit('daily-content-screen-habit') } },
          { order: (this.reflection ? this.reflection.screenOrder : 0), screenSetter: () => { this.setReflection('daily-content-screen-reflection') } },
          { order: (this.learningScreenData ? this.learningScreenData.learningScreenOrder : 0), screenSetter: () => { this.setLearningScreen(this.learningScreenData, 'daily-content-screen-learning-settings') } },
          { order: (this.managerEmailScreenData ? this.managerEmailScreenData.managerScreenOrder : 0), screenSetter: () => { this.setManagerScreen(this.managerEmailScreenData, 'daily-content-screen-manager-email') } },
          { order: (this.accountabilityEmailData ? this.accountabilityEmailData.accountScreenOrder : 0), screenSetter: () => { this.setAccountabilityManagerScreeen(this.accountabilityEmailData,'daily-content-screen-accountability-email') } },
          { order: (this.profileScreenData ? this.profileScreenData.profileScreenOrder : 0), screenSetter: () => { this.setProfilePhotoScreeen(this.profileScreenData,'daily-content-screen-profile-photo') } },
        ]
        console.log('sortableCalls 4:', sortableCalls);
      }
      sortableCalls.sort((a, b) => a.order - b.order)
      sortableCalls.forEach(c => c.screenSetter())
    }


    this.appState.set("stepScreenIds", this.stepActionPointInfo);
    this.setOutScreen()
    if (!this.screensIds.length) {
      return this.router.navigate(['/main'])
    }
  }
onbordingSettingsScreenOrder() {
    this.multipleOnboardingSettings = this.currentPath.onboardingSteps?.filter(onboardData => onboardData?.startDay === this.currentDay);
    console.log(
      "in onboarding settings screen",
      this.multipleOnboardingSettings.slice(),
      this.multipleOnboardingSettings.slice()
    );
    if (this.multipleOnboardingSettings?.length > 1) {
      this.multipleOnboardingSettings = this.multipleOnboardingSettings
        ?.slice()
        ?.sort((a, b) => {
          const getNonNullScreenorder = (obj) => {
            for (let i = 0; i < this.multipleOnboardingSettings?.length; i++) {
              return this.oboardingScreensOrder(obj);
            }
            return null; // All screenorder values are null
          };

          const firstScreenorder = getNonNullScreenorder(a);
          const secondScreenorder = getNonNullScreenorder(b);

          if (firstScreenorder === null && secondScreenorder === null) {
            return 0; // Treat two nulls as equal
          } else if (firstScreenorder === null) {
            return -1; // Null is considered smaller
          } else if (secondScreenorder === null) {
            return 1; // Null is considered smaller
          } else {
            return firstScreenorder - secondScreenorder;
          }
        });
    }
    else if (this.multipleOnboardingSettings?.length == 1) {

      const firstScreenorder = this.oboardingScreensOrder(this.multipleOnboardingSettings[0]);
      if (firstScreenorder === null) {
        return 0;
      } else {
        return firstScreenorder;
      }
    }

  }
  oboardingScreensOrder(obj) {
     if (obj.learningScreenOrder !== null || obj.stepTypes == "notifications") {
       this.learningScreenData = obj;
       return obj.learningScreenOrder;
     }
      if ((obj.profileScreenOrder !== null || obj.stepTypes == "profile")  && (this.currentUser.imageUrl.endsWith("avatar.png") || this.currentUser.imageUrl === "nil" || this.currentUser.profileUploadedDay ==obj.startDay)) {
        this.profileScreenData = obj;
        return obj.profileScreenOrder;
       }
      if (obj.accountScreenOrder !== null || obj.stepTypes == "accountability") {
        this.accountabilityEmailData = obj;
        return obj.accountScreenOrder;
      }
      if (obj.managerScreenOrder !== null || obj.stepTypes == "manager") {
        this.managerEmailScreenData = obj;
        return obj.managerScreenOrder;
      }
    return null;
  }

    setManagerScreen(item,id) {
      if (!item) {
        return
      }
  
    if (item.managerPreScreen?.length > 1) {
      item.managerPreScreen = item.managerPreScreen.slice().sort((a, b) => a.order - b.order);
    }
      if (item.managerPreScreen && item.managerPreScreen.length > 0) {
        item.managerPreScreen.forEach((screen, i) =>
          this.addScreen(id + '-' + item.id + screen.order + '-pre-screen')
        )
      }

    this.addScreen(id);
    
  
    if (item.managerPostScreen?.length > 1) {
      item.managerPostScreen = item.managerPostScreen.slice().sort((a, b) => a.order - b.order);
    }
      if (item.managerPostScreen && item.managerPostScreen.length > 0) {
        item.managerPostScreen.forEach((screen, i) =>
          this.addScreen(id + '-' + item.id + screen.order + '-post-screen')
        )
      }
   if (!this.currentUser.managerEmail || !this.appState.get("managerEmail")) {
        this.stepActionPointInfo.push({type:'managerEmail',actionPoints:item?.managerActionPoints})
    }  

   }
  
  
  setAccountabilityManagerScreeen(item,id) {
      if (!item) {
        return
      }
  
    if (item.accountPreScreen?.length > 1) {
      item.accountPreScreen = item.accountPreScreen.slice().sort((a, b) => a.order - b.order);
    }
      if (item.accountPreScreen && item.accountPreScreen.length > 0) {
        item.accountPreScreen.forEach((screen, i) =>
          this.addScreen(id + '-' + item.id + screen.order + '-pre-screen')
        )
      }

    this.addScreen(id);
  
    if (item.accountPostScreen?.length > 1) {
      item.accountPostScreen = item.accounntPostScreen.slice().sort((a, b) => a.order - b.order);
    }
      if (item.accountPostScreen && item.accountPostScreen.length > 0) {
        item.accountPostScreen.forEach((screen, i) =>
          this.addScreen(id + '-' + item.id + screen.order + '-post-screen')
        )
      }
    if (this.currentUser.accountabilityEmails?.length == 0 || (this.appState.get("accountabilityEmails") ==undefined || this.onboardLearningComponent?.arrayOfAccountabilyEmails?.length==0) ) {
           this.stepActionPointInfo.push({type:'accountability',actionPoints:item?.accountActionPoints})
    }
  } 
  
  setProfilePhotoScreeen(item,id) {
      if (!item) {
        return
      }
  
    if (item.profilePreScreen?.length > 1) {
      item.profilePreScreen = item.profilePreScreen.slice().sort((a, b) => a.order - b.order);
    }
      if (item.profilePreScreen && item.profilePreScreen.length > 0) {
        item.profilePreScreen.forEach((screen, i) =>
          this.addScreen(id + "-" + item.id + screen.order + "-pre-screen")
        );
      }

    this.addScreen(id);
  
    if (item.profilePostScreen?.length > 1) {
      item.profilePostScreen = item.profilePostScreen.slice().sort((a, b) => a.order - b.order);
    }
      if (item.profilePostScreen && item.profilePostScreen.length > 0) {
        item.profilePostScreen.forEach((screen, i) =>
          this.addScreen(id + "-" + item.id + screen.order + "-post-screen")
        );
      }
     this.stepActionPointInfo.push({type:'profile',actionPoints:item?.profileActionPoints})
  } 
  saveManagerEmail() {
    this.addedManagerEmail = this.appState.get("managerEmail");
    let answers = {};
    if (this.onboardingLearningModal?.isManagerEmailAdded&&(!this.addedManagerEmail || !this.currentUser?.managerEmail)) {
      this.homeUIService.saveManagerEmailAddress(answers, this.addedManagerEmail, this.currentDay);
      this.onboardingLearningModal.isManagerEmailAdded = undefined;
    }
  }
    setLearningScreen(item,id) {
    // this.setContentItem(this.learningScreenData, learningSettingsId)
     this.pathRegistration = this.appState.get("pathRegistration");
      if (!item) {
        return
      }
  
    if (item.learningPreScreen?.length > 1) {
      item.learningPreScreen = item.learningPreScreen.slice().sort((a, b) => a.order - b.order);
    }
      if (item.learningPreScreen && item.learningPreScreen.length > 0) {
        item.learningPreScreen.forEach((screen, i) =>
          this.addScreen(id + '-' + item.id + screen.order + '-pre-screen')
        )
      }
    if ((!this.learningTime || this.learningTime =='null') || (this.pathRegistration?.pathData?.selectedLearningDay == item?.startDay && this.pathRegistration?.selectedDayPart!=null) ) {
      this.addScreen(id);
      this.addScreen('daily-content-screen-learning-settings-confirmation-calender')
    }
    else {
    this.addScreen('daily-content-screen-learning-settings-confirmation');
    }
  
    if (item.learningPostScreen?.length > 1) {
      item.learningPostScreen = item.learningPostScreen.slice().sort((a, b) => a.order - b.order);
    }
      if (item.learningPostScreen && item.learningPostScreen.length > 0) {
        item.learningPostScreen.forEach((screen, i) =>
          this.addScreen(id + '-' + item.id + screen.order + '-post-screen')
        )
      }
    if ((!this.learningTime || this.learningTime =='null') || (this.pathRegistration?.pathData?.selectedLearningDay == item?.startDay && this.pathRegistration?.selectedDayPart!=null) ) {
      this.stepActionPointInfo.push({ type: 'notification', actionPoints: item?.learningActionPoints })
    }
  }
  setInScreen() {
    this.inoutScreen = this.findForCurrentDay(this.currentPath.inoutScreens)
    if (
      this.inoutScreen &&
      this.inoutScreen.preScreens &&
      this.inoutScreen.preScreens.length > 0
    ) {
      this.inoutScreen.preScreens.forEach((screen, i) =>
        this.addScreen('daily-content-screen-intro-' + i)
      )
    }
  }

  setYesterdayHabit() {
    // LUMBA-1373
    if (this.currentDay < 1) {
      return
    }
    this.currentPath.habits.sort((a, b) => a.endDay - b.endDay)
    // LUMBA-1360
    let currentHabitIndex
    let prevHabitIndex
    for (let i in this.currentPath.habits) {
      const habit = this.currentPath.habits[i]
      if (
        habit.startDay <= this.currentDay &&
        this.currentDay <= habit.endDay
      ) {
        if (!currentHabitIndex) {
          currentHabitIndex = i
        }
      } else if (
        habit.startDay <= this.currentDay &&
        habit.endDay <= this.currentDay
      ) {
        prevHabitIndex = i
      }
    }
    if (currentHabitIndex >= 0) {
      prevHabitIndex = currentHabitIndex
    } else if (!prevHabitIndex) {
      return
    }
    this.yesterdayHabit = this.currentPath.habits[prevHabitIndex] // LUMBA-1344
  }

  setOutScreen() {
    this.inoutScreen = this.findForCurrentDay(this.currentPath.inoutScreens)
    if (
      this.inoutScreen &&
      this.inoutScreen.postScreens &&
      this.inoutScreen.postScreens.length > 0
    ) {
      this.inoutScreen.postScreens.forEach((screen, i) =>
        this.addScreen('daily-content-screen-outro-' + i)
      )
    }
  }

  removeBackButtonFromFirstScreen() {
    if (!this.screensIds) {
      return
    }
    window.document
      .getElementById('#' + this.screensIds[0])
      .querySelector('.nav-back')
      .remove()
  }

  setContentItem(item, id) {
    if (!item) {
      return
    }

    if (item.preScreens && item.preScreens.length > 0) {
      item.preScreens.forEach((screen, i) =>
        this.addScreen(id + '-' + i + '-pre-screen')
      )
    }

    this.addScreen(id)

    if (item.postScreens && item.postScreens.length > 0) {
      item.postScreens.forEach((screen, i) =>
        this.addScreen(id + '-' + i + '-post-screen')
      )
    }
  }
  setPoll(id) {
    let actionPoints=0
    this.multiplePolls.forEach(item => {
      if (!item) {
        return
      }
      if (item?.actionPoints != null && !item?.isCompleted) {
        actionPoints = actionPoints + item?.actionPoints;
      }      
      item.preScreens = item.preScreens.slice().sort((a, b) => a.order - b.order);
      if (item.preScreens && item.preScreens.length > 0) {
        item.preScreens.forEach((screen, i) =>
          this.addScreen(id + '-' + item.id + screen.order + '-pre-screen')
        )
      }
  
      this.addScreen(id + '-' + item.id)
  
      item.postScreens = item.postScreens.slice().sort((a, b) => a.order - b.order);
      if (item.postScreens && item.postScreens.length > 0) {
        item.postScreens.forEach((screen, i) =>
          this.addScreen(id + '-' + item.id + screen.order + '-post-screen')
        )
      }
    });
    if (this.multiplePolls && actionPoints>0) {
      this.stepActionPointInfo.push({ type: 'poll', actionPoints: actionPoints })
    }
  }

  setContentSurvey(contentSurveyScreenId) {
    if (this.contentSurvey) {
      this.stepActionPointInfo.push({type:'survey',actionPoints:this.contentSurvey?.actionPoints})
    }
    this.setContentItem(this.contentSurvey, contentSurveyScreenId)
  }

  setDocument(documentScreenId) {
    if (this.document) {
      this.stepActionPointInfo.push({type:'document',actionPoints:this.document?.actionPoints})
    }
    this.setContentItem(this.document, documentScreenId)
  }

  setQuestion(questionScreenId) {
    if (this.question) {
      this.stepActionPointInfo.push({type:'question',actionPoints:this.question?.actionPoints})      
    }
    this.setContentItem(this.question, questionScreenId)
  }

  setHabit(habitScreenId) {
    if (this.habit) {
      this.stepActionPointInfo.push({type:'action',actionPoints:this.habit?.actionPoints})
    }
    this.setContentItem(this.habit, habitScreenId)
  }

  setRoutine(routineScreenId) {
    if (this.routine) {
      const uniqueItem =this.stepActionPointInfo?.find((item) => item.type === 'routine');
      if (!uniqueItem) {
        this.stepActionPointInfo.push({type:'routine',actionPoints:this.routine?.actionPoints})
      }
    }
    this.setContentItem(this.routine, routineScreenId)
  }

  setTodo(todoScreenId) {
    this.setContentItem(this.toDos, todoScreenId)
  }

  setReflection(reflectionScreenId) {
    if (this.reflection) {
      this.stepActionPointInfo.push({type:'reflection',actionPoints:this.reflection?.actionPoints})
    }
    this.setContentItem(this.reflection, reflectionScreenId)
  }

  addScreen(id) {
    // check that you have scheduled later or not
    // if you schedule later you will get not see this screen
    // dj - 24 march
    this.screensIds.push(id);
    // const filteredArray =  this.screensIds.filter(item => !/\b(pre|post|intro|outro)\b/.test(item));
    // console.log(filteredArray);
    // this.appState.set("stepScreenIds", this.stepActionPointInfo);
    this.screensEnabled[id] = true;
  }

  findForCurrentDay(collection: any): any {
    return collection.find(
      item => item.startDay <= this.currentDay && this.currentDay <= item.endDay
    )
  }

  findLastHabit(collection: any, lastDay: any): any {
    return collection.find(
      item => item.startDay <= lastDay && lastDay <= item.endDay
    )
  }

  htmlToPlaintext(text) {
    return text ? String(text).replace(/<[^>]+>/gm, '') : ''
  }

  habitConfirm(userId, pathId, habit) {
    mixpanel.track('In Steps-habit confirm', {'habit': habit, 'userId': userId, 'pathId': pathId})
    if (this.allowScreenCount && this.currentDay ==this.scheduledDay && this.dailyStepsArray[this.scheduledDay-1]!='Review') {
      this.utilService.setScreenData(this.currentScreenIndex, this.currentDay, this.screensIds)
    }
    var isHabitComplete = habit.confirmation
    try {
      this.dailyStepsService.removeFromSnoozed(this.yesterdayHabit.id)
      localStorage.setItem(
        `Toggle:User:${this.currentUser.id}:HabitConfirmed:${this.yesterdayHabit.id}`,
        'yes'
      )
      localStorage.removeItem(
        `Toggle:User:${this.currentUser.id}:HabitScheduled:${this.yesterdayHabit.id}`
      )
      // this.spinnerService.on()
      this.habitService.confirm(this.yesterdayHabit.id).subscribe(res => {
        // LUMBA-1344
        // this.spinnerService.off()
        this.cdRef.detectChanges()
      })
    } catch (o_0) {
      console.log('Errors ', o_0)
    }

    try {
      let todaysHabit
      if (this.currentDay != 0) {
        todaysHabit = this.findLastHabit(this.currentPath.habits, this.currentDay)
      }
      if (this.currentPath.enabledAttributes.feedback && isHabitComplete == 0) {
        this.initModal(habit)
        if (this.isCommented) {
          this.nextScreen()
        }
      } else {
        if (!this.routineVersion && todaysHabit.isroutine && !todaysHabit.isConvertedToRoutine) {
          this.openImInDialog(todaysHabit, userId, pathId)
        } else {
          this.nextScreen()
        }
      }
    } catch (o_O) {
      console.log(o_O);
      this.nextScreen()
    }
  }

  async habitScheduleLater() {
    let posts, docLink;
    this.habit?.documents?.forEach((doc)=>{
      if(doc?.id == this.habit?.casualDocumentId) {
        posts =doc;
      }
    })
    let doc = this.appState.get('singleDocument');
    if(posts?.id == doc?.id) {
      posts =this.appState.get('singleDocument')
    }
    
    this.appState.set('singleDocument', posts)
    docLink= posts?.editedDocumentUrl?posts?.editedDocumentUrl:posts?.link;
    mixpanel.track('In Steps-habit schedule later', {'value':'commit'})
    if (this.allowScreenCount && this.currentDay ==this.scheduledDay && this.dailyStepsArray[this.scheduledDay-1]!='Review') {
      this.utilService.setScreenData(this.currentScreenIndex, this.currentDay, this.screensIds)
    }

    this.prepareCalendarParams(this.habit.title, this.routineService.innerHtmlToMobileAction(this.habit.text,docLink))

    if (this.hasNativeCalendar) {
      this.createCalendarEvent()
      this.nextScreen()
    } else {
      this.calendarParams.notes = this.routineService.innerHtmlToWebAction(this.habit.text,docLink)

      this.homeService.setCalendarData(this.calendarParams)
    
      const modal = await this.modalController.create({
        component: AlertWebactionCalendarComponent,
        animated: false,
        cssClass: 'custom-modal-size',
       
      });
       await modal.present() 
       modal.onDidDismiss().then(()=>{
        //this.nextScreen();
       });
      
    }

    if (this.yesterdayHabit.scheduledLater > 0 && this.isMobile) {
      // set schedule later yes sometime when button not get clicked - 24 march - d
      // localStorage.setItem(`Toggle:User:${this.currentUser.id}:HabitScheduled:${this.yesterdayHabit.id}`, 'yes')
      this.nextScreen()
      return false
    }

    localStorage.setItem(
      `Toggle:User:${this.currentUser.id}:HabitScheduled:${this.yesterdayHabit.id}`,
      'yes'
    )
    this.habitService.scheduleLater(this.yesterdayHabit.id).subscribe(res => {
      this.getActions(this.currentUser.id, this.currentPath.id)  
      this.cdRef.detectChanges()
    })
  }

  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    console.log("this.isMobileWeb value", this.isMobileWeb);
    this.dailyStepsArray= this.appState.get("DailyStepsData");
    this.multiplePolls.forEach(element => {
      if(element?.priorQuestionId && element?.ptype =='linear' && element?.benchmarkType==2) {
        this.getScaleAnswers(this.currentUser.id,this.currentPath.id,element.priorQuestionId)
      }
      if(element?.ptype =='mcq' && !element?.rationale){
        this.hideDropDown =true;
      }
      else {
        this.hideDropDown =false;
      }
    });
    if(this.appState.get('IsOptionSelected')) {
      this.showAnimation =true
    }
    this.IsOptionSelected = this.appState.get('IsOptionSelected');
    this.scheduledDay = this.appState.get("scheduleDayForCurrentStep")
    if (localStorage.getItem('isBeginClicked') === 'true' && this.currentDay ==this.scheduledDay && this.dailyStepsArray[this.scheduledDay-1]?.stepStatus!='Review') {
      this.utilService.setScreenData(this.currentScreenIndex, this.currentDay, this.screensIds )
      this.utilService.setScreenCount()
      localStorage.setItem('isBeginClicked', 'false')
    }
    this.browserRefresh = browserRefresh;
    if (this.browserRefresh) {
      if (localStorage.getItem("toDosWhenRefreshed")) {
        this.toDos = JSON.parse(localStorage.getItem("toDosWhenRefreshed"));
      }     
       this.appState.set('toDos', this.toDos);
  
    this.allowScreenCount =true;
      let screenStatus
      if (localStorage.getItem("screenStatus")) {
 screenStatus = JSON.parse(localStorage.getItem("screenStatus"));
      }        
    this.appState.set('screenStatus', screenStatus);
      this.appState.set('allowScreenCount',true);

      if (this.appState.get('routine_schedule_day')) {
        this.appState.set('routine_schedule_day', null)
      }
    }
    this.colorArray = this.utilService.convertToArray()
    if (this.allowScreenCount && this.currentDay ==this.scheduledDay && this.dailyStepsArray[this.scheduledDay-1]!='Review') {
      this.utilService.setScreenData(this.currentScreenIndex, this.currentDay, this.screensIds)
    }
    this.unCheckedLevels = this.appState.get('unCheckedLevels')
        if(this.unCheckedLevels){
          function getUnique(arr) {
            const unique = arr.filter((id,index)=>{
              return arr.indexOf(id)===index
            })
            return unique;
          }
  
          let finalUncheckedLevels = []
          finalUncheckedLevels = getUnique(this.unCheckedLevels) 
          finalUncheckedLevels =[...new Set(finalUncheckedLevels)];
          finalUncheckedLevels.forEach(unCheckedLevel => {
          this.routine?.levels.forEach(routineLevel => {
           
              if ( unCheckedLevel.id === routineLevel.id ) {
                let originalIndex = this.routine.levels.findIndex(item => item.id === unCheckedLevel.id);
                this.routine.levels.splice(originalIndex, 1)
                this.cdRef.detectChanges()
              }
            });
          })
        }
        

    }
   
  


  innerHtmlToNotesData(habit: string) {

    var arrayOfUrls = []
    var divHabitData = document.createElement("DIV");
    divHabitData.innerHTML = habit;
    let cleanText = divHabitData.innerText;

    var uri_pattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/ig;
    arrayOfUrls = habit.match(uri_pattern);

    return cleanText + "\n\n Here is the tool you saw in the 1st90 app to help you complete this action: " + arrayOfUrls + "\n\n After you have completed this action, mark it complete in the 1st90 app by clicking here: https://stage1st90.page.link/d6o5";
  }

  innerHtmlToRoutinesData(habit: string) {

    var arrayOfUrls = []
    var divHabitData = document.createElement("DIV");
    divHabitData.innerHTML = habit;
    let cleanText = divHabitData.innerText;

    var uri_pattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/ig;
    arrayOfUrls = habit.match(uri_pattern);

    return cleanText + "\n\n After you have completed this action, mark it complete in the 1st90 app by clicking here: https://stage1st90.page.link/d6o5";
  }

  innerHtmlToWebAction(habit: string) {

    var arrayOfUrls = []
    var divHabitData = document.createElement("DIV");
    divHabitData.innerHTML = habit;
    let cleanText = divHabitData.innerText;

    var uri_pattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/ig;
    arrayOfUrls = habit.match(uri_pattern);
    return cleanText + "\n\n After you have completed this action, mark it complete in the 1st90 app by clicking here: https://stage1st90.page.link/d6o5";
  }


  innerHTMLToMainTitle() {
    var divMainTitle = document.createElement("DIV");
    divMainTitle.innerHTML = this.inoutScreen?.homepageSubtext.text;
    let tv_mainTitle = divMainTitle.innerText;
    return tv_mainTitle;
  }

  prepareCalendarParams(title: any, msg: String, routinelen: any = null, eventlen: string = null) {
    const start = moment(new Date(), 'HH:mm')
    const end = moment(new Date(), 'HH:mm').add(10, 'minute')
    var titleOfEvent = "Daily Learning Reminder"

    if (this.currentPath.names && this.currentPath.names.titleOfEvent) {
      titleOfEvent = this.currentPath.names.titleOfEvent;
    } else {
      titleOfEvent = "Daily Learning Reminder"
    }

    this.calendarParams = {
      startDate: start.toDate(),
      endDate: end.toDate(),
      title: title, //updated title
      eventLocation: '',
      notes: msg,
      endEventTime: routinelen,
      frequency: eventlen
    }
  }

  prepareRoutineCalendarParams(title: any, msg: String, routinelen: any = null, eventlen: string = null, routineTime: any = null) {
    if (routineTime) {
      var date = moment().format('LL')
      var tempDate = date + " " + routineTime
      const start = moment(tempDate)
      const end = moment(tempDate).add(10, 'minute')
      var titleOfEvent = "Daily Learning Reminder"

      if (this.currentPath.names && this.currentPath.names.titleOfEvent) {
        titleOfEvent = this.currentPath.names.titleOfEvent;
      } else {
        titleOfEvent = "Daily Learning Reminder"
      }

      this.calendarParams = {
        startDate: start.toDate(),
        endDate: end.toDate(),
        title: title, //updated title
        eventLocation: '',
        notes: msg,
        endEventTime: routinelen,
        frequency: eventlen
      }
    } else {
      const start = moment(new Date(), 'HH:mm')
      const end = moment(new Date(), 'HH:mm').add(10, 'minute')
      var titleOfEvent = "Daily Learning Reminder"

      if (this.currentPath.names && this.currentPath.names.titleOfEvent) {
        titleOfEvent = this.currentPath.names.titleOfEvent;
      } else {
        titleOfEvent = "Daily Learning Reminder"
      }

      this.calendarParams = {
        startDate: start.toDate(),
        endDate: end.toDate(),
        title: title, //updated title
        eventLocation: '',
        notes: msg,
        endEventTime: routinelen,
        frequency: eventlen
      }
    }

  }

  createCalendarEvent() {
    const success = function (message) { console.log('Success: ' + JSON.stringify(message)) }
    const error = function (message) { console.error('Error: ' + message) }

    // create an event interactively
    const w: any = window
    const calOptions = w.plugins.calendar.getCalendarOptions()
    const calendarList = w.plugins.calendar.listCalendars(success, error);

    const daysCount = this.currentPath.daysOfContent * 2  // LUMBA-1399
    // calOptions.recurrence = 'daily' // supported are: daily, weekly, monthly, yearly
    calOptions.recurrenceInterval = 1
    calOptions.firstReminderMinutes = 0
    calOptions.recurrenceEndDate = moment(new Date(), 'HH:mm').add(daysCount, 'day').toDate()

    this.calendarService.scheduleEvents(
      this.calendarParams.title,
      this.calendarParams.eventLocation,
      this.calendarParams.notes,
      this.calendarParams.startDate,
      this.calendarParams.endDate,
      calOptions,
      success,
      error
    );
  }


  showActions() {
    this.isRoutine = false
    this.showRoutine = false
    this.habits = new Array(this.currentPath.daysOfContent)
      .fill(0)
      .map((value, index) => {
        const dayNum = index + 1;
        const habit = this.currentPath.habits.find(
          item => item.startDay <= dayNum && dayNum <= item.endDay
        );

        if (habit) {
          return {
            id: habit.id,
            dayNum: habit.startDay, // LUMBA-1307
            title: habit.title,
            text: habit.text,
            casualDocumentId: habit?.casualDocumentId,
            documents: habit?.documents,
            scheduledLater: habit.confirmation < 1 && habit.scheduledLater > 0,
            confirmed: habit.confirmation > 0,
            isConvertedToRoutine: habit.isConvertedToRoutine,
            isCompleted: habit.isCompleted,
            screenId: "daily-content-screen-habit"
          };
        }
        return {
          id: null,
          dayNum: dayNum,
          title: null,
          text: null,
          casualDocumentId: null,
          documents: null,
          scheduledLater: false,
          confirmed: false,
          isConvertedToRoutine: false,
          isCompleted: false,
          screenId: ""
        };
      });

    this.habits.filter(
      item => item.isCompleted !== true
    )
  }

  markAsDone(habit, userId, pathId) {

    var habit = this.findHabitbyId(this.currentPath.habits, habit.id)

    if (!habit.isCompleted) {
      this.habitService.toggleHabit(habit.id).subscribe(
        response => {
          console.log('commit response', response)
        },
        error => {
          console.log('commit error', error)
        },
        () => { }
      )
    }

    try {
      this.habitService.confirm(habit.id).subscribe(res => {
        // LUMBA-1344
        console.log("Habit confirmed ", res);
      })
    } catch (o_0) {
      console.log('Errors ', o_0)
    }

    try {
      if (this.currentPath.enabledAttributes.feedback && habit.isCompleted) {
      const dialogRef = this.dialog.open(RatingAlertBoxComponent, {
          data: habit,
        });
        dialogRef.afterClosed().subscribe((result) => {

          if (result == 'confirm') {
            const requestData = {
              'user_id': this.currentUser.id,
              'habit_id': habit.id,
              'rating': JSON.parse(localStorage.getItem('rating1')),
              'rcomment': localStorage.getItem('postText'),
              'userIds': this.appState.get("tags_ids")
            }

            if (requestData.rcomment == '' && requestData.rating == 0) {
              this.showActions()
            } else {
              this.homeService.giveFeedback(requestData).subscribe(
                (response) => {
                  this.tostrService.success(this.i18n.t('Action Completed!'))
                  this.showActions()
                  this.cdRef.detectChanges()
                },
                (error) => {
                  console.log('Error for feedback ', error)
                }
              )
            }
          }
          if (!this.routineVersion && habit.isroutine) {
            this.openImInDialog(habit, userId, pathId)
          } else {
            this.showActions()
            this.nextScreen()
          }
        })
      } else {
        this.checkIsRoutine(habit, userId, pathId)
      }

    } catch (o_O) {
      console.log(o_O);
    }
  }

  openImInDialog(habit, userId, pathId) {
    let dialogImIn;
    if (!this.isMobile) {
      dialogImIn = this.dialog.open(AlertIamInComponent, {
        width: '400px',
      })
    } else {
      dialogImIn = this.dialog.open(AlertIamInComponent)
    }
    dialogImIn.afterClosed().subscribe((result) => {
      if (result == 'confirm') {
        if (this.hasNativeCalendar) {
          this.prepareCalendarParams("1st90 Habit: " + habit.title, this.innerHtmlToRoutinesData(habit.text))
          this.createRoutineEvent(habit)
        }

        this.routineService.createNewRoutine(habit.id, this.currentPath.id, this.currentUser.id).subscribe(
          (response) => {
            console.log('Response for routine ', response)
            let status = response.status
            if (status === true) {
              this.tostrService.success(this.i18n.t("Habit created!"))
              this.appState.get('currentPath').update('habits', habit.id, { isConvertedToRoutine: true })
              this.getRoutineList(userId, pathId);
              this.showActions();
              this.cdRef.detectChanges()

              if (this.hasNativeCalendar === false) {
                this.prepareCalendarParams("1st90 Habit: " + habit.title, this.innerHtmlToRoutinesData(habit.text), habit.routinelen, habit.eventlen)
                this.calendarParams.notes = this.innerHtmlToWebAction(habit.text)
                this.homeService.setCalendarData(this.calendarParams)
                const dialogRef = this.dialog.open(AlertWebCalendarComponent)
                dialogRef.afterClosed().subscribe((result) => {
                  this.nextScreen()
                })
              } else {
                this.nextScreen()
              }

            } else {
              this.getRoutineList(userId, pathId);
              this.showActions();
              this.cdRef.detectChanges()
            }

          },
          (error) => {
            console.log('Error for feedback ', error)
          }
        )
      } else {
        this.nextScreen()
      }
    })
  }

  logaRep(routineId, userId, pathId) {

    const dialogRef = this.dialog.open(AlertRatingRoutineComponent)
    dialogRef.afterClosed().subscribe((result) => {

      if (result == 'confirm') {
        const requestData = {
          'routine_id': routineId,
          'routine_rating_second': JSON.parse(localStorage.getItem('routineRating2')),
          'routine_rating_first': JSON.parse(localStorage.getItem('routineRating1')),
          'routine_comment': localStorage.getItem('feedbackText')
        }

        this.routineService.giveFeedback(requestData).subscribe(
          (response) => {
            this.tostrService.success(this.i18n.t('Rep Completed!'))
            console.log('Response for feedback ', response)
            this.showRoutine = true
            this.getRoutineList(userId, pathId);
            this.cdRef.detectChanges()
          },
          (error) => {
            this.tostrService.error(this.i18n.t('Rep Not Completed!'))
            console.log('Error for feedback ', error)
          }
        )

      }
    })

  }


  createRoutineEvent(habit) {
    const success = function (message) { console.log('Success: ' + JSON.stringify(message)) }
    const error = function (message) { console.error('Error: ' + message) }
    this.recurrenceInterval = ''
    try {
      if (typeof habit.eventlen === 'string') {
        this.recurrenceInterval = habit.eventlen
      }
    } catch (o_0) {

    }

    // create an event interactively
    const w: any = window
    const calOptions = w.plugins.calendar.getCalendarOptions()
    const calendarList = w.plugins.calendar.listCalendars(success, error);

    const daysCount = habit.routinelen  // LUMBA-1399
    calOptions.recurrence = this.recurrenceInterval.toLowerCase(); // supported are: daily, weekly, monthly, yearly
    calOptions.firstReminderMinutes = 0
    calOptions.recurrenceEndDate = moment(new Date(), 'HH:mm').add(daysCount, 'day').toDate()
    this.calendarService.scheduleEvents(
      this.calendarParams.title,
      this.calendarParams.eventLocation,
      this.calendarParams.notes,
      this.calendarParams.startDate,
      this.calendarParams.endDate,
      calOptions,
      success,
      error
    );
  }


  findHabitbyId(collection: any, id: any): any {
    return collection.find(
      item => item.id === id
    )
  }

  @HostListener('click', ['$event'])
  onLinkClick(e) {

    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {

        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {

        window.open(e.target.href, '_blank')
      }
    }
  }

  async getRoutineList(user_id: any, path_id: any) {
    const requestData = {
      'user_id': user_id,
      'path_id': path_id
    }

    if (requestData.user_id == 0 && requestData.path_id == 0) {
      return true;
    } else {
      await this.routineService.getRoutineList(requestData).subscribe(
        (response) => {
          this.routineList = response.data
        //  console.log("Rioutine list", this.routineList)
          this.appState.set("routines", this.routineList)
           this.actionList= response.data[0].action
           ?.forEach((action)=>{
            if(action?.startDay===this.scheduledDay) 
            {
              this.actionList= action;
            }
           })
          console.log("action", this.actionList);
          this.cdRef.detectChanges();
        },
        (error) => {
          console.log('Error for routines ', error)
        }

      )
    }
  }


  async setReminder(habit) {
    var habit = this.findHabitbyId(this.currentPath.habits, habit.id)
    let posts, docLink;
    habit?.documents?.forEach((doc)=>{
      if(doc?.id == habit?.casualDocumentId) {
        posts =doc;
      }
    })
    let doc = this.appState.get('singleDocument');
    if(posts?.id == doc?.id) {
      posts =this.appState.get('singleDocument')
    }
    
    this.appState.set('singleDocument', posts)
    docLink= posts?.editedDocumentUrl?posts?.editedDocumentUrl:posts?.link;
    this.prepareCalendarParams("1st90 Action : " + habit.title, this.routineService.innerHtmlToMobileAction(habit.text,docLink))
    if (this.hasNativeCalendar) {
      this.createRoutineEvent(habit)
    } else {
      this.calendarParams.notes = this.routineService.innerHtmlToWebAction(habit.text,docLink)
      this.homeService.setCalendarData(this.calendarParams)
     
      const modal = await this.modalController.create({
        component: AlertWebactionCalendarComponent,
        animated: false,
        cssClass: 'custom-modal-size',
       
      });
       await modal.present() 
    }
  }

  checkIsRoutine(habit, userId, pathId) {
    if (habit.isroutine) {
      this.openImInDialog(habit, userId, pathId)
    } else {
      this.tostrService.success(this.i18n.t('Action Completed!'))
      this.getRoutineList(userId, pathId);
      this.showActions();
      this.cdRef.detectChanges()
    }
  }

  refresh() {
    return new Promise(resolve => {
      this.appState.refreshMainComponent.emit()
      setTimeout(resolve, 0)
    })
  }

  onClick(): void {
    this.questionHeading.nativeElement.scrollIntoView({
      top: this.questionHeading.nativeElement.offsetTop,
      behavior: 'smooth'
    });
  }

  onScroll(event: Event) {

    var offset = (event.target as HTMLElement).scrollTop
    if (offset > 60) {
      this.isScrolledBottom = true
    } else {
      this.isScrolledBottom = false
    }
  }

  receiveScale($dataEvent) {
    this.scaleData = $dataEvent.scaleValue;
    console.log("called", $dataEvent)
    if (this.scaleData && $dataEvent.question.ptype == 'linear') {
      this.setScale($dataEvent.question, $dataEvent.higherScale)
    }
  }

  saveQuestionType(poll) {
    
      this.nextScreen()
  }

  showRoutines() {
    this.showRoutine = true
    this.getRoutineList(this.currentUser.id, this.currentPath.id)
  }

  showImInScreen() {
    this.routineSkipped = false
    this.isImInCompleted = true
    mixpanel.track("In Steps-Habit-isImInCompleted", {'Habit-isImInCompleted': true})
    this.appState.set('indexOfLevel', 0)
    this.appState.set('isImInCompleted', true)
    this.appState.set('isRoutineEdited', false)
    this.nextScreen()
  }

  setScale(poll,higherScale?) {
    const requestData = {
      'path_id': this.currentPath.id,
      'user_id': this.currentUser.id,
      'schedule_day': this.appState.get('currentScheduleDay'),
      'is_mandatory': poll.isMandatory,
      'linear_question_id': poll.id,
      'enabled': poll.enabled,
      'linear_rating': this.scaleData
    }

    if (requestData) {
      // this.spinnerService.on()
      this.pollService.setLinearScale(requestData).subscribe(
        (response) => {
          // this.spinnerService.off()
          this.responseData = response.data
          for (let linear of this.responseData ) {
            this.pathAverage = linear.path_average+'/'+higherScale;
          }
          let pollObj = {
            poll: poll,
            pollIndex: poll.startDay - 1
          }
          this.blurCard(true,this.scaleData+"/"+higherScale,this.pathAverage,pollObj)
          this.appState.set('rankOptionData', response.data)
          this.appState.get('currentPath').update('polls', poll.id, { isCompleted: true })
          const index = this.multiplePolls.findIndex(item => item.id === poll.id);
          if (index !== -1) {
            this.multiplePolls[index].isCompleted = true;
          }
          console.log('this.multiplePolls :', this.multiplePolls);
          this.cdRef.detectChanges()
        },
        (error) => {
          // this.spinnerService.off()
          this.tostrService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
        }
      )
    }
  }

  selectCueOption(button,routine,level) {
    this.appState.set('changeCue', false)
    this.selectedCueOption = button
    this.appState.set('makeCommitment','yes')
    // this.makeRoutineData('yes');
    if (this.selectedCueOption === 'Day(s) & time') {
      this.appState.set('commitData',null)
      localStorage.setItem('commitData', null)
      localStorage.setItem('makeCommitment', 'yes')
      // this.makeRoutineData('yes');
      this.typeValue = undefined
      this.openRoutineCalendar(false,this.subRoutineAnswer,routine,level)
    }
    else {
      this.nextScreen()
    } 
  }

  getTypeValue($event) {
    this.typeValue = $event
  }

  openModal() {
    const dialogRef = this.dialog.open(AlertAskRoutineComponent, {
      data: {
        dataKey: { typeValue: this.typeValue, type: this.selectedCueOption }
      },
      // panelClass:this.homeUIService.isDark()?'changeDialog-color':'',
      panelClass: `${this.homeUIService.isDark() ? 'dark-theme' : 'light-theme'}`
    })
    dialogRef.afterClosed().pipe(filter(data => data)).subscribe((data) => {
      this.cdRef.detectChanges()
      this.getLocalRoutineData()
      if (this.makeCommitment === 'no') {
        this.isEventCreated = true
      }
      this.nextScreen()
    })
  }

  getCommitDay($event) {
    this.routineDay = $event
  }

  getCommitTime($event) {
    this.routineTime = $event
  }

  getactivityCueData($event) {
    this.activityCueTime = $event
    localStorage.setItem('activityCueTime', this.activityCueTime)
    this.cdRef.detectChanges()
  }

  checkHabitLoop() {
    // this.appState.set('isScreenViewed', true)
    this.nextScreen()
  }

  getLocalRoutineData() {
    var localCommitData = localStorage.getItem('commitData')
    var appStateCommitData = this.appState.get('commitData')
    if (localCommitData) {
      this.localRoutineData = localCommitData
      var type = this.localRoutineData.split(',')
      this.commitType = type[1]
      this.makeCommitment = type[0]
    } else {
      this.localRoutineData = appStateCommitData
    }

    var localMakeCommitment = localStorage.getItem('makeCommitment')
    var localActivityCueTime = localStorage.getItem('activityCueTime')
    var appStateMakeCommitment = this.appState.get('makeCommitment')
    var appStateActivityCueTime = this.appState.get('activityCueTime')
    if (localMakeCommitment && localActivityCueTime) {
      this.makeCommitment = localMakeCommitment
      this.activityCueTime = localActivityCueTime
    } else {
      this.makeCommitment = appStateMakeCommitment
      this.activityCueTime = appStateActivityCueTime
    }
  }

  makeRoutineData(makeCommitment) {
     this.appState.set('commitData',`${makeCommitment},${this.selectedCueOption},${this.typeValue}`)
    localStorage.setItem('commitData', `${makeCommitment},${this.selectedCueOption},${this.typeValue}`)
    
    this.appState.set('makeCommitment',makeCommitment)
    localStorage.setItem('makeCommitment', makeCommitment)
     this.getLocalRoutineData()
      if (this.makeCommitment === 'no') {
        this.isEventCreated = true
      }
  }

  openRoutineCalendar(isSingleEvent: boolean, subRoutineAnswer: any, routine: any, level: any) {

    if (!this.commitType) { this.commitType = 'dayTime' }
    var routineTime = this.appState.get('routineTime')
    var routineDay = this.appState.get('selectedDate')
    if (!routineTime) { routineTime = '' }
    if (!routineDay) { routineDay = '' }
    // if (routineDay) {
    //   var today = (routineDay).getDay();
    //   var dayName = this.routinev2Service.getDayFromDate(today)
    // }
    if (!this.activityCueTime) { this.activityCueTime = '' }
    if (this.commitType === 'Activity' && !this.activityCueTime) { this.activityCueTime = 'before' }

    var data = {
      text: level.text,
      title: level?.title,
      routineAnswer: subRoutineAnswer,
      level: level,
      isSingleEvent: isSingleEvent,
      backTo: this.backTo,
      typeValue: this.typeValue,
      activityCueTime: this.activityCueTime,
      selectedCueOption: this.selectedCueOption,
      commitType: this.commitType,
      makeCommitment: this.makeCommitment
    }
    this.appState.set("routineCalendarData", data)

    const dialogRef = this.dialog.open(RoutineCalendarComponent, {
      maxWidth: '84vw',
      maxHeight: '501px',
      minWidth:'300px',
      height: '100%',
      width: '100%',
      panelClass:this.homeUIService.isDark()?'changeDialog-color2':'',
    })
    dialogRef.afterClosed().subscribe((result) => {
      if (!this.commitType) { this.commitType = 'dayTime' }
      var routineTime = this.appState.get('routineTime')
      var routineDay = this.appState.get('selectedDate')
      if (!routineTime) { routineTime = '' }
      if (!routineDay) { routineDay = '' }

      // if (routineDay) {
      //   var today = (routineDay).getDay();
      //   var dayName = this.routinev2Service.getDayFromDate(today)
      // }
      if (!this.activityCueTime) { this.activityCueTime = '' }
      if (this.commitType === 'Activity' && !this.activityCueTime) { this.activityCueTime = 'before' }
      var differentTimes = this.appState.get('differentTimes')
      var differentDays = this.appState.get('differentDays')
      if (differentTimes) {
         differentTimes = this.routinev2Service.formatTimeForFrontEnd(differentTimes);
      }
      if (differentDays && differentDays?.length>0) {
        this.dayTimeArray = differentDays.map(function (x, i) {
          return x + ' at ' + differentTimes[i]
        })

        this.subRoutineAnswer = this.routinev2Service.createCommitEvent(routine, level, this.typeValue,
          this.activityCueTime,
          differentTimes, differentDays, this.commitType, this.makeCommitment, "on " + this.dayTimeArray.join(', '))
      } else {
       if (this.makeCommitment === 'yes') {
        differentDays = ['Monday', ' Tuesday', ' Wednesday', ' Thursday', ' Friday', ' Saturday', ' Sunday'];
        this.dayTimeArray = "every day at " + differentTimes
        this.subRoutineAnswer = this.routinev2Service.createCommitEvent(routine, level, this.typeValue, this.activityCueTime, differentTimes, differentDays, this.commitType, this.makeCommitment, this.dayTimeArray)
      } else if (this.makeCommitment === 'no') {
        differentDays = [];
        this.dayTimeArray = [];
        this.subRoutineAnswer = this.routinev2Service.createCommitEvent(routine, level, this.typeValue, this.activityCueTime, differentTimes, differentDays, this.commitType, this.makeCommitment, this.dayTimeArray)
      }
      }

      if (differentTimes.length >= 1) {
        const dialogCommit = this.dialog.open(AlertCommitRoutineComponent, {       
          data: {
            dataKey: { answer: this.subRoutineAnswer }
          },   
          disableClose: true,
          panelClass: `${this.homeUIService.isDark() ? 'dark-theme' : 'light-theme'}`
        })
        dialogCommit.afterClosed().subscribe((result) => {
          if (result == 'commit') {
            this.makeRoutine(routine, level)
          } else if (result == 'confirm') {
            this.appState.set('dayTimeArray', this.dayTimeArray)
            this.appState.set('isTimeEdited', true)
            isSingleEvent= this.appState.get("routineCalendarData")?.isSingleEvent
            this.openRoutineCalendar(isSingleEvent, this.subRoutineAnswer, routine, level)
          } else {
            console.log('in else');
            this.nextScreen()
          }

        })
      }

    })

  }

  closeUnlockModal() {
    this.appState.set('isLevelCommited', undefined);
    if (this.appState.get('IsRepsAppendAfterReoptCommit') == true) {
        this.homeUIService.closingModalOnClickingBackground();
      }
      if (this.appState.get('isLevelClosedBeforeCommit') == true) {
        this.homeUIService.closingModalOnClickingBackgroundForUnLockedCommit();
      }
    this.router.navigate(['/main']);
  }
  makeRoutine(routine: any, level: any) {
    var routineTime = this.appState.get('routineTime')
    var routineDay = this.appState.get('selectedDate')
    if (!routineTime) { routineTime = '' }
    if (!routineDay) { routineDay = '' }
    if (!this.commitType) { this.commitType = 'dayTime' }
    if (!this.typeValue) { this.typeValue = '' }
    if (!this.activityCueTime) { this.activityCueTime = '' }
    if (this.commitType === 'Activity' && !this.activityCueTime) { this.activityCueTime = 'before' }
    // if (routineDay) {
    //   var today = (routineDay).getDay();
    //   var dayName = this.routinev2Service.getDayFromDate(today)
    // }
    var differentTimes = this.appState.get('differentTimes')
    var differentDays = this.appState.get('differentDays')
    if (differentTimes) {
      differentTimes.forEach(element => {
        const date = moment().format('YYYY/MM/DD')
        const time = element;
        const timeAndDate = moment(date + ' ' + time);
        timeAndDate.format('YYYY/MM/DD hh:mm A')
        let newUtcDate = moment(timeAndDate).utc().format('hh:mm A')
        this.selectedTimings.push(newUtcDate)
      });
      differentTimes = this.routinev2Service.formatTimeForFrontEnd(differentTimes);
    }
    if (this.commitType === 'dayTime') { this.makeCommitment = 'yes' }

    if (differentDays && differentDays?.length>0) {
      this.dayTimeArray = differentDays.map(function (x, i) {
        return x + ' at ' + differentTimes[i]
      })
      this.subRoutineAnswer = this.routinev2Service.createCommitEvent(routine, level, this.typeValue, this.activityCueTime, differentTimes, differentDays, this.commitType, this.makeCommitment, "on " + this.dayTimeArray)
    } else {
      if (this.makeCommitment === 'yes') {
        differentDays = ['Monday', ' Tuesday', ' Wednesday', ' Thursday', ' Friday', ' Saturday', ' Sunday'];
        this.dayTimeArray = "every day at " + differentTimes
        this.subRoutineAnswer = this.routinev2Service.createCommitEvent(routine, level, this.typeValue, this.activityCueTime, differentTimes, differentDays, this.commitType, this.makeCommitment, this.dayTimeArray)
      } else if (this.makeCommitment === 'no') {
         differentDays = [];
         this.dayTimeArray = [];
        this.subRoutineAnswer = this.routinev2Service.createCommitEvent(routine, level, this.typeValue, this.activityCueTime, differentTimes, differentDays, this.commitType, this.makeCommitment, this.dayTimeArray)
      }
    }

    this.selectedDates = this.appState.get("multipleDates") 
    let convertedDates = []
    if (this.selectedDates) {
      this.selectedDates.forEach(element => {
        let newUtcDate = moment(element).toDate()  
        convertedDates.push(newUtcDate.toString())      
      });
    } 
    

    const random = Math.floor(Math.random() * this.colorArray.length);
    let routineColor = this.colorArray[random]
    let levelsArray = this.sessionService.getSelectedLevels()
    levelsArray= [...new Set(levelsArray)]
    
    this.routinev2Service.commitRoutine(routine.id, this.currentPath.id, this.currentUser.id, true, level.enabled, this.commitType,
      this.typeValue, level.id, true, this.selectedTimings, this.activityCueTime, differentDays, this.subRoutineAnswer,
      routineColor?.colorCode, this.currentDay, true, convertedDates, levelsArray).subscribe(
        (response) => {
          this.appState.set('isLevelClosedBeforeCommit',null)
          this.homeUIService.appendRepsOnreoptLevel(routine, level);
          this.appState.set('isScreenViewed', true)
          this.appState.set('prevRoutineTime', this.appState.get('routineTime'))
          this.appState.set('prevdays', this.appState.get('differentDays'))
          this.appState.set('prevTimings', this.appState.get('differentTimes'))
          this.selectedTimings = []
          this.dayTimeArray = []
          localStorage.removeItem(
            `Toggle:Day:${this.currentDay}:LevelNumber:${this.levelId + 1}:true`
          )

          var levelSaveData = {
            levelId: this.levelId,
            currentDay: this.currentDay
          }
          this.appState.set('levelSaveData', levelSaveData)

          level.isCompleted = true
          this.appState.set('levelId', this.levelId)
          this.levelId = this.appState.get('levelId')
          this.getActions(this.currentUser.id, this.currentPath.id)
      
          const dialogAnim = this.dialog.open(AlertRoutineAnimComponent, {
            data: {
              dataKey: { level: level }
            },
            disableClose: true,
            panelClass: `${this.homeUIService.isDark() ? 'dark-theme' : 'light-theme'}`
          })
          dialogAnim.afterClosed().subscribe((result) => {
             this.appConstantService.removeAppStateData()
            if (result == 'confirm') {
              this.indexOfLevel = this.appState.get('indexOfLevel')
              let levelCount = this.selectedLevels?.findIndex(item => item.id === level.id)
 
              if (!this.appState.get('levelUpdated') && levelCount != this.selectedLevels[levelCount]?.index) {
                levelCount = levelCount+1
              }
              else {
                levelCount = this.indexOfLevel+1
              }
              
              if (levelCount >1 || this.appState.get('levelEdit')) {
                if (this.appState.get('levelEdit')) {
                  this.appState.set('levelEdit', undefined)
                }
                if (this.appState.get('isTodoReps') === true) {
                  let currentDay = this.appState.get('lastTodoScreenStep')
                  this.appState.set('isTodoReps',undefined)
                  if (currentDay === routine.startDay) {
                    console.log('currentDay :', currentDay);
                    this.nextScreen()
                    this.nextScreen()
                  }else{
                    this.router.navigate(['/content', currentDay], { queryParams: { startFromScreen: 'daily-content-screen-todo' } })
                  }

                  this.appState.set('levelUpdated', undefined)
                } else {
                  this.appState.set('showUpdatedScreens', undefined)
                  let levelSaveData = this.appState.get('levelSaveData')
                  if (levelSaveData) {
                    localStorage.setItem(
                      `Toggle:Day:${levelSaveData.currentDay}:LevelNumber:${levelSaveData.levelId}:true`,
                      'true'
                    )
                    this.appState.set('levelSaveData', undefined)
                  }
                  console.log('fromTodoWidget :', this.appState.get('fromTodoWidget'));
                  if (this.appState.get('fromTodoWidget')) {
                    this.appState.set('fromTodoWidget',undefined)
                    this.router.navigate(['/main'])
                  }else{
                    //this.router.navigate(['/main/commitments'])
                    this.router.navigate(['/main'])
                   if (this.appState.get('isNotificationViewed') || this.appState.get('isNotificationViewed')==undefined) {
                    setTimeout(()=>{
                      this.homeUIService.openNextComponent(MyCommitmentsComponent);
                    },650)
                    }
                  }
                }
                this.appState.set('LevelNumber', undefined)
              } else {
                 this.appState.set('showUpdatedScreens', undefined)
                 this.appState.set('levelSaveData', undefined)
                const check = this.appState.get("isFromEdit");
                  if (check) {
                    this.router.navigate(['/main']);
                    if (this.appState.get('isNotificationViewed') || this.appState.get('isNotificationViewed')==undefined) {
                      setTimeout(() => {
                        this.homeUIService.openNextComponent(MyCommitmentsComponent);
                      }, 650)
                    }
                  } else {
                    console.log('nextScreen 2265 :', this.selectedCueOption);
                    if ( this.selectedCueOption === 'Day(s) & time') {
                      console.log('1.1 :', );
                      this.nextScreen()
                      this.nextScreen()
                    }else{
                      console.log('1.2 :', );
                      this.nextScreen()
                    }
                  }
                if (this.screensIds[this.currentScreenIndex] === "daily-content-screen-commit-routine" && this.commitType === 'dayTime') {
                  if (check) {
                    this.router.navigate(['/main']);
                   if (this.appState.get('isNotificationViewed') || this.appState.get('isNotificationViewed')==undefined) {
                      setTimeout(() => {
                        this.homeUIService.openNextComponent(MyCommitmentsComponent);
                      }, 650)
                    }
                  } else {
                  console.log('nextScreen 2274 :', );
                    this.nextScreen()
                  }
                }
              }
            }
          })

          // this.appConstantService.removeAppStateData()
          this.appState.set('isRoutineEdited', false)
          this.appState.set('isLevelCommited',undefined);
        },
        (error) => {
          console.log('error', error)
          this.tostrService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
        }
      )
  }

  makeRoutineLater(routine, userId, pathId) {
    mixpanel.track('In Steps-routine later', {'routineId': routine.id, 'routing start step': routine})
    const requestData = {
      'path_id': pathId,
      'user_id': userId,
      'schedule_day': this.currentDay?this.currentDay:this.appState.get('currentScheduleDay'),
      'routine_info_id': routine.id,
      'is_opted': false
    }

    if (!requestData) {
      // this.spinnerService.off();
    } else {
      this.routinev2Service.notNow(requestData).subscribe(
        (response) => {
          if(response.status) {
            this.routineSkipped = true;
          }
          this.appState.get('currentPath').update('routineInfo', routine.id, {isCompleted: true})
          this.showNotRightNowAlert();
        },
        (error) => {
          this.tostrService.error(this.i18n.t('Something went wrong..! Try Again'))
        }
      )
    }
  }

  async showNotRightNowAlert() {
     const alert = await this.alertController.create({
      cssClass:'alert-not-committed',
      subHeader: this.i18n.t('You can start building these habits any time via the “Not committed” section of your To-Do List.​'),
      buttons: [
        
        {
          text: 'OK',
          // role: 'confirm',
          handler: () => {
          
          },
        },
      ],
    });
    
    alert.onWillDismiss().then(() => {
      this.appState.set('IsRepsAppendAfterReoptCommit',undefined)
      this.appState.set('isLevelClosedBeforeCommit',undefined)
      this.skipRoutineScreens();
      if (this.allowScreenCount && this.currentDay == this.scheduledDay && this.dailyStepsArray[this.scheduledDay - 1] != 'Review') {
        if (this.currentScreenIndex < this.screensIds.length) {
          this.utilService.setScreenData(this.currentScreenIndex, this.currentDay, this.screensIds)
        } else {
          this.utilService.setScreenData(this.currentScreenIndex - 1, this.currentDay, this.screensIds)
        }
      }    
    });
    
    await alert.present();
  }

  skipRoutineScreens(block = false) {
    if (block) { return false }
    this.previousSkippedScreenIndex = this.currentScreenIndex;
    for (let i = 0; i < 6; i++) {
      this.currentScreenIndex++
    }
    if(this.allowScreenCount && this.currentDay ==this.scheduledDay && this.dailyStepsArray[this.scheduledDay-1]!='Review') {
    this.appState.set('currentScreenIndexAfterScreenView', this.currentScreenIndex)
    localStorage.setItem('currentScreenIndexAfterScreenView', JSON.stringify(this.currentScreenIndex));
    }
    if (this.currentScreenIndex >= this.screensIds.length) {
      return this.lastScreenAction()
    }
    this.currentScreenId = this.screensIds[this.currentScreenIndex]
    this.showCurrentScreen()
  }

  async initModal(habit) {
    // this.appState.set('currentDay', this.currentDay)
    const modal = await this.modalCtrl.create({
      component: CompletedReviewModalComponent,
       componentProps: {currentHabit: habit},
      cssClass: this.homeUIService.isDark() ? 'dark-theme-review-modal mobile-custom-to_do_list-forms' : 'light-theme mobile-custom-to_do_list-forms',
    });

    modal.onDidDismiss().then((modalDataResponse) => {
      if (modalDataResponse !== null) {
        this.modalDataResponse = modalDataResponse.data;
        this.appState.set("isCommented", false)
        this.nextScreen()
      }
    });

    return await modal.present();
  }

  leftSwipeAnimation() {
    this.leftSwipe = false;
    setTimeout(() => {
      this.leftSwipe = true;
    }, 0);
  }

  rightSwipeAnimation() {
    this.rightSwipe = false;
    setTimeout(() => {
      this.rightSwipe = true;
    }, 0);
  }

  checkLibraryStatus(poll, contentSurvey, document, question, habit, reflection) {
    let savedDocumentId, habitId, surveyId, pollId, questionId, flag =false, reflectionId;
    let savedLibraryPosts = this.appState.get('savedPosts')

    savedLibraryPosts?.forEach(element => {
      savedDocumentId = element.documents[0]?.id
      habitId = element.habits[0]?.id
      surveyId = element.contentSurveys[0]?.id
      pollId = element.polls[0]?.id
      questionId = element.questions[0]?.id
      // reflectionId = element.reflections[0]?.id

      if (savedDocumentId && savedDocumentId === document?.id || habitId && habitId === habit?.id ||
        pollId && pollId === poll?.id || surveyId && surveyId === contentSurvey?.id || 
        questionId && questionId === question?.id) {

        if (element.documents.length > 0 && element.habits.length < 1 && element.contentSurveys.length < 1 &&
          element.polls.length < 1 && element.questions.length < 1) {
          this.isDocumentOnly = true
        } else if (element.documents?.length > 0 || element.habits?.length > 0 || element.contentSurveys?.length > 0 ||
          element.polls?.length > 0 || element.questions?.length > 0) {
          this.isAlreadyPresent = true
          flag =true;

        }
        else if(!flag) {
          this.isAlreadyPresent =undefined
        }
      }
      else if(!flag) {
        this.isAlreadyPresent =undefined
      }
     
    });
    if (savedLibraryPosts?.length < 1) {
      this.isAlreadyPresent = undefined
    }

    let recentSavedPost = this.appState.get('recentSavedStep')
    let recentSavedDocumentId = recentSavedPost?.documents[0]?.id
    let recentSavedHabitId = recentSavedPost?.habits[0]?.id
    let recentSavedPollId = recentSavedPost?.polls[0]?.id
    let recentSavedQuestionId = recentSavedPost?.questions[0]?.id
    let recentSavedSurveyId = recentSavedPost?.contentSurveys[0]?.id
    let recentSavedReflectionId = recentSavedPost?.reflections[0]?.id

    if (recentSavedDocumentId || recentSavedHabitId || recentSavedPollId || recentSavedSurveyId || recentSavedQuestionId || recentSavedReflectionId) {
      if (recentSavedPost?.documents.length > 0 && recentSavedPost?.habits.length < 1 && recentSavedPost?.contentSurveys.length < 1 &&
        recentSavedPost?.polls.length < 1 && recentSavedPost?.questions.length < 1 && recentSavedPost?.reflections.length < 1) {
        this.isDocumentOnly = true
      } else if (recentSavedPost.documents?.length > 0 || recentSavedPost.habits?.length > 0 || recentSavedPost.contentSurveys?.length > 0 ||
        recentSavedPost.polls?.length > 0 || recentSavedPost.questions?.length > 0 || recentSavedPost.reflections?.length > 0) {
        this.isAlreadyPresent = true
      }
      
    }

    if (this.appState.get('isNewStep')) {
      this.isAlreadyPresent = undefined
      this.isDocumentOnly = undefined
    }

  }

  getCurrentScreenData(contentSurvey, habit, poll, document, question, inoutScreen, routine, pathId, type, reflection) {
    let savedDocumentId, habitId, surveyId, pollId, questionId, reflectionId;
    let presentPost, presentDocument
    this.savedLibraryPosts = this.appState.get('savedPosts')
    this.savedLibraryPosts?.forEach(element => {
      savedDocumentId = element.documents[0]?.id
      habitId = element.habits[0]?.id
      surveyId = element.contentSurveys[0]?.id
      pollId = element.polls[0]?.id
      questionId = element.questions[0]?.id
      // reflectionId = element.reflections[0]?.id

      if (savedDocumentId && savedDocumentId === document?.id || habitId && habitId === habit?.id ||
        pollId && pollId === poll?.id || surveyId && surveyId === contentSurvey?.id || 
        questionId && questionId === question?.id) {
        if (element.documents.length > 0 && element.habits?.length < 1 && element.contentSurveys?.length < 1 &&
          element.polls?.length < 1 && element.questions?.length < 1) {
          this.isDocumentOnly = true
          presentDocument = element
        } else if (element.documents?.length > 0 || element.habits?.length > 0 || element.contentSurveys?.length > 0 ||
          element.polls?.length > 0 || element.questions?.length > 0) {
          this.isAlreadyPresent = true
          presentPost = element
        }
      }
      
    });

    let recentSavedPost = this.appState.get('recentSavedStep')

    let recentSavedDocumentId = recentSavedPost?.documents[0]?.id
    let recentSavedHabitId = recentSavedPost?.habits[0]?.id
    let recentSavedPollId = recentSavedPost?.polls[0]?.id
    let recentSavedQuestionId = recentSavedPost?.questions[0]?.id
    let recentSavedSurveyId = recentSavedPost?.contentSurveys[0]?.id
    // let recentSavedReflectionId = recentSavedPost?.reflections[0]?.id

    if (recentSavedDocumentId || recentSavedHabitId || recentSavedPollId || recentSavedSurveyId || recentSavedQuestionId) {
      if (recentSavedDocumentId === document?.id || recentSavedHabitId === habit?.id || recentSavedPollId === poll?.id || recentSavedSurveyId === contentSurvey?.id || recentSavedQuestionId === question?.id) {
        if (recentSavedPost?.documents.length > 0 && recentSavedPost?.habits.length < 1 && recentSavedPost?.contentSurveys.length < 1 &&
          recentSavedPost?.polls.length < 1 && recentSavedPost?.questions.length < 1) {
          this.isDocumentOnly = true
          presentDocument = recentSavedPost
        } 
        else if ( recentSavedPost.habits?.length > 0 || recentSavedPost.contentSurveys?.length > 0 ||
          recentSavedPost.polls?.length > 0 || recentSavedPost.questions?.length > 0 ) {
          this.isAlreadyPresent = true
          presentPost = recentSavedPost
        }
       
      }
      
    }

    //LOGIC to check post is already present or not
    // If present show delete option to user
    // If not present show save option to user
    if (this.isAlreadyPresent || this.isDocumentOnly) {
      //delete this post

      if (type === 'document' && presentPost?.documents.length > 0 && presentPost?.inoutScreens.length < 1) {
        const dialogRef = this.dialog.open(AlertDeletePostComponent)
        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'confirm') {
            //this.isAlreadyPresent = undefined
            this.deletePost(presentPost, 'step')
          }
        })
      } else {
        if (type === 'step' && this.isAlreadyPresent) {
          const dialogRef = this.dialog.open(AlertDeletePostComponent)
          dialogRef.afterClosed().subscribe((result) => {
            if (result === 'confirm') {
              this.deletePost(presentPost, 'step')
            }
          })
        } else if (type === 'document' && this.isDocumentOnly) {
          const dialogRef = this.dialog.open(AlertDeletePostComponent)
          dialogRef.afterClosed().subscribe((result) => {
            if (result === 'confirm') {
              this.deletePost(presentDocument, 'document')
            }
          })
        } else {
          if (type === 'step') {
            this.saveStep(contentSurvey, habit, poll, document, question, inoutScreen, routine, reflection, pathId)
          } else if (type === 'document') {
            this.saveDocument(document, pathId)
          }
        }
      }

    } else {
      //create new post
      if (type === 'step') {
        this.saveStep(contentSurvey, habit, poll, document, question, inoutScreen, routine, reflection, pathId)
      } else if (type === 'document') {
        this.saveDocument(document, pathId)
      }
    }
  }

  saveStep(contentSurvey, habit, poll, document, question, inoutScreen, routine, reflection, pathId) {
    const dialogRef = this.dialog.open(AlertAddNotesComponent)
    dialogRef.afterClosed().subscribe((result) => {

      if (result === 'confirm') {
        let text = localStorage.getItem('journalNote')
        let requestData;
        requestData = {
          'text': [text],
          'pathId': pathId,
          'contentSurveyId': contentSurvey?.id,
          'habitId': habit?.id,
          'pollId': poll?.id,
          'documentId': document?.id,
          'questionId': question?.id,
          'inoutScreenId': inoutScreen?.id,
          'routineInfoId': routine?.id,
          'screenId': this.savedScreenId,
          'reflectionId': reflection?.id
        }
        console.log('requestData :', requestData);

        this.spinnerService.on()
        this.libraryService.save(requestData).subscribe((response) => {
          console.log('response :', response);
          this.savedLibraryPosts = this.sessionService.getLibraryPosts()
          if(this.appState.get('FirstTimeTabCheck')){
            this.appState.set('tabLabel', 'saved')
          }
          this.postToJournal = response; 
           // this.spinnerService.off()
          this.appState.set("recentSavedStep", this.postToJournal.postToJournal.journalPost)
          this.appState.set("refreshPosts", true)
          this.appState.set('isNewStep', undefined)
          this.toasterService.success(this.i18n.t('Step saved.'))
          this.cdRef.detectChanges()
          this.isAlreadyPresent = true

        },
          (error) => {
            this.spinnerService.off()
            console.log('error :', error);
          })
      }
    })
  }

  saveDocument(document, pathId) {
    const dialogRef = this.dialog.open(AlertAddNotesComponent)
    dialogRef.afterClosed().subscribe((result) => {

      if (result === 'confirm') {
        let text = localStorage.getItem('journalNote')
        let requestData;
        requestData = {
          'text': [text],
          'pathId': pathId,
          'contentSurveyId': null,
          'habitId': null,
          'pollId': null,
          'documentId': document.id,
          'questionId': null,
          'inoutScreenId': null,
          'routineInfoId': null,
          'screenId': this.savedScreenId,
          'reflectionId': null
        }

        this.spinnerService.on()
        this.libraryService.save(requestData).subscribe((response) => {
          console.log("response :", response);
          this.savedLibraryPosts = this.sessionService.getLibraryPosts();
          if (this.appState.get("FirstTimeTabCheck")) {
            this.appState.set("tabLabel", "saved");
          }
          this.postToJournal = response;
          //this.spinnerService.off()
          this.appState.set("recentSavedStep", this.postToJournal.postToJournal.journalPost)
          this.appState.set("refreshPosts", true)
          this.appState.set('isNewStep', undefined)
          this.toasterService.success(this.i18n.t('Document saved.'))
          this.cdRef.detectChanges()
          this.isDocumentOnly = true

        },
          (error) => {
            this.spinnerService.off()
            console.log('error :', error);
          })
      }
    })
  }

  deletePost(post: any, type: string) {
    console.log('type :', type);
    const requestData = {
      id: post?.id
    }
    this.libraryService.deletePost(post?.id).subscribe(
      (response) => {
        this.savedLibraryPosts = this.sessionService.getLibraryPosts()
        this.appState.set('recentSavedStep', undefined)
        console.log('response :', response);
        this.toasterService.success(this.i18n.t('Post Deleted!'))  
        this.checkLibraryStatus(this.poll, this.contentSurvey, this.document, this.question, this.habit, this.reflection)
        if (type === 'step') {
          this.isAlreadyPresent = undefined
        } else if (type === 'document') {
          this.isDocumentOnly = undefined
        }
        if(this.appState.get('FirstTimeTabCheck')) {
          if(this.appState.get("savedPostsCount")>0){
            this.appState.set('tabLabel', 'saved')
          } 
          else {
            this.appState.set('tabLabel', 'recommended')
          } 
        }
        this.cdRef.detectChanges()

      },
      (error) => {
        console.log('Error for feedback ', error)
      }
    )
  }

  sendPathCompletionEmail(userId: number) {
    this.utilService.sendEmail(userId).subscribe((response) => {
      console.log("response :", response);
    },
      (error) => {
        console.log('error :', error);
      })
  }

  showKeyboard() {
    if (this.textareaElement && this.screensIds[this.currentScreenIndex] === "daily-content-screen-reflection") {
      this.textareaElement.nativeElement.focus()
      this.utilService.enableHeight(this.textareaContainer, this.isMobile, this.platform, this.keyboard)
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
      this.contentClick(event)
      let singlePoll = this.appState.get('singlePoll')
      if (singlePoll) {
        this.disableListener?.forEach(element => {
          if(element?.nativeElement.contains(event.target)) {
            this.enableBlur =false;
           this.blurCard(this.enableBlur,this.linearScale, this.pathAverage,singlePoll);
         }
        });
      }
  }

  getActions(userId: number, pathId: number) {
    const requestData = {
      'user_id': userId,
      'path_id': pathId
    }
    this.dailyStepsService.getActionsList(requestData).subscribe((response) => {
      this.todoActions = response.data[0].action
      this.todoRoutines = this.homev5Service.removeDuplicates(response.data[0].routine, "routine_info_id")
      this.appState.set('todoRoutines',this.homev5Service.removeDuplicates(response.data[0].routine, "routine_info_id"))
      this.appState.set('todoActions',response.data[0].action)

      if (!this.isMobile && !this.screensIds.includes('daily-content-screen-todo') && this.toDos) {
        this.addScreen('daily-content-screen-todo')
      }

    }, (error) => {
      console.log('error :', error);
    })
  }

 saveAccountabilityEmails() {
   console.log("accountability emaols", this.onboardLearningComponent?.arrayOfAccountabilyEmails);
  //  if (this.currentUser.accountabilityEmails?.length == 0 || (this.appState.get("accountabilityEmails") == undefined || this.onboardLearningComponent.arrayOfAccountabilyEmails?.length == 0)) {
     this.onboardLearningComponent.pushAccountablityEmails(this.currentDay)
  //  }
  }  
}
