import { Component, DoCheck, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppState } from '../app.service';
import { CordovaService } from '../cordova.service';
import { RoutineInfo } from './routine-info.model';
import { RoutineLevel } from './routine-level.model';
import { HomeUiService } from '../../app/new-design/home-ui.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
@Component({
  selector: 'first90-routine-sublevel',
  templateUrl: './routine-sublevel.component.html',
  styleUrls: ['./routine-sublevel.component.scss'],
  animations: [
    trigger('popupAnimation', [
      state('void', style({ opacity: 0, transform: 'scale(0.9)' })),
      state('*', style({ opacity: 1, transform: 'scale(1)' })),
      transition('void <=> *', animate('300ms ease-in-out')),
    ]),
  ]

})
export class RoutineSubLevelComponent implements OnInit, DoCheck {

  @Input() routines: RoutineInfo
  @Input() levels: RoutineLevel
  @Input() inverted = false
  levelCount: number
  selectedLevels: any
  indexOfLevel: number
  totalLevels: number=0;
  isPopupVisible: boolean = false;
  isMobile = false;
  isMobileWeb = false
  isMobileWebIos = false;
  constructor(private cordovaService: CordovaService,
    public homeUIService: HomeUiService,
    public appState: AppState) {
     }

  ngOnInit() {
     this.isMobile = this.appState.get('isMobile')
      this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
  }

  ngDoCheck(){
    this.selectedLevels = this.appState.get('selectedLevelIds')
    this.indexOfLevel = this.appState.get('indexOfLevel')
    this.levelCount = this.selectedLevels?.findIndex(item => item.id === this.levels.id)
    // if (!this.appState.get('levelUpdated')) {
    //   this.levelCount = 1
    //   this.totalLevels = this.routines.levels.length
    // }else {
    if (!this.appState.get('levelUpdated') && this.levelCount != this.selectedLevels[this.levelCount]?.index) {
        this.levelCount = this.levelCount+1
    }
    else {
      this.levelCount = this.indexOfLevel + 1
      if (this.selectedLevels?.length > 0) {
        this.totalLevels = this.selectedLevels?.length;
      }
      else {
         this.totalLevels = this.routines.levels.length
      }
     
    }
   // }
    if(this.levels && this.levels.enabled==false )
    {
      let flag =false
        this.routines.levels.forEach((level,index)=>{
          if(level.enabled && !flag && level.level == this.appState.get('LevelNumber')) {
            this.levels =level
            this.appState.set('indexOfLevel', index) 
            flag =true;
          }
        })
    }
  }

  openPopup() {
    this.isPopupVisible = !this.isPopupVisible;
  }
  closePopup() {
    this.isPopupVisible = false;
  }
}
