import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverse'
})
export class ReversePipe implements PipeTransform {
  transform(value?: any, args?: any): any {
    return value ? value.slice().reverse() : null
  }
}
