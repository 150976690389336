import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Filter } from '../shared/filter'
import * as query from './custom-badge.query'
import { filter, map } from 'rxjs/operators'
import { Observable } from 'rxjs'

import { CustomBadge } from './custom-badge.model'
import { environment } from '../../environments/environment'
import { Path } from '../path/path.model'
import { User } from '../user/user.model'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { AppState } from '../app.service'

@Injectable()
export class CustomBadgeService {

  API_URL: string = environment.apiBaseUrl
  listUserBadge = '/listUserBadges'

  currentPath: Path
  currentUser: User

  constructor(
    private httpclient: HttpClient,
    private apollo: Apollo,
    private appState: AppState,
    ) { }

    getListCustomUserBadge(data): Observable<any> {
      const headers = new HttpHeaders();
       headers.set("Authorization", localStorage.getItem("token"));
        const params = new HttpParams().append('user_id', data.user_id)
                                        .append('path_id', data.path_id);
      return this.httpclient.get(this.API_URL+this.listUserBadge,{headers,params})
                            .pipe(
                              map(response => {
                                return response
                              })
                            )
    }


  getList(filter: Filter = null): Observable<CustomBadge[]> {
    return this.apollo
      .watchQuery<any>({ query: query.listCustomBadges, variables: { filter: filter } })
      .valueChanges
      .pipe(
        map(response => {
          const list: CustomBadge[] = []
          response.data.customBadges.forEach(element => { list.push(new CustomBadge(element)) })
          return list
        })
      )
  }

  getUserList(filter: Filter = null): Observable<CustomBadge[]> {
    return this.apollo
      .watchQuery<any>({ query: query.listUserCustomBadges, variables: { filter: filter } })
      .valueChanges
      .pipe(
        map(response => {
          console.log("Badge response ",response);
          
          const list: CustomBadge[] = []
          response.data.userCustomBadges.forEach(element => { list.push(new CustomBadge(element.customBadge)) })

          console.log("Badge response list ",response);


          return list
        })
      )
  }

  getCustomBadge(id: number): Observable<CustomBadge> {
    return this.apollo
      .watchQuery<any>({ query: query.findCustomBadge, variables: { id: id } })
      .valueChanges
      .pipe(
        map(response => {
          return new CustomBadge(response.data.customBadge)
        })
      )
  }

  save(customBadge: CustomBadge) {
    return this.apollo.mutate({
      mutation: query.updateCustomBadge,
      variables: {
        customBadge: customBadge.input()
      }
    })
  }
}
