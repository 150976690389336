import { Component, OnInit, HostListener, ViewChild, ElementRef, AfterViewInit } from '@angular/core'
import { PtrAnimates } from './ptr-animates'
import { AppState } from '../app.service'

@Component({
  selector: 'ptr-container',
  templateUrl: './ptr-container.component.html',
  styleUrls: ['./ptr-container.component.scss']
})
export class PtrContainerComponent implements OnInit, AfterViewInit {
  @ViewChild('container') container: ElementRef
  @ViewChild('ptrControl') ptrControl: ElementRef

  animates: PtrAnimates
  distance: number
  offset: number
  state: string // state: pulling, aborting, reached, refreshing, restoring

  threshold: number = 300

  touchId: any
  startX: any
  startY: any
  panstartCalled: boolean

  constructor(
    private appState: AppState
  ) {
  }

  @HostListener('document:touchstart', ['$event'])
  touchstart(e) {
    const touch = e.changedTouches[0]
    this.touchId = touch.identifier
    this.startX = touch.screenX
    this.startY = touch.screenY
  }

  @HostListener('document:touchmove', ['$event'])
  touchmove(e) {
    if (this.calcMovement(e)) {
      if (!this.panstartCalled) {
        this.onpanstart(e)
        this.panstartCalled = true
      }
      this.onpanmove(e)
    }
  }

  @HostListener('document:touchend', ['$event'])
  touchend(e) {
    if (this.calcMovement(e)) {
      this.onpanend(e)
    }
  }

  onpanstart(e) {
  }

  onpanmove(e) {
    let d = e.deltaY

    if (this.scrollTop() > 0 || d < 0 && !this.state || this.state in {aborting: 1, refreshing: 1, restoring: 1}) {
      return
    }

    if (this.distance == null) {
      this.offset = d
      this.state = 'pulling'
      this.addClass(this.state)
      this.onStateChange(this.state)
    }

    d = d - this.offset
    if (d < 0) {
      d = 0
    }
    this.distance = d

    if (d >= this.threshold && this.state !== 'reached' || d < this.threshold && this.state !== 'pulling') {
      this.removeClass(this.state)
      this.state = this.state === 'reached' ? 'pulling' : 'reached'
      this.addClass(this.state)
      this.onStateChange(this.state)
    }

    this.animates.pulling(d)
  }

  onpanend(e) {
    if (this.state == null) {
      return
    }

    if (this.state === 'pulling') {
      this.removeClass(this.state)
      this.state = 'aborting'
      this.onStateChange(this.state)
      this.addClass(this.state)
      this.animates.aborting().then(() => {
        this.removeClass(this.state)
        this.distance = this.state = this.offset = null
        this.onStateChange(this.state)
      })
    } else if (this.state === 'reached') {
      this.removeClass(this.state)
      this.state = 'refreshing'
      this.addClass(this.state)
      this.onStateChange(this.state)
      this.animates.refreshing()

      this.refresh().then(() => {
        this.removeClass(this.state)
        this.state = 'restoring'
        this.addClass(this.state)
        this.onStateChange(this.state)

        this.animates.restoring().then(() => {
          this.removeClass(this.state)
          this.distance = this.state = this.offset = null
          this.onStateChange(this.state)
        })
      })
    }
  }

  onStateChange(state: string) {
  }

  ngOnInit() {
    
  }

  ngAfterViewInit(){
    this.animates = new PtrAnimates(this.ptrControl.nativeElement, this.threshold)
  }

  scrollTop() {
    //if (!scrollable || [window, document, document.body, document.documentElement].includes(scrollable)) {
    return document.documentElement.scrollTop || document.body.scrollTop
    //} else {
    // return scrollable.scrollTop
    //}    
  }

  refresh() {
    return new Promise(resolve => {
      // here to fetch the data and rerender the contents.
      this.appState.refreshMainComponent.emit()
      setTimeout(resolve, 0)
    })
  }

  calcMovement(e) {
    const touch = Array.prototype.slice.call(e.changedTouches).filter(touch => touch.identifier === this.touchId)[0]
    if (!touch) {
      return false
    }
    e.deltaX = touch.screenX - this.startX
    e.deltaY = touch.screenY - this.startY
    return true
  }

  addClass(cls) {
    this.container.nativeElement.classList.add('pull-to-refresh--' + cls)
  }

  removeClass(cls) {
    this.container.nativeElement.classList.remove('pull-to-refresh--' + cls)
  }
}
