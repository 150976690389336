declare var require: any;
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import {Location} from '@angular/common'

import { AppState } from '../../app.service'
import { Client } from '../../client/client.model'
import { CookieService } from 'ngx-cookie-service'
import { User } from '../../user/user.model'
import { WalletService } from '../../wallet/wallet.service'
import { environment } from '../../../environments/environment'
import { CordovaService } from '../../cordova.service'
import { Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { DailyStepsComponent } from '../../daily-steps/daily-steps.component'
import { Path } from '../../path/path.model'

declare var $: any

@Component({
  selector: 'first90-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{
  @Input() headerText: string
  @Input() backBtn = false
  @Input() backUrl = '/main/settings'
  @ViewChild('moreModal') moreModal: ElementRef
  @ViewChild('moreBtn') moreBtn: ElementRef
  @ViewChild('dailyStepsComponent') dailyStepsComponent: DailyStepsComponent
  
  user: User
  client: Client
  clientImageUrl: string = 'assets/images/default-logo.jpg' // LUMBA-1389
  currentTime: string = null
  environment: any
  onCordova = false

  homeRoute = '/main';
  activeIcon: string;
  chatRoute = '/main/questions';
  exploreRoute = '/main/documents';
  commitmentsRoute = '/commitments';
  
  $moreModal: any
  onMobile = false
  onIE: boolean 
  lengthOfSnoozed: number

  scheduledStep: any;
  intro: any;
  currentDayStarted: boolean;
  path: Path;

  isMobile: boolean
  routineVersion: boolean
  enabledAttributes: any 

  constructor(
    private appState: AppState,
    private cookieService: CookieService,
    private _location: Location,
    public walletService: WalletService,
    private cordovaService: CordovaService,
    private router: Router, 
    private dialogRef: MatDialog
  ) {
    this.user = this.appState.get('currentUser')
    this.client = this.appState.get('currentClient')
    if (this.client && this.client.imageUrl) {
      this.clientImageUrl = this.client.imageUrl
    }
    this.currentTime = this.cookieService.get('current_time') || ''
    this.environment = environment
    this.onCordova = this.cordovaService.onCordova
    this.path = this.appState.get("currentPath");
    this.lengthOfSnoozed = this.appState.get('snoozedSteps')
    this.scheduledStep = this.appState.get('scheduleDayForCurrentStep')
    this.currentDayStarted = !!localStorage.getItem(
      `Toggle:User:${this.user.id}:DayProgress:${this.path.id}:${this.scheduledStep}`
    )
    this.isMobile = appState.get('isMobile')

    this.enabledAttributes = this.appState.get("routineVersion")
    if (this.enabledAttributes.routineVersion) {
      this.routineVersion = this.enabledAttributes.routineVersion
    }else{
      this.routineVersion = false
    }
  }


  ngOnInit() {
    if (this.onIE) {
      $('body').addClass('onIE')
    }

    if(this.router.url==this.homeRoute){
      this.activeIcon='Home'
    }else if(this.router.url==this.chatRoute){
      this.activeIcon='Chat'
    } else if(this.router.url==this.commitmentsRoute){
      this.activeIcon='Commitments'
    }

  }

  setCurrentTime() {
    this.cookieService.set('current_time', this.currentTime, 365, '/')
    window.location.reload()
  }

  back() {
    // window.history.back()
    // this._location.back()
  }

  openModal() {
    $('#action-points-modal').modal('show')
  }

  ngAfterViewInit() {
    
    if(!this.lengthOfSnoozed){
      try{

        if (this.dailyStepsComponent && this.dailyStepsComponent.getSnoozedSteps()) {
          this.lengthOfSnoozed = this.dailyStepsComponent.getSnoozedSteps().length
        }
        
      }catch(error){
        console.log('lengthOfSnoozed error',error)
      }
    }
    
    if (!this.isMobile) {
      this.$moreModal = $(this.moreModal.nativeElement)

      this.$moreModal.on('shown.bs.modal', () => {
        $('.modal-backdrop').click(() => { this.$moreModal.modal('hide') })
      })
    }
    

  }

  setClass(icon){
    this.activeIcon = icon;
  }
  getClass(icon){
    return this.activeIcon == icon;
  }

  isSnoozedDotPresent(): boolean {
    if (this.lengthOfSnoozed > 0) {
      return true
    } else {
      return false
    }
  }

}
