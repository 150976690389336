import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from '../app.service';
import { Client } from '../client/client.model';
import { HomeV5Service } from '../pages/home-v5/home-v5.service';
import { Path } from '../path/path.model';
import { SessionService } from '../session/session.service';
import { ToasterService } from '../toaster.service';
import { User } from '../user/user.model';
import { I18n } from '../i18n.service';
import { HomeUiService } from '../new-design/home-ui.service';

@Component({
  selector: 'app-change-subpath-alert',
  templateUrl: './change-subpath-alert.component.html',
  styleUrls: ['./change-subpath-alert.component.scss'],
})
export class ChangeSubpathAlertComponent implements OnInit {

  
  responseData: any = null
  task: any
  isLoading = true;
  path: Path
  user: User
  client: Client
  isonboardingcompleted: any;
  isDoneButton: any = false;
  pathGroupId: any;
  //dialogRef: any;

  constructor(
    private homeService: HomeV5Service,
    private appState: AppState, 
    private dialogRef: MatDialog,
    private route: ActivatedRoute,
    private toasterService: ToasterService,
    private i18n: I18n,
    private homeUiService: HomeUiService,
    private router: Router,
    private sessionService: SessionService ) {

   }

  ngOnInit() {

    this.path = this.appState.get('currentPath')
    this.user = this.appState.get('currentUser')
    this.client = this.appState.get('currentClient')
    this.pathGroupId = localStorage.getItem('pathGroupId')
    this.getSubPathList()



  }


  submit(){
    console.log('Id of the subpath', this.task);

    this.changeSubPath()
  }

  closeDialog() {
    if(this.dialogRef!=null)
    this.dialogRef.closeAll();
  }

  private changeSubPath() {
    const request = {
      'user_id': this.user.id,
      'active_path_id': this.task.path_id
    }

    console.log("Sub path change ", request)
    this.homeService.subpathchange(request).subscribe(
      (response) => {
        console.log("Response of path changed ", response)

        this.sessionService.removeLocalRoutinesData() 
       
        let data = response["data"];
        console.log('isOnBOARDING', this.isonboardingcompleted)
        try {
          let isOnboardContentPresent = data.onboardingSurvey || data.isIntroVideo;
          if (data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted?.length > 0 && isOnboardContentPresent) {
            let value = data['isonboardingcompleted'][0];
            this.isonboardingcompleted = value["isonboardingcompleted"];
            if ((this.isonboardingcompleted === false || this.isonboardingcompleted === null) && isOnboardContentPresent) {
              this.router.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          
            } else if (this.isonboardingcompleted === true) {
              this.router.navigate(['/main']);
            }
          } else if(data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted.length===0 && isOnboardContentPresent){
              this.router.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          
          }else{
            this.homeUiService.checkSubpathStatus();
            this.router.navigate(['/main']);
          }
          this.refresh()
      }catch (error) {
          this.router.navigate(['/main']);
          this.toasterService.error(this.i18n.t('Server or connection issue. Please check your internet connection.'))
          console.log('onboarding video profile',error)
        }
        this.refresh()
      }, (error) => {
        console.log("Response of path changed ", error)
        this.toasterService.error(this.i18n.t('Server or connection issue. Please check your internet connection.'))
      }
    )
  }

  refresh() {
    return new Promise(resolve => {
      // here to fetch the data and rerender the contents.
      this.appState.refreshMainComponent.emit()
      setTimeout(resolve, 0)
      
    })

  }

  selectButton(value:any){
    console.log('is Done button ',value);
    
    this.isDoneButton = value;
  }
  private getSubPathList(){

    const request = {
        'client_id': this.client.id,
        'path_group_id': this.pathGroupId
    }

    this.homeService.getSubPathList(request).subscribe(
      (response) => {
        this.isLoading = false
        this.responseData = response.data
        console.log('current path', this.path)
        console.log('Response for feedback ', response)
      },
      (error) => {
        console.log('Error for feedback ', error)
      }
    )
  }

}
