import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { AppState } from "../app.service";
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import * as query from './library.query'
import { environment } from "src/environments/environment";
import { HomeV5Service } from "../pages/home-v5/home-v5.service";
import { Router } from "@angular/router";
import { HomeUiService } from "../new-design/home-ui.service";
import { MyLibraryComponent } from "./my-library.component";


@Injectable()
export class LibraryService {

  API_URL: string = environment.apiBaseUrl
  edit = '/edit_journal'
  editDocument='/edit_document'
  delete = '/delete_journal'
  casualDocuments = '/casual_documents'
  postAnnotationDocument: string = '/files'
  constructor(
    private apollo: Apollo,
    private appState: AppState,
    private httpclient: HttpClient,
    private homev5Service: HomeV5Service,
    private homeUIService:HomeUiService,
    private router: Router,) {

  }

  save(library: any) {
    return this.apollo.mutate({
      mutation: query.saveToLibrary,
      variables: {
        journalPost: {
          text: library.text,
          pathId: library.pathId,
          contentSurveyId: library.contentSurveyId,
          habitId: library.habitId,
          pollId: library.pollId,
          documentId: library.documentId,
          editedDocumentUrl: library?.editedDocumentUrl,
          questionId: library.questionId,
          inoutScreenId: library.inoutScreenId,
          routineInfoId: library.routineInfoId,
          screenId: library.screenId,
          reflectionId: library.reflectionId
        }
      }
    }).pipe(
      map(response => {
        return response.data
      })
    )
  }

  public editNote(data): Observable<any> {
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));  
    return this.httpclient.post(this.API_URL + this.edit, data,{headers})
  }

  public editAnnotationDoc(editedDataUrl): Observable<any> {
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));  
    return this.httpclient.post(this.API_URL + this.editDocument, editedDataUrl,{headers})
  }

  public postAnnotationDoc(data): Observable<any>{
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));  
    return this.httpclient.post<any>(this.API_URL + this.postAnnotationDocument, data,{headers});
  }

  public deletePost(id: number, type:string=null): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Authorization', localStorage.getItem('token'));
    const params = new HttpParams().append('id', id)
                                    .append('type', type);
    return this.httpclient.delete(this.API_URL + this.delete, { headers, params })
  }

  public getCasualDocuments(data): Observable<any> {
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
    const params = new HttpParams().append('client_id', data.clientId)
                                    .append('user_id', data.userId);
    return this.httpclient.get(this.API_URL + this.casualDocuments, { headers, params })
}

  checkNotePresent(post){
    if (post) {
      var b = JSON.stringify(post);
      let testNote = b.replace(/\\/g, '');
      let testNote1 = testNote.replace(/[\[\]']+/g, '')
      let note = testNote1.replace(/"/g, '');
      if (note.length >= 1) {
        return true
      }
    }
  }

  changeSubPath(prevPathData: any) {
    console.log('prevPathData :', prevPathData);
    const request = {
      'user_id': prevPathData.userId,
      'active_path_id': prevPathData.pathId
    }
    this.refresh()
    this.homev5Service.subpathchange(request).subscribe(
      (response) => {
        console.log('response :', response);
        let data = response["data"];
        try {
          this.refresh()
          let isOnboardContentPresent = data.onboardingSurvey || data.isIntroVideo;
          if (data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted?.length > 0 && isOnboardContentPresent) {
            let value = data['isonboardingcompleted'][0];
            let isonboardingcompleted = value["isonboardingcompleted"];
            if ((isonboardingcompleted === false || isonboardingcompleted === null) && isOnboardContentPresent) {
              this.router.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          
            } else if (isonboardingcompleted === true) {
              // this.router.navigate(['/main/library']);
              this.router.navigate(['/main']);
              // setTimeout(()=>{
              //   this.homeUIService.openNextComponent(MyLibraryComponent);
              // },700);
            }
          } else if(data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted.length===0 && isOnboardContentPresent){
              this.router.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          
          } else {
            this.homeUIService.checkSubpathStatus();
            this.router.navigate(['/main']);
          }
        } catch (error) {
          this.router.navigate(['/main']);
        }
      }, (error) => {
        console.log("Response of path changed ", error)
      }
    )
  }

  refresh() {
    return new Promise(resolve => {
      this.appState.refreshMainComponent.emit()
      setTimeout(resolve, 0)
    })
  }

}