import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Apollo } from 'apollo-angular';
import { AppState } from '../app.service';
import { I18n } from '../i18n.service';
import { MobileChatServiceService } from '../pages/mobile-chat-board/mobile-chat-service.service';
import { Path } from '../path/path.model';
import { Question } from '../question/question.model';
import { Reply } from '../reply/reply.model';
import { RoutineV2Service } from '../routine-v2/routine-v2.service';
import { ToasterService } from '../toaster.service';
import { User } from '../user/user.model';

@Component({
  selector: 'app-comment-list-modal',
  templateUrl: './comment-list-modal.component.html',
  styleUrls: ['./comment-list-modal.component.scss'],
})
export class CommentListModalComponent implements OnInit {

  @Input() name: string;

  public question: any
  @Input() mode: string = 'question'
  @Input() isPreview: boolean
  @Input() inverted: boolean = false
  @Input() small = false

  path: Path
  user: User
  public replies: Reply[]
  public repliesTree: Reply[]
  sortBy: string = 'timestamp'

  ishideComment:boolean = true;
  repliesEnabled: any
  questionText: string

  constructor(
    private appState: AppState,
    private toastrService: ToasterService,
    private i18n: I18n,
    private routineV2Service: RoutineV2Service,
    public modalCtrl: ModalController,
    private cdRef: ChangeDetectorRef, 
  ) { 
    this.path = this.appState.get('currentPath')
    this.user = this.appState.get('currentUser')
    this.user = this.appState.get('currentUser')
    this.question = this.appState.get('question')
    console.log('question', this.question);

    this.repliesEnabled = this.question.repliesEnabled
    this.questionText = this.question.text
  }

  ngOnInit() { 
    
  }

  ngAfterViewInit() {
    this.getQuestionDetails();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.question || changes.question.firstChange) {
      return false
    }
    this.question.sortRepliesBy(this.sortBy)
    this.cdRef.detectChanges()
  }
  
  async close() {
    const closeModal: string = "Modal Closed";
    await this.modalCtrl.dismiss(closeModal);
  }

  HideComment(){
      this.ishideComment=false
  }

  ShowComment(){
      this.ishideComment=true
  }

  toggleSort(by: string) {
    console.log("by:" + by);
    this.sortBy = by
    this.question.sortRepliesBy(by)
  }


  getQuestionDetails(){
    //  this.spinnerService.on()
    var mode = this.capitalizefirstletter(this.mode);

    console.log('questionId',this.question.id)
    console.log('path',this.path)
    console.log('user',this.user)
    console.log('mode',mode)

    try{
      const data = {
        id : this.question,
        path_id : this.path.id,
        user_id : this.user.id,
        mode : mode
      }
      console.log('data',data)
      this.routineV2Service.getHabitDetails(data).subscribe(
        (response) => {
          this.appState.set('isQuestionCompleted', response.data.isCompleted)
          var level1 = response.data.replies
          
          if (level1) {
            var subReply = level1.filter(item => item.repliableId)
            console.log('subReply',subReply)
              
            level1.forEach(reply => {
              subReply.forEach(element => {
                if (element.repliableId === reply.id) {
                  reply.replies.push(element)
                }
                reply.timestamp = new Date(reply.createdAt)
              });
            }); 
  
            this.repliesTree = level1.filter(reply=> reply.id && reply.level === 1)
  
            console.log('repliesTree',this.repliesTree)
            this.appState.set('repliesLength', this.repliesTree.length) 
          }
       
      },

       (error) => {
        console.log('error',error)
        this.toastrService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
      }
      )
    }catch (error){
      console.log('error',error)
        this.toastrService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
    }
  } 

  capitalizefirstletter(word: string){
    return word.charAt(0).toUpperCase() + word.slice(1)
  }

}
