import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyCommitmentsService {
  isLoaded =new Subject<boolean>();
  constructor(private modalController: ModalController) { }
  setLoader(val){
    this.isLoaded.next(val);
  }
  get getLoader(){
    return this.isLoaded.asObservable()
  }

  closeModals(){
    this.modalController.dismiss()
    setTimeout(() => {
      this.modalController.dismiss()
    }, 550);
  }
}
