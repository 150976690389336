import gql from 'graphql-tag'

export const checklistItemFields = gql`
fragment checklistItemFields on Item {
    id
    checklistId
    text  
    actionPoints
    isCompleted
    createdAt
    updatedAt
    enabled
}
`

export const checklistFields = gql`
fragment checklistFields on Checklist {
    id
    display
    text  
    items {
      ...checklistItemFields
    }
    startDay
    endDay
    createdAt
    updatedAt
    enabled
}
${checklistItemFields}
`

export const toggleChecklistItem = gql`
mutation toggleChecklistItem($checklistItemId: Int!) {
  toggleChecklistItem(checklistItemId: $checklistItemId) {
    status
    actionPoints
  }
}
`

export const updateChecklist = gql`
mutation updateChecklist($checklist: ChecklistInput!) {
  updateChecklist(checklistInput: $checklist) {
    ...checklistFields
  }
}
${checklistFields}
`

export const findChecklist = gql`
query findChecklist($id: Int!) {
  checklist(id: $id) {
    ...checklistFields
  }
}
${checklistFields}
`

export const listChecklists = gql`
query listChecklists($filter: FilterInput) {
  checklists(filter: $filter) {
    ...checklistFields
  }
}
${checklistFields}
`

export const listPathChecklists = gql`
query listPathChecklists($filter: FilterInput, $userId: Int) {
  pathChecklists(filter: $filter, userId: $userId) {
    id
    pathId
    enabled
    checklist {
      ...checklistFields
    }
  }
}
${checklistFields}
`
