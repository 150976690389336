import { Component, OnInit } from '@angular/core';
import { AppState } from '../app.service';

@Component({
  selector: 'app-alert-animation',
  templateUrl: './alert-animation.component.html',
  styleUrls: ['./alert-animation.component.scss'],
})
export class AlertAnimationComponent implements OnInit {

  isMobile: boolean

  constructor(private appState: AppState) { 

    this.isMobile = this.appState.get('isMobile')
  }

  ngOnInit() {
  }

}
