import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppState } from '../app.service';
import { CordovaService } from '../cordova.service';
import { RoutineInfo } from './routine-info.model';
import { RoutineLevel } from './routine-level.model';
import { HomeUiService } from '../new-design/home-ui.service';


@Component({
  selector: 'meet-cue',
  templateUrl: './meet-cue.component.html',
  styleUrls: ['./meet-cue.component.scss']
})
export class MeetCueComponent implements OnInit {

    @Input() inverted : boolean = false
    @Input() levels : RoutineLevel
    changeCue: boolean = false

  constructor(private cordovaService: CordovaService,
    public homeUIService: HomeUiService,
     private appState: AppState) { 
    this.changeCue = this.appState.get('changeCue')
  }

  ngOnInit() {
  }

}
