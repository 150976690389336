import { Component, ElementRef, OnInit, Renderer2, ViewChild, ViewChildren } from '@angular/core'
import { PathGroup } from '../path-group/path-group.model'
import { PathGroupService } from '../path-group/path-group.service'
import { ActivatedRoute, Router } from '@angular/router'
import { SignUpForm, User} from '../user/user.model'
import { SessionService } from '../session/session.service'
import { AppState } from '../app.service'
import { I18n } from '../i18n.service'
import { HomeUiService } from '../new-design/home-ui.service';
import { NotificationService } from '../notification/notification.service'
import { UserService } from '../user/user.service'
import { CordovaService } from '../cordova.service'
import { ThemeService } from '../theme/theme.service'
import { SpinnerService } from '../spinner.service'
import { CohortService } from '../cohorts/cohort.service'
import { ToasterService } from '../toaster.service'
import { expandOnEnterAnimation } from 'angular-animations'
import {AlertSelectPathComponent } from '../alert-select-path/alert-select-path.component'
import { MatDialog } from "@angular/material/dialog"
import { ModalController } from '@ionic/angular';
import { AnimationsService } from 'src/app/shared/animations.service';

import { trigger, state, style, animate, transition } from '@angular/animations';

export interface SignUpByForm{
  [signUpByForm: string]: any
}

export interface Collapse {
  isCollapsable: boolean;
  isCollapsed: boolean;
}

export interface SetActivePath{
  [setActivePath: string]: any
}

@Component({
  selector: 'app-path-select',
  templateUrl: './path-select.component.html',
  styleUrls: ['./path-select.component.scss'],
  animations:[
    expandOnEnterAnimation({ duration: 150 }),
    trigger('fadeInOut', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [
        style({ opacity: 0 }), // Remove height styling
        animate('1s ease-out', style({ opacity: 1 })) // Adjust duration to make it slower
      ]),
      transition(':leave', [
        animate('0.2s ease-in', style({ opacity: 0, height: 0 }))
      ])
    ]),
    trigger('fadeInOut2', [
      state('in', style({ opacity: 1, transform: 'scale(1)', maxHeight: '1000px' })),
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.95)', maxHeight: 0 }),
        animate('0.5s ease', style({ opacity: 1, transform: 'scale(1)', maxHeight: '1000px', height: '*' }))
      ]),
      transition(':leave', [
        animate('0.5s ease', style({ opacity: 0, transform: 'scale(0.95)', maxHeight: 0, height: 0 }))
      ])
    ])

  ]
  // animations: [
  //   trigger('modalAnimation', [
  //     state('open', style({
  //       transform: 'translateY(0%)',
  //       opacity: 1
  //     })),
  //     state('closed', style({
  //       transform: 'translateY(100%)',
  //       opacity: 0
  //     })),
  //     transition('closed => open', [
  //       animate('100ms ease-out') // Adjust the duration as needed
  //     ]),
  //     transition('open => closed', [
  //       animate('100ms ease-in') // Adjust the duration as needed
  //     ]),
  //   ]),
  // ],
  
  
})
export class PathSelectComponent implements OnInit {

  clientId: Number
  collapse: Array<Collapse>=[];
  clientBackgroundImageUrl: string
  activePathId?: number
  currentPathGroup: PathGroup
  pathGroups: PathGroup[]
  origin: string
  data: SignUpForm
  loading = false
  onCordova = false
  public formSignUp: SignUpByForm
  public activePath: SetActivePath
  cohorts: any
  cohortId?: number
  pathList: any
  user: User
  enbleBtn:boolean;
  isMobile: boolean
  clientImage;
  verifyEmail: string;
  isCohort: any

  @ViewChild('path') path: ElementRef;
  @ViewChild('textCount') textCount: ElementRef;
  @ViewChildren('cardElement') cardElement: ElementRef[];
  @ViewChild('dynamicModal') dynamicModal: AlertSelectPathComponent;
  isDialogOpen = false;
  dialogState: 'open' | 'closed' = 'closed';
  maxHeight: number = 80;
  activeCohortId: any; 
  modalClosed = true;
  cohortSelectedPaths = []
  filteredPathsGroups = [];
  showVerification = false
  showCohort = true
  constructor(
    private i18n: I18n,
    private appState: AppState,
    private pathGroupService: PathGroupService,
    private spinnerService: SpinnerService,
    private notificationService: NotificationService,
    private userService: UserService,
    private sessionService: SessionService,
    private themeService: ThemeService,
    public route: ActivatedRoute,
    private router: Router,
    private render: Renderer2,
    private cordovaService: CordovaService,
    public cohortService: CohortService,
    private toasterService: ToasterService,
    private matDialog: MatDialog,
    private modalController: ModalController,
    public homeUIService: HomeUiService,
    private animationService: AnimationsService,
  ) {
    this.onCordova = this.cordovaService.onCordova
    this.user = this.appState.get('currentUser')
    this.isMobile = this.appState.get('isMobile')
    console.log('this.user :', this.user);
    this.origin = this.route.snapshot.queryParamMap.get('origin')
    const activePathId = localStorage.getItem('activePathId')
    if (activePathId) {
      this.activePathId = parseInt(activePathId, 10)
    }
    const clientId = localStorage.getItem('clientId')
    if (clientId) {
      this.clientId = parseInt(clientId, 10)
    }
    this.clientImage = this.appState.get('clientImage')

    // LUMBA-1396
    this.setClientBackground()
    if (this.origin === 'signup') {
      this.data = JSON.parse(this.appState.get('signupData'))
    } else if (this.origin === 'settings') {
      if (!this.appState.get('currentUser')) {
        this.loading = false
        this.goBack()
        return
      }
      const token = localStorage.getItem('token')
      if (!token) {
        this.loading = false
        return
      }
      this.userService.pathGroupsByToken(localStorage.getItem('token'))
        .subscribe(pathGroups => {
          this.pathGroups = pathGroups
          this.loading = false
        }, () => {
          this.loading = false
        })
    } else if (this.origin === 'login') {
      const email = localStorage.getItem('lastUserEmail')
      const clientSubDomain = this.themeService.getSubDomain() // LUMBA-1408
      if (!email || !(this.clientId)) {
        this.loading = false
        return
      }
      this.userService.pathGroupsByClientEmail(email, this.clientId.toString(), clientSubDomain, this.onCordova)
        .subscribe(result => {
          this.pathGroups = result.clientPathGroups
          const errors = result.errors
          if (errors.length > 0) {  // LUMBA-1391
            const messages = errors.map(e => this.i18n.t(e))
            this.toasterService.error(this.i18n.t(messages.join('<br>')))
          }
          this.loading = false
        }, error => {
          this.loading = false
          const messages = error.graphQLErrors.map(e => e.message)
          this.toasterService.error(this.i18n.t(messages.join('<br>')))
        })
    }
  }

  ngOnInit() {   
    if (!this.pathGroups) {
      this.pathGroups = this.appState.get('clientPathGroups')
      console.log('this.pathGroups :', this.pathGroups);
      this.getCohorts(this.pathGroups[0].clientId)
    }
    if (!this.pathGroups && this.origin === 'signup') {  // LUMBA-1392
      this.goToSignup()
    }
    if (!this.activePathId) {
      this.loading = false
      return
    }
    if (!this.clientId) {
      this.loading = false
      return
    } 
  }
  async openModal(){
    this.cohortId = this.activeCohortId;
    this.modalClosed = false
    const modal = await this.modalController.create({
      component: AlertSelectPathComponent,
      cssClass: 'select-path-model-custom',
      showBackdrop:false,
      componentProps: {
        data: this.filteredPathsGroups,
      },
      enterAnimation: this.animationService.slideLeftTopEnterAnimation ,
      leaveAnimation: this.animationService.slideLeftTopLeaveAnimation
    });
    
    modal.onDidDismiss().then((result) => {
      if (result.role === 'success') { 
        const selectedPath = result.data?.path; 
        this.activePathId = selectedPath?.id
        this.currentPathGroup = result.data?.pathGroup
        this.openShowVerification()
      } else {
        this.modalClosed = true
      }
    });
  await modal.present();
  }
 
  openShowVerification() {
   this.signUp()
    this.showCohort = false;
    this.showVerification = true
  }
  openDialog() {
    this.isDialogOpen = true;
    this.dialogState = 'open';
  }

  closeDialog() {
    this.dialogState = 'closed';
  }

  onDialogClosed() {
    this.isDialogOpen = false;
  }

  selectPath(i) {
    console.log("value i ..", i);
    const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--theme-color');

   let id = document.getElementsByClassName('add-border')

   for(let j=0; j<id?.length;j++) {
    if(i==j) {
    //  this.currentPathGroup = this.pathGroups[1].paths[i]
   
      id[j].setAttribute('style',`border:2px solid ${primaryColor};display:flex; border-radius: 7px; justify-content:center; width:97%;padding:1rem 0.7rem;`);
    }
    else {
      id[j].setAttribute('style','border:0;display:flex;width:97%;justify-content:center;padding:1rem 0.7rem;');
    }
 //   this.selectActivePath(path.id);
   }
   
  //  if(id){
  //   id[i].setAttribute('style','border:1px solid red');
  //  }
  }
  getCollapsedText(list) {
   // this.currentPathGroup = list
    if(list?.length>0 && list?.length==this.collapse?.length) {
      return true;
    }
   else if( list?.length>this.collapse?.length){
      this.cardElement?.forEach((cardElement, index) => {
     const cardHeight = cardElement.nativeElement.offsetHeight;
    //  const lineHeight = this.textCount?.nativeElement?.offsetHeight;
     if (cardHeight > this.maxHeight) {
       this.collapse.push({isCollapsable :true,isCollapsed :true})
       this.render.addClass(this.textCount.nativeElement, 'reply-texts');
     
   }else {
     this.collapse.push({isCollapsable :false,isCollapsed :false})
  
   }
  
   });
  return true;
  
    }
    else {
      return true;
    }
  }
  // LUMBA-1408
  setClientBackground() {
    const data = this.themeService.themeData
    console.log('data :', data);
    if (data && data.clientBackgroundImageUrl) {
      this.clientBackgroundImageUrl = data.clientBackgroundImageUrl
    } else if (this.clientBackgroundImageUrl) {

    } else if (this.origin === 'signup') {
      this.clientBackgroundImageUrl = 'assets/images/default-background.jpg'
    } else if (this.origin === 'settings') {
      this.clientBackgroundImageUrl = this.appState.get('currentClient').backgroundImageUrl
    } else if (this.origin === 'login') {
      this.clientBackgroundImageUrl = 'assets/images/default-background.jpg'
    }
  }

  selectActivePath(i,activePathId) {
    let currentPathGroup
    this.enbleBtn =true
    this.selectPath(i);
    this.activePathId = activePathId
    this.pathGroups.forEach(pathGroup => {
      pathGroup.paths.forEach(path => {
        if(path.id === activePathId){
          currentPathGroup = pathGroup
        }
      });
    });

    console.log('pathGrpId :', currentPathGroup);
    this.currentPathGroup = currentPathGroup
    //this.getCollapsedText(this.currentPathGroup);
   this.textExpand(i)
    //this.getCollapsedText(this.pathGroups[0].paths)
  }

  selectActivePathGroup(pathGroup) {
    console.log('pathGroup :', pathGroup);
    this.currentPathGroup = pathGroup
    this.getCollapsedText(this.currentPathGroup);
  }

  goToLogin() {
    this.router.navigate([`login`])
  }

  goToSignup() {
    this.router.navigate([`signup`])
  }

  goToMain() {
    this.router.navigate([`/main`])
  }

  goBack() {
    this.appState.set('signUpForm', this.data)
    this.appState.set('signupData',undefined)
    if (this.origin === 'signup') {
      this.goToSignup()
    } else if (this.origin === 'login') {
      this.goToLogin()
    } else if (this.origin === 'settings') {
      this.goToMain()
    }
  }
  // selectcohart(){
  //   const dialogRef = this.matDialog.open(AlertSelectPathComponent)
  //   dialogRef.afterClosed().subscribe((result) => {
  //   })
  // }
  
  signUp() {
    console.log('this.currentPathGroup.id :', this.currentPathGroup?.id);
    console.log('this.currentPathGroup :', this.currentPathGroup);
    console.log('this.activePathId :', this.activePathId);
    console.log('this.cohortId :', this.cohortId);

    if (!this.activePathId || !this.currentPathGroup.id) {
      return false
    }
    this.loading = true
    this.data['activePathId'] = this.activePathId
    this.data['cohortId'] = this.cohortId
    this.pathGroupService.getPathGroup(this.currentPathGroup.id).subscribe(pathGroup => {
        this.clientId = pathGroup.client.id
        this.clientBackgroundImageUrl = pathGroup.client.backgroundImageUrl

        console.log("Path data ",pathGroup);

        localStorage.setItem('clientId', this.clientId.toString())
        localStorage.setItem('activePathId', this.activePathId.toString())
        localStorage.setItem('activePathGroupId', pathGroup.id.toString())
        localStorage.setItem('clientBackgroundImageUrl', pathGroup.client.backgroundImageUrl)
        this.currentPathGroup = pathGroup
        this.data['clientId'] = this.clientId
        this.userService.signUp(new User(this.data))
          .subscribe(result => {
            this.formSignUp = result.data
              const user = this.formSignUp.signUpByForm.user
              const errors = this.formSignUp.signUpByForm.errors
              this.loading = false
              if (user && !errors) {
                this.verifyEmail = this.data.email;
                this.appState.set('verifyEmail', this.data.email)
                this.themeService.apply(pathGroup, pathGroup.client)  // LUMBA-1428
                this.goToLogin()
              } else if (errors && errors.length > 0) {
                console.log(errors)
                const messages = errors?.map(e => this.i18n.t(e))
                this.notificationService.alert(messages.join('<br>'), 'Oops!', {'enableHtml': true})
              }
            },
            (error) => {
            console.log('error :', error);
              this.loading = false
              const messages = error.graphQLErrors.map(e => e.message)
              this.notificationService.alert(messages.join('<br>'), 'Oops!')
            }
          )
      },
      (error) => {
        console.log('error :', error);
        this.loading = false
        this.router.navigate([`/not-found`])

      })
  }

  updateActivePath() {
    this.loading = true
    this.appState.set('activePathId', this.activePathId)  // LUMBA-1402
    this.appState.set('changePath', true)
    this.goToLogin()
  }

  setActivePath() {
    this.loading = true
    this.pathGroupService.setActivePath(this.activePathId, this.appState.get('currentUser').id)
      .subscribe( response => {
        this.activePath = response.data
        const data = this.activePath.setActivePath
        console.log("Path Data : ",data)
        this.sessionService.changePathData(data)
        this.goBack()
      }
    )
  }

  getCohorts(clientId){
    if (clientId) {
     // this.spinnerService.on()
      this.cohortService.getCohort(clientId).subscribe((cohort) => {
        //this.spinnerService.off()
        this.cohorts = cohort?.data
        this.isCohort = this.pathGroups && this.pathGroups[0]?.client.isCohort
        if(!this.isCohort) {
          this.activeCohortId = null
        }else {
          this.activeCohortId = this.cohorts && this.cohorts?.length > 0 ? this.cohorts[0].id : null;
        }
        this.filteredPathsGroups = this.getPathsFromCohortForWeb(this.activeCohortId);
        console.log('this.cohort', this.cohorts)
        
      },
      (error)=>{
        console.log("err",error)
        this.spinnerService.off()
      });
    } else {
      this.spinnerService.off()
      console.log('no cohort found')
    } 
  }

  selectActiveCohort(cohort){
    this.cohortId = cohort?.detail?.value
    this.enbleBtn =false;
    console.log('this.cohortId :', this.cohortId);
    this.getPathsFromCohort(this.cohortId)
  }
  selectActiveCohortWeb(cohort){
    this.cohortId = cohort
    this.enbleBtn =false;
    this.getPathsFromCohortForWeb(this.cohortId)
  }
getPathsFromCohortForWeb(cohortId) {
  const cohortPaths = [];
  this.filteredPathsGroups = [];

  // If cohortId is not provided or not found, return all path groups and paths
  if (!cohortId || !this.cohorts.find(cohort => cohort.id === cohortId)) {
      this.pathGroups.forEach(group => {
          const matchingPaths = group.paths.map(path => {
              return path;
          });
          if (matchingPaths.length > 0) {
              this.filteredPathsGroups.push({
                  id: group.id,
                  groupName: group.name,
                  paths: matchingPaths
              });
          }
      });
  } else {
      const cohort = this.cohorts.find(cohort => cohort.id === cohortId);
      if (cohort) {
          cohortPaths.push(...cohort.paths.map(path => path.id));
      } 
      this.pathGroups.forEach(group => {
          const matchingPaths = group.paths.filter(path => cohortPaths.includes(path.id));
          if (matchingPaths.length > 0) {
              this.filteredPathsGroups.push({
                  id: group.id,
                  groupName: group.name,
                  paths: matchingPaths
              });
          }
      });
  }
  // console.log('filtered path group', this.filteredPathsGroups);
  return this.filteredPathsGroups;
}
resendVerificationEmail() {
  // if (!this.verifyEmail) {
  //   console.log('inside ifff')
  //   return false;
  // }
  if(!this.verifyEmail && this.appState.get('verifyEmail')) {
    this.verifyEmail = this.appState.get('verifyEmail')
  }
  else if(!this.verifyEmail && !this.appState.get('verifyEmail')){
    let data = JSON.parse(this.appState.get('signupData'))
   if(data) {
    this.verifyEmail= data?.email;
   }
  }

  this.userService.resendVerificationEmail(this.verifyEmail).then(
    (response) => {
      if (response && response.success && response.message) {
        this.toasterService.success(this.i18n.t(
          "An email has been sent to this address."
        ));
      }
      return response.data;
    },
    (error) => {
      console.log("sendForgotPassword error", error);
      this.toasterService.error(this.i18n.t("Email not found"));
    }
  );

  return false;
}
  getPathsFromCohort(cohortId){
    this.cohorts.forEach(element => {
      if (element.id == cohortId) {
        this.pathList = element.paths
      }
    });
  }
  textExpand(val) {
    for(let j=0;j<this.collapse?.length;j++) {
      if(j==val){
         this.collapse[val].isCollapsed =false;
         this.collapse[val].isCollapsable =true; 
      }
      else {
        this.collapse[j].isCollapsed =true;
        this.collapse[j].isCollapsable =true; 
      }
    }
    // if(collpase){
    //   // this.render.removeClass(this.textCount.nativeElement, 'reply-texts');
    //   path.isCollapsed = false;
    //   path.isCollapsable = true;
    // }
    // else {
    //   // this.render.addClass(this.textCount.nativeElement, 'reply-texts');
    //   path.isCollapsed = true;
    //   path.isCollapsable = true;
    // }
    //this.cdRef.detectChanges();
  }

}
