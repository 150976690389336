import { ChangeDetectorRef, Component, DoCheck, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { AppState } from '../app.service';
import { FeedbackFormComponent } from '../components/commitments/feedback-form/feedback-form.component';
import { DailyStepsService } from '../daily-steps/daily-steps.service';
import { HomeV5Service } from '../pages/home-v5/home-v5.service';
import { RoutineV2Service } from '../routine-v2/routine-v2.service';
import { SpinnerService } from '../spinner.service';
import { ToasterService } from '../toaster.service';
import * as confetti from 'canvas-confetti';
import { MatDialog } from '@angular/material/dialog';
import { AlertAskRoutineQuestionComponent } from '../alert-ask-routine-question/alert-ask-routine-question.component';
import { AlertGiveFeedbackCommitmentComponent } from '../alert-give-feedback-commitment/alert-give-feedback-commitment.component';
import { AlertRepeatRoutineComponent } from '../alert-repeat-routine/alert-repeat-routine.component';
import { AlertEditLevelsComponent } from '../alert-edit-levels/alert-edit-levels.component';
import { FullTodoInfoComponent } from '../full-todo-info/full-todo-info.component';
import { DailyContentV2Component } from '../daily-content-v2/daily-content-v2.component';
import { ActionRescheduleComponent } from './action-reschedule/action-reschedule.component';
import { CordovaService } from '../cordova.service';
import { AlertUnlockLevelComponent } from '../alert-unlock-level/alert-unlock-level.component';
import { I18n } from '../i18n.service';
import { FullScreenVideoComponent } from '../full-screen-video/full-screen-video.component';
import { FullScreenDocumentComponent } from '../my-library/full-screen-document.component';
import { FullScreenAudioComponent } from '../full-screen-audio/full-screen-audio.component';
import { HomeUiService } from '../new-design/home-ui.service';
import { AnimationsService } from '../shared/animations.service';
import { DailyStepsContentModalComponent } from '../new-design/daily-steps-content-modal/daily-steps-content-modal.component';

@Component({
  selector: 'to-do-list',
  templateUrl: './to-do-list.component.html',
  styleUrls: ['./to-do-list.component.scss'],
})
export class ToDoListComponent implements OnInit, DoCheck {

  @Input() currentDay: number
  @Output() onLogaRep: any = new EventEmitter<any>();

  @Input() todoRoutines: any
  @Input() todoActions: any

  todoLevelsList: any
  todoActionList: any
  currentRoutine: any
  currentUser: any
  currentPath: any
  isonboardingcompleted: any;
  canvas: ElementRef
  makeHabit: boolean
  checked: boolean = false
  checkedHabit: boolean = false
  levelDescription: string
  levelId: number
  actionId: number
  isMobile;
  hideLevels: boolean = false
  selectedLevels =[]
  videoFormats = ['.mp4', '.mov', '.avi', '.avchd', '.f4v', '.mkv', '.wmv','youtube.com', 'webm', 'html5', 'HTML5', 'watch'];
  audioFormats = [".mp3"];
  constructor(public modalController: ModalController,
    private appState: AppState,
    private routineService: RoutineV2Service,
    private dailyStepsService: DailyStepsService,
    private homev5Service: HomeV5Service,
    private spinnerService: SpinnerService,
    private toasterService: ToasterService,
    private cdRef: ChangeDetectorRef,
    private route: Router,
    private dialog: MatDialog,
    private homeUIService: HomeUiService,
    private cordovaService: CordovaService,
    private alertController: AlertController,
    private animationService: AnimationsService,
    public i18n: I18n,
    private dailyStepsContent: DailyStepsContentModalComponent,
    private dailyContent: DailyContentV2Component) {
    this.currentUser = this.appState.get('currentUser')
    this.currentPath = this.appState.get('currentPath')
    this.getActions(this.currentUser.id, this.currentPath.id)
    this.isMobile= this.appState.get('isMobile');
   }

  ngOnInit() {
  }

  ngDoCheck(){
    this.getRoutinesByCurrentDay()

  }

  getRoutinesByCurrentDay(){
    let levels = []
    if (this.todoRoutines) {
      this.todoRoutines.forEach(element => {
        element.routine.forEach(element => {
          if (element.routine_description) {
            levels.push(element)
          }
          
        });
      });
      this.todoLevelsList = levels

    }
  }

  openDocument(post) {
    //let post=this.actionList
    let doc = this.appState.get('singleDocument');
    if(post?.id == doc?.id) {
      post =this.appState.get('singleDocument')
    }
    console.log('casual post :', post);
    // let post;
    // posts?.forEach((doc)=>{
    //   if(doc?.id ==id){
    //     post =doc
    //   }
    // })
    this.appState.set('singleDocument', post)
    this.appState.set('journalPost', undefined)
    if (post.isrecommended && post.isrecommended === true) {
      this.appState.set('isCasualDocument', true)
    }
    if(this.isMobile) {
      this.dialog.closeAll();
    }
    else {
     // this.modalController.dismiss();
    }
    this.appState.set('journalPathName', this.currentPath.userGroup.name)
    this.appState.set('journalStepNumber', post.startDay)
    const url = post.link;
    if (this.audioFormats.some(el => url.includes(el))) {
      console.log('Audio File');
      // this.route.navigate(['/audio-library'], { state: { data: post } })
      if(this.isMobile){
        this.homeUIService.openNextComponent(FullScreenAudioComponent, {data:post})
      }
      else {
        this.homeUIService.openNextComponent(FullScreenAudioComponent, {data:post},'desktop-library-custom')
      }
    } else if (this.videoFormats.some(el => url.includes(el))) {
      console.log('Video File');
      // this.route.navigate(['/video-library'], { state: { data: post } })
      if(this.isMobile){
        this.homeUIService.openNextComponent(FullScreenVideoComponent, {data:post})
      }
      else {
        this.homeUIService.openNextComponent(FullScreenVideoComponent, {data:post},'desktop-library-custom')
      }

    } else {
      console.log('Document');
      // this.route.navigate(['/detail-document'])
      if(this.isMobile){
        this.homeUIService.openNextComponent(FullScreenDocumentComponent)
      }
      else {
        this.homeUIService.openNextComponent(FullScreenDocumentComponent,'','desktop-library-custom')
      }

     
    }
  }
  async showFeedbackForm(subItem) {
    this.levelId = subItem.id
    this.currentRoutine = this.getRoutineByLevel(subItem)
    let repsIndex = subItem.reps_commit.findIndex((item) => item.is_completed === false);    if (repsIndex > -1) {
      this.checkedHabit = true
      const modal = await this.modalController.create({
        component: FeedbackFormComponent,
        componentProps: {
          mainItem: this.currentRoutine,
          subItem: subItem,
          index: repsIndex,
          fromToDoListScreen:true,
        },
        cssClass: this.isMobile?'mobile-custom-to_do_list-forms': 'web_feedbackForm_modal'
      });
      modal.onDidDismiss().then((modelData) => {
        this.checkedHabit = false

        if (modelData !== null && modelData.data !== undefined) {
          this.onLogaRep.emit(modelData.data);
          this.logaRep(this.currentRoutine,subItem,repsIndex)
        }
      });
      return await modal.present();
    } else {
      this.checkedHabit = false
      this.cdRef.detectChanges()
      console.log('Complted level')
    }
  }

  getRoutineByLevel(subItem){
    let currentRoutine
    if (this.todoRoutines) {
      this.todoRoutines.forEach(element => {
        if (subItem.routine_info_id === element.routine_info_id) {
          currentRoutine = element
        }
      });
      return currentRoutine
    }
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  getReps(item) {
    return item.reps_commit
  }

  logaRep(routine: any, level: any, index: any) {
    this.checkedHabit = false
    let repsCommitArray = routine.routine.filter(item => item.reps_commit)
    let repsArray = repsCommitArray.map(this.getReps)
    let totalRepsArray = Array.prototype.concat.apply([], repsArray);
    let totalLevelRepsArray = level.reps_commit.filter(item => item.is_completed === false)

    if (level.is_unlocked === false) {
      this.toasterService.warning(this.i18n.t('Please complete previous level.'))
    } else {
      this.spinnerService.on();

      const requestData = {
        'user_id': this.currentUser.id,
        'path_id': routine.path_id,
        'routine_info_id': routine.routine_info_id,
        'level_id': level.id,
        'schedule_day': this.currentDay,
        'feedback': localStorage.getItem('repLogText'),
        'step': index + 1
      }

      this.routineService.replog(requestData).subscribe(
        (response) => {
          let object = null
          this.spinnerService.off();
          // add reply to the local path routine_info
          if (response && response.data && response.data.routine_info_id) {
            object = this.appState.get('currentPath').routineInfo.find(q => q.id === response.data.routine_info_id)
          }

          this.toasterService.success(this.i18n.t('Rep Logged!'))
          this.getActions(this.currentUser.id, this.currentPath.id)
          this.cdRef.detectChanges()

          let indexOfLevel = this.appState.get('indexOfLevel')
          var isLastRepLogged = level.reps_commit.filter(item => item.is_completed === true)

          if (level.reps_commit.length == isLastRepLogged.length + 1 && indexOfLevel === routine.routine.length) {
            //this.checkLastRep(routine)
            if(this.isMobile) {
              this.dailyContent.close()
            }
            else {
              setTimeout(()=>{
                this.dailyStepsContent.close('isInstepTodoScreen')
              },600)
             // this.modalController.dismiss({isInstepTodoScreen:true});
            }
          }else if (totalLevelRepsArray.length - 1 === 0) {
            let selectedIndex = routine.routine.findIndex(item => item.id === level.id);
            this.appState.set('indexOfLevel', selectedIndex + 1)
            this.appState.set('levelUpdated', true)
            this.appState.set('showUpdatedScreens', true)
            this.appState.set('allLevelsCompleted', undefined)
            // this.getCurrentRoutine(routine,level)
            if (routine.path_id === this.currentPath.id) {
              var data = {
                levelUpdated: true,
                routine: routine,
                level: level
              }
              this.appState.set('levelUpdateData', data)
              this.appState.set('isTodoReps', true)
              this.appState.set('lastTodoScreenStep', this.currentDay)
              this.getCurrentRoutine(routine,level)
              if(this.isMobile) {
                this.dailyContent.close()
              }
              else {
                setTimeout(()=>{
                  this.dailyStepsContent.close('isInstepTodoScreen')
                },600)
                // this.modalController.dismiss({isInstepTodoScreen:true});
              }

            }else{
              setTimeout(()=>{
              this.changeSubPath(routine.path_id, routine.schedule_day)
              },600)
            }
          }else{
            console.log('inside else:', );
          }

        },
        (error) => {
          this.spinnerService.off()
          this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."));
          console.log('Error for feedback ', error)
        }
      )
    }
  }

  getActions(userId: number, pathId: number) {
    const requestData = {
      'user_id': userId,
      'path_id': pathId
    }
    this.dailyStepsService.getActionsList(requestData).subscribe((response) => {
      this.todoRoutines = this.homev5Service.removeDuplicates(response.data[0].routine, "routine_info_id");
      this.todoActions = response.data[0].action
    }, (error) => {
      console.log('error :', error);
    })
  }

  getTitle(title){
    return title.slice(15,-1)
  }

  private changeSubPath(pathId: number, routineDay: number) {

    const request = {
      'user_id': this.currentUser.id,
      'active_path_id': pathId
    }

    this.homev5Service.subpathchange(request).subscribe(
      (response) => {
        this.appState.set('isAllPaths', false)
        this.appState.set("screensViewed", undefined);
        this.appState.set('stepsArray', undefined)
        this.appState.set('stepObject', undefined)
        this.refresh()
        let data = response["data"];
        this.homeUIService.dismissAllModals().then(()=>{

        try {
          let isOnboardContentPresent = data.onboardingSurvey || data.isIntroVideo;
          if (data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted?.length > 0 && isOnboardContentPresent) {
            let value = data['isonboardingcompleted'][0];
            this.isonboardingcompleted = value["isonboardingcompleted"];
            if ((this.isonboardingcompleted === false || this.isonboardingcompleted === null) && isOnboardContentPresent) {
              this.route.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          
            } else if (this.isonboardingcompleted === true) {
              this.appState.set("allowScreenCount", undefined)
              if(this.isMobile) {
                this.route.navigate(['/content', routineDay], { queryParams: { startFromScreen: 'daily-content-screen-routine' } })
              }
              else {
              setTimeout(()=>{
                this.homeUIService.openDailyStepsComponent(DailyStepsContentModalComponent,{currentDay: routineDay,startFromScreen: 'daily-content-screen-routine'},'web-daily-steps-custom-modal')
              },1100);
              }
            }
          } else if(data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted.length===0 && isOnboardContentPresent){
              this.route.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          
          } else {
            console.log('remove', this.isonboardingcompleted)
            this.homeUIService.checkSubpathStatus();
            this.appState.set("allowScreenCount", undefined)
            if(this.isMobile) {
              this.route.navigate(['/content', routineDay], { queryParams: { startFromScreen: 'daily-content-screen-routine' } })
            }
            else {
              setTimeout(()=>{
                this.homeUIService.openDailyStepsComponent(DailyStepsContentModalComponent,{currentDay: routineDay,startFromScreen: 'daily-content-screen-routine'},'web-daily-steps-custom-modal')
              },1100);
            }
          }
        } catch (error) {
          this.route.navigate(['/main']);
        }
      }); 
        this.refresh()
      }, (error) => {
        console.log("Response of path changed ", error)
      }
    )
  }

  refresh() {
    return new Promise(resolve => {
      // here to fetch the data and rerender the contents.
      this.appState.refreshMainComponent.emit()
      setTimeout(resolve, 0)
    })
  }

  checkLastRep(routine?: any) {
    this.showAnimation()
    this.toasterService.success(this.i18n.t('Habit Complete!'))
    this.appState.set('LevelNumber', undefined)
    this.appState.set("allLevelsCompleted", true);
    setTimeout(async () => {
 
      const alert = await this.alertController.create({
         cssClass: 'custom-alert-action',
         message: this.i18n.t('Has this routine,')+" "+ routine?.routine_info?.title + " "+ this.i18n.t(", become a habit for you?"),
         
          buttons: [
            {
              text: this.i18n.t("No"),
              cssClass:'custom-alert-textcase',
              handler:()=>{
                this.makeHabit = false
                this.openAlertGiveFeedbackCommitmentComponent(routine);
              }
            },
            {
              text: this.i18n.t("Yes"),
              cssClass:'custom-alert',
              handler:()=>{
                this.makeHabit = true
                this.openAlertGiveFeedbackCommitmentComponent(routine);
              }
            }
           ],
        });
        await alert.present();
    }, 1500);

  }

  async openAlertGiveFeedbackCommitmentComponent(routine) {
    const modal= await this.modalController.create({
      component: AlertGiveFeedbackCommitmentComponent,
      cssClass: this.isMobile ? 'custom-alert-give-feedback-modal' : 'web_routine_feedback_modal',
      enterAnimation: this.animationService.slideInLeftEnterAnimation,
      leaveAnimation: this.animationService.SlideOutRightLeaveAnimation
    })
    this.homeUIService.setModalStack(modal);
    await modal.present();
   modal.onDidDismiss().then((res)=>{
     if(res){
      this.giveFeedback(routine)
     }
   })
  }

  showAnimation() {
    const myConfetti = confetti.create(this.canvas, {
      resize: true // will fit all screen sizes
    });
    myConfetti();

  }

  giveFeedback(routine: any) {
    var step_rating = localStorage.getItem("commitmentRating1")
    var step_comment = localStorage.getItem("commitmentFeedback")
    try {
      const requestData = {
        'user_id': this.currentUser.id,
        'path_id': this.currentPath.id,
        'step_score': step_rating,
        'step_comment': step_comment,
        'routine_info_id': routine.routine_info_id
      }
      this.spinnerService.on()
      if (requestData.step_comment == '' && requestData.step_score == '') {
        this.spinnerService.off();
      } else {
        this.routineService.routineFeedback(requestData).subscribe(
          async (response) => {
            console.log('respose', response)
            this.getActions(this.currentUser?.id, this.currentPath.id);
            this.cdRef.detectChanges()
            this.spinnerService.off();
            if (this.makeHabit === false) {
             
              const alert = await this.alertController.create({
               
                 cssClass: 'custom-alert-action',
               
                 message: this.i18n.t('Do you want to repeat all or part of this habit to continue building the habit of')+" "+routine?.routine_info?.title,
                 
                  buttons: [
                    {
                      text: this.i18n.t("No"),
                      cssClass:'custom-alert-textcase',
                      handler:()=>{
                       
                      }
                    },
                    {
                      text: this.i18n.t("Yes"),
                      cssClass:'custom-alert',
                      handler:()=>{
                        this.appState.set('levelEdit', true)
                        this.onSubmit(routine);
                      }
                    }
                   ],
                });
                await alert.present();
            }
          },
          (error) => {
            this.spinnerService.off()
            this.toasterService.error(this.i18n.t('Server or connection issue. Please check your internet connection.'))
          }
        )
      }

    } catch (o_O) {
      console.log(o_O);
    }

  }
  findLevelId(routine: any, checkedLevel: any) {
    let someArray = []
    let level_ids =[];
    routine.routine.forEach(element => {
      if (element.id != checkedLevel.id) {
        someArray.push(element)
        level_ids.push(element.id)
      }
    });
    this.appState.set('LevelNumber', someArray[0].level)
    this.appState.set('indexOfLevel', undefined)    
    this.appState.set('currentLevel', someArray[0])
    this.appState.set('levelUpdated', undefined)
    const repsRequestData= {  
      user_id:routine.user_id,
      is_reset:true,
      level_id:JSON.stringify(level_ids),
      routine_info_id:routine.routine_info_id
    }
   
    if(repsRequestData.user_id==0){
      return true;
    }else{
      this.routineService.appendRepsCount(repsRequestData).subscribe((res)=>{
        console.log("response for reps", res);
      })
      this.routineService.editRoutine(repsRequestData).subscribe(
        (response) => {
          this.appState.set('levelUpdated', undefined)
          this.cdRef.detectChanges();
        },
        (error) => {
          console.log('Error for edit routines', error)
        })
    }

  }

  onSubmit(routine) {
    this.appState.set('showUpdatedScreens', true)
      this.appState.set('LevelNumber', 1) 
      this.findLevelId(routine,1)
      this.appState.set('hidePrevScreen', true);
      this.appState.set("allowScreenCount", undefined)
      if(this.isMobile) {
        this.route.navigate(['/content', routine.schedule_day], { queryParams: { startFromScreen: 'daily-content-screen-routine-levels' } })
      }
      else {
        this.modalController.dismiss()
        this.homeUIService.openDailyStepsComponent(DailyStepsContentModalComponent,{currentDay: routine.schedule_day,startFromScreen: 'daily-content-screen-routine'},'web-daily-steps-custom-modal')
      }
  }

  async viewFullAction(action){
    const modal = await this.modalController.create({
      component: FullTodoInfoComponent,
      componentProps: {
        type: 'Action',
        action: action
      },
      cssClass: this.isMobile?'':'web-action-calender-modal'
    });
    modal.onDidDismiss().then((modelData) => {
    });
    return await modal.present();
  }

  async viewFullHabit(level){
    this.currentRoutine = this.getRoutineByLevel(level)
    const modal = await this.modalController.create({
      component: FullTodoInfoComponent,
      componentProps: {
        type: 'Habit',
        level: level,
        currentRoutine: this.currentRoutine
      },
      cssClass: this.isMobile?'':'web-action-calender-modal'
    });
    modal.onDidDismiss().then((modelData) => {
    });
    return await modal.present();
  }

  async openModal(action){
    this.actionId = action.id
    this.checked = true
    const modal = await this.modalController.create({
      component: ActionRescheduleComponent,
      componentProps: {
        action: action,
        currentDay: action.startDay
      },
      cssClass: this.isMobile?'':'web-action-calender-modal'
    });
    modal.onDidDismiss().then((modelData) => {
      this.checked = false
      this.getActions(this.currentUser.id, this.currentPath.id)
    });
    return await modal.present();
  }

  @HostListener('click', ['$event'])
  onLinkClick(e) {
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        window.open(e.target.href, '_blank')
      }
    }
  }

  getCurrentRoutine(routine, level) {
    let unCheckedLevels = [];

    this.currentPath.routineInfo.forEach(currentRoutine => {
      if (currentRoutine.id === routine.routine_info_id) {
        currentRoutine.levels.forEach(routineLevel => {
          routine.routine.forEach(myLevel => {
            const match =routine.routine.filter((level)=>level.id === routineLevel.id)
            if (myLevel.id !== routineLevel.id && (match.length===0)) {
              unCheckedLevels.push({ 'id': routineLevel.id, 'index': currentRoutine.levels.indexOf(routineLevel) })
            }
          });
        });
        unCheckedLevels = [...new Set(unCheckedLevels)];
      }

      // function getUnique(arr, comp) {
      //   const unique = arr.map(e => e[comp])
      //     .map((e, i, final) => final.indexOf(e) !== i && i)
      //     .filter((e) => arr[e]).map(e => arr[e]);

      //   return unique;
      // }
      this.appState.set('unCheckedLevels', [...new Set(unCheckedLevels)])
      let unselectedIndex = routine.routine.findIndex(item => item.id === level.id);
      this.appState.set('indexOfLevel', unselectedIndex + 1)
      this.appState.set('levelUpdated', true)
    });

    routine.routine.forEach(element => {
      this.selectedLevels.push({ 'id': element.id, 'index': routine.routine.indexOf(element) })
    });
    this.selectedLevels=[...new Set(this.selectedLevels)]
    this.appState.set('selectedLevelIds', this.selectedLevels)
  }

}
