import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import mixpanel from 'mixpanel-browser';
import { AppState } from '../app.service';

@Component({
  selector: 'app-alert-ask-routine-question',
  templateUrl: './alert-ask-routine-question.component.html',
  styleUrls: ['./alert-ask-routine-question.component.scss']
})
export class AlertAskRoutineQuestionComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AlertAskRoutineQuestionComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private appState: AppState) { }

  ngOnInit() {
  }
  confirmHabit() {
    mixpanel.track("Commitment-Habit Confirmation", {value: 'Yes'})

  }
  cancelHabitConfirmation() {
    mixpanel.track("Commitment-Habit Confirmation", {value: 'No'})
  }

}
