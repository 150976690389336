import { Base } from "../shared/base"

import { Checklist } from '../checklist/checklist.model'

export class PathChecklist extends Base {

  init() {
    this._fields = ['id', 'pathId', 'enabled', 'completed', 'checklist']

    this._nestedFields = ['checklist']
  }

  public id: number
  public pathId: number
  public enabled: boolean
  public completed: boolean
  public checklist: Checklist
}
