import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Keyboard } from "@awesome-cordova-plugins/keyboard/ngx";

@Component({
  selector: 'app-alert-add-notes',
  templateUrl: './alert-add-notes.component.html',
  styleUrls: ['./alert-add-notes.component.scss'],
})
export class AlertAddNotesComponent implements OnInit {

  postText:any = ''
  // keyboard: any;

  constructor(
    public dialogRef: MatDialogRef<AlertAddNotesComponent>, 
    private keyboard: Keyboard
  ) { }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.resizeHeight();
    this.restoreResizeHeight();
  }

  resizeHeight(){
    this.keyboard.onKeyboardDidShow().subscribe((info) => {
         const mobileLoginScreens = document.querySelector('.cdk-overlay-pane');
            mobileLoginScreens.setAttribute('style', `margin-top: -200px`);
          console.log('keyboard show');
       });
  }
  restoreResizeHeight(){
    this.keyboard.onKeyboardDidHide().subscribe(() => {
      const mobileLoginScreens = document.querySelector('.cdk-overlay-pane');
        mobileLoginScreens.setAttribute('style', `margin-top: auto`);
      console.log('keyboard hide');
    })
  }

  onSubmit(){
    localStorage.setItem('journalNote',this.postText);
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
