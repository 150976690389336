import { Component, Input, OnInit } from "@angular/core";
import { AppState } from "src/app/app.service";
import { Client } from "src/app/client/client.model";
import { Path } from "src/app/path/path.model";
import { SpinnerService } from "src/app/spinner.service";
import { StatService } from "src/app/stat/stat.service";
import { User } from "src/app/user/user.model";
import * as introJs from 'intro.js/intro.js';
import mixpanel from 'mixpanel-browser';
@Component({
    selector: 'left-side-bar',
    templateUrl: './left-side-bar.component.html',
    styleUrls: ['./left-side-bar.component.scss'],
})
export class LeftSideBarComponent implements OnInit {

    client: Client
    path: Path
    currentUser: User

    stats: object
    thisPathUser: any
    thisPathUserRank: any
    routineVersion: boolean
    enabledAttributes: any

    constructor(
        private appState: AppState,
        private statService: StatService,
        private spinnerService: SpinnerService,
    ){
        this.client = this.appState.get('currentClient')
        this.path = this.appState.get('currentPath')
        this.currentUser = this.appState.get('currentUser')
        this.getStats(this.path.id)
        this.enabledAttributes = this.appState.get("routineVersion")
        if (this.enabledAttributes.routineVersion) {
            this.routineVersion = this.enabledAttributes.routineVersion
        } else {
            this.routineVersion = false
        }
    }

    ngOnInit(){}
    ngAfterViewInit() {
        // introJs(document.querySelector("left-side-bar")).setOptions({
        //   'nextLabel': 'Next step',
        //   'prevLabel': 'Previous step',
        //   'skipLabel': 'Don\'t bother me!',
        //   'doneLabel': 'Finish'
        // }).start(); 
      }
    getStats(pathId){
        this.statService.getList(pathId)
        .then(response => {
            this.stats = response
            this.getCurrentUser(this.stats)
        })
        .catch(error => {
            this.stats = error
        })
    
      }

    getCurrentUser(stats: any) {
        var current_path_user = []
        current_path_user = stats.leaderboard.current_path_user

        try {
            this.thisPathUserRank = current_path_user.findIndex(element => element.isMe)
            let thisUser = current_path_user.filter(element => element.isMe)
            this.thisPathUser = thisUser[0]
        } catch (error) {
            console.log('error catch', error);

        }
    }
    trackMenuClick(value: string, event: any) {
        mixpanel.track('Home-Menu Click', { 'pageName':value, 'url': event.target.href });
      }
}