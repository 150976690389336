import gql from 'graphql-tag'

export const reflectionFields = gql`
fragment reflectionFields on Reflection {
  id
  startDay
  title
  endDay
  actionPoints
  preScreens
  postScreens
  prompt
  isMandatory
  isDeleted
  enabled
  text
  imageUrl
  screenOrder
}
`