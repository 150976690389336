import { Component, EventEmitter,  OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppState } from '../../app.service';
import { I18n } from '../../i18n.service';
import { Habit } from '../../habit/habit.model';
import { Document } from '../../document/document.model';
import { Path } from '../../path/path.model';
import { StatService } from '../../stat/stat.service';
import { UtilityService } from '../../common-services/utility.service';
import { Router } from '@angular/router';
import { slideOutRightAnimation } from 'angular-animations';
import { HomeUiService } from '../home-ui.service';
import { ModalController, Platform } from '@ionic/angular';
import * as moment from 'moment';
import { SessionService } from 'src/app/session/session.service';
import { HomeV5Service } from 'src/app/pages/home-v5/home-v5.service';

@Component({
  selector: 'your-progress',
  templateUrl: './your-progress.component.html',
  styleUrls: ['./your-progress.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class YourProgressComponent implements OnInit {

  isMobile: boolean
  percentage: number
  path: Path
  thisPathUserRank: any
  thisPathUser: any
  stats: object
  options: [
    {
      label: 'Sliding Entrances',
      animations: ['slideOutRight']
    },
  ]
  animationState = false;
  @Output() animationStateChange = new EventEmitter()
  pathRegistration: any
  highlightedDates = []
  currentDate: any
  learningMinutes: any
  scheduleDay: number
  currentCompletedDay: number

  constructor(private appState: AppState,
    private i18n: I18n,
    private statService: StatService,
    private utilService: UtilityService,
    private modalController: ModalController,
    public platform: Platform,
    private sessionService: SessionService,
    private homeUIService: HomeUiService,
    private homev5Service: HomeV5Service,) {
    this.path = this.appState.get('currentPath')
    this.isMobile = this.appState.get('isMobile')
    this.getStatsList(this.path.id)
    this.pathRegistration = this.appState.get('pathRegistration')
    this.scheduleDay = this.appState.get("scheduleDayForCurrentStep")
  }

  ngOnInit() {
    this.currentDate = moment().format("YYYY-MM-DD");
    let dates = []
    if(this.appState.get('loggedInDates')){
      dates = this.appState.get('loggedInDates')
    }else{
      dates = this.pathRegistration.loggedInDates
    }
    for (let i = 0; i < dates.length; i++) {
      this.highlightedDates.push({
        date: dates[i],
        textColor: '#fff',
        backgroundColor: 'var(--theme-color)',
      });
    }
    this.currentCompletedDay = parseInt(localStorage.getItem('stepCompleted'))
    this.getLearningMinute()
  }

   ngAfterViewInit(){}

   getLearningMinute() {
    if (this.currentCompletedDay) {
      this.learningMinutes = this.sessionService.calculateLearningMinutes(this.path.inoutScreens, this.currentCompletedDay)
    }else{
      if(this.scheduleDay>1) this.learningMinutes = this.sessionService.calculateLearningMinutes(this.path.inoutScreens, 1)
    }
  }

  returnPathPercentage(){
    try {
      const totalCount = this.path.daysOfContent
      let screensViewed = this.appState.get('screensViewed')
      if (screensViewed) {
        let stepsArray = this.homev5Service.removeDuplicates(screensViewed, "step")
        let completedSteps = []
        stepsArray.forEach(element => {
          if (element.visitedScreens === element.totalScreens) {
            completedSteps.push(element)
          }
        });
        const completedTaskCount = completedSteps.length
        this.percentage = (completedTaskCount / totalCount) * 100
      } else {
        let stepsArray = this.homev5Service.removeDuplicates(this.pathRegistration.screensViewed, "step")
        let completedSteps = []
        stepsArray.forEach(element => {
          if (element.visitedScreens === element.totalScreens) {
            completedSteps.push(element)
          }
        });
        const completedTaskCount = completedSteps.length
        this.percentage = (completedTaskCount / totalCount) * 100
      }
      return Math.ceil(this.percentage)
    } catch (error) { }
  }

  getStatsList(pathId) {
    this.statService.getList(pathId)
      .then(response => {
        this.stats = response
        this.thisPathUserRank = this.utilService.getCurrentUserRank(this.stats)
        this.thisPathUser = this.utilService.getCurrentPathUser(this.stats)
      })
      .catch(error => {
        this.stats = error
      })
  }

  closePage() {
    this.homeUIService.getLastModal();
    this.modalController.dismiss();
  }

  getCircumference(): number {
    return 2 * Math.PI * 45;
  }

  getStrokeOffset(): number {
    const circumference = this.getCircumference();
    return circumference - (circumference * this.percentage) / 100;
  }
}
