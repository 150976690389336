import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { AlertHabitLoopInfoComponent } from '../alert-habit-loop-info/alert-habit-loop-info.component';
import { CordovaService } from '../cordova.service';
import { I18n } from '../i18n.service';
import { HomeUiService } from '../new-design/home-ui.service';
import { RoutineInfo } from './routine-info.model';
import { RoutineLevel } from './routine-level.model';
import { AppState } from '../app.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'habit-loop',
  templateUrl: './habit-loop.component.html',
  styleUrls: ['./habit-loop.component.scss']
})
export class HabitLoopComponent implements OnInit {

  @Input() inverted = false
  loopComponent: any
  info: any
  isMobile;
  constructor(private cordovaService: CordovaService, 
    private dialog: MatDialog,
    public homeUIService: HomeUiService,
    private appState: AppState,
    private ModalControlller: ModalController,
    private i18n: I18n) { }

  ngOnInit() {
    this.isMobile = this.appState.get('isMobile');
  }

  showCueInfo(){
    this.loopComponent = this.i18n.t('Cue')
    this.info = this.i18n.t('The cue is anything that triggers the habit, most commonly: a time of day, location, other people, an emotional state, or an immediately preceding action.') 
    this.openModal(this.loopComponent,this.info)
  }

  showRoutineInfo(){
    this.loopComponent = this.i18n.t('Routine')
    this.info = this.i18n.t('The routine is the behavior you will repeat every time you experience the cue. Once you no longer need to pay attention to the cue to complete the routine, you have built a habit.')
    this.openModal(this.loopComponent,this.info)
  }

  showRewardInfo(){
    this.loopComponent = this.i18n.t('Reward')
    this.info = this.i18n.t('The reward is the positive reinforcement you experience after completing the routine, and it’s the reason your brain decides the behavior is worth remembering.')
    this.openModal(this.loopComponent,this.info)
  }

  async openModal(loopComponent, info) {
    if (this.isMobile) {
      const dialogRef = this.dialog.open(AlertHabitLoopInfoComponent, {
       data: {
        dataKey: { loopName: loopComponent, infoData: info }
      }
     })
      dialogRef.afterClosed().pipe(filter(data => data)).subscribe((data) => {
      })
    }
    else {
      const modalRef = await this.ModalControlller.create({
        component: AlertHabitLoopInfoComponent,
        componentProps: { loopInfo: {loopName: loopComponent, infoData: info } },
        cssClass:'web-routine-loop-modal'
      })
      await modalRef.present();
    }
  }

}
