import { Component, OnInit } from '@angular/core';
import { HomeUiService } from '../new-design/home-ui.service';

@Component({
  selector: 'app-alert-repeat-event',
  templateUrl: './alert-repeat-event.component.html',
  styleUrls: ['./alert-repeat-event.component.scss']
})
export class AlertRepeatEventComponent implements OnInit {

  constructor(
    public homeUIService: HomeUiService,

  ) { }

  ngOnInit() {
  }

}
