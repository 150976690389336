import { ChangeDetectorRef, Component, DoCheck, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActionSheetController, ModalController, AnimationController, AlertController, AlertInput, Platform, IonRow  } from '@ionic/angular';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { AlertProfilePictureComponent } from '../alert-profile-picture/alert-profile-picture.component';
import { AppState } from '../app.service';
import { Badge } from '../badge/badge.model';
import { BadgeService } from '../badge/badge.service';
import { CustomBadge } from '../badge/custom-badge.model';
import { CustomBadgeService } from '../badge/custom-badge.service';
import { ChangeSubpathAlertComponent } from '../change-subpath-alert/change-subpath-alert.component';
import { Client } from '../client/client.model';
import { CordovaService } from '../cordova.service';
import { I18n } from '../i18n.service';
import { Path } from '../path/path.model';
import { SessionService } from '../session/session.service';
import { Filter } from '../shared/filter';
import { SpinnerService } from '../spinner.service';
import { ToasterService } from '../toaster.service';
import { User } from './user.model';
import { UserService } from './user.service';
import { EditProfileComponent } from '../components/edit-profile/edit-profile.component';
import mixpanel from 'mixpanel-browser';
import { HomeUiService } from '../new-design/home-ui.service';
import { AchievementsPageComponent } from '../pages/achievement/achievements-page.component';
import { FaqComponent } from '../faq/faq.component';
import { NotificationsSettingsComponent } from '../pages/notifications-settings/notifications-settings.component';
import { DeletionPageComponent } from '../deletion-page/deletion-page.component';
import { WebFaqComponent } from '../web-faq/web-faq.component';


const DEFAULT_URL = environment.apiBaseUrl + '/files'
const DEFAULT_MAX_FILE_SIZE = 10 * 1024 * 1024 // 10 MB - Accept raw images from the phone's camera
const DEFAULT_ALLOWED_MIME_TYPES = null // Workaround: Android issue with comparing types

class UploadConfig {
  url?: string = DEFAULT_URL
  maxFileSize?: number = DEFAULT_MAX_FILE_SIZE
  allowedMimeTypes?: string[] = DEFAULT_ALLOWED_MIME_TYPES
}

interface SignInForm {
  email: string;
  password: string;
  pathGroupId?: number;
  pathId?: number; // LUMBA-1402
  changePath?: boolean; // LUMBA-982
  fromMobile?: boolean; // LUMBA-982
}

export interface UpdateUser{
  [updateUser: string]: any
}
@Component({
  selector: 'first90-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, DoCheck {

  @ViewChild('input1') inputEl:ElementRef;

  NotificationComp= NotificationsSettingsComponent;
  user: User
  path: Path
  client: Client
  tz: string
  nameS: string
  environment: any
  onCordova = false
  showPathSelect: boolean = false // LUMBA-982
  isShowed: boolean = false
  videoPathUrl:any = ""
  responseData: any
  photoUrl: any
  dataRefresher: any
  faqComp = FaqComponent;
  achiementsComp =AchievementsPageComponent;
  isNewPhoto: boolean | true
  isPhotoUploaded: boolean;
  label: any;

  enabled = true
  userBadges: any = {}
  completions: any = {}
  badges: {[badgeStringId: string]: Badge} = {}
  customBadges: CustomBadge[]
  userCustomBadges: string[]
  stepBadges: any
  isBadgeOpened: boolean = false

  private _subscription: any
  isMobile: boolean;
  userUpdate: UpdateUser
  badgeDetails = {
    small_steps: { userBadges: null, pathBadge: null },
    question_answers: { userBadges: null, pathBadge: null },
    poll_answers: { userBadges: null, pathBadge: null },
    document_views: { userBadges: null, pathBadge: null },
    small_steps_perfect_weeks: { userBadges: null, pathBadge: null },
  }

  state:boolean = false;
  animation=['fadeInLeft'];
  isSingleBadgeAbsent: boolean 
  isCustomBadgeAbsent: boolean
  signInForm: SignInForm = {
    email: "drax@aventior.com", //henry@spacex.com
    password: "password", //password
    pathGroupId: null, // LUMBA-982
    pathId: null, // LUMBA-1402
    changePath: null,
    fromMobile: null,
  };
  loading = false;
  valid = true;
  error: string;
  animationState;
  currentRank: any
  currentActionPoints: any
  pathPercentage: any
  daysVisited: number
  learningMinutes: any
  currentDate: any
  highlightedDates = []
  statsForActivities: any
  pathRegistration: any
  showDeleteModal: boolean = false
  showConfirmDelete: boolean = false
  showDeletedMsg: boolean = false
  @ViewChild('rowElement', { static: false }) rowElement: HTMLIonRowElement;
  allBadges: any

  constructor(
    private i18n: I18n,
    private router: Router,
    private userService: UserService,
    private sessionService: SessionService,
    private appState: AppState,
    private toastrService: ToasterService,
    public alertController: AlertController,
    private spinnerService: SpinnerService,
    private cordovaService: CordovaService,
    private dialog: MatDialog,
    private cdRef : ChangeDetectorRef,
    public platform: Platform,
    private badgeService: BadgeService,
    private animationCtrl: AnimationController,
    private homeUiService: HomeUiService,
    private customBadgeService: CustomBadgeService, // LUMBA-1289
    public actionSheetController: ActionSheetController,
    public modalController: ModalController,
  ) {
    this.path = this.appState.get('currentPath')
    this.user = this.appState.get('currentUser')
    this.client = this.appState.get('currentClient')
    this.currentRank = this.appState.get('currentRank')
    this.currentActionPoints = this.appState.get('currentActionPoints')
    this.pathPercentage = this.appState.get('pathPercentage')
    this.daysVisited = this.appState.get('daysVisited')
    this.learningMinutes = this.appState.get('learningMinutes')
    this.currentDate = moment().format("YYYY-MM-DD");
    this.pathRegistration = this.appState.get('pathRegistration')
    this.statsForActivities = this.appState.get('statsForActivities').activities
    this.initNames()
    this.tz = moment.tz.guess()
    this.environment = environment
    this.onCordova = this.cordovaService.onCordova

    this.path.badges.forEach(badge => {
      if (badge.enabled) {
        this.badges[badge.stringId] = badge
      }else{
        this.isSingleBadgeAbsent = true
      }
    })

    if (this.path.enabledAttributes['custom_badges']) {
      this.getBadgeList(this.user.id,this.path.id)
    }
    this.getHighlightedDates()
   }

   ngOnDestroy() {
    this.stopInterval()
  }

  ngDoCheck(){
    let data = this.appState.get('userData')
    if (data) {
      this.user.firstName = data.firstName
      this.user.lastName = data.lastName
      this.user.email = data.userEmail
      this.appState.set('userData', undefined)
    }
    this.currentRank = this.appState.get('currentRank')
    this.currentActionPoints = this.appState.get('currentActionPoints')
    this.pathPercentage = this.appState.get('pathPercentage')
    this.daysVisited = this.appState.get('daysVisited')
    this.learningMinutes = this.appState.get('learningMinutes')
    this.statsForActivities = this.appState.get('statsForActivities').activities
  }

  async close(){
    this.showDeleteModal = false
    await this.modalController.dismiss();
  }
  
  ngOnInit() {

    this.path = this.appState.get('currentPath')
    this.user = this.appState.get('currentUser')
    this.client = this.appState.get('currentClient')
    this.isMobile = this.appState.get('isMobile')
    this.initNames()
    this.tz = moment.tz.guess()
    this.environment = environment
    this.onCordova = this.cordovaService.onCordova

    this.path.badges.forEach(badge => {
      if (badge.enabled) {
        this.badges[badge.stringId] = badge
      }else{
        this.isSingleBadgeAbsent = true
        this.cdRef.detectChanges()
      }
    })
    
  }

  getBadgeDetails(key) {
    if (Object.keys(this.userBadges).includes(key)) {
      this.badgeDetails[key] = {
        userBadges: this.userBadges[key].map(ub => +ub.step),
        pathBadge: this.badges[key]
      }
    }
  }

  public getOrdinal(n) {
    let s=["th","st","nd","rd"]
    let v=n%100
    return n+(s[(v-20)%10]||s[v]||s[0])
  }
  
  private getFilter() {
    const filter = new Filter()
    filter.q = { user_id_eq: this.user.id}
    return filter
  }
  

  inputFocus(){
    this.isShowed=true
  }

  getBadgeList(userId, pathId) {
    const requestData = {
      'user_id': userId,
      'path_id': pathId
    }
    if(requestData){
      this.badgeService.getListUserBadge(requestData).subscribe(userBadges => {
        this.allBadges = userBadges
        this.customBadges = userBadges.custom_badges
        this.userBadges.custom_badges = {}

        // userBadges.path_badges.forEach(badge => {
        //   if (badge.enabled) {
        //     this.badges[badge.stringId] = badge
        //   }else{
        //     this.isSingleBadgeAbsent = true
        //   }
        // })
  
        if (userBadges.list_user_badge) {
          for (const key in this.badges) {
            const badge = this.badges[key]
            this.userBadges[badge.stringId] = []
            this.userBadges[badge.stringId] = userBadges.list_user_badge.filter(el => el.badgeId === badge.badgeId)
          }
        }
        this.getBadgeDetails('small_steps');
        this.getBadgeDetails('question_answers');
        this.getBadgeDetails('poll_answers');
        this.getBadgeDetails('document_views');
        this.getBadgeDetails('small_steps_perfect_weeks');

        if (userBadges.list_user_custombadge) {
          this.userCustomBadges = userBadges.list_user_custombadge.filter(el => el.enabled).map(badge => badge.badgeTitle)   
        }else{
          this.isCustomBadgeAbsent = true
          this.cdRef.detectChanges() 
        }
        this.cdRef.detectChanges()
      },
      (error) => {
        console.log('Record Not Found' , error)
        this.toastrService.error(this.i18n.t("Server or connection issue. Please check your internet connection."));
      })
    }

  }

  toggle(){
    this.isShowed = !this.isShowed;
    this.inputFocus();
  }

  toggleEdit(){
    if(this.isShowed){
      this.isShowed=false;
    }else{
      this.isShowed=true
    }
  }

  async showEditProfile(){
   if (this.isMobile) {
    this.openComponent(EditProfileComponent)
   } else {
    this.homeUiService.openNextComponent(EditProfileComponent,'','desktop-profile-custom')
   }
  }

  onSubmit () {

    if(!this.sessionService.validatePassword(this.user.password)){
      this.toastrService.warning( this.i18n.t('Password must contain at least 7 characters including at least 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number') )
      return
    }

    if (this.user.password == this.user.passwordConfirmation) {
       delete this.user.userBgColor;
      this.userService.save( this.user ).subscribe( response => {
        this.userUpdate = response.data
        const updatedUser = new User( this.userUpdate.updateUser )
        this.user = updatedUser
        this.label=''
        this.appState.set( 'currentUser', updatedUser )
        this.spinnerService.off()
        this.isPhotoUploaded=true
        this.toastrService.success( this.i18n.t('Profile updated!') )
      } )

    }else{
      this.toastrService.warning( this.i18n.t('Password is not matched') )
      this.spinnerService.off()
    }

    this.stopInterval()

  }

  change_subpath(){
    const dialogRef = this.dialog.open(ChangeSubpathAlertComponent)
    dialogRef.afterClosed().subscribe((result) => {
    })
   //this.openComponent(ChangeSubpathAlertComponent)
  }

  logout () {
    this.homeUiService.dismissAllModals().then(()=>{
    const client = this.appState.get('currentClient')
    const path = this.appState.get('currentPath')  // LUMBA-1410


      if (path.pathGroup.allowMultipleLogin) {
      setTimeout(()=>{
        this.sessionService.destroy()
        this.router.navigate([''])

      },550);

      } else {
        // mixpanel.track('session-end', { 'duration': mixpanel.time_event('Session End')});
        this.appState.set('routedPath', path.pathGroup.name)
        setTimeout(()=>{
          this.sessionService.destroy()
          this.router.navigate([`login`])
        },550);
      }
   
  
    this.toastrService.success(this.i18n.t('Logged out!'))
    const requestData = {
      'user_id': this.user.id,
      'path_id': this.path.id,
      'enabled': false,
    }
    mixpanel.track("Logout-session", {PathId: requestData.path_id, UserId: requestData.user_id})
    this.userService.logOut(requestData).subscribe(
      (response) => {
        // mixpanel.track('session-end', { 'duration': mixpanel.time_event('Session End')});
        console.log('response of logout', response);
      },
      (error) => {
        console.log('error of logout', error);
      }
    );
  });
  }

  private initNames () {
    this.nameS = this.path.getCustomText( 'st_s' )
  }

  chooseOption() {
    this.stopInterval()
    let dialogRef = this.dialog.open(AlertProfilePictureComponent)
      dialogRef.afterClosed().subscribe((result) => {
        console.log('session url', this.sessionService.url);
        console.log('session url result', result);
        this.isNewPhoto=true
        this.label='Loading...'
        this.refresh() 
    })
  }

  refresh() {
    this.dataRefresher =
    setInterval(() => {
      this.user.imageUrl = this.sessionService.url 
      delete this.user.userBgColor;
        if(this.isNewPhoto && this.user.imageUrl){
          this.userService.save(this.user).subscribe((response) => {
            this.userUpdate = response.data
            const updatedUser = new User(this.userUpdate.updateUser);
            this.user = updatedUser;
            this.label = "";
            this.appState.set("currentUser", updatedUser);
            localStorage.setItem('userid', updatedUser.id.toString());
            this.spinnerService.off();
            this.isPhotoUploaded = true;
            this.toastrService.success(this.i18n.t("Profile Picture Added!"));
            console.log("update response", response);
          });
          this.toastrService.success( this.i18n.t('Please click on Update to save changes.') )
          this.user.imageUrl=null;
          this.isNewPhoto=false
          this.label=''
        } 
    }, 500);
  }

  refreshScreen() {
    this.appState.refreshMainComponent.emit();
  }

  onShowTutorialBtnClick (url:any=null) {

    this.videoPathUrl = this.path.names["videopath"]
    // mixpanel.track('Menu Click', {'PageName': 'Watch tutorial video', 'video url': this.url_1st90})
    if(!this.videoPathUrl){
      this.toastrService.error( this.i18n.t('Video tutorial not found') )
      return;
    }
    if (this.cordovaService.onCordova) {
      this.cordovaService.openBrowserLinkVideo(this.videoPathUrl)
    } else {
      window.open(this.videoPathUrl, '_blank')
    }
  }
  
  stopInterval(){
    if(this.dataRefresher){
      clearInterval(this.dataRefresher)
    }
  }

  goPrevScreen(){
    window.history.back()
  }

  async openDeleteUserModal(){

    const alert = await this.alertController.create({
      
       header: this.i18n.t('Delete Account'),
       subHeader: this.i18n.t('Are you sure you want to delete your account?'),
       buttons: [
         {
           text: this.i18n.t("Cancel"),
           handler:()=>{
         
           },
           role: 'cancel',
         },
         {
           text: this.i18n.t("Confirm"),
           cssClass: 'custom-alert',
           handler: async () => {
            const alert = await this.alertController.create({
               header: this.i18n.t('Delete Account'),
               cssClass: 'del-custom-alert',
               message: this.user?.email ,
                inputs: [
                 
                  {
                    name:'password',
                    type:'text',
                    placeholder: this.i18n.t('Enter Password'),
                  },
                 
                ],
                buttons: [
                  {
                    text: this.i18n.t("Submit"),
                    cssClass: 'delete-custom-alert', 
                      handler: (alertData: AlertInput) => {
                        this.appState.set('password', alertData['password'])
                        this.validateUser();
                      }
                  },
                 ],
              });
              await alert.present();
           },
         },
       ]  
      });
   
     await alert.present();
  }

  openComponent(comp) {
    this.homeUiService.openNextComponent(comp)
  }

  validateUser(){
    let password = this.appState.get('password')
    // this.spinnerService.on();
    this.signInForm.email = this.user.email
    this.signInForm.password = password
    this.sessionService.validateSession(this.signInForm).subscribe(
      (result) => {     
          console.log('result :', result);
          // this.spinnerService.off();
          this.wipe()
          if (this.isMobile)  {
            this.homeUiService.openNextComponent(DeletionPageComponent)            
          }else{
            this.showConfirmDelete = false
            this.showDeletedMsg = true
          }
      },
      (errorResponse) => {
        console.log('errorResponse :', errorResponse);
        // this.spinnerService.off();
        this.valid = false;
        this.loading = false;
        let messages;
        if (
          typeof errorResponse.graphQLErrors !== "undefined" &&
          errorResponse.graphQLErrors.length > 0
        ) {
          messages = errorResponse.graphQLErrors.map((e) => e.message);
        } else {
          messages = [
            "Server or connection issue. Please check your internet connection.",
          ];
        }
        this.error = messages.join("<br>");
        this.toastrService.error(this.i18n.t(this.error));
      }
    );
  }

  wipe() {
    this.spinnerService.on()
    this.userService.deleteUser(this.user)
      .subscribe(
        response => {
          this.spinnerService.off()
          this.toastrService.success(this.i18n.t('User will be deleted.'))
        },
        error => {},
        () => { this.spinnerService.off() }
      )
    }
    trackMenuClick(value: string, event: any,comp?) {
      if(comp){
        this.openComponent(comp);
      }
      mixpanel.track('Home-Menu Click', { 'pageName':value, 'url': event.target.href });
    }

  getHighlightedDates() {
    let dates = []
    if (this.appState.get('loggedInDates')) {
      dates = this.appState.get('loggedInDates')
    } else {
      dates = this.pathRegistration.loggedInDates
    }
    for (let i = 0; i < dates.length; i++) {
      this.highlightedDates.push({
        date: dates[i],
        textColor: '#fff',
        backgroundColor: 'var(--theme-color)',
      });
    }
  }

  openFaq(){
    this.homeUiService.openNextComponent(WebFaqComponent,'','desktop-profile-custom')
  }

  openNotificationSettings(){
    this.homeUiService.openNextComponent(NotificationsSettingsComponent,'','desktop-profile-custom')
  }

  deleteAccount(){
    this.appState.set('password', this.user.password)
    this.validateUser();
  }

  openBadges(event){
    this.homeUiService.openNextComponent(AchievementsPageComponent,'','desktop-profile-custom')
  }

}
