import { Base } from '../shared/base'

export class ContentSurveyOption extends Base {
  init() {
    this._fields = ['id', 'text','isCorrect']
  }

  public id: number
  public text: string
  public isCorrect: boolean
  public isSelected: boolean
}
