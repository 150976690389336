import { Base } from "../shared/base"
import { Schedulable } from "../shared/schedulable"
import { TextScreen } from '../text-screen/text-screen.model'

// reflect first90-backend-ruby/app/models/document.rb enum section
// FIXME: maybe convert to string enum Higlighted = "highlighted"
export enum DocumentSection {
  Highlighted,
  Practice,
  Resource,
  More
}

export class Document extends Base implements Schedulable {
  init() {
    this._fields = ['id', 'title', 'link', 'imageUrl', 'section', 'isViewed',
    'startDay', 'endDay', 'actionPoints', 'isMandatory', 'isCompleted',
    'preScreens', 'postScreens', 'enabled', 'screenOrder','stitle','source','time', 'isRecommended'
  ]

    this.preScreens = []
    this.postScreens = []
    this.screenOrder =  0
  }

  afterInit() {
    this.preScreens = this.preScreens || []
    this.postScreens = this.postScreens || []
  }

  public id: number
  public title: string
  public stitle: string
  public source: string
  public link: string
  public imageUrl: string
  public section: DocumentSection
  public preScreens: TextScreen[]
  public postScreens: TextScreen[]
  public isViewed: boolean
  public startDay: number
  public endDay: number
  public screenOrder: number
  public actionPoints: number
  public isMandatory: boolean
  public isCompleted: boolean
  public enabled: boolean
  public time: any
  public isRecommended: boolean

  getTitle() {
    return 'Daily Reading'
  }

  getTitleKey() {
    return 's_r'
  }

  getDescription() {
    return this.title
  }

  getColorClass() {
    return 'stat-color-4'
  }

  completeBy() {
    return 'ByType'
  }
}
