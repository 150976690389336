import { Base } from '../shared/base'
import { Poll } from '../poll/poll.model'
import { ContentSurvey } from '../content-survey/content-survey.model'
import { Document, DocumentSection } from '../document/document.model'
import { Question } from '../question/question.model'
import { Reward } from '../reward/reward.model'
import { Badge } from '../badge/badge.model'
import { CustomBadge} from '../badge/custom-badge.model'
import { Habit } from '../habit/habit.model'
import { Checklist } from '../checklist/checklist.model'
import { UserGroup, PathGroup } from '../path-group/path-group.model'
import { PathClient } from './path-client.model'
import { Survey } from '../survey/survey.model'
import { InoutScreen } from '../inout-screen/inout-screen.model'
import { hexToRgb, shadeColor } from '../utils.service'
import { Theme } from '../theme/theme.model'
import { RoutineInfo } from '../routine-v2/routine-info.model'
import { Routine } from '../daily-content-v2/routine.model'
import { TodoListModel } from '../to-do-list/todo-list.model'
import { Reflection } from '../my-library/full-screen-reflection/reflection.model'
import { OnboardingSteps } from './onboarding-steps.model'

export interface ColoursAttributes {
  [name: string]: string
}
export interface NamesAttributes {
  [name: string]: string
}
export interface EnabledAttributes {
  [section: string]: boolean
}
export interface DailyStepsSubObjects {
  contentSurveys: ContentSurvey,
  inoutScreens: InoutScreen,
  routineInfo: RoutineInfo,
  toDos: TodoListModel,
  reflections: Reflection
  habits: Habit,
  polls: Poll,
  step: string,
  stepStatus: string

}
export interface DailySteps {
  [dailySteps:string]:DailyStepsSubObjects[]
}

export class Path extends Base {
  init() {
    this._fields = [
      'id', 'clientId', 'userGroup', 'pathGroup', 'name',
      'daysOfTheWeek', 'logoImage', 'signupEnabled',
      'onboardingSurveys', 'closeoutSurveys','dailySteps', 'onboardingSteps', 'inoutScreens', 'signupEmail',
      'daysOfContent', 'starts', 'ends', 'colours', 'names',
      'enabledAttributes', 'client', 'chatBoardReplies', 'polls',
      'contentSurveys', 'documents', 'questions', 'rewards', 'habits', 'chartTargets', 'enabled',
      'language', 'ptype', 'lowerlabel', 'higherlabel', 'lowerscale', 'higherscale', 'routineInfo','toDos', 
      'badgess', 'reflections', 'pathImage'
    ]

    this._defaultInput = { exclude: ['badges', 'customBadges', 'client'] }

    this._nestedFields = {
      client: PathClient,
      onboardingSurveys: Survey,
      closeoutSurveys: Survey,
      polls: Poll,
      contentSurveys: ContentSurvey,
      documents: Document,
      questions: Question,
      inoutScreens: InoutScreen,
      rewards: Reward,
      badges: Badge,
      // customBadges: CustomBadge, // LUMBA-1289
      habits: Habit,
      // checklists: Checklist,
      routineInfo: RoutineInfo,
      toDos: TodoListModel,
      reflections: Reflection,
      onboardingSteps: OnboardingSteps
    }

    // Defaults
    // Initialization of all nested objects is required
    this.names = {}
    this.dailySteps ={}
    this.colours = {
      themeheader: '#FFFFFF',
      highlightheader: '#FFFFFF',
      header: '#FFFFFF',
      theme: '#54BDAF',
      highlight: '#54BDAF',
      stat1: '#575757',
      stat2: '#C8FA0A',
      stat3: '#00A0DC',
      stat4: '#6E1E78',
      stat5: '#E63264' 
    }

    this.enabledAttributes = {}
    this.onboardingSurveys = []
    this.closeoutSurveys = []
    this.polls = []
    this.contentSurveys = []
    this.documents = []
    this.questions = []
    this.inoutScreens = []
    this.rewards = []
    this.badges = []
    // this.customBadges = []
    this.habits = []
    // this.checklists = []
    this.chatBoardReplies = []
    this.routineInfo = []
    this.toDos = []
    this.reflections = []
    this.onboardingSteps = []
  }

  afterInit() {
    this.gridDocuments = this.documents.filter(document => document.section === DocumentSection.Highlighted)
    this.casualDocuments = this.documents.filter(document => document.section !== DocumentSection.Highlighted)
    this.gridQuestions = this.questions.filter(question => !question.isPinned)
    this.fixedQuestions = this.questions.filter(question => question.isPinned && question.enabled)

    try {
      this.colours['highlight-faded'] = hexToRgb(this.colours['highlight'], .5)
      this.colours['stat1-shaded'] = shadeColor(this.colours['stat1'], -.2)
      this.colours['stat2-shaded'] = shadeColor(this.colours['stat2'], -.2)
      this.colours['stat3-shaded'] = shadeColor(this.colours['stat3'], -.2)
      this.colours['stat4-shaded'] = shadeColor(this.colours['stat4'], -.2)
      this.colours['stat5-shaded'] = shadeColor(this.colours['stat5'], -.2)
    } catch (e) {}
    this.setTheme()
  }

  public id: number
  public clientId: number
  public badgeIds: number[]
  public userGroup: UserGroup
  public pathGroup: PathGroup
  public name: string
  public daysOfTheWeek: number
  public logoImage: string
  public signupEnabled: boolean
  public signupEmail: string
  public daysOfContent: number
  public starts: string
  public ends: string
  public colours: ColoursAttributes
  public names: NamesAttributes
  public enabledAttributes: EnabledAttributes
  public language: string
  public onboardingSurveys: Survey[]
  public closeoutSurveys: Survey[]
  public polls: Poll[]
  public contentSurveys: ContentSurvey[]
  public documents: Document[]
  public questions: Question[]
  public inoutScreens: InoutScreen[]
  public routineInfo: RoutineInfo[]
  public toDos: TodoListModel[]
  // This should be untyped array
  public chatBoardReplies: any[]
  public rewards: Reward[]
  // FIXME: remove from request and from here
  // badges loaded separately, path has badgesIds
  public badges: Badge[]
  // public customBadges: CustomBadge[]
  public habits: Habit[]
  // public checklists: Checklist[]
  public client: PathClient
  // public users: User[]
  public enabled: boolean
  public reflections: Reflection[]

  public gridQuestions: Question[]
  public fixedQuestions: Question[]
  public gridDocuments: Document[]
  public casualDocuments: Document[]
  public theme: Theme

  public ptype: string
  public lowerlabel: string
  public higherlabel: string
  public lowerscale: number
  public higherscale: number
  public pathImage: string
  public dailySteps: DailySteps
  public onboardingSteps: OnboardingSteps[]
  public getCustomText(identifier: string): string {
    return this.names && this.names[identifier] ? this.names[identifier] : ''
  }

  public update(key, id, diff) {
    if (!this.hasOwnProperty(key)) { return false }
    console.log(this[key]);
    const element = this[key].find(elem => elem.id === id)
    if (element) {
      return Object.assign(element, diff)
    }
  }

  public setTheme() {
    const variables = {}
    if (this.colours) {
      for (const variableName of Object.keys(this.colours)) {
        variables[`--${variableName}-color`] = this.colours[variableName]
      }
    }

    const fonts = []
    if (this.client && this.client.bodyFontUrl) {
      const bodyFontName = `client${this.client.id}BodyFont`
      fonts.push({name: bodyFontName, url: this.client.bodyFontUrl})
      variables['--body-font'] = bodyFontName
    }
    if (this.client && this.client.headerFontUrl) {
      const headerFontName = `client${this.client.id}HeaderFont`
      fonts.push({name: headerFontName, url: this.client.headerFontUrl})
      variables['--header-font'] = headerFontName
    }
    if (this.client && this.client.backgroundImageUrl) {
      variables['--client-background'] = `url(${this.client.backgroundImageUrl})`
    } else { // LUMBA-1389
      variables['--client-background'] = 'url(assets/images/default-background.jpg)'
    }

    this.theme = new Theme(variables, fonts)
  }

  public getTheme(): Theme {
    return this.theme
  }

  public closeoutEnabledAndPresent() {
    const closeoutEnabled = !!this.enabledAttributes['closeout_survey']
    const closeoutPresent = Array.isArray(this.closeoutSurveys) && this.closeoutSurveys.length > 0
    return closeoutEnabled && closeoutPresent
  }

  public checkContentForDay(day: number) {
    const survey = this.findForCurrentDay(this.contentSurveys, day)
    if (survey) { return true }
    const poll = this.findForCurrentDay(this.polls, day)
    if (poll) { return true }
    const habit = this.findForCurrentDay(this.habits, day)
    if (habit) { return true }
    const question = this.findForCurrentDay(this.gridQuestions, day)
    if (question) { return true }
    const document = this.findForCurrentDay(this.gridDocuments, day)
    if (document) { return true }
    const routine = this.findForCurrentDay(this.routineInfo, day)
    if (routine) { return true }
    const inout = this.findForCurrentDay(this.inoutScreens, day)
    if (inout) { return true }
    const todo = this.findForCurrentDay(this.toDos, day)
    if (todo) { return true }
    const reflection = this.findForCurrentDay(this.reflections, day)
    if (reflection) { return true }
    const onboardingSettings = this.findForCurrentDay(this.onboardingSteps, day)
    if(onboardingSettings) { return true }
    return false
  }

  // LUMBA-1317
  public checkMandatoryForDay(day: number) {
    const poll = this.findForCurrentDay(this.polls, day)
    if (poll && poll.isMandatory && !poll.isCompleted) { return true }

    const habit = this.findForCurrentDay(this.habits, day)
    if (habit && habit.isMandatory && !habit.isCompleted) { return true }

    const survey = this.findForCurrentDay(this.contentSurveys, day)
    if (survey && survey.isMandatory && !survey.isCompleted) { return true }

    const question = this.findForCurrentDay(this.gridQuestions, day)
    if (question && question.isMandatory && !question.isCompleted) { return true }

    const document = this.findForCurrentDay(this.gridDocuments, day)
    if (document && document.isMandatory && !document.isCompleted) { return true }

    const routine = this.findForCurrentDay(this.routineInfo, day)
    if (routine && routine.isMandatory && !routine.isCompleted) { return true }

    const todo = this.findForCurrentDay(this.toDos, day)
    if (todo && todo.isMandatory && !todo.isCompleted) { return true }

    const reflection = this.findForCurrentDay(this.reflections, day)
    if (reflection && reflection.isMandatory && !reflection.isCompleted) { return true }
    const onboardingSettings = this.findForCurrentDay(this.onboardingSteps, day)
    if(onboardingSettings) { return true }
    return false    
  }

  public getHomepageTitleForDay(day: number) {
    const inout = this.findForCurrentDay(this.inoutScreens, day)
    if (!inout || !inout.homepageTitle || !inout.homepageTitle.text) {
      return 'Today`s focus'
    }
    return inout.homepageTitle.text
  }

  public getHomepageSubtextForDay(day: number) {
    const inout = this.findForCurrentDay(this.inoutScreens, day)
    if (!inout || !inout.homepageSubtext || !inout.homepageSubtext.text) {
      return 'Subtext'
    }
    return inout.homepageSubtext.text
  }

  public findForCurrentDay(collection: any, day: number) {
    return collection.find(item => item.startDay <= day && day <= item.endDay)
  }

  public lastDayOfContent(): number {
    const lastDay = (acc, curr) => {
      if (curr.isMandatory || !curr.isMandatory) {
        return Math.max(acc, curr.endDay)
      } else {
        return acc
      }
    }
    return [
      this.polls.reduce(lastDay, 0),
      this.habits.reduce(lastDay, 0),
      this.contentSurveys.reduce(lastDay, 0),
      this.gridQuestions.reduce(lastDay, 0),
      this.gridDocuments.reduce(lastDay, 0),
      this.routineInfo.reduce(lastDay, 0),
      this.toDos.reduce(lastDay, 0),
      this.inoutScreens.reduce(lastDay, 0),
      this.reflections.reduce(lastDay, 0)
    ].reduce((acc, curr) => Math.max(acc, curr), 0)
  }
}
