import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { ModalController, Platform } from '@ionic/angular';
@Component({
  selector: 'app-video-details',
  templateUrl: './video-details.component.html',
  styleUrls: ['./video-details.component.scss'],
})
export class VideoDetailsComponent implements OnInit {
  @Input() data: any;
  isMobile: any;

  constructor(private router: Router, public file: File, private modal: ModalController, public platform: Platform) {
   // this.journalPosts = this.router.getCurrentNavigation().extras.state.data;
  }

  ngOnInit() {
    var myVideo = document.getElementById('myVideo');
    myVideo.requestFullscreen();
  }
  close() {
    this.modal.dismiss();
  }
}

