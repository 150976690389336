import { Component, OnInit } from '@angular/core';
import { Path } from '../../path/path.model';
import { Reward } from '../../reward/reward.model';
import { AppState } from '../../app.service';
import { Router } from '@angular/router';
import { User } from 'src/app/user/user.model';
import { Client } from 'src/app/client/client.model';
import { ModalController } from '@ionic/angular';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'first90-page-achievements',
  templateUrl: './achievements-page.component.html',
  styleUrls: ['./achievements-page.component.scss']
})
export class AchievementsPageComponent implements OnInit {
  path: Path
  rewards: Reward[]
  showing: string = 'badges'
  backTo: string
  isMobile: boolean;
  currentUser: User
  client: Client
  enabled;

  constructor(private appState: AppState,private router: Router,
    private modalController: ModalController) {
    this.rewards = this.appState.get('currentPath').rewards
    this.isMobile = this.appState.get('isMobile')
    this.currentUser = this.appState.get('currentUser')
    this.client = this.appState.get('currentClient')
    this.path = this.appState.get('currentPath')
    this.enabled = this.path.enabledAttributes['rewards']

  }

  ngOnInit() {

  }

  toggleSettingDisplay(setting) {
    this.showing = setting
  }
  prevScreen() {
    window.history.back();
  }
  close() {
    if (this.backTo) {
      this.router.navigate([this.backTo])
    } else {
      this.router.navigate(['/main/profile'])
    }
  }

  async closeModal(){
    await this.modalController.dismiss();
  }

}
