import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppState } from '../app.service';

@Component({
  selector: 'app-alert-repeat-routine',
  templateUrl: './alert-repeat-routine.component.html',
  styleUrls: ['./alert-repeat-routine.component.scss']
})
export class AlertRepeatRoutineComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AlertRepeatRoutineComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private appState: AppState) { }

  ngOnInit() {
    console.log(this.data)
  }

}
