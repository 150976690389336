import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'first90-badge-icon-svg',
  templateUrl: './badge-icon-svg.component.html',
  styleUrls: ['./badge-icon-svg.component.scss']
})
export class BadgeIconSvgComponent implements OnInit {
  @Input() count: number
  @Input() forceCount: boolean
  @Input() onlyIcon: boolean

  constructor() { }

  ngOnInit() {
  }

}
