import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '../app.service';
import { UserBadge } from '../user/user-badge.model';
import { HomeUiService } from '../new-design/home-ui.service';

@Component({
  selector: 'profile-badge-icon',
  templateUrl: './profile-badge-icon.component.html',
  styleUrls: ['./profile-badge-icon.component.scss']
})
export class ProfileBadgeIconComponent {
  @Input() userBadge: UserBadge
  @Input() actionPoints: number = null
  @Input() imageUrl: string = ''
  @Input() badgeId: string
  @Input() colour: string = ''
  @Input() title: string = ''
  @Input() url;
  @Input() earned: boolean = false

  constructor(
    public router: Router,
    public homeUIService:HomeUiService,
    public appState: AppState,){
    }

  showBadgeInfo(){
    this.router.navigate(['badge'])
    this.appState.set('badgeTitle',this.title)
    this.appState.set('badgeActionPoints',this.actionPoints)
    this.appState.set('badgeId',this.badgeId)
    this.appState.set('imageUrl',this.imageUrl)
    this.appState.set('colour',this.colour)
    this.appState.set('earned',this.earned)
  }
  openComponent(comp) {
    this.homeUIService.openNextComponent(comp);
  }
}