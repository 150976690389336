import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Filter } from '../shared/filter'
import * as query from './badge.query'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'

import { Badge } from './badge.model'
import { UserBadge } from '../user/user-badge.model'
import { environment } from '../../environments/environment'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { User } from '../user/user.model'
import { Path } from '../path/path.model'
import { AppState } from '../app.service'

@Injectable()
export class BadgeService {


  API_URL: string = environment.apiBaseUrl
  listUserBadge = '/listUserBadges'

  currentPath: Path
  currentUser: User

  constructor(
    private httpclient: HttpClient,
    private apollo: Apollo,
    private appState: AppState,
    ) { }
  // TODO: Create a UserBadgeService`

  getListUserBadge(data): Observable<any> {
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
      const params = new HttpParams().append('user_id', data.user_id)
                                      .append('path_id', data.path_id);
    return this.httpclient.get(this.API_URL+this.listUserBadge,{headers,params})
                          .pipe(
                            map(response => {
                              return response
                            })
                          )
  }

  getList(filter: Filter = null): Observable<UserBadge[]> {
    return this.apollo
      .watchQuery<any>({ query: query.listUserBadges, variables: { filter: filter } })
      .valueChanges
      .pipe(
        map(response => {
          let list: UserBadge[] = []
          response.data.userBadges.forEach(element => { list.push(new UserBadge(element)) })
          return list
        })
      )
  }

  getBadge(id: number): Observable<Badge> {
    return this.apollo
      .watchQuery<any>({ query: query.findBadge, variables: { id: id } })
      .valueChanges
      .pipe(
        map(response => {
          return new Badge(response.data.badge)
        })
      )
  }

  save(badge: Badge) {
    return this.apollo.mutate({
      mutation: query.updateBadge,
      variables: {
        badge: badge.input()
      }
    })
  }
}
