import { Component, Input, ViewChild, OnInit, OnChanges, SimpleChanges, ElementRef, AfterViewInit } from '@angular/core'
import { Question } from './question.model'
import { Reply } from '../reply/reply.model'
import { QuestionService } from './question.service'
import { ReplyService } from '../reply/reply.service'
import { ReplyComponent } from '../reply/reply.component'
import { AttachmentUploaderComponent } from '../shared/attachment-uploader/attachment-uploader.component'
import { ToastrService } from 'ngx-toastr'

import { TranslationService } from '../translation/translation.service'
import { AppState } from '../app.service'
import { Path } from '../path/path.model'
import { Router } from '@angular/router'
import { Apollo } from 'apollo-angular'
import { CordovaService } from '../cordova.service'
import { SessionService } from '../session/session.service'
import { SpinnerService } from '../spinner.service'
import { I18n } from '../i18n.service'

@Component({
  selector: 'first90-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() question: Question
  @Input() mode: string = 'question'
  @Input() isPreview: boolean

  @ViewChild('uploader') uploader: AttachmentUploaderComponent
  @ViewChild('replyBlock') replyBlock: ElementRef
  @ViewChild('repliesContainer') repliesContainer: ElementRef

  path: Path
  sortBy: string = 'timestamp'
  replyText: string = ''
  attachmentUrl: string = null
  errors: { answerError: false }
  replyTo: Reply = null
  replyToComponent: ReplyComponent
  replyToId: number
  isLoading: boolean = false
  replyOffset: number
  tagName_id: number

  constructor(
    private apollo: Apollo,
    private router: Router,
    private appState: AppState,
    private toastrService: ToastrService,
    private i18n: I18n,
    private questionService: QuestionService,
    private replyService: ReplyService,
    private translationService: TranslationService,
    private spinnerService: SpinnerService,
    private cordovaService: CordovaService,
    private sessionService: SessionService
  ) {
    this.path = this.appState.get('currentPath')
  }

  ngOnInit() {
    console.log('init question component')
  }

  ngAfterViewInit() {
    this.replyOffset = this.replyBlock.nativeElement.offsetTop
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.question || changes.question.firstChange) {
      return false
    }
    this.question.sortRepliesBy(this.sortBy)
  }

  toggleSort(by: string) {
    this.sortBy = by
    this.question.sortRepliesBy(by)
  }

  reply() {
    if (!this.replyText || this.replyText.length < 1) {
      return false
    }
    if (this.isPreview) {
      return false
    }
    if (this.isLoading) {
      return false
    }
    //this.spinnerService.on()
    this.isLoading = true

   
    var list_of_tags_ids = []

    list_of_tags_ids = this.sessionService.getUserTAGs(this.replyText,this.complexItems)

    let attachmentUrl = ""
    if (this.replyTo) {
      this.replyService
        .reply(this.question, this.replyTo.id, this.replyText,attachmentUrl,list_of_tags_ids)
        .subscribe(reply => {
            //this.question.insertReply(reply)
            //this.spinnerService.off()
            this.reset()
            this.isLoading = false
            this.toastrService.success(this.i18n.t('Comment posted!'))
            this.repliesContainer.nativeElement.scrollIntoView()
            this.repliesContainer.nativeElement.scrollTop = 0
          },
          error => {
            this.toastrService.error(this.i18n.t('Comment not posted. Check your connection and try reloading the app.', 'Error'))
            this.isLoading = false
          })
    } else {
      this.questionService
        .replyQuestion(this.question, this.replyText, this.attachmentUrl,list_of_tags_ids, this.mode)
        .subscribe(reply => {
          //this.spinnerService.off()
          this.appState.set('currentActionPoints', reply?.data?.replyQuestion?.actionPoints)
          this.reset()
          this.isLoading = false
          this.toastrService.success(this.i18n.t('Comment posted!'))
          //
          this.repliesContainer.nativeElement.scrollIntoView()
          this.repliesContainer.nativeElement.scrollTop = 99999
        })
    }
    return false
  }

  translate() {
    let path: Path = this.appState.get('currentPath')
    let repliesIds = this.question.replies.map(reply => reply.id)
    this.translationService.loadRepliesTranslations(repliesIds, path.language).subscribe(
      (translations) => {
        this.question.replies.forEach(reply => {
          const key = `Reply:${reply.id}`
          reply.text = translations[key].text
        })
      }
    )
  }

  reset() {
    this.replyText = ''
    this.attachmentUrl = null
    if (this.uploader) {
      this.uploader.reset()
    }
  }

  resetReplyTo() {
    this.replyTo = null
    this.replyToId = null
    if (this.replyToComponent) {
      this.replyToComponent.selected = false
    }
    this.replyToComponent = null
  }

  setReplyTo(replyComponent: ReplyComponent) {
    if (this.uploader) {
      this.uploader.reset()
    }
    // This code was needed when all replies was visible
    // Now all replies hidden except selected for reply
    if (this.replyToComponent) {
      this.replyToComponent.selected = false
    }
    this.replyToComponent = replyComponent
    if (replyComponent) {
      this.replyTo = replyComponent.reply
      this.replyToId = this.replyTo.id
    } else {
      this.replyTo = null
      this.replyToId = null
    }
  }

  refresh() {
    console.log(this.router.url)
    this.apollo.getClient().clearStore()
    this.appState.refreshMainComponent.emit()
    // this.router.navigateByUrl('/DummyComponent', {skipLocationChange: true})
    //   .then(() => {
    //     console.log('test')
    //     this.router.navigate([this.router.url])
    //   })
    //location.reload()
  }

  scrollTo(event) {
    if (this.cordovaService.onCordova) {
      console.log('click event')
      console.log(this.replyBlock.nativeElement.offsetTop)
      //document.getElementById('container').style.paddingBottom = '200px'

      setTimeout(() => {
        //this.replyBlock.nativeElement.scrollIntoView()
        //window.scrollTo(0, this.replyBlock.nativeElement.offsetTop - 200)
        window.scrollTo({top: this.replyBlock.nativeElement.offsetTop - 160, behavior: 'smooth'})
        //this.replyBlock.nativeElement.scrollIntoView()
        //document.getElementById('main').scrollTop = this.replyOffset + 50
      }, 10)
    }
  }


  complexItems = this.appState.get("userlist")

  namesData : any[]

  format(item:any) {
    var data = item['id']
    setTimeout(() => {
      SessionService.setData(data)
    //  this.tagName_id = item['id'];
      console.log("Format data ",SessionService.getData());
    }, 100);

    return item['username'].toUpperCase()
  }

  mentionConfig:any = {
    mentions: [
      {
        items: this.complexItems,
        labelKey: 'username',
        mentionSelect: this.format,
        dropUp:true
      }
    ]
  };


}
