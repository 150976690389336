import { AppState } from './app.service'
import { Injectable } from '@angular/core'
import { MESSAGES } from './locale/messages'

@Injectable()
export class I18n {
  constructor(private appState: AppState) {
  }

  t(value: any, args?: any): any {
    const lang = this.appState.get('language') || 'en'

    if (lang != 'en' && MESSAGES[lang] && MESSAGES[lang][value]) {
      return MESSAGES[lang][value]
    } else {
      return value
    }
  }
}
