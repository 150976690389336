import { Component, Input, OnInit } from '@angular/core';

import { Reward } from './reward.model'

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'first90-reward',
  templateUrl: './reward.component.html',
  styleUrls: ['./reward.component.scss']
})
export class RewardComponent implements OnInit {

  @Input() reward;
  constructor() {

  }
  ngOnInit(): void {
    console.log("reward.............", this.reward)
  }
}
