import { Base } from "../shared/base"
import { TextScreen } from "../text-screen/text-screen.model"

export class OnboardingSteps extends Base {
    init() {
        this._fields = ['id', 'isMandatory', 'startDay', 'endDay', 'enabled', 'learningPreScreen',
            'learningPostScreen', 'isDeleted', 'dayPartImage','dayPartText', 'confirmedIntroText', 'notificationIntroMethodText',
            'learningScreenOrder', 'learningStepNo', 'learningActionPoints', 'accountHeaderImage', 'accountText',
            'accountPreScreen', 'accountPostScreen', 'accountScreenOrder', 'accountStepNo',
            'accountActionPoints', 'managerHeaderImage', 'managerEmailText', 'managerPreScreen', 'managerPostScreen',
            'managerScreenOrder', 'managerStepNo', 'managerActionPoints', 'profilePhotoText',
            'profilePreScreen', 'profilePostScreen', 'profileScreenOrder', 'profileStepNo', 'profileActionPoints',
            'dayPartHeader', 'accountHeader', 'managerHeader', 'profileHeader','confirmationIntroHeader','stepTypes']
        this.learningPreScreen = []
        this.learningPostScreen = []
        this.accountPreScreen = []
        this.accountPostScreen = []
        this.managerPreScreen = []
        this.managerPostScreen = []
        this.profilePreScreen = []
        this.profilePostScreen = []
      }
    
      afterInit() {
        this.learningPreScreen = this.learningPreScreen || []
        this.learningPostScreen = this.learningPostScreen || []
        this.accountPreScreen = this.accountPreScreen || []
        this.accountPostScreen = this.accountPostScreen || []
        this.managerPreScreen = this.managerPreScreen || []
        this.managerPostScreen = this.managerPostScreen || []
        this.profilePreScreen = this.profilePreScreen || []
        this.profilePostScreen = this.profilePostScreen || []
      }
    
      public id: number
    
      public isMandatory: boolean
      public startDay: number
      public endDay: number
      public enabled: boolean
      public maxLength: number
      public draft: boolean
      public isDeleted: boolean

      public dayPartImage: string
      public dayPartText: string
      public dayPartHeader: string
      public confirmedIntroText: string
      public confirmationIntroHeader: string
      public notificationIntroMethodText: string
      public learningPreScreen: TextScreen[]
      public learningPostScreen: TextScreen[]
      public learningScreenOrder: number
      public learningActionPoints: number

      public accountText: string
      public accountHeader: string
      public accountPreScreen: TextScreen[]
      public accountPostScreen: TextScreen[]
      public accountScreenOrder: number
      public accountActionPoints: number
      public accountHeaderImage: string

      public managerEmailText: string
      public managerHeader: string
      public managerPreScreen: TextScreen[]
      public managerPostScreen: TextScreen[]
      public managerScreenOrder: number
      public managerActionPoints: number
      public managerHeaderImage: string

      public profilePhotoText: string
      public profileHeader: string
      public profilePreScreen: TextScreen[]
      public profilePostScreen: TextScreen[]
      public profileScreenOrder: number
      public profileActionPoints: number
      public stepTypes: string
}
