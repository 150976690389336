declare var require: any;

import { Component, ChangeDetectorRef, Input, HostListener, Inject, ViewChild, ElementRef, ViewEncapsulation, DoCheck, OnDestroy } from '@angular/core'
import { Path } from '../../path/path.model'
import { Question } from '../../question/question.model'
import { AppState } from '../../app.service'

import { SessionService } from '../../session/session.service'
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router'
import { HomeV5Service } from '../home-v5/home-v5.service'
import { ReplyService } from '../../reply/reply.service'
import { User } from '../../user/user.model'
import { StatService } from '../../stat/stat.service'
import { CordovaService } from '../../cordova.service'
import { MobileChatServiceService } from './mobile-chat-service.service'
import { AlertUserLikeListComponent } from '../../alert-user-like-list/alert-user-like-list.component'
import { zoomInUpAnimation, slideInUpAnimation, bounceInAnimation, slideInLeftAnimation, slideInRightAnimation } from 'angular-animations'
import { ToastrService } from 'ngx-toastr'
import { TranslationService } from '../../translation/translation.service';
import { lang } from 'moment';
import { WINDOW } from '../../window.service';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from 'src/app/spinner.service';
import { AlertSupportComponent } from 'src/app/alert-support/alert-support.component';
import { Client } from 'src/app/client/client.model';
import { FullScreenFeedComponent } from 'src/app/full-screen-feed/full-screen-feed.component';
import { FullScreenRoutineComponent } from 'src/app/full-screen-routine/full-screen-routine.component';
import { AnalyticsServiceService } from 'src/app/services/analytics-service.service';
import { I18n } from 'src/app/i18n.service';
import { ModalController, Platform } from '@ionic/angular';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import mixpanel from 'mixpanel-browser';
import { expandOnEnterAnimation, slideOutRightAnimation } from 'angular-animations';
import { AnimationsService } from 'src/app/shared/animations.service';
import { HomeUiService } from 'src/app/new-design/home-ui.service';


declare var $: any;

@Component({
  selector: 'first90-mobile-chat-board',
  templateUrl: './mobile-chat-board.component.html',
  styleUrls: ['./mobile-chat-board.component.scss'],
  animations: [
    zoomInUpAnimation({ duration: 500, delay: 0 }),
    bounceInAnimation({ duration: 500, delay: 0 }),
    slideInUpAnimation({ duration: 500, delay: 0 }),
    slideInLeftAnimation({ duration: 300, delay: 0 }),
    slideInRightAnimation({ duration: 300, delay: 0 }),
    slideOutRightAnimation({duration: 500}),
    expandOnEnterAnimation({ duration: 400 }),
  ],
  encapsulation: ViewEncapsulation.None
})
export class MobileChatBoardComponent implements DoCheck, OnDestroy {

  @Input() mode: string = "question";
  @Input() isHomeRoute: boolean;
  @Input() data;
  @ViewChild('h1')
  h1: ElementRef;
  @ViewChild('h2')
  h2: ElementRef;
  @ViewChild('h3')
  h3: ElementRef;
  destroyed$ = new Subject<boolean>();
  leftState=false;
  rightState=false;
  path: Path;
  client: Client;
  showing = "questions";
  nameCB: string;
  nameCBST = `Post ideas, learnings or begin a discussion with your peers. You can also share articles, links, videos that help others along the path.`;
  sortBy: "recent";
  text: string;
  chatBoardVisible: boolean = true;
  isCreating: boolean = false;
  loading: boolean = false;
  scheduleDays: any[];
  activeQuestion: Question | false; // LUMBA-1306
  openQuestionFeed: Question | false; // LUMBA-1306
  activeQuestionDay: number;
  comments: any[] = []; // FIXME: This are ChatBoardComments
  chatBoard: Question;
  scheduleDay: number;
  questions: Question[];
  habits: any;
  fixedQuestion: Question;
  task: string;
  isdailyQuestion: boolean;
  isAllQuestion: boolean;
  isChartBoard: boolean;
  isPostFeeds: boolean = false;
  level_1_replies: any;
  feedsList: any[] = [];
  isLoading=false;
  voteLoading: boolean = false;
  user: any;
  state: boolean = false;
  delay: number;
  duration: number;
  animationState = false;
  currentResponseDay = -1;
  animation = ['zoomInUp', 'slideInUp'];
  scrollPosition: any = null;
  intro: any;
  isMobile: any
  isRoutineV2: any;
  hasVoted: any;
  page: number
  isScrolledBottom: boolean = false
  isMobileWebIos = false;
  isMobileWeb=false;
  currentTab = 0;
  allLabel: string
  questionsLabel: string
  actionLabel: string
  habitLabel: string
  communityNotification: boolean
  notificationData: any
  startTime: any
  endTime: any
  showNoDataMsg: boolean = false
  stats: object
  options: [
    {
      label: 'Sliding Entrances',
      animations: ['slideOutRight']
    },
  ]
  

  constructor(
    private appState: AppState,
    private cdRef: ChangeDetectorRef,
    private replyService: ReplyService,
    private sessionService: SessionService,
    private spinnerService: SpinnerService,
    private router: Router,
    private homev5Service: HomeV5Service,
    private statService: StatService,
    private homeUIService: HomeUiService,
    private cordovaService: CordovaService,
    private mobileService: MobileChatServiceService,
    private dialog: MatDialog,
    private translationService: TranslationService,
    private toasterService: ToastrService,
    private modalController: ModalController,
    private animationService: AnimationsService,
    private route: Router,
    private i18n: I18n,
    private platform: Platform,
    private analyticsService: AnalyticsServiceService,
    //@Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window
  ) {
    this.allLabel = this.i18n.t('ALL')
    this.allLabel= this.allLabel[0].toUpperCase() + this.allLabel.substr(1).toLowerCase()
    this.questionsLabel = this.i18n.t('QUESTIONS')
    this.questionsLabel= this.questionsLabel[0].toUpperCase() + this.questionsLabel.substr(1).toLowerCase()
    this.actionLabel = this.i18n.t('ACTIONS')
    this.actionLabel= this.actionLabel[0].toUpperCase() + this.actionLabel.substr(1).toLowerCase()
    this.habitLabel = this.i18n.t('HABITS')
    this.habitLabel= this.habitLabel[0].toUpperCase() + this.habitLabel.substr(1).toLowerCase()
    this.client = this.appState.get('currentClient');
    this.path = this.appState.get("currentPath");
    this.scheduleDay = this.appState.get("currentScheduleDay");
    this.chatBoard = this.appState.get("currentChatBoard");
    this.level_1_replies = this.chatBoard["replies"];
    this.chatBoard.repliesEnabled = true;
    this.chatBoardVisible = this.path.enabledAttributes["community"];
    this.user = this.appState.get("currentUser");
    console.log("this.user", this.user);
    this.habits = this.path.habits;
    this.scrollPosition = this.appState.get("scrollPosition")
    this.page = this.appState.get('page')
    this.isMobile = this.appState.get('isMobile')
    if (!this.page) {
      this.page = 1
    }
    this.getFeeds(this.path.id, this.user.id, false, null, this.page, true);
    this.startTime = new Date();
    this.chatBoard.text = this.path.getCustomText("q_cb_st") || this.nameCBST;
    this.isRoutineV2 = this.appState.get("routineVersion")
    this.task = "dailyQuestion";
    this.isdailyQuestion = true;

    this.isChartBoard = false;
    this.loading = false;
    const questionsLoaded = this.appState.get("questionsLoaded");
    if (!questionsLoaded) {
      this.appState.set("questionsLoaded", true);
    } else {
      this.loading = true;
      // this.sessionService.refreshSession().subscribe(() => {
      //   this.loading = false;
      // });
    }

    this.isMobile = this.appState.get('isMobile')
  }

  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    console.log("this.isMobileWeb value", this.isMobileWeb);
    this.getUserTAGsList();
    if(!this.isHomeRoute && !this.isMobile) {
      this.currentTab =0
    }
    else {
      this.currentTab = Number(localStorage.getItem('communityTab'));
    }
    this.analyticsService.setScreen(AnalyticsServiceService.COMMUNITY)
  }

  ngDoCheck(){
    //added if app is in BG & screen is community
    if (this.appState.get('isBackground')) {
    console.log('isBackground', this.appState.get('isBackground'));
    this.appState.set('isBackground', undefined)
      console.log('this.feedsList inside docheck:', this.feedsList);
      this.navigateToPost(this.feedsList)
      
    }
    
  }
  
  scroller() {
    setTimeout(() => {
      window.scroll(this.scrollPosition.x, this.scrollPosition.y);
    }, 1);
  }

  changeTab(evn) {
    this.currentTab = evn.index;
    this.endTime = new Date();
    let timeDiff = this.endTime - this.startTime; // time difference in milliseconds
    let seconds = Math.floor(timeDiff / 1000); // convert to seconds
    let minutes = Math.floor(seconds / 60); // convert to minutes
    let hours = Math.floor(minutes / 60); // convert to hours
    let timeSpent = hours + ":" + minutes + ":" + seconds; // format the time spent
    mixpanel.track('Community- Tab change', {'Tabs active': this.currentTab == 0 ? 'All' : this.currentTab == 1 ? 'Questions': this.currentTab == 2 ? 'Actions' : this.currentTab == 3 ? 'Habits' : '', 'time spent': timeSpent})
    localStorage.setItem('communityTab', this.currentTab.toString());
   //this.onChange();
  }

  getUserTAGsList() {
    if (!this.appState.get("userListLoaded")) {
      this.statService.getDailyParticipation().subscribe((response) => {
        this.appState.set("userListLoaded", true);
      });
    }
  }

  setActiveQuestion(day_num) {
    const question = this.path.gridQuestions.find(
      (n) => n.startDay <= day_num && day_num <= n.endDay
    );
    this.activeQuestionDay = day_num; // LUMBA-1306
    if (question && day_num <= this.scheduleDay && question.enabled) {
      this.activeQuestion = question;
    } else {
      // LUMBA-1306
      this.activeQuestion = false;
    }
  }
  closePage(){
   
    this.modalController.dismiss();

  }

  toggleChatBoard() {
    if (this.isChartBoard) {
      this.isChartBoard = false;
    } else {
      this.isChartBoard = true;
    }
  }

  toggleSort(by) {
    this.sortBy = by;
  }

  openResponse(i) {
    if (i == this.currentResponseDay) {
      this.currentResponseDay = -1;
    } else {
      this.currentResponseDay = i;
    }
  }
  isOpen(i) {
    return this.currentResponseDay == i;
  }
  toggleButtons() {
    if (this.task == "dailyQuestion") {
      this.task = "allQuestion";
      this.isAllQuestion = true;
      this.isdailyQuestion = false;
    } else {
      this.task = "dailyQuestion";
      this.isAllQuestion = false;
      this.isdailyQuestion = true;
    }
  }

  closeCommunityFeeds() {
    this.bounceAnimation()
    if (this.isPostFeeds) {
      this.isPostFeeds = false;
    }
  }

  openPostFeed() {

    this.bounceAnimation()
    if (!this.isPostFeeds) {
      this.isPostFeeds = true
    }
  }

  totalLikes(items) {
    return items.reduce((a, b) => +a + +b.votesCount, 0);
  }

  async openQuestion(e,flag, singleTask, mode, isCommentSelect: boolean = null) {
    console.log('singleTask :', singleTask);
    mixpanel.track('Community-Post', {'post id': singleTask.id, 'post type': singleTask.type, 'post title': singleTask.title, 'post description': singleTask.routine_description, 'post vote': singleTask.hasVoted})
    const scrollPosition = {
      x: e.pageX,
      y: e.pageY - 160
    }
   //this.closePage();
    this.appState.set("scrollPosition", scrollPosition);
    console.log('scrollPosition :', scrollPosition);

    let selectedOption = null;
    if (
      e.target.tagName.toLowerCase() === "a" &&
      e.target.target === "_blank"
    ) {
      if (this.cordovaService.onCordova) {
        e.stopPropagation();
        e.preventDefault();
        this.cordovaService.openLinkInBrowser(e.target.href);
      } else {
        window.open(e.target.href, "_blank");
      }
    } else {
      if (isCommentSelect || flag) {
        this.appState.set("commentClicked", true);
      }

      selectedOption = this.appState
        .get("currentPath")
        .update(mode + "s", singleTask.id, {
          votesCounts: singleTask.votescount
        });
console.log("selected", selectedOption);
      if (selectedOption) {
        console.log('selectedOption :', selectedOption);
        const data = {
          question: selectedOption,
          modes: mode,
        };
        this.appState.set("singleFeed", data)
        console.log('singleFeed :', data);
        this.appState.set("singleHabit", singleTask)
        if(flag) {
          const modal = await this.modalController.create({
            component: FullScreenFeedComponent,
            componentProps: {data:singleTask, focuseTextArea: true},
            // animated: false,
            cssClass: 'custom-modal',
            enterAnimation: this.animationService.slideInLeftEnterAnimation,
            leaveAnimation: this.animationService.SlideOutRightLeaveAnimation
          
           })  
           await modal.present()  
          
        }
        else {
          const modal = await this.modalController.create({
            component: FullScreenFeedComponent,
            // animated: false,
            componentProps: {data:singleTask, focuseTextArea: false},
            cssClass: 'custom-modal',
            enterAnimation: this.animationService.slideInLeftEnterAnimation,
            leaveAnimation: this.animationService.SlideOutRightLeaveAnimation,
         
           })  
           await modal.present()  

        }
        //this.router.navigate(["/open-question"]);
      }
    }
  }

  async openRoutine(e,flag, singleTask, mode, isCommentSelect: boolean = null) {
  console.log('singleTask :', singleTask);
    mixpanel.track('Community-post clicked', {'postId': singleTask.id, 'postTitle': singleTask.title, 'postDescription':singleTask.routine_description})
    const scrollPosition = {
      x: e.pageX,
      y: e.pageY - 160
    }
    //this.closePage()
    this.appState.set("scrollPosition", scrollPosition);
    this.appState.set("routine_color", singleTask.routine_color);

    let selectedOption = null;
    if (
      e.target.tagName.toLowerCase() === "a" &&
      e.target.target === "_blank"
    ) {
      if (this.cordovaService.onCordova) {
        e.stopPropagation();
        e.preventDefault();
        this.cordovaService.openLinkInBrowser(e.target.href);
      } else {
        window.open(e.target.href, "_blank");
      }
    } else {
      if (isCommentSelect || flag) {
        this.appState.set("commentClicked", true);
      }

      selectedOption = this.appState
        .get("currentPath")
        .update("routineInfo", singleTask.routine_info_id, {
          votesCounts: singleTask.votescount
        });

      if (selectedOption) {
        const data = {
          routine: selectedOption,
          mode: mode,
        };

        this.appState.set("singleFeed", data);
      }

      if (singleTask) {
        const data = {
          singleRoutine: singleTask,
          mode: mode,
        };

        this.appState.set("singleRoutineFeed", data)
        if(flag) {

          const modal = await this.modalController.create({
            component: FullScreenRoutineComponent,
            // animated: false,
            componentProps: {data:singleTask, focuseTextArea: true},
            cssClass: 'custom-modal',
            enterAnimation: this.animationService.slideInLeftEnterAnimation,
            leaveAnimation: this.animationService.SlideOutRightLeaveAnimation,

           })  
           await modal.present() 
        }
        else {
          const modal = await this.modalController.create({
            component: FullScreenRoutineComponent,
            // animated: false,
            componentProps: {data:singleTask, focuseTextArea: false},
            cssClass: 'custom-modal',
            enterAnimation: this.animationService.slideInLeftEnterAnimation,
            leaveAnimation: this.animationService.SlideOutRightLeaveAnimation,

           })  
           await modal.present() 
        }
      

       
      }

    }
  }

  openPost(e, post, isCommentSelect?: any) {
    const scrollPosition = {
      x: e.pageX,
      y: e.pageY - 160
    }
    this.appState.set("scrollPosition", scrollPosition);

    if (
      e.target.tagName.toLowerCase() === "a" &&
      e.target.target === "_blank"
    ) {
      if (this.cordovaService.onCordova) {
        e.stopPropagation();
        e.preventDefault();
        this.cordovaService.openLinkInBrowser(e.target.href);
      } else {
        window.open(e.target.href, "_blank");
      }
    } else {
      console.log('')
      let selectedOption = this.chatBoard.replies.find(
        (item) => item.id === post.id
      );

      if (isCommentSelect) {
        this.appState.set("commentClicked", true);
      }
      this.appState.set("singleFeed", selectedOption)
      if (selectedOption !== null && this.appState.get('singleFeed')) {
        this.router.navigate(["/open-post-feed"]);
      }
    }
  }

  reverseAndSortChaboard() {
    var sort_reply = this.level_1_replies.filter((event) => event.level == 1);
    return sort_reply;
  }

  refresh() {
    return new Promise((resolve) => {
      // here to fetch the data and rerender the contents.
      this.appState.refreshMainComponent.emit();
      setTimeout(resolve, 0);
    });
  }

  getFeeds(path_id, userId, isTranslated, language, page, isFirstLoad, event = null) {
    if (this.router.url === '/main/questions') {
      this.spinnerService.on();      
      console.log('step 1', this.spinnerService)
    }
    this.homev5Service.getListOfComments_v2(path_id, isTranslated, language, page).subscribe(
      (response) => {
        if (this.router.url === '/main/questions') {
          this.spinnerService.off();
          console.log("stp 2")
        }
        this.isLoading=true;
        console.log("losifnh", this.isLoading)
       

        this.feedsList = this.feedsList.concat(response.data)
        this.appState.set("feeds_list", this.feedsList);
        console.log('appstate feedsList :', this.appState.get('feeds_list'));
        if(this.isLoading) {
          this.showNoDataMsg = true
        }
        if (isFirstLoad){}
          event?.target.complete();
          console.log("step3")
          
        this.page = this.page + 1

        this.platform.ready().then(() => {
          if(this.platform.is('android') || this.platform.is('ios')){
            this.navigateToPost(this.feedsList)
          }
        })
    
      },
      (error) => {
        this.isLoading=true;
        console.log("loading", this.isLoading)
        if (this.router.url === '/main/questions') {
          this.spinnerService.off();
        }        
        this.toasterService.error(this.i18n.t('Server or connection issue. Please check your internet connection.'))
      }
    );
  }

  toggleLike(reply, mode) {
    this.user = this.appState.get("currentUser");
    mixpanel.track('Community-Post like', {'post Id': reply.id, 'vote': reply.hasVoted, 'post type': reply.type})
    this.voteLoading = true;
    reply.hasVoted = !reply.hasVoted;
    reply.votescount += reply.hasVoted ? 1 : -1;
    console.log("this.mode", mode);
    if(reply.hasVoted)
    {
      reply.likes?.push(this.user?._data);

    }
    else {
      reply.likes?.forEach( (user, index) => {
        console.log("hser",user, this.user?._data)
        if(user?.id === this.user?._data?.id) 
        reply.likes?.splice(index,1);
      });
      
    }
    this.cdRef.detectChanges();
    this.replyService.voteReply(reply, mode)
    .pipe(takeUntil(this.destroyed$)).subscribe(
      (status) => {
        this.voteLoading = false;
        this.cdRef.detectChanges();

        if (mode) {
          let habit = this.appState
            .get("currentPath")
            .update(mode + "s", reply.id, {
              hasVoted: status,
              votesCounts: status ? reply.votescount + 1 : reply.votescount - 1,
            });
        }
      },
      (error) => { 
        this.voteLoading = false;
        this.cdRef.detectChanges();
      }
    );
  }

  toggleLikeUserPost(reply, mode) {

    if (reply.user_id === this.user.id) {
      return false;
    }

    this.voteLoading = true;
    reply.hasVoted = !reply.hasVoted;
    reply.votes_count += reply.hasVoted ? 1 : -1;

    this.cdRef.detectChanges();
    this.replyService.voteReply(reply, mode).subscribe(
      (status) => {


        let habit = this.appState
          .get("currentPath")
          .update("chatBoardReplies", reply.id, {
            hasVoted: status,
            votesCount: status ? reply.votescount + 1 : reply.votescount - 1,
          });

        this.voteLoading = false;
        this.cdRef.detectChanges();
      },
      (error) => { },
      () => {
        this.voteLoading = false;
        this.cdRef.detectChanges();
      }
    );
  }

  bounceAnimation() {
    this.animationState = false;
    setTimeout(() => {
      this.animationState = !this.animationState;
    }, 100);
  }


  showLikeList(postId: number, mode: string) {
    this.mobileService.setIsLikeList(true)
    this.mobileService.setPostId(postId)
    this.mobileService.setMode(mode)
    // var dialog = this.dialog.open(AlertUserLikeListComponent, {
    //   panelClass: 'custom-dialog-container'
    // }
    // )
    this.homeUIService.openNextComponent(AlertUserLikeListComponent,'','alert-like-modal');
  }

  showComment(mode: string, id) {
    console.log('modemodemode', mode, id)
  }
  showCommentList(mode: string, id: number) {

    const requestUserList = {
      id: id,
      path_id: this.path.id,
      mode: mode
    }
    this.mobileService.setIsLikeList(false)
    this.mobileService.setRequestUserList(requestUserList)
    // var dialog = this.dialog.open(AlertUserLikeListComponent, {
    //   panelClass: 'custom-dialog-container'
    // }
    // )
    this.homeUIService.openNextComponent(AlertUserLikeListComponent,'','alert-like-modal');
  }


  deletePost(id) {

    this.mobileService.deletePost(id).subscribe(
      response => {
        console.log(response);
        this.toasterService.success(this.i18n.t('Post deleted successfully'))
        this.getFeeds(this.path.id, this.user.id, false, null, this.page, false)
      },
      error => {
        console.log(error);
      }

    )

  }

  copyMessage(val: string, comment_id: number) {
    try {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.toasterService.success(this.i18n.t("Copied to clipboard!"));
      this.deletePost(comment_id)
    } catch {
      this.toasterService.warning(this.i18n.t("Not able to copy and delete!"));
    }
  }

  translate() {
    this.spinnerService.on()
    const path: Path = this.appState.get('currentPath')
    const repliesIds = this.chatBoard.replies.map(reply => reply.id)
    this.translationService.loadRepliesTranslations(repliesIds, path.language).subscribe(
      (translations) => {
        this.chatBoard.replies.forEach(reply => {
          const key = `Reply:${reply.id}`
          reply.text = translations[key].text
        })
        this.getFeeds(this.path.id, this.user.id, false, null, this.page, false);

      }
    )
  }
  goToQuestion() {
    var supportDialog = this.dialog.open(AlertSupportComponent);
    supportDialog.afterClosed().subscribe()
  }


  @HostListener("window:scroll", [])
  onWindowScroll() {
    const offset = this.window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (offset > 200) {
      this.isScrolledBottom = true
    } else {
      this.isScrolledBottom = false
    }
  }

  doInfinite(event) {
    console.log('doInfinite :');
    this.getFeeds(this.path.id, this.user.id, false, null, this.page, false, event);
  }

  navigateToPost(feedsList: any) {
    this.communityNotification = this.appState.get('communityNotification')
    this.notificationData = this.appState.get('notificationData')
    let selectedOption = null;
    let postId: number
    if(typeof this.notificationData?.postId === 'string'){
      postId = parseInt(this.notificationData?.postId)
    }else{
      postId = this.notificationData?.postId
    }
    
    if (this.communityNotification && this.notificationData) {
      let singlePost = feedsList.find(item => item.id === postId, 10)
      let singleHabitPost = feedsList.find(item => item.routine_info_id === postId, 10)
      let path = this.appState.get('currentPath')
      this.sessionService.removeNotificationBadge()
      localStorage.setItem('CommunityNotificationId', this.notificationData.id.toString())

      if (this.notificationData.mode == 'question' || this.notificationData.mode == 'habit') {
        selectedOption = path.update(this.notificationData.mode + "s", singlePost.id, {
          votesCounts: singlePost.votescount
        });
        if (selectedOption) {
          const data = {
            question: selectedOption,
            modes: this.notificationData.mode,
          };
          this.appState.set("singleFeed", data)
          this.appState.set("singleHabit", singlePost)
          if(this.notificationData.mode == 'question'){
            localStorage.setItem('communityTab', '1');
          } else if(this.notificationData.mode == 'habit'){
            localStorage.setItem('communityTab', '2');
          }
         // this.dialog.open(FullScreenFeedComponent)
          console.log('singlePost :', singlePost);
          setTimeout(() => {
            this.homeUIService.openNextComponent(FullScreenFeedComponent, { data: singlePost })
          }, 700);
        
        }
      }

      if (this.notificationData.mode == 'routineInfo') {
        selectedOption = path.update("routineInfo", singleHabitPost.routine_info_id, {
            votesCounts: singleHabitPost.votescount
          });
        if (selectedOption) {
          const data = {
            routine: selectedOption,
            mode: 'routineInfo',
          };
          this.appState.set("singleFeed", data);
        }
        if (singleHabitPost) {
          const data = {
            singleRoutine: singleHabitPost,
            mode: 'routineInfo',
          };
          this.appState.set("singleRoutineFeed", data)
          localStorage.setItem('communityTab', '3');
         // this.dialog.open(FullScreenRoutineComponent, {});
         this.homeUIService.openNextComponent(FullScreenRoutineComponent,{data:singleHabitPost})

        }
      }
      
    }
  }
   ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
   }
}
