import { ChangeDetectorRef, Component, OnInit } from '@angular/core'

import { AppState } from '../app.service'
import { BadgeService } from './badge.service'
import { CustomBadgeService} from './custom-badge.service'
import { Path } from '../path/path.model'
import { User } from '../user/user.model'
import { Filter } from '../shared/filter'
import { Badge } from './badge.model'
import { CustomBadge } from './custom-badge.model'
import {HomePageService} from '../pages/home/home-page.service'

@Component({
  selector: 'first90-badge-list',
  templateUrl: './badge-list.component.html',
  styleUrls: ['./badge-list.component.scss']
})
export class BadgeListComponent implements OnInit {

  private user: User
  private path: Path
  private cdRef : ChangeDetectorRef

  enabled = true

  userBadges: any = {}
  completions: any = {}
  badges: {[badgeStringId: string]: Badge} = {}
  customBadges: CustomBadge[]
  userCustomBadges: string[]

  constructor(
    private badgeService: BadgeService,
    private appState: AppState,
  ) {
    this.user = this.appState.get('currentUser')
    this.path = this.appState.get('currentPath')
    if (this.path.enabledAttributes['custom_badges']) {
      this.getAllBadges(this.user.id, this.path.id)
    }
    this.path.badges.forEach(badge => {
      if (badge.enabled) {
        this.badges[badge.stringId] = badge
      }
    })
  }

 
  ngOnInit() {
    
  }

  getAllBadges(userId, pathId){
    const requestData = {
      'user_id': userId,
      'path_id': pathId
    }
    if (requestData) {
      this.badgeService.getListUserBadge(requestData).subscribe(userBadges => {
        this.customBadges = userBadges.custom_badges
        this.userBadges.list_user_badge = {}

        // userBadges.path_badges.forEach(badge => {
        //   if (badge.enabled) {
        //     this.badges[badge.stringId] = badge
        //   }
        // })

        if (userBadges.list_user_badge) {
          for (const key in this.badges) {
            const badge = this.badges[key]
            this.userBadges[badge.stringId] = []
            this.userBadges[badge.stringId] = userBadges.list_user_badge.filter(el => el.badgeId === badge.badgeId)
          }
        }
        if (userBadges.list_user_custombadge) {
          this.userCustomBadges = userBadges.list_user_custombadge.filter(el => el.enabled).map(badge => badge.badgeTitle)
        }
        console.log('this.userBadges :', this.userBadges);
        console.log("Custom badges ", this.customBadges);
      },
        (error) => {
          console.log('Record Not Found', error)
        })
    }
  }

}
