import { Base } from '../shared/base'

import { Habit } from '../habit/habit.model'

export class PathHabit extends Base {

  init() {
    this._fields = ['id', 'pathId', 'starts', 'expires', 'enabled', 'habit']

    this._nestedFields = ['habit']
  }

  public id: number
  public pathId: number
  public starts: string
  public expires: string
  public enabled: boolean
  public habit: Habit
}
