import { Component, OnInit } from '@angular/core'
import { AppState } from '../../app.service'
import { SessionService } from '../../session/session.service'
import { HomePageService } from '../home/home-page.service'

@Component({
  selector: 'first90-page-documents',
  templateUrl: './documents-page.component.html'
})
export class DocumentsPageComponent implements OnInit{
  loading = false
  completions: any
  scheduleDay: number

  constructor(
    private appState: AppState,
    private sessionService: SessionService,
    //private spinnerService: SpinnerService,
    private homePageService: HomePageService
  ) {
    this.scheduleDay = this.appState.get('currentScheduleDay')
    /*
      This is a refresh feature for pages that dependend on appState.currentPath data
      1) When we init this page for the first time we already have all data in appState
      2) When MainComponent refreshes recreates child component we need to reload
      session data manually

      See DocumentListComponent how appState.currentPath is used
    */
    this.loading = false
    const documentsLoaded = this.appState.get('documentsLoaded')

    if (!documentsLoaded) {
      this.appState.set('documentsLoaded', true)
    } else {
      this.loading = true
     // this.spinnerService.on()
      this.sessionService.refreshSession().subscribe(() => {
        this.loading = false
     //   this.spinnerService.off()
      })
    }
  }

  ngOnInit() {
    this.loadCompletionsForProgressChart()
  }

  loadCompletionsForProgressChart() {
  //  this.spinnerService.on()
    this.homePageService.getContent().subscribe(
      content => {
        this.loading = false
        this.completions = content.completions
        this.scheduleDay = content.scheduleDay
   //     this.spinnerService.off()
      },
      error => {
    //    this.spinnerService.off()
        this.loading = false
      }
    )
  }

  onView(event) {
    return false
  }
}
