import { environment } from '../../environments/environment'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { DefaultResponse } from '../shared/base.service'

const API_URL: string = environment.apiBaseUrl
const PARTICIPATION: string = '/users/participation'

@Injectable()
export class DailyParticipationService {

  constructor (private http: HttpClient) { }

  public getList() {
    return this.http
      .get<DefaultResponse>(API_URL + PARTICIPATION)
      .toPromise()
      .then(response => {
        return response.data
      })
      .catch(error => {
        return error
      })
  }
}
