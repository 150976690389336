import { Component, Input, OnInit } from '@angular/core'
import { Chart } from 'chart.js'

import { AppState } from '../app.service'
import { ColorUtil } from '../shared/utils/color-util'
import { Path } from '../path/path.model'
import { UserPublicProfileComponent } from '../components/user-public-profile/user-public-profile.component'
import { PopoverController } from '@ionic/angular'

@Component({
  selector: 'first90-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.scss']
})
export class StatComponent {

  @Input() id: number
  @Input() data: any
  @Input() enabled: boolean
  @Input() title: string
  @Input() subTitle: string
  @Input() color: string

  // FIXME: Fill all this fields with the corresponding path names
  nameTOTAL: string
  nameG: string
  chart = [];

  // FIXME: Make it a constant in a global file
  private path: Path
  private defaultColor: string = '#444444'
  private colors: Array<string> = ['#23BFFF', '#BC23FF', '#FF9C30', '#791C61', '#0074b1']

  constructor(private appState: AppState) {
    this.path = this.appState.get('currentPath')
    this.initColors()
  }

  ngAfterViewInit() {
    this.setChart()
  }

  getArray(value: number): Array<any> {
    return new Array(value)
  }
  

  private initColors() {
    this.colors = new ColorUtil().getColors(this.path) || this.colors
  }

  private setChart() {
    let backgroundColors = []
    // Colors of the elements that were finished.
    for (let i = 0; i < this.data.stats.labels.length; i++) {
      backgroundColors = backgroundColors.concat(this.colors[this.id - 1])
    }
    // Colors of the missing elements (the complement) - same color, but with .5 opacity -.
    for (let i = 0; i < (this.data.goal - this.data.total); i++) {
      backgroundColors = backgroundColors.concat("#d3d3d3")
    }
    let chartData = {
      datasets: [{ data: this.data.stats.data, backgroundColor: backgroundColors }],
      labels: this.data.stats.labels
    }
    // Add the missing labels for missing elements.
    for (let i = 0; i < (this.data.goal - this.data.total); i++) {
      chartData.datasets[0].data = chartData.datasets[0].data.concat( 1 )
      chartData.labels = chartData.labels.concat( 'incomplete' )
    }

    let canvas = document.getElementById(`canvas-${this.id}`);
    this.chart = new Chart(canvas, {
      type: 'doughnut',
      data :chartData,
      
      options: {
        responsive: true,
        aspectRatio: 3,
        cutoutPercentage: 70,
        maintainAspectRatio: true,
        legend: { display: false },
        title: { display: false },
        animation: {
          animateScale: true,
          animateRotate: true
        }
      }
    })
  }

  // FIXME: Send this to a global class.
  private hexToRGB(color, opacity): string {
    let hex = color.replace( /#/, '' ),
          r = parseInt( hex.substring( 0, 2 ), 16 ),
          g = parseInt( hex.substring( 2, 4 ), 16 ),
          b = parseInt( hex.substring( 4, 6 ), 16 )
    return 'rgba( ' + r + ', ' + g + ', ' + b + ', ' + ( opacity / 100 ) + ' )';
  }
}
