import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { AppState } from '../../app.service';
import { Path } from '../../path/path.model';
import { PathRegistration } from 'src/app/user/path-registration.model';
import { Poll } from 'src/app/poll/poll.model';
import { ContentSurvey } from 'src/app/content-survey/content-survey.model';
import { Question } from 'src/app/question/question.model';
import { Habit } from 'src/app/habit/habit.model';
import { Document } from 'src/app/document/document.model';
import { User } from 'src/app/user/user.model';
import { RoutineService } from 'src/app/daily-content-v2/routine.service';
declare var require: any

@Injectable()
export class HomeV5Service {

  intro:any
  API_URL: string = environment.apiBaseUrl
  UPDATE_SCHEDULEDAY = '/users/update_schedule_day'
  COMMUNITY_FEEDS = '/getAllData'
  post_comments = "/get_posts"
  post_comments_v2 ='/get_posts_v2'
  feedback = '/users/rate_app'
  list_subpath = '/users/path_list'
  change_subpath = '/users/update_path'
  setOnboardingCompleted = '/users/set_onboarding'
  accoutablity_email = '/users/accountability_email'
  userFeedback = '/createFeedbacks'
  path: any;
  calendarData: any
  stepFeedback = '/createStepRatings'
  DISABLE_NOTIFICATION = '/set_notification'
  ROUTINE_UPDATE_DATA = '/routineUpdateData'
  dailySteps = []
  poll: Poll
  survey: ContentSurvey
  document: Document
  question: Question
  habit: Habit


  constructor(private httpclient: HttpClient,
    private appState: AppState,
    private routineService: RoutineService) {
    this.path = this.appState.get('currentPath')
   }


  public updateScheduleDay(data): Observable<any>{
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));  
    return this.httpclient.put(this.API_URL+this.UPDATE_SCHEDULEDAY,data,{headers});
  }

  public giveFeedback(data): Observable<any>{
    const headers = new HttpHeaders();
    headers.set('Authorization', localStorage.getItem('token'));
    return this.httpclient.put(this.API_URL+this.feedback,data,{headers})
  }


  public getSubPathList(data: any): Observable<any>{
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));  
    return this.httpclient.put(this.API_URL+this.list_subpath,data,{headers})
  }


  public subpathchange(data:any):Observable<any>{
    this.appState.set('toDos', undefined)
    this.appState.set('loggedInDates', undefined)
    this.routineService.getTodoList(this.path?.id)
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));  
    return this.httpclient.put(this.API_URL+this.change_subpath,data,{headers})
  }


  public onbordingCompleted(data): Observable<any>{
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));  
    return this.httpclient.put(this.API_URL+this.setOnboardingCompleted,data,{headers})
  }

  public accountablityEmailAPI(data): Observable<any>{
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));  
    return this.httpclient.put(this.API_URL+this.accoutablity_email,data,{headers})
  }

  public supportFeedback(data): Observable<any>{
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));
    return this.httpclient.post(this.API_URL + this.userFeedback, data,{headers})
  }

  public postStepFeedback(data): Observable<any>{
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));
    return this.httpclient.post(this.API_URL + this.stepFeedback, data,{headers})
  }

  public routineUpdateData(data): Observable<any>{
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));
    return this.httpclient.post(this.API_URL + this.ROUTINE_UPDATE_DATA, data,{headers});
  }

  public getListOfFeeds(data,userId,isTranslate,language,page): Observable<any>{
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
    const params = new HttpParams()
    .append('id', data)
    .append('user_id',userId)
    .append('is_translate',isTranslate)
    .append('language',language)
    .append('page',page);
    
    return this.httpclient.get(this.API_URL+this.COMMUNITY_FEEDS,{headers,params});
  }

  public getListOfComments(data,userId,isTranslate,language,page): Observable<any>{
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
    const params = new HttpParams()
    .append('id', data)
    .append('user_id',userId)
    .append('is_translate',isTranslate)
    .append('language',language)
    .append('page',page);
    
    return this.httpclient.get(this.API_URL+this.post_comments,{headers,params});
  }
   public getListOfComments_v2(data,isTranslate,language,page): Observable<any>{
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
    const params = new HttpParams()
    .append('id', data)
    .append('is_translate',isTranslate)
    .append('language',language)
    .append('page',page);
    
    return this.httpclient.get(this.API_URL+this.post_comments_v2,{headers,params});
  }
  
  findForDay(collection: any,day_number): any {
    return collection.find(
      item => item.startDay <= day_number && day_number <= item.endDay
    )
  }

  getHabitByActiveDay(day_number:any,path:Path){
    var closeTime = null
    var timeArray = []
    try{

      const getCorrectTimeObject = (data) => {
      if(data && data.time){
        return timeArray.push(data.time) 
      }
    }

    var habitAction = this.findForDay(path.habits,day_number)
      getCorrectTimeObject(habitAction)
    
    var poll = this.findForDay(path.polls,day_number)
      getCorrectTimeObject(poll)
    
    var document = this.findForDay(path.documents,day_number)
      getCorrectTimeObject(document)
    
    var question = this.findForDay(path.questions,day_number)
      getCorrectTimeObject(question)
     
    var temp = timeArray[0]
      timeArray.forEach(element => {
        if(moment(temp).isBefore(element)){
          temp = element
        }
        closeTime = temp;
      });
    }catch(o_O){
        console.log("error",o_O);
    }
    if(!closeTime){
      return ''
    }else{
      return closeTime
    }
    
  }


  findById(arrayOfData,id){
    var data = arrayOfData.find( item => item.id === id)
    return data
  }


  getCalendarData(){
    return this.calendarData
  }


  setCalendarData(data){
    this.calendarData = data
    return this.calendarData
  }

  
  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject  = {};
    for(var i in originalArray) {
       lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for(i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
     return newArray;
  }

  animateActionPoints(points, start, end, duration){
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      points.innerHTML = Math.floor(progress * (end - start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
    this.appState.set('PreviousPoints',end)
  }

  public disableNotification(data): Observable<any>{
    const headers = new HttpHeaders();
    headers.set("Authorization", localStorage.getItem("token"));  
    return this.httpclient.put(this.API_URL + this.DISABLE_NOTIFICATION, data,{headers});
  }

  checkScreenCount(pathRegistration: PathRegistration, scheduleDay: number): boolean {
    let screensViewed = this.appState.get('screensViewed')
    let stepsArray = []
    let showContinueButton: boolean
    if (screensViewed && (pathRegistration?.screensViewed ==null || pathRegistration?.screensViewed?.length== screensViewed?.length)) {
      let steps = screensViewed
      stepsArray = this.removeDuplicates(steps, "step");
    } else {
      let steps = pathRegistration?.screensViewed
      stepsArray = this.removeDuplicates(steps, "step");
    }
    if (stepsArray?.length > 0) {
      stepsArray.forEach(element => {
        if (element.step === scheduleDay && element.visitedScreens < element.totalScreens) {
          showContinueButton = true
        }
      });
      return showContinueButton
    }
    return false
  }

  getDailySteps(currentDay: number, path: Path){
    this.poll = this.findForCurrentDay(path.polls, currentDay)
    this.survey = this.findForCurrentDay(path.contentSurveys, currentDay)
    this.document = this.findForCurrentDay(path.gridDocuments, currentDay)
    this.question = this.findForCurrentDay(path.gridQuestions, currentDay)
    this.habit = this.findForCurrentDay(path.habits, currentDay)

    this.dailySteps = [
      {
        kind: 'poll',
        title: 'h_ds_p',
        icon: 'glyphicon glyphicon-question-sign'
      },
      {
        kind: 'document',
        title: 'h_ds_d',
        icon: 'glyphicon glyphicon-list'
      },
      {
        kind: 'question',
        title: 'h_ds_q',
        icon: 'glyphicon glyphicon-comment'
      },
      {
        kind: 'survey',
        title: 'h_ds_s',
        icon: 'glyphicon glyphicon-comment'
      },
      {
        kind: 'habit',
        title: 'h_ds_h',
        icon: 'glyphicon glyphicon glyphicon-list'
      }
    ]
    this.dailySteps.forEach(s => this.fillParams(s))
    this.dailySteps.sort((a, b) => a.order - b.order)
  }

  fillParams(item: any) {
    const source = this[item.kind]
    if (source) {
      item.exists = true
      item.isCompleted = source.isCompleted
      item.order = source.screenOrder
      item.isMandatory = source.isMandatory
      item.actionPoints = source.actionPoints ? source.actionPoints : source.answer ? source.answer.actionPoints : 0
      item.screenId = `daily-content-screen-${item.kind}`
    } else {
      item.exists = false
      item.isCompleted = false
      item.isMandatory = false
      item.order = 0
      item.actionPoints = 0
    }
    return item
  }

  checkAllNonMandatory(): boolean {    
    let isNonMandatoryArray = this.dailySteps.filter(s => s.exists && !s.isMandatory);
    let isAllTaskArray = this.dailySteps.filter(s => s.exists);
    let isAllNonMandatory: boolean = false

    if (isNonMandatoryArray.length == isAllTaskArray.length) {
      isAllNonMandatory = true
    } 
    return isAllNonMandatory
  }

  findForCurrentDay(collection: any, currentDay: number): any {
    return collection.find(item => item.startDay <= currentDay && currentDay <= item.endDay)
  }

  checkPrevDayCompletedForNonMand(pathRegistration: PathRegistration, scheduleDay: number, currentUser: User, currentPath: Path, currentDayStarted: boolean): boolean {
    let showBeginButton: boolean
    if (scheduleDay === 1 && !currentDayStarted) {
      showBeginButton = true
      return showBeginButton
    }
    let stepsArray = []
    let screensViewed = this.appState.get('screensViewed')
    if (screensViewed) {
      let steps = screensViewed
      stepsArray = this.removeDuplicates(steps, "step");
    } else {
      let steps = pathRegistration.screensViewed
      stepsArray = this.removeDuplicates(steps, "step");
    }
    return false
  }

  checkPrevDayCompleted(pathRegistration: PathRegistration, scheduleDay: number, currentUser: User, currentPath: Path): boolean {
    let showPrevButton: boolean
    let stepsArray = []
    let screensViewed = this.appState.get('screensViewed')
    if (screensViewed) {
      let steps = screensViewed
      stepsArray = this.removeDuplicates(steps, "step");
    } else {
      let steps = pathRegistration.screensViewed
      stepsArray = this.removeDuplicates(steps, "step");
    }    
    if (stepsArray?.length > 0) {
      stepsArray.forEach(element => {
        if (element.step === scheduleDay && element.visitedScreens === element.totalScreens) {
          // localStorage.setItem(`Toggle:User:${currentUser.id}:DayUnlockCompleted:${currentPath.id}:${scheduleDay - 1}`, 'yes') 
          showPrevButton = true
        }
      });
      return showPrevButton
    }
    return false
  }

  checkReplayForNonMand(pathRegistration: PathRegistration, scheduleDay: number): boolean {
    let screensViewed = this.appState.get('screensViewed')
    let stepsArray = []
    let showReplayButton: boolean
    if (screensViewed) {
      let steps = screensViewed
      stepsArray = this.removeDuplicates(steps, "step");
    } else {
      let steps = pathRegistration.screensViewed
      stepsArray = this.removeDuplicates(steps, "step");
    }
    if (stepsArray?.length > 0) {
      stepsArray.forEach(element => {
        if (element.step === scheduleDay && element.visitedScreens === element.totalScreens) {
          showReplayButton = true
        }
      });
      return showReplayButton
    }
    return false
  }

}
