import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertSupportComponent } from '../alert-support/alert-support.component';
import { AppState } from '../app.service';
import { Client } from '../client/client.model';
import mixpanel from 'mixpanel-browser';
import { Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { I18n } from '../i18n.service';
import { HomeUiService } from '../new-design/home-ui.service';

@Component({
  selector: 'common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss'],
})
export class CommonHeaderComponent implements OnInit {

  client: Client
  @Input() public showEdit: boolean = false;
  @Input() headerName: any
  animationState = false;
  @Output() animationStateChange = new EventEmitter()

  constructor(
    private dialog: MatDialog,
    private appState: AppState,
    public platform: Platform,
    private route: Router,
    private i18n: I18n,
    private homeUIService: HomeUiService,
    private modalController: ModalController
  ) {
    this.client = this.appState.get('currentClient')
  }

  ngOnInit() {
    if(this.headerName){
     this.headerName = this.i18n.t(`${this.headerName}`)
    }
   }

  goToQuestion() {
    var supportDialog = this.dialog.open(AlertSupportComponent);
    supportDialog.afterClosed().subscribe()
  }
  trackMenuClick(value: string, event: any) {
    console.log("evennt", event)
    mixpanel.track('Home-Menu Click', { 'pageName':value, 'url': event.target.href });
  }

  close(){
    this.homeUIService.getLastModal();
    this.modalController.dismiss();
 
  }

}
