import { Component, OnInit,ViewChild } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { AppState } from '../app.service';
import { SessionService } from '../session/session.service';
import { ModalController,IonTextarea, } from '@ionic/angular';

@Component({
  selector: 'app-alert-give-feedback-commitment',
  templateUrl: './alert-give-feedback-commitment.component.html',
  styleUrls: ['./alert-give-feedback-commitment.component.scss']
})
export class AlertGiveFeedbackCommitmentComponent implements OnInit {

  postText:any = ''
  formRating1:any = 0
  formRating2:any = 0
  postMsg: any = "";
  complexItems = this.appState.get("userlist")
  namesData : any[] = new Array()  
  tempValue = 0
  index = 0
  @ViewChild('textarea', { static: false }) textarea: IonTextarea;
  
  constructor(private appState: AppState,private modalController: ModalController, private sessionService: SessionService) { }

  ngOnInit() {
  }
  private capitalizeSentences(text: string): string {
    return text.replace(/(?:^|\.\s)([a-z])/g, (match) => match.toUpperCase());
  }
  capitalLine(event): void {
    const textareaElement = this.textarea?.getInputElement();
    let ele = document.getElementsByClassName("web-textarea");
    // const lineHeight = parseFloat(getComputedStyle(this.textarea['el']).lineHeight);
    if (event && event.target.value) {
      this.postText = this.capitalizeSentences(event.target.value);
    }
  }
  onTextModification(ev) {
    console.log("called on change", ev.target.value);
    this.capitalLine(ev)
  }
  onSumbit(){
    this.modalController.dismiss();
    mixpanel.track('Commitments-Habit Complete Post', {value: this.postText})
    localStorage.setItem('commitmentFeedback',this.postText);
    localStorage.setItem('commitmentRating1',JSON.stringify(this.formRating1));
    localStorage.setItem('commitmentRating2',JSON.stringify(this.formRating2));
  }

  
  
  format(item:any) {
    return item['username'].toUpperCase()
  }

}
