import {AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild, OnChanges} from '@angular/core'
import {User} from '../user/user.model'
import {Path} from '../path/path.model'
import {AppState} from '../app.service'
import {ActivatedRoute, Router} from '@angular/router'
import {Poll} from '../poll/poll.model'
import {Document} from '../document/document.model'
import {Question} from '../question/question.model'
import {Habit} from '../habit/habit.model'
import {HabitService} from '../habit/habit.service'
import {SnoozedHabit} from '../daily-steps/daily-steps.model'
import { StatService } from '../stat/stat.service';
import { CordovaService } from '../cordova.service';
import { ContentSurvey, ContentSurveyStats } from '../content-survey/content-survey.model';
import * as _ from 'underscore';
import { RoutineService } from '../daily-content-v2/routine.service'
import { Filter } from '../shared/filter'
import { DailyStepsService } from '../daily-steps/daily-steps.service'

declare var $: any

@Component({
  selector: 'app-app-steps-desktop',
  templateUrl: './app-steps-desktop.component.html',
  styleUrls: ['./app-steps-desktop.component.scss']
})
export class AppStepsDesktopComponent implements OnInit {

  @ViewChild('modalSnoozed') modalSnoozed: ElementRef
  @Input() 'visible': boolean // LUMBA-1312
  @Input() 'showReplayButton': boolean // LUMBA-1312
  @Input() showUnlockButton: boolean // LUMBA-1312



  currentPath: Path
  currentUser: User
  currentDay: number
  maxAllowedScheduleDay: number  // LUMBA-1301
  poll: Poll
  survey: ContentSurvey
  document: Document
  question: Question
  habit: Habit
  dailySteps = []
  firstSurveyStats: ContentSurveyStats
  finalSurveyStats: ContentSurveyStats
  modalSnoozedHabit: SnoozedHabit
  messageDailySteps = 0;
  messageDailyStepsTotal = 0
  index:number
  messageTotalActionPoints = 0 
  titleOne:string
  titleTwo:string
  titleThree:string
  titleOfTask : string
  scheduledStep: any
  routineList: any[]
  isMobile: boolean

  constructor(
    private appState: AppState,
    private router: Router,
    private habitService: HabitService,
    private statService: StatService,
    private cordovaService: CordovaService, // LUMBA-1384
    private dailyStepsService: DailyStepsService,
    public route: ActivatedRoute,
    private routineService: RoutineService,
  ) {
    this.maxAllowedScheduleDay = this.appState.get('maxAllowedScheduleDay')
    this.init()
  }

  init() {
    this.currentUser = this.appState.get('currentUser')
    this.currentPath = this.appState.get('currentPath')
    this.currentDay = this.appState.get('currentScheduleDay')
    this.scheduledStep = this.appState.get('scheduleDayForCurrentStep')
    this.isMobile = this.appState.get('isMobile')

    this.poll = this.findForCurrentDay(this.currentPath.polls)
    this.survey = this.findForCurrentDay(this.currentPath.contentSurveys)
    const firstSurvey = this.statService.findFirstSurvey(this.currentPath.contentSurveys, this.currentDay);
    const finalSurvey = this.statService.findFinalCompletedSurvey(this.currentPath.contentSurveys);
    if (firstSurvey) {
      this.firstSurveyStats = firstSurvey.getStats()
    }
    if (finalSurvey) {
      this.finalSurveyStats = finalSurvey.getStats()
    }
    this.document = this.findForCurrentDay(this.currentPath.gridDocuments)
    this.question = this.findForCurrentDay(this.currentPath.gridQuestions)
    this.habit = this.findForCurrentDay(this.currentPath.habits)

    console.log("Current DailySteps ",this.currentPath);

    this.dailySteps = [
      { kind: 'poll',
        title: 'h_ds_p',
        icon: 'glyphicon glyphicon-question-sign'
      },
      { kind: 'document',
        title: 'h_ds_d',
        icon: 'glyphicon glyphicon-list'
      },
      { kind: 'question',
        title: 'h_ds_q',
        icon: 'glyphicon glyphicon-comment'
      },
      { kind: 'survey',
        title: 'h_ds_s',
        icon: 'glyphicon glyphicon-comment'
      },
      { kind: 'habit',
        title: 'h_ds_h',
        icon: 'glyphicon glyphicon glyphicon-list'
      }
    ]
    this.dailySteps.forEach(s => this.fillParams(s))
    this.dailySteps.sort((a, b) => a.order - b.order)
    this.setSnoozedHabits()
    this.checkCurrentDayCompleted()
    this.checkUnlockCurrentDayCompleted();
    this.checkAllNonMandatory();
    this.taskProgress()
    this.dailyStepNames();

    console.log("Today task ",this.titleOfTask)

    this.routineList = this.appState.get('routines')
    console.log('this.routinelist',this.routineList)
    if(!this.routineList || this.routineList && this.routineList.length === 0){
      this.routineService.getList(this.currentUser.id,this.currentPath.id)
    }
    
  }


  getTodaysTask(){
    if(!_.isUndefined(this.currentPath.inoutScreens[this.currentDay-1])){
      this.titleOfTask = this.currentPath.inoutScreens[this.currentDay-1].homepageSubtext.text;
      return this.titleOfTask;
      }
  }


  getTaskDurations(){
    if(!_.isUndefined(this.currentPath.inoutScreens[this.currentDay-1])){

      if(this.currentPath.inoutScreens[this.currentDay-1].stime && this.currentPath.inoutScreens[this.currentDay-1].etime){
      this.titleOfTask =  "Duration "+ this.currentPath.inoutScreens[this.currentDay-1].stime +" - "+ this.currentPath.inoutScreens[this.currentDay-1].etime + " Min" ;
      return this.titleOfTask;
      }else if(!this.currentPath.inoutScreens[this.currentDay-1].stime && this.currentPath.inoutScreens[this.currentDay-1].etime){
        this.titleOfTask =  "Duration "+ this.currentPath.inoutScreens[this.currentDay-1].etime + " Min" ;
        return this.titleOfTask;
        }else{
        return "Duration 5 - 10 Min";
      }
      }else {
        return "Duration 5 - 10 Min";
      }
  }

  fillParams(item: any) {

    const source = this[item.kind]
    if (source) {
      item.exists = true
      item.isCompleted = source.isCompleted
      item.order = source.screenOrder
      item.isMandatory = source.isMandatory
      item.actionPoints = source.actionPoints ? source.actionPoints : source.answer ? source.answer.actionPoints : 0
      item.screenId = `daily-content-screen-${item.kind}`
    } else {
      item.exists = false
      item.isCompleted = false
      item.isMandatory = false
      item.order = 0
      item.actionPoints = 0
    }
    return item
  }

  
  taskProgress(){

    console.log("this.dailySteps --> ",this.dailySteps); // prints elements: 0, 1, 2, 3

    this.messageTotalActionPoints = 0 
    for (let val of this.dailySteps) {
      if(val.isCompleted){
          this.messageDailySteps+=1
      }
      if(val.actionPoints){
        this.messageTotalActionPoints+=val.actionPoints
      }
    }  
    this.messageDailyStepsTotal = this.dailySteps.length
  }

  checkCurrentDayCompleted() {
    if (this.dailySteps.filter(s => s.exists && !s.isCompleted).length) {
      localStorage.removeItem(`Toggle:User:${this.currentUser.id}:DayCompleted:${this.currentPath.id}:${this.currentDay}`)
    } else {
      localStorage.setItem(`Toggle:User:${this.currentUser.id}:DayCompleted:${this.currentPath.id}:${this.currentDay}`, 'yes')
    }
}

  checkUnlockCurrentDayCompleted() {    

    let isMandatoryArray = this.dailySteps.filter(s => s.exists && s.isMandatory);
    let isMandCompletedArray = this.dailySteps.filter(s => s.exists && s.isMandatory && s.isCompleted);

    console.log('Mand',isMandatoryArray);
    console.log('Mand completed',isMandCompletedArray);

    if(isMandatoryArray.length == isMandCompletedArray.length){
      localStorage.setItem(`Toggle:User:${this.currentUser.id}:DayUnlockCompleted:${this.currentPath.id}:${this.currentDay}`, 'yes') 
    }else{
      localStorage.removeItem(`Toggle:User:${this.currentUser.id}:DayUnlockCompleted:${this.currentPath.id}:${this.currentDay}`)
    }
  }


  checkAllNonMandatory() {
    let isNonMandatoryArray = this.dailySteps.filter(s => s.exists && !s.isMandatory);
    let isAllTaskArray = this.dailySteps.filter(s => s.exists);
    console.log("isNonMandatoryArray -: ",isNonMandatoryArray);
    console.log("isAllTaskArray -: ",isAllTaskArray);

    if(isNonMandatoryArray.length == isAllTaskArray.length){
      localStorage.setItem(`Toggle:User:${this.currentUser.id}:checkNonMandatory:${this.currentPath.id}:${this.currentDay}`, 'yes') 
    }else{
      localStorage.removeItem(`Toggle:User:${this.currentUser.id}:checkNonMandatory:${this.currentPath.id}:${this.currentDay}`)
    }
  }




  dailyStepNames(){
    for (var dailyIndex in this.dailySteps) {
      console.log("Title ",this.dailySteps[dailyIndex].title); // prints elements: 0, 1, 2, 3
    }
  }


  setSnoozedHabits() {
    this.currentPath.habits.sort((a, b) => a.endDay - b.endDay)
    this.dailyStepsService.setSnoozedSteps(
      this.currentPath.habits
        .filter(item => item.scheduledLater
                       // hided the this condition which show the snooz action above the completed day
         //  && item.endDay < this.currentDay
           && item.confirmation < 1) // LUMBA-1360, // LUMBA-1445
        .map(habit => {
          return {
            id: habit.id,
            dayNum: habit.startDay, // LUMBA-1307
            title: habit.getTitle(),
            text: habit.text,
            actionPoints: habit.actionPoints,
            screenId: 'daily-content-screen-habit'
          }
        }
      )
    )
  }

  getSnoozedSteps() {
    return this.dailyStepsService.getSnoozedSteps()
  }

  removeFromSnoozed(habit_id) {
    this.dailyStepsService.removeFromSnoozed(habit_id)
  }

  findForCurrentDay(collection: any): any {
    return collection.find(item => item.startDay <= this.currentDay && this.currentDay <= item.endDay)
  }

  findFirstSurvey(collection: any): any {
    return collection.find(item => item.isCompleted && item.startDay <= this.currentDay)
  }

  findFinalSurvey(collection: any): any {
    return collection.reverse().find(item => item.isCompleted)
  }

  openDailyContent(step) {
    if(!this.showUnlockButton){
      localStorage.removeItem(`Toggle:User:${this.currentUser.id}:DayProgress:${this.currentPath.id}:${this.currentDay}`)
      this.router.navigate(['/content', this.currentDay], { queryParams: { startFromScreen: step.screenId } })
    }
  }

  openSnoozedContent(step) {
    this.modalSnoozedHabit = step
    $(this.modalSnoozed.nativeElement).modal('show')
  }

  markCompleted(habit_id: number) {
    this.habitService.confirm(habit_id).subscribe(
      response => {
      },
      error => {
      },
      () => {
        localStorage.setItem(`Toggle:User:${this.currentUser.id}:HabitConfirmed:${habit_id}`, 'yes')
        localStorage.removeItem(`Toggle:User:${this.currentUser.id}:HabitScheduled:${habit_id}`)
        this.removeFromSnoozed(habit_id)
        $(this.modalSnoozed.nativeElement).modal('hide')
      })
  }

  ngOnInit() {

  }

  ngAfterViewInit() {

    try{
    $(this.modalSnoozed.nativeElement).on('shown.bs.modal', () => {
      $('.modal-backdrop').click(() => { $(this.modalSnoozed.nativeElement).modal('hide') }) // LUMBA-1426
    })
    }catch(o_O){
      
    }
  
  }


  // LUMBA-1384
  @HostListener('click', ['$event'])
  onLinkClick(e) {
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        console.log('cordova click')
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        window.open(e.target.href, '_blank')
      }
    }
  }


}
