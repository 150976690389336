import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ToastrService } from 'ngx-toastr'
import { AppState } from '../app.service'
import { CordovaService } from '../cordova.service'
import { I18n } from '../i18n.service'
import { NotificationService } from '../notification/notification.service'
import { Path } from '../path/path.model'
import { TimePickerComponent } from '../time-picker/time-picker.component'
import { ToasterService } from '../toaster.service'
import { PathRegistration } from '../user/path-registration.model'
import { User } from '../user/user.model'
import { UserService } from '../user/user.service'
import { UpdatePathRegistration } from '../common-services/utility.service'
declare var FCMPlugin: any

@Component({
  selector: 'first90-notification-time-select',
  templateUrl: './notification-time-select.component.html',
  styleUrls: ['./notification-time-select.component.scss']
})
export class NotificationTimeSelectComponent implements OnInit {
  @ViewChild ('timePicker') timePicker: TimePickerComponent

  public confirmedTimeValue: string
  public selectedTimeOptionValue: string = null

  @Output() confirmedTimeChange = new EventEmitter()
  @Output() selectedTimeOptionChange = new EventEmitter()
  

  morningTime: boolean
  lunchTime: boolean
  atWorkTime: boolean
  homeTime: boolean
  isTimePicked=false
  isTimeSelector=true
  isTimePicker=false

  isTimeCard:boolean
  isTimePick:boolean
  isTimeConfirmedValue = false

  pushNotificationsEnabled: boolean
  currentPath: Path
  currentUser: User
  loading: boolean = false
  isMobile;
  pathRegistration
  public updatePathRegistration: UpdatePathRegistration;

  constructor(private appState: AppState,
    private cordovaService: CordovaService,
    private userService: UserService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private toastrService: ToasterService,
    private i18n: I18n,
    ) {

    this.currentUser = this.appState.get('currentUser')
    this.currentPath = this.appState.get('currentPath')
    this.isMobile = this.appState.get("isMobile")
    this.pathRegistration = this.appState.get('pathRegistration')
    if (localStorage.getItem('notificationsEnabled')) {
      this.pushNotificationsEnabled = true
    }

   }

  ngOnInit() {
    this.atWorkTime=false;
    this.morningTime=false;
    this.lunchTime=false;
    this.homeTime=false; 

    this.isTimeCard=true
    this.isTimePick=false

  }

  timePickerToggle(){
    if(this.isTimePicked){
      this.isTimePicked=false;
    }else{
      this.isTimePicked=true;
    }

  }

  @Input()
  get confirmedTime() {
    return this.confirmedTimeValue
  }

  set confirmedTime(value: string) {
    this.confirmedTimeValue = value
    this.isTimeConfirmedValue = true;
    this.confirmedTimeChange.emit(this.confirmedTimeValue)
  }

  @Input()
  get selectedTimeOption() {
    return this.selectedTimeOptionValue
  }


  @Input()
  get isTimeConfirmed() {
    return this.isTimeConfirmedValue
  }


  set selectedTimeOption(value: string) {
    this.selectedTimeOptionValue = value
    this.selectedTimeOptionChange.emit(this.selectedTimeOptionValue)
  }

  selectTime(option) {
    if (this.confirmedTime) return
    this.selectedTimeOption = option
    let time = ''
    switch (option) {
      case 'AFTER_WAKING_UP':
        time = '08:00'
        this.morningTime=true;
        this.atWorkTime=false;
        this.lunchTime=false;
        this.homeTime=false;
        break
      case 'AFTER_ARRIVING_AT_WORK':
        time = '11:00'
        this.atWorkTime=true;
        this.morningTime=false;
        this.lunchTime=false;
        this.homeTime=false;
        break
      case 'AFTER_LUNCH':
        time = '13:00'
        this.lunchTime=true;
        this.atWorkTime=false;
        this.morningTime=false;
        this.homeTime=false;
    
        break
      case 'AFTER_ARRIVING_HOME':
        time = '19:00'
        this.atWorkTime=false;
        this.morningTime=false;
        this.lunchTime=false;
        this.homeTime=true;
        break
      default: time = '11:00'
    }

    window.requestAnimationFrame(() => {
      this.timePicker.setActiveTimeByTime(time)
    })

    this.isTimeCard=false
    this.isTimePick=true
  }

  isTimeCardData(){

    if(this.isTimePick){
      this.isTimeCard=false
    }

  }


  confirmTime() {
    if (!this.timePicker) return

    this.confirmedTime = this.timePicker.getTime()

    this.isTimePick=false;
    this.isTimeCard=true

    this.save()
  }



  save() {
    
    if (localStorage.getItem('notificationsEnabled')) {
      this.pushNotificationsEnabled = true
    }
     let deviceId;
    if (this.isMobile && (localStorage.getItem('deviceToken') !== null || localStorage.getItem('deviceToken') != undefined)) {
      deviceId =localStorage.getItem('deviceToken')
    }
    else {
      deviceId = this.pathRegistration.pathData.deviceRegistrationId;
    }
    // TODO: Add Cordova push API
    const pathRegistration = new PathRegistration({
      userId: this.currentUser.id,
      pathId: this.currentPath.id,
      pathData: {
        loginReminderEnabled: this.pushNotificationsEnabled,
        loginReminderTime: this.confirmedTime,
        deviceRegistrationId: deviceId,
        emailReminderEnabled: true,
        pushReminderEnabled: true,
        socialReminderEnabled: true,
        calenderEnabled: true
      }
    })

    if (this.cordovaService.isIOS() && typeof FCMPlugin !== 'undefined') {
      FCMPlugin.requestPermissionOnIOS()
    }

    this.loading = true
    const deviceToken = localStorage.getItem('deviceToken')

    if (deviceToken) {
      this.userService.savePathRegistration(pathRegistration).subscribe(
        (response) => {
          this.updatePathRegistration = response.data;
          this.appState.set(
            "pathRegistration",
            this.updatePathRegistration.updatePathRegistration
          );
          this.toastrService.success(this.i18n.t('Settings updated!'))
        },
        error => {
          console.log('Server error', error)
          this.loading = false
          this.toastrService.error(this.i18n.t('Settings not saved. Please check your internet connection.'))
        },
        () => {
          this.loading = false
        }
      )
    } else {
      console.error('Device token not found')
      this.loading = false
      this.toastrService.success(this.i18n.t('Settings updated!'))
    }
  }



  unconfirmTime() {
    const previousTime = this.confirmedTime
    this.confirmedTime = null
    window.requestAnimationFrame(() => {
      this.timePicker.setActiveTimeByTime(previousTime)
    })
  }

}
