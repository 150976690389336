import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppState } from '../app.service';
import { CordovaService } from '../cordova.service';
import { SpinnerService } from '../spinner.service';
import { Survey } from '../survey/survey.model';
import { Path } from '../path/path.model';
import { User } from '../user/user.model';
import { PollService } from '../poll/poll.service';
import { PopoverComponent } from '../shared/popover/popover.component';
import { PopoverController } from '@ionic/angular';
import { AnalyticsServiceService } from '../services/analytics-service.service';
import { Client } from '../client/client.model';
import { I18n } from '../i18n.service';
import { HomeUiService } from '../new-design/home-ui.service';

@Component({
  selector: 'survey-force-rank',
  templateUrl: './survey-force-rank.component.html',
  styleUrls: ['./survey-force-rank.component.scss']
})
export class SurveyForceRankComponent implements OnInit {

  @Input() parentComponentName: string;
  @Input() question: Survey

  @Output() dataEvent = new EventEmitter<any>();

  optionIds: number[]
  answerList: any

  isMobile: boolean
  listItems: any;
  isMobileWeb=false;
  isMobileWebIos = false;
  enableFinalizeButton = true
  finalizedOptionIds: string
  currentPath: Path
  currentUser: User
  currentClient: Client
  isAnswered = false
  statsPer: number[]

  constructor(
    private appState: AppState,
    private spinnerService: SpinnerService,
    private cordovaService: CordovaService,
    private pollService: PollService,
    private cdRef: ChangeDetectorRef, 
    private popover:PopoverController,
    private analyticsService: AnalyticsServiceService,
    public homeUIService: HomeUiService,
    private i18n: I18n
  ) { 
    this.isMobile = appState.get('isMobile')
    this.currentPath = appState.get('currentPath')
    this.currentUser = appState.get('currentUser')
    this.currentClient = appState.get('currentClient')
  }

  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    console.log("this.isMobileWeb value", this.isMobileWeb);
  }


  changeRankToTop(optionsArray:any, option: any){
    let currentIndex = optionsArray.findIndex(data => data.id === option.id);
    let topIndex = currentIndex - 1
    if (currentIndex >= 1) {
      this.swapArray(optionsArray,currentIndex,topIndex)
    }
  }

  changeRankToBottom(optionsArray:any, option: any){
    let currentIndex = optionsArray.findIndex(data => data.id === option.id);
    let topIndex = currentIndex + 1
    if (topIndex < optionsArray.length) {
      this.swapArray(optionsArray,currentIndex,topIndex)
    }

  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  swapArray(optionsArray,currentIndex,topIndex){
    var temp = optionsArray[currentIndex];
    optionsArray[currentIndex] = optionsArray[topIndex]
    optionsArray[topIndex] = temp
    let updatedArray = optionsArray
    this.optionIds = updatedArray.map(item => item.id)
    let optionId = '{' + this.optionIds + '}'
    this.dataEvent.emit(optionId);
  }

  changeAnswerRankToTop(answerList,answer){
    let currentIndex = answerList.findIndex(data => data.id === answer.id);
    let topIndex = currentIndex - 1
    if (currentIndex >= 1) {
      this.swapArray(answerList,currentIndex,topIndex)
    }
  }

  changeAnswerRankToBottom(answerList,answer){
    let currentIndex = answerList.findIndex(data => data.id === answer.id);
    let topIndex = currentIndex + 1
    if (topIndex < answerList.length) {
      this.swapArray(answerList,currentIndex,topIndex)
    }

  }

  onRenderItems(event) {
    
    this.listItems = this.question.options
     let draggedItem = this.listItems.splice(event.detail.from,1)[0];
     this.listItems.splice(event.detail.to,0,draggedItem)
    event.detail.complete();

    let idsArray = []
    this.listItems.forEach(element => {
      idsArray.push(element)
    });

    this.optionIds = idsArray.map(item => item.id)
    this.finalizedOptionIds = '{' + this.optionIds + '}'
    
  }
  changeRadio(event){
    this.listItems = this.question.options
    let idsArray = []
    this.listItems.forEach(element => {
      idsArray.push(element)
    });
    this.optionIds = idsArray.map(item => item.id)
    let optionId = '{' + this.optionIds + '}'
    this.dataEvent.emit(optionId);
  }

  finalizeAnswer(){
    this.setRank(this.question)
  }

  setRank(question: any) {
    if(!this.finalizedOptionIds && !this.optionIds){
      this.optionIds = question.options.map(item => item.id);
      this.finalizedOptionIds = '{' + this.optionIds + '}'
    }
    //this.spinnerService.on()
    const requestData = {
      'path_id': this.currentPath.id,
      'user_id': this.currentUser.id,
      'schedule_day': this.appState.get('currentScheduleDay'),
      'option_ids': this.finalizedOptionIds,
      'force_question_id': question.id,
      'enabled': question.enabled,
      'is_mandatory': true
    }

    if (!requestData) {
      //this.spinnerService.off();
    } else {
      this.pollService.setOnboardingForceRank(requestData).subscribe(
        (response) => {
          this.appState.set('isRankSwapped', true)
          this.setStats(response.data.stats)
          this.isAnswered = true
          this.cdRef.detectChanges()
          //this.spinnerService.off();
          this.analyticsService.setOnboardingEvent('Onboarding', 'Forcerank completed', 'survey-screen-' + question.id, this.currentPath.id, this.currentClient.id)
        },
        (error) => {
          //this.spinnerService.off();
          console.log(error);
        }
      )
    }
  }

  setStats(stats: any) {
    if (!stats) {
      return
    }

    const total = stats.reduce((sum, n) => sum + n)
    if (!total) {
      return
    }

    this.statsPer = stats.map(n => Math.floor(n * 100 / total))
  }

  async presentPopover(ev) {
    const dynamicText = this.i18n.t('These numbers show the percentage of your colleagues who ranked each option #1.');
    const popover = await this.popover.create({
      component: PopoverComponent,
      translucent: true,
      event: ev,
      mode:'ios',
      showBackdrop: true,
      cssClass: 'force-rank-popover',
      componentProps: {
        dynamicText: dynamicText,
      },
    });
    await popover.present();
    await popover.onDidDismiss();
  }

}
