import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import axios from "axios";
import { ToasterService } from "../toaster.service";
import { SpinnerService } from "../spinner.service";
import { environment } from "src/environments/environment";
import { CordovaService } from "../cordova.service";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { AppState } from "../app.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionService } from "../session/session.service";
import { I18n } from "../i18n.service";
import { HomeUiService } from "../new-design/home-ui.service";
import { Platform } from "@ionic/angular";
import { IosASWebauthenticationSession } from '@ionic-native/ios-aswebauthenticationsession-api/ngx';
import mixpanel from "mixpanel-browser";
interface SignInFormSSO {
  email: string;
  ssoUid: string;

  isSso: boolean;
  changePath: boolean;
  fromMobile: boolean;
  pathGroupId: number;
  pathId: number;
  ssoProvider: string;
}

const SSO_PROVIDER = 'ssoProvider'
@Injectable({
  providedIn: "root",
})
export class SignInService {

  isMobile: any;
  signInFormSSO: SignInFormSSO;
  error: any;
  toast: ToasterService;
  spinner: SpinnerService;
  route: ActivatedRoute;
  router: Router;
  showAuthLoader = false;
  constructor(
    private appState: AppState,
    private sessionService: SessionService,
    private inAppBrowser: InAppBrowser,
    private i18n: I18n,
    private cordovaService: CordovaService,
    private homeUiService: HomeUiService,
    private platform: Platform,
    private iosASWebAuthSession: IosASWebauthenticationSession
  ) {
    this.isMobile = this.appState.get("isMobile");
  }

  initServices(
    toasterService: ToasterService,
    spinnerService: SpinnerService,
    route: ActivatedRoute,
    router: Router,
   
  ) {
    this.toast = toasterService;
    this.spinner = spinnerService;
    this.route = route;
    this.router = router;
  }

  openExxonMobileBrowser(url) {
    if (this.cordovaService.onCordova) {
      if (this.platform.is("ios")) {
        let callbackURLScheme = "com1st90";
        try {
          console.log("sessio started", url);
          this.iosASWebAuthSession
            .start(encodeURIComponent(callbackURLScheme), url)
            .then((response) => {
              this.showAuthLoader = true
               mixpanel.track('ASWebAuthentication Successfully started', {'callbackurl': response})
              console.log("Authentication successful url", response);
              this.getAccessCodeFromURLMobileExxon(response);
            })
            .catch((error) => {
              mixpanel.track('ASWebAuthentication is failed', {'callbackurl': error})
              console.error("Authentication failed", error);
              this.toast.error("Authentication failed");
              this.showAuthLoader = false
            });
        } catch (error) {
          console.error("Error initializing ASWebAuthSession:", error);
          this.toast.error("Error initializing ASWebAuthSession:");
          this.showAuthLoader = false;
        }
      }
    }
  }
  openSAPBrowser(url: string) {
    if (this.cordovaService.onCordova) {
      var ref = this.inAppBrowser.create(url, "_blank", "location=yes");
      ref.on("loadstop").subscribe(async (event) => {
        this.showAuthLoader = true;
        if(localStorage.getItem(SSO_PROVIDER) === 'SAP'){
          this.getCodeAndStateFromUrl(event.url, ref);
        }else if(localStorage.getItem(SSO_PROVIDER) === 'DENONE'){
          this.getAccessCodeFromUrlDenone(event.url, ref);
        }else if(localStorage.getItem(SSO_PROVIDER) === 'OKTA'){
          this.getAccessCodeFromUrlOkta(event.url, ref);
        }else if(localStorage.getItem(SSO_PROVIDER) === 'EXXON'){
          console.log('EXXON event.url :', event.url);
          this.getAccessCodeFromUrlExxon(event.url, ref);
        }else if(localStorage.getItem(SSO_PROVIDER) === 'LULULEMON'){
          console.log('LULULEMON event.url :', event.url);
          this.getAccessCodeFromUrlLululemon(event.url, ref);
        }
      });

      ref.on("loaderror").subscribe(async (event) => {
        this.showAuthLoader = false;
        this.toast.error(this.i18n.t(event.message));
        this.getCodeAndStateFromUrl(event.url, ref);
      });
    }
  }

  getAccessCodeFromURLMobileExxon(callBackurl) {
    const url = new URL(callBackurl);
    console.log('callBackurl :', url);
    let accessToken;
    //const accessToken = url?.searchParams.get("access_token");
    if (callBackurl.includes("access_token=")) {
      let splittedValue = callBackurl?.split("=");
      let unfilteredToken = splittedValue[1]?.split("&");
       accessToken = Array.isArray(unfilteredToken)
        ? unfilteredToken[0]
        : null;
    }
    if (accessToken) {
      localStorage.setItem(environment.EXXON_CODE, accessToken);
       window.location.reload();
    }
    else {
      this.toast.error("access_token not found");
      this.showAuthLoader = false;
    }
  }

  // fetch code and state from redirect url
  getParamsFromURL(
    loading: boolean,
    expired: boolean,
    client: any,
    valid: boolean
  ) {
    this.route.queryParams.subscribe(async (params) => {
      let code = params?.code;
      let state = params?.state;
      let splittedValue = this.router.url?.split('=')
     console.log("query params method is called")
      let unfilteredToken = splittedValue[1]?.split('&')
      let accessToken = Array.isArray(unfilteredToken) ? unfilteredToken[0] : null;

      if (code && state || localStorage.getItem(SSO_PROVIDER) === 'SAP') {
          var userInfo = await this.getUserTokenSAP(code);
          this.signInFormSSO = {
            email: userInfo.data?.mail,
            ssoUid: userInfo.data?.uid,
            isSso: true,
            changePath: null,
            fromMobile: null,
            pathGroupId: 0,
            pathId: 0,
            ssoProvider: localStorage.getItem(SSO_PROVIDER) ? localStorage.getItem(SSO_PROVIDER) : 'undefined',
          };
          this.loginWithSSO(
            this.signInFormSSO,
            loading,
            expired,
            client,
            valid
          );
        
      }else if(accessToken){
        console.log('accessToken :', accessToken);
        let valueToken = splittedValue[2]?.split('&')
        let token = valueToken[0]?.split('&')
        if (localStorage.getItem(SSO_PROVIDER) === 'OKTA') {
          // this.showAuthLoader = true;
          var userInfo = await this.getUserTokenOkta(token[0]);
          this.signInFormSSO = {
            email: userInfo.data?.email,
            ssoUid: userInfo.data?.sub,
            isSso: true,
            changePath: null,
            fromMobile: null,
            pathGroupId: 0,
            pathId: 0,
            ssoProvider: environment.OKTA,
          };
          this.loginWithSSO(
            this.signInFormSSO,
            loading,
            expired,
            client,
            valid
          );
        } else if (localStorage.getItem(SSO_PROVIDER) === 'EXXON') {
          // this.showAuthLoader = true;
           mixpanel.track('Fetch params from URL',{'SSO_provider':localStorage.getItem(SSO_PROVIDER)})
          var userInfo = await this.getUserInfoExxon(accessToken);
          console.log('EXXON userInfo & accessToken:', userInfo, accessToken);
          this.signInFormSSO = {
            email: userInfo.data?.email,
            ssoUid: userInfo.data?.sub,
            isSso: true,
            changePath: null,
            fromMobile: null,
            pathGroupId: 0,
            pathId: 0,
            ssoProvider: environment.EXXON,
          };
          this.loginWithSSO(
            this.signInFormSSO,
            loading,
            expired,
            client,
            valid
          );
        } else if (localStorage.getItem(SSO_PROVIDER) === 'LULULEMON') {
          var userInfo = await this.getUserInfoLululemon(accessToken);
          console.log('LULULEMON userInfo & accessToken :', userInfo, accessToken);
          this.signInFormSSO = {
            email: userInfo.data?.email,
            ssoUid: userInfo.data?.sub,
            isSso: true,
            changePath: null,
            fromMobile: null,
            pathGroupId: 0,
            pathId: 0,
            ssoProvider: environment.LULULEMON,
          };
          this.loginWithSSO(
            this.signInFormSSO,
            loading,
            expired,
            client,
            valid
          );
        }else{
          console.log('client not found & accessToken :', accessToken);
        }
          
        
        
      
      }
    // Commented code for Denone SSO
      // var userInfo = await this.getUserInfoDenone(accessToken);
      // this.signInFormSSO = {
      //   email: userInfo.data?.email,
      //   ssoUid: userInfo.data?.sub,
      //   isSso: true,
      //   changePath: null,
      //   fromMobile: null,
      //   pathGroupId: 0,
      //   pathId: 0,
      //   ssoProvider: environment.DENONE,
      // };
      // this.loginWithSSO(
      //   this.signInFormSSO,
      //   loading,
      //   expired,
      //   client,
      //   valid
      // );
    
    // }
    });
  }

  async getUserInfoSAP(accessToken: string) {
    mixpanel.track("Access token for SAP is stored", { accessToken :accessToken})
    let config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    let body = {};

    try {
      return await axios.post(environment.GET_USER_INFO_SAP, body, config);
    } catch (error) {
      this.spinner.off();
      this.showAuthLoader = false;
    }
  }

  async getUserTokenSAP(code: string) {
    let userInfoResponse = null;
    let username = environment.userSSOSAP;
    let password = environment.secretSSOSAP;
    let body = new URLSearchParams();
             body.set('grant_type', `authorization_code`);
             body.set('redirect_uri', environment.SSO_REDIRECT_URL);
             body.set('code', code);
             body.set('client_id',  environment.clientIdSAP);

    const encodedToken = btoa(`${username}:${password}`);

    let config = {
      withCredentials: false,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Basic " + encodedToken,
      },
      params: {
      },
    };

    try {
      const response = await axios.post(
        environment.GET_TOKEN_SAP,
        body.toString(),
        config
      );
      let token = response.data?.access_token;
      userInfoResponse = await this.getUserInfoSAP(token);
    } catch (error) {
      this.spinner.off();
      this.showAuthLoader = false;
      
    }
    return userInfoResponse;
  }

  async getUserTokenOkta(accessToken: string){
    mixpanel.track("Access token for Okta is stored", { accessToken :accessToken})
    let response = null
    let config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      response = await axios.get(environment.GET_USER_INFO_OKTA, config);
      return response;
    } catch (error) {
      this.spinner.off();
      this.showAuthLoader = false;
    }
  }

  async getUserInfoDenone(accessToken: string) {
    mixpanel.track("Access token for Denone is stored", { accessToken :accessToken})
    let response = null
    let config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    let body = {};

    try {
      response = await axios.post(environment.GET_USER_INFO_DENONE, body, config);
      return response;
    } catch (error) {
      this.spinner.off();
      this.showAuthLoader = false;
    }
  }

  async getUserInfoExxon(accessToken: string) {
    console.log('getUserInfoExxon accessToken :', accessToken);
    mixpanel.track("Access token for EXXONMOBIL is stored", { accessToken :accessToken})
    let response = null
    let config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    let body = {};

    try {
      response = await axios.post(environment.GET_USER_INFO_EXXON, body, config);
      console.log('GET_USER_INFO_EXXON response :', response);
      return response;
    } catch (error) {
      this.showAuthLoader = false;
      console.log('GET_USER_INFO_EXXON error :', error);
      this.spinner.off();
    }
  }

  async getUserInfoLululemon(accessToken: string) {
    mixpanel.track("Access token for Lululemon is stored", { accessToken :accessToken})
    console.log('getUserInfoLululemon accessToken :', accessToken);
    let response = null
    let config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    let body = {};

    try {
      response = await axios.post(environment.GET_USER_INFO_LULULEMON, body, config);
      console.log('GET_USER_INFO_EXXON response :', response);
      return response;
    } catch (error) {
      console.log('GET_USER_INFO_EXXON error :', error);
      this.toast.error(error)
      this.showAuthLoader = false;
      this.spinner.off();
    }
  }

  // async getUserTokenDenone(code: string) {
  //   let userInfoResponse = null;
  //   // let username = 'environment.CLIENTIDDENONE';
  //   // let password = '';
  //   let body = new URLSearchParams({
  //     client_id: environment.CLIENTIDDENONE, //gave the values directly for testing
  //     scope: 'openid',
  //     redirect_uri: environment.SSO_REDIRECT_URL,
  //     grant_type: 'authorization_code',
  //     client_secret: environment.SECREATDENONE,
  //     code: code
  //   });

  //   // const encodedToken = btoa(`${username}:${password}`);

  //   let config = {
  //     // withCredentials: false,
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //       "Access-Control-Allow-Headers": "x-requested-with, x-requested-by",
  //       // "Origin": "http://localhost"
  //     }
  //   };

  //   try {
  //     const response = await axios.post(
  //       environment.GET_TOKEN_DENONE,
  //       body,
  //       config
  //     );
  //     let token = response.data?.access_token;
  //     userInfoResponse = await this.getUserInfoDenone(token);
  //   } catch (error) {
  //     this.spinner.off();
  //     this.toast.error(error.message);
  //   }
  //   return userInfoResponse;
  // }

  // first time will get call after mobile browser close
 async authenticateSSOTokenForLogin(
    loading: boolean,
    expired: boolean,
    client: any,
    valid: boolean
  ) {
    let code = ''
    if(localStorage.getItem(SSO_PROVIDER) === 'SAP'){
      code = localStorage.getItem(environment.SAP_CODE);
    }else if(localStorage.getItem(SSO_PROVIDER) === 'DENONE'){
      code = localStorage.getItem(environment.DENONE_CODE);
    }else if(localStorage.getItem(SSO_PROVIDER) === 'OKTA'){
      code = localStorage.getItem(environment.OKTA_CODE);
    }else if(localStorage.getItem(SSO_PROVIDER) === 'EXXON'){
      code = localStorage.getItem(environment.EXXON_CODE);
    }else if(localStorage.getItem(SSO_PROVIDER) === 'LULULEMON'){
      code = localStorage.getItem(environment.LULULEMON_CODE);
    }

    if (this.isMobile && code!=='null' && code !=="") {
      let signInFormSSO: any
      signInFormSSO = await this.checkSSOProviderAndServeAPI(code);
      console.log('signInFormSSO', this.signInFormSSO);
      mixpanel.track('authenticateSSOtokenForLogin',{'SSO_provider':localStorage.getItem(SSO_PROVIDER)})
      this.loginWithSSO(
        signInFormSSO,
        loading,
        expired,
        client,
        valid
      );
    }
  }



  loginWithSSO(
    signInFormSSO: SignInFormSSO,
    loading: boolean,
    expired: boolean,
    client: any,
    valid: boolean
  ) {
    if (!this.isMobile && signInFormSSO&& localStorage.getItem(SSO_PROVIDER)) {
      mixpanel.track("after web app sso verification done externally", { 'showAuth': this.showAuthLoader })
      this.showAuthLoader =true
    }
    if (signInFormSSO) {
      if (!signInFormSSO.pathGroupId) {
        const activePathId = this.appState.get("activePathId");
        if (activePathId && this.appState.get("changePath")) {
          signInFormSSO.pathId = parseInt(activePathId, 10);
          signInFormSSO.changePath = true;
        }
      }
      signInFormSSO.fromMobile = this.cordovaService.onCordova;
    }

   // this.spinner.on();
    this.sessionService.newSessionSSO(signInFormSSO).subscribe(
      (result) => {
       // this.spinner.off();
        console.log('result :', result);
        loading = false;
        this.routingAfterLogin(signInFormSSO, result, expired, client);
      },
      (errorResponse) => {
       // this.spinner.off();
        valid = false;
        loading = false;
        let messages;
        if (
          typeof errorResponse.graphQLErrors !== "undefined" &&
          errorResponse.graphQLErrors.length > 0
        ) {
          messages = errorResponse.graphQLErrors.map((e) => e.message);
        } else {
          messages = [
            "Server or connection issue. Please check your internet connection.",
          ];
        }
        this.error = messages.join("<br>");
        this.showAuthLoader = false;
        this.toast.error(this.i18n.t(this.error));
      }
    );
  }

  refreshIfMobile(ref: any) {
    if (this.isMobile) {
      ref.close();
      window.location.reload();
      this.spinner.off()
    }
  }

  getCodeAndStateFromUrl(urlString: any, ref: any) {
    if (urlString.includes("code=") && urlString.includes("state=")) {
      let pamasString = urlString.split("?")[1];
      let codeString = pamasString.split("&")[0];
      let stateString = pamasString.split("&")[1];
      let code = codeString.split("=")[1];
      let state = stateString.split("=")[0];
      localStorage.setItem(environment.SAP_CODE, code);
      localStorage.setItem("sap_state", state);
      this.spinner.on()
      this.refreshIfMobile(ref);
    }
  }


  getAccessCodeFromUrlDenone(urlString: any, ref: any) {
    if (urlString.includes("access_token=")) {
      let splittedValue = urlString?.split('=')
      let unfilteredToken = splittedValue[1]?.split('&')
      let accessToken = Array.isArray(unfilteredToken) ? unfilteredToken[0] : null;
      localStorage.setItem(environment.DENONE_CODE, accessToken);
      this.refreshIfMobile(ref);
    }
  }

  getAccessCodeFromUrlOkta(urlString: any, ref: any) {
    if (urlString.includes("access_token=") ) {
      const splittedValue = urlString.split('=');
      let valueToken = splittedValue[2]?.split('&')
      let token = valueToken[0]?.split('&')
      localStorage.setItem(environment.OKTA_CODE, token);
      localStorage.setItem("okta_state", '123');
      this.refreshIfMobile(ref);
    }
  }

  getAccessCodeFromUrlExxon(urlString: any, ref: any) {
    console.log('getAccessCodeFromUrlExxon urlString :', urlString);
    if (urlString.includes("access_token=")) {
      let splittedValue = urlString?.split('=')
      let unfilteredToken = splittedValue[1]?.split('&')
      let accessToken = Array.isArray(unfilteredToken) ? unfilteredToken[0] : null;
      localStorage.setItem(environment.EXXON_CODE, accessToken);
      this.refreshIfMobile(ref);
    }else{
      this.toast.error('access_token not found')
    }
  }

  getAccessCodeFromUrlLululemon(urlString: any, ref: any) {
    console.log('getAccessCodeFromUrlLululemon urlString :', urlString);
    if (urlString.includes("access_token=")) {
      let splittedValue = urlString?.split('=')
      let unfilteredToken = splittedValue[1]?.split('&')
      let accessToken = Array.isArray(unfilteredToken) ? unfilteredToken[0] : null;
      localStorage.setItem(environment.LULULEMON_CODE, accessToken);
      this.refreshIfMobile(ref);
    }
  }

  routingAfterLogin(
    signInFormSSO: SignInFormSSO,
    result: any,
    expired: boolean,
    client: any
  ) {
    if (result.expiredOrRemoved) {
      expired = true;
      client = result.client;
      this.toast.error(this.i18n.t("Client is expired"));
      mixpanel.track("Client is expired", {client: client});
    } else if (result) {
      this.spinner.off();
      localStorage.setItem("lastUserEmail", signInFormSSO["email"]); // LUMBA-982
      let onboaringSurvey = result?.data?.path?.onboardingSurveys?.length > 0 && result?.data.path?.enabledAttributes['onboarding_survey'];
      let isIntroVideo = result?.data.path?.names["isIntroVideo"];
      if (result.showOnboardingSurvey && (onboaringSurvey || isIntroVideo)) {
        this.router.navigate(["/onboarding"], {state: { onboardingSurvey: onboaringSurvey, isIntroVideo: isIntroVideo }});                          
      } else if (result.showCloseout) {
        this.router.navigate(["/closeout"]);
      } else {
        if (result.showOnboardingSurvey && !(result?.data?.path?.onboardingSurveys?.length > 0 || result?.data.path?.names["isIntroVideo"])) {
          this.homeUiService.checkSubpathStatus();
        }
        this.router.navigate(["/main"]);
      }
      this.toast.success(this.i18n.t("You’re in!"));
      mixpanel.track("successfully logged In");
    }else{
      this.toast.error('Login failed');
      mixpanel.track("LogIn failed");
    }
    setTimeout(() => {
      mixpanel.track("Disabled showAuthLoader", { 'AuthScreenstatus':this.showAuthLoader })
      this.showAuthLoader = false;
    }, 400);
    
  }

  async checkSSOProviderAndServeAPI(code: string){
    // this.spinner.on();
      let ssoProvider = localStorage.getItem(SSO_PROVIDER)
      let userInfo = null;
      if(!ssoProvider){ this.toast.error(this.i18n.t('SSO provider not found!')); return false; }

      // check for ssoProvider and call the APIs
      if(localStorage.getItem(SSO_PROVIDER) === 'SAP'){
        this.spinner.off();
        userInfo = await this.getUserTokenSAP(code);
        this.signInFormSSO = {
          email: userInfo.data?.mail,
          ssoUid: userInfo.data?.uid,
          isSso: true,
          changePath: null,
          fromMobile: null,
          pathGroupId: 0,
          pathId: 0,
          ssoProvider: localStorage.getItem(SSO_PROVIDER),
        };
      }else if(localStorage.getItem(SSO_PROVIDER) === 'DENONE'){
        userInfo = await this.getUserInfoDenone(code);
        this.signInFormSSO = {
          email: userInfo.data?.email,
          ssoUid: userInfo.data?.sub,
          isSso: true,
          changePath: null,
          fromMobile: null,
          pathGroupId: 0,
          pathId: 0,
          ssoProvider: localStorage.getItem(SSO_PROVIDER),
        };
      }else if(localStorage.getItem(SSO_PROVIDER) === 'OKTA'){
        userInfo = await this.getUserTokenOkta(code);
        this.signInFormSSO = {
          email: userInfo.data?.email,
          ssoUid: userInfo.data?.sub,
          isSso: true,
          changePath: null,
          fromMobile: null,
          pathGroupId: 0,
          pathId: 0,
          ssoProvider: localStorage.getItem(SSO_PROVIDER),
        };
      }else if(localStorage.getItem(SSO_PROVIDER) === 'EXXON'){
        userInfo = await this.getUserInfoExxon(code);
        console.log('checkSSOProviderAndServeAPI EXXON userInfo :', userInfo);
        this.signInFormSSO = {
          email: userInfo.data?.email,
          ssoUid: userInfo.data?.sub,
          isSso: true,
          changePath: null,
          fromMobile: null,
          pathGroupId: 0,
          pathId: 0,
          ssoProvider: localStorage.getItem(SSO_PROVIDER),
        };
      }else if(localStorage.getItem(SSO_PROVIDER) === 'LULULEMON'){
        console.log('checkSSOProviderAndServeAPI LULULEMON userInfo :', userInfo);
        userInfo = await this.getUserInfoLululemon(code);
        this.signInFormSSO = {
          email: userInfo.data?.email,
          ssoUid: userInfo.data?.sub,
          isSso: true,
          changePath: null,
          fromMobile: null,
          pathGroupId: 0,
          pathId: 0,
          ssoProvider: localStorage.getItem(SSO_PROVIDER),
        };
      }
      localStorage.setItem(environment.SAP_CODE, null);
      localStorage.setItem(environment.DENONE_CODE, null);
      localStorage.setItem(environment.OKTA_CODE, null);
      localStorage.setItem(environment.EXXON_CODE, null);
      localStorage.setItem(environment.LULULEMON_CODE, null);
      console.log('this.signInFormSSO;', this.signInFormSSO);

      return this.signInFormSSO;
  }

  validateMail(userEmail: string){
    // below regex validate email only
    // const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const regex = /^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|[a-zA-Z0-9]+)$/       // this regex validates email & login id as well
    const match = userEmail.match(regex);
    return match
  }

  removeWhiteSpaces(inputText){
    // let trimmedText = inputText.replace(/\s+/g, ''); // Removes all white spaces
    let trimmedText = inputText.trim();
    return trimmedText
  }

  validateLoginId(input: string): boolean {
    const digitRegex = /^\d+$/;
    return digitRegex.test(input);
  }

}
