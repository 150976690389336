import {Base} from '../shared/base'
import {TextScreen} from '../text-screen/text-screen.model'
import {TextHomepage} from '../text-homepage/text-homepage.model'

export enum Tag {
  Action,
  Story,
  Reflection
}
export class InoutScreen extends Base {

  init() {
    this._fields = ['id', 'startDay', 'endDay', 'homepageTitle', 'homepageSubtext', 'preScreens',
      'postScreens', 'enabled', 'etime', 'stime', 'emailTeaser', 'libraryImage','tag'
    ]

    this.preScreens = []
    this.postScreens = []
  }

  afterInit() {
    this.preScreens = this.preScreens || []
    this.postScreens = this.postScreens || []
  }

  public id: number

  public startDay: number
  public endDay: number
  public stime: string
  public etime: string
  public enabled: boolean
  public homepageTitle: TextHomepage
  public homepageSubtext: TextHomepage
  public preScreens: TextScreen[]
  public postScreens: TextScreen[]
  public emailTeaser: string
  public libraryImage: string
  public maxLength: number
  public draft: boolean
  public tag: Tag
}
