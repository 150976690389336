import { APP_INITIALIZER, ErrorHandler, NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA  } from '@angular/core';
import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';

import { IonicModule, IonicRouteStrategy, LoadingController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { HttpClientModule, HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppState } from './app.service';
import { ThemeService, ThemeServiceFactory } from './theme/theme.service';
import { NotificationService } from './notification/notification.service';
import { SessionService } from './session/session.service';
import { HomePageService } from './pages/home/home-page.service';
import { DocumentService } from './document/document.service';
import { PathGroupService } from './path-group/path-group.service';
import { PathService } from './path/path.service';
import { ChecklistService } from './checklist/checklist.service';
import { HabitService } from './habit/habit.service';
import { UserService } from './user/user.service';
import { ClientService } from './client/client.service';
import { QuestionService } from './question/question.service';
import { ReplyService } from './reply/reply.service';
import { BadgeService } from './badge/badge.service';
import { CustomBadgeService } from './badge/custom-badge.service';
import { RewardListComponent } from './reward/reward-list.component'
import { RewardService } from './reward/reward.service';
import { PollService } from './poll/poll.service';
import { ContentSurveyService } from './content-survey/content-survey.service';
import { WalletService } from './wallet/wallet.service';
import { TranslationService } from './translation/translation.service';
import { SurveyService } from './survey/survey.service';
import { CordovaService } from './cordova.service';
import { NamesPipe } from './names.pipe';
import { I18n } from './i18n.service';
import { I18nPipe } from './i18n.pipe';

import { RoutineService } from './daily-content-v2/routine.service';
import { SingleSignOnService } from './single-sign-on.service';
import { AppConstantsService } from './app-constants.service';
import { WINDOW_PROVIDERS } from './window.service';
import { environment } from 'src/environments/environment';
import { onError } from "@apollo/client/link/error";
// import { InMemoryCache } from '@apollo/client/core';
import { ApolloLink,InMemoryCache } from "@apollo/client/core";
import { HomeV5Service } from './pages/home-v5/home-v5.service';
import { StatService } from './stat/stat.service';
import { DailyParticipationService } from './daily-participation/daily-participation.service';
import { JournalService } from './pages/journal/journal.service';
import { DailyStepsService } from './daily-steps/daily-steps.service';
import { MobileChatServiceService } from './pages/mobile-chat-board/mobile-chat-service.service';
import { RoutineV2Service } from './routine-v2/routine-v2.service';
import { SignInComponent } from './sign-in/sign-in.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignUpComponent } from './sign-up/sign-up.component';
import { FaqComponent } from './faq/faq.component';
import { AvatarComponent } from './avatar/avatar.component'
import { PictureUploaderComponent } from './picture-uploader/picture-uploader.component';
import { OnboardingSurveyComponent } from './onboarding-survey/onboarding-survey.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { AuthService } from './auth/auth.service';
import { AuthInterceptor } from './auth/auth.interceptor';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { LinearScaleComponent } from './linear-scale/linear-scale.component';
import { MatIconModule } from '@angular/material/icon'
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog'
import { MatRadioModule } from '@angular/material/radio'
import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatDividerModule } from '@angular/material/divider'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatListModule } from '@angular/material/list'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatSliderModule } from '@angular/material/slider'
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { FileUploadModule } from 'ng2-file-upload'
import { YaFileUploaderComponent } from './shared/ya-file-uploader/ya-file-uploader.component'
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { UserComponent } from './user/user.component';
import { DailyStepsComponent } from './daily-steps/daily-steps.component';
import { HomeV6Component } from './pages/home-v6/home-v6.component';
import { ScreenProgressComponent } from './screen-progress/screen-progress.component';
import { JournalComponent } from './pages/journal/journal.component';
import { MainComponent } from './main/main.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { FooterComponent } from './shared/footer/footer.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { AlertBoxDialogComponent } from './alert-box-dialog/alert-box-dialog.component';
import { AlertNotificationUpdateComponent } from './alert-notification-update/alert-notification-update.component';
import { AlertReviewRoutineComponent } from './alert-review-routine/alert-review-routine.component';
import { AlertCommitCalenderComponent } from './alert-commit-calender/alert-commit-calender.component';
import { AlertWebCalendarComponent } from './alert-web-calendar/alert-web-calendar.component';
import { AlertAnimationComponent } from './alert-animation/alert-animation.component';
import { AlertConfirmNotificationComponent } from './alert-confirm-notification/alert-confirm-notification.component';
import { ChangeSubpathAlertComponent } from './change-subpath-alert/change-subpath-alert.component';
import { DailyParticipationChartComponent } from './daily-participation-chart/daily-participation-chart.component';
import { HabitListModalComponent } from './habit-list-modal/habit-list-modal.component';
import { AlertWebactionCalendarComponent } from './alert-webaction-calendar/alert-webaction-calendar.component';
import { AlertRatingRoutineComponent } from './alert-rating-routine/alert-rating-routine.component';
import { AlertIamInComponent } from './alert-iam-in/alert-iam-in.component';
import { DocumentsPageComponent } from './pages/document/documents-page.component';
import { DocumentComponent } from './document/document.component';
import { DocumentListComponent } from './document/document-list.component';
import { StatsPageComponent } from './pages/stat/stats-page.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { StatListComponent } from './stat/stat-list.component';
import { StatComponent } from './stat/stat.component';
import { BadgeComponent } from './badge/badge.component';
import { BadgeCategoryComponent } from './badge/badge-category.component'
import { ProfileBadgeComponent } from './profile-badge/profile-badge.component';
import { ProfileBadgeIconComponent } from './profile-badge-icon/profile-badge-icon.component';
import { ProfileBadgeCategoryComponent } from './profile-badge-category/profile-badge-category.component';
import { BadgeListComponent } from './badge/badge-list.component';
import { BadgeSvgComponent } from './badge/badge-svg.component';
import { BadgeIconSvgComponent } from './badge/badge-icon-svg.component';
import { MyCommitmentsComponent } from './my-commitments/my-commitments.component';
import { SubRoutineInfoComponent } from './my-commitments/sub-routine-info/sub-routine-info.component';
import { SingleHabitInfoComponent } from './my-commitments/single-habit-info.component';
import { ToastrModule } from 'ngx-toastr';
import { AlertActionCompleteComponent } from './alert-action-complete/alert-action-complete.component';
import { AlertRepLogComponent } from './alert-rep-log/alert-rep-log.component';
import { AlertAskRoutineQuestionComponent } from './alert-ask-routine-question/alert-ask-routine-question.component';
import { AlertGiveFeedbackCommitmentComponent } from './alert-give-feedback-commitment/alert-give-feedback-commitment.component';
import { AlertRepeatRoutineComponent } from './alert-repeat-routine/alert-repeat-routine.component';
import { AlertEditLevelsComponent } from './alert-edit-levels/alert-edit-levels.component';
import { AlertOptRoutineComponent } from './alert-opt-routine/alert-opt-routine.component';
import { AchievementsPageComponent } from './pages/achievement/achievements-page.component';
import { QuestionComponent } from './question/question.component';
import { QuestionListComponent } from './question/question-list.component';
import { ChatBoardComponent } from './chat-board/chat-board.component';
import { MobileChatBoardComponent } from './pages/mobile-chat-board/mobile-chat-board.component';
import { AlertUserLikeListComponent } from './alert-user-like-list/alert-user-like-list.component';
import { FullScreenFeedComponent } from './full-screen-feed/full-screen-feed.component';
import { FullScreenPostComponent } from './full-screen-post/full-screen-post.component';
import { FullScreenRoutineComponent } from './full-screen-routine/full-screen-routine.component';
import { EditRoutineComponent } from './edit-routine/edit-routine.component';
import { DailyContentV2Component } from './daily-content-v2/daily-content-v2.component';
import { DocumentV2Component } from './document-v2/document-v2.component';
import { AlertRoutineAnimComponent } from './alert-routine-anim/alert-routine-anim.component';
import { AlertCommitRoutineComponent } from './alert-commit-routine/alert-commit-routine.component';
import { AlertAskRoutineComponent } from './alert-ask-routine/alert-ask-routine.component';
import { RoutineCalendarComponent } from './routine-v2/routine-calendar/routine-calendar/routine-calendar.component';
import { AlertRepeatEventComponent } from './alert-repeat-event/alert-repeat-event.component';
import { TextScreenComponent } from './text-screen/text-screen.component';
import { HabitV2Component } from './habit-v2/habit-v2.component';
import { PollV2Component } from './poll-v2/poll-v2.component';
import { ForceRankComponent } from './force-rank/force-rank.component';
import { ContentSurveyComponent } from './content-survey/content-survey.component';
import { QuestionV2Component } from './question-v2/question-v2.component';
import { ReplyComponent } from './reply/reply.component';
import { CommentComponent } from './comment/comment.component'
import { ActivityComponent } from './routine-v2/routine-options/activity.component';
import { DayTimeComponent } from './routine-v2/routine-options/day-time.component';
import { LocationComponent } from './routine-v2/routine-options/location.component';
import { OtherComponent } from './routine-v2/routine-options/other.component';
import { PersonComponent } from './routine-v2/routine-options/person.component';
import { HabitLoopComponent } from './routine-v2/habit-loop.component';
import { MeetCueComponent } from './routine-v2/meet-cue.component';
import { RoutineSubLevelComponent } from './routine-v2/routine-sublevel.component';
import { RoutineV2Component } from './routine-v2/routine-v2.component';
import { AlertHabitLoopInfoComponent } from './alert-habit-loop-info/alert-habit-loop-info.component';
import { AttachmentUploaderComponent } from './shared/attachment-uploader/attachment-uploader.component';
import { WeekendCalendarComponent } from './routine-v2/weekend-calendar.component';
import { AlertUnlockStepComponent } from './alert-unlock-step/alert-unlock-step.component';
import { WeldonescreenComponent } from './weldonescreen/weldonescreen.component';
import { MobileWeldonescreenComponent } from './mobile-weldonescreen/mobile-weldonescreen.component';
import { SupportBubbleComponent } from './support-bubble/support-bubble.component';
import { AlertSupportComponent } from './alert-support/alert-support.component';
import { AlertUserFeedbackComponent } from './alert-user-feedback/alert-user-feedback.component';
import { BarRatingModule } from 'ngx-bar-rating';
import { ToasterService } from './toaster.service';
import { WrongLoginLinkComponent } from './pages/wrong-login-link/wrong-login-link.component';
import { AlertEndRoutineComponent } from './alert-end-routine/alert-end-routine.component';
import { AlertCalendarOpenComponent } from './alert-calendar-open/alert-calendar-open.component';
import { EndRoutineComponent } from './end-routine/end-routine.component';
import { PathSelectComponent } from './path-select/path-select.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { TestBoardComponent } from './pages/test-board/test-board.component';
import { CloseoutSurveyComponent } from './closeout-survey/closeout-survey.component';
import { CloseoutSurveyWelcomeComponent } from './closeout-survey/closeout-survey-welcome.component';
import { NotificationsSettingsComponent } from './pages/notifications-settings/notifications-settings.component';
import { AlertNotifyComponent } from './alert-notify/alert-notify.component';
import { SpinnerService } from './spinner.service';
import { AlertProfilePictureComponent } from './alert-profile-picture/alert-profile-picture.component';
import { RatingAlertBoxComponent } from './rating-alert-box/rating-alert-box.component';
import { PtrContainerComponent } from './ptr-container/ptr-container.component';
import { SurveyLinearScaleComponent } from './survey-linear-scale/survey-linear-scale.component';
import { SurveyForceRankComponent } from './survey-force-rank/survey-force-rank.component';
import { PostFeedComponent } from './post-feed/post-feed.component';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { NotificationTimeSelectComponent } from './notification-time-select/notification-time-select.component';
import { PushNotificationService } from './push-notification.service';
import { HeaderComponent } from './shared/header/header.component';
import { SideNavBarComponent } from './side-nav-bar/side-nav-bar.component';
import { CustomHammerConfig } from './custom-hammer-config';
import { CookieService } from 'ngx-cookie-service';
import { ActionPointsIconSvgComponent } from './action-points/action-points-icon-svg.component';
import { AppStepsDesktopComponent } from './app-steps-desktop/app-steps-desktop.component';
import { MtrToggleComponent } from './shared/mtr-toggle/mtr-toggle.component';
import { AllPathsComponent } from './all-paths/all-paths.component';
import { SwiperModule } from 'swiper/angular'
import { CommentListModalComponent } from './comment-list-modal/comment-list-modal.component';
import { CompletedReviewModalComponent } from './completed-review-modal/completed-review-modal.component';
import { FeedbackComponentComponent } from './components/commitments/feedback-component/feedback-component.component';
import { MyActionComponentComponent } from './components/commitments/my-action-component/my-action-component.component';
import { FeedbackFormComponent } from './components/commitments/feedback-form/feedback-form.component';
import { HabitCommunityComponent } from './components/community/habit-community/habit-community.component';
import { ActionCommunityComponent } from './components/community/action-community/action-community.component';
import { QuestionCommunityComponent } from './components/community/question-community/question-community.component';
import { PostCommunityComponent } from './components/community/post-community/post-community.component';
import { LeaderboardCardComponent } from './leaderboard-card/leaderboard-card.component';
import { HeaderTabsComponent } from './header-tabs/header-tabs.component';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { TextToSpeechAdvanced } from '@awesome-cordova-plugins/text-to-speech-advanced/ngx';
import { DayPartSelectComponent } from './day-part-select/day-part-select.component';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';
import { FilePath } from '@awesome-cordova-plugins/file-path/ngx';
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx';
import { TopHeaderComponent } from './pages/home-v6/home-components/top-header.component';
import { LeftSideBarComponent } from './pages/home-v6/home-components/left-side-bar.component';
import { HomeContentComponent } from './pages/home-v6/home-components/home-content.component';
import { WebFaqComponent } from './web-faq/web-faq.component';
import { MentionModule } from 'angular-mentions';
import { CommonHeaderComponent } from './common-header/common-header.component';
import { UserPublicProfileComponent } from './components/user-public-profile/user-public-profile.component';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { UtilityService } from './common-services/utility.service';
import { SafeHtml } from './safehtml.pipe';
import { ErrorhandlingService } from './error-handler';
import { DatePipe } from '@angular/common';
import { CohortService } from './cohorts/cohort.service';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Market } from '@awesome-cordova-plugins/market/ngx';
import { MyLibraryComponent } from './my-library/my-library.component';
import { AlertAddNotesComponent } from './alert-add-notes/alert-add-notes.component';
import { LibraryService } from './my-library/library.service';
import { FullScreenStepComponent } from './my-library/full-screen-step.component';
import { FullScreenDocumentComponent } from './my-library/full-screen-document.component';
import { AlertEditNoteComponent } from './alert-edit-note/alert-edit-note.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CommonModule } from '@angular/common';
import { FullScreenAudioComponent } from './full-screen-audio/full-screen-audio.component';
import { FullScreenVideoComponent } from './full-screen-video/full-screen-video.component';
import { AlertDeletePostComponent } from './alert-delete-post/alert-delete-post.component';
import { AlertPlayIntroComponent } from './alert-play-intro/alert-play-intro.component';
import { StreamingMedia } from '@ionic-native/streaming-media/ngx';
// import { AngMusicPlayerModule } from 'ang-music-player';
import { AlertDeleteUserComponent } from './alert-delete-user/alert-delete-user.component';
import { AlertInputPasswordComponent } from './alert-input-password/alert-input-password.component';
import { DeletionPageComponent } from './deletion-page/deletion-page.component';
import { YoutubeVideoPlayer } from '@awesome-cordova-plugins/youtube-video-player/ngx';
import { ToDoListComponent } from './to-do-list/to-do-list.component';
import { FullTodoInfoComponent } from './full-todo-info/full-todo-info.component';
import { ActionRescheduleComponent } from './to-do-list/action-reschedule/action-reschedule.component';
import { AlertUnlockLevelComponent } from './alert-unlock-level/alert-unlock-level.component';
import { ReversePipe } from './reverse.pipe';
import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { DocumentViewer } from '@awesome-cordova-plugins/document-viewer/ngx';
 import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { InstepRepliesComponent } from './instep-replies/instep-replies.component';
import mixpanel from 'mixpanel-browser';
import { QuestionCommentComponent } from './question-comment/question-comment.component';
import { CommentsListWebComponent } from './components/commitments/comments-list-web/comments-list-web.component';
import { HabitCommentListWebComponent } from './components/commitments/habit-comment-list-web/habit-comment-list-web.component';
import { SkeletonListComponent } from './shared/skeleton-list/skeleton-list.component';
import { ReflectionComponent } from './reflection/reflection.component';
import { FullScreenReflectionComponent } from './my-library/full-screen-reflection/full-screen-reflection.component';
import { ReflectionService } from './my-library/full-screen-reflection/reflection.service';
import { RewardComponent } from './reward/reward.component';
import { AlertDeleteReflectionComponent } from './alert-delete-reflection/alert-delete-reflection.component';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { DynamicCircleComponent } from './dynamic-circle/dynamic-circle.component';
import { PopoverComponent } from './shared/popover/popover.component';
import { SurveyMcqComponent } from './survey/survey-mcq/survey-mcq.component';
import { AlertCreateAccountComponent } from './alert-create-account/alert-create-account.component';
import { YourProgressComponent } from './new-design/your-progress/your-progress.component';
import { NewDesignModule } from './new-design/new-design.module';
import { HomeSheetModalComponent } from './new-design/home-sheet-modal/home-sheet-modal.component';
import { UpdatedHomeComponent } from './new-design/updated-home/updated-home.component';
import { YourPathComponent } from './new-design/your-path/your-path.component';
import { DailyStepsTrackerNewComponent } from './new-design/daily-steps-tracker-new/daily-steps-tracker-new.component';
import { HomeWeeklyTrackerComponent } from './new-design/home-weekly-tracker/home-weekly-tracker.component';
import { HomeUiService } from './new-design/home-ui.service';
import { HamburgerMenuComponent } from './new-design/hamburger-menu/hamburger-menu.component';
import { AnimationsService } from './shared/animations.service';
import { NativeService } from './services/native.service';
import { SignupPopoverComponent } from './signup-popover/signup-popover.component';
import { AlertWrongCodeComponent } from './alert-wrong-code/alert-wrong-code.component';
import { AlertSelectPathComponent } from './alert-select-path/alert-select-path.component';
import { DailyStepsContentModalComponent } from './new-design/daily-steps-content-modal/daily-steps-content-modal.component';
import { FooziAiConciergeComponent } from './new-design/foozi-ai-concierge/foozi-ai-concierge.component';
import { FooziAiConciergeService } from './new-design/foozi-ai-concierge.service';
import { FooziAiLibraryPopupComponent } from './new-design/foozi-ai-library-popup/foozi-ai-library-popup.component';
import { OnboardingLearningNotificationComponent } from './onboarding-learning-notification/onboarding-learning-notification.component';
import { NotificationConfirmationPopupComponent } from './notification-confirmation-popup/notification-confirmation-popup.component';
// import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation/ngx";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { IosASWebauthenticationSession } from "@ionic-native/ios-aswebauthenticationsession-api/ngx";
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { StripHtmlPipe } from './strip-html.pipe';
import { PlyrModule } from '@makiwin/ngx-plyr';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { ReoptHabitLevelModalComponent } from './reopt-habit-level-modal/reopt-habit-level-modal.component';
import { ActionHubComponent } from './action-hub/action-hub.component';

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  declarations: [
    ReversePipe,
    NamesPipe,
    RewardListComponent,
    FullScreenAudioComponent,
    FullScreenVideoComponent,
    AppComponent,
    SignInComponent,
    SignUpComponent,
    FaqComponent,
    SurveyMcqComponent,
    PictureUploaderComponent,
    OnboardingSurveyComponent,
    UpdatedHomeComponent, 
    HomeSheetModalComponent,
    I18nPipe,
    SafeHtml,
    TimePickerComponent,
    LinearScaleComponent,
    ImageUploaderComponent,
    UserComponent,
    HabitCommentListWebComponent,
    DailyStepsComponent,
    HomeV6Component,
    ScreenProgressComponent,
    JournalComponent,
    MainComponent,
    MainNavComponent,
    EditRoutineComponent,
    FooterComponent,
    SkeletonListComponent,
    AlertBoxDialogComponent,
    AlertNotificationUpdateComponent,
    AlertReviewRoutineComponent,
    AlertCommitCalenderComponent,
    AlertWebCalendarComponent,
    AlertAnimationComponent,
    AlertConfirmNotificationComponent,
    ChangeSubpathAlertComponent,
    DailyParticipationChartComponent,
    HabitListModalComponent,
    AlertWebactionCalendarComponent,
    AlertRatingRoutineComponent,
    AlertIamInComponent,
    DocumentsPageComponent,
    DocumentComponent,
    DocumentListComponent,
    StatsPageComponent,
    SettingsComponent,
    LeaderboardComponent,
    StatListComponent,
    StatComponent,
    BadgeComponent,
    BadgeCategoryComponent,
    BadgeListComponent,
    BadgeSvgComponent,
    BadgeIconSvgComponent,
    AvatarComponent,
    MyCommitmentsComponent,
    MyLibraryComponent,
    SubRoutineInfoComponent,
    SingleHabitInfoComponent,
    AlertBoxDialogComponent,
    ChangeSubpathAlertComponent,
    AlertNotifyComponent,
    AlertIamInComponent,
    AlertRatingRoutineComponent,
    AlertUserLikeListComponent,
    AlertProfilePictureComponent,
    AlertEndRoutineComponent,
    AlertCalendarOpenComponent,
    AlertWebCalendarComponent,
    AlertWebactionCalendarComponent,
    AlertUserFeedbackComponent,
    AlertSupportComponent,
    AlertActionCompleteComponent,
    AlertRepLogComponent,
    AlertAskRoutineComponent,
    AlertHabitLoopInfoComponent,
    AlertAskRoutineQuestionComponent,
    AlertGiveFeedbackCommitmentComponent,
    AlertRepeatRoutineComponent,
    AlertUnlockStepComponent,
    AlertEditLevelsComponent,
    AlertNotificationUpdateComponent,
    AlertReviewRoutineComponent,
    AlertAnimationComponent,
    AlertConfirmNotificationComponent,
    RoutineCalendarComponent,
    AlertCommitRoutineComponent,
    AlertOptRoutineComponent,
    AlertUserLikeListComponent,
    AlertRepeatEventComponent,
    AlertRoutineAnimComponent,
    AlertCommitCalenderComponent,
    WeekendCalendarComponent,
    AchievementsPageComponent,
    QuestionComponent,
    QuestionListComponent,
    ChatBoardComponent,
    MobileChatBoardComponent,
    ReplyComponent,
    CommentComponent,
    AttachmentUploaderComponent,
    FullScreenFeedComponent,
    FullScreenPostComponent,
    FullScreenRoutineComponent,
    AlertRepeatEventComponent,
    AlertRoutineAnimComponent,
    AlertCommitCalenderComponent,
    WeekendCalendarComponent,
    DailyContentV2Component,
    DailyStepsContentModalComponent,
    YaFileUploaderComponent,
    DocumentV2Component,
    TextScreenComponent,
    EditProfileComponent,
    HabitV2Component,
    PollV2Component,
    ForceRankComponent,
    ContentSurveyComponent,
    QuestionV2Component,
    ReplyComponent,
    ActivityComponent,
    ProfileBadgeComponent,
    ProfileBadgeIconComponent,
    ProfileBadgeCategoryComponent,
    DayTimeComponent,
    LocationComponent,
    OtherComponent,
    PersonComponent,
    HabitLoopComponent,
    MeetCueComponent,
    RoutineSubLevelComponent,
    RoutineV2Component,
    AttachmentUploaderComponent,
    WeldonescreenComponent,
    MobileWeldonescreenComponent,
    SupportBubbleComponent,
    AlertSupportComponent,
    AlertUserFeedbackComponent,
    WrongLoginLinkComponent,
    EndRoutineComponent,
    PathSelectComponent,
    VerifyEmailComponent,
    TestBoardComponent,
    CloseoutSurveyComponent,
    CloseoutSurveyWelcomeComponent,
    NotificationsSettingsComponent,
    RatingAlertBoxComponent,
    PtrContainerComponent,
    SurveyLinearScaleComponent,
    SurveyForceRankComponent,
    PostFeedComponent,
    NotificationTimeSelectComponent,
    HeaderComponent,
    SideNavBarComponent,
    ActionPointsIconSvgComponent,
    AppStepsDesktopComponent,
    AllPathsComponent,
    MtrToggleComponent,
    MtrToggleComponent,
    CommentListModalComponent,
    CompletedReviewModalComponent,
    FeedbackComponentComponent,
    MyActionComponentComponent,
    FeedbackFormComponent,
    HabitCommunityComponent,
    ActionCommunityComponent,
    QuestionCommunityComponent,
    CommentsListWebComponent,
    PostCommunityComponent,
    LeaderboardCardComponent,
    HeaderTabsComponent,
    DayPartSelectComponent,
    TopHeaderComponent,
    LeftSideBarComponent,
    HomeContentComponent,
    WebFaqComponent,
    CommonHeaderComponent,
    UserPublicProfileComponent,
    AlertAddNotesComponent,
    FullScreenStepComponent,
    FullScreenDocumentComponent,
    AlertEditNoteComponent,
    AlertDeletePostComponent,
    AlertPlayIntroComponent,
    AlertDeleteUserComponent,
    AlertInputPasswordComponent,
    DeletionPageComponent,
    ToDoListComponent,
    FullTodoInfoComponent,
    ActionRescheduleComponent,
    AlertUnlockLevelComponent,
    InstepRepliesComponent,
    RewardComponent,
    QuestionCommentComponent,
    DynamicCircleComponent,
    ReflectionComponent,
    PopoverComponent,
    FullScreenReflectionComponent,
    AlertDeleteReflectionComponent,
    AlertCreateAccountComponent,
    YourProgressComponent,
    YourPathComponent,
    DailyStepsTrackerNewComponent,
    HamburgerMenuComponent,
    HomeWeeklyTrackerComponent,
    DailyStepsContentModalComponent,
    AlertWrongCodeComponent,
    SignupPopoverComponent,
    AlertWrongCodeComponent,
    AlertSelectPathComponent,
    FooziAiConciergeComponent,
    FooziAiLibraryPopupComponent,
    OnboardingLearningNotificationComponent,
    NotificationConfirmationPopupComponent,
    ReoptHabitLevelModalComponent,
    StripHtmlPipe,
    ActionHubComponent
  ],
  imports: [
    // AngMusicPlayerModule,
    CommonModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ApolloModule,
    NgxExtendedPdfViewerModule,
    // HttpLinkModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    FileUploadModule,
    // AngularSvgIconModule,
    MatDialogModule,
    MatRadioModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatRippleModule,
    MentionModule,
    // MatProgressSpinnerModule,
    MatCheckboxModule,
    BarRatingModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatExpansionModule,
    MatBottomSheetModule,
    MatDividerModule,
    MatSidenavModule,
    MatListModule,
    MatTooltipModule,
    MatTabsModule,
    MatSliderModule,
    // NgxSpinnerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    HammerModule,
    SwiperModule,
    Ng2SearchPipeModule,
    ToastrModule.forRoot({
      timeOut: 3000
    }),
    // NgxLinkifyjsModule.forRoot(),
    NgCircleProgressModule.forRoot({}),
    RouterModule.forRoot([]),
    PlyrModule,
  ],
  providers: [
    YoutubeVideoPlayer,
    StreamingMedia,
    LoadingController,
    DatePipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AppState,
    Clipboard,
    ThemeService,
    {
      provide: APP_INITIALIZER,
      useFactory: ThemeServiceFactory,
      deps: [ThemeService],
      multi: true,
    },
    AuthGuardService,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: CustomHammerConfig
    },
    { provide: ErrorHandler, useClass: ErrorhandlingService },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' }
    },
    SessionService,
    CookieService,
    HomePageService,
    DocumentService,
    PathGroupService,
    PathService,
    ChecklistService,
    HabitService,
    UserService,
    ClientService,
    QuestionService,
    ReplyService,
    BadgeService,
    CustomBadgeService,
    RewardListComponent,
    RewardComponent,
    RewardService,
    HomeV5Service,
    StatService,
    DailyParticipationService,
    PollService,
    ContentSurveyService,
    JournalService,
    SpinnerService,
    WalletService,
    TranslationService,
    SurveyService,
    NotificationService,
    CordovaService,
    DailyStepsService,
    MobileChatServiceService,
    NamesPipe,
    I18n,
    I18nPipe,
    SafeHtml,
    RoutineService,
    SingleSignOnService,
    RoutineV2Service,
    AppConstantsService,
    WINDOW_PROVIDERS,
    MatDatepickerModule,
    MatNativeDateModule,
    ToasterService,
    UtilityService,
    Calendar,
    InAppBrowser,
    StatusBar,
    TextToSpeechAdvanced,
    SocialSharing,
    Camera,
    Deeplinks,
    PushNotificationService,
    Keyboard,
    File,
    WebView,
    FilePath,
    Chooser,
    FirebaseX,
    CohortService,
    Market,
    AppVersion,
    LibraryService,
    Badge,
    FileReader,
    FileOpener,
    DocumentViewer,
    FileTransfer,
    ReflectionService,
    EmailComposer,
    NewDesignModule,
    HomeUiService,
    AnimationsService,
    IosASWebauthenticationSession,
    NativeService,
    FooziAiConciergeService,
    DailyContentV2Component,
    DailyStepsContentModalComponent,
    ScreenOrientation,
    SplashScreen,
    AndroidPermissions
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(
    private apollo: Apollo,
    private httpLink: HttpLink,
    private appState: AppState,
    private spinnerService: SpinnerService
  ) {
    const http = httpLink.create({ uri: environment.graphQLUrl, withCredentials: true });

    const authMiddleware = new ApolloLink((operation, forward) => {
      // add the authorization to the headers
      const headers = new HttpHeaders();
      headers.set('Authorization', localStorage.getItem('token'));
      if (localStorage.getItem('token')) {
        operation.setContext({
          headers: headers
        });
      }
      return forward(operation);
    });

    const errorHandler = onError((errorResponse) => {
      console.log(errorResponse);
      const { graphQLErrors, networkError } = errorResponse;
      spinnerService.off()
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
          console.log(`Graphql error: ${message}`);
            mixpanel.track('GraphQl Error', {graphQLError:message})
        });
      }

      if (networkError) {
        console.log(`Network error: ${networkError.message}`);
        mixpanel.track('Network Error', {networkError:networkError.message})
      }
      // let messages = errorResponse.graphQLErrors.map(e => e.message)
      // toasterService.error(messages.join('<br>'), 'Error', {enableHtml: true})
    });

    // const cache = new InMemoryCache({
    //   dataIdFromObject: object => {
    //     switch (object.__typename) {
    //       case 'foo': return object.key; // use `key` as the primary key
    //       case 'bar': return object.blah; // use `blah` as the priamry key
    //       default: return object.id || object._id
    //     }
    //   }
    // });

    apollo.create({
      link: ApolloLink.from([authMiddleware, errorHandler, http]),
      cache: new InMemoryCache(),
      connectToDevTools: true
    });
    mixpanel.init(environment.mixPanelToken);
  }
}
