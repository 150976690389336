import { Base } from "../shared/base"

import { Document } from '../document/document.model'

export class PathDocument extends Base {

  init() {
    this._fields = ['id', 'pathId', 'starts', 'expires', 'enabled', 'document']

    this._nestedFields = ['document']
  }

  public id: number
  public pathId: number
  public starts: string
  public expires: string
  public enabled: boolean
  public document: Document
}
