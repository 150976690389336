import { Base } from '../shared/base'

import { Badge } from '../badge/badge.model'

export class UserBadge extends Base {
  //
  init() {
    this._fields = ['id', 'pathId', 'userId', 'badgeId', 'step', 'enabled', 'createdAt', 'badge']

    
  }

  public id: number
  public pathId: number
  public userId: number
  public badgeId: number
  public badge: Badge
  public step: number
  public enabled: boolean
  public createdAt: number

  getActionPoints(): number {
    const position = JSON.parse(this.badge.cappedSteps).indexOf(String(this.step))
    if (position !== -1) {
      return Number(JSON.parse(this.badge.actionPoints)[position])
    }
    return 0
  }

  getName(): string {
    if (this.step && this.step > 0) {
      return `${ String(this.getOrdinal(this.step)) } ${this.badge.title}`
    }
    return ''
  }

  /**
    * From Shopify:
    *
    * https://stackoverflow.com/a/31615643
    */
  private getOrdinal(n) {
    var s=["th","st","nd","rd"],
    v=n%100;
    return n+(s[(v-20)%10]||s[v]||s[0]);
 }
}
