import { Base } from '../shared/base'
import { TextScreen } from '../text-screen/text-screen.model'

export class TodoListModel extends Base {
    init() {
        this._fields = ['id', 'isMandatory', 'enabled', 'preScreens', 'postScreens', 'screenOrder',
        'isDeleted', 'endDay', 'startDay']
        this.preScreens = []
        this.postScreens = []
        this.screenOrder =  0
      }
    
      afterInit() {
        this.preScreens = this.preScreens || []
        this.postScreens = this.postScreens || []
      }
    
      public id: number  
      
      public isMandatory: boolean
      public screenOrder: number
    
      public enabled: boolean
      public preScreens: TextScreen[]
      public postScreens: TextScreen[]
    
      public maxLength: number  
      public draft: boolean
      public isDeleted: boolean

      public startDay: number
      public endDay: number
  
}
