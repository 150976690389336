import gql from 'graphql-tag';
import { contentSurveyFields } from '../content-survey/content-survey.query';
import { documentFields } from '../document/document.query';
import { habitFields } from '../habit/habit.query';
import { inoutScreenFields } from '../inout-screen/inout-screen.query';
import { pollFields } from '../poll/poll.query';
import { questionFields } from '../question/question.query';
import { routineFields } from '../routine-v2/routine-info.query';
import { reflectionFields } from './full-screen-reflection/reflection.query';

export const saveToLibrary = gql`
mutation postToJournal($journalPost: JournalPostInput!) {
    postToJournal(journalPostInput: $journalPost) {
        status
        journalPost {
            id
            userId
            pathId
            text
            isPinned
            screenId
            inoutScreens{
                ...inoutScreenFields
            }
            polls{
                ...pollFields
            }
            contentSurveys{
                ...contentSurveyFields
            }
            documents{
                ...documentFields
            }
            editedDocumentUrl
            questions{
                ...questionFields
            }
            habits{
                ...habitFields
            }
            routineInfo{
                ...routineFields
            }
            reflections{
                ...reflectionFields
            }
        }
    }
}
${pollFields}
${contentSurveyFields}
${documentFields}
${questionFields}
${inoutScreenFields}
${habitFields}
${routineFields}
${reflectionFields}
`