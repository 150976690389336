import { Base } from '../shared/base'
import { User } from '../user/user.model'

interface LinkThumbnail {
  url: string
  image: string
  title: string
  description: string
}

export class Reply extends Base {
  init() {
    this._fields = ['id', 'repliableId', 'level', 'text', 
    'hasVoted', 'votesCount', 'enabled', 'user', 'replies', 
    'linkThumbnails', 'attachmentUrl', 'votes', 'uploadedAt',
    'createdAt', 'enabled', 'isReset','step', 'type', 'rootId', 'routineAnswerId']

    this._defaultInput = { exclude: ['uploadedAt', 'createdAt', 'enabled'] }

    this._nestedFields = {
      replies: Reply,
      user: User
    }

    this.replies = []
    this.linkThumbnails = []
  }

  afterInit() {
    this.timestamp = new Date(this.createdAt)
  }

  public id: number
  public repliableId: number
  public level: number
  public text: string
  public hasVoted: boolean
  public votesCount: number
  public enabled: boolean
  public user: User
  public replies: Reply[]
  public votes: any
  public linkThumbnails: LinkThumbnail[]
  public attachmentUrl: string
  public createdAt: string
  public timestamp: Date
  public isReset: boolean
  public step: number
  public type: any
  public rootId: any
  public routineAnswerId: any

  // Parse Amazon S3 full path to file stored in bucket.
  public getAttachmentName() {
    if ( !this.attachmentUrl ) return '';
    var url = this.attachmentUrl.split( '?' )[0];

    if ( !url ) return '';
    var fullName = url.split( '/' ).pop();

    if ( !fullName ) return '';
    var name = fullName.split( '-' )

    if ( !name ) return '';
    if ( name.length && name.length > 1) name.shift();
    return name.join('-');
  }
}
