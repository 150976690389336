import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-create-account',
  templateUrl: './alert-create-account.component.html',
  styleUrls: ['./alert-create-account.component.scss'],
})
export class AlertCreateAccountComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AlertCreateAccountComponent>) { }

  ngOnInit() {}

  closeDialog() {
    this.dialogRef.close();
  }

}
