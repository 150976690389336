import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import { User } from '../user/user.model';

const DEFAULT_URL = environment.apiBaseUrl + '/files'
const DEFAULT_MAX_FILE_SIZE = 10 * 1024 * 1024 // 10 MB - Accept raw images from the phone's camera
const DEFAULT_ALLOWED_MIME_TYPES = null // Workaround: Android issue with comparing types
// const DEFAULT_ALLOWED_MIME_TYPES = [
//  'image/png',
//  'image/gif',
//  'image/jpeg',
//]

class UploadConfig {
  url?: string = DEFAULT_URL
  maxFileSize?: number = DEFAULT_MAX_FILE_SIZE
  allowedMimeTypes?: string[] = DEFAULT_ALLOWED_MIME_TYPES
}


@Component({
  selector: 'image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class ImageUploaderComponent implements OnInit {

  @Input() config: UploadConfig = new UploadConfig()
  @Input() label: string
  @Input() type: string
  @Input() labelSelected: string = ''
  @Input() fileUrl: string
  @Output() fileUrlChange = new EventEmitter<string>()

  @ViewChild('imageContainer') imageContainer: ElementRef;
  @ViewChild('photoContainer') photoContainer: ElementRef;

  currentUser: User

  public uploader: FileUploader
  isMobileResolution: boolean
  isMobile: boolean


  constructor() { 
    console.log('fileUrl', this.fileUrl);
    
  }

  ngOnInit() {}

}
