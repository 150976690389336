import { Base } from "../shared/base"
import { User } from "../user/user.model"
import { Path, NamesAttributes } from "../path/path.model"

export class Client extends Base {
  init() {
    this._fields = ['id', 'name', 'tagLine', 'headerFontUrl', 'bodyFontUrl', 'faqUrl', 'tutorialUrl', 'subDomain',
      'imageUrl', 'backgroundImageUrl','enableFoozi', 'usersCount', 'users', 'paths', 'enabled', 'createdAt', 'isCohort', 'alternateCohortName','pathDescription', 'pathImage']
    this._nestedFields = {
      users: User,
      paths: Path
    }
  }

  public id: number
  public name: string
  public tagLine: string
  public headerFontUrl: string
  public bodyFontUrl: string
  public faqUrl: string
  public tutorialUrl: string
  public subDomain: string
  public imageUrl: string
  public backgroundImageUrl: string
  public enableFoozi: boolean
  public usersCount: number
  public users: User[]
  // public paths: Path[] //removed on 22/11 bcoz of not in use

  public names: NamesAttributes
  public enabled: boolean
  public createdAt: string
  public isCohort: boolean
  public alternateCohortName: string
  public pathDescription: string
  public pathImage: string
}
