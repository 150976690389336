import { Base } from "../shared/base"
import { User } from "../user/user.model"
import { NamesAttributes } from "../path/path.model"

export class PathClient extends Base {
  init() {
    this._fields = ['id', 'name', 'headerFontUrl', 'bodyFontUrl', 'faqUrl', 'tutorialUrl', 'subDomain', 'imageUrl', 'backgroundImageUrl',
      'usersCount', 'users', 'paths', 'enabled', 'createdAt']
    this._nestedFields = {
      users: User
    }
  }

  public id: number
  public name: string
  public headerFontUrl: string
  public bodyFontUrl: string
  public faqUrl: string
  public tutorialUrl: string
  public subDomain: string
  public imageUrl: string
  public backgroundImageUrl: string
  public usersCount: number
  public users: User[]

  public names: NamesAttributes
  public enabled: boolean
  public createdAt: string
}
