import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppState } from '../app.service';
import { SpinnerService } from '../spinner.service';
import { ToasterService } from '../toaster.service';
import { User } from '../user/user.model';
import { UserService } from '../user/user.service';

@Component({
  selector: 'app-alert-input-password',
  templateUrl: './alert-input-password.component.html',
  styleUrls: ['./alert-input-password.component.scss'],
})
export class AlertInputPasswordComponent implements OnInit {

  password: any
  currentUser: User

  constructor(private appState: AppState,
    private spinnerService: SpinnerService,
    private toasterService: ToasterService,
    private userService: UserService,
    private dialogRef: MatDialogRef<AlertInputPasswordComponent>,) {
    this.currentUser = this.appState.get('currentUser')
   }

  ngOnInit() {
  }

  onSubmit(){
    this.appState.set('password', this.password)
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
