import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Filter } from '../shared/filter'
import * as query from './path.query'
import { Path } from './path.model'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'

interface PathForLoginParams {
  pathGroupName: string
}

@Injectable()
export class PathService {
  constructor(private apollo: Apollo) { }

  getList(filter: Filter = null): Observable<Path[]> {
    return this.apollo
      .watchQuery<any>({ query: query.listPaths, variables: { filter: filter } })
      .valueChanges
      .pipe(
        map(response => {
          const list: Path[] = []
          response.data.paths.forEach(element => { list.push(new Path(element)) })
          return list
        })
      )
  }

  getPath(id: number): Observable<Path> {
    return this.apollo
      .watchQuery<any>({ query: query.findPath, variables: { id: id } })
      .valueChanges
      .pipe(
        map(response => {
          return new Path(response.data.path)
        })
      )
  }

  save(path: Path) {
    return this.apollo.mutate({
      mutation: query.updatePath,
      variables: {
        path: path.input()
      }
    })
  }
}
