import {
  Component,
  Input,
  ViewChild,
  OnInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  HostListener,
  ViewEncapsulation,
  Inject,
  DoCheck,
  Renderer2,
} from "@angular/core";
import { Question } from "../question/question.model";
import { Reply } from "../reply/reply.model";
import { QuestionService } from "../question/question.service";
import { ReplyService } from "../reply/reply.service";
import { ReplyComponent } from "../reply/reply.component";
import { AttachmentUploaderComponent } from "../shared/attachment-uploader/attachment-uploader.component";
import { ToastrService } from "ngx-toastr";

import { TranslationService } from "../translation/translation.service";
import { AppState } from "../app.service";
import { Path } from "../path/path.model";
import { Router } from "@angular/router";
import { Apollo } from "apollo-angular";
import { CordovaService } from "../cordova.service";
import { I18n } from "../i18n.service";
import { SessionService } from "../session/session.service";
import { Observable } from "rxjs";
import { bounceInAnimation, slideOutRightAnimation } from "angular-animations";
import { AlertUserLikeListComponent } from "../alert-user-like-list/alert-user-like-list.component";
import { MobileChatBoardComponent } from "../pages/mobile-chat-board/mobile-chat-board.component";
import { MobileChatServiceService } from "../pages/mobile-chat-board/mobile-chat-service.service";
import { WINDOW } from "../window.service";
import { WalletService } from "../wallet/wallet.service";
import { RoutineInfo } from "../routine-v2/routine-info.model";
import { RoutineV2Service } from "../routine-v2/routine-v2.service";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SpinnerService } from "../spinner.service";
import { IonInput, ModalController, NavController, Platform, PopoverController } from "@ionic/angular";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { UserPublicProfileComponent } from "../components/user-public-profile/user-public-profile.component";
import { AnalyticsServiceService } from "../services/analytics-service.service";
import { RoutineService } from "../daily-content-v2/routine.service";
import { Keyboard } from "@awesome-cordova-plugins/keyboard/ngx";
import { HomeUiService } from "../new-design/home-ui.service";

@Component({
  selector: 'app-full-screen-routine',
  templateUrl: './full-screen-routine.component.html',
  styleUrls: ['./full-screen-routine.component.scss'],
  animations: [slideOutRightAnimation({duration:300})]
})

export class FullScreenRoutineComponent implements OnInit, AfterViewInit, DoCheck {
  @Input() mode: string = "routine";
  @Input() isPreview: boolean;
  @Input() inverted: boolean = false;
  @Input() small = false;
  @Output() closed: EventEmitter<any>;
  @Output() itemSelected: EventEmitter<any>;

  @ViewChild("uploader") uploader: AttachmentUploaderComponent;
  @ViewChild("replyBlock") replyBlock: ElementRef;
  @ViewChild("repliesContainer") repliesContainer: ElementRef;
  @ViewChild("textareaForFeeds") txtarea_post: ElementRef;
  @ViewChild('btn') btn: ElementRef;
  textareaContentLength =0;
  @ViewChild('h1')
  h1: ElementRef;
  @Input() data;
  @Input() focuseTextArea;
  isTranslated;
  flag;
  isLoaded =false;
  path: Path;
  isTextboxHide =true
  rightState= false
  ishideComment: boolean = false;
  sortBy: string = "timestamp";
  replyText: string = "";
  cachedReplyText: string = ""; // LUMBA-1383
  attachmentUrl: string = null;
  errors: { answerError: false };
  replyTo: Reply = null;
  replyToComponent: ReplyComponent;
  replyToId: number;
  @ViewChild('replyComponent') replyComponent: ReplyComponent;
  isLoading: boolean = false;
  replyOffset: number;
  selected: boolean = false;
  voteLoading: boolean = false;
  user: any;
  routine: RoutineInfo;
  tagName_id: number
  dataOfUser: Observable<any>;
  namesData: any[]
  tempData: any
  state: boolean = false;
  animation = ['bounceIn'];
  isScrolledBottom: boolean = false
  color: string
  // backgroundColor = '#a972cb';
  // svgIcon = 'https://img.icons8.com/emoji/24/000000/white-heart.png';
  singleRoutineAnswer: any
  signleRoutine: any
  userDetails;
  public replies: Reply[]
  public repliesTree: any[] =[]
  public repliesIdsMap: Map<number, number>
  public masterRoutineAnswer: any
  isMobile: boolean
  page;
  apiRepliesCount = 0;
  replyCount = 0;
  pathRegistration
  constructor(
    private i18n: I18n,
    private apollo: Apollo,
    private router: Router,
    private appState: AppState,
    private toastrService: ToastrService,
    private questionService: QuestionService,
    private replyService: ReplyService,
    private translationService: TranslationService,
    private spinnerService: SpinnerService,
    private cordovaService: CordovaService,
    private sessionService: SessionService,
    private mobileService: MobileChatServiceService,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private toasterService: ToastrService,
    private walletService: WalletService,
    private routineV2Service: RoutineV2Service,
    private nav: NavController,
    private routineService: RoutineService,
    private _bottomSheet: MatBottomSheet,
    private modalController: ModalController,
    public platform: Platform,
    private analyticsService: AnalyticsServiceService,
    private keyboard: Keyboard,
    private homeUiService: HomeUiService,
    private popoverController:PopoverController,
    public render: Renderer2,
    @Inject(WINDOW) private window: Window,
    // @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.path = this.appState.get("currentPath");
    this.user = this.appState.get("currentUser");
    this.pathRegistration = this.appState.get("pathRegistration");
    var dataOfFeeds = this.appState.get('singleFeed')
    var dataOfRoutineFeeds = this.appState.get('singleRoutineFeed')
    this.isMobile = this.appState.get('isMobile')
    this.page =1
    this.singleRoutineAnswer = dataOfRoutineFeeds.singleRoutine
    console.log('singleRoutineAnswer', this.singleRoutineAnswer);

    this.tempData = dataOfFeeds["routine"]
    this.mode = dataOfFeeds["mode"]
    console.log('this.mode :', this.mode, this.path[dataOfFeeds["mode"]]);
        this.appState.set("mode", this.mode);
    this.routine = this.sessionService.findById(this.path[dataOfFeeds["mode"]], this.tempData.id)
    this.color = this.appState.get("routine_color")
    this.appState.set('oldActionPoints', this.walletService.actionPoints.value)

    // this.masterRoutineAnswer = this.sessionService.findById(this.tempData.routineAnswerList, this.singleRoutineAnswer.id)
    // console.log('masterRoutineAnswer', this.masterRoutineAnswer);
    this.appState.set('questionsLoaded', false)

  }

  ngOnInit() {
    this.getRoutineDetails()
    setTimeout(()=>{
      if(this.focuseTextArea) {
        this.clickOnInput();
       }
    },600 )
    
    this.isLoaded =false;
    //this.spinnerService.on();
    this.data = this.data;
    this.singleRoutineAnswer =this.data;
    console.log("this.routine", this.routine, this.data,  this.singleRoutineAnswer);
    this.analyticsService.setScreen(AnalyticsServiceService.FULLROUTINE)
    this.appState.set('communityNotification', undefined)
    this.appState.set('notificationData', undefined)
  }

  async ngDoCheck() {
    let isCommentDeleted = this.appState.get('commentDeleted')    
    if (isCommentDeleted) {
      this.appState.set('commentDeleted', undefined)
      this.page = 1;
      await (this.getRoutineDetails(null, true))
       //this.removeDeletedComment();
    }
  }

  async removeDeletedComment() {
    let pageCount = this.page;
    console.log("pageCount", this.page, pageCount);
    this.page = 1;
    for (let i = 1; i < pageCount; i++) {
      try {
        // if(this.page ==1) {
        //   this.apiRepliesCount = 0;
        //   this.repliesTree = [];
        // }
      await (this.getRoutineDetails(null, true)).then(() => {
        this.page += 1;
      });
        
      } catch (error) {
        break; 
      }
    }
  }
  
  checkTextareaLength() {
    let len = this.replyText?.trim();
    this.textareaContentLength =len.length;
}
  ngAfterViewInit() {
    this.focusComment()
    window.scroll(0, 0);
    if(this.isMobile){
      if(this.platform.is('android')) {
       let id =document.getElementsByClassName('list-group')[0];
      //  id.setAttribute('style', 'margin-top:60px;')
        this.keyboard.onKeyboardDidShow().subscribe((info) => {
          console.log('keyboard will show with height', info.keyboardHeight);
          this.replyBlock.nativeElement.style.setProperty(
            'bottom',
            `${0}px`
          );
          this.replyBlock.nativeElement.style.setProperty(
            'padding-bottom',
            `${25}px`
          );
        });
    
        this.keyboard.onKeyboardDidHide().subscribe(() => {
          console.log('keyboard did hide');
          this.replyBlock.nativeElement.style.setProperty(
            'bottom',
            `${0}px`
          );
          this.replyBlock.nativeElement.style.setProperty(
            'padding-bottom',
            `${20}px`
          );
        });
      }
    }
    //this.showCommentsData(this.data);
    //     // this line moves the screen at the bottom scroll
    //this.replyOffset = 0;
  }

  showCommentsData(data) {
    let user ={
      email: data?.email,
     first_name: data?.first_name,
     id: data?.user_id,
     imageUrl: data?.image_url,
     last_name: data?.last_name
    }
    this.userDetails=user;
          
             var level1 = data.replies;
             this.replyCount = data.reply_count;
            if (level1) {
              var subReply = level1.filter(item => item.repliableId)
              level1.forEach(reply => {
                subReply.forEach(element => {
                  let flag=0
                  reply?.replies?.forEach((item)=>{
                    if(item.id===element.repliableId)
                    {
                       flag ++;
                    }
                  })
                  if (element.repliableId === reply.id && flag ==0) {
                    reply?.replies?.push(element)
                    reply.replies = [...new Set( reply.replies)];
                  }
                  reply.timestamp = new Date(reply.createdAt)
                });
              });
  
              this.repliesTree = level1.filter(reply => reply.id && reply.level === 1)
              this.isLoaded=true;
  
              this.appState.set('repliesLength', this.repliesTree?.length)
            }
  
  }
  onCommentClick() {
    this.txtarea_post?.nativeElement.focus();
      this.replyTo = null;
      this.replyToId = null;
  }

  itemMentioned(tag) {
    console.log("selected ", tag);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.routine || changes.routine.firstChange) {
      return false;
    }
    this.sortRepliesBy(this.sortBy)
    this.cdRef.detectChanges();
  }

  toggleSort(by: string) {
    console.log("by:" + by);
    this.sortBy = by;
    this.sortRepliesBy(this.sortBy)
  }

  orderingSubReplies(reply) {
    this.isLoaded = true;
    this.isTextboxHide = true;
    this.appState.set("isMobileReply", false);
    // this.commentCount = reply.reply_count;
    // this.data.votescount = reply?.voteCount;
    let replySort;
    this.data.reply_count = this.data.reply_count+1;
    var level1 = [reply, ...this.repliesTree];
    if (level1) {
      var subReply = level1.filter(item => item.repliableId)
      level1?.forEach(reply => {
        subReply?.forEach(element => {
          if (element?.repliableId === reply.id) {
            const updatedReply = { ...reply };
            updatedReply.replies = [element, ...updatedReply.replies];
            updatedReply.timestamp = new Date(reply.createdAt);
            //replySort =updatedReply;
            const index = level1.findIndex(r => r.id === updatedReply.id);
            level1[index] = updatedReply;
          }
        });
      });
      // if (replySort) {
      //   replySort.timestamp = new Date(reply.createdAt);
      // }
    //  let index =level1?.findIndex((reply) => reply.id == replySort.id)
    //   level1.splice(index,1)
      let repliesTree = level1.filter(reply => reply.id && reply.level === 1)
      this.repliesTree = repliesTree;
      this.data.replies = this.repliesTree;
      console.log("repliestree in comment list", this.repliesTree);
    }
  }
  reply() {
    if(this.textareaContentLength>0){
      this.isLoaded =false
    }
    if(this.replyTo) {
      this.appState.set('isMobileReply',true)
      this.isTextboxHide =false;
     }
    let formatedText;
    if (this.replyText.length == 0) {
      this.toastrService.error(this.i18n.t('Please enter a comment'), this.i18n.t('Error'))
      return false;
    }

    var list_of_tags_ids = []

    list_of_tags_ids = this.sessionService.getUserTAGs(this.replyText, this.complexItems)

    console.log("Array of tags ", list_of_tags_ids);

    // if (this.isPreview) {
    //   return false
    // }
    // if (this.isLoading) {
    //   return false
    // }
    this.isLoading = true
    this.complexItems.forEach(element => {
      let matchText = `@${element.username.toUpperCase()}`;
      if (this.replyText.includes(matchText)) {
        let replaceValue = `<span id="${element.id}">${element.username.toUpperCase()} </span>`;
        formatedText = this.replyText.replace(RegExp(matchText, 'g'), replaceValue);
      }
    });
    //this.replyText=formatedText;
    this.unloadReply();
    if (formatedText) {
      this.cachedReplyText = formatedText;
    }
    if (this.replyTo) {
      this.isLoaded =true;
      this.replyService
        .replyRoutine(this.singleRoutineAnswer, this.replyTo.id, this.cachedReplyText, this.attachmentUrl, list_of_tags_ids, 'routineInfo', this.singleRoutineAnswer.id)
        .subscribe(reply => {
          this.ishideComment = true;
          this.replyTo = null;
          this.replyToId = null;
          this.appState.set('isReplyAnimationApplied',true);
          this.reset()
          this.isLoading = false
          this.toastrService.success(this.i18n.t('Comment posted!'))
          this.replyComponent.replyText = '';
          this.replyComponent.isLoaded = true;
          this.replyComponent.isMobileLoaded=true
          //this.getRoutineDetails()
          this.loadReply()
          this.orderingSubReplies(reply)
          this.isLoading = true;
          this.cdRef.detectChanges()
        },
          (error) => {

            this.toastrService.error(this.i18n.t('Comment not posted. Check your connection and try reloading the app.'), this.i18n.t('Error'))
            this.isLoading = false
            this.isLoaded = true;
            this.isTextboxHide = true;
            this.appState.set("isAnimationApplied", true);
            this.appState.set("isMobileReply", false);
            this.loadReply()
            this.cdRef.detectChanges()
          })
    } else {
      this.questionService
        .replyRoutineAnswer(this.singleRoutineAnswer, this.cachedReplyText, this.attachmentUrl, list_of_tags_ids, "routine")
        .subscribe(reply => {
          this.appState.set('currentActionPoints', reply?.data?.replyQuestion?.actionPoints)
          this.ishideComment = true;
          this.replyTo = null;
          this.replyToId = null;
          this.appState.set('isAnimationApplied',true);
          this.reset()
          this.isLoading = false
          this.loadReply()
          // this.getRoutineDetails()
          this.orderingSubReplies(reply.data.replyQuestion.reply);
          this.isLoading = true;
          this.cdRef.detectChanges()
          console.log('reply response', reply);

          this.toastrService.success(this.i18n.t('Comment posted!'))
          // if(this.repliesContainer!= undefined){
          //   this.repliesContainer.nativeElement.scrollIntoView()
          //   this.repliesContainer.nativeElement.scrollTop = 0
          // }



        }, error => {
          console.log(error)
          this.loadReply()
          this.cdRef.detectChanges()
          this.isLoaded = true;

          this.toastrService.error(this.i18n.t('Comment not posted. Check your connection and try reloading the app.'), this.i18n.t('Error'))
          this.isLoading = false

        })
    }
  }

  translate($event){
    let toggleEnabled = $event.detail.checked
    console.log('toggleEnabled :', toggleEnabled);

    if (toggleEnabled === true) {
      this.isTranslated =true;
      this.flag =false;
      console.log('repliesTree before translation:', this.repliesTree);
      this.path = this.appState.get("currentPath");
      const repliesIds = this.repliesTree.map((reply) => reply.id);
      this.translationService
        .loadRepliesTranslations(repliesIds, this.path.language)
        .subscribe((translations) => {
          this.repliesTree.forEach((reply) => {
            const key = `Reply:${reply.id}`;
            reply.text = translations[key].text;
            this.isTranslated =false;
            this.flag = true
            this.cdRef.detectChanges()
          });
        });
      console.log('this.repliesTree after translation:', this.repliesTree);
    }

    else {
      this.flag = false;
      this.appState.set('commentDeleted', true)
      this.cdRef.detectChanges()
    }
  }

  reset() {
    this.replyText = "";
    this.attachmentUrl = null;
    if (this.uploader) {
      this.uploader.reset();
    }
    this.cachedReplyText = "";
  }

  // LUMBA-1383
  loadReply() {
    if (this.cachedReplyText) {
      this.replyText = this.cachedReplyText;
      this.cachedReplyText = "";
    }
  }

  // LUMBA-1383
  unloadReply() {
    this.cachedReplyText = this.replyText;
    this.replyText = "";
  }

  setReplyTo(replyComponent: ReplyComponent) {
    if (this.uploader) {
      this.uploader.reset();
    }
    // This code was needed when all replies was visible
    // Now all replies hidden except selected for reply
    if (this.replyToComponent) {
    }
    if(replyComponent?.selected){
      this.txtarea_post.nativeElement.focus();
    }
    this.replyToComponent = replyComponent;
    if (replyComponent) {
      this.replyTo = replyComponent.reply;
      this.replyToId = this.replyTo.id;
    } else {
      this.replyTo = null;
      this.replyToId = null;
    }
  }

  refresh() {
    console.log(this.router.url);
    this.apollo.getClient().clearStore();
    this.appState.refreshMainComponent.emit();
  }

  scrollTo(event) {
    if (this.cordovaService.onCordova) {
      console.log("click event");
      console.log(this.replyBlock.nativeElement.offsetTop);

      setTimeout(() => {
        window.scrollTo({
          top: this.replyBlock.nativeElement.offsetTop - 160,
          behavior: "smooth",
        });
      }, 10);
    }
  }

  close() {
   
    this.modalController.dismiss()
   
  }

  totalLikes(items) {
    return items.reduce((a, b) => +a + +b.votescount, 0);
  }

  clickOnInput() {
    this.txtarea_post.nativeElement.focus();
  }

  toggleVote(reply) {
   
    if (this.isMobile) {
      this.bounceAnimation()
    }

    this.voteLoading = true;
    reply.hasVoted = !reply.hasVoted;
    reply.votescount += reply.hasVoted ? 1 : -1;
    if(reply.hasVoted)
    {
      reply.likes?.push(this.user._data);
      this.data.likes = reply.likes;  
            this.singleRoutineAnswer.likes = reply.likes; 

    }
    else {
      reply.likes?.forEach( (user, index) => {
        if(user.id === this.user?._data.id) 
        reply.likes?.splice(index,1);
      });
      this.data.likes = reply.likes;  
      this.singleRoutineAnswer.likes = reply.likes; 

    }
    this.cdRef.detectChanges();
    this.replyService.voteReply(reply, "Routine").subscribe(
      (status) => {
        this.voteLoading = false;
        this.cdRef.detectChanges();
      },
      (error) => { },
      () => {
        this.voteLoading = false;
        this.cdRef.detectChanges();
      }
    );
  }

  complexItems = this.appState.get("userlist")

  tempValue = 0
  index = 0

  format(item: any) {
    var data = item['id']
    setTimeout(() => {
      SessionService.setData(data)
      //  this.tagName_id = item['id'];
      console.log("Format data ", SessionService.getData());
    }, 100);
    return `@${item['username'].toUpperCase()}`;
    //return item['username'].toUpperCase()
  }

  mentionConfig: any = {
    mentions: [
      {
        items: this.complexItems,
        labelKey: 'username',
        mentionSelect: this.format,
        dropUp: true
      }
    ]
  };


  focusComment() {
    if (this.appState.get('commentClicked')) {
      this.appState.set('commentClicked', false)
        this.txtarea_post.nativeElement.focus();
     
    }
    else {
      this.txtarea_post.nativeElement.blur();
    }
  }

  bounceAnimation() {
    this.render.addClass(this.btn.nativeElement, 'bounce');
    if(this.btn.nativeElement.querySelector('ion-icon').getAttribute("name") == 'star-outline') {
      this.render.setAttribute(this.btn.nativeElement.querySelector('ion-icon'),"name", 'star')
    }
    else {
      this.render.setAttribute(this.btn.nativeElement.querySelector('ion-icon'),"name", 'star-outline')

    }
    setTimeout(() => {
      this.render.removeClass(this.btn.nativeElement, 'bounce');
    }, 200);
  }

  showLikeList(postId: number, mode: string) {
    this.mobileService.setIsLikeList(true)
    this.mobileService.setPostId(postId)
    this.mobileService.setMode(mode)
    this.homeUiService.openNextComponent(AlertUserLikeListComponent,'','alert-like-modal');
    // var dialog = this.dialog.open(AlertUserLikeListComponent, {
    //   panelClass: 'custom-dialog-container'
    // }
    // )
  }

  showCommentList(mode: string, id: number) {

    const requestUserList = {
      id: id,
      path_id: this.path.id,
      mode: mode
    }
    this.mobileService.setIsLikeList(false)
    this.mobileService.setRequestUserList(requestUserList)
    this.homeUiService.openNextComponent(AlertUserLikeListComponent,'','alert-like-modal');
    // var dialog = this.dialog.open(AlertUserLikeListComponent, {
    //   panelClass: 'custom-dialog-container'
    // }
    // )
  }

  getRoutineText(routine) {
    if (routine.routineAnswer && routine.routineAnswer.routineDescription) {
      return routine.routineAnswer.routineDescription;
    } else {
      return ''
    }
  }

  getImageUrl(item) {
    if (item && item.user && item.user.image_url) {
      return item.user.image_url
    } else {
      return ''
    }
  }

  getUserName(item) {
    if (item && item.user && item.user.first_name && item.user.last_name) {
      return item.user.first_name + item.user.last_name
    } else {
      return ''
    }
  }



  @HostListener('click', ['$event'])
  onLinkClick(e) {
    console.log("Link data ", e);

    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        console.log('cordova click')
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        console.log('cordova not click')
        window.open(e.target.href, '_blank')
      }
    }
    if (e.target.tagName.toLowerCase() === 'span') {
      console.log('target id', e.target.id);
      this.getUserData(e, e.target.id);
    }
  }
  getUserData(event, id) {
    this.routineService.getUserDetailsById(id).subscribe(value => {
      console.log('userid....', value);
      this.openuserInfo(event, value.data[0]);
    });
  }
  async openuserInfo(ev, userData): Promise<void> {
    console.log('userData', userData);
    const popover = await this.popoverController.create({
      component: UserPublicProfileComponent,
      event: ev,
      mode: 'ios',
      translucent: false,
      componentProps: { 'user': userData }
    });
    await popover.present();

  }

  onClick(): void {
    this.h1.nativeElement.scrollIntoView({
      top: this.h1.nativeElement.offsetTop,
      behavior: 'smooth'
    });
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const offset = this.window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (offset > 100) {
      this.isScrolledBottom = true
    } else {
      this.isScrolledBottom = false
    }
  }
  doInfinite(event) {
    console.log('doInfinite :');
    this.getRoutineDetails(event);
  }

  async getRoutineDetails(event =null,isPostDeleted=false): Promise<any> {
    if (!this.singleRoutineAnswer) {
      //this.spinnerService.off()
      return
    }
   return new Promise( async (resolve, reject) => {
    try {
      const data = {
        id: this.singleRoutineAnswer.routine_info_id,
        routine_answer_id: this.singleRoutineAnswer.id,
        mode: this.singleRoutineAnswer.type,
        path_id: this.path.id,
        page: this.page
      };
       await this.routineV2Service.getAllPostsRepliyDetails(data).subscribe(
        (response) => {
          this.appState.set('isMobileReply',false)
          this.isLoaded =true;
          this.isTextboxHide =true;
          this.userDetails=response.data.user;
          this.data.votescount= response.data?.voteCount;
          this.data.reply_count = response.data?.reply_count;
          this.singleRoutineAnswer.votescount= response.data?.voteCount;
          this.singleRoutineAnswer.reply_count = response.data?.reply_count;
            var level1;
             if(this.page ==1 || isPostDeleted) {
                this.apiRepliesCount = 0;
               this.repliesTree = [];
             }
            if (this.page == 1 && response.data.replies?.length > 0) {
              this.repliesTree = [];
              level1 = response.data.replies;
            }
            else if (response.data.replies?.length > 0) {
              level1 = this.repliesTree.concat(response.data.replies);
            }
            console.log('level 1', level1);
            this.replyCount = response.data.reply_count;
            this.apiRepliesCount += response.data.replies?.length;
          if (level1) {
            var subReply = level1.filter(item => item.repliableId)
            level1?.forEach(reply => {
              subReply?.forEach(element => {
                if (element?.repliableId === reply.id) {
                  reply.replies.push(element)
                }
                reply.timestamp = new Date(reply.createdAt)
              });
            });

              this.repliesTree = level1.filter(reply => reply.id && reply.level === 1)
            this.page = this.page + 1
            if (event) {
              event.target.complete();
            }
            this.singleRoutineAnswer.replies = this.repliesTree;
            this.data.replies =this.repliesTree;
            this.appState.set('repliesLength', this.repliesTree.length)
            let isCommentDeleted = this.appState.get('commentDeleted')
            if (isCommentDeleted) {
              this.appState.set('commentDeleted', false)
            }
          }
        },
        (error) => {
          this.isLoaded =true;
          this.isTextboxHide =true;
          this.appState.set('isMobileReply',false)
          console.log('error', error)
          this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
          //this.spinnerService.off()
        }
      )
        resolve(true);
    } catch (error) {
      this.isLoaded =true;
      this.isTextboxHide =true;
      this.appState.set('isMobileReply',false)
      console.log('error', error)
      reject(error);
      this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
      //this.spinnerService.off()
    }
  });


  }

  sortRepliesBy(key = 'timestamp') {
    this.repliesTree.sort((a, b) => a[key] > b[key] ? -1 : a[key] < b[key] ? 1 : 0)
    this.cdRef.detectChanges();
  }

  translatePost(language: string,text: any){

    let data = {
      language: language,
      text: text
    }

    this.mobileService.translateContent(data).subscribe(
      (response) => {
        console.log('response :', response);
        if(response.status){
          this.replyText = response.data.text
          this.cdRef.detectChanges()
          this.toastrService.success(this.i18n.t('Post translated successfully!'))
        }
      },
    (error) => {
      this.toastrService.error(this.i18n.t('Text translation failed!'))
      console.log("TranslationService data ",error);
    }
    )
  }

}
