import { Base } from '../shared/base'
import { Schedulable } from '../shared/schedulable'
import { TextScreen } from '../text-screen/text-screen.model'
import { Reply } from '../reply/reply.model'

export class Habit extends Base implements Schedulable {

  init() {
    this._fields = ['id', 'text', 'actionPoints', 'preScreens', 'postScreens','casualDocumentId','documents',
    'startDay', 'endDay', 'isMandatory', 'scheduledLater', 'confirmation', 'isCompleted', 'enabled','replies','createdAt', 'screenOrder','isroutine',
      'title', 'routinelen', 'eventlen', 'isConvertedToRoutine', 'updatedAt','time','colleaguesCount','votesCounts', 'isScheduled', 'isCompleteNow', 'prompt']

    this._nestedFields = {
      replies: Reply
    }
    this.replies = []
    this.repliesTree = []
    this.preScreens = []
    this.postScreens = []
    this.repliesTree = []
    this.screenOrder =  0
  }

  afterInit() {
    this.preScreens = this.preScreens || []
    this.postScreens = this.postScreens || []

    this.repliesIdsMap = new Map<number, number>()
    this.repliesTree = []
    this.replies.forEach(reply => this.insertReply(reply))
  }
  public id: number
  public text: string
  public preScreens: TextScreen[]
  public postScreens: TextScreen[]
  public actionPoints: number
  public startDay: number
  public endDay: number
  public screenOrder: number
  public isMandatory: boolean
  public isCompleted: boolean
  public scheduledLater: number
  public confirmation: number  // LUMBA-1419
  public enabled: boolean
  public replies: Reply[]
  public repliesTree: Reply[]
  public repliesIdsMap: Map<number, number>
  public createdAt: any
  public isroutine: boolean
  public title: string
  public routinelen: number
  public eventlen: number
  public isConvertedToRoutine: boolean
  public updatedAt: any
  public time: any
  public colleaguesCount: any
  public votesCounts:number
  public isScheduled: boolean
  public isCompleteNow: boolean
  public casualDocumentId;
  public documents;
  public prompt: string
  getTitle() {
    return 'Building Habits'
  }

  getTitleKey() {
    return 's_awp'
  }

  getDescription() {
    return this.text
  }

  getColorClass() {
    return 'stat-color-2'
  }

  completeBy() {
    return 'ByDay'
  }

  insertReply(reply: Reply, onTop = false) {
    if (reply.repliableId) {
      let index = this.repliesIdsMap.get(reply.repliableId)
      if (this.repliesTree[index] && this.repliesTree[index].replies) {
        this.repliesTree[index].replies.unshift(reply)
      }
    } else {
      if (onTop) {
        this.repliesTree.unshift(reply)
        this.repliesIdsMap.forEach((value, key, map) => { map.set(key, value + 1) })
        this.repliesIdsMap.set(reply.id, 0)
      } else {
        const index = this.repliesTree.length
        this.repliesTree.push(reply)
        this.repliesIdsMap.set(reply.id, index)
      }
    }
  }

  replaceReply(id: number, reply: Reply) {
    if (reply.repliableId) {
      let index = this.repliesIdsMap.get(reply.repliableId)
      let j = this.repliesTree[index].replies.findIndex(el => { return el.id === id })
      this.repliesTree[index].replies[j] = reply
    } else {
      let index = this.repliesIdsMap.get(id)
      this.repliesTree[index] = reply
      this.repliesIdsMap.set(reply.id, index)
    }
  }

  sortRepliesBy(key = 'timestamp') {
    // Universal sort number or date
    this.repliesIdsMap = new Map<number, number>()
    this.repliesTree.sort((a, b) => a[key] > b[key] ? -1 : a[key] < b[key] ? 1 : 0)
    this.repliesTree.forEach((reply, index) => { this.repliesIdsMap.set(reply.id, index) })
  }

}
