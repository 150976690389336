import { Base } from '../shared/base'

import { Reply } from '../reply/reply.model'
import { Schedulable } from '../shared/schedulable'
import { TextScreen } from '../text-screen/text-screen.model'

interface RepliesHash {
  [replyId: string]: Reply
}

export class Question extends Base implements Schedulable {
  init() {
    this._fields = ['id', 'text',
    'startDay', 'endDay', 'isMandatory', 'isCompleted', 'preScreens', 'postScreens',
    'enabled', 'isPinned', 'isLinked', 'repliesEnabled', 'replies', 'actionPoints', 
  'votesCounts' ,'screenOrder','createdAt', 'time','colleaguesCount','']

    this._nestedFields = {
      replies: Reply
    }

    this.replies = []
    this.repliesTree = []
    this.preScreens = []
    this.postScreens = []
    this.screenOrder =  0
  }

  afterInit() {
    this.preScreens = this.preScreens || []
    this.postScreens = this.postScreens || []
    // [IMPORTANT] Assuming we have replies sorted by level!
    this.repliesIdsMap = new Map<number, number>()
    this.repliesTree = []
    this.replies.forEach(reply => this.insertReply(reply))
    // for (let i = 0; i < this.replies.length; i++) {
    //   let reply = this.replies[i]
    //   if (reply.level == 1) {
    //     this.repliesTree[reply.id.toString()] = reply
    //   } else {
    //     this.repliesTree[reply.repliableId.toString()].replies.push(reply)
    //   }
    // }
  }

  public id: number
  public text: string
  public preScreens: TextScreen[]
  public postScreens: TextScreen[]
  public startDay: number
  public endDay: number
  public screenOrder: number
  public isMandatory: boolean
  public isCompleted: boolean
  public enabled: boolean
  public isPinned: boolean
  public isLinked: boolean
  public repliesEnabled: boolean
  public actionPoints: number
  public replies: Reply[]
  public repliesTree: Reply[]
  public createdAt: any
  public time: any
  public colleaguesCount:any
  public votesCounts:number


  private repliesIdsMap: Map<number, number>

  quotedText() {
    return '"""' + this.text + '""""'
  }
  
  getTitle() {
    return 'Share Your Thoughts'
  }

  getTitleKey() {
    return 's_sp'
  }
  
  getDescription() {
    return this.text
  }

  getColorClass() {
    return 'stat-color-3'
  }

  completeBy() {
    return 'ByType'
  }
  
  insertReply(reply: Reply, onTop = false) {
    if (reply.repliableId) {
      let index = this.repliesIdsMap.get(reply.repliableId)
      if (this.repliesTree[index] && this.repliesTree[index].replies) {
        this.repliesTree[index].replies.unshift(reply)
      }
    } else {
      if (onTop) {
        this.repliesTree.unshift(reply)
        this.repliesIdsMap.forEach((value, key, map) => { map.set(key, value + 1) })
        this.repliesIdsMap.set(reply.id, 0)
      } else {
        const index = this.repliesTree.length
        this.repliesTree.push(reply)
        this.repliesIdsMap.set(reply.id, index)
      }

    }
  }

  replaceReply(id: number, reply: Reply) {
    if (reply.repliableId) {
      let index = this.repliesIdsMap.get(reply.repliableId)
      let j = this.repliesTree[index].replies.findIndex(el => { return el.id === id })
      this.repliesTree[index].replies[j] = reply
    } else {
      let index = this.repliesIdsMap.get(id)
      this.repliesTree[index] = reply
      this.repliesIdsMap.set(reply.id, index)
    }
  }

  sortRepliesBy(key = 'timestamp') {
    // Universal sort number or date
    this.repliesIdsMap = new Map<number, number>()
    this.repliesTree.sort((a, b) => a[key] > b[key] ? -1 : a[key] < b[key] ? 1 : 0)
    this.repliesTree.forEach((reply, index) => { this.repliesIdsMap.set(reply.id, index) })
  }
}
