import { Component, Input, OnInit } from '@angular/core';
import { AppState } from 'src/app/app.service';

@Component({
  selector: 'app-skeleton-list',
  templateUrl: './skeleton-list.component.html',
  styleUrls: ['./skeleton-list.component.scss'],
})
export class SkeletonListComponent implements OnInit {
  isMobile;
  @Input() type:string;
  @Input() data;
  constructor(public appState: AppState) { }

  ngOnInit() {
    this.isMobile =this.appState.get('isMobile')
  }

}
