import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-alert-iam-in',
  templateUrl: './alert-iam-in.component.html',
  styleUrls: ['./alert-iam-in.component.scss']
})
export class AlertIamInComponent implements OnInit {

  private dialog: MatDialog

  constructor() { }

  ngOnInit() {
  }

}
