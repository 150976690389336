import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { AppState } from './app.service';
import { I18n } from './i18n.service';

@Injectable({
  providedIn: "root",
})
export class SpinnerService {
  isLoaderBusy = false;
  loaderMsg: string;
  constructor(public loadingController: LoadingController, private i18n: I18n) {
    this.loaderMsg = this.i18n.t("Loading...");
  }

  on() {
    if (!this.isLoaderBusy) {
      this.isLoaderBusy = true;
      this.loaderCreate();
    }
  }

  async off() {
    if (this.isLoaderBusy) {
      await this.loaderDismiss();
      setTimeout(() => {
        this.isLoaderBusy = false;
      }, 500);
    }
  }

  loaderCreate() {
    this.loadingController
      .create({
        message: this.loaderMsg,
        cssClass: "loader-css-class",
        backdropDismiss: true,
      })
      .then((res) => {
        res.present();
      });
  }
  loaderDismiss() {
    this.loadingController.dismiss();
  }
}
