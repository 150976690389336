import { Component, Input, OnInit } from '@angular/core';

import { AppState } from '../app.service'
import { Path } from '../path/path.model'
import { PathReward } from '../path/path-reward.model'
import { RewardService } from './reward.service'
import { Filter } from '../shared/filter'
import { Reward } from './reward.model';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'first90-reward-list',
  templateUrl: './reward-list.component.html',
  styleUrls: ['./reward-list.component.scss']
})
export class RewardListComponent implements OnInit {
  pathRewards: PathReward[] = []
  path: Path
  enabled: boolean
  nameR: string

  @Input() rewards: Reward[];

  constructor(
    private appState: AppState
  ) {
    this.path = this.appState.get('currentPath')
    this.isEnabled()
    this.initNames()
  }

  ngOnInit() {
    console.log("rewards", this.rewards);
  }

  private isEnabled() {
    this.enabled = this.path.enabledAttributes['rewards']
    console.log("this.enabled", this.enabled);
  }

  private initNames() {
    this.nameR = this.path.getCustomText('r_r')
  }
}
