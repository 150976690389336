import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertEditNoteComponent } from '../alert-edit-note/alert-edit-note.component';
import { AppState } from '../app.service';
import { I18n } from '../i18n.service';
import { HomeV5Service } from '../pages/home-v5/home-v5.service';
import { ToasterService } from '../toaster.service';
import { LibraryService } from './library.service';
import { HomeUiService } from '../new-design/home-ui.service';
import { AlertController, AlertInput, IonCol, ModalController } from '@ionic/angular';
import { UtilityService } from '../common-services/utility.service';
import { DailyStepsContentModalComponent } from '../new-design/daily-steps-content-modal/daily-steps-content-modal.component';
import { AnimationsService } from '../shared/animations.service';
import { CommonHeaderComponent } from '../common-header/common-header.component';

@Component({
  selector: 'full-screen-step',
  templateUrl: './full-screen-step.component.html',
  styleUrls: ['./full-screen-step.component.scss'],
})

export class FullScreenStepComponent implements OnInit {

  journalPost: any
  poll: any
  question: any
  document: any
  contentSurvey: any
  habit: any
  routineInfo: any
  inoutScreen: any
  stepNumber: number
  isMobile: boolean
  isEditNote: boolean
  journalNote: string
  currentPath: any
  isonboardingcompleted: any;
  nameTQ: string
  nameHYPA: string
  reflection: any
  isBackAllowed: boolean
  currentDay: any
  currentClient
  dailyStepsArray: any
  commonHeader: CommonHeaderComponent
  @ViewChild('contentContainer') contentContainer;
  constructor(
    private appState: AppState,
    private route: Router,
    private dialog: MatDialog,
    private homeUIService: HomeUiService,
    private alertController:AlertController,
    private homeService: HomeV5Service,
    private modalController: ModalController,
    private libraryService: LibraryService,
    public i18n: I18n,
    public render: Renderer2,
    private toasterService: ToasterService,
    private utilityService: UtilityService,
    private animationService: AnimationsService,) {
    this.isMobile = this.appState.get('isMobile')
    this.journalPost = this.appState.get('journalPost')
    this.currentPath = this.appState.get('currentPath')
    this.currentDay = this.appState.get("currentScheduleDay");
    this.dailyStepsArray= this.appState.get("DailyStepsData");
    this.isBackAllowed = this.appState.get('isBackAllowed')
    this.poll = this.journalPost?.polls[0]
    this.question = this.journalPost?.questions[0]
    this.document = this.journalPost?.documents[0]
    this.contentSurvey = this.journalPost?.contentSurveys[0]
    this.habit = this.journalPost?.habits[0]
     this.currentClient = this.appState.get('currentClient');
    this.routineInfo = this.journalPost?.routineInfo[0]
    this.inoutScreen = this.journalPost?.inoutScreens[0]
    this.reflection = this.journalPost?.reflections[0]
    if (this.journalPost) {
      if (this.journalPost?.questions[0]) {
        this.stepNumber = this.journalPost?.questions[0].startDay
      } else if(this.journalPost?.documents[0]){
        this.stepNumber = this.journalPost?.documents[0].startDay
      } else if(this.journalPost?.contentSurveys[0]){
        this.stepNumber = this.journalPost?.contentSurveys[0].startDay
      } else if(this.journalPost?.habits[0]){
        this.stepNumber = this.journalPost?.habits[0].startDay
      } else if(this.journalPost?.inoutScreens[0]){
        this.stepNumber = this.journalPost?.inoutScreens[0].startDay
      } else if(this.journalPost?.reflections[0]){
        this.stepNumber = this.journalPost?.reflections[0].startDay
      }
      else if(this.journalPost?.polls[0]){
        this.stepNumber = this.poll?.startDay
      }
    }
    this.initNames()
    this.getNoteContent(this.journalPost)
    

  }

  getNoteContent(post){
    if (post?.text) {
      let note = post.text
      var b = JSON.stringify(note);
      let testNote = b.replace(/\\/g, '');
      let testNote1 = testNote.replace(/[\[\]']+/g, '')
      this.journalNote = testNote1.replace(/"/g, '');
    }
  }

  ngOnInit() {
  
  }

  private initNames() {
    this.nameTQ = this.currentPath.getCustomText('p_tq')
    this.nameHYPA = this.currentPath.getCustomText('p_hypa')
  }
  
  openInStep(screenId){
    this.appState.set('screenId', screenId)
    this.homeUIService.dismissAllModals().then(()=>{
    if (this.journalPost.pathId === this.currentPath.id) {
      this.appState.set('currentScheduleDay', this.stepNumber);
      if (this.isMobile) {
        this.route.navigate(['/content', this.stepNumber])
      } else {
        this.homeUIService.closeAllModals(this.stepNumber)
      }
      this.appState.set('isRouteToLibrary', true) 
    }else{
      var data = {
        pathId: this.currentPath.id,
        userId: this.journalPost.userId
      }
      this.appState.set('prevPathData', data)
      setTimeout(() => {
      this.changePath(this.journalPost.pathId, this.journalPost.userId, this.stepNumber)
      },600)
    }
  });
  }

  async openNote(){

    let note = this.appState.get('singleDocNote')
    this.getNoteContent(this.journalPost);
    const alert =await this.alertController.create({
      header: this.i18n.t("Notes"),
      inputs:[
        {
          type:'textarea',
          name:'textareaValue',
          value: this.journalNote,
          cssClass:'alert-textarea'
        },
      
      ],
      buttons: [
        {
          text:this.i18n.t('Save'),
          cssClass:'custom-alert',
          handler:(alertData: AlertInput)=>{
            let noteText;
                noteText = alertData['textareaValue'];
                console.log('noteText :', noteText);
                localStorage.setItem('journalNote', noteText);
                this.appState.set('singleDocNote', noteText)
                this.journalNote = noteText
                this.saveNote()
          }
        }
      ]
    })
    await alert.present();
  }

  refresh() {
    return new Promise(resolve => {
      this.appState.refreshMainComponent.emit()
      setTimeout(resolve, 0)
    })
  }

  changePath(pathId, userId, stepNumber){
    const request = {
      'user_id': userId,
      'active_path_id': pathId
    }
    this.homeService.subpathchange(request).subscribe(
      (response) => {
        let data = response["data"];
      this.homeUIService.dismissAllModals().then(()=>{   
        try {
         
          let isOnboardContentPresent = data.onboardingSurvey || data.isIntroVideo;
          if (data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted?.length > 0 && isOnboardContentPresent) {
            let value = data['isonboardingcompleted'][0];
            this.isonboardingcompleted = value["isonboardingcompleted"];
            if ((this.isonboardingcompleted === false || this.isonboardingcompleted === null) && isOnboardContentPresent) {
              this.route.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          
            } else if (this.isonboardingcompleted === true) {
              if (this.isMobile) {
                  setTimeout(()=>{
                this.route.navigate(['/content', this.stepNumber])
              },600)          
              }
              else {
                 setTimeout(()=>{
                   this.homeUIService.openDailyStepsComponent(DailyStepsContentModalComponent,{currentDay: stepNumber},'web-daily-steps-custom-modal')
                 }, 1500);
              }
              }
          } else if(data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted.length===0 && isOnboardContentPresent){
            setTimeout(()=>{
              this.route.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          
            },600);
          } else {
            this.homeUIService.checkSubpathStatus();
            if (this.isMobile) {
                  setTimeout(()=>{
                this.route.navigate(['/content', this.stepNumber])
              },600)          
              }
              else {
                setTimeout(()=>{
                   this.homeUIService.openDailyStepsComponent(DailyStepsContentModalComponent,{currentDay: stepNumber},'web-daily-steps-custom-modal')
                }, 1500);
            }
                      
          }
        } catch (error) {
          setTimeout(()=>{
            this.route.navigate(['/main']);
            },600);        }
      });
        this.refresh()
      }, (error) => {
        console.log('error :', error);
      }
    )
  }

  clearNote(){
    if (this.isBackAllowed) {
      this.appState.set('isBackAllowed', undefined)
    }
    this.appState.set('singleDocNote', undefined)
    this.appState.set('dontRefresh', true)
    this.appState.set('isDocumentClosed',true);
  //  window.history.back()
  this.modalController.dismiss();
  }

  editNote(){
    this.isEditNote = true
  }

  saveNote(){
    this.isEditNote = false
    const requestData = {
      id: this.journalPost.id,
      text: this.journalNote
    }
    this.libraryService.editNote(requestData).subscribe(
      (response) => {
        this.toasterService.success(this.i18n.t("Note saved."))
      },
      (error) => {
        console.log('Error for feedback ', error)
      }
    )
  }
  changeSubTextFont() {
      const element = this.contentContainer?.el;
      const specificElement = element?.querySelector('p') || element?.querySelector('h1') || element?.querySelector('h2');
      if (specificElement) {
        this.render.setStyle(specificElement, 'font-size', '20px');
        this.render.setStyle(specificElement, 'font-weight', '700');
        this.render.setStyle(specificElement, 'word-break', 'break-word');
        this.render.setStyle(specificElement, 'margin-bottom', '0px');
      }
    return true;
  }
}
