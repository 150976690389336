import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'

import { AuthService } from './auth.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        // 'Content-Type' : 'application/json charset=utf-8',
        // 'Accept'       : 'application/json',
        // FIXME: Get token from the AuthService.
        'Authorization': `${ localStorage.getItem( 'token' ) }`
        // 'Authorization': `Bearer ${ AuthService.getToken() }`
      },
    })

    return next.handle(req)
  }
}