import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '../app.service';
import { CordovaService } from '../cordova.service';
import { UserComponent } from '../user/user.component';
import { HomeUiService } from '../new-design/home-ui.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-deletion-page',
  templateUrl: './deletion-page.component.html',
  styleUrls: ['./deletion-page.component.scss'],
})
export class DeletionPageComponent implements OnInit {

  isMobile: boolean

  constructor(public cordovaService: CordovaService,
    private router: Router, 
    public homeUiService: HomeUiService,
    public modalController: ModalController,
    private appState: AppState) {
      this.isMobile = this.appState.get('isMobile')
     }

  ngOnInit() {}

  close(){
  
    this.modalController.dismiss();
  }

}
