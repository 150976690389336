import { ChangeDetectorRef, Component, DoCheck, EventEmitter, OnInit, Output, ViewChildren } from '@angular/core'
import { RoutineService } from '../daily-content-v2/routine.service';
import { HabitService } from '../habit/habit.service';
import { CalendarService } from '../calendar.service';
import { collapseAnimation, collapseOnLeaveAnimation, expandOnEnterAnimation, slideOutRightAnimation } from 'angular-animations';
import { CompletedReviewModalComponent } from '../completed-review-modal/completed-review-modal.component';
import { IonItemSliding, ModalController, Platform } from '@ionic/angular';
import { RoutineV2Service } from '../routine-v2/routine-v2.service';
import { AppState } from '../app.service';
import { DailyStepsService } from '../daily-steps/daily-steps.service';
import { HomeV5Service } from '../pages/home-v5/home-v5.service';
import { HomeUiService } from '../new-design/home-ui.service';
import { CordovaService } from '../cordova.service';
import { I18n } from '../i18n.service';
import { FullScreenDocumentComponent } from '../my-library/full-screen-document.component';
import { FullScreenVideoComponent } from '../full-screen-video/full-screen-video.component';
import { FullScreenAudioComponent } from '../full-screen-audio/full-screen-audio.component';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackFormComponent } from '../components/commitments/feedback-form/feedback-form.component';
import { SpinnerService } from '../spinner.service';
import { ToasterService } from '../toaster.service';
import { MyCommitmentsService } from '../my-commitments/my-commitments.service';
import { Router } from '@angular/router';
import { DailyStepsContentModalComponent } from '../new-design/daily-steps-content-modal/daily-steps-content-modal.component';

export interface CalendarParams {
  title: String,
  startDate: Date
  endDate: Date,
  eventLocation: String,
  notes: String,
  endEventTime: any,
  frequency: string
}
@Component({
  selector: 'app-action-hub',
  templateUrl: './action-hub.component.html',
  styleUrls: ['./action-hub.component.scss'],
  animations: [
    slideOutRightAnimation({ duration: 500 }),
    collapseAnimation({ duration: 150 }),
    expandOnEnterAnimation({ duration: 250 }),
    collapseOnLeaveAnimation({ duration: 250 })
  ]
})
export class ActionHubComponent implements OnInit, DoCheck {

  active_todoLevelsList = []
  habitCommitmentIndex;
  actionCommitmentIndex;
  routineClicked = []
  isRoutineExpanded: boolean[] = [];
  actionClicked = []
  isActionExpanded: boolean[] = [];
  isDataFetched = false;
  @ViewChildren('slidingItem') slidingItem: IonItemSliding[];
  calendarParams: CalendarParams
  todoLevelsList: any []
  currentRoutine: any
  currentUser: any
  currentPath: any
  isMobile;
  todoRoutines: any
  todoActions: any =[]
  checked: boolean = false
  actionId: number
  videoFormats = ['.mp4', '.mov', '.avi', '.avchd', '.f4v', '.mkv', '.wmv', 'youtube.com', 'webm', 'html5', 'HTML5', 'watch'];
  audioFormats = [".mp3"];
  selectedLevels = []
  isActionTaken = false;
  isInitiallyLoadedToDo = false;
  isIonListActive = false;
  isContentVisible = false;
  active_levels_length = 0;
  @Output() onLogaRep: any = new EventEmitter<any>();
  @Output() screenCompletionDataEvent = new EventEmitter<any>();
  constructor(public modalController: ModalController,
    private appState: AppState,
    private routineService: RoutineV2Service,
    private dialog: MatDialog,
    private dailyStepsService: DailyStepsService,
    private homev5Service: HomeV5Service,
    private cdRef: ChangeDetectorRef,
    public homeUIService: HomeUiService,
    public i18n: I18n,
    public route: Router,
    public platform : Platform,
    private habitService: HabitService,
    private spinnerService: SpinnerService,
    private toasterService: ToasterService,
    public commitService: MyCommitmentsService,
     private dailyStepsContent: DailyStepsContentModalComponent,
    ) {
    this.currentUser = this.appState.get('currentUser')
    this.currentPath = this.appState.get('currentPath')
    this.fetchItemsInTodoList();
    this.isMobile = this.appState.get('isMobile');
  }
  ngOnInit() {
    this.getActions(this.currentUser.id, this.currentPath.id)
   }

  ngDoCheck(): void {
    this.todoLevelsList = this.homev5Service.removeDuplicates(this.appState.get('todoRoutines'), "routine_info_id");
    this.sortOnlyActiveHabits()
    this.todoActions = this.appState.get('todoActions')
  }
  fetchItemsInTodoList() {
    this.isInitiallyLoadedToDo = true;
    let routine= this.appState.get('todoRoutines')
    let action = this.appState.get('todoActions')
    if (routine?.length > 0) {
      this.todoLevelsList = this.homev5Service.removeDuplicates(routine, "routine_info_id");
      this.sortOnlyActiveHabits()
    }
    if (action.length > 0) {
      this.todoActions = action;
    }
    console.log("routines and actions", routine,action)
    
  }

  sortOnlyActiveHabits() {
   // let todoLevelsList =[]
    this.todoLevelsList = this.todoLevelsList?.filter(habit => 
    habit?.routine && habit?.routine?.some(level => level?.is_unlocked ==true  && level?.is_level_completed === false));
    //this.todoLevelsList = todoLevelsList;
  }
  getActions(userId: number, pathId: number) {
    const requestData = {
      'user_id': userId,
      'path_id': pathId
    }
    this.dailyStepsService.getActionsList(requestData).subscribe((response) => {
      console.log("etered")
      if (!this.isInitiallyLoadedToDo) {
        this.todoLevelsList = this.homev5Service.removeDuplicates(response.data[0].routine, "routine_info_id");
        this.sortOnlyActiveHabits()
        this.todoActions = response.data[0].action
      }
      this.appState.set('todoRoutines', this.homev5Service.removeDuplicates(response.data[0].routine, "routine_info_id"))
      this.appState.set('todoActions', response.data[0].action)
       if (!this.isActionTaken && this.isDataFetched) {
          setTimeout(() => {
            this.isActionTaken = true;
          },0);
        }
       this.isDataFetched =false
      this.isInitiallyLoadedToDo = false;
      this.cdRef.detectChanges();
    }, (error) => {
      console.log('error :', error);
       this.isDataFetched =false
    })
  }
  async openReviewModal(event: Event, action) {

    const skipButton = document.querySelector('.skip-btn');
    if (skipButton) {
      skipButton.classList.add('skip-btn-change');
    
      // If you want to remove the class after some time
      setTimeout(() => {
        skipButton.classList.remove('skip-btn-change');
      }, 2000);
    }

    //event.stopPropagation();
    this.actionId = action.id
    this.checked = true
    this.habitConfirm_condensed(action);
  }

  habitConfirm_condensed(habit) {
    var isHabitComplete = habit.confirmation
    try {
      // this.spinnerService.on()
      this.habitService.confirm(habit.id).subscribe(res => {
        // this.spinnerService.off()
        habit.confirmed = true
        habit.scheduledLater = false
        this.cdRef.detectChanges()
         try {
      if (this.currentPath.enabledAttributes.feedback && isHabitComplete == 0) {
        this.inithabitModal(habit)
      }
      else {
        this.isDataFetched =true
        this.closeOpenItems();
        this.getActions(this.currentUser?.id, this.currentPath.id);
        this.cdRef.detectChanges()
      }
    } catch (o_O) {
      console.log(o_O);
    }
      })
    } catch (o_0) {
      console.log('Errors ', o_0)
    }
   
  }
  async inithabitModal(habit) {
    // this.appState.set('currentDay', habit.startDay)
    const modal = await this.modalController.create({
      component: CompletedReviewModalComponent,
      componentProps: { currentHabit: habit },
      cssClass: this.isMobile ? 'mobile-custom-to_do_list-forms' : 'web_feedbackForm_modal'
    });
    modal.onDidDismiss().then((modalDataResponse) => {
      if (modalDataResponse !== null) {
        this.appState.set("isCommented", false)
      }
      this.appState.set('updateActionPoints', true)
       this.isDataFetched =true
      this.closeOpenItems();
      this.getActions(this.currentUser.id, this.currentPath.id)
    });
    return await modal.present();
  }
  getRoutineLevelInfo(routine, index) {
    this.active_todoLevelsList[index] = this.homeUIService.getRoutineLevel(routine)
    this.active_levels_length = this.active_todoLevelsList[index]?.length
    if (this.active_todoLevelsList[index]?.length > 0) {
      return true
    }
    else {
      return false;
    }
  }

  closeOpenItems(index?) {
    if (this.slidingItem) {
      this.slidingItem.forEach((item) => {
        item.closeOpened();
        this.actionCommitmentIndex = null;
        this.habitCommitmentIndex = null;
      });
    }
  }

  openOptions(item: IonItemSliding, index, type) {
    this.isIonListActive = !this.isIonListActive;
    this.closeOpenItems(index);
    if (type == 'action') {
      this.actionCommitmentIndex = index;
    }
    else {
      this.habitCommitmentIndex = index;
    }
    item.open('start');
  }

  toggleExpandRoutine(index) {
    this.isContentVisible = !this.isContentVisible;
    if (this.routineClicked[index]) {
      setTimeout(() => {
        this.routineClicked[index] = !this.routineClicked[index]
      }, 200);
    }
    else {
      this.routineClicked[index] = !this.routineClicked[index]
    }
    this.isRoutineExpanded[index] = !this.isRoutineExpanded[index];
  }
  
  getLevelTitle(level) {
    return level?.title
  }

  toggleExpandAction(index: number) {
    if (this.actionClicked[index]) {
      setTimeout(() => {
        this.actionClicked[index] = !this.actionClicked[index]
      }, 200);
    }
    else {
      this.actionClicked[index] = !this.actionClicked[index]
    }
    this.isActionExpanded[index] = !this.isActionExpanded[index];
  }

  openDocument(post) {
    //let post=this.actionList
    let doc = this.appState.get('singleDocument');
    if (post?.id == doc?.id) {
      post = this.appState.get('singleDocument')
    }
    console.log('casual post :', post);
    // let post;
    // posts?.forEach((doc)=>{
    //   if(doc?.id ==id){
    //     post =doc
    //   }
    // })
    this.appState.set('singleDocument', post)
    this.appState.set('journalPost', undefined)
    if (post.isrecommended && post.isrecommended === true) {
      this.appState.set('isCasualDocument', true)
    }
    if (this.isMobile) {
      this.dialog.closeAll();
    }
    else {
      // this.modalController.dismiss();
    }
    this.appState.set('journalPathName', this.currentPath.userGroup.name)
    this.appState.set('journalStepNumber', post.startDay)
    const url = post.link;
    if (this.audioFormats.some(el => url.includes(el))) {
      console.log('Audio File');
      // this.route.navigate(['/audio-library'], { state: { data: post } })
      if (this.isMobile) {
        this.homeUIService.openNextComponent(FullScreenAudioComponent, { data: post })
      }
      else {
        this.homeUIService.openNextComponent(FullScreenAudioComponent, { data: post }, 'desktop-library-custom')
      }
    } else if (this.videoFormats.some(el => url.includes(el))) {
      console.log('Video File');
      // this.route.navigate(['/video-library'], { state: { data: post } })
      if (this.isMobile) {
        this.homeUIService.openNextComponent(FullScreenVideoComponent, { data: post })
      }
      else {
        this.homeUIService.openNextComponent(FullScreenVideoComponent, { data: post }, 'desktop-library-custom')
      }
  
    } else {
      console.log('Document');
      // this.route.navigate(['/detail-document'])
      if (this.isMobile) {
        this.homeUIService.openNextComponent(FullScreenDocumentComponent)
      }
      else {
        this.homeUIService.openNextComponent(FullScreenDocumentComponent, '', 'desktop-library-custom')
      }
  
       
    }
  }
  
  getTitle(title) {
    return title.slice(15, -1)
  }

  async showFeedbackForm(subItem) {
    let currentRoutine = this.homeUIService.getRoutineByLevel(subItem, this.todoLevelsList)
    let repsIndex = subItem?.reps_commit.findIndex((item) => item.is_completed === false);
    if (repsIndex > -1) {
      const modal = await this.modalController.create({
        component: FeedbackFormComponent,
        componentProps: {
          mainItem: currentRoutine,
          subItem: subItem,
          index: repsIndex
        },
        cssClass: this.isMobile ? 'mobile-custom-to_do_list-forms' : 'web_feedbackForm_modal'
      });
      modal.onDidDismiss().then((modelData) => {
        if (modelData !== null && modelData.data !== undefined) {
          this.onLogaRep.emit(modelData.data);
          this.isDataFetched =true
          this.logaRep(currentRoutine, subItem, repsIndex)
        }
        else {
          this.closeOpenItems();
        }
      });
      return await modal.present();
    } else {
      this.cdRef.detectChanges()
      console.log('Complted level')
    }
  }
   getReps(item) {
    return item.reps_commit
   }
  
   getCurrentRoutine(routine, level) {
    let unCheckedLevels = [];

    this.currentPath.routineInfo.forEach(currentRoutine => {
      if (currentRoutine.id === routine.routine_info_id) {
        currentRoutine.levels.forEach(routineLevel => {
          routine.routine.forEach(myLevel => {
            const match =routine.routine.filter((level)=>level.id === routineLevel.id)
            if (myLevel.id !== routineLevel.id && (match.length === 0)) {
              routineLevel.enabled = false;
              unCheckedLevels.push({ 'id': routineLevel.id, 'index': currentRoutine.levels.indexOf(routineLevel) })
            }
          });
        });
        unCheckedLevels = [...new Set(unCheckedLevels)];
      }

      // function getUnique(arr, comp) {
      //   const unique = arr.map(e => e[comp])
      //     .map((e, i, final) => final.indexOf(e) !== i && i)
      //     .filter((e) => arr[e]).map(e => arr[e]);

      //   return unique;
      // }
      let unselectedIndex = routine.routine.findIndex(item => item.id === level.id);
      // this.appState.set('indexOfLevel', unselectedIndex + 1)
       let unCommittedLevel, unCommitedIndex;
      unCommittedLevel = this.homeUIService.getRoutineLockedLevel(routine)
      if (level?.is_unlocked == true && unCommittedLevel) {
        unCommitedIndex = routine.routine.findIndex(item => item.id === unCommittedLevel?.id);
        this.appState.set('indexOfLevel', unCommitedIndex)
      }
      else {
         this.appState.set('indexOfLevel', unselectedIndex+1)
      }
      //this.appState.set('levelUpdated', true)
    });
     unCheckedLevels= unCheckedLevels.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));
      this.appState.set('unCheckedLevels',[...new Set(unCheckedLevels)] )
    this.selectedLevels = [];
     routine.routine.forEach(element => {
      let isPresentInUncheckedLevel = unCheckedLevels?.findIndex(item => item?.id === element.id)
      if (!element.enabled && isPresentInUncheckedLevel==-1) {
        element.enabled = true;
        this.selectedLevels.push({ 'id': element.id, 'index': routine.routine.indexOf(element) })
      }
      else if(element.enabled) {
         this.selectedLevels.push({ 'id': element.id, 'index': routine.routine.indexOf(element) })
      }
    });
    this.selectedLevels=[...new Set(this.selectedLevels)]
    this.appState.set('selectedLevelIds', this.selectedLevels)
  }

   logaRep(routine: any, level: any, index: any) {
    let repsCommitArray = routine.routine.filter(item => item.reps_commit)
    let repsArray = repsCommitArray.map(this.getReps)
    let totalRepsArray = Array.prototype.concat.apply([], repsArray);
    let totalLevelRepsArray = level.reps_commit.filter(item => item.is_completed === false)

    if (level.is_unlocked === false) {
      this.toasterService.warning(this.i18n.t('Please complete previous level.'))
    } else {
      //this.spinnerService.on();
      this.commitService.setLoader(false);
      const requestData = {
        'user_id': this.currentUser.id,
        'path_id': routine.path_id,
        'routine_info_id': routine.routine_info_id,
        'level_id': level.id,
        'schedule_day': routine?.schedule_day,
        'feedback': localStorage.getItem('repLogText'),
        'step': index + 1
      }

      // this.getRoutines(this.currentUser?.id, this.currentPath.id);
      this.routineService.replog(requestData).subscribe(
        (response) => {
          let object = null
        //  this.spinnerService.off();
          this.commitService.setLoader(true);
          // add reply to the local path routine_info
          if (response && response.data && response.data.routine_info_id) {
            object = this.appState.get('currentPath').routineInfo.find(q => q.id === response.data.routine_info_id)
          }

          this.toasterService.success(this.i18n.t('Rep Logged!'))
          this.closeOpenItems();
          console.log('Response for feedback ', response)
          this.getActions(this.currentUser?.id, this.currentPath.id);
          this.cdRef.detectChanges();
          var isLastRepLogged = level.reps_commit.filter(item => item.is_completed === true)

          const last = routine.routine[routine.routine.length - 1];
          this.appState.set('isTodoReps', undefined)
          // if (level.reps_commit.length == isLastRepLogged.length + 1  && last.id === level.id) {
          //   this.checkLastRep(routine)
          // } else
            if (totalLevelRepsArray.length - 1 === 0 ) {
            // this.appState.set('LevelNumber', level.level + 1)
             this.appState.set('levelUpdated', undefined)
            this.getCurrentRoutine(routine,level)
              // this.appState.set('showUpdatedScreens', true)
              var data = {
                levelUpdated: true,
                routine: routine,
                level: level
              }
              this.appState.set('levelUpdateData', data)
              this.appState.set('isLevelReoptedFromActionHubScreen', true)
              if (this.isMobile) {
                this.route.navigate(['/main'])
              }
              else {

                this.dailyStepsContent.close('isInstepTodoScreen');
             }
            //this.homeUIService.openAlertGiveFeedbackCommitmentComponent(routine,level)
            // if (routine.path_id === this.currentPath.id) {
            //   this.appState.set('isLevelCommited',false);
            //   this.appState.set("allowScreenCount", undefined)
            //   // TODO: differentiate between mobile and web for routing
            //   if (this.isMobile) {
            //     this.route.navigate(['/content', this.routineDay], { queryParams: { startFromScreen: 'daily-content-screen-routine' } })
            //   } else {
            //     this.commitService.closeModals()
            //     setTimeout(() => {
            //       this.homeUIService.openNextComponent(DailyStepsContentModalComponent,{currentDay: this.routineDay,startFromScreen: 'daily-content-screen-routine'},'web-daily-steps-custom-modal')                  
            //     }, 1100);
            //   }
              
            // } else {
            //   this.changeSubPath(routine.path_id, this.routineDay)
            // }
          } else {
            console.log('inside else:',);
          }

        },
        (error) => {
          //this.spinnerService.off()
          this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."));
          console.log('Error for feedback ', error)
        }
      )
    }
  }
  
  nextStepEndScreen() {
    this.screenCompletionDataEvent.emit({ 'isNextButttonClicked': true })
  }  
}

