import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AppState } from 'src/app/app.service';

@Component({
  selector: 'app-home-weekly-tracker',
  templateUrl: './home-weekly-tracker.component.html',
  styleUrls: ['./home-weekly-tracker.component.scss'],
})
export class HomeWeeklyTrackerComponent implements OnInit {
  
  weekDays = []
  pathRegistration: any
  path;
  isMobile: boolean
  isMobileWebIos = false;
  isMobileWeb=false;
  constructor(private appState: AppState) {
    this.pathRegistration = this.appState.get('pathRegistration')
    this.isMobile = this.appState.get('isMobile')
  }
  
  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    console.log("this.isMobileWeb value", this.isMobileWeb);
    this.path= this.appState.get('currentPath');
    if(!this.appState.get('weekDays')){
      this.generateWeekDays()
    }else{
      this.weekDays = this.appState.get('weekDays')
      this.getFilledDays(this.weekDays)
    }
  }

  ngDoCheck(){
    if(this.appState.get('weekDays')){
      this.weekDays = this.appState.get('weekDays')
    } 
  }

  generateWeekDays() {
    const startOfWeek = moment().startOf('week');
    for (let i = 0; i < 7; i++) {
      this.weekDays.push({
        initial: startOfWeek.locale(this.path.language).format('ddd').charAt(0),
        day: startOfWeek.locale(this.path.language).format('ddd').substring(0,3),
        date: startOfWeek.locale(this.path.language).format('YYYY-MM-DD') 
      });
      startOfWeek.add(1, 'day');
    }
    const currentDate = moment().locale(this.path.language).format('YYYY-MM-DD'); // Get the current date
     this.weekDays = this.weekDays.map(day => {
       const dateToCheck = `${day.date}`;
      if (this.pathRegistration.loggedInDates.includes(dateToCheck)) {
        return { ...day, isFilled: true };
      } else {
        return day;
      }
    }).map(day => {
      const dayDate = moment(day.date, 'YYYY-MM-DD');
      if (moment(`${day.date}`).format('YYYY-MM-DD') === currentDate) {
        return { ...day, isCurrentDay: true };
      } else if (dayDate.isBefore(currentDate, 'day')) {
      return { ...day, isPreviousDay: true };
    } else {
        return day;
      }
    });
    this.getFilledDays(this.weekDays)
    return this.weekDays;
  }

  getFilledDays(weekDays){
   let data = weekDays.filter(item => item.isFilled === true)
   this.appState.set('daysVisited',data.length)
  }

  

}
