import { Injectable } from '@angular/core';
import { CordovaService } from './cordova.service';
import { Router } from '@angular/router';
import { AppState } from './app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

declare var Microsoft: any;
var AuthenticationContext: any;
var authority =
  "https://login.microsoftonline.com/cf90b97b-be46-4a00-9700-81ce4ff1b7f6";
var resourceUrl = "https://graph.windows.net/";
var appId = "66d51746-0586-4475-a3a9-92329a225a6a";
var redirectUrl = "http://localhost:4400/services/aad/redirectTarget.html";
var authContext: any;

interface SignInForm {
  email: string
  ssoUid: string
  ssoId: string
  isSso?:boolean // LUMBA-1402
  changePath?: boolean // LUMBA-982
  fromMobile?: boolean // LUMBA-982
}

export interface Info {
  
}

@Injectable()
export class SingleSignOnService {

  isIframe = false;
  loggedIn = false;
  loading=false;

  signInForm: SignInForm = {
    email: '',
    ssoUid: '',
    ssoId: '',
    isSso:null,// LUMBA-1402
    changePath: null,
    fromMobile: null,
  }

  valid: boolean;
  error: any;
  expired: boolean;
  client: any;
  isLoading: boolean;
  updateLogins: Subject<boolean> = new Subject<boolean>();



  constructor(
    private cordovaService: CordovaService,
    //private broadcastService: BroadcastService, 
    private router: Router,
    //private authService: MsalService,
    private spinner: NgxSpinnerService,
    private appState: AppState
    ) { 

    if (this.cordovaService.cordova && authContext == null) {
      console.log( "Authentication context isn't created yet. Create context first");
      this.createContextForMobileSSO();
    }

   


  }



  showSpinner(){
    this.spinner.show()
  }

  hideSpinner(){
    this.spinner.hide()
  }

  toggleSpinner(value:boolean){

    if(value){
      this.spinner.show()
    }else{
      this.spinner.hide()
    }

  }

  createContextForMobileSSO() {
   
    try{
      AuthenticationContext = Microsoft.ADAL.AuthenticationContext;

      AuthenticationContext.createAsync(authority).then(function (context) {
        authContext = context;
        console.log(
          "Created authentication context for authority URL: " + context.authority
        );
      }, console.log("Failed to authentication context for authority URL "));
    }catch(e){

    }

   
    
  }

  mobileSSOMsalAccessLogin() {

    if (authContext == null) {
      console.log("Authentication context isn't created yet. Create context first");
      this.createContextForMobileSSO();
      return
    }

    this.clearTokenCache()

    this.showSpinner()

    authContext.acquireTokenAsync(resourceUrl, appId, redirectUrl).then(
      (authResult) => {
        console.log("Acquired token successfully: " + authResult);
        var userInfo = authResult.userInfo;
        this.serverLoginSSO(userInfo.uniqueId,authResult.tenantId,userInfo.userId,true)
      },
      (err) => {
        this.updateLogins.next(false);
        //this.notificationService.alert('', "Authentication cancelled")
        console.log("Failed to acquire token: " + err);
      }
    );
  }


  checkLoggedWithSSO(): boolean{
    var isLoginData = false;
    if (this.cordovaService.cordova){ 
    if (authContext == null) {
      console.log('Authentication context isn\'t created yet. Create context first');
      return;
  }

   authContext.tokenCache.readItems()
    .then((res) => {

      console.log('step 4');

        var text = "Read token cache successfully. There is " + res.length + " items stored.";
        console.log(text);

        if (res.length > 0) {
            text += "The first one is: " + res[0];
            var authResult = res[0]
           

        }
        console.log(text);

    }, (err) => {
      isLoginData = false
      console.log("Failed to read token cache: " + err);
    });

  }

  return isLoginData;
  }



  clearTokenCache() {
    if (authContext == null) {
      console.log('Authentication context isn\'t created yet. Create context first');
        return;
    }

    authContext.tokenCache.clear().then( (response) => {
      console.log("Cache cleaned up successfully.");
    },  (err) => {
      console.log("Failed to clear token cache: " + err);
    });
}



  // initSSOMsalWeb(){
  //   this.isIframe = window !== window.parent && !window.opener;

  //   this.checkoutAccountSSOWeb();
    
  //   this.broadcastService.subscribe('msal:loginSuccess', () => {
  //     this.checkoutAccountSSOWeb();
  //   });

  //   this.authService.handleRedirectCallback((authError, response) => {

  //     if (authError) {
  //       console.log('Redirect Error: ', authError.errorMessage);
  //       return;
  //     }

  //     this.showSpinner()
  //     this.serverLoginSSO(response.account.userName,response.tenantId,response.uniqueId,true)

  //   });

  //   this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
  //     console.log('MSAL Logging: ', message);
  //   }, {
  //     correlationId: CryptoUtils.createNewGuid(),
  //     piiLoggingEnabled: false
  //   }));
  // }



  logoutMobileSSO() {

    if (authContext == null) {
        console.log('Authentication context isn\'t created yet. Create context first');
        return;
    }

    authContext.tokenCache.clear().then(function () {
      console.log("Cache cleaned up successfully.");
    }, function (err) {
      console.log("Failed to clear token cache: " + err);
    });
  
  }


  // api for verify sso user
 serverLoginSSO(email,SsoId,SsoUid,isSso){

  console.log("Hello 2");
  
    
    if (this.signInForm) {   

      // if (!this.signInForm.pathGroupId) {
      //   const activePathId = this.appState.get('activePathId')
      //   if (activePathId && this.appState.get('changePath')) {
      //     this.signInForm.pathId = parseInt(activePathId, 10)
      //     this.signInForm.changePath = true
      //   }
      // }

      this.signInForm.email = email;
      this.signInForm.ssoId = SsoId;
      this.signInForm.ssoUid = SsoUid;
      this.signInForm.isSso= isSso;
      this.signInForm.changePath = true
      this.signInForm.fromMobile = this.cordovaService.onCordova
    }

    // this.sessionService.newSessionWithSSO(this.signInForm)
    // .subscribe(
    //   result => {
    //     this.updateLogins.next(false)
    //     this.toastrService.success(this.i18n.t('You’re in!'))

    //     if (result.expiredOrRemoved) {
    //       this.expired = true
    //       this.client = result.client
    //     } else if (result) {
    //       localStorage.setItem('lastUserEmail', this.signInForm['email']) // LUMBA-982
    //       if (result.showOnboardingSurvey) {
    //         this.router.navigate(['/onboarding'])
    //       } else if (result.showCloseout) {
    //         this.router.navigate(['/closeout-welcome'])
    //       } else {
    //         this.router.navigate(['/main'])
    //       }
    //     } else {
    //       // TODO: Handle false response? Try again?
    //     }
    //     this.refresh()
    //   },
    //   errorResponse => {

    //     this.updateLogins.next(false)

    //     let messages
    //     if (typeof errorResponse.graphQLErrors  !== 'undefined' && errorResponse.graphQLErrors .length > 0) {
    //       messages = errorResponse.graphQLErrors.map(e => this.i18n.t(e.message))
    //     } else {
    //       messages = ['Server or connection issue. Please check your internet connection.']
    //     }
    //     this.valid = false
    //     this.error = messages.join('<br>')
    //     this.notificationService.alert('', this.error)

    //   })
  }


  // checkoutAccountSSOWeb() {
  //   console.log("What is status of login ",this.loggedIn);
  //   this.loggedIn = !!this.authService.getAccount();
  // }

  refresh() {
    return new Promise(resolve => {
      // here to fetch the data and rerender the contents.
      this.appState.refreshMainComponent.emit()
      setTimeout(resolve, 0)
      
    })

  }

}
