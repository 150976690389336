import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Filter } from '../shared/filter'
import * as query from './reply.query'
import { Reply } from './reply.model'
import { map } from 'rxjs/operators'
import { Observable, BehaviorSubject, Subject } from 'rxjs'
import { Question } from '../question/question.model'
import { AppState } from '../app.service'
import { RoutineInfo } from '../routine-v2/routine-info.model'
import { REPLY_REPLIES_FRAGMENT } from './reply.query'

export interface VoteReply{
  [voteReply: string]: any
}

@Injectable()
export class ReplyService {

  public replyVote: VoteReply
  private propertyValueSubject = new Subject<string>();
  propertyValue$ = this.propertyValueSubject.asObservable();

 

  constructor(private apollo: Apollo, private appState: AppState) { }
  setPropertyValue(value) {
    this.propertyValueSubject.next(value);
  }
  getList(filter: Filter = null): Observable<Reply[]> {
    return this.apollo
      .watchQuery<any>({ query: query.listReplies, variables: { filter: filter } })
      .valueChanges
      .pipe(
        map(response => {
          let list: Reply[] = []
          response.data.replies.forEach(element => { list.push(new Reply(element)) })
          return list
        })
      )
  }

  getReply(id: number): Observable<Reply> {
    return this.apollo
      .watchQuery<any>({ query: query.findReply, variables: { id: id } })
      .valueChanges
      .pipe(
        map(response => {
          return new Reply(response.data.reply)
        })
      )
  }

  save(reply: Reply) {
    return this.apollo.mutate({
      mutation: query.updateReply,
      variables: {
        reply: reply.input()
      }
    })
  }

  voteReply(reply: Reply, mode: string) {
    return this.apollo.mutate({
        mutation: query.voteReply,
        variables: {
          replyId: reply.id,
          mode: mode
        },
        optimisticResponse: {
          __typename: 'Mutation',
          voteReply: {
            status: null,
            actionPoints: null
          },
        },
        fetchPolicy: 'no-cache',
        update: (proxy, { data: { voteReply: { status } } }) => {
          console.log("status", status);
          proxy.writeFragment({
            id: `Reply:${reply.id}`,
            fragment: query.REPLY_VOTE_FRAGMENT,
            data: { hasVoted: status, votesCount: status ? reply.votesCount + 1 : reply.votesCount - 1 }
          })
        }
      }).pipe(
        map(response => {
          console.log(response)
          this.replyVote = response.data
          return this.replyVote.voteReply.status
        })
      )
  }

  reply(question: any, replyId: number, text: string, attachmentUrl: string = null,userIds:any,rootId:any=null,mode : string = 'question') {
    let user = this.appState.get('currentUser')
    let optimisticId = 2000000000 + Math.round(Math.random() * 1000000000)
    console.log("question detailas before call",question, typeof(question))
    return this.apollo.mutate({
        mutation: query.replyReply,
        variables: {
          replyId: replyId,
          text: text,
          attachmentUrl: attachmentUrl,
          userIds:userIds,
          mode:mode,
          rootId:rootId
        },
        optimisticResponse: {
          __typename: 'Mutation',
          replyReply: {
            __typename: 'ReplyQuestionPayload',
            optimistic: true,
            status: true,
            reply: {
              level: 2,
              linkThumbnails: null,
              attachmentUrl: null,
              votesCount: 0,
              id: optimisticId,
              text: text,
              repliableId: replyId,
              replies:[],
              user: {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                imageUrl: user.imageUrl,
                advanceColour: '',
                userBgColor: user.userBgColor,
                __typename: "User",
              },
              createdAt: '',
              updatedAt: '',
              enabled: true, 
              __typename: 'Reply'
            }
          },
        },        
      update: (proxy, { data: { replyReply } }) => {
        let reply = replyReply.reply
        console.log("question._data?.replies", question, reply);
          if (question.id) {
            // Questions
            proxy.writeFragment({
              id: `Question:${question.id}`,
              fragment: query.REPLY_REPLIES_FRAGMENT,
              data: {replies: question?._data?.replies?.length>0? [...question._data['replies'], reply]: reply}
            })

            let object=null
            if(mode=="habit"){
              console.log("Habit is started");
              
               object = this.appState.get('currentPath').habits.find(q => q.id === question.id)
            }else if(mode=="question"){
              
              object = this.appState.get('currentPath').questions.find(q => q.id === question.id)
              this.appState.get('currentPath')?.update('questions', question.id, {isCompleted: true})
            }

            if (replyReply.optimistic) {
              console.log("reply",reply)
              console.log("object",object)
              //object.insertReply(new Reply(reply))
            } else {
              console.log("reply",reply)
              console.log("object",object)
              //object.replaceReply(optimisticId, new Reply(reply))
            }
          } else {
            // Chatboard
            const object = this.appState.get('currentChatBoard')
            if (replyReply.optimistic) {
             // object.insertReply(new Reply(reply))
            } else {
             // object.replaceReply(optimisticId, new Reply(reply))
            }
          }
        }
      }).pipe(
        map(response => {
          return new Reply(response.data.replyReply.reply)
        })
      )
  }  


  replyRoutine(question: any, replyId: number, text: string, attachmentUrl: string = null,userIds:any,mode : string = 'routineInfo',rootId:number=null) {
    let user = this.appState.get('currentUser')
    let optimisticId = 2000000000 + Math.round(Math.random() * 1000000000)
    return this.apollo.mutate({
        mutation: query.replyReply,
        variables: {
          replyId: replyId,
          text: text,
          attachmentUrl: attachmentUrl,
          userIds:userIds,
          mode: mode,
          rootId:rootId
        },
        optimisticResponse: {
          __typename: 'Mutation',
          replyReply: {
            __typename: 'ReplyQuestionPayload',
            optimistic: true,
            status: true,
            reply: {
              level: 2,
              linkThumbnails: null,
              attachmentUrl: null,
              votesCount: 0,
              id: optimisticId,
              text: text,
              repliableId: replyId,
              user: {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                imageUrl: user.imageUrl,
                advanceColour: '',
                userBgColor: user.userBgColor,
                __typename: "User",
              },
              createdAt: '',
              updatedAt: '',
              enabled: true, 
              __typename: 'Reply'
            }
          },
        },        
        update: (proxy, { data: { replyReply } }) => {
          let reply = replyReply.reply
          if (question.id) {
            // Questions
            proxy.writeFragment({
              id: `Question:${question.id}`,
              fragment: query.REPLY_REPLIES_FRAGMENT,
              data: {
                replies: question?._data?.replies?.length>0? [...question._data['replies'], reply]: reply
              }
            })

            let object=null
            if(mode=="routineInfo"){
              console.log("routineInfo is started");
               let routineInfo = this.appState.get('currentPath').routineInfo.find(q => q.id === question.routine_info_id)
               object = routineInfo?.routineAnswerList?.find(q => q?.id === question?.id)
               console.log('routineInfo', routineInfo);
               
            }

            if (replyReply.optimistic) {
              console.log("reply",reply)
              console.log("object",object)
             // object.insertReply(new Reply(reply))
            } else {
              console.log("reply",reply)
              console.log("object",object)
             // object.replaceReply(optimisticId, new Reply(reply))
            }
          } else {
            // Chatboard
            const object = this.appState.get('currentChatBoard')
            if (replyReply.optimistic) {
              object.insertReply(new Reply(reply))
            } else {
              object.replaceReply(optimisticId, new Reply(reply))
            }
          }
        }
      }).pipe(
        map(response => {
          return new Reply(response.data.replyReply.reply)
        })
      )
  } 

}
