import { Base } from '../shared/base'
import { Option } from '../option/option.model'
import { Schedulable } from '../shared/schedulable'
import { TextScreen } from '../text-screen/text-screen.model'

export interface PollStats {
  ids: number[]
  labels: string[]
  data: number[]
}
export enum BenchmarkType {
  PathAverage,
  Custom,
  PriorAnswer
}
export interface PollAnswer {
  optionId: number
  isCorrect: boolean
  actionPoints: number
  stats: PollStats
}

export class Poll extends Base implements Schedulable {

  init() {
    this._fields = ['id', 'title', 'rationale',
      'startDay', 'endDay', 'isMandatory', 'enabled', 'actionPoints',
      'preScreens', 'postScreens','benchLabel','benchmarkType','priorQuestionId','benchmarkNumber',
      'options', 'status', 'answer', 'screenOrder','time', 'ptype', 'lowerlabel', 'higherlabel', 'lowerscale', 'higherscale', 'isCompleted']

    this._nestedFields = {
      options: Option
    }

    this.options = []
    this.preScreens = []
    this.postScreens = []
    this.screenOrder =  0
  }

  afterInit() {
    // const LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
    // this.options.forEach((option, index) => {
    //   option.text = LETTERS[index] + ': ' + option.text
    //   option.letter = LETTERS[index]
    // })
    this.preScreens = this.preScreens || []
    this.postScreens = this.postScreens || []
    if (!this.answer) {
      return true
    }
    this.isCompleted = true
    this.isClicked = true
    let selectedOption = this.options.find(option => {
      return option.id == this.answer.optionId
    })
    selectedOption.isSelected = true
    selectedOption.isCorrect = this.answer.isCorrect
  }

  public id: number
  public title: string
  public rationale: string
  public options: Option[]
  public startDay: number
  public endDay: number
  public screenOrder: number
  public isMandatory: boolean
  public isCompleted: boolean
  public enabled: boolean
  public stats: any
  public status: string
  public answer: PollAnswer
  public preScreens: TextScreen[]
  public postScreens: TextScreen[]
  public time: any
  public actionPoints: number
  public isClicked: boolean

  public ptype: string
  public lowerlabel: string
  public higherlabel: string
  public lowerscale: number
  public higherscale: number
  public benchLabel: string;
  public benchmarkType: BenchmarkType
  public priorQuestionId: number
  public benchmarkNumber: string
  getTitle() {
    return 'Daily Question'
  }

  getTitleKey() {
    return 's_pq'
  }

  getDescription() {
    return this.title
  }

  getColorClass() {
    return 'stat-color-1'
  }

  completeBy() {
    return 'ByType'
  }
}
