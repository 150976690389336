import { Reply } from "../reply/reply.model"
import { Base } from "../shared/base"

export class RoutineLevel extends Base {
  init() {
    this._fields = ['id', 'title', 'text', 'reps', 'level','enabled','isCompleted','repsData','replies', 'isLevelCompleted','isNotificationSent']

    this._nestedFields = {
      replies: Reply
    }
   
    this.replies = []
    this.repliesTree = []

    this.repliesTree = []

  }

  afterInit() {
    this.repliesIdsMap = new Map<number, number>()
    this.repliesTree = []
    this.replies.forEach(reply => this.insertReply(reply))}

  public id: number
  public title: string
  public text: string
  public reps: number
  public level: number
  public enabled: boolean
  public isCompleted: boolean
  public repsData: boolean
  public replies: Reply[]
  public repliesTree: Reply[]
  public repliesIdsMap: Map<number, number>
  public isLevelCompleted: boolean
  public isNotificationSent: boolean
  public routine_info_id: string



  insertReply(reply: Reply, onTop = false) {
    if (reply.repliableId) {
      let index = this.repliesIdsMap.get(reply.repliableId)
      if (this.repliesTree[index] && this.repliesTree[index].replies) {
        this.repliesTree[index].replies.unshift(reply)
      }
    } else {
      if (onTop) {
        this.repliesTree.unshift(reply)
        this.repliesIdsMap.forEach((value, key, map) => { map.set(key, value + 1) })
        this.repliesIdsMap.set(reply.id, 0)
      } else {
        const index = this.repliesTree.length
        this.repliesTree.push(reply)
        this.repliesIdsMap.set(reply.id, index)
      }
    }
  }

  replaceReply(id: number, reply: Reply) {
    if (reply.repliableId) {
      let index = this.repliesIdsMap.get(reply.repliableId)
      let j = this.repliesTree[index].replies.findIndex(el => { return el.id === id })
      this.repliesTree[index].replies[j] = reply
    } else {
      let index = this.repliesIdsMap.get(id)
      this.repliesTree[index] = reply
      this.repliesIdsMap.set(reply.id, index)
    }
  }

  sortRepliesBy(key = 'timestamp') {
    // Universal sort number or date
    this.repliesIdsMap = new Map<number, number>()
    this.repliesTree.sort((a, b) => a[key] > b[key] ? -1 : a[key] < b[key] ? 1 : 0)
    this.repliesTree.forEach((reply, index) => { this.repliesIdsMap.set(reply.id, index) })
  }

}
