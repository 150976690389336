import { Base } from '../shared/base'

export class Option extends Base {

  init() {
    this._fields = ['id', 'text', 'actionPoints', 'isCorrect', 'status', 'enabled']
  }

  public id: number
  public text: string
  public actionPoints: number
  public isCorrect: boolean
  public isSelected: boolean
  public status: string
  public letter: string
  public enabled: boolean
}
