import gql from 'graphql-tag'

export const contentSurveyFields = gql`
fragment contentSurveyFields on ContentSurvey {
  id
  title
  contentSurveyQuestions {
    id
    title
    rationale
    answer {
      id
      optionId
      correctOptionId
      isCorrect
      stats
    }
    contentSurveyOptions {
      id
      text
      isCorrect
    }
  }
  actionPoints
  isMandatory
  startDay
  endDay
  preScreens
  postScreens
  screenOrder
  createdAt
  updatedAt
  enabled
  isCompleted
}
`

export const updateContentSurvey = gql`
mutation updateContentSurvey($contentSurvey: ContentSurveyInput!) {
  updateContentSurvey(contentSurveyInput: $contentSurvey) {
    ...contentSurveyFields
  }
}
${contentSurveyFields}
`

export const answerContentSurvey = gql`
mutation answerContentSurveyV2($optionIds: [Int!]!)  {
  answerContentSurveyV2(optionIds: $optionIds){
    answer {
      id
      optionId
      correctOptionId
      isCorrect
      stats
    }
  }
}
`


export const findContentSurvey = gql`
query findContentSurvey($id: Int!) {
  contentSurvey(id: $id) {
    ...contentSurveyFields
  }
}
${contentSurveyFields}
`

export const listContentSurveys = gql`
query listContentSurveys($filter: FilterInput) {
  contentSurveys(filter: $filter) {
    ...contentSurveyFields
    createdAt
  }
}
${contentSurveyFields}
`
