import { Component, OnInit } from '@angular/core'

function _window(): any {
  // For Cordova `navigator.app` access
  return window
}

@Component({
  selector: 'app-wrong-login-link',
  templateUrl: './wrong-login-link.component.html',
  styleUrls: ['./wrong-login-link.component.scss']
})
export class WrongLoginLinkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  exit() {
    if (_window().navigator.app) 
      _window().navigator.app.exitApp()
  }
}
