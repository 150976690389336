import gql from 'graphql-tag'

export const pollFields = gql`
fragment pollFields on Poll {
  id
  title
  rationale
  options {
    id
    text
    isCorrect
  }
  answer {
    id
    optionId
    actionPoints
    isCorrect
    stats
  }
  actionPoints
  preScreens
  postScreens
  startDay
  endDay
  screenOrder
  isMandatory  
  enabled
  time
  ptype
  lowerlabel
  higherlabel
  lowerscale
  higherscale
  isCompleted
  benchLabel
  benchmarkType
  priorQuestionId
  benchmarkNumber
}
`

export const updatePoll = gql`
mutation updatePoll($poll: PollInput!) {
  updatePoll(pollInput: $poll) {
    ...pollFields
  }
}
${pollFields}
`

export const answerPoll = gql`
mutation answerPoll($optionId: Int!) {
  answerPoll(optionId: $optionId) {
    actionPoints
    answer {
      id
      optionId
      actionPoints
      isCorrect
      stats
    }
  }
}
`


export const findPoll = gql`
query findPoll($id: Int!) {
  poll(id: $id) {
    ...pollFields
  }
}
${pollFields}
`

export const listPolls = gql`
query listPolls($filter: FilterInput) {
  polls(filter: $filter) {
    ...pollFields
    createdAt
  }
}
${pollFields}
`
