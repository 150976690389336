import { Path } from '../../path/path.model'

export class ColorUtil {
  hexToRGB(color, opacity): string {
    let hex = color.replace( /#/, '' ),
          r = parseInt( hex.substring( 0, 2 ), 16 ),
          g = parseInt( hex.substring( 2, 4 ), 16 ),
          b = parseInt( hex.substring( 4, 6 ), 16 )
    return 'rgba( ' + r + ', ' + g + ', ' + b + ', ' + ( opacity / 100 ) + ' )';
  }

  getColors(path: Path): Array<string> {
    return [
      path.colours['stat1'],
      path.colours['stat2'],
      path.colours['stat3'],
      path.colours['stat4'],
      path.colours['stat5']
    ]
  }
}
