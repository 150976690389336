import { Component, Input, Output, EventEmitter, OnInit, OnChanges, ViewChild, ElementRef } from '@angular/core'
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload'
import { environment } from '../../environments/environment'
import { MatDialog } from '@angular/material/dialog'
import { PictureUploaderComponent } from '../picture-uploader/picture-uploader.component'
import { User } from '../user/user.model'
import { SessionService } from '../session/session.service'
import { CordovaService } from '../cordova.service'
import { AppState } from '../app.service'

const DEFAULT_URL = environment.apiBaseUrl + '/files'
const DEFAULT_MAX_FILE_SIZE = 10 * 1024 * 1024 // 10 MB - Accept raw images from the phone's camera
const DEFAULT_ALLOWED_MIME_TYPES = null // Workaround: Android issue with comparing types
// const DEFAULT_ALLOWED_MIME_TYPES = [
//  'image/png',
//  'image/gif',
//  'image/jpeg',
//]

class UploadConfig {
  url?: string = DEFAULT_URL
  maxFileSize?: number = DEFAULT_MAX_FILE_SIZE
  allowedMimeTypes?: string[] = DEFAULT_ALLOWED_MIME_TYPES
}
@Component({
  selector: 'app-alert-profile-picture',
  templateUrl: './alert-profile-picture.component.html',
  styleUrls: ['./alert-profile-picture.component.scss'],
  
})
export class AlertProfilePictureComponent implements OnInit, OnChanges {
  //@Input() config: UploadConfig = new UploadConfig()
 // @Input() label: string
 // @Input() type: string
 // @Input() labelSelected: string = ''
 // @Input() fileUrl: string
 // @Output() fileUrlChange = new EventEmitter<string>()

  public profile: PictureUploaderComponent
  showTakeAPhoto = true
  public uploader: FileUploader

  user: User
  label: any
  labelSelected: any

  constructor(private dialogRef: MatDialog,
    private cordovaService: CordovaService,
    private sessionService: SessionService,
    private appState: AppState) { }

  ngOnInit() {

    if(this.cordovaService.isAndroid() || window.innerWidth > 768) {
      this.showTakeAPhoto=false;
    }else{
      this.showTakeAPhoto=true;
    }

    this.label = this.label || 'Choose an image'
   
      this.label = this.labelSelected
      // this.setBackground(this.fileUrl)
      // console.log('File url',this.fileUrl);
    
    this.uploader = new FileUploader({
      url: environment.apiBaseUrl + '/files',
      allowedMimeType: null,
      maxFileSize: 10 * 1024 * 1024,
      autoUpload: true,
      authTokenHeader: "Authorization",
      authToken: `${localStorage.getItem('token')}`,
    })

    console.log('File url this.uploader',this.uploader);


    this.uploader.onBeforeUploadItem = (fileItem: FileItem) => {
      this.label = `Loading...`
      console.log("onBeforeUploadItem ",fileItem);
      
      this.dialogRef.closeAll();
    }
    this.uploader.onCompleteItem = (item: FileItem, response: any, status: any, headers: any) => {
      response = JSON.parse(response)
      console.log("onCompleteItem ",response);
      this.sessionService.url = response['data']['url']
      this.setBackground(response['data']['url'])
     // this.fileUrlChange.emit(response['data']['url'])
      this.label = this.labelSelected
    }
    //this.uploader.onSuccessItem = (item:FileItem, response:string, status:number, headers:ParsedResponseHeaders) => {}
    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      console.log(item, response, headers)
      console.log("onErrorItem ",response);
    }
  }
  fileUrl(fileUrl: any) {
    throw new Error('Method not implemented.')
  }

  ngOnChanges() {
    // if (this.fileUrl) {
    //   this.label = this.labelSelected
    //   this.setBackground(this.fileUrl)
    // }
  }

  private setBackground(url) {
    console.log(url);
    this.sessionService.url = url
  }

}
