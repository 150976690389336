import gql from 'graphql-tag';
import { clientFields } from '../client/client.query'
import { pathFields } from '../path/path.query'

export const pathRegistrationFields = gql`
  fragment pathRegistrationFields on PathRegistration {
    userId
    pathId
    earnedActionPoints
    pathData {
      loginReminderEnabled
      loginReminderTime
      deviceRegistrationId
    }
    scheduleDay
    selectedDayPart
  }
`;

export const pathGroupFields = gql`
fragment pathGroupFields on PathGroup {
  id
  name
  daysOfTheWeek
  logoImage
  signupEmail
  daysOfContent
  starts
  ends

  signupCode

  colours
  names
  language
  allowMultipleLogin
  enabledAttributes

  createdAt
  updatedAt
  enabled

  clientId
  client {
    ...clientFields
  }
  userGroups {
    id
    pathGroupId
    pathId
    name
  }
  paths {
    id
    name
    pathDescription
    pathImage
  }
}
${clientFields}
`

export const findPathGroup = gql`
query findPathGroup($id: Int!) {
  pathGroup(id: $id) {
    ...pathGroupFields
  }
}
${pathGroupFields}
`

export const findPathGroupByName = gql`
query findPathGroupByName($name: String!) {
  pathGroup(name: $name) {
    ...pathGroupFields
  }
}
${pathGroupFields}
`

export const setActivePath = gql`
mutation setActivePath($activePathId: Int!, $userId: Int!) {
  setActivePath(activePathId: $activePathId, userId: $userId) {
    path {
      ...pathFields
    }
    pathGroup {
      id
      name
    }
    pathRegistration {
      ...pathRegistrationFields
    }
    scheduleDay
    showOnboardingSurvey
    showCloseoutSurvey
    allContentCompleted
  }
}
${pathFields}
${pathRegistrationFields}
`

export const listPathsGroups = gql`
query listPathsGroups($filter: FilterInput) {
  pathGroups(filter: $filter) {
    ...pathGroupFields
  }
}
${pathGroupFields}
`
