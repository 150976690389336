import gql from 'graphql-tag';

export const clientFields = gql`
fragment clientFields on Client {
    id
    name
    tagLine
    imageUrl
    headerFontUrl
    bodyFontUrl
    faqUrl
    tutorialUrl
    enableFoozi
    subDomain
    backgroundImageUrl
    isCohort
    alternateCohortName
}
`
export const allpath = gql`
fragment allpath on Client {
  id
  paths{
  id
  name
  pathDescription
  pathImage
  isPathCompleted
  userGroup{
    id
    name
  }
  inoutScreens (isDeleted: false){
    id
    homepageSubtext
  }
  }
  }

`

export const updateClient = gql`
mutation updateClient($client: ClientInput!) {
  updateClient(clientInput: $client) {
    ...clientFields
  }
}
${clientFields}
`

export const findClient = gql`
query findClient($id: Int, $name: String, $subDomain: String) {
  client(id: $id, name: $name, subDomain: $subDomain) {
    ...clientFields
  }
}
${clientFields}
`

export const listClients = gql`
query listClients($filter: FilterInput) {
  clients(filter: $filter) {
    ...clientFields
    createdAt
  }
}
${clientFields}
`


export const allpaths = gql`
query allpaths($id: Int!) {
  client(id: $id) {
    ...allpath
    createdAt
  }
}
${allpath}
`