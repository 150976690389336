import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { AppState } from './app.service';
import { I18n } from './i18n.service';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  private DURATION = 2000 ;  

  constructor(
    public toast: ToastController,
    private i18n: I18n) 
  { }

  success(message: string = null, pos:any="top", className:any='success-toaster-class'){
    this.toast.create({
      message: this.i18n.t(message),
      position: pos,
      cssClass: className,
      duration: this.DURATION
    }).then((obj) => {
      obj.present();
    });
  }

  warning(message: string = null){
    this.toast.create({
      message: this.i18n.t(message),
      position: 'top',
      cssClass: 'warning-toaster-class',
      duration: this.DURATION
    }).then((obj) => {
      obj.present();
    });
  }

  error(message: string = null){
    this.toast.create({
      message: this.i18n.t(message),
      position: 'top',
      cssClass: 'error-toaster-class',
      duration: this.DURATION
    }).then((obj) => {
      obj.present();
    });
  }


}
