import gql from 'graphql-tag';

export const badgeFields = gql`
fragment badgeFields on Badge {
    id
    stringId
    createdAt
    updatedAt
    enabled
}

`
export const pathBadgeFields = gql`
fragment pathBadgeFields on PathBadge {
    id
    badgeId
    stringId
    title
    imageUrls
    position
    actionPoints
    cappedSteps
    isCapped
    createdAt
    updatedAt
    enabled
}
`

export const updateBadge = gql`
mutation updateBadge($badge: BadgeInput!) {
  updateBadge(badgeInput: $badge) {
    ...badgeFields
  }
}
${badgeFields}
`

export const findBadge = gql`
query findBadge($id: Int!) {
  badge(id: $id) {
    ...badgeFields
  }
}
${badgeFields}
`

export const listBadges = gql`
query listBadges($filter: FilterInput) {
  badges(filter: $filter) {
    ...badgeFields
  }
}
${badgeFields}
`

export const listUserBadges = gql`
query listUserBadges($filter: FilterInput) {
  userBadges(filter: $filter) {
    id
    pathId
    userId
    badgeId
    step
    enabled
    createdAt
  }
}
`