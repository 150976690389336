import { Injectable } from '@angular/core';
import { TextToSpeechAdvanced } from '@awesome-cordova-plugins/text-to-speech-advanced/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { File, IFile } from '@awesome-cordova-plugins/file/ngx';
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';
import { ActionSheetController, Platform } from '@ionic/angular';
import { FilePath } from '@awesome-cordova-plugins/file-path/ngx';
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx';
import * as moment from 'moment';
import { I18n } from '../i18n.service';

@Injectable({
  providedIn: 'root'
})
export class NativeService {

  constructor(
    private tts: TextToSpeechAdvanced,
    private socialSharing: SocialSharing,
    private camera: Camera,
    private webview: WebView,
    private i18n: I18n,
    public file: File,
    public platform: Platform,
    public filePath: FilePath,
    public chosser: Chooser,
  ) {

  }
  showActionSheetForFiles() {
    return new Promise(async (resolve, reject) => {
      const actionSheet = await new ActionSheetController().create({
        header: this.i18n.t('Upload Profile Picture'),
        buttons: [
          {
            text: this.i18n.t('Use Camera'),
            icon: 'ios-camera',
            cssClass: 'upload-photo-sheet',
            handler: () => {
              this.openCamera().then(async (imagePath: any) => {
                const returnFileResult = await this.setAttachmentsData(imagePath.fileUrl, imagePath.fileName);
                console.log('returnFileResult', returnFileResult);
                resolve(returnFileResult);
              });
            }
          }, {
            text: this.i18n.t('Browse Gallery'),
            icon: 'ios-image',
            cssClass: 'upload-photo-sheet',
            handler: () => {
              this.pickImage().then(async (imagePath: any) => {
                  const returnFileResult = await this.setAttachmentsData(imagePath.fileUrl, imagePath.fileName);
                  resolve(returnFileResult);
              });
            }
          },
          {
            text: this.i18n.t('Cancel'),
            role: 'cancel',
            handler: () => {
              resolve(0);
            }
          }]
      });
      await actionSheet.present();
    });
  }

  async setAttachmentsData(imagePath, fileName, data?: any) {
    if (imagePath.startsWith('data:image')) {
      // Base64 image case
      const blob = await this.base64ToBlob(imagePath);
      const result = this.getObjectForAttachments(imagePath, blob, fileName, data);
      return result;
    } else {
      const fileSrc = this.convertFileSrc(imagePath);
      const imageFile: any = await this.getSingleFile(imagePath, fileName);
      const pushedData = this.getObjectForAttachments(fileSrc, imageFile, imagePath, data);
      return pushedData;
    }
  }
  base64ToBlob(base64Data: string): Promise<Blob> {
    return fetch(base64Data).then(res => res.blob());
}
  getObjectForAttachments(url, blobData, imagePath, data?: any): any {
    const result: any = {};
    result.uri = url;
    result.nativePath = imagePath;
    if (data) {
      result.displayUrl = data.displayUrl;
      result.mimeType = data.mimeType;
    }
    result.name = blobData.name;
    result.blob = blobData;
    return result;
  }
  textToSpeech() {
    console.log('test....');
    this.tts.speak('Hello World')
      .then(() => console.log('Success'))
      .catch((reason: any) => console.log(reason));
  }
  shareViaApp(
    message?: string,
    subject?: string,
    fileUrl?: string[]
  ) {
    var options = {
      message: message, // not supported on some apps (Facebook, Instagram)
      subject: subject, // fi. for email
      files: fileUrl, // an array of filenames either locally or remotely
      //url: 'https://www.website.com/foo/#bar?a=b',
      chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title

    };
    this.socialSharing.shareWithOptions(options);
  }
  async openCamera() {
    const options: CameraOptions = {
      quality: 20,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      targetWidth: 720,
      correctOrientation: true,
      sourceType: 1
    };
    console.log('camera calling....');
    const fileUrl: any = await this.camera.getPicture(options);

    const tempFilename = fileUrl.substr(fileUrl.lastIndexOf('/') + 1);
    const returnData: any = {};
    returnData.fileUrl = fileUrl;
    returnData.fileName = tempFilename;
    console.log('file url', returnData);
    //this.uploadFile(fileUrl, tempFilename);
    return returnData;
  }
  async pickImage() {
    if (this.platform.is('ios')) {
      const options: CameraOptions = {
        quality: 20,
        sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
        destinationType: this.camera.DestinationType.FILE_URI,
        encodingType: this.camera.EncodingType.JPEG,
        mediaType: this.camera.MediaType.PICTURE
      };
      const fileUrl: any = await this.camera.getPicture(options);
      const tempFilename = fileUrl.substr(fileUrl.lastIndexOf('/') + 1);
      const returnData: any = {};
      returnData.fileUrl = fileUrl;
      returnData.fileName = tempFilename;
      return returnData;
    } else if (this.platform.is('android')) {
      const options: CameraOptions = {
        quality: 20,
        sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
        destinationType: this.camera.DestinationType.DATA_URL,  // Using DATA_URL to get base64 image
        encodingType: this.camera.EncodingType.JPEG,
        mediaType: this.camera.MediaType.PICTURE
      };

      try {
        const imageData = await this.camera.getPicture(options);
        const base64Image = 'data:image/jpeg;base64,' + imageData; 
        const tempFilename = imageData.substr(imageData.lastIndexOf('/') + 1);
        const returnData: any = {};
        returnData.fileUrl = base64Image; 
        returnData.fileName = tempFilename; 
        return returnData;
      } catch (error) {
        console.error("Error capturing image:", error);
        throw error;  
      }
    }
    else {
      const file: any = await this.chosser.getFile('image/jpeg, image/png' as any);
      const data: any = {};
      data.imgname = this.setImageName();
      const filePath = await this.filePath.resolveNativePath(file.uri);
      const returnData: any = {};
      returnData.fileUrl = filePath;
      returnData.fileName = file.name;
      return returnData;
    }
  }
  convertFileSrc(path) {
    console.log()
    return this.webview.convertFileSrc(path);
  }
  async getSingleFile(filePath: string, fileName: string): Promise<File> {
    // Get FileEntry from image path
    const fileEntry = await this.file.resolveLocalFilesystemUrl(filePath);
    console.log('i am here', fileEntry);

    // Get File from FileEntry. Again note that this file does not contain the actual file data yet.
    const cordovaFile: IFile = await this.convertFileEntryToCordovaFile(fileEntry);
    // console.log('i am here cordovaFile', await this.convertCordovaFileToJavascriptFile(cordovaFile, fileName));
    // Use FileReader on the File object to populate it with the true file contents.
    return await this.convertCordovaFileToJavascriptFile(cordovaFile, fileName);
  }
  private convertFileEntryToCordovaFile(fileEntry): Promise<IFile> {
    return new Promise<IFile>((resolve, reject) => {
      fileEntry.file(resolve, reject);
    });
  }
  private convertCordovaFileToJavascriptFile(cordovaFile: IFile, fileName: string): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      const reader = new FileReader();
      // const reader = this.getFileReader();
      console.log('reder', reader);
      reader.onloadend = () => {
        if (reader.error) {
          reject(reader.error);
        } else {
          const blob: any = new Blob([reader.result], { type: cordovaFile.type });
          blob.lastModifiedDate = new Date();
          blob.name = fileName;// cordovaFile.name;
          resolve(blob as File);
        }
      };
      reader.readAsArrayBuffer(cordovaFile);
    });
  }

  setImageName() {
    const timestamp = moment().unix();
    return timestamp + '.jpeg';
  }
  formatDate(date: Date): string {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
  }
}
