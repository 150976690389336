export class PtrAnimates {
  constructor(
    private elControl: any, 
    private threshold: number
  ) {}
  
  pulling(d) {
    let p = d / this.threshold
    if (p > 1) p = 1
    else p = p * p * p
    const y = d / 2.5

    this.elControl.style.opacity = p
    this.elControl.style.transform = y ? `translate3d(-50%, ${y}px, 0) rotate(${360 * p}deg)` : ''
  }

  refreshing() {
    this.elControl.style.transition = 'transform 0.2s'
    this.elControl.style.transform = `translate3d(-50%, ${this.threshold / 2.5}px, 0)`
  }

  aborting() {
    return new Promise<void>(resolve => {
      if (this.elControl.style.transform) {
        this.elControl.style.transition = 'transform 0.3s, opacity 0.15s'
        this.elControl.style.transform = 'translate3d(-50%, 0, 0)'
        this.elControl.style.opacity = 0
        this.elControl.addEventListener('transitionend', () => {
          this.elControl.style.transition = ''
          resolve()
        })
      } else {
        resolve()
      }
    })
  }

  restoring() {
    return new Promise<void>(resolve => {
      this.elControl.style.transition = 'transform 0.3s'
      this.elControl.style.transform += ' scale(0.01)'
      this.elControl.addEventListener('transitionend', () => {
        this.elControl.style.transition = ''
        resolve()
      })
    })
  }
}