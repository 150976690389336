import { Base } from '../shared/base'
import { ContentSurveyOption } from './content-survey-option.model'
import { ContentSurveyQuestionAnswer } from './content-survey.model'

export class ContentSurveyQuestion extends Base {
  init() {
    this._fields = ['id', 'title', 'answer', 'rationale', 'contentSurveyOptions']
    this._nestedFields = {
      contentSurveyOptions: ContentSurveyOption
    }
  }

  public id: number
  public title: string
  public rationale: string
  public answer: ContentSurveyQuestionAnswer
  public contentSurveyOptions: ContentSurveyOption[]
}
