import { ContentSurvey } from 'src/app/content-survey/content-survey.model'
import { Habit } from 'src/app/habit/habit.model'
import { InoutScreen } from 'src/app/inout-screen/inout-screen.model'
import { Poll } from 'src/app/poll/poll.model'
import { Question } from 'src/app/question/question.model'
import { RoutineInfo } from 'src/app/routine-v2/routine-info.model'
import { Base } from '../../shared/base'
import { Reflection } from 'src/app/my-library/full-screen-reflection/reflection.model'

export class JournalPost extends Base {
  init() {
    this._fields = ['id', 'text', 'createdAt', 'inoutScreens', 'polls', 
    'contentSurveys', 'documents', 'questions', 'habits', 'routineInfo', 'pathName', 'screenId', 'pathId', 'userId', 'reflections']
  }

  afterInit() {
    if (this.createdAt) {
      this.timestamp = new Date(this.createdAt)
    } else {
      this.timestamp = new Date()
    }
  }

  public id: number
  public userId: number
  public pathId: number
  public text: string
  public isPinned: boolean
  public createdAt: string
  public updateAt: string
  public timestamp: Date
  public polls: Poll
  public contentSurveys: ContentSurvey
  public documents: Document
  public questions: Question
  public inoutScreens: InoutScreen
  public routineInfo: RoutineInfo
  public habits: Habit
  public pathName: string
  public screenId: string
  public reflections: Reflection
}
