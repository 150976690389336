import { Base } from '../shared/base'

export interface AwardFor {
  [name: string]: number
}

export class CustomBadge extends Base {
  init() {
    this._fields = [
      'id', 'badgeId', 'badgeTitle', 'colour', 'position', 'actionPoints', 'imageShape', 'awardFor', 'isEligible', 'enabled'
    ]
  }
  public id: number
  public badgeId: number
  public badgeTitle: string
  public colour: string
  public position: number
  public actionPoints: number
  public imageShape: string
  public awardFor: AwardFor
  public enabled: boolean

  isEligible(stats: any) {
    let eligible = true
    for (let key in this.awardFor) {
      if (this.awardFor[key] > stats[key]) {
        eligible = false
      }
    }
    return eligible
  }
}
