import { Component, Inject, OnInit, Optional } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AppState } from '../app.service';

@Component({
  selector: 'app-alert-ask-routine',
  templateUrl: './alert-ask-routine.component.html',
  styleUrls: ['./alert-ask-routine.component.scss']
})
export class AlertAskRoutineComponent implements OnInit {



  constructor(public dialogRef: MatDialogRef<AlertAskRoutineComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private appState: AppState) { }

  ngOnInit() {
  }

submit(makeRoutine,type,value) {
    console.log(makeRoutine,type,value);
    this.dialogRef.close(`${makeRoutine},${value},${type}`);

    this.appState.set('commitData',`${makeRoutine},${value},${type}`)
    localStorage.setItem('commitData', `${makeRoutine},${value},${type}`)
    
    this.appState.set('makeCommitment',makeRoutine)
    localStorage.setItem('makeCommitment', makeRoutine)
  }

}


