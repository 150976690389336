import gql from 'graphql-tag';

export const documentFields = gql`
fragment documentFields on Document {
    id
    title
    preScreens
    postScreens
    link
    imageUrl
    section
    startDay
    endDay
    screenOrder
    actionPoints
    isMandatory
    enabled
    stitle
    source
    time
    isRecommended
}
`
// TODO: Make this work.
// Error: PathDocuments does not exists.
export const pathDocumentFields = gql`
fragment pathDocumentFields on PathDocuments {
  id
  pathId
  starts
  expires
  enabled
  document {
    ...docuementFields
  }
}
${documentFields}
`

export const viewDocument = gql`
mutation viewDocument($documentId: Int!) {
  viewDocument(documentId: $documentId) {
    status
    actionPoints
  }
}
`
export const DOCUMENT_FRAGMENT = gql`
  fragment documentFragment on Document {
    id
    title
    scheduledLater
  }
`;

export const HABIT_FRAGMENT = gql`
  fragment habitFragment on Habit {
    id
    confirmation
  }
`;

export const HABIT_SCHEDULELATER_FRAGMENT = gql`
  fragment habitFragmentScheduleLater on Habit {
    id
    scheduledLater
  }
`;
export const HABIT_TOGGLE_FRAGMENT = gql`
  fragment habitFragmentToggle on Habit {
    id
    isCompleted
  }
`;
export const DOCUMENT_UPDATE_FRAGMENT = gql`
  fragment documentFragmentToggle on Document {
    id
    isCompleted
  }
`;
export const updateDocument = gql`
mutation updateDocument($document: DocumentInput!) {
  updateDocument(documentInput: $document) {
    ...documentFields
  }
}
${documentFields}
`

export const findDocument = gql`
query findDocument($id: Int!) {
  document(id: $id) {
    ...documentFields
  }
}
${documentFields}
`

export const listDocuments = gql`
query listDocuments($filter: FilterInput) {
  documents(filter: $filter) {
    ...documentFields
    createdAt
  }
}
${documentFields}
`

export const listPathDocuments = gql`
query listPathDocuments($filter: FilterInput) {
  pathDocuments(filter: $filter) {
    id
    pathId
    starts
    expires
    enabled
    document {
      ...documentFields
    }
  }
}
${documentFields}
`
