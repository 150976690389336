import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  HostListener,
  ChangeDetectorRef // LUMBA-1308
} from '@angular/core'

import { AppState } from '../app.service'
import { Path } from '../path/path.model'
import { ContentSurvey, ContentSurveyStats } from '../content-survey/content-survey.model'
import { ContentSurveyService } from '../content-survey/content-survey.service'
import { CordovaService } from '../cordova.service'
import { ContentSurveyQuestion } from './content-survey-question.model'
import { ContentSurveyOption } from './content-survey-option.model'
import { WalletService } from '../wallet/wallet.service'
import { SpinnerService } from '../spinner.service'
import mixpanel from 'mixpanel-browser'
import { AlertController, PopoverController } from '@ionic/angular'
import { expandOnEnterAnimation } from 'angular-animations'
import { PopoverComponent } from '../shared/popover/popover.component'
import { HomeUiService } from '../new-design/home-ui.service';
import { I18n } from '../i18n.service'

@Component({
  selector: 'first90-content-survey',
  templateUrl: './content-survey.component.html',
  animations: [
    expandOnEnterAnimation({ duration: 50 })
  ],
  styleUrls: ['./content-survey.component.scss']
})
export class ContentSurveyComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() contentSurvey: ContentSurvey
  @Input() isPreview: boolean
  @Input() inverted = false

  enabled = true
  path: Path
  stats =[];
  status: string
  chart = []
  loading: boolean
  enableFinal: boolean;
  isMobile: boolean;
  statsPer=[];
  optionArray: number[];
  QuesstionIdArray: number[];
  showActionpoints=false;
  showFinalize=false;
  constructor(
    private contentSurveyService: ContentSurveyService,
    private appState: AppState,
    private spinnerService: SpinnerService,
    private cordovaService: CordovaService,
    private popover: PopoverController,
    private walletService: WalletService,
    private alertController: AlertController,
    public homeUIService: HomeUiService,
    private cdRef: ChangeDetectorRef, // LUMBA-1308
    private i18n: I18n
  ) {
    this.path = this.appState.get('currentPath')
    this.isEnabled()
    this.isMobile = this.appState.get('isMobile')
    this.initNames()
  }

  ngOnInit() {
    if (this.contentSurvey) {
      this.contentSurvey?.contentSurveyQuestions?.forEach((question)=>{
        question?.contentSurveyOptions?.forEach((option)=>{
          if(option?.isSelected) {
            option.isSelected =false;
          }
        });
      });
      if (this.contentSurvey && this.contentSurvey.isCompleted) {
        this.contentSurvey.contentSurveyQuestions.forEach((question)=>{
         this.stats.push(question.answer.stats);
        })
      }



      
      this.appState.set('surveyStats', this.stats)
    }
    this.isMobile = this.appState.get('isMobile')

  }

  ngAfterViewInit() {
    if (this.contentSurvey.isCompleted) this.setStats()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['contentSurvey'] || changes['contentSurvey'].firstChange) { return false }
  }

  setStats() {
    if (!this.stats) {
      return
    }
    if (!this.stats?.length) {
      return
    }
    // if (!this.stats['data'].length) {
    //   return
    // }
this.stats.forEach((stat)=>{
  const total = stat.data.reduce((sum, n) => sum + n)
  if(total){
    this.statsPer.push(stat.data.map(n => Math.round(n * 100 / total)));

  }
  else {

    this.statsPer.push(stat.data.map(n => total))
  }
})

  }

  onAnimationDone(event: any) {
    const element = event.element as HTMLElement;
    let primaryColor;
      
    primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--highlight-color');
    element.style.borderColor = primaryColor ;
  }

  storeSurveyData(contentSurvey){
    let optArray =[];
    let questionArray =[];
    contentSurvey?.contentSurveyQuestions?.forEach((question)=>{
      question?.contentSurveyOptions?.forEach((option)=>{
        if(option?.isSelected) {
          optArray.push(option.id);
          questionArray.push(question.id)
        }
        else {
          questionArray.push(question.id)
          questionArray =[...new Set(questionArray)]
        }
      })
    })
    questionArray =[...new Set(questionArray)]
    this.QuesstionIdArray = [...new Set(questionArray)];
    this.optionArray = [...new Set(optArray)];
    if (!this.enableFinal && optArray?.length == questionArray?.length) {
      this.showFinalize = true;
      console.log("this.eableFinal ", this.enableFinal)
      setTimeout(() => {
        this.showFinalize = false;
      }, 600);
    }
    this.enableFinal = optArray?.length ==questionArray?.length;
    
  }
  saveSurvey(contentSurvey) {
    this.storeSurveyData(contentSurvey);
     this.loading = true
  // this.spinnerService.on()
  this.cdRef.detectChanges();
   this.contentSurveyService.answerContentSurvey(
    this.contentSurvey, this.QuesstionIdArray, this.optionArray
  ).subscribe(response => {
   // mixpanel.track("In Steps-contentSurvey", {'data': question, option})
   for(let i=0; i<response.answer.length;i++) {
     this.contentSurvey.contentSurveyQuestions[i].answer = response.answer[i]
   }
   this.enableFinal =false; 
    this.showActionpoints=true;
    setTimeout(()=>{
      this.showActionpoints=false;
    },3000);
  //  this.spinnerService.off()
   this.stats =[];
   response.answer?.forEach((answer)=>{
     this.stats.push(answer?.stats)
   })
      this.contentSurvey.isCompleted = true;
      this.appState.get('currentPath').update('contentSurveys', this.contentSurvey.id, {isCompleted: true})        
      this.cdRef.detectChanges()
    this.setStats();
    this.appState.set('surveyStats', this.stats)
    this.loading = false
    // if(this.isMobile) {
    //   setTimeout(()=>{
    //     this.presentAlert(contentSurvey.question.rationale);
    //   },1300)
    // }
    this.cdRef.detectChanges()
  }, error => {
    // this.spinnerService.off()
    this.contentSurvey?.contentSurveyQuestions?.forEach((question)=>{
      question?.contentSurveyOptions?.forEach((option)=>{
        if(option?.isSelected) {
          option.isSelected =false;
        }
      });
    });    
    this.enableFinal =false; 
    this.loading = false
    this.cdRef.detectChanges() // LUMBA-1308
  })
  }
  onOptionClicked(question: ContentSurveyQuestion, option: ContentSurveyOption) {
    if(!this.contentSurvey.isCompleted) {
      mixpanel.track('In Steps-clicked option', {'question': question.title, 'answer': option.text})
      if(this.appState.get('IsOptionSelected') ==undefined) {
        this.appState.set('IsOptionSelected',true);
      }
      question.contentSurveyOptions.forEach((opt)=>{
        if(opt.id ==option.id) {
          option.isSelected = true
        }
        else {
          opt.isSelected =false
        }
      })
      this.storeSurveyData(this.contentSurvey);
    }
  
  }

  async presentAlert(msg) {
    const alert = await this.alertController.create({
      translucent: true,
      header:'Interaction' ,
      message: msg,
    });
   
  
    await alert.present();
  }
  private isEnabled() {
    this.enabled = this.path.enabledAttributes['polls']
  }

  private initNames() {
  }

  public isCorrectAnswer(option: ContentSurveyOption, question: ContentSurveyQuestion) {
    // mixpanel.track('isCorrectAnswer', {'question': question, 'option': option})
    if (option?.isSelected) {
      // if (!this.loading && option.isCorrect) {
        return true
      // }
    } else if (question?.answer) {
      if (question.answer.optionId === option.id) {
        return true
      }
    }
    return false
  }
  async presentPopover(ev) {
    const dynamicText = this.i18n.t('These charts show the breakdown of answers among all your colleagues in this path.');
    const popover = await this.popover.create({
      component: PopoverComponent,
      translucent: true,
      event: ev,
      mode:'ios',
      showBackdrop: true,
      cssClass: 'custom-popover',
      componentProps: {
        dynamicText: dynamicText,
      },
    });
    await popover.present();
    await popover.onDidDismiss();
  }
  public isFalseAnswer(option: ContentSurveyOption, question: ContentSurveyQuestion) {
    // mixpanel.track('isFalseAnswer', {'question': question, 'option': option})
    if (option.isSelected) {
      if (!this.loading && !option.isCorrect) {
        return true
      }
    } else if (question.answer) {
      if (!question.answer.isCorrect && question.answer.optionId === option.id) {
        return true
      }
    }
    return false
  }

  public isNotAnswered(option: ContentSurveyOption, question: ContentSurveyQuestion) {
    if (!option.isSelected && question?.answer?.optionId !== option.id) {
      // mixpanel.track('isNotAnswered', {'question': question, 'option': option})
      return true
    }
    else {
      return false
    }
    //return question.answer && question.answer.optionId !== option.id
  }

  public isRightAnswer(option: ContentSurveyOption, question: ContentSurveyQuestion) {
    if (question.answer && question.answer.correctOptionId === option.id && !question.answer.isCorrect) {
      // mixpanel.track('isRightAnswer', {'question': question, 'option': option})
      return true
    }
  }

  @HostListener('click', ['$event'])
  onLinkClick(e) {
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        window.open(e.target.href, '_blank')
      }
    }
  }
}
