import { Component, OnInit } from '@angular/core';
import { AppState } from '../app.service';
import { User } from '../user/user.model';

@Component({
  selector: 'header-tabs',
  templateUrl: './header-tabs.component.html',
  styleUrls: ['./header-tabs.component.scss'],
})
export class HeaderTabsComponent implements OnInit {

  currentUser: User

  constructor(private appState: AppState) { 
    this.currentUser = this.appState.get('currentUser')
  }

  ngOnInit() {}

}
