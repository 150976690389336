import { HammerGestureConfig } from '@angular/platform-browser'
import { Injectable } from '@angular/core'

declare const Hammer: any

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    const options = { touchAction: 'pan-y' }

    const mc = new Hammer(element, options)

    // keep default angular config
    mc.get('pinch').set({ enable: true })
    mc.get('rotate').set({ enable: true })
    mc.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL,velocity: 0.1 })

    // retain support for angular overrides object
    Object.keys(this.overrides).forEach(eventName => {
      mc.get(eventName).set(this.overrides[eventName])
    })

    return mc
  }
}
