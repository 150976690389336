import { Injectable } from '@angular/core'
import { AppState } from '../app.service'
import { SessionService } from '../session/session.service'
import { Observable, of } from 'rxjs'

@Injectable()
export class AuthService {
  constructor(
    public appState: AppState,
    public sessionService: SessionService
  ) { }

  public isAuthenticated(): Observable<boolean> {
    if (this.appState.get('isAuthenticated')) return of(true)
    const token = localStorage.getItem('token')
    if (!token) return of(false)
    this.appState.set('showLoader', true)
    return this.sessionService.newSessionByToken(token)
  }
}
