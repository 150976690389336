import { Base } from "../shared/base"

import { Question } from '../question/question.model'

export class PathQuestion extends Base {

  init() {
    this._fields = ['id', 'pathId', 'starts', 'expires', 'enabled', 'question']

    this._nestedFields = ['question']
  }

  public id: number
  public pathId: number
  public starts: string
  public expires: string
  public enabled: boolean
  public question: Question
}
