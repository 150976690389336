import { Injectable } from '@angular/core';
// import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  // constructor(private push: Push) {

  //   this.initPushNotification();
  //  }

  //  initPushNotification(){
  //    this.push.hasPermission()
  //      .then((res: any) => {

  //        if (res.isEnabled) {
  //          console.log('We have permission to send push notifications');
  //        } else {
  //          console.log('We do not have permission to send push notifications');
  //        }

  //      });

  //    this.push.createChannel({
  //      id: "testchannel1",
  //      description: "My first test channel",
  //      importance: 3,
  //      badge: false
  //    }).then(() => console.log('Channel created'));

  //    this.push.deleteChannel('testchannel1').then(() => console.log('Channel deleted'));

  //    this.push.listChannels().then((channels) => console.log('List of channels', channels))


  //    const options: PushOptions = {
  //      android: {},
  //      ios: {
  //        alert: 'true',
  //        badge: true,
  //        sound: 'false'
  //      },
  //      windows: {},
  //      browser: {
  //        pushServiceURL: 'http://push.api.phonegap.com/v1/push'
  //      }
  //    }

  //    const pushObject: PushObject = this.push.init(options);

  //    pushObject.on('notification').subscribe((notification: any) => console.log('Received a notification', notification));
  //    pushObject.on('registration').subscribe((registration: any) => console.log('Device registered', registration));
  //    pushObject.on('error').subscribe(error => console.error('Error with Push plugin', error));
  //  }

  

}
