import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AppState } from '../app.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-alert-habit-loop-info',
  templateUrl: './alert-habit-loop-info.component.html',
  styleUrls: ['./alert-habit-loop-info.component.scss']
})
export class AlertHabitLoopInfoComponent implements OnInit {
  @Input() loopInfo;
  isMobile;
  constructor( @Optional() public dialogRef: MatDialogRef<AlertHabitLoopInfoComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private appState: AppState, private modal: ModalController) {
      this.isMobile = this.appState.get('isMobile');
    }


  ngOnInit() {
    console.log(this.data)
  }

  closeDialog() {
    if (this.isMobile) {
       this.dialogRef.close();
    }
    else {
      this.modal.dismiss();
    }
   
  }
  
}
