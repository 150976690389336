import gql from 'graphql-tag';

// fragments cannot be recursive, using official hack
// https://github.com/facebook/graphql/issues/91
export const replyFields = gql`
fragment replyFields on Reply {
  id
  repliableId
  level
  text
  isReset
  step
  user {
    id
    imageUrl
    advanceColour
    firstName
    lastName
    userBgColor
  }
  linkThumbnails {
    url
    image
    title
    description
  }
  attachmentUrl
  votesCount
  createdAt
  updatedAt
  enabled    
  type
  rootId
  replies{
    id
  }
  routineAnswerId
}
`

export const updateReply = gql`
mutation updateReply($reply: ReplyInput!) {
  updateReply(replyInput: $reply) {
    ...replyFields
  }
}
${replyFields}
`
export const REPLY_VOTE_FRAGMENT = gql`
  fragment ReplyFragment on Reply {
    hasVoted
    votesCount
  }
`;

export const REPLY_REPLIES_FRAGMENT = gql`
  fragment replyRepliesFragment on Reply {
    id
  repliableId
  level
  text
  isReset
  step
  user {
    id
    imageUrl
    advanceColour
    firstName
    lastName
    userBgColor
  }
  linkThumbnails {
    url
    image
    title
    description
  }
  attachmentUrl
  votesCount
  createdAt
  updatedAt
  enabled    
  type
  rootId
  replies{
    id
  }
  routineAnswerId
  }
`;
export const voteReply = gql`
mutation voteReply($replyId: Int!,$mode: String) {
  voteReply(replyId: $replyId,mode:$mode) {
    status
  }
}
`

export const replyReply = gql`
mutation replyReply($replyId: Int!, $text: String!, $attachmentUrl: String, $userIds: [Int!],$mode: String!,$rootId: Int!) {
  replyReply(replyId: $replyId, text: $text, attachmentUrl: $attachmentUrl, userIds:$userIds,mode:$mode,rootId:$rootId) {
    status
    reply {
      ...replyFields
    }
  }
}
${replyFields}
`

export const findReply = gql`
query findReply($id: Int!) {
  reply(id: $id) {
    ...replyFields
  }
}
${replyFields}
`

export const listReplies = gql`
query listReplies($filter: FilterInput) {
  replies(filter: $filter) {
    ...replyFields
  }
}
${replyFields}
`
