import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Filter } from '../shared/filter'
import * as query from './client.query'
import { Client } from './client.model'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'

@Injectable()
export class ClientService {
  constructor(private apollo: Apollo) { }

  getList(filter: Filter = null): Observable<Client[]> {
    return this.apollo
      .watchQuery<any>({ query: query.listClients, variables: { filter: filter } })
      .valueChanges
      .pipe(
        map(response => {
          let list: Client[] = []
          response.data.clients.forEach(element => { list.push(new Client(element)) })
          return list
        })
      )
  }

  getClient(id: number, name: string): Observable<Client> {
    return this.apollo
      .watchQuery<any>({ query: query.findClient, variables: { id: id, name: name } })
      .valueChanges
      .pipe(
        map(response => {
          let client = new Client(response.data.client)
          return client
        })
      )
  }

  // LUMBA-1408
  getClientBySubDomain(subDomain: string): Observable<Client> {
    return this.apollo
      .watchQuery<any>({ query: query.findClient, variables: { subDomain: subDomain } })
      .valueChanges
      .pipe(
        map(response => {
          const client = new Client(response.data.client)
          return client
        })
      )
  }

  save(client: Client) {
    return this.apollo.mutate({
      mutation: query.updateClient,
      variables: {
        client: client.input()
      }
    })
  }

  getallpath(id: number,type?): Observable<Client> {
    return this.apollo
      .watchQuery<any>({
        query: query.allpaths, variables: { id: id },
        fetchPolicy: type?'no-cache':'cache-first'
      })
      .valueChanges
      .pipe(
        map(response => {
          let allpaths = new Client(response.data.client)
          return allpaths
        })
      )
  }
}
