import gql from 'graphql-tag';

import { userFields, pathRegistrationFields } from '../user/user.query'
import { clientFields } from '../client/client.query'
import { pathFields } from '../path/path.query'

export const multipleSignInByForm = gql`
mutation multipleSignInByForm(
  $email: String!, $password: String!, $pathGroupId: Int, $pathId: Int, $changePath: Boolean, $fromMobile: Boolean
) {
  multipleSignInByForm(
    email: $email, password: $password, pathGroupId: $pathGroupId, pathId: $pathId, changePath: $changePath, fromMobile: $fromMobile
  ) {
    token
    user {
      ...userFields
    }
    client {
      ...clientFields
    }
    path {
      ...pathFields
    }
    pathCount
    pathRegistration {
      ...pathRegistrationFields
    }
    scheduleDay
    showOnboardingSurvey
    showCloseoutSurvey
    allContentCompleted
    expiredOrRemoved
  }
}
${userFields}
${clientFields}
${pathFields}
${pathRegistrationFields}
`

export const multipleSignInBySso = gql`
mutation multipleSignInBySso(
  $email: String!, $ssoUid: String!, $isSso: Boolean, $changePath: Boolean, $fromMobile: Boolean, $pathGroupId: Int!, $pathId: Int!, $ssoProvider: String!
) {
  multipleSignInBySso(
    email: $email,
    ssoUid: $ssoUid,
    isSso: $isSso,
    changePath: $changePath,
    fromMobile: $fromMobile,
    pathGroupId: $pathGroupId,
    pathId: $pathId,
    ssoProvider: $ssoProvider
  ) {
    token
    user {
      ...userFields
    }
    client {
      ...clientFields
    }
    path {
      ...pathFields
    }
    pathCount
    pathRegistration {
      ...pathRegistrationFields
    }
    scheduleDay
    showOnboardingSurvey
    showCloseoutSurvey
    allContentCompleted
    expiredOrRemoved
  }
}
${userFields}
${clientFields}
${pathFields}
${pathRegistrationFields}
`


export const multipleSignInByToken = gql`
mutation multipleSignInByToken($token: String!) {
  multipleSignInByToken(token: $token) {
    result
    user {
      ...userFields
    }
    client {
      ...clientFields
    }
    path {
      ...pathFields
    }
    pathCount
    pathRegistration {
      ...pathRegistrationFields
    }
    scheduleDay
    showCloseoutSurvey
    allContentCompleted
  }
}
${userFields}
${clientFields}
${pathFields}
${pathRegistrationFields}
`

export const validateDeletion = gql`
mutation multipleSignInByForm(
  $email: String!, $password: String!, $pathGroupId: Int, $pathId: Int, $changePath: Boolean, $fromMobile: Boolean
) {
  multipleSignInByForm(
    email: $email, password: $password, pathGroupId: $pathGroupId, pathId: $pathId, changePath: $changePath, fromMobile: $fromMobile
  ) {
    token
    user {
      ...userFields
    }
    scheduleDay
    showCloseoutSurvey
    allContentCompleted
    expiredOrRemoved
  }
}
${userFields}
`

export const userLogin = gql`
mutation multipleSignInByForm(
  $email: String!, $password: String!, $pathGroupId: Int, $pathId: Int, $changePath: Boolean, $fromMobile: Boolean
) {
  multipleSignInByForm(
    email: $email, password: $password, pathGroupId: $pathGroupId, pathId: $pathId, changePath: $changePath, fromMobile: $fromMobile
  ) {
    token
    user {
      ...userFields
    }
    scheduleDay
    showOnboardingSurvey
    showCloseoutSurvey
    allContentCompleted
  }
}
${userFields}
`