import { Pipe, PipeTransform } from '@angular/core'
import { I18n } from './i18n.service'

@Pipe({
  name: 'i18n'
})
export class I18nPipe implements PipeTransform {
  constructor(private i18n: I18n) {
  }

  transform(value: any, args?: any): any {
    return this.i18n.t(value, args)
  }
}
