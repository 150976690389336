import { Component, Inject, OnInit, ViewChild, } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AppState } from '../app.service';
import { SessionService } from '../session/session.service';
import { ModalController,IonTextarea, } from '@ionic/angular';

@Component({
  selector: 'app-rating-alert-box',
  templateUrl: './rating-alert-box.component.html',
  styleUrls: ['./rating-alert-box.component.scss']
})
export class RatingAlertBoxComponent implements OnInit {

  postText:any = ''
  postMsg: any = "";
  formRating1:any = 0
  formRating2:any = 0
  complexItems = this.appState.get("userlist")
  isMobile;
  namesData : any[] = new Array()  
  tempValue = 0
  index = 0

  ratingCompleted: boolean = false
  habitPoints: number
  @ViewChild('textarea', { static: false }) textarea: IonTextarea;
  constructor(private appState: AppState,
    private sessionService: SessionService,
    private modal:ModalController,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.habitPoints = this.appState.get('habitPoints')
    this.isMobile = this.appState.get('isMobile');
    // document.addEventListener('deviceready', function() {
    //   Keyboard.shrinkView(true)
    //   window.addEventListener('keyboardDidShow', function () {
    //     document.activeElement.scrollIntoView()
    //   })
    // })
  }
  private capitalizeSentences(text: string): string {
    return text.replace(/(?:^|\.\s)([a-z])/g, (match) => match.toUpperCase());
  }
  capitalLine(event): void {
    const textareaElement = this.textarea?.getInputElement();
    let ele = document.getElementsByClassName("web-textarea");
    // const lineHeight = parseFloat(getComputedStyle(this.textarea['el']).lineHeight);
    if (event && event.target.value) {
      this.postText = this.capitalizeSentences(event.target.value);
    }
  }
  onTextModification(ev) {
    console.log("called on change", ev.target.value);
    this.capitalLine(ev)
  }
  onSumbit(){

    var list_of_tags_ids = []

    list_of_tags_ids = this.sessionService.getUserTAGs(this.postText,this.complexItems)
    
    console.log("Array of tags ",list_of_tags_ids);

    this.appState.set("tags_ids",list_of_tags_ids)

    localStorage.setItem('postText',this.postText);

    localStorage.setItem('rating1',JSON.stringify(this.formRating1));

    localStorage.setItem('rating2',JSON.stringify(this.formRating2));
    if(this.isMobile) {
      this.modal.dismiss({isCloses:true});
    }
    //this.dialog.closeAll()
    this.ratingCompleted = true

    //document.getElementById("myTextArea").style.height = "15vh";

  }

  
  
  format(item:any) {
    return item['username'].toUpperCase()
  }

  mentionConfig:any = {
    mentions: [
      {
        items: this.complexItems,
        labelKey: 'username',
        mentionSelect: this.format,
        dropUp:true
      }
    ]
  };

  onClick(){
    //document.getElementById("myTextArea").style.height = "26vh";
    let fullWindowHeight = window.innerHeight;
    let keyboardIsProbablyOpen = false;

    window.addEventListener("resize", function () {
      if (window.innerHeight == fullWindowHeight) {
        keyboardIsProbablyOpen = false;
        console.log('closed');
        
      } else if (window.innerHeight < fullWindowHeight * 0.9) {
        keyboardIsProbablyOpen = true;
        console.log('opened');
        
      }
    });
  }
}
