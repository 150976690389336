import { AfterViewInit, ChangeDetectorRef, Component, DoCheck, ElementRef, HostListener, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core'
import { AppState } from '../app.service'
import { Path } from '../path/path.model'
import { Router } from '@angular/router'
import { Client } from '../client/client.model'
import { SurveyService } from '../survey/survey.service'
import { TimePickerComponent } from '../time-picker/time-picker.component'
import { ThemeService } from '../theme/theme.service'
import { NotificationService } from '../notification/notification.service'
import { User } from '../user/user.model'
import { StatService } from '../stat/stat.service'
import { ChartSettings } from '../daily-participation-chart/daily-participation-chart.component'
import { ContentSurveyStats } from '../content-survey/content-survey.model'
import { SpinnerService } from '../spinner.service'
import { PollService } from '../poll/poll.service'
import { slideInUpAnimation } from 'angular-animations'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { HomeUiService } from '../new-design/home-ui.service'
import { CloseoutSurveyWelcomeComponent } from './closeout-survey-welcome.component'
import { ModalController } from '@ionic/angular'

@Component({
  selector: 'app-closeout-survey',
  templateUrl: './closeout-survey.component.html',
  styleUrls: ['../shared/survey.shared.scss', './closeout-survey.component.scss'],
  animations: [
    slideInUpAnimation({ duration: 300 }),
    trigger('collapseAnimation', [
      state('open', style({ height: '*', opacity: '1',bottom:'69px' })),
      state('closed', style({ height: '0', overflow:'hidden', opacity: '0',bottom:'69px' })),
      transition('open <=> closed', animate('0.2s ease-in-out')),
    ]),
  ]
})
export class CloseoutSurveyComponent implements OnInit, AfterViewInit, DoCheck {
  @ViewChildren('surveyScreen') surveyScreens: QueryList<ElementRef>
  @ViewChild ('timePicker') timePicker: TimePickerComponent
  @ViewChild('disableCard') disableCard: ElementRef;
  @ViewChild('disableMcqCard')disableMcqCard: ElementRef;
  currentPath: Path
  currentClient: Client
  currentUser: User
  currentScreenId
  currentScreenIndex = 0
  screensIds = []
  enableMcqBlur =false;
  isMobileWebIos = false;
  isMobileWeb=false;
  confirmedTime: string
  confirmedTimeValue: string
  sendNotifications: boolean
  addToCalendar: boolean
  answers = {}
  pathAverage;
  benchLinearScale;
  enableBlur=false;
  linearScale;
  surveyQuestions = []
  selectedTimeOption: string = null
  savingResults: boolean = false
  chartSettings: ChartSettings
  leaderboardPosition: string
  badgesCount: number
  graphData: any
  graphGreen: any
  graphLabels: any
  graphRealDay: any
  firstSurveyStats: ContentSurveyStats
  finalSurveyStats: ContentSurveyStats
  isShow=false;
  isMobile: boolean
  isRankSwaped: boolean
  dataFromChild: any
  responseData: any
  scaleData: any

  constructor(
    private appState: AppState,
    private router: Router,
    private surveyService: SurveyService,
    private statService: StatService,
    private themeService: ThemeService,
    private notificationService: NotificationService,
    private spinnerService: SpinnerService,
    private pollService: PollService,
    public homeUIService: HomeUiService,
    private renderer: Renderer2,
    private modalController: ModalController,
    private cdRef: ChangeDetectorRef,
  ) {
    this.currentPath = this.appState.get('currentPath');
    this.isMobile = this.appState.get('isMobile');
    this.currentUser = this.appState.get('currentUser');
    this.currentClient = this.appState.get('currentClient');
    this.themeService.setTheme(this.currentPath.getTheme())
    this.surveyQuestions = this.currentPath.closeoutSurveys
    this.screensIds = [
      'survey-screen-animation',
      ...this.surveyQuestions.map(q => `survey-screen-${q.id}`),
      'survey-screen-last',
    ]
    this.currentScreenIndex = 0
    this.currentScreenId = this.screensIds[this.currentScreenIndex]
    
  }

  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    console.log("this.isMobileWeb value", this.isMobileWeb);
    this.statService.getDailyParticipation().subscribe(
      response => {
        const cutResponse = this.statService.cutParticipationData(response);
        this.chartSettings = {
          colour: this.currentPath.colours['theme'],
          labels: cutResponse.labels,
          data: cutResponse.data,
          greenDays: response.greenDays,
          realDay: response.realDay
        }
        this.leaderboardPosition = response.leaderboardPosition
        this.badgesCount = response.badgesCount
        const firstSurvey = this.statService.findFirstCompletedSurvey(this.currentPath.contentSurveys);
        const finalSurvey = this.statService.findFinalCompletedSurvey(this.currentPath.contentSurveys);
        if (firstSurvey) {
          this.firstSurveyStats = firstSurvey.getStats()
        }
        if (finalSurvey) {
          this.finalSurveyStats = finalSurvey.getStats()
        }
      }
    )
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  ngDoCheck() {
    this.isRankSwaped = this.appState.get('isRankSwapped')
  }

  ngAfterViewInit() {
    this.showCurrentScreen()
  }
  showCurrentScreen() {
    this.surveyScreens.map((item) => {
      const { nativeElement: { classList } } = item
      if (item.nativeElement.id === this.currentScreenId) {
        classList?.add('show')
      } else {
        classList?.remove('show')
      }
    })
  }
  firstScreen() {
    this.currentScreenIndex = 0
    this.currentScreenId = this.screensIds[this.currentScreenIndex]
    this.showCurrentScreen()
  }
  prevScreen(block = false) {
    if (block) return false
    this.currentScreenIndex--
    if (this.currentScreenIndex < 0) window.history.back()
    this.currentScreenId = this.screensIds[this.currentScreenIndex]
    this.showCurrentScreen()
  }
  nextScreen(block = false) {
    if (block) return false
    this.currentScreenIndex++
    this.currentScreenId = this.screensIds[this.currentScreenIndex]
    this.showCurrentScreen()
  }
  selectOption(question, option) {
    this.answers[`Survey:${question.id}`] = option
  }
  isSelected(question, option) {
    return this.answers[`Survey:${question.id}`] === option
  }
  isAnswered(question) {
    return !!this.answers[`Survey:${question.id}`]
  }

  selectTime(option) {
    if (this.confirmedTime) return
    this.selectedTimeOption = option
    let time = ''
    switch (option) {
      case 'AFTER_WAKING_UP':
        time = '08:00'
        break
      case 'AFTER_ARRIVING_AT_WORK':
        time = '11:00'
        break
      case 'AFTER_LUNCH':
        time = '13:00'
        break
      case 'AFTER_ARRIVING_HOME':
        time = '19:00'
        break
      default: time = '11:00'
    }
    console.log(option, time)
    window.requestAnimationFrame(() => {
      this.timePicker.setActiveTimeByTime(time)
    })
  }
  confirmTime() {
    this.confirmedTime = this.timePicker.getTime()
  }
  unconfirmTime() {
    const previousTime = this.confirmedTime
    this.confirmedTime = null
    window.requestAnimationFrame(() => {
      this.timePicker.setActiveTimeByTime(previousTime)
    })
  }
  questionAnswer(question) {
    const option = this.answers[`Survey:${question.id}`]
    return option && option.text ? option.text : 'N/A'
  }
  permissionRequest() {
    // TODO: Add Cordova push API
    this.notificationService.confirm('Allow app to send push notifications?')
    this.nextScreen()
  }
  addCalendarEvent() {
    this.notificationService.confirm('Add events to you calendar?')
    this.nextScreen()
  }
  explore() {
    this.savingResults = true
    let addedManagerEmail = this.appState.get("managerEmail");
    let currentDay = this.appState.get("currentScheduleDay");
    this.surveyService.saveAnswers(this.answers, null,null).subscribe(
      (status) => {
        this.appState.set("isRankSwapped", false);
        this.savingResults = false;
        if (!this.isMobile) {
          this.modalController.dismiss();
          this.homeUIService.openDailyStepsComponent(
            CloseoutSurveyWelcomeComponent,
            "",
            "web-closeout-welcome-modal"
          );
        } else {
          this.router.navigate(["/closeout-welcome"]);
        }
      },
      (error) => {
        this.savingResults = false;
      }
    );
  }

  calculateScreen(i,surveyQuestions,question){
    if(i < surveyQuestions.length-1){
      this.nextScreen()
    }else if(i === surveyQuestions.length-1){
      if(!this.isMobile){
        this.modalController.dismiss();
        this.homeUIService.openDailyStepsComponent(CloseoutSurveyWelcomeComponent,'','web-closeout-welcome-modal');
      }
      else {
       this.router.navigate(["/closeout-welcome"]);
      }

      //  this.explore()
    }
    
  }

  receiveData($event) {
    this.dataFromChild = $event;
  }
  receiveScale(event) {
    this.scaleData = event.scaleValue;
    this.setScale(event.question,event.higherScale)
  }

  blurCard(enable,ptype, linearScale?,pathAverage?) {
    let  rationale;
    this.surveyQuestions?.forEach((question)=>{
     if(question.ptype =='mcq'){
      rationale =question.rationale;
     }
    })
    if(ptype =='linear') {
      this.enableBlur =enable;
      this.linearScale =linearScale;
      this.pathAverage =pathAverage;
      if(enable ==true){
        this.renderer.addClass(this.disableCard?.nativeElement, 'container-blur');
        
        document.getElementsByClassName('survey-screens')[0]?.setAttribute('style','overflow: hidden;height: 92vh;background: lightgray;border-radius: 16px; position: relative;')
        if(!this.isMobile){
          document.getElementsByClassName('survey-screens')[0]?.setAttribute('style',' margin-top: 5%;overflow: hidden;height: 95vh;background: lightgray;border-radius: 16px; position: relative;')

        }
      }
      else if(enable ==false) {
        this.renderer.removeClass(this.disableCard?.nativeElement, 'container-blur');
        document.getElementsByClassName('survey-screens')[0]?.setAttribute('style','overflow: hidden;height: 92vh;background: white;border-radius: 16px; position: relative;')
        if(!this.isMobile){
          document.getElementsByClassName('survey-screens')[0]?.setAttribute('style',' margin-top: 5%;overflow: hidden;height: 95vh;background: white;border-radius: 16px; position: relative;')

        }
      }
    }
    else if(ptype =='mcq' ) {
      this.enableMcqBlur =enable
      if(enable ==true && rationale){
        this.renderer.addClass(this.disableMcqCard?.nativeElement, 'container-blur');
        document.getElementsByClassName('survey-screens')[0]?.setAttribute('style','overflow: hidden;height: 92vh;background: lightgray;border-radius: 16px; position: relative;')
        if(!this.isMobile){
          document.getElementsByClassName('survey-screens')[0]?.setAttribute('style',' margin-top: 5%;overflow: hidden;height: 95vh;background: lightgray;border-radius: 16px; position: relative;')

        }
      }
      else if(enable ==false) {
        this.renderer.removeClass(this.disableMcqCard?.nativeElement, 'container-blur');
        document.getElementsByClassName('survey-screens')[0]?.setAttribute('style','overflow: hidden;height: 92vh;background: white;border-radius: 16px; position: relative;')
        if(!this.isMobile){
          document.getElementsByClassName('survey-screens')[0]?.setAttribute('style',' margin-top: 5%;overflow: hidden;height: 95vh;background: white;border-radius: 16px; position: relative;')

        }
      }
    }
   
  }
  setScale(option: any,higherScale?) {
    const requestData = {
      'path_id': this.currentPath.id,
      'user_id': this.currentUser.id,
      'schedule_day': this.appState.get('currentScheduleDay'),
      'linear_rating': this.scaleData,
      'linear_question_id': option.id,
    }

    if (!requestData) {
    } else {
      this.pollService.setOnboardingLinearScale(requestData).subscribe(
        (response) => {
          console.log('response', response.data)
          this.responseData = response.data
          for (let linear of this.responseData ) {
            this.pathAverage = linear?.path_average+'/'+higherScale;
          }
          this.blurCard(true,'linear',this.scaleData+"/"+higherScale,this.pathAverage)        },
        (error) => {
          console.log(error);
        }
      )
    }
  }
  
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.disableCard?.nativeElement.contains(event.target)) {
      this.enableBlur =false;
     this.blurCard(this.enableBlur,'linear',this.scaleData, this.pathAverage);
    }
    if(this.disableMcqCard?.nativeElement.contains(event.target)) {
      this.enableMcqBlur =false;
      this.blurCard(this.enableMcqBlur,'mcq',this.scaleData, this.pathAverage);
    }
  }
}
