import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppState } from '../app.service';
import { Client } from '../client/client.model';
import { Path } from '../path/path.model';
import { ThemeService } from '../theme/theme.service';

import introJs from 'intro.js/intro.js';
import * as $ from 'jquery';
import { MatDialog } from '@angular/material/dialog';
import { AlertPlayIntroComponent } from '../alert-play-intro/alert-play-intro.component';
import { SessionService } from '../session/session.service';
import { I18n } from '../i18n.service';
import mixpanel from 'mixpanel-browser';

@Component({
  selector: 'first90-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {

  @ViewChild("modalTutorial") modalTutorial: ElementRef;
  @ViewChild("video") video: ElementRef;

  @ViewChild('step4') inputEl: ElementRef;

  client: Client;
  path: Path;
  layoutClass: string = "";
  isVideoLoaded: boolean;

  isIntroShown: any;
  scheduledStep: any;
  intro: any;
  currentUser: any;
  currentDayStarted: boolean;
  isMobile: boolean;
  timer;

  constructor(
    private appState: AppState,
    private themeService: ThemeService,
    private dialog: MatDialog,
    public sessionService: SessionService,
    public i18n: I18n
  ) {
    this.client = this.appState.get("currentClient");
    this.path = this.appState.get("currentPath");
    this.currentUser = this.appState.get('currentUser')
    this.themeService.setTheme(this.path.getTheme());
    this.scheduledStep = this.appState.get('scheduleDayForCurrentStep')
    this.currentDayStarted = !!localStorage.getItem(
      `Toggle:User:${this.currentUser.id}:DayProgress:${this.path.id}:${this.scheduledStep}`
    )
    this.isIntroShown = localStorage.getItem("isIntroShown");

  }

  ngOnInit() {
    this.checkMobile()
    this.sessionService.getLibraryPosts();
    this.path = this.appState.get("currentPath");
    //this.themeService.setTheme(this.path.getTheme());
  }

  ngAfterViewInit() {
    // localStorage.setItem('dontShowAgain', 'true');   //ADDED THIS LINE TO HIDE TOUR
    if (localStorage.getItem("dontShowAgain") !== 'true') {
      const dialogRef = this.dialog.open(AlertPlayIntroComponent)
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'confirm') {
          introJs(document.querySelector("first90-main")).setOptions({
            'nextLabel': this.i18n.t("Next"),
            'prevLabel': this.i18n.t("Previous"),
            'skipLabel': this.i18n.t("Skip"),
            "dontShowAgain": true,
            "exitOnOverlayClick": false,
            "hidePrev": true,
            "overlayOpacity": 0.8,
            'doneLabel': 'Finish',
            "autoPosition": true,
          }).onbeforechange(function (targetElement) {
            let dataStep = targetElement.getAttribute("data-step");
            if(window.innerWidth > 800){          
              if (dataStep > 1 && dataStep < 6  ) {
                mixpanel.track("QS Tour-Tour Steps", {'steps': dataStep})
                $(".introjs-helperLayer").css("margin-left", "-5px");
              } else if (dataStep == 6  ){
                mixpanel.track("QS Tour-Tour Steps", {'steps': dataStep})
                $(".introjs-helperLayer").css("margin-left", "0px");
              }else if (dataStep==7 ){
                mixpanel.track("QS Tour-Tour Steps", {'steps': dataStep})
                $(".introjs-helperLayer").css("margin-left", "-5px");
              }else if(dataStep==8 ){
                mixpanel.track("QS Tour-Tour Steps", {'steps': dataStep})
                $(".introjs-helperLayer").css("margin-left", "1px");
                $(".introjs-helperLayer").css("margin-top", "-1px");
              }
            }else{
              if(dataStep < 7 ){
                mixpanel.track("QS Tour-Tour Steps", {'steps': dataStep})
                $(".introjs-helperLayer").css("margin-top", "5px");
              }else if(dataStep <=8){
                mixpanel.track("QS Tour-Tour Steps", {'steps': dataStep})
                $(".introjs-helperLayer").css("margin-top", "-.5px");
              }else{
                mixpanel.track("QS Tour-Tour Steps", {'steps': dataStep})
                $(".introjs-helperLayer").css("margin-top", "5px");
              }
    
              if (dataStep > 8 && window.innerHeight < 700) {
                $(".introjs-tooltipReferenceLayer").css("margin-top", "-7px");
              }
            }
          }).onbeforeexit(function (data) {
            mixpanel.track("QS Tour-Tour Skipped",{'isSkipped': true})
            localStorage.setItem("dontShowAgain", 'true');
          }).start();
          if(window.innerWidth > 800){
            $(".introjs-helperLayer").css("margin-left", "-5px");
          }
          introJs().oncomplete(function () {
            localStorage.setItem('doneTour', 'yeah!');
          });
        }else{
          mixpanel.track("QS Tour-Tour Skipped",{'isSkipped': true})
          localStorage.setItem('dontShowAgain', 'true');
        }
      })     
    }    

  }


  checkMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // true for mobile device
      this.isMobile = true
      this.appState.set("isMobile", this.isMobile)
    } else {
      // false for not mobile device
      this.isMobile = false
      this.appState.set("isMobile", this.isMobile)
    }
  }

}
