import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-add-notes',
  templateUrl: './alert-add-notes.component.html',
  styleUrls: ['./alert-add-notes.component.scss'],
})
export class AlertAddNotesComponent implements OnInit {

  postText:any = ''

  constructor(public dialogRef: MatDialogRef<AlertAddNotesComponent>,) { }

  ngOnInit() {}

  onSubmit(){
    localStorage.setItem('journalNote',this.postText);
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
