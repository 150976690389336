import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppState } from '../app.service';

@Component({
  selector: 'app-alert-commit-calender',
  templateUrl: './alert-commit-calender.component.html',
  styleUrls: ['./alert-commit-calender.component.scss'],
})
export class AlertCommitCalenderComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AlertCommitCalenderComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private appState: AppState) { }

  ngOnInit() {
  }

}
