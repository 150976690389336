import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { hexToRgb } from '../utils.service'
import { Chart } from 'chart.js'



export interface ChartSettings {
  colour: string
  labels: string[]
  data: any
  greenDays: number[]
  realDay: number
}

@Component({
  selector: 'app-daily-participation-chart',
  templateUrl: './daily-participation-chart.component.html',
  styleUrls: ['./daily-participation-chart.component.scss']
})
export class DailyParticipationChartComponent implements OnInit, OnChanges, AfterViewInit {
  // tslint:disable-next-line:no-input-rename
  @Input('height') height = 300
  // tslint:disable-next-line:no-input-rename
  @Input('settings') settings: ChartSettings
  @ViewChild('participationChart') participationChart: ElementRef

  private chartJs: any
  private participationChartElement: HTMLElement

  constructor() {
  }

  ngOnInit() {
  
  }

  ngAfterViewInit(){
    try {
      this.participationChartElement = this.participationChart.nativeElement;
    } catch (error) {
      
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.chartJs) { this.chartJs.destroy() }
    if (this.settings) { this.buildChart() }
    console.log("this.settings ",this.settings)
  }

  heightStyle() {
    return {height: `${this.height}px`}
  }

  buildChart() {
    const width = 20 + this.settings.labels.length * 40
    console.log('this.participationChartElement', this.participationChartElement);
    
    this.participationChartElement.parentElement.style.width = width + 'px'
    this.chartJs = new Chart(this.participationChartElement, {
      type: 'line',
      backgroundColor: '#6ca6cd',

      data: {
        labels: this.settings.labels,
        datasets: [{
          data: this.settings.data,
          fill: true,
          borderColor: '#5290AC',
          pointBorderColor: 'white',
          pointBorderWidth: 4,
          pointRadius: 5,
          pointBackgroundColor: '#30667F',
          borderWidth: 3,
          backgroundColor: 'transparent',
          pointHitRadius: 8,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 4
        }]
      },
      options: {
        steppedLine: false,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        elements: {
          line: {
            tension: 0.4, // disables bezier curves
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: false,
              stepSize: 1,
              suggestedMin: 0,
              suggestedMax: 5,
              min: 0,
            },
            gridLines: {
              display: false
            }
          }],
          xAxes: [{

            fontColor: '#00000',
            fontSize: 14
          }]
        }
      }
    })
  }
}
