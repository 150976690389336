interface InputArguments {
  includeNested?: boolean
  include?: string[]
  exclude?: string[]
}

export abstract class Base {

  protected _fields: string[]
  protected _nestedFields: object
  protected _defaultInput: InputArguments
  /* 
    Load _fields and _nestedFields in this function
  */
  abstract init()
  afterInit() {} // callbacks
  beforeInput() {}

  input(args: InputArguments = {includeNested: false}) {
    this.beforeInput()
    let clone: object = {}
    let inputSettings = this._defaultInput ? Object.assign(this._defaultInput, args) : args
    if (inputSettings.exclude) {
      // FIXME: maybe convert to non-destructive fashion 
      this._fields = this._fields.filter(field => -1 === inputSettings.exclude.indexOf(field))
    } else if (inputSettings.include) {
      this._fields = this._fields.filter(field => -1 !== inputSettings.exclude.indexOf(field))
    }
    this._fields.forEach(field => {
      if (this._nestedFields && this._nestedFields[field]) {
        if (!inputSettings.includeNested) return // Skip nested fields if flag is set
        if (this[field] instanceof Array) {
          clone[field] = []
          for (let i in this[field])
            clone[field].push(this[field][i].input({includeNested: inputSettings.includeNested}))
        } else if (this[field] instanceof Object) {
          clone[field] = this[field].input({includeNested: inputSettings.includeNested})
        }
      } else {
        if (typeof this[field] !== 'undefined')
          clone[field] = this[field]
      }
    })
    return clone
  }

  constructor(public _data: Object = {}) {
    if (_data === null) return null
    this.init()
    // console.log(this._fields, _data)        
    // https://github.com/Microsoft/TypeScript/issues/1617

    // Hack for Apollo __typename
    // https://github.com/apollographql/apollo-client/issues/1564

    if (this._nestedFields instanceof Array) {
      throw new Error('_nestedFields on models should be a key-value object')
    }
    // First deal with nested 
    for (let field in this._nestedFields) {
      if (typeof _data[field] === 'undefined') continue
      if (_data[field] instanceof Array) {
        this[field] = []
        for (let i in _data[field])
          this[field].push(new this._nestedFields[field](_data[field][i]))
      } else if (_data[field] instanceof Object) {
        this[field] = new this._nestedFields[field](_data[field])
      }
    }

    // Deal with plain fields
    this._fields.forEach(field => {
      if (typeof _data[field] === 'undefined' ||
        (this._nestedFields && this._nestedFields[field])) return

      // We need to do clone non-scalar vars because data from Apollo client response is immutable
      if (_data[field] instanceof Array) {
        this[field] = _data[field].slice(0) 
        // Fastest way to clone array 
        // via https://stackoverflow.com/questions/3978492/javascript-fastest-way-to-duplicate-an-array-slice-vs-for-loop
      } else if (_data[field] instanceof Object) {
        this[field] = Object.assign({}, _data[field])
      } else {
        this[field] = _data[field]
      }
    })
    this.afterInit()
  }
}