import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Filter } from '../shared/filter'
import * as query from './document.query'
import { Document } from './document.model'
import { PathDocument } from '../path/path-document.model'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { AppState } from '../app.service'
import { WalletService } from '../wallet/wallet.service'
import { invalidateCache } from '../utils.service'
import { HomePageService } from '../pages/home/home-page.service'

@Injectable()
export class DocumentService {
  constructor(
    private apollo: Apollo, 
    private appState: AppState, 
    private homePageService: HomePageService,
    public walletService: WalletService) { }

  // TODO: Fix this name.
  getList(filter: Filter = null): Observable<PathDocument[]> {
    return this.apollo
      .watchQuery<any>({ query: query.listPathDocuments, variables: { filter: filter } })
      .valueChanges
      .pipe(
        map(response => {
          let list: PathDocument[] = []
          response.data.pathDocuments.forEach(element => { list.push(new PathDocument(element)) })
          return list
        })
      )
  }

  getDocument(id: number): Observable<Document> {
    return this.apollo
      .watchQuery<any>({ query: query.findDocument, variables: { id: id } })
      .valueChanges
      .pipe(
        map(response => {
          return new Document(response.data.document)
        })
      )
  }

  save(document: Document) {
    return this.apollo.mutate({
      mutation: query.updateDocument,
      variables: {
        document: document.input()
      }
    })
  }

  viewDocument(documentId: number) {
    return this.apollo.mutate({
        mutation: query.viewDocument,
        variables: {
          documentId: documentId
        },
        optimisticResponse: {
          __typename: 'Mutation',
          viewDocument: {
            __typename: 'ToggleDocumentPayload',
            status: true,
            actionPoints: this.walletService.actionPoints.value,
          }
        },
        update: (proxy, { data: { viewDocument } }) => {
          this.homePageService.updateCompletions(proxy, `Document:${documentId}`)
          proxy.writeFragment({
            id: `Document:${documentId}`,
            fragment: query.DOCUMENT_UPDATE_FRAGMENT,
            data: { isCompleted: true }
          })
          this.appState.get('currentPath').update('documents', documentId, {isCompleted: true, time: new Date()})
          this.walletService.update(viewDocument.actionPoints)
          invalidateCache(proxy, 'ROOT_QUERY.dailyParticipation')
        }
      }).pipe(
        map(response => {
          return response.data.viewDocument
        })
      )
  }
}
