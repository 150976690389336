import { Component, OnInit } from '@angular/core';
import { AppState } from 'src/app/app.service';
import { I18n } from 'src/app/i18n.service';
import { Path } from 'src/app/path/path.model';
import { AnalyticsServiceService } from 'src/app/services/analytics-service.service';
import { ToasterService } from 'src/app/toaster.service';
import { User } from 'src/app/user/user.model';
import { JournalPost } from './journal-post.model';
import { JournalService } from './journal.service';

@Component({
  selector: 'app-journal',
  templateUrl: './journal.component.html',
  styleUrls: ['./journal.component.scss'],
})
export class JournalComponent implements OnInit {

  
  public isLoading = false
  public postText: string
  public cachedPostText: string  // LUMBA-1383
  public journalPosts: JournalPost[]
  public currentPath: Path
  public currentUser: User
  public sortBy = 'mostRecent'
  public notePage = 'write'
  public sendingPdf = false
  public isAllNotesShown = false
  public isWitesShown = true

  constructor(
    private appState: AppState,
    private i18n: I18n,
    private journalService: JournalService,
    private toastrService: ToasterService,
    private analyticsService: AnalyticsServiceService
  ) { 
    this.currentPath = this.appState.get('currentPath')
    this.currentUser = this.appState.get('currentUser')
  }

  ngOnInit() {
    this.journalService.getList().subscribe(journalPosts => {
      this.journalPosts = journalPosts
      console.log('this.journalPosts :', this.journalPosts);
      //this.sort()
      this.writeNotes()
      this.sort('pinned');

    })

    this.analyticsService.setScreen(AnalyticsServiceService.JOURNAL)
  }


  allNotes() {
    this.isAllNotesShown=true
    this.isWitesShown=false
     // sorted data
    this.notePage='allnotes'
  }

  writeNotes() {
      this.isWitesShown=true
      this.isAllNotesShown=false
      this.notePage = 'write'
  }


  sort(sortBy: string = null) {
    if (sortBy) {
     this.sortBy = sortBy
    }
    const key = this.sortBy === 'mostRecent' ? 'timestamp' : 'isPinned'
    this.journalPosts.sort((a, b) => a[key] > b[key] ? -1 : a[key] < b[key] ? 1 : 0)
  }

  post() {
    if (this.postText.replace(/\s+/, '').length === 0) { return false }
    this.isLoading = true
    this.unloadPost()
    this.journalService.post(this.cachedPostText).subscribe(
     () => {
        this.isLoading = false
        this.reset()
        this.toastrService.success(this.i18n.t("Note saved!"));
     },
      () => {
        this.isLoading = false
        this.loadPost()
        this.toastrService.error()
      },
      () => {
     })
  }

  pin(journalPost: JournalPost) {
    this.journalService.toggleJournalPostPin(journalPost.id, !journalPost.isPinned).subscribe(
      () => {},
      () => {},
      () => {}
    )
    // show only that not
    this.allNotes();
    this.sort('pinned');

  }

   // LUMBA-1383
   loadPost() {
    if (this.cachedPostText) {
      this.postText = this.cachedPostText
      this.cachedPostText = null
    }
  }

  // LUMBA-1383
  unloadPost() {
    this.cachedPostText = this.postText
    this.postText = null
  }

  reset() {
    this.postText = null
    this.cachedPostText = null
  }


  sendPdf() {
    this.sendingPdf = true
    this.journalService.sendPdf().subscribe(
      () => {
        this.toastrService.success(this.i18n.t('PDF sent!'))
        this.sendingPdf = false
      },
      () => {
        this.toastrService.error(this.i18n.t('PDF not sent. Please check your internet connection.'))
        this.sendingPdf = false
      }
    )
  }
}
