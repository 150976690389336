import { Component, Input } from '@angular/core'

import { UserBadge } from '../user/user-badge.model'

@Component({
  selector: 'first90-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
// TODO: Rename this component to UserBadgeComponent
export class BadgeComponent {
  @Input() userBadge: UserBadge
  @Input() actionPoints: number = null
  @Input() imageUrl: string = ''
  @Input() badgeId: string
  @Input() colour: string = ''
  @Input() title: string = ''
  @Input() earned: boolean = false
}
