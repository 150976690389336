import { Base } from "src/app/shared/base"
import { TextScreen } from "src/app/text-screen/text-screen.model"

export interface Prompt {
  text: string
}

export class Reflection extends Base {

  init() {
    this._fields = ['id', 'title', 'prompt', 'isMandatory', 'startDay', 'endDay', 'enabled', 'preScreens',
      'postScreens', 'screenOrder', 'isDeleted', 'actionPoints', 'text', 'isCompleted', 'imageUrl']
    this.preScreens = []
    this.postScreens = []
    this.screenOrder =  0
  }

  afterInit() {
    this.preScreens = this.preScreens || []
    this.postScreens = this.postScreens || []

    if (this.text) {
      this.isCompleted = true
    }
  }

  public id: number
  public title: string
  public prompt: Prompt[]
  public text: string
  public actionPoints: number

  public isCompleted: boolean
  public isMandatory: boolean
  public startDay: number
  public endDay: number
  public screenOrder: number

  public enabled: boolean
  public preScreens: TextScreen[]
  public postScreens: TextScreen[]

  public maxLength: number
  public draft: boolean

  public isDeleted: boolean
  public imageUrl: string
}
