import { Injectable, ViewChild } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppState } from '../app.service';
import { RoutineV2Service } from '../routine-v2/routine-v2.service';
import { RoutineCalendarComponent } from '../routine-v2/routine-calendar/routine-calendar/routine-calendar.component';
import { I18n } from '../i18n.service';
import * as moment from 'moment';
import { AlertRoutineAnimComponent } from '../alert-routine-anim/alert-routine-anim.component';
 import { MyCommitmentsComponent } from './my-commitments.component';
import { ToasterService } from '../toaster.service';
import { AppConstantsService } from '../app-constants.service';
import { DailyStepsService } from '../daily-steps/daily-steps.service';
import { HomeV5Service } from '../pages/home-v5/home-v5.service';
import { AlertWebCalendarComponent } from '../alert-web-calendar/alert-web-calendar.component';
import { CordovaService } from '../cordova.service';
import { CalendarParams } from '../daily-content-v2/daily-content-v2.component';
import { MatDialog } from '@angular/material/dialog';
import { CalendarService } from '../calendar.service';
import { AnimationsService } from '../shared/animations.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { AlertRepeatEventComponent } from '../alert-repeat-event/alert-repeat-event.component';

@Injectable({
  providedIn: 'root'
})
export class MyCommitmentsService {
  isLoaded = new Subject<boolean>();
  localRoutineData;
  commitType;
  makeCommitment;
  activityCueTime;
  subRoutineAnswer;
  dayTimeArray;
  typeValue;
  selectedDates: string[];
  colorArray = [];
  selectedTimings = [];
  currentUser;
  currentPath;
  levelId;
  isMobile;
  dayNameArray = [];
  hasNativeCalendar: any;
  calendarParams: CalendarParams
  todayTimeArray = [];
  recurrenceInterval;
  modalStack = [];
  IsRecommitNotificationAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(private modalController: ModalController, private appState: AppState, private cordovaService: CordovaService,
    private routinev2Service: RoutineV2Service, private alertController: AlertController, private calendarService: CalendarService,
    private i18n: I18n, private toasterService: ToasterService,private animationService: AnimationsService,
    private dailyStepsService: DailyStepsService, private homev5Service: HomeV5Service, private dialog: MatDialog, private screenOrientation:ScreenOrientation,
    private appConstantService: AppConstantsService
  ) { 
    this.currentUser = this.appState.get('currentUser')
    this.isMobile = this.appState.get('isMobile');
    this.currentPath = this.appState.get('currentPath')
  }
  setLoader(val){
    this.isLoaded.next(val);
  }
  get getLoader(){
    return this.isLoaded.asObservable()
  }

  closeModals(){
    this.modalController.dismiss()
    setTimeout(() => {
      this.modalController.dismiss()
    }, 550);
  }

   getLocalRoutineData() {
    var localCommitData = localStorage.getItem('commitData')
    var appStateCommitData = this.appState.get('commitData')
    if (localCommitData) {
      this.localRoutineData = localCommitData
      var type = this.localRoutineData.split(',')
      this.commitType = type[1]
      this.makeCommitment = type[0]
    } else {
      this.localRoutineData = appStateCommitData
    }

    var localMakeCommitment = localStorage.getItem('makeCommitment')
    var localActivityCueTime = localStorage.getItem('activityCueTime')
    var appStateMakeCommitment = this.appState.get('makeCommitment')
    var appStateActivityCueTime = this.appState.get('activityCueTime')
    if (localMakeCommitment && localActivityCueTime) {
      this.makeCommitment = localMakeCommitment
      this.activityCueTime = localActivityCueTime
    } else {
      this.makeCommitment = appStateMakeCommitment
      this.activityCueTime = appStateActivityCueTime
    }
   }
  
  async openRoutineCalendarForLevel(isSingleEvent: boolean, subRoutineAnswer: any, routine: any, level: any,isWeekendReopt?) {
    this.getLocalRoutineData();
    this.typeValue = level?.type_value;
    this.commitType = level?.routine_type;
    this.activityCueTime = level?.activity_time
    this.makeCommitment=isSingleEvent ==null?'no':'yes'
    var routineTime = level?.routine_time
    var routineDay = level?.day
    if (!routineTime) { routineTime = '' }
    if (!routineDay) { routineDay = '' }
    this.appState.set('routineTime',routineTime)
    this.appState.set('selectedDate', routineDay)
    this.appState.set('commitData',`${this.makeCommitment},${this.commitType},${this.typeValue}`)
    this.appState.set('makeCommitment',this.makeCommitment)
    // if (routineDay) {
    //   var today = (routineDay).getDay();
    //   var dayName = this.routinev2Service.getDayFromDate(today)
    // }
    if (!this.activityCueTime) { this.activityCueTime = '' }
    if (this.commitType === 'Activity' && !this.activityCueTime) { this.activityCueTime = 'before' }

    var data = {
      text: level.text,
      title: level?.title,
      routineAnswer: subRoutineAnswer,
      level: level,
      isSingleEvent: !isSingleEvent,
      typeValue: level?.type_value,
      activityCueTime: this.activityCueTime? this.activityCueTime:level?.activity_time,
      selectedCueOption: level?.routine_type,
      commitType: this.commitType? this.commitType:level?.routine_type,
      makeCommitment: this.makeCommitment
    }
    this.appState.set("routineCalendarData", data)
    if (this.isMobile) {
      const dialogRef = this.dialog.open(RoutineCalendarComponent,{
        // data: { isWeekendCalandar: isWeekendReopt },
      maxWidth: '80vw',
      minWidth:'300px',
      maxHeight: '500px',
      height: '100%',
      width: '100%',
    })
      dialogRef.afterClosed().subscribe((result) => {
         isSingleEvent = this.appState.get("routineCalendarData")?.isSingleEvent
        if (isSingleEvent == null) {
          this.earlierOptedNotsureTimesDays(subRoutineAnswer, routine, level, isSingleEvent)
        }
        else {
          this.editCommitmenntConfirmation(isSingleEvent, subRoutineAnswer, routine, level,isWeekendReopt)
        }
      });
    }
    else {
 const modal = await this.modalController.create ({
    component: RoutineCalendarComponent,
    animated: false,
    // componentProps: { isWeekendCalandar:{ isWeekendCalandar: isWeekendReopt }},
    cssClass:this.isMobile?'changeDialog-color2':' web-action-calender-modal routine-calN',
    //backdropDismiss:false
  })
    await modal.present()
    modal.onWillDismiss().then((result) => {
      console.log("on ackdropdismss", result);
      if (result?.role === 'backdrop') {
        if (this.appState.get('IsRepsAppendAfterReoptCommit') == true) {
         this.closingModalOnClickingBackground();
        }
      }
    })
      modal.onDidDismiss().then(async (result) => {
        isSingleEvent = this.appState.get("routineCalendarData")?.isSingleEvent
        if (isSingleEvent == null) {
          this.earlierOptedNotsureTimesDays(subRoutineAnswer, routine, level, isSingleEvent)
        }
        else {
          this.editCommitmenntConfirmation(isSingleEvent, subRoutineAnswer, routine, level,isWeekendReopt)
        }
      })
    }
 
  }
  async editCommitmenntConfirmation(isSingleEvent: boolean, subRoutineAnswer: any, routine: any, level: any,  isWeekendReopt?) {
    console.log("result after modal close");
    this.isMobile = this.appState.get('isMobile');
      var routineTime = this.appState.get('routineTime')
      var routineDay = this.appState.get('selectedDate')
    var routineDate = level?.routine_date
    let eventOldScheduledTimes=[], eventOldScheduledDay=[]
      if (!routineTime) { routineTime = '' }
      if (!routineDay) { routineDay = '' }

      // if (routineDay) {
      //   var today = (routineDay).getDay();
      //   var dayName = this.routinev2Service.getDayFromDate(today)
      // }
      if (!this.activityCueTime) { this.activityCueTime = '' }
      if (this.commitType === 'Activity' && !this.activityCueTime) { this.activityCueTime = 'before' }
      var differentTimes = this.appState.get('differentTimes')
      var differentDays = this.appState.get('differentDays')
      if (differentTimes) {
        differentTimes = this.routinev2Service.formatTimeForFrontEnd(differentTimes);
      }
      if (differentDays && differentDays?.length>0) {
        this.dayTimeArray = differentDays.map(function (x, i) {
          return x + ' at ' + differentTimes[i]
        })

        this.subRoutineAnswer = this.routinev2Service.createCommitEvent(routine, level, this.typeValue,
          this.activityCueTime,
          differentTimes, differentDays, this.commitType, this.makeCommitment, "on " + this.dayTimeArray.join(', '))
      } else if (!differentDays || differentDays?.length==0) {
        differentDays = 'every day'
        this.dayTimeArray = differentDays + " at " + differentTimes
        this.subRoutineAnswer = this.routinev2Service.createCommitEvent(routine, level, this.typeValue, this.activityCueTime, differentTimes, differentDays, this.commitType, this.makeCommitment, this.dayTimeArray)
      }

      if (differentTimes?.length >= 1) {
        const alert = await this.alertController.create({      
          header: this.i18n.t("My Commitment"),
          message: this.subRoutineAnswer,
          cssClass: 'alertBackdrop',
          buttons: [
            {
              cssClass:"alert-edit",
              text: this.i18n.t("Edit"),
              handler: async () => {
                this.appState.set('dayTimeArray', this.dayTimeArray)
                this.appState.set('isTimeEdited', true)
                  if (this.appState.get("routineCalendarData")) {
                     isSingleEvent= !this.appState.get("routineCalendarData")?.isSingleEvent
                   }
                    this.openRoutineCalendarForLevel(isSingleEvent, this.subRoutineAnswer, routine, level)
              },
              role: 'cancel',
            },
            {
              text: this.i18n.t("Commit"),
              cssClass: 'alert-commite-btn alert-commite-btn',
              handler: (result) => {
                let multipleDates = this.appState.get('multipleDates')
                let eventOldScheduledDates =[]
                routineDate?.forEach((dateObj, index) => {
                  let matchedDate = multipleDates?.some(date1 =>typeof(date1)=='object'? date1?.valueOf() === new Date(dateObj)?.valueOf(): date1 === dateObj)
                  let matchedDateIndex
                  if (matchedDate) {
                    matchedDateIndex = multipleDates?.findIndex((dateObj1)=>typeof(dateObj1)=='object'? dateObj1?.valueOf() === new Date(dateObj)?.valueOf(): dateObj1===dateObj)
                  }
                  // if (matchedDateIndex !== index ) {
                  //   eventNewScheduledDates.push(dateObj);
                  // }
                  // else {
                  if (matchedDateIndex == index) {
                    console.log("routineDate.slice(index, 1)", routineDate.slice(index, 1))
                    if (differentTimes?.length > index && differentDays?.length > index && routineDate?.length > index) {
                      eventOldScheduledTimes.push(differentTimes[index]);
                      eventOldScheduledDates.push(routineDate[index]);
                      eventOldScheduledDay.push(differentDays.slice(index, index+1)[0]);
                    }
                  }
                })
                if ((!isSingleEvent || !routineDate) && eventOldScheduledDates?.length==0) {
                   this.makeRoutine(routine, level)
                }
                else {
                  if (this.isMobile) {
                    this.setCalendarEvent(routine,level, eventOldScheduledTimes,eventOldScheduledDates, eventOldScheduledDay, !isSingleEvent,isWeekendReopt?isWeekendReopt:null)
                  }
                  else {
                    this.setCalendarEventInWeb(routine,level, eventOldScheduledTimes,eventOldScheduledDates, eventOldScheduledDay, !isSingleEvent,isWeekendReopt?isWeekendReopt:null)
                  }
                }
              },
             
          },
            
        ]
      });
      
      await alert.present();
      alert.onWillDismiss().then(async (result) => {
        if (result?.role === 'backdrop') {
          if (this.appState.get('IsRepsAppendAfterReoptCommit') == true) {
            this.closingModalOnClickingBackground();
          }
        }
      })
      }
      else {
        if (this.appState.get('IsRepsAppendAfterReoptCommit') == true) {
            this.closingModalOnClickingBackground();
          }
      }
  }

  async sameCueOptSelection(isSingleEvent, subRoutineAnswer, routine, level,isWeekendReopt?) {
    this.isMobile = this.appState.get('isMobile');
   // this.resetLevelEnabledStatus(routine,level)
    this.getLocalRoutineData();
    this.currentPath= this.appState.get('currentPath')
    this.typeValue = level?.type_value;
    this.subRoutineAnswer =subRoutineAnswer
    this.commitType = level?.routine_type;
    this.makeCommitment=isSingleEvent ==null?'no':'yes'
    var routineTime = level?.routine_time
    var routineDate = level?.routine_date
    var routineDay = routineDate ? level?.day : []
     if (!routineTime) { routineTime = '' }
    if (!routineDay) { routineDay = '' }
    if (!this.commitType) { this.commitType = 'dayTime' }
    if (!this.typeValue) { this.typeValue = '' }
    if (!this.activityCueTime) { this.activityCueTime = '' }
    if (this.commitType === 'Activity' && !this.activityCueTime) { this.activityCueTime = 'before' }
    this.appState.set('commitData',`${this.makeCommitment},${this.commitType},${this.typeValue}`)
    this.appState.set('makeCommitment',this.makeCommitment)
    this.appState.set('routineTime',routineTime)
    this.appState.set('selectedDate',routineDate)
    this.activityCueTime = level?.activity_time

     const w: any = window
    this.hasNativeCalendar = this.cordovaService?.onCordova && w && w.plugins && w.plugins.calendar;
    const alert = await this.alertController.create({      
          header: this.i18n.t("My Commitment"),
          message: subRoutineAnswer,
          cssClass: 'alertBackdrop',
          buttons: [
            {
              cssClass:"alert-edit",
              text: this.i18n.t("Edit"),
              handler: async () => {
                this.appState.set('dayTimeArray', this.dayTimeArray)
                    if(routineDay && routineDay?.length==routineTime?.length){
                      this.getTimings(routineTime, routineDay, routineDate)
                    }
                    this.appState.set('isTimeEdited', true)
                    this.openRoutineCalendarForLevel(isSingleEvent, subRoutineAnswer, routine, level,isWeekendReopt?isWeekendReopt:null)
              },
              // role: 'cancel',
            },
            {
              text: this.i18n.t("Commit"),
              cssClass: 'custom-alert-commite',
              handler: (result) => {
                if (this.isMobile) {
                  this.setCalendarEvent(routine,level, routineTime,routineDate, routineDay, isSingleEvent,isWeekendReopt?isWeekendReopt:null)
                }
                else {
                  this.setCalendarEventInWeb(routine,level, routineTime,routineDate, routineDay, isSingleEvent,isWeekendReopt?isWeekendReopt:null)
                }
                //this.makeRoutine(routine, level)
              },
             
            },
            
          ]  
         });
      
    await alert.present();
        alert.onWillDismiss().then(async (result) => {
          if (result?.role === 'backdrop') {
            if (this.appState.get('IsRepsAppendAfterReoptCommit') == true) {
              this.closingModalOnClickingBackground();
            }
          }
        })
  }
    async showRepeatAlert(currentRoutine, level, routineTime, selectedDate, isTimeEvent, routineLen,index, isWeekendReopt?){
    //  if(this.isMobile) {
    //       const dialogRepeat = this.dialog.open(AlertRepeatEventComponent, {
    //       })
    //       dialogRepeat.afterClosed().subscribe((result) => {
    //         if (result == 'confirm') {
    //           this.createDifferentTimeEventsScheduleMobile(currentRoutine, level, routineTime, selectedDate, isTimeEvent, routineLen,index)
    //         }else{
    //           this.makeRoutine(currentRoutine, level)
    //         }
    //       })

    //    }
    //    else {
    //     console.log("enter repeat alert", isTimeEvent);
          const alert = await this.alertController.create({
            subHeader: this.i18n.t('The next calendar scheduler is for the next day and time you selected.'),
            backdropDismiss: false,
            buttons: [
              // {
              //   cssClass:"alert-no-btn",
              //   text: this.i18n.t("No"),
              //   handler: () =>{
              //     this.makeRoutine(currentRoutine, level)
              //   },
              //   role: 'cancel',
              // },
              {
                cssClass:"alert-yes-btn",
                text: this.i18n.t("Continue"),
                handler: () => {
                 this.createDifferentTimeEventsScheduleWeb(currentRoutine, level, routineTime, selectedDate, isTimeEvent, routineLen,index,isWeekendReopt?isWeekendReopt:null)
                },
              },
            ],
          })
          await alert.present();
       //}
  }
  prepareRoutineCalendarParams(title:any,msg: String,routinelen:any = null,eventlen:string =null, routineTime: any=null,selectedDate:any=null) {
    var date = moment().format('LL')
    if (!routineTime) {
      this.toasterService.error(this.i18n.t("Please select routine time!"))
    }
    if(selectedDate){
      const targetDateTime = moment(selectedDate);
      if (moment().valueOf() > targetDateTime.valueOf()) {
        date = this.reassignToCurrentWeek(moment(), targetDateTime);
         var eventDate = moment(date).format('LL')+ " " + routineTime;
      }
      else {
        date = moment(selectedDate).format('LL')
        var eventDate = date + " " + routineTime;
      }
    }
    var titleOfEvent = "Daily Learning Reminder"
    let start = moment(eventDate)
    let end = moment(eventDate).add(10, 'minute')

      if(this.currentPath.names && this.currentPath.names.titleOfEvent){
        titleOfEvent = this.currentPath.names.titleOfEvent;
      }else{
        titleOfEvent = "Daily Learning Reminder"
      }  

      this.calendarParams = {
        startDate: start.toDate(),
        endDate: end.toDate(),
        title: title, //updated title
        eventLocation: '',
        notes: msg,
        endEventTime: routinelen,
        frequency: eventlen
      }
  }
  reassignToCurrentWeek( currentDate,pastDate) {
  const enabledStart = currentDate; // Start of the enabled range

  // Calculate the day of the week for the past date
  const pastDayOfWeek = pastDate.day();

  // Find the corresponding day in the current week
    const newDate = enabledStart.clone().day(pastDayOfWeek);
     while (newDate.isBefore(enabledStart)) {
         if (newDate.isBefore(enabledStart)) {
           newDate.add(7, 'days'); // Shift forward by a week
         }
       }

  return newDate; // Format the reassigned date
}
  async setCalendarEvent(currentRoutine:any,level,routineTime:any,selectedDate: any,selectedDay, isTimeEvent: boolean,isWeekendReopt?){
    this.appState.set('isTimeEdited', false)
    var routineLen = 1

    if(!routineTime){
      this.toasterService.warning(this.i18n.t("Please select the time!"))
      return;
    }
    if(isTimeEvent){
      routineLen=7
    }

    if(!this.subRoutineAnswer){
      this.subRoutineAnswer=""
    }

    if(this.hasNativeCalendar){
      this.getTimings(routineTime,selectedDay, selectedDate) 
      if (isTimeEvent) {
      this.prepareRoutineCalendarParams("1st90 Habit: " + level?.title, this.subRoutineAnswer + " " + this.routinev2Service.innerHtmlToRoutinesData(level?.text), routineLen, !isTimeEvent?'Does not repeat':'Daily', routineTime, selectedDate)
      if (!isTimeEvent) {
        this.createDifferentSubRoutineEvent()
      } else {
        this.createSubRoutineEvent()
      }

      this.appState.set('isEventCreated', true)
      // this.getTimings()       
      this.makeRoutine(currentRoutine, level)
      } else if (isTimeEvent == false) {
        if (routineTime?.length > 0 && selectedDate?.length > 0 && routineTime?.length == selectedDate?.length) {
          this.createDifferentTimeEventsScheduleMobile(currentRoutine, level, routineTime, selectedDate, isTimeEvent, routineLen, 0)
        }
      }
    }else {
       this.getTimings(routineTime, selectedDay, selectedDate) 
      if (isTimeEvent) {
        this.appState.set('isEventCreated', true)
        this.prepareRoutineCalendarParams("1st90 Habit: " + level?.title, this.subRoutineAnswer + " " + this.routinev2Service.innerHtmlToRoutinesData(level?.text), routineLen, !isTimeEvent?'Does not repeat':'Daily', routineTime, selectedDate)
        this.calendarParams.notes = this.subRoutineAnswer + this.routinev2Service.innerHtmlToRoutinesData(level?.text)
        this.homev5Service.setCalendarData(this.calendarParams)
        const modal = await this.modalController.create({
          component: AlertWebCalendarComponent,
          componentProps: { isWeekendCalandar:{ isWeekendCalandar: isWeekendReopt }},
          animated: this.isTabletDevice() ? true : false,
          cssClass: this.isMobile ? 'custom-modal-size' : 'web-action-calender-modal',
       
        });
        await modal.present()
        await modal.onDidDismiss().then(async () => {
          //this.showRepeatAlert(isTimeEvent)
          await this.makeRoutine(currentRoutine,level)
        });
        
      }
      else if (isTimeEvent == false) {
        if (routineTime?.length > 0 && selectedDate?.length > 0 && routineTime?.length == selectedDate?.length) {
          this.createDifferentTimeEventsScheduleWeb(currentRoutine, level, routineTime, selectedDate, isTimeEvent, routineLen, 0,isWeekendReopt?isWeekendReopt:null)
        }
      }
    }
  }
  
  getTimings(routineTime,selectedDay, routineDates) {
     let oldDayArray = this.appState.get('differentDays')
    let oldTimeArray = this.appState.get('differentTimes')

    if (oldDayArray && oldTimeArray) {
      this.dayNameArray = oldDayArray
      this.todayTimeArray = oldTimeArray
    }
     if(this.appState.get('prevdays') && this.appState.get('prevTimings')){
      let diffDays = this.appState.get('prevdays')
      diffDays.forEach(element => {
        this.dayNameArray.push(element)
      });
      this.todayTimeArray = this.appState.get('prevTimings')
     }
    if (!oldDayArray || !oldTimeArray) {
      this.appState.set('multipleDates', routineDates);
      this.todayTimeArray = routineTime
      this.appState.set('differentTimes', this.todayTimeArray)
      this.dayNameArray = selectedDay;
      this.appState.set('differentDays', this.dayNameArray)
    }

  }
  async setCalendarEventInWeb(currentRoutine:any,level,routineTime:any,selectedDate: any,selectedDay, isTimeEvent: boolean, isWeekendReopt?){
   this.currentPath= this.appState.get('currentPath')
    this.appState.set('isTimeEdited', false)
    var routineLen = 1

    if(!routineTime){
      this.toasterService.warning(this.i18n.t("Please select the time!"))
      return;
    }
    if(isTimeEvent){
      routineLen=7
    }

    if(!this.subRoutineAnswer){
      this.subRoutineAnswer=""
    }

    if (this.hasNativeCalendar) {
      this.getTimings(routineTime, selectedDay, selectedDate)
      if (isTimeEvent) {
      this.prepareRoutineCalendarParams("1st90 Habit: " + level?.title, this.subRoutineAnswer + " " + this.routinev2Service.innerHtmlToRoutinesData(level?.text), routineLen, !isTimeEvent?'Does not repeat':'Daily', routineTime, selectedDate)
      if (!isTimeEvent) {
        this.createDifferentSubRoutineEvent()
      } else {
        this.createSubRoutineEvent()
      }

      this.appState.set('isEventCreated', true)
      // this.getTimings()       
      this.makeRoutine(currentRoutine, level)
      } else if (isTimeEvent == false) {
        if (routineTime?.length > 0 && selectedDate?.length > 0 && routineTime?.length == selectedDate?.length) {
          this.createDifferentTimeEventsScheduleMobile(currentRoutine, level, routineTime, selectedDate, isTimeEvent, routineLen, 0)
        }
      }
    }else {
      this.getTimings(routineTime, selectedDay, selectedDate) 
      if (isTimeEvent) {
        this.appState.set('isEventCreated', true)
        this.prepareRoutineCalendarParams("1st90 Habit: " + level?.title, this.subRoutineAnswer + " " + this.routinev2Service.innerHtmlToRoutinesData(level?.text), routineLen, !isTimeEvent?'Does not repeat':'Daily', routineTime, selectedDate)
        this.calendarParams.notes = this.subRoutineAnswer + this.routinev2Service.innerHtmlToRoutinesData(level?.text)
        this.homev5Service.setCalendarData(this.calendarParams)
        const modal = await this.modalController.create({
          component: AlertWebCalendarComponent,
          componentProps: { isWeekendCalandar: { isWeekendCalandar: isWeekendReopt } },
          animated: this.isTabletDevice() ? true : false,
          cssClass: this.isMobile ? 'custom-modal-size' : 'web-action-calender-modal',
       
        });
        await modal.present()
        await modal.onDidDismiss().then(async () => {
          //this.showRepeatAlert(isTimeEvent)
          await this.makeRoutine(currentRoutine,level)
        });
        
      }
      else if (isTimeEvent == false) {
        if (routineTime?.length > 0 && selectedDate?.length > 0 && routineTime?.length == selectedDate?.length) {
          this.createDifferentTimeEventsScheduleWeb(currentRoutine, level, routineTime, selectedDate, isTimeEvent, routineLen,0, isWeekendReopt?isWeekendReopt:null)
        } 
      }
    }
  }
 async createDifferentTimeEventsScheduleMobile(currentRoutine:any,level,routineTime:any,selectedDate: any, isTimeEvent: boolean,routineLen,index) {
    // if (routineTime?.length > 0 && selectedDate?.length > 0 && routineTime?.length == selectedDate?.length) {
    //   routineTime.forEach(async (time, index) => {
    
        this.appState.set('isEventCreated', true)
         if (index>routineTime?.length)  {
          this.makeRoutine(currentRoutine, level)
          return
        }
         else {
           let dayTimeArray = selectedDate[index] + ' at ' + routineTime[index]
           let subRoutineAnswer = this.routinev2Service.createCommitEvent(currentRoutine, level, this.typeValue, this.activityCueTime, routineTime[index], selectedDate[index], this.commitType, this.makeCommitment, "on " + dayTimeArray)
          this.prepareRoutineCalendarParams("1st90 Habit: " + level?.title, subRoutineAnswer + " " + this.routinev2Service.innerHtmlToRoutinesData(level?.text), routineLen, !isTimeEvent?'Does not repeat':'Daily', routineTime[index], selectedDate[index])
          if (isTimeEvent) {
            this.createDifferentSubRoutineEvent()
          } else {
            this.createSubRoutineEvent()
          }
           if (index+1 < routineTime?.length) {
              this.showRepeatAlert(currentRoutine, level, routineTime, selectedDate, isTimeEvent, routineLen,index+1)
            }
            else if(index+1==routineTime?.length) {
              this.makeRoutine(currentRoutine, level)
            }
          this.appState.set('isEventCreated', true)
        }
    //   })
    // }
  }

  async createDifferentTimeEventsScheduleWeb(currentRoutine:any,level,routineTime:any,selectedDate: any, isTimeEvent: boolean,routineLen,index,isWeekendReopt?) {
    // routineTime.forEach(async (time, index) => {
        this.appState.set('isEventCreated', true)
        if (index>routineTime?.length) {
          this.makeRoutine(currentRoutine, level)
          return
        }
        else {
          let dayTimeArray = selectedDate[index] + ' at ' + routineTime[index]
           let subRoutineAnswer = this.routinev2Service.createCommitEvent(currentRoutine, level, this.typeValue, this.activityCueTime, routineTime[index], selectedDate[index], this.commitType, this.makeCommitment, "on " + dayTimeArray)
          this.prepareRoutineCalendarParams("1st90 Habit: " + level?.title, subRoutineAnswer + " " + this.routinev2Service.innerHtmlToRoutinesData(level?.text), routineLen, !isTimeEvent?'Does not repeat':'Daily', routineTime[index], selectedDate[index])
          this.calendarParams.notes = subRoutineAnswer + this.routinev2Service.innerHtmlToRoutinesData(level?.text)
          this.homev5Service.setCalendarData(this.calendarParams)
          const modal = await this.modalController.create({
            component: AlertWebCalendarComponent,
            componentProps: { isWeekendCalandar: { isWeekendCalandar: isWeekendReopt } },
            animated: this.isTabletDevice() ? true : false,
            cssClass: this.isMobile ? 'custom-modal-size' : 'web-action-calender-modal',
       
          });
           modal.present()
          modal.onDidDismiss().then(async () => {
            if (index+1 < routineTime?.length) {
              this.showRepeatAlert(currentRoutine, level, routineTime, selectedDate, isTimeEvent, routineLen,index+1, isWeekendReopt?isWeekendReopt:null)
            }
            else if(index+1==routineTime?.length) {
              this.makeRoutine(currentRoutine, level)
            }
          });
        }
    //   })
    // }
  }

  isTabletDevice(): boolean { 
    let isTablet = this.appState.get('isTabletDevice')
    return isTablet && (this.screenOrientation.type == 'landscape-primary' || this.screenOrientation.type == 'landscape' || this.screenOrientation.type == 'landscape-secondary' )
  }
  createSubRoutineEvent() {
    const success = function(message) {
      
      console.log('Success routine calendar: ' + JSON.stringify(message))
      
    }
    const error = function(message) { console.error('Error: ' + message) }
    this.recurrenceInterval='never'

    const w: any = window
    const calOptions = w.plugins.calendar.getCalendarOptions()
    const calendarList = w.plugins.calendar.listCalendars(success,error);
    
    const daysCount = 7  // LUMBA-1399
    calOptions.recurrence = this.recurrenceInterval.toLowerCase(); // supported are: daily, weekly, monthly, yearly
    calOptions.firstReminderMinutes = 0
    calOptions.recurrenceEndDate = moment(new Date(), 'HH:mm').add(daysCount, 'day').toDate()
    this.calendarService.scheduleEvents(
      this.calendarParams.title,
      this.calendarParams.eventLocation,
      this.calendarParams.notes,
      this.calendarParams.startDate,
       this.calendarParams.endDate,
       calOptions,
       success,
       error
      );
  }
  
  createDifferentSubRoutineEvent() {
    const success = function(message) {
      
      console.log('Success routine calendar: ' + JSON.stringify(message))
      
    }
    const error = function(message) { console.error('Error: ' + message) }
    //this.recurrenceInterval='never'

    const w: any = window
    const calOptions = w.plugins.calendar.getCalendarOptions()
    const calendarList = w.plugins.calendar.listCalendars(success,error);
    
    const daysCount = 7  // LUMBA-1399
    //calOptions.recurrence = this.recurrenceInterval.toLowerCase(); // supported are: daily, weekly, monthly, yearly
    calOptions.firstReminderMinutes = 0
    //calOptions.recurrenceEndDate = moment(new Date(), 'HH:mm').add(daysCount, 'day').toDate()
    // w.plugins.calendar.createEventInteractivelyWithOptions(
    //   this.calendarParams.title,
    //   this.calendarParams.eventLocation,
    //   this.calendarParams.notes,
    //   this.calendarParams.startDate,
    //   this.calendarParams.endDate,
    //   calOptions,
    //   success,
    //   error
    // )

    this.calendarService.scheduleEvents(
      this.calendarParams.title,
      this.calendarParams.eventLocation,
      this.calendarParams.notes,
      this.calendarParams.startDate,
       this.calendarParams.endDate,
       calOptions,
       success,
       error
      );
  }
  async earlierOptedNotsureTimesDays(subRoutineAnswer: any, routine, level, isSingleEvent) {
     this.isMobile = this.appState.get('isMobile');
   // this.resetLevelEnabledStatus(routine,level)
    this.getLocalRoutineData();
    this.currentPath= this.appState.get('currentPath')
    this.typeValue = level?.type_value;
    this.subRoutineAnswer =subRoutineAnswer
    this.commitType = level?.routine_type;
    this.makeCommitment=isSingleEvent ==null?'no':'yes'
    var routineTime = level?.routine_time
    var routineDate = level?.routine_date
    var routineDay = routineDate ? level?.day : []
     if (!routineTime) { routineTime = '' }
    if (!routineDay) { routineDay = '' }
    if (!this.commitType) { this.commitType = 'dayTime' }
    if (!this.typeValue) { this.typeValue = '' }
    if (!this.activityCueTime) { this.activityCueTime = '' }
    if (this.commitType === 'Activity' && !this.activityCueTime) { this.activityCueTime = 'before' }
    this.appState.set('commitData',`${this.makeCommitment},${this.commitType},${this.typeValue}`)
    this.appState.set('makeCommitment',this.makeCommitment)
    this.appState.set('routineTime',routineTime)
    this.appState.set('selectedDate',routineDate)
    this.activityCueTime = level?.activity_time

     const w: any = window
    this.hasNativeCalendar = this.cordovaService?.onCordova && w && w.plugins && w.plugins.calendar;
    const alert = await this.alertController.create({      
          header: this.i18n.t("My Commitment"),
          message: subRoutineAnswer,
          cssClass: 'alertBackdrop',
          buttons: [
            {
              text: this.i18n.t("Commit"),
              cssClass: 'custom-alert-commite',
              handler:(result)=>{
               
              },
             
            },
            
          ]  
         });
      
    await alert.present();
    alert.onWillDismiss().then((result) => {
      if (result?.role === 'backdrop') {
        if (this.appState.get('IsRepsAppendAfterReoptCommit') == true) {
              console.log("From commitment service")
              this.closingModalOnClickingBackground();
            }
          }
       this.makeRoutine(routine, level)
    })
  }

  closeUnlockModal() {
    this.modalController.dismiss();
  }

  appendRepsOnreoptLevel(routine, level) {
    if (this.appState.get('IsRepsAppendAfterReoptCommit') ==true) {
      let someArray = []
      let level_ids = [];
      routine.routine.forEach(element => {
        if (element.id == level.id) {
          someArray.push(element)
          level_ids.push(element.id)
        }
      });
      const repsRequestData = {
        user_id: routine.user_id,
        is_reset: true,
        level_id: JSON.stringify(level_ids),
        routine_info_id: routine.routine_info_id,
        path_id: routine?.path_id
      }
   
      if (repsRequestData.user_id == 0) {
        return true;
      } else {
        this.routinev2Service.appendRepsCount(repsRequestData).subscribe((res) => {
          console.log("response for reps", res);
              this.appState.set('IsRepsAppendAfterReoptCommit',null)
        })

        //  this.routinev2Service.appendOptedRoutineLevels(repsRequestData).subscribe(
        // (response) => {
        //   this.appState.set('levelUpdated', undefined)
        //  // this.cdRef.detectChanges();
        // },
        // (error) => {
        //   console.log('Error for edit routines', error)
        // })
      }
    }  
  }
  
  getSelectedLevels(routine,level){
    //return selected habit levels
    let unCheckedLevels = [];
    let selectedLevels = [];
    let currentPath = this.appState.get('currentPath');
     currentPath.routineInfo.forEach(currentRoutine => {
      if (currentRoutine.id === routine.routine_info_id) {
        currentRoutine.levels.forEach(routineLevel => {
            // const match = currentRoutine.levels.filter((level) => level.id === routineLevel.id)
          // if (level.id == routineLevel.id && (match.length === 0)) {
            const match = routine.routine.filter((level) => level.id === routineLevel.id)
            if ( match.length === 0) {
              routineLevel.enabled = false;
              unCheckedLevels.push({ 'id': routineLevel.id, 'index': currentRoutine.levels.indexOf(routineLevel) })
            }
            else {
              let index;
              if (routine?.routine?.length > 0) {
                index = routine?.routine?.findIndex(item => item?.id === routineLevel.id)
              }
              if (routine?.levels?.length > 0) {
                index = routine?.levels?.findIndex(item => item?.id === routineLevel.id)
              }
              if (index>=0) {
                routineLevel.enabled = true;
                selectedLevels.push({ 'id': routineLevel.id, 'index': currentRoutine.levels.indexOf(routineLevel) })
              }
              
            }

        });
      }
    
    });
    console.log("selectedLevelIds in commit service", selectedLevels);
    let levelsArray = []
    if (selectedLevels) {
      selectedLevels.forEach(element => {
        levelsArray.push(element.id)
      });
    }
    // levelsArray.sort(function (a, b) {
    //   return a - b;
    // });
    return levelsArray
  }
  convertToArray() {
    this.currentPath = this.appState.get('currentPath')
    let jsonObject = this.currentPath.colours
    let colorArray = []
    for (let element in jsonObject) {
      if (element === 'stat1' || element === 'stat2' || element === 'stat3' || element === 'stat4' || element === 'stat5') {
        colorArray.push({
          colorCode: jsonObject[element]
        });
      }
    }
    return colorArray
  }
  makeRoutine(routine: any, level: any) {
    //this.resetLevelEnabledStatus(routine,level)
    this.currentUser = this.appState.get('currentUser')
    this.colorArray = this.convertToArray()
    this.levelId = level.level;
    this.getLocalRoutineData();
    var routineTime = this.appState.get('routineTime')
    var routineDay = this.appState.get('selectedDate')
    if (!routineTime) { routineTime = '' }
    if (!routineDay) { routineDay = '' }
    if (!this.commitType) { this.commitType = 'dayTime' }
    if (!this.typeValue) { this.typeValue = '' }
    if (!this.activityCueTime) { this.activityCueTime = '' }
    if (this.commitType === 'Activity' && !this.activityCueTime) { this.activityCueTime = 'before' }
    // if (routineDay) {
    //   var today = (routineDay).getDay();
    //   var dayName = this.routinev2Service.getDayFromDate(today)
    // }
    var differentTimes = this.appState.get('differentTimes')
    var differentDays = this.appState.get('differentDays')
    if (differentTimes) {
      differentTimes.forEach(element => {
        const date = moment().format('YYYY/MM/DD')
        const time = element;
        const timeAndDate = moment(date + ' ' + time);
        timeAndDate.format('YYYY/MM/DD hh:mm A')
        let newUtcDate = moment(timeAndDate).utc().format('hh:mm A')
        this.selectedTimings.push(newUtcDate)
      });
      // differentTimes = this.routinev2Service.formatTimeForFrontEnd(differentTimes);
    }
    if (this.commitType === 'dayTime') { this.makeCommitment = 'yes' }

    if (differentDays?.length>0) {
      this.dayTimeArray = differentDays.map(function (x, i) {
        return x + ' at ' + differentTimes[i]
      })
      this.subRoutineAnswer = this.routinev2Service.createCommitEvent(routine, level, this.typeValue, this.activityCueTime, differentTimes, differentDays, this.commitType, this.makeCommitment, "on " + this.dayTimeArray)
    } else {
      if (this.makeCommitment === 'yes') {
        differentDays = ['Monday', ' Tuesday', ' Wednesday', ' Thursday', ' Friday', ' Saturday', ' Sunday'];
        this.dayTimeArray = "every day at " + differentTimes
        this.subRoutineAnswer = this.routinev2Service.createCommitEvent(routine, level, this.typeValue, this.activityCueTime, differentTimes, differentDays, this.commitType, this.makeCommitment, this.dayTimeArray)
      } else if (this.makeCommitment === 'no') {
        this.subRoutineAnswer = this.routinev2Service.createCommitEvent(routine, level, this.typeValue, this.activityCueTime, differentTimes, differentDays, this.commitType, this.makeCommitment, this.dayTimeArray)
      }
    }

    this.selectedDates = this.appState.get("multipleDates") 
    let convertedDates = []
    if (this.selectedDates) {
      this.selectedDates.forEach((element,index) => {
        let newUtcDate = moment(element) 
        if (moment().valueOf()>newUtcDate.valueOf()) {
          newUtcDate =this.reassignToCurrentWeek(moment(), newUtcDate)
        }
        convertedDates.push((newUtcDate.toDate()).toString())      
      });
    } 
    

    const random = Math.floor(Math.random() * this.colorArray.length);
    let routineColor = this.colorArray[random]
    let levelsArray = this.getSelectedLevels(routine,level)
    if (levelsArray && levelsArray?.length > 0) {
       levelsArray= [...new Set(levelsArray)]
    }
    else {
       levelsArray =[...new Set(routine?.level_ids)]
    }

    this.routinev2Service.commitRoutine(routine?.routine_info_id, routine?.path_id, this.currentUser.id, true, true, this.commitType,
      this.typeValue, level.id, true, this.selectedTimings, this.activityCueTime, differentDays, this.subRoutineAnswer,
      routineColor?.colorCode, routine?.schedule_day, true, convertedDates, levelsArray).subscribe(
        async (response) => {
          if (this.appState.get('IsRepsAppendAfterReoptCommit')) {
            this.appendRepsOnreoptLevel(routine, level)
          }
          this.appState.set('isLevelClosedBeforeCommit',null)
          this.appState.set('isScreenViewed', true)
          // this.appState.set('prevRoutineTime', this.appState.get('routineTime'))
          // this.appState.set('prevdays', this.appState.get('differentDays'))
          // this.appState.set('prevTimings', this.appState.get('differentTimes'))
          this.appConstantService.removeAppStateData()
          this.selectedTimings = []
          this.dayTimeArray = []
          localStorage.removeItem(
            `Toggle:Day:${routine?.schedule_day}:LevelNumber:${this.levelId + 1}:true`
          )

          var levelSaveData = {
            levelId: this.levelId,
            currentDay: routine?.schedule_day
          }
          this.appState.set('levelSaveData', levelSaveData)

          level.isCompleted = true
          this.appState.set('levelId', this.levelId)
          this.levelId = this.appState.get('levelId')
          this.getActions(this.currentUser.id, this.currentPath.id)
          if (!this.isMobile) {
            const modal = await this.modalController.create({
              component: AlertRoutineAnimComponent,
              componentProps: { level: level },
              backdropDismiss: false,
              cssClass: 'web-action-calender-modal'
            })
            await modal.present();
            modal.onDidDismiss().then((result) => {
              this.appConstantService.removeAppStateData()
              if (result) {
             
                const check = this.appState.get("isFromEdit");
                // this.router.navigate(['/main']);
                // this.modalController.dismiss();
                if (this.appState.get('isNotificationViewed') || this.appState.get('isNotificationViewed') == undefined) {
                  setTimeout(() => {
                    this.openCommitMentComponent(MyCommitmentsComponent, '', 'desktop-todo');
                  }, 600)
                }
                else {
                  this.setPendingRecommitNotifications(true)
                }
              }
            })
          }
          else {
             const dialogAnim = this.dialog.open(AlertRoutineAnimComponent, {
              panelClass:"confirm-commitment",
            data: {
              dataKey: { level: level }
            },
            disableClose: true,
          })
            dialogAnim.afterClosed().subscribe((result) => {
              this.appConstantService.removeAppStateData()
              if (this.appState.get('isNotificationViewed') || this.appState.get('isNotificationViewed') == undefined) {
                setTimeout(() => {
                  this.openCommitMentComponent(MyCommitmentsComponent);
                }, 600)
              }
              else {
                this.setPendingRecommitNotifications(true)
              }
            });
          }
          
          // this.appConstantService.removeAppStateData()
          this.appState.set('isRoutineEdited', false)
          this.appState.set('isLevelCommited',undefined);
        },
        (error) => {
          console.log('error', error)
          this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
        }
      )
  }

   getActions(userId: number, pathId: number) {
    const requestData = {
      'user_id': userId,
      'path_id': pathId
    }
    this.dailyStepsService.getActionsList(requestData).subscribe((response) => {
      this.appState.set('todoRoutines',this.homev5Service.removeDuplicates(response.data[0].routine, "routine_info_id"))
      this.appState.set('todoActions',response.data[0].action)

    }, (error) => {
      console.log('error :', error);
    })
   }
  resetLevelEnabledStatus(routine, level) {
    let unCheckedLevels = [];
    let selectedLevels = []
     let currentPath = this.appState.get('currentPath')
    currentPath.routineInfo.forEach(currentRoutine => {
      if (currentRoutine.id === routine.routine_info_id) {
        currentRoutine.levels.forEach(routineLevel => {
            const match = currentRoutine.levels.filter((level) => level.id === routineLevel.id)
            if (level.id == routineLevel.id && (match.length === 1)) {
               routineLevel.enabled = false;
              unCheckedLevels.push({ 'id': routineLevel.id, 'index': currentRoutine.levels.indexOf(routineLevel) })
            }
            else {
              let index;
              if (routine?.routine?.length > 0) {
                index= routine?.routine?.findIndex(item => item?.id === routineLevel.id)
              }
              if (routine?.levels?.length > 0) {
                index= routine?.levels?.findIndex(item => item?.id === routineLevel.id)
              }
              if (index) {
                routineLevel.enabled = true;
                selectedLevels.push({ 'id': routineLevel.id, 'index': currentRoutine.levels.indexOf(routineLevel) })
             }
              
            }
        });
      }
    
    });
      unCheckedLevels= unCheckedLevels.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));
    this.appState.set('unCheckedLevels', [...new Set(unCheckedLevels)])
    selectedLevels = [...new Set(selectedLevels)]
    this.appState.set('selectedLevelIds', selectedLevels)
    console.log('selected ad unselected levels',unCheckedLevels, selectedLevels)
  }

  async closingModalOnClickingBackgroundForUnLockedCommit() {
    if (this.appState.get('isLevelClosedBeforeCommit') == true) {
      this.appState.set('isLevelClosedBeforeCommit', null)
      this.appState.set('showUpdatedScreens', undefined)
      this.appState.set('levelUpdated', undefined)
      this.appState.set('fromTodoWidget', undefined)
      this.appState.set('levelEdit', undefined)
      this.appState.set('isTodoReps', undefined)
      this.appState.set('lastTodoScreenStep', undefined)
      setTimeout(() => {
        let openedRoutineInfo = this.appState.get('routinneLevelInfo')
        if (openedRoutineInfo?.routine && openedRoutineInfo?.level) {
           this.resetLevelEnabledStatus(openedRoutineInfo?.routine, openedRoutineInfo?.level)
        }
        this.appState.set('routinneLevelInfo', undefined)
      }, 650);
      let alert = await this.alertController.create({
       header:this.i18n.t('Commitment not confirmed'),
        subHeader: this.i18n.t('You can find and commit to this Habit in the Not Committed section of your To-Do List.'),
        buttons: [
          {
            cssClass:"alert-ok-btn",
            text: this.i18n.t('OK'),
            role: 'confirm',
            handler: () => {
              
            }
          }
        ]

      })
      await alert.present();
    }
  }
  async closingModalOnClickingBackground() {
    if (this.appState.get('IsRepsAppendAfterReoptCommit') == true) {
      this.appState.set('IsRepsAppendAfterReoptCommit', null)
      this.appState.set('showUpdatedScreens', undefined)
      this.appState.set('levelUpdated', undefined)
      this.appState.set('isTodoReps', undefined)
      this.appState.set('lastTodoScreenStep', undefined)
      this.appState.set('fromTodoWidget', undefined)
      this.appState.set('levelEdit', undefined)
      let alert = await this.alertController.create({
       header:this.i18n.t('Commitment not confirmed'),
        subHeader: this.i18n.t('You can find and re-commit to this Habit in the Completed section of your To-Do List.'),
        buttons: [
          {
            cssClass:"alert-ok-btn",
            text: this.i18n.t('OK'),
            role: 'confirm',
            handler: () => {
              
            }
          }
        ]

      })
      await alert.present();
    }
  }

  async openCommitMentComponent(comp?, compProps?,className?){
 
    if(comp) {
      const modal = await this.modalController.create({
        component: comp,
        componentProps: compProps,
        // animated: false,
        cssClass: className?className:'custom-modal',
        enterAnimation: this.animationService.slideInLeftEnterAnimation,
        leaveAnimation: this.animationService.SlideOutRightLeaveAnimation
      });
       await modal.present() 
      this.setModalStack(modal)
      modal.onDidDismiss().then(() => {
        if (this.appState.get('IsRepsAppendAfterReoptCommit') == true && comp != MyCommitmentsComponent) {
          this.closingModalOnClickingBackground();
        }
        if (this.appState.get('isLevelClosedBeforeCommit') == true && comp != MyCommitmentsComponent) {
          this.closingModalOnClickingBackgroundForUnLockedCommit();
       }
      })
    }
   
  }
  
  async dismissAllModals() {
    // Retrieve the list of active modals and dismiss them one by one
    let activeModals;
    activeModals= this.getModalStack()
    activeModals?.forEach(async (modal) => {
      await modal.dismiss();
    });
  }
   getModalStack() {
    return this.modalStack;
  }
  async dismissTopModal() {
    this.modalStack =this.getModalStack();
    if (this.modalStack.length > 0) {
      // Dismiss the top modal in the stack
      const modal = this.modalStack.pop();
      await modal.dismiss();
    }
  }
  setModalStack(modal){
    this.modalStack.push(modal);
  }
  get pendingRecommitNotifications() {
    return this.IsRecommitNotificationAvailable.asObservable();
  }
  setPendingRecommitNotifications(val) {
    this.IsRecommitNotificationAvailable.next(val);
  }
}
