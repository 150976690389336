import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Filter } from '../shared/filter'
import * as query from './reward.query'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'

import { PathReward } from '../path/path-reward.model'
import { Reward } from './reward.model'

@Injectable()
export class RewardService {
  constructor(private apollo: Apollo) { }

  // TODO: Create a PathRewardService
  getList(filter: Filter = null): Observable<PathReward[]> {
    return this.apollo
      .watchQuery<any>({ query: query.listPathRewards, variables: { filter: filter } })
      .valueChanges
      .pipe(
        map(response => {
          let list: PathReward[] = []
          response.data.pathRewards.forEach(element => { list.push(new PathReward(element)) })
          return list
        })
      )
  }

  getReward(id: number): Observable<Reward> {
    return this.apollo
      .watchQuery<any>({ query: query.findReward, variables: { id: id } })
      .valueChanges
      .pipe(
        map(response => {
          return new Reward(response.data.reward)
        })
      )
  }

  save(reward: Reward) {
    return this.apollo.mutate({
      mutation: query.updateReward,
      variables: {
        reward: reward.input()
      }
    })
  }
}
