import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ErrorhandlingService implements ErrorHandler {
    private setting = {
        element: {
            dynamicDownload: null as HTMLElement
        }
    }

    constructor(private router: Router, public datepipe: DatePipe) {

    }
    handleError(error: any): void {
        const Dt = new Date().toISOString();
        if (error instanceof HttpErrorResponse) {
            console.log(Dt, '\r\nHTTP error: ', error.message, '\r\nStatus code:', (<HttpErrorResponse>error).status);
        }
        else if (error instanceof TypeError) {
            console.log(Dt, '\r\nType Error: ', error.message);
        }
        else if (error instanceof Error) {
            console.log(Dt, '\r\nGeneral Error: ', error.message);
        }
        else {
            console.log(Dt, '\r\nAnonymous Error: ', error);
        }
        // let currentDateTime = this.datepipe.transform((new Date), 'MM/dd/yyyy h:mm:ss');
        // this.dyanmicDownloadByHtmlTag({
        //     fileName: currentDateTime + '-Ist90-Error',
        //     text: JSON.stringify(error.message)
        // });
        //this.router.navigate(["/errors"]);
    }
    private dyanmicDownloadByHtmlTag(arg: {
        fileName: string,
        text: string
    }) {
        if (!this.setting.element.dynamicDownload) {
            this.setting.element.dynamicDownload = document.createElement('a');
        }
        const element = this.setting.element.dynamicDownload;
        const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
        element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
        element.setAttribute('download', arg.fileName);

        var event = new MouseEvent("click");
        element.dispatchEvent(event);
    }
}