import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs'
import { environment } from "src/environments/environment";


@Injectable()
export class ReflectionService {

  API_URL: string = environment.apiBaseUrl
  reflection_answer = '/add_reflection_answer'

  constructor(
    private httpclient: HttpClient,) {
  }


  public saveReflectionAnswer(data): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Authorization', localStorage.getItem('token'));
    return this.httpclient.post(this.API_URL + this.reflection_answer, data,{headers})
  }

}