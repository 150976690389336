import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Filter } from '../shared/filter'
import * as query from './habit.query'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'

import { Habit } from './habit.model'
import { PathHabit } from '../path/path-habit.model'
import { AppState } from '../app.service'
import { WalletService } from '../wallet/wallet.service'
import { invalidateCache } from '../utils.service'
import { HomePageService } from '../pages/home/home-page.service'
import { HABIT_FRAGMENT, HABIT_SCHEDULELATER_FRAGMENT, HABIT_TOGGLE_FRAGMENT } from '../document/document.query'

export interface ScheduleHabit{
  [scheduleHabit: string]: any
}

@Injectable()
export class HabitService {

  public scheduleHabit: ScheduleHabit

  constructor(
    private apollo: Apollo,
    private appState: AppState,
    private homePageService: HomePageService,
    public walletService: WalletService) {
  }

  getPathList(filter: Filter = null): Observable<PathHabit[]> {
    return this.apollo
      .watchQuery<any>({query: query.listPathHabits, variables: {filter: filter}})
      .valueChanges
      .pipe(
        map(response => {
          const list: PathHabit[] = []
          response.data.pathHabits.forEach(element => {
            list.push(new PathHabit(element))
          })
          return list
        })
      )
  }

  getList(filter: Filter = null): Observable<Habit[]> {
    return this.apollo
      .watchQuery<any>({query: query.listHabits, variables: {filter: filter}})
      .valueChanges
      .pipe(
        map(response => {
          const list: Habit[] = []
          response.data.habits.forEach(element => {
            list.push(new Habit(element))
          })
          return list
        })
      )
  }

  // LUMBA-1419
  sendPdf() {
    const path = this.appState.get('currentPath')
    return this.apollo.mutate({
        mutation: query.sendHabitListPdf, variables: {pathId: path.id}
      }
    )
  }

  getHabit(id: number): Observable<Habit> {
    return this.apollo
      .watchQuery<any>({query: query.findHabit, variables: {id: id}})
      .valueChanges
      .pipe(
        map(response => {
          return new Habit(response.data.habit)
        })
      )
  }

  save(habit: Habit) {
    return this.apollo.mutate({
      mutation: query.updateHabit,
      variables: {
        habit: habit.input()
      }
    })
  }

  scheduleLater(habitId: number) {
    return this.apollo.mutate({
      mutation: query.scheduleHabit,
      variables: {
        habitId: habitId
      },
      optimisticResponse: {
        __typename: 'Mutation',
        scheduleHabit: {
          __typename: 'ScheduleHabitPayload',
          status: true,
        }
      },
      update: (proxy, {data: {scheduleHabit}}) => {
        this.homePageService.updateCompletions(proxy, `Habit:${habitId}`)
        proxy.writeFragment({
          id: `Habit:${habitId}`,
          fragment: HABIT_SCHEDULELATER_FRAGMENT,
          data: {scheduledLater: scheduleHabit.status}
        })
        if(scheduleHabit.status){
          this.appState.get('currentPath').update('habits', habitId, {scheduledLater: scheduleHabit.status,isCompleted: true})
        }else{
          this.appState.get('currentPath').update('habits', habitId, {scheduledLater: scheduleHabit.status})
        }
        invalidateCache(proxy, 'ROOT_QUERY.dailyParticipation')
      }
    }).pipe(
      map(response => {
        return response.data.scheduleHabit
      })
    )
  }

  confirm(habitId: number) {
    return this.apollo.mutate({
      mutation: query.confirmHabit,
      variables: {
        habitId: habitId
      },
      optimisticResponse: {
        __typename: 'Mutation',
        confirmHabit: {
          __typename: 'ConfirmHabitPayload',
          status: true,
          actionPoints: this.walletService.actionPoints.value,
        }
      },
      update: (proxy, {data: {confirmHabit}}) => {
        this.homePageService.updateCompletions(proxy, `Habit:${habitId}`)
        proxy.writeFragment({
          id: `Habit:${habitId}`,
          fragment: HABIT_FRAGMENT,
          data: {confirmation: confirmHabit.status}
        })
        if (confirmHabit.status === true) {
          this.appState.get('currentPath').update('habits', habitId, {confirmation: confirmHabit.status, scheduledLater: false,isCompleted: confirmHabit.status, time: new Date()})
        } else {
          this.appState.get('currentPath').update('habits', habitId, {confirmation: confirmHabit.status})
        }
        this.walletService.update(confirmHabit.actionPoints)
        invalidateCache(proxy, 'ROOT_QUERY.dailyParticipation')
      }
    }).pipe(
      map(response => {
        this.scheduleHabit = response.data
        return this.scheduleHabit.scheduleHabit
      })
    )
  }

  toggleHabit(habitId: number) {
    return this.apollo.mutate({
      mutation: query.toggleHabit,
      variables: {
        habitId: habitId
      },
      optimisticResponse: {
        __typename: 'Mutation',
        toggleHabit: {
          __typename: 'ToggleHabitPayload',
          status: true,
        }
      },
      update: (proxy, {data: {toggleHabit}}) => {
        this.homePageService.updateCompletions(proxy, `Habit:${habitId}`)
        proxy.writeFragment({
          id: `Habit:${habitId}`,
          fragment: HABIT_TOGGLE_FRAGMENT,
          data: {isCompleted: toggleHabit.status}
        })
        this.appState.get('currentPath').update('habits', habitId, {isCompleted: toggleHabit.status})
        console.log("Habit data",this.appState.get('currentPath'));

        invalidateCache(proxy, 'ROOT_QUERY.dailyParticipation')
      }
    }).pipe(
      map(response => {
        return response.data.toggleHabit
      })
    )
  }
}
