import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { expandOnEnterAnimation, slideInDownAnimation } from 'angular-animations';
import mixpanel from 'mixpanel-browser';
import { AppState } from 'src/app/app.service';
import { CordovaService } from 'src/app/cordova.service';
import { I18n } from 'src/app/i18n.service';
import { Path } from 'src/app/path/path.model';
import { Poll, PollStats } from 'src/app/poll/poll.model';
import { PollService } from 'src/app/poll/poll.service';
import { PopoverComponent } from 'src/app/shared/popover/popover.component';
import { SpinnerService } from 'src/app/spinner.service';
import { SurveyService } from '../survey.service';
import { HomeUiService } from '../../new-design/home-ui.service';

@Component({
  selector: 'app-survey-mcq',
  templateUrl: './survey-mcq.component.html',
  animations:[
    expandOnEnterAnimation({ duration: 400 }),
    slideInDownAnimation({duration:300})
  ],
  styleUrls: ['./survey-mcq.component.scss'],
})
export class SurveyMcqComponent implements OnInit {
  @Input() parentComponentName: string;
  @Input() poll: Poll
  @Input() isPreview: boolean
  @Input() inverted = false
  @Output() blurCard= new EventEmitter<any>();
  managerEmail=null;
  // Isdispaly:boolean=true;

  enabled = true // TODO: Get it from the path
  nameTQ: string // TODO: Get it from the path
  nameHYPA: string // TODO: Get it from the path
  path: Path
  stats: PollStats
  status: string
  chart = []
  loading: boolean = false;
  isMobileWebIos = false;
  isMobileWeb=false;
  statsPer: number[]
  isMobile: boolean
 @Input() enable= false;
  constructor(
    private pollService: PollService,
    private appState: AppState,
    private spinnerService: SpinnerService,
    private popover:PopoverController,
    private cordovaService: CordovaService,
    private surveyService: SurveyService,
    private cdRef: ChangeDetectorRef, // LUMBA-1308
    private i18n: I18n,
    public homeUIService: HomeUiService,
  ) {
    this.path = this.appState.get('currentPath')
    this.isMobile = this.appState.get('isMobile')
    this.isEnabled()
    this.initNames()
  }

  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    console.log("this.isMobileWeb value", this.isMobileWeb);
    this.loading = false;
    if (this.poll && this.poll.isCompleted) {
      this.stats = this.poll.answer.stats
    } 
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  ngAfterViewInit() {
    if (this.poll.isCompleted) this.setStats()
  }
enableBlur(val) {
  this.enable=val;
 // if(this.enable)
    this.blurCard?.emit({
      enable: this.enable
    })
    
}
  ngOnChanges(changes: SimpleChanges) {
    if (!changes['poll'] || changes['poll'].firstChange) { return false }
    if (this.poll && this.poll.isCompleted) {
      this.stats = this.poll.answer.stats
      window.requestAnimationFrame(() => {
        this.setStats()
      })
    }
  }

  onOptionClicked(option) {
    mixpanel.track('Step' + JSON.stringify(this.poll.startDay), {'question': this.poll.title, 'selectedOption': option.text})
    if (this.poll.isCompleted) {  return false }
    if (this.isPreview) { return false }
    this.loading = true
    this.poll.isCompleted = true;
    this.enable = true;
    this.managerEmail = this.appState.get("managerEmail");
    let currentDay = this.appState.get("currentScheduleDay");
    option.isSelected = true
    this.cdRef.detectChanges()
    this.surveyService.saveAnswers(option, null,null,this.poll?.id).subscribe(
      (response) => { 
        let data = response.data;
      if(response) {  
       this.stats = data['answerSurvey'].answer?.stats
       this.setStats()
      this.loading = false
      setTimeout(()=>{
      this.enableBlur(this.enable);
      },500);
      this.cdRef.detectChanges()
    }
    }, error => {
      option.isSelected = false
      this.loading = false
      this.cdRef.detectChanges() // LUMBA-1308
    })  

  }

  setStats() {
    if (!this.stats) {
      return
    }
    if (!this.stats.data) {
      return
    }
    if (!this.stats.data.length) {
      return
    }

    const total = this.stats.data.reduce((sum, n) => sum + n)
    if (!total) {
      return
    }

    this.statsPer = this.stats.data.map(n => Math.round(n * 100 / total))
  }

  private isEnabled() {
    this.enabled = this.path.enabledAttributes['polls']
  }

  private initNames() {
    this.nameTQ = this.path.getCustomText('p_tq')
    this.nameHYPA = this.path.getCustomText('p_hypa')
  }
  async presentPopover(ev) {
    const dynamicText = this.i18n.t('These charts show the breakdown of answers among all your colleagues in this path.');
    const popover = await this.popover.create({
      component: PopoverComponent,
      translucent: true,
      event: ev,
      mode:'ios',
      showBackdrop: true,
      cssClass: 'custom-popover',
      componentProps: {
        dynamicText: dynamicText,
      },
    });
    await popover.present();
    await popover.onDidDismiss();
  }

  @HostListener('click', ['$event'])
  onLinkClick(e) {
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        console.log('cordova click')
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        window.open(e.target.href, '_blank')
      }
    }
  }

}
