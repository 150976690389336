import {
  Component,
  Input,
  ViewChild,
  OnInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  ViewChildren,
  QueryList,
  HostListener
} from '@angular/core'
import { Question } from '../question/question.model'
import { Reply } from '../reply/reply.model'
import { QuestionService } from '../question/question.service'
import { ReplyService } from '../reply/reply.service'
import { ReplyComponent } from '../reply/reply.component'
import { AttachmentUploaderComponent } from '../shared/attachment-uploader/attachment-uploader.component'
import { ToastrService } from 'ngx-toastr'

import { TranslationService } from '../translation/translation.service'
import { AppState } from '../app.service'
import { Path } from '../path/path.model'
import { Router } from '@angular/router'
import { Apollo } from 'apollo-angular'
import { CordovaService } from '../cordova.service'
import { I18n } from '../i18n.service'
import { SessionService } from '../session/session.service'
import { MobileChatServiceService } from '../pages/mobile-chat-board/mobile-chat-service.service'
import { RoutineV2Service } from '../routine-v2/routine-v2.service'
import { SpinnerService } from '../spinner.service'
import { AnalyticsServiceService } from '../services/analytics-service.service'
//import { error } from 'util'

@Component({
  selector: 'app-full-screen-post',
  templateUrl: './full-screen-post.component.html',
  styleUrls: ['./full-screen-post.component.scss']
})
export class FullScreenPostComponent implements OnInit {

  question: any
  @Input() mode: string = 'question'
  @Input() isPreview: boolean
  @Input() inverted: boolean = false
  @Input() small = false

  @ViewChild('uploader') uploader: AttachmentUploaderComponent
  @ViewChild('replyBlock') replyBlock: ElementRef
  @ViewChild('repliesContainer') repliesContainer: ElementRef
  @ViewChild("txtareaPost") txtareaPost:ElementRef


  path: Path
  ishideComment:boolean = false;
  sortBy: string = 'timestamp'
  replyText: string = ''
  cachedReplyText: string = ''  // LUMBA-1383
  attachmentUrl: string = null
  errors: { answerError: false }
  replyTo: Reply = null
  replyToComponent: ReplyComponent
  replyToId: number
  isLoading: boolean = false
  replyOffset: number
  currentReply:any
  filterCurrentReplyArray:any
  filterCurrentReply: any
  chatBoardDetails: any
  public replies: Reply[]
  public repliesTree: Reply[]

  state: boolean = false;
  selected: boolean = false
  voteLoading: boolean = false
  user: any
  options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  todaysDate: Date
  tagName_id: number
  likes: number = 0

  constructor(
    private i18n: I18n,
    private apollo: Apollo,
    private router: Router,
    private appState: AppState,
    private toastrService: ToastrService,
    private questionService: QuestionService,
    private replyService: ReplyService,
    private translationService: TranslationService,
    private cordovaService: CordovaService,
    public sessionService: SessionService,
    private routineV2Service: RoutineV2Service,
    private cdRef: ChangeDetectorRef ,
    private toasterService: ToastrService,
    private analyticsService: AnalyticsServiceService,
    private mobileService: MobileChatServiceService
  ) {
    this.path = this.appState.get('currentPath')
    this.question = this.appState.get('currentChatBoard')
    this.user = this.appState.get('currentUser')
    this.currentReply = this.appState.get('singleFeed')
    this.filterCurrentReplyArray = this.filterReply(this.currentReply.id)
    this.filterCurrentReply = this.filterCurrentReplyArray[0]
    console.log("post",this.filterCurrentReply);
    
    this.todaysDate = new Date()

  }

  ngOnInit() {
    // LUMBA-1317
    this.toggleSort('timestamp')
    window.scrollTo(0,0)
    this.analyticsService.setScreen(AnalyticsServiceService.FULLPOST)

  }

  ngAfterViewInit() {
    this.getChatBoardDetails()
    // this line moves the screen at the bottom scroll
   this.replyOffset = this.replyBlock.nativeElement.offsetTop
   this.focusComment()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.question || changes.question.firstChange) {
      return false
    }
    this.question.sortRepliesBy(this.sortBy)
    this.cdRef.detectChanges()
  }

  toggleSort(by: string) {
    console.log("by:" + by);
    this.sortBy = by
    this.question.sortRepliesBy(by)
  }

  hideAndShowComment(){

    if(this.ishideComment){
      this.ishideComment=false
    }else{
      this.ishideComment=true
    }

  }

  reply() {

    if(this.replyText.length==0){
      this.toastrService.error(this.i18n.t('Please enter a comment'), this.i18n.t('Error'))
      return false;
    }

    // if (!this.replyText || this.replyText.length < 3) {
    //   this.toastrService.error(this.i18n.t('Comment is too short'), this.i18n.t('Error'))
    //   return false
    // }

 
    var list_of_tags_ids = []

    list_of_tags_ids = this.sessionService.getUserTAGs(this.replyText,this.complexItems)
    
    console.log("Array of tags ",list_of_tags_ids);
    
    if (this.isPreview) {
      return false
    }
    if (this.isLoading) {
      return false
    }
    this.isLoading = true

    this.unloadReply()
    if (this.replyTo) {      
      this.replyService
        .reply(this.question, this.replyTo.id, this.cachedReplyText, this.attachmentUrl,list_of_tags_ids, this.replyTo.id, this.replyTo.type)
        .subscribe(reply => {
          this.ishideComment = true;
            this.reset()
            this.isLoading = false
            this.toastrService.success(this.i18n.t('Comment posted!'))
            this.loadReply()
            if(this.repliesContainer!= undefined){
              this.repliesContainer.nativeElement.scrollIntoView()
              this.repliesContainer.nativeElement.scrollTop = 0
            }
            this.getChatBoardDetails()
            this.cdRef.detectChanges()
            

          },
          error => {
            this.loadReply()
            this.toastrService.error(this.i18n.t('Comment not posted. Check your connection and try reloading the app.'), this.i18n.t('Error'))
            this.isLoading = false
            this.cdRef.detectChanges()
          })
    } else {
      this.questionService
        .replyQuestion(this.question, this.cachedReplyText, this.attachmentUrl, list_of_tags_ids, this.mode)
        .subscribe(reply => {
          this.appState.set('currentActionPoints', reply?.data?.replyQuestion?.actionPoints)
          this.ishideComment = true;
          this.reset()
          this.isLoading = false
          this.loadReply()
          this.getChatBoardDetails()
          this.toastrService.success(this.i18n.t('Comment posted!'))
          if(this.repliesContainer!= undefined){
            this.repliesContainer.nativeElement.scrollIntoView()
            this.repliesContainer.nativeElement.scrollTop = 0
          }
          this.cdRef.detectChanges()
          

        }, error => {
          console.log(error)
          this.loadReply()
          this.toastrService.error(this.i18n.t('Comment not posted. Check your connection and try reloading the app.'), this.i18n.t('Error'))
          this.isLoading = false
          this.cdRef.detectChanges()
        })
    }
  }

  translate() {
    const path: Path = this.appState.get('currentPath')
    const repliesIds = this.question.replies.map(reply => reply.id)
    this.translationService.loadRepliesTranslations(repliesIds, path.language).subscribe(
      (translations) => {
        this.question.replies.forEach(reply => {
          const key = `Reply:${reply.id}`
          reply.text = translations[key].text
        })
      }
    )
  }

  reset() {
    this.replyText = ''
    this.attachmentUrl = null
    if (this.uploader) {
      this.uploader.reset()
    }
    this.cachedReplyText = ''
  }

  // LUMBA-1383
  loadReply() {
    if (this.cachedReplyText) {
      this.replyText = this.cachedReplyText
      this.cachedReplyText = ''
    }
  }

  // LUMBA-1383
  unloadReply() {
    this.cachedReplyText = this.replyText
    this.replyText = ''
  }

  setReplyTo(replyComponent: ReplyComponent) {
    if (this.uploader) {
      this.uploader.reset()
    }
    // This code was needed when all replies was visible
    // Now all replies hidden except selected for reply
    if (this.replyToComponent) {
      this.replyToComponent.selected = false
    }
    this.replyToComponent = replyComponent
    if (replyComponent) {
      this.replyTo = replyComponent.reply
      this.replyToId = this.replyTo.id
    } else {
      this.replyTo = null
      this.replyToId = null
    }
  }

  refresh() {
    console.log(this.router.url)
    this.apollo.getClient().clearStore()
    this.appState.refreshMainComponent.emit()
  }

  scrollTo(event) {
    if (this.cordovaService.onCordova) {
      console.log('click event')
      console.log(this.replyBlock.nativeElement.offsetTop)

      setTimeout(() => {
        window.scrollTo({top: this.replyBlock.nativeElement.offsetTop - 160, behavior: 'smooth'})
      }, 10)
    }
  }

  close() {
    this.router.navigate(['/main/questions']);
  }

  totalLikes(items){
    return items.reduce((a, b) => +a + +b.votesCount, 0);
   }

   filterReply(reply_id){
    var replyFilterArray = this.question.repliesTree.filter(item => item.id == reply_id)
    return replyFilterArray
   }




  toggleVote(reply) {

    console.log('hasVoted', reply.hasVoted);
    
    if (reply.user_id === this.user.id) {
      return false
    }

    this.bounceAnimation()

    this.voteLoading = true

    if(reply.hasVoted==false){
      reply.hasVoted = true
      reply.votesCount++
      this.likes = reply.votesCount
    }
    else{
     reply.hasVoted = false
     reply.votesCount-- 
     this.likes = reply.votesCount
    }

    this.cdRef.detectChanges()
   
    this.replyService.voteReply(reply,this.filterCurrentReply.type).subscribe(
      status => {
        this.voteLoading = false
        this.cdRef.detectChanges()
      },
      error => {},
      () => {
        this.voteLoading = false
        this.cdRef.detectChanges()
      }
    )
    // this.getChatBoardDetails()
  }

  bounceAnimation(){
    this.state = false;
    setTimeout(() => {
      this.state = true;
      console.log('this.state', this.state);
    }, 1);
  }

  complexItems = this.appState.get("userlist")

  namesData : any[] = new Array()  
  tempValue = 0
  index = 0
  
  format(item:any) {

    var data = item['id']
      setTimeout(() => {
        SessionService.setData(data)
      }, 100);
    return item['username'].toUpperCase()
  }

  mentionConfig:any = {
    mentions: [
      {
        items: this.complexItems,
        labelKey: 'username',
        mentionSelect: this.format,
        dropUp:true
      }
    ]
  };


  focusComment(){
    if(this.appState.get('commentClicked')){
      this.appState.set('commentClicked',false)
    setTimeout(()=>{
      this.txtareaPost.nativeElement.focus();
    },0);  
  }
  }

  viewImage(imageUrl){
    if (this.cordovaService.onCordova) {
      console.log("cordova click");
      this.cordovaService.openLinkInBrowser(imageUrl);
    } else {
      window.open(imageUrl, "_blank");
    }
  }

  deletePost(id){

    this.mobileService.deletePost(id).subscribe(
      response => {
          console.log(response);
          this.toasterService.success(this.i18n.t('Post deleted successfully'));
          this.router.navigate(['/main/questions'])
      },
      error => {
        console.log(error);
      }

    )

  }


  copyMessage(val: string,comment_id: number){
    try {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.toasterService.success(this.i18n.t("Copied to clipboard!"));
      this.deletePost(comment_id)
    }catch{
      this.toasterService.warning(this.i18n.t("Not able to copy and delete!"));
    }
  }


  @HostListener('click', ['$event'])
  onLinkClick(e) {    
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        console.log('cordova click')
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        console.log('not a cordova click')
        window.open(e.target.href, '_blank')
      }
    }
  }

  getChatBoardDetails(){
    if (!this.filterCurrentReply) {
      // this.spinnerService.off()
      return
    }

    try{
      const data = {
        user_id : this.user.id,
        id : this.filterCurrentReply.id,
        path_id : this.path.id,
        mode : this.filterCurrentReply.type
      }

      this.routineV2Service.getChatBoardDetails(data).subscribe(
        (response) => { 
        
          this.chatBoardDetails = response.data
          this.likes =  this.chatBoardDetails.votesCount
          
          var level1 = response.data.replies
          console.log('level1',level1)
          if (level1) {
            var subReply = level1.filter(item => item.repliableId)
            console.log('subReply',subReply)
              
            level1.forEach(reply => {
              subReply.forEach(element => {
                if (element.repliableId === reply.id) {
                  reply.replies.push(element)
                }
                reply.timestamp = new Date(reply.createdAt)
              });
            }); 
  
            this.repliesTree = level1.filter(reply=> reply.id && reply.level === 2)
  
            console.log('repliesTree',this.repliesTree)
            this.appState.set('repliesLength', this.repliesTree.length) 
        }

        // this.spinnerService.off()
       
      },

       (error) => {
       // console.log('error',error)
        this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
        // this.spinnerService.off()
      }
      )
    }catch{
     // console.log('error',error)
      this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
      // this.spinnerService.off()
    }

  }

  translatePost(language: string,text: any){

    let data = {
      language: language,
      text: text
    }

    this.mobileService.translateContent(data).subscribe(
      (response) => {
        console.log('response :', response);
        if(response.status){
          this.replyText = response.data.text
          this.cdRef.detectChanges()
          this.toastrService.success('Post translated successfully!')
        }
      },
    (error) => {
      this.toastrService.error('Text translation failed!')
      console.log("TranslationService data ",error);
    }
    )
  }

}
