import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'first90-screen-progress',
  templateUrl: './screen-progress.component.html',
  styleUrls: ['./screen-progress.component.scss'],
})
export class ScreenProgressComponent implements OnInit {

  @Input() progressItems = []
  @Input() currentIndex = 0
  @Input() themed = false
  @Input() inverted = false

  constructor() { }

  ngOnInit() {}

}
