import { Base } from "../shared/base"

export class Reward extends Base {
  init() {
    this._fields = ['id', 'name', 'description', 'imageUrl', 'actionPoints', 'isEligible', 'enabled']
  }

  public id: number
  public name: string
  public description: string
  public imageUrl: string
  public actionPoints: number
  public isEligible: boolean
  public enabled: boolean
}
