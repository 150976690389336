import { Injectable } from '@angular/core';
import { AnimationController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AnimationsService {

  constructor(private animationCtrl: AnimationController) { }
  
  // slideInLeft and slideoutRight animation

  slideInLeftEnterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector('ion-backdrop')!)
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector('.modal-wrapper')!)
      .keyframes([
        { offset: 0, opacity: '0', transform: 'translateX(100%)' },
        { offset: 1, opacity: '0.99', transform: 'translateX(0)' },
      ]);

    return this.animationCtrl
      .create()
      .addElement(baseEl)
      .easing('ease-out')  
      .duration(500)
      .addAnimation([backdropAnimation,wrapperAnimation]);
  };

  SlideOutRightLeaveAnimation = (baseEl: HTMLElement) => {
    return this.slideInLeftEnterAnimation(baseEl).direction('reverse');
  };

    //slideoutRight and slideInLeft animation

  slideoutRightEnterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector('ion-backdrop')!)
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector('.modal-wrapper')!)
      .keyframes([
        { offset: 0, opacity: '0', transform: 'translateX(-100%)' },
        { offset: 1, opacity: '0.99', transform: 'translateX(0)' },
      ]);

    return this.animationCtrl
      .create()
      .addElement(baseEl)
      .easing('ease-out')  
      .duration(500)
      .addAnimation([backdropAnimation,wrapperAnimation]);
  };

  SlideInLeftLeaveAnimation = (baseEl: HTMLElement) => {
    return this.slideoutRightEnterAnimation(baseEl).direction('reverse');
  };

  //SlideINDOWN and SlideOUTUP animation
  slideInDownEnterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector('ion-backdrop')!)
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector('.modal-wrapper')!)
      .keyframes([
        { offset: 0, opacity: '0', transform: 'translateY(-100%)' },
        { offset: 1, opacity: '0.99', transform: 'translateY(0)' },
      ]);

    return this.animationCtrl
      .create()
      .addElement(baseEl)
      .easing('ease-out')  
      .duration(500)
      .addAnimation([backdropAnimation,wrapperAnimation]);
  };

  slideOutUpLeaveAnimation = (baseEl: HTMLElement) => {
    return this.slideInDownEnterAnimation(baseEl).direction('reverse');
  };

  //SlideINUP and SlideOUTDown animation

  slideInUpEnterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector('ion-backdrop')!)
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector('.modal-wrapper')!)
      .keyframes([
        { offset: 0, opacity: '0', transform: 'translateY(100%)' },
        { offset: 1, opacity: '0.99', transform: 'translateY(0%)' },
      ]);

    return this.animationCtrl
      .create()
      .addElement(baseEl)
      .easing('ease')  
      .duration(400)
      .addAnimation([backdropAnimation,wrapperAnimation]);
  };

  slideOutDownLeaveAnimation= (baseEl: HTMLElement) => {
    return this.slideInUpEnterAnimation(baseEl).direction('reverse');
  };

  slideLeftTopEnterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector('ion-backdrop')!)
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector('.modal-wrapper')!)
      .keyframes([
        { offset: 0, opacity: '0', transform: 'translate(100px, 100px)'},
        { offset: 1, opacity: '0.99', transform: 'translate(0px, 0px)'},
      ]);

    return this.animationCtrl
      .create()
      .addElement(baseEl)
      .easing('ease-out')  
      .duration(500)
      .addAnimation([backdropAnimation,wrapperAnimation]);
  };
  slideLeftTopLeaveAnimation = (baseEl: HTMLElement) => {
    return this.slideLeftTopEnterAnimation(baseEl).direction('reverse');
  };
}
