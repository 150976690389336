import { Component, Input, } from '@angular/core';
import { Question } from '../question/question.model';
import { AppState } from '../app.service';
import { Path } from '../path/path.model';

@Component({
  selector: 'first90-chat-board',
  templateUrl: './chat-board.component.html',
  styleUrls: ['./chat-board.component.scss']
})
export class ChatBoardComponent {

  path: Path
  nameCB: string
  nameCBST: string = 'Post ideas, learnings or begin a discussion with your peers. You can also share articles, links, videos that help others along the path.'
  sortBy: string = 'recent'
  text: string
  isCreating: boolean = false
  comments: any[] = [] // FIXME: This are ChatBoardComments
  chatBoard: Question

  constructor(private appState: AppState) {
    this.path = this.appState.get('currentPath')
    this.chatBoard = this.appState.get('currentChatBoard')
    this.chatBoard.repliesEnabled = true
    this.chatBoard.text = this.path.getCustomText('q_cb_st') || this.nameCBST
  }

  toggleSort(by) {
    this.sortBy = by
    // TODO: Sort the collection
  }
}
