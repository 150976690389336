import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import mixpanel from 'mixpanel-browser';
import { AlertHabitLoopInfoComponent } from '../alert-habit-loop-info/alert-habit-loop-info.component';
import { AppState } from '../app.service';

@Component({
  selector: 'app-alert-edit-note',
  templateUrl: './alert-edit-note.component.html',
  styleUrls: ['./alert-edit-note.component.scss'],
})
export class AlertEditNoteComponent implements OnInit {

  note: string

  constructor(public dialogRef: MatDialogRef<AlertEditNoteComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private appState: AppState) {
    let note = this.appState.get('singleDocNote')
    if (note) {
      var b = JSON.stringify(note);
      let testNote = b.replace(/\\/g, '');
      let testNote1 = testNote.replace(/[\[\]']+/g, '')
      this.note = testNote1.replace(/"/g, '');
    }
    

  }

  ngOnInit() { }

  onSubmit() {
    mixpanel.track("Library-notes added", {'notes': this.note})
    localStorage.setItem('journalNote', this.note);
  }

  close() {
    this.dialogRef.close();
  }

}
