import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from '../app.service';
import { RoutineService } from '../daily-content-v2/routine.service';
import { Path } from '../path/path.model';
import { Filter } from '../shared/filter';
import { User } from '../user/user.model';

@Component({
  selector: 'app-alert-end-routine',
  templateUrl: './alert-end-routine.component.html',
  styleUrls: ['./alert-end-routine.component.scss']
})
export class AlertEndRoutineComponent implements OnInit {

 

    ngOnInit() {

    }


   

}
