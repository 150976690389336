import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import gql from 'graphql-tag'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import * as query from './translation.query'

export interface ReplyTranslation {
  id: number
  text?: string
}

@Injectable()
export class TranslationService {

  constructor(
    private apollo: Apollo
  ) { }

  loadRepliesTranslations(repliesIds: number[], lang: string = 'en'): Observable<{[id: string]: ReplyTranslation}> {
    return this.apollo
      .watchQuery<any>({ query: query.repliesTranslations, variables: { lang: lang, repliesIds: repliesIds } })
      .valueChanges
      .pipe(
        map(response => {
          let list: any = {}
          response.data.repliesTranslations.forEach(element => { list[`Reply:${element.replyId}`] = element })
          return list
        })
      )
  }
}
