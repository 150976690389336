import gql from 'graphql-tag';

export const routines = gql`
mutation routines($habitId: Int!, $pathId: Int!, $userId: Int!) {
    routines(habitId: $habitId, pathId: $pathId, userId: $userId) {
        status
        routine
        {
          createdAt
          id
          path
          {
            id
          }
          habit
          {
            id
          }
          routineRepLogs
          {
            id
          }
          text
          title
          updatedAt
        }
  }
}
`

export const listRoutines = gql`
query routines($filter: FilterInput) {
  routines(filter: $filter) {
    id
    title
    updatedAt
    routineRepLogs{
      createdAt
      id
    }
    completedRoutine
  }
}
`

export const endRoutine = gql`
mutation confirmRoutine($id: Int!, $pathId: Int!, $userId: Int!,$habitId: Int!) {
  confirmRoutine(id: $id, pathId: $pathId, userId: $userId, habitId:$habitId) {
      status
    }
}
`