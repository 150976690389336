import { Base } from "../shared/base"

export class SurveyOption extends Base {
  init() {
    this._fields = ['id', 'text', 'enabled']
  }

  public id: number  
  public text: string  
  public enabled: boolean
}
