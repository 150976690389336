import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Filter } from '../shared/filter'
import * as query from './path-group.query'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { PathGroup } from './path-group.model'

@Injectable()
export class PathGroupService {
  constructor(private apollo: Apollo) { }

  getList(filter: Filter = null): Observable<PathGroup[]> {
    return this.apollo
      .watchQuery<any>({ query: query.listPathsGroups, variables: { filter: filter } })
      .valueChanges
      .pipe(
        map(response => {
          const list: PathGroup[] = []
          response.data.pathGroups.forEach(element => { list.push(new PathGroup(element)) })
          return list
        })
      )
  }

  getPathGroup(id: number): Observable<PathGroup> {
    return this.apollo
      .watchQuery<any>({ query: query.findPathGroup, variables: { id: id } })
      .valueChanges
      .pipe(
        map(response => {
          return new PathGroup(response.data.pathGroup)
        })
      )
  }

  getPathGroupByName(name: string): Observable<PathGroup> {
    return this.apollo
      .watchQuery<any>({ query: query.findPathGroupByName, variables: { name: name } })
      .valueChanges
      .pipe(
        map(response => {
          return new PathGroup(response.data.pathGroup)
        })
      )
  }

  // LUMBA-982
  setActivePath(activePathId: number, userId: number) {
    return this.apollo.mutate({
      mutation: query.setActivePath,
      variables: {activePathId: activePathId, userId: userId}
    })
  }
}
