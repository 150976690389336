import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { FirebaseX } from "@awesome-cordova-plugins/firebase-x/ngx";
import { getAnalytics, logEvent, setCurrentScreen } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AnalyticsServiceService {
  firebaseConfig = environment.firebaseConfig;

  analytics = null;
  isMobile: boolean;
  static CLICK_EVENT = "Button clicks event";
  static LOGIN = "Login";
  static SIGNUP = "Signup";
  static FAQ = "FAQ";
  static WELDONE = "Weldone";
  static HABIT = "Action";
  static COMMUNITY = "Community";
  static FULLPOST = "FullscreenPost";
  static FULLFEED = "Fullscreen Feed";
  static FULLROUTINE: "Fullscreen routine";
  static JOURNAL = "Journal";

  constructor(private platform: Platform, private firebasex: FirebaseX) {
   
  }

  initFirebaseAnalytics() {
    this.platform.ready().then(() => {
      if (this.platform.is("ios") || this.platform.is("android")) {
        this.firebasex.setAnalyticsCollectionEnabled(true);
      } else {
        initializeApp(this.firebaseConfig);
        this.analytics = getAnalytics();
      }
    });
  }

  setScreen(screenName: string) {
    if (this.platform.is("ios") || this.platform.is("android")) {
      this.platform.ready().then(() => {
        this.firebasex.setScreenName(screenName);
      });
    } else {
      let fbanalytics = this.analytics
        ? this.analytics
        : this.initFirebaseAnalytics();
      setCurrentScreen(fbanalytics, screenName, {
        global: false,
      });
    }
  }

  setLogEvent(
    eventName: string,
    contentType: string = "",
    contentId: string = "",
    items: any[] = []
  ) {
    if (this.platform.is("ios") || this.platform.is("android")) {
      this.platform.ready().then(() => {
        this.firebasex.logEvent(AnalyticsServiceService.CLICK_EVENT, {
          eventName: eventName,
          contentType: contentType,
          contentId: contentId,
          items: items,
        });
      });
    } else {
      let fbanalytics = this.analytics
        ? this.analytics
        : this.initFirebaseAnalytics();

      logEvent(fbanalytics, eventName, {
        content_type: contentType,
        content_id: contentId,
        items: items,
      });
    }
  }

  setClickEvent(
    eventName: string,
    eventType: string = "",
    pageName: string = "",
    pathId: string = "",
    stepNumber: number = 0
  ) {
    let eventOption = {
      eventName: eventName,
      eventType: eventType,
      pageName: pageName,
      pathId: pathId,
      stepNumber: stepNumber,
    };

    if (this.platform.is("ios") || this.platform.is("android")) {
      this.platform.ready().then(() => {
        this.firebasex.logEvent(
          AnalyticsServiceService.CLICK_EVENT,
          eventOption
        );
      });
    } else {
      let fbanalytics = this.analytics
        ? this.analytics
        : this.initFirebaseAnalytics();
      logEvent(fbanalytics, AnalyticsServiceService.CLICK_EVENT, eventOption);
    }
  }

  setCrashlyticsEnabled() {
    // this.platform.ready().then(() => {
    //   if (this.platform.is("ios") || this.platform.is("android")) {
    //     this.firebasex
    //       .setCrashlyticsCollectionEnabled(true)
    //       .then((res) => {
    //         console.log("Crashlytics data collection is enabled");
    //       })
    //       .catch((error) => {
    //         console.error(
    //           "Crashlytics data collection couldn't be enabled: " + error
    //         );
    //       });
    //   }
    // });
  }

  setCrashErrorLog(error: string) {
    if (this.platform.is("ios") || this.platform.is("android")) {
      this.firebasex.logError(error);
      this.firebasex.sendCrash();
    }
  }

  setOnboardingEvent(
    eventName: string = "",
    eventMessage: string = "",
    pageName: string = "",
    pathId: number = 0,
    clientId: number = 0,
    eventTime: string = ""
  ) {
    let eventOption = {
      eventMessage: eventMessage,
      pageName: pageName,
      pathId: pathId,
      clientId: clientId,
      eventTime: eventTime,
    };

    if (this.platform.is("ios") || this.platform.is("android")) {
      this.platform.ready().then(() => {
        this.firebasex.logEvent(eventName, eventOption);
      });
    } else {
      let fbanalytics = this.analytics
        ? this.analytics
        : this.initFirebaseAnalytics();

      logEvent(fbanalytics, eventName, eventOption);
    }
  }
}
