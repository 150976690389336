import { Component, OnInit, ViewChild, Input, AfterViewInit, ChangeDetectorRef, ElementRef, ViewChildren, QueryList, EventEmitter, Output, DoCheck } from '@angular/core';
import { IonModal, Platform } from '@ionic/angular';
import { PathGroup } from '../path-group/path-group.model';
import Swiper from 'swiper';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { SessionService } from '../session/session.service';

@Component({
  selector: 'app-alert-select-path',
  templateUrl: './alert-select-path.component.html',
  styleUrls: ['./alert-select-path.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('0.2s ease-in', style({ opacity: 0, height: 0 }))
      ])
    ])
  ]
})
export class AlertSelectPathComponent implements OnInit, AfterViewInit,DoCheck {
  modalState: 'open' | 'closed' = 'closed';
  @Input() data: PathGroup[];
  pathGroups: PathGroup[];
  isTabletDevice;
  @ViewChild(IonModal, { static: true }) modal: IonModal;
  isHovered: boolean = false;
  rowHovered: boolean[] = [];
  @Output() pathSelected: EventEmitter<any> = new EventEmitter<any>();
  expandedPaths: { [key: string]: boolean } = {};
  swiperInstances: { [key: string]: Swiper } = {};

  constructor(
    private platform: Platform,
    private cdr: ChangeDetectorRef,
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    this.pathGroups = this.data;
  }

  ngDoCheck(): void {
   this.isTabletDevice= this.platform.is("mobileweb") &&
  (this.platform.is("ipad") || this.platform.is("tablet"));  }
  ngAfterViewInit() {
    this.cdr.detectChanges();
    setTimeout(() => {
      this.initSwipers();
    }, 100);
  }

  onSelect(path: any,pathGroup) {
    this.modal.dismiss({path:path,pathGroup:pathGroup }, 'success');
  }

  toggleDescription(path: any): void {
    this.expandedPaths[path.id] = !this.expandedPaths[path.id];
  }

  async openModal() {
    this.modalState = 'open';
  }

  closeModal() {
    this.modalState = 'closed';
  }
  setRowHovered(index: number, hovered: boolean): void {
    this.rowHovered[index] = hovered;
  }
  
  cancel() {
    if (this.modal) {
      this.modal.dismiss(null, 'cancel');
    }
  }

  initSwipers(): void {
    let slidesPerView: number;

    if (this.platform.is('tablet')) {
      slidesPerView = 2;
    } else if (this.platform.is('desktop')) {
      slidesPerView = 3;
    } else {
      slidesPerView = 1.3;
    }

    this.pathGroups.forEach((pathGroup, index) => {
      const swiperContainer = document.getElementById('swiperContainer_' + index);

      if (swiperContainer) {
        new Swiper(swiperContainer, {
          slidesPerView: slidesPerView,
          centeredSlides: false,
          spaceBetween: 5,
          freeMode: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        });
      }
    });
  }

  // scrollToNext(index: number): void {
  //   console.log('clicked next')
  //   const swiperContainer = document.getElementById('swiperContainer_' + index);
  //   if (swiperContainer) {
  //     const swiperInstance = new Swiper(swiperContainer);
  //     swiperInstance.slideNext();
  //   }
  // }

  // scrollToPrev(index: number): void {
  //   console.log('clicked to prev')
  //   const swiperContainer = document.getElementById('swiperContainer_' + index);
  //   if (swiperContainer) {
  //     const swiperInstance = new Swiper(swiperContainer);
  //     swiperInstance.slidePrev();
  //   }
  // }
// Initialize the Swiper instances outside the scroll functions

scrollToNext(index: number): void {
  console.log('clicked next');
  const swiperContainerId = 'swiperContainer_' + index;
  const swiperContainer = document.getElementById(swiperContainerId);
  if (swiperContainer) {
    if (!this.swiperInstances[swiperContainerId]) {
      this.swiperInstances[swiperContainerId] = new Swiper(swiperContainer, {
        loop: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    }
    this.swiperInstances[swiperContainerId].slideNext();
  }
}

scrollToPrev(index: number): void {
  console.log('clicked to prev');
  const swiperContainerId = 'swiperContainer_' + index;
  const swiperContainer = document.getElementById(swiperContainerId);
  if (swiperContainer) {
    if (!this.swiperInstances[swiperContainerId]) {
      this.swiperInstances[swiperContainerId] = new Swiper(swiperContainer, {
        loop: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    }
    this.swiperInstances[swiperContainerId].slidePrev();
  }
}
}
