import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'first90-action-points-icon-svg',
  templateUrl: './action-points-icon-svg.component.html',
  styleUrls: ['./action-points-icon-svg.component.scss']
})
export class ActionPointsIconSvgComponent implements OnInit {
  @Input() points: number
  @Input() forcePoints: boolean
  @Input() onlyIcon: boolean

  constructor() { }

  ngOnInit() {
  }

}
