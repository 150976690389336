import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppState } from 'src/app/app.service';
//import * as EventEmitter from 'events';
import { FeedbackFormComponent } from '../feedback-form/feedback-form.component';
import { MyCommitmentsService } from 'src/app/my-commitments/my-commitments.service';
import { AnimationsService } from 'src/app/shared/animations.service';

@Component({
  selector: 'app-feedback-component',
  templateUrl: './feedback-component.component.html',
  styleUrls: ['./feedback-component.component.scss'],
})
export class FeedbackComponentComponent implements OnInit {
  @Input() data: any = [];
  @Input() pathId;
  @Input() path: any;
  isLoaded=true;
  @Output() onNext: any = new EventEmitter<any>();
  @Output() onLogaRep: any = new EventEmitter<any>();
  constructor(public modalController: ModalController, 
    private appState: AppState,
    private animationService: AnimationsService,
    private myCommitmentService: MyCommitmentsService) { }

  ngOnInit() {
  }
  ngOnChanges() {
    // if (this.data) {
    //   this.data.push(...this.data);
    // }
  }
  ngDoCheck(){
    this.myCommitmentService.getLoader.subscribe((val)=>{
      this.isLoaded =val
    })
  }
  async showFeedbackForm(mainItem, subItem, index) {
    // var length1=subItem.reps_commit.filter((item)=> item.is_completed === false);
    //console.log('length1length1', length1);
    let repsIndex = subItem.reps_commit.findIndex((item) => item.is_completed === false);
    if (repsIndex > -1) {
      const modal = await this.modalController.create({
        component: FeedbackFormComponent,
        componentProps: {
          mainItem: mainItem,
          subItem: subItem,
          index: repsIndex
        },
        enterAnimation: this.animationService.slideInLeftEnterAnimation,
        leaveAnimation: this.animationService.SlideOutRightLeaveAnimation
      });
      modal.onDidDismiss().then((modelData) => {
        if (modelData !== null && modelData.data !== undefined) {

          //this.modelData = modelData.data;
          //console.log('modal dismiss', modelData.data);
          //this.data = modelData.data;
          this.isLoaded=false;
          this.myCommitmentService.setLoader(false);
          this.onLogaRep.emit(modelData.data); 
          //console.log('Modal Data : ' + this.data);
        }
      });

      return await modal.present();
    } else {
      console.log('Complted level')
    }
  }
  goToNext(routine, level) {
    this.onNext.emit({
      routine: routine,
      level: level
    })
  }
}
