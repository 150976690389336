import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { User } from '../user/user.model'
import { Path } from '../path/path.model'
import { Client } from '../client/client.model'
import { AppState } from '../app.service'
import { Router } from '@angular/router'
import { StatService } from '../stat/stat.service'
import { ThemeService } from '../theme/theme.service'
import { NotificationService } from '../notification/notification.service'
import { ChartSettings } from '../daily-participation-chart/daily-participation-chart.component'
import {WalletService} from '../wallet/wallet.service'
import { ContentSurveyStats } from '../content-survey/content-survey.model'
import { SpinnerService } from '../spinner.service';
import { UtilityService } from '../common-services/utility.service';
import { initial } from 'underscore';
import { HomeUiService } from '../new-design/home-ui.service';
import { MyCommitmentsComponent } from '../my-commitments/my-commitments.component';
import { StatListComponent } from '../stat/stat-list.component';
import { AllPathsComponent } from '../all-paths/all-paths.component';
import { CloseoutSurveyComponent } from './closeout-survey.component';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-closeout-survey-welcome',
  templateUrl: './closeout-survey-welcome.component.html',
  styleUrls: ['./closeout-survey-welcome.component.scss']
})
export class CloseoutSurveyWelcomeComponent implements OnInit {
  currentPath: Path
  currentClient: Client
  currentUser: User
  chartSettings: ChartSettings
  leaderboardPosition: string
  badgesCount: number
  graphLabels:any
  graphData:any
  graphGreen:any
  graphRealDay:any
  firstSurveyStats: ContentSurveyStats
  finalSurveyStats: ContentSurveyStats
  allowScreenCount: boolean
  stats: any;
  thisPathUserRank: any
  thisPathUser:any;
  makePath: boolean=false;
  scheduleDay:any;
  isShow=false;
  isMobile;
  dailyStepsArray;
  scheduledStep;
  constructor(
    private appState: AppState,
    private router: Router,
    private statService: StatService,
    private themeService: ThemeService,
    private homeUIService: HomeUiService,
    public walletService: WalletService,  // LUMBA-1403
    private utilService: UtilityService,
    private modalController: ModalController,
    private cdRef: ChangeDetectorRef,
  ) {
    this.currentPath = this.appState.get('currentPath')
    this.currentUser = this.appState.get('currentUser');
    this.currentClient = this.appState.get('currentClient');
    this.scheduleDay = +this.appState.get('currentScheduleDay');
    this.themeService.setTheme(this.currentPath.getTheme())
    this.allowScreenCount = this.appState.get('allowScreenCount')
    this.isMobile = this.appState.get('isMobile');
    this.init()
    setTimeout(()=>{this.isShow=true}, 100);
  }

  ngOnInit() {
    this.scheduledStep =this.appState.get('currentDayAfterScreenView')
    this.dailyStepsArray= this.appState.get("DailyStepsData");
    if (this.allowScreenCount && this.scheduleDay ==this.scheduledStep && this.dailyStepsArray[this.scheduledStep-1]?.stepStatus!='Review'){
      this.utilService.setScreenCount()
    }
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  
  init(){
    this.statService.getDailyParticipation().subscribe(
      response => {
        const cutResponse = this.statService.cutParticipationData(response);
        this.chartSettings = {
          colour: this.currentPath.colours['theme'],
          labels: cutResponse.labels,
          data: cutResponse.data,
          greenDays: response.greenDays,
          realDay: response.realDay
        }
        
        this.leaderboardPosition = response.leaderboardPosition
        this.badgesCount = response.badgesCount
        const firstSurvey = this.statService.findFirstCompletedSurvey(this.currentPath.contentSurveys);
        const finalSurvey = this.statService.findFinalCompletedSurvey(this.currentPath.contentSurveys);
        if (firstSurvey) {
          this.firstSurveyStats = firstSurvey.getStats()
        }
        if (finalSurvey) {
          this.finalSurveyStats = finalSurvey.getStats()
        }
      }
      
    )
    this.getStats();
  }

  goToSurvey() {
    if (this.allowScreenCount && this.scheduleDay ==this.scheduledStep && this.dailyStepsArray[this.scheduledStep-1]?.stepStatus!='Review'){
      this.utilService.setScreenCount()
    }
    // this.router.navigate(['/closeout'])
    this.modalController.dismiss();
    this.homeUIService.openDailyStepsComponent(CloseoutSurveyComponent,'','web-daily-steps-custom-modal')
  }
  getStats() {
    this.statService.getList(this.currentPath.id)
      .then(response => {
        this.stats = response;
        this.getCurrentUser(this.stats);
      })
      .catch(error => {
        this.stats = error
      })

  }
  getCurrentUser(stats: any) {
    var current_path_user = []
    current_path_user = stats.leaderboard.current_path_user
    try {
      this.thisPathUserRank = current_path_user.findIndex(element => element.isMe)
      let thisUser = current_path_user.filter(element => element.isMe)
      this.thisPathUser = thisUser[0];
    } catch (error) {
      console.log('error catch', error);

    }
  }
  getClass(){
    return true;
  }
  goToLeaderBoard(){
    //this.router.navigate(['/main/stats'])
    this.homeUIService.openNextComponent(StatListComponent);

  }
  gotoMyCommitment(){
   // this.router.navigate(['/main/commitments'])
   this.homeUIService.openNextComponent(MyCommitmentsComponent)
  }
  goToNewPath(){
    // this.router.navigate(['/main/stats'])
    this.homeUIService.openNextComponent(StatListComponent);
  }
  setClass(value) {
     this.appState.set("isAllPaths", true);
    this.homeUIService.openNextComponent(AllPathsComponent)
    //this.makePath=value;
  }
  goToHome(){
    this.router.navigate(['/main'])
  }
  openAllPathsModal() {
    this.appState.set("isAllPaths", true);
    this.modalController.dismiss();
    setTimeout(()=>{
      this.homeUIService.openNextComponent(AllPathsComponent, '', 'desktop-allpath');
    },550)
  }
  openToDoListModal() {
    this.modalController.dismiss();
    setTimeout(()=>{
      this.homeUIService.openNextComponent(MyCommitmentsComponent,'','desktop-todo')
    },550)
  }
  close() {
    this.modalController.dismiss();
  }
}
