import { Pipe, PipeTransform } from '@angular/core';
import { AppState } from './app.service';
import { Path } from './path/path.model';
import { names } from './names.data';

@Pipe({
  name: 'names'
})
export class NamesPipe implements PipeTransform {
  path: Path

  constructor(private appState: AppState) { 
    this.path = this.appState.get('currentPath')
  }
  
  transform(value: any, args?: any): any {
    let result = this.path.names[value] || names[value] || 'Invalid name' 
    return result
  }
}
