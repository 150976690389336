import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { NavParams } from '@ionic/angular';
import { AppState } from 'src/app/app.service';
import { HomeUiService } from 'src/app/new-design/home-ui.service';

@Component({
  selector: 'app-user-public-profile',
  templateUrl: './user-public-profile.component.html',
  styleUrls: ['./user-public-profile.component.scss'],
})
export class UserPublicProfileComponent implements OnInit {
  user:any;
  path;
  isMobile;
  constructor(
    public navParams: NavParams, private appState: AppState,
    public homeUiService:HomeUiService
    //@Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.isMobile = this.appState.get('isMobile');
   }

  ngOnInit() {
    this.user=this.navParams.get('user');
    this.path= this.appState.get("currentPath");
    console.log('bottom sheet', this.user);
    this.isMobile = this.appState.get('isMobile');
  }

}
