import {
  Component,
  Input,
  ViewChild,
  OnInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core'
import { Question } from '../question/question.model'
import { Reply } from '../reply/reply.model'
import { QuestionService } from '../question/question.service'
import { ReplyService } from '../reply/reply.service'
import { ReplyComponent } from '../reply/reply.component'
import { AttachmentUploaderComponent } from '../shared/attachment-uploader/attachment-uploader.component'
import { ToastrService } from 'ngx-toastr'
import { TranslationService } from '../translation/translation.service'
import { AppState } from '../app.service'
import { Path } from '../path/path.model'
import { Router } from '@angular/router'
import { Apollo } from 'apollo-angular'
import { CordovaService } from '../cordova.service'
import { I18n } from '../i18n.service'
import { SessionService } from '../session/session.service'
import { MobileChatServiceService } from '../pages/mobile-chat-board/mobile-chat-service.service'
import { SpinnerService } from '../spinner.service'
import { ToasterService } from '../toaster.service'

@Component({
  selector: 'app-post-feed',
  templateUrl: './post-feed.component.html',
  styleUrls: ['./post-feed.component.scss']
})
export class PostFeedComponent implements OnInit {

  @Input() question: Question
  @Input() mode: string = 'question'
  @Input() isPreview: boolean
  @Input() inverted: boolean = false
  @Input() small = false

  @ViewChild('uploader') uploader: AttachmentUploaderComponent
  @ViewChild('replyBlock') replyBlock: ElementRef
  @ViewChild('repliesContainer') repliesContainer: ElementRef

  path: Path
  ishideComment:boolean = false;
  sortBy: string = 'timestamp'
  replyText: string = ''
  cachedReplyText: string = ''  // LUMBA-1383
  attachmentUrl: string = null
  errors: { answerError: false }
  replyTo: Reply = null
  replyToComponent: ReplyComponent
  replyToId: number
  isLoading: boolean = false
  replyOffset: number

  constructor(
    private i18n: I18n,
    private apollo: Apollo,
    private router: Router,
    private appState: AppState,
    private toastrService: ToasterService,
    private questionService: QuestionService,
    private replyService: ReplyService,
    private translationService: TranslationService,
    private spinnerService: SpinnerService,
    private cordovaService: CordovaService,
    private sessionService: SessionService,
    private mobileService: MobileChatServiceService,
    private cdRef: ChangeDetectorRef // LUMBA-1325
  ) {
    this.path = this.appState.get('currentPath')
  }

  ngOnInit() {
    // LUMBA-1317
    this.toggleSort('timestamp')
  }

  ngAfterViewInit() {
    this.replyOffset = this.replyBlock.nativeElement.offsetTop
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.question || changes.question.firstChange) {
      return false
    }
    this.question.sortRepliesBy(this.sortBy)
    this.cdRef.detectChanges()
  }

  toggleSort(by: string) {
    console.log("by:" + by);
    this.sortBy = by
    this.question.sortRepliesBy(by)
  }

  hideAndShowComment(){

    if(this.ishideComment){
      this.ishideComment=false
    }else{
      this.ishideComment=true
    }

  }

  reply() {

    if(this.replyText.length==0){
      this.toastrService.error(this.i18n.t('Please enter a comment'))
      return false;
    }

    // if (!this.replyText || this.replyText.length < 3) {
    //   this.toastrService.error(this.i18n.t('Comment is too short'))
    //   return false
    // }
    if (this.isPreview) {
      return false
    }
    if (this.isLoading) {
      return false
    }
    this.isLoading = true

    var list_of_tags_ids = []

    list_of_tags_ids = this.sessionService.getUserTAGs(this.replyText,this.complexItems)
    
    console.log("Array of tags ",list_of_tags_ids);

    this.unloadReply()
    if (this.replyTo) {
      this.replyService
        .reply(this.question, this.replyTo.id, this.cachedReplyText, this.attachmentUrl,list_of_tags_ids,'')
        .subscribe(reply => {
         // this.ishideComment = true;
           // this.reset()
            this.isLoading = false
            this.toastrService.success(this.i18n.t('Posted!'))
          },
          error => {
           // this.loadReply()
            this.toastrService.error(this.i18n.t('Post not saved. Please check your internet connection.'))
            this.isLoading = false
           // this.cdRef.detectChanges()
          })
    } else {
      this.questionService
        .replyQuestion(this.question, this.cachedReplyText, this.attachmentUrl,list_of_tags_ids,this.mode)
        .subscribe(reply => {
         // this.ishideComment = true;
          // this.reset()
          this.appState.set('currentActionPoints', reply?.data?.replyQuestion?.actionPoints)
          this.isLoading = false
       
          this.toastrService.success(this.i18n.t('Posted!'))
          
         this.appState.set("questionsLoaded",false);

          this.refresh();

        }, error => {
          console.log(error)
          // this.loadReply()
          this.toastrService.error(this.i18n.t('Post not saved. Please check your internet connection.'))
          this.isLoading = false
          // this.cdRef.detectChanges()
        })
    }
  }

  translate() {
    const path: Path = this.appState.get('currentPath')
    const repliesIds = this.question.replies.map(reply => reply.id)
    this.translationService.loadRepliesTranslations(repliesIds, path.language).subscribe(
      (translations) => {
        this.question.replies.forEach(reply => {
          const key = `Reply:${reply.id}`
          reply.text = translations[key].text
        })
      }
    )
  }

  reset() {
    this.replyText = ''
    this.attachmentUrl = null
    if (this.uploader) {
      this.uploader.reset()
    }
    this.cachedReplyText = ''
  }

  // LUMBA-1383
  loadReply() {
    if (this.cachedReplyText) {
      this.replyText = this.cachedReplyText
      this.cachedReplyText = ''
    }
  }

  // LUMBA-1383
  unloadReply() {
    this.cachedReplyText = this.replyText
    this.replyText = ''
  }

  setReplyTo(replyComponent: ReplyComponent) {
    if (this.uploader) {
      this.uploader.reset()
    }
    // This code was needed when all replies was visible
    // Now all replies hidden except selected for reply
    if (this.replyToComponent) {
      this.replyToComponent.selected = false
    }
    this.replyToComponent = replyComponent
    if (replyComponent) {
      this.replyTo = replyComponent.reply
      this.replyToId = this.replyTo.id
    } else {
      this.replyTo = null
      this.replyToId = null
    }
  }

  refresh() {
    console.log(this.router.url)
    this.apollo.getClient().clearStore()
    this.appState.refreshMainComponent.emit()
  }


  translatePost(language: string,text: any){

    let data = {
      language: language,
      text: text
    }

    this.mobileService.translateContent(data).subscribe(
      (response) => {
        if(response.status){
          this.replyText = response.data.text
          this.toastrService.success(this.i18n.t('Post translated successfully!'))
        }
      },
    (error) => {
      console.log("TranslationService data ",error);
    }
    )
  }



  scrollTo(event) {
    if (this.cordovaService.onCordova) {
      console.log('click event')
      console.log(this.replyBlock.nativeElement.offsetTop)

      setTimeout(() => {
        window.scrollTo({top: this.replyBlock.nativeElement.offsetTop - 160, behavior: 'smooth'})
      }, 10)
    }
  }

  complexItems = this.appState.get("userlist")

  namesData : any[] = new Array()  
  tempValue = 0
  index = 0
  
  format(item:any) {

    var data = item['id']
      setTimeout(() => {
        SessionService.setData(data)
      }, 100);
    return item['username'].toUpperCase()
  }

  mentionConfig:any = {
    mentions: [
      {
        items: this.complexItems,
        labelKey: 'username',
        mentionSelect: this.format,
        dropUp:true
      }
    ]
  };


}
