import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { result } from 'underscore';
import { AlertSupportComponent } from '../alert-support/alert-support.component';
import { AppState } from '../app.service';
import { SessionService } from '../session/session.service';

@Component({
  selector: 'app-support-bubble',
  templateUrl: './support-bubble.component.html',
  styleUrls: ['./support-bubble.component.scss']
})
export class SupportBubbleComponent implements OnInit {

  isMobile: boolean

  constructor(
    private matDialog: MatDialog,
    private appState: AppState
  ) { }

  ngOnInit() {
    this.isMobile = this.appState.get('isMobile')
  }

  openPopup(){

    var supportDialog = this.matDialog.open(AlertSupportComponent);

    supportDialog.afterClosed().subscribe(
      
      
    
    )

  }

}
