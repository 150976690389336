import gql from 'graphql-tag'

export const journalPostFields = gql`
fragment journalPostFields on JournalPost {
  id
  userId
  pathId
  text
  isPinned
  pathName
  screenId
  inoutScreens{
    id
    startDay
    endDay
    preScreens
    postScreens
    homepageTitle
    homepageSubtext
    libraryImage
  }
  polls{
    id
    title
    options {
      id
      text
    }
    preScreens
    postScreens
    startDay
    endDay
    ptype
    lowerlabel
    higherlabel
  }
  contentSurveys{
    id
    title
    contentSurveyQuestions {
      id
      title
      rationale
      answer {
        id
        optionId
        correctOptionId
        isCorrect
        stats
      }
      contentSurveyOptions {
        id
        text
      }
    }
    startDay
    endDay
    preScreens
    postScreens
  }
  documents{
    id
    title
    preScreens
    postScreens
    link
    imageUrl
    startDay
    endDay
    stitle
    source
    isRecommended
  }
  editedDocumentUrl
  questions{
    id
    text
    preScreens
    postScreens
    startDay
    endDay
  }
  habits{
    id
    text
    preScreens
    postScreens
    startDay
    endDay
    title
  }
  routineInfo{
    id
    title
    startDay
    endDay
    isMandatory
    levels{
      id
      text
      reps
      title
      level
      repsData
      isLevelCompleted
    }
  }
  reflections {
    id
    startDay
    title
    endDay
    actionPoints
    preScreens
    postScreens
    prompt
    isMandatory
    isDeleted
    enabled
    text
    imageUrl
  }
}
`

export const postToJournal = gql`
mutation postToJournal($journalPost: JournalPostInput!) {
  postToJournal(journalPostInput: $journalPost) {
    status
    journalPost {
      ...journalPostFields
    }
  }
}
${journalPostFields}
`

export const sendJournalPdf = gql`
mutation sendJournalPdf($pathId: Int!) {
  sendJournalPdf(pathId: $pathId) {
    status
  }
}
`

export const toggleJournalPostPin = gql`
mutation toggleJournalPostPin($postId: Int!, $pin: Boolean!) {
  toggleJournalPostPin(postId: $postId, pin: $pin) {
    pin
  }
}
`

export const listJournalPosts = gql`
query listJournalPosts($filter: FilterInput) {
  journalPosts(filter: $filter) {
    ...journalPostFields
  }
}
${journalPostFields}
`
