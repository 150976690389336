import gql from 'graphql-tag'

export const answerSurvey = gql`
mutation answerSurvey($answersJson: String!, $managerEmail: String, $currentDay: Int) {
  answerSurvey(answersJson: $answersJson, managerEmail: $managerEmail,currentDay: $currentDay) {
    status
    answer{
      id
      stats
    }
  }
}
`;
