import { AfterViewInit, Component, DoCheck, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AlertEditNoteComponent } from '../alert-edit-note/alert-edit-note.component';
import { AppState } from '../app.service';
import { CordovaService } from '../cordova.service';
import { SpinnerService } from '../spinner.service';
import { LibraryService } from './library.service';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { ToasterService } from '../toaster.service';
import { AlertController, AlertInput, IonCol, ModalController, Platform } from '@ionic/angular';
 import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { DomSanitizer } from '@angular/platform-browser';
import { IPDFViewerApplication } from 'ngx-extended-pdf-viewer';
import { filter, pairwise } from 'rxjs/operators';
import { I18n } from '../i18n.service';
import mixpanel from 'mixpanel-browser';
import { HomeUiService } from '../new-design/home-ui.service';

@Component({
  selector: 'full-screen-document',
  templateUrl: './full-screen-document.component.html',
  styleUrls: ['./full-screen-document.component.scss'],
})

export class FullScreenDocumentComponent implements AfterViewInit, DoCheck {

  singleDocument: any
  pathName: string
  stepNumber: number
  journalPost: any
  presignedUrl: any
  isMobile: any
  journalNote: string
  isEditNote: boolean
  isCasualDocument: boolean
  pdfSrc: any;
  pdflink;
  public height = "0"; 
  webPdfLik;
  user;
  flag=false;
  currentClient;
  annotations;
  PDFViewerApplication;
  state=false
  note;
  isBackAllowed: boolean
  @ViewChild('contentContainer') contentContainer;

  constructor(
    private socialSharing: SocialSharing,
    private appState: AppState,
    private cordovaService: CordovaService,
    private libraryService: LibraryService,
    private dialog: MatDialog,
    private spinnerService: SpinnerService,
    private toasterService: ToasterService,
    private route: Router,
    private file: File,
    public i18n: I18n,
    public modalController: ModalController,
    public platform: Platform,
    private transfer: FileTransfer,
    private statusBar: StatusBar,
    private sanitized: DomSanitizer,
    public render: Renderer2,
    private alertController: AlertController,
    public homeUIService: HomeUiService,
    ) {
      route.events
      .subscribe(async (event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate' && event.url == '/main/library' && !this.state) {
          // Perform actions
          let PDFViewerApplication: IPDFViewerApplication = (window as any).PDFViewerApplication;
          this.annotations = PDFViewerApplication?.pdfDocument?.annotationStorage?.getAll();
          localStorage.setItem("annotationData", JSON.stringify(this.annotations))
          if(this.annotations) {
            let i = await PDFViewerApplication?.pdfDocument?.saveDocument()
            let unit8Array =this.arrayBufferToString(i);
            localStorage.setItem("saveAnnotationData",unit8Array )
            localStorage.setItem('isCloseButtonClicked', JSON.stringify(false));

          }
          this.state =true
        }
      });
      this.route.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        console.log('previous url', events[0].urlAfterRedirects);
        console.log('current url', events[1].urlAfterRedirects);
      });   
     this.pathName = this.appState.get('journalPathName')
    this.journalPost = this.appState.get('journalPost')
    console.log('this.journalPost :', this.journalPost);
    this.singleDocument = this.appState.get('singleDocument')
    this.isCasualDocument = this.appState.get('isCasualDocument')
    console.log('this.isCasualDocument :', this.isCasualDocument);
    console.log('singleDocument :', this.singleDocument);
    this.isMobile = this.appState.get('isMobile');
    this.user = this.appState.get('currentUser')
    this.currentClient = this.appState.get('currentClient')
    this.presignedUrl = environment.preSignedUrl
    this.isBackAllowed = this.appState.get('isBackAllowed')
    console.log('this.isBackAllowed :', this.isBackAllowed);
    if (this.journalPost) {
      if (this.journalPost?.questions[0]) {
        this.stepNumber = this.journalPost?.questions[0].startDay
      } else if(this.journalPost?.documents[0]){
        this.stepNumber = this.journalPost?.documents[0].startDay
      } else if(this.journalPost?.contentSurveys[0]){
        this.stepNumber = this.journalPost?.contentSurveys[0].startDay
      } else if(this.journalPost?.habits[0]){
        this.stepNumber = this.journalPost?.habits[0].startDay
      } else if(this.journalPost?.inoutScreens[0]){
        this.stepNumber = this.journalPost?.inoutScreens[0].startDay
      }
    }
    
    if (this.journalPost) {
      this.getNoteContent(this.journalPost?.text)
    }else{
      this.getNoteContent(this.singleDocument?.notes)
    }
    
  }

   async ngDoCheck(): Promise<void> {
    let PDFViewerApplication: IPDFViewerApplication = (window as any).PDFViewerApplication;
     this.annotations = PDFViewerApplication?.pdfDocument?.annotationStorage?.getAll();
     if (localStorage.getItem("previousAnotations") =='undefined' && this.annotations) {
       localStorage.setItem("previousAnotations", JSON.stringify(this.annotations));
     }
     
    localStorage.setItem("annotationData", JSON.stringify(this.annotations))
  }
  ngAfterViewInit(): void {
    this.singleDocument = this.appState.get('singleDocument')
    localStorage.setItem('singleDocument', JSON.stringify(this.singleDocument))
    this.viewDocument(this.singleDocument?.editedDocumentUrl
      ?this.singleDocument?.editedDocumentUrl
      : this.singleDocument?.link);  
    this.webPdfLik =this.sanitized.bypassSecurityTrustResourceUrl(this.singleDocument?.editedDocumentUrl
      ?this.singleDocument?.editedDocumentUrl
      : this.singleDocument?.link);
    this.webPdfLik = this.webPdfLik.changingThisBreaksApplicationSecurity;
  }
  getNoteContent(post){
    if (post) {
      let note = post
      var b = JSON.stringify(note);
      let testNote = b.replace(/\\/g, '');
      let testNote1 = testNote.replace(/[\[\]']+/g, '')
      this.journalNote = testNote1.replace(/"/g, '');
      console.log('journalNote',this.journalNote);
    }
  }

  async saveEditPdf() {
    const PDFViewerApplication: IPDFViewerApplication = (window as any).PDFViewerApplication;
    const name = this.singleDocument?.link.substr(this.singleDocument?.link.lastIndexOf("/") + 1);
      const fileName = name?.split("?")[0];
      const i = await PDFViewerApplication?.pdfDocument?.saveDocument()
                  , n = new Blob([i],{
                            type: "application/pdf"
                        })
                        const url = window.URL.createObjectURL(n);
                         console.log(typeof(i),i,url);
                        const myFormData = new FormData()
                       
                        myFormData.append('file',n, fileName);
                        
                      this.libraryService.postAnnotationDoc(myFormData).subscribe((res)=>{
                        mixpanel.track('Library-pdf post annotation', {'s3_url': res.data.s3_url, 'url': res.data.url, 'message': res.message})
                        let editData ={
                          "user_id":this.user.id,
                          "document_id" :this.singleDocument?.id,
                          'edited_document_url' : res?.data?.s3_url,
                          "is_reset": false
                        }
                        this.libraryService.editAnnotationDoc(editData).subscribe((res)=>{
                          console.log("result link", res, n);
                          mixpanel.track('Librarypdf edit annotation', {'data': res.data})
                          this.getCasualDocuments(this.currentClient.id, this.user.id)
                          this.spinnerService.off();
                          this.toasterService.success(this.i18n.t("Saved"),'top','success-toaster-class-doc');
                          this.modalController.dismiss();
               
                         }, ()=>{
                          this.spinnerService.off();
                         // this.toasterService.success(this.i18n.t(error))
                        })
                       
                      }
                      , ()=>{
                        this.spinnerService.off();
                        //this.toastrService.success(this.i18n.t(error))
                      })
                       
                        
                        
                        
  }
  alertControllerCreate() {
    this.alertController
      .create({
        message: this.i18n.t("Do you want to save annotations in this document?"),
        backdropDismiss: false,
        cssClass: 'alertLogCss',
        buttons: [
          {
            text: this.i18n.t("No"),
            role: 'cancel',
            handler: () => {
            localStorage.setItem("previousAnotations", undefined);
            this.modalController.dismiss()
            }
          },
          {
            text: this.i18n.t("Yes"),
            handler: () => {
              // eslint-disable-next-line @typescript-eslint/dot-notation
              localStorage.setItem("previousAnotations", undefined);
              this.spinnerService.on();
              this.saveEditPdf();

            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }
  arrayBufferToString( buffer: Uint8Array ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return  binary;
}
  async close(event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    const PDFViewerApplication: IPDFViewerApplication = (window as any).PDFViewerApplication;
    let annotations = PDFViewerApplication?.pdfDocument?.annotationStorage?.getAll();
    console.log("annotation", annotations)
    if (this.isBackAllowed) {
      this.appState.set('isBackAllowed', undefined)
    }
    this.appState.set('singleDocNote', undefined)
    this.appState.set('isDocumentClosed',true);
    localStorage.removeItem('journalNote')
    if (this.isCasualDocument) {
      //this.appState.set('tabLabel', 'recommended') 
      this.appState.set('isCasualDocument', undefined) 
    }
    if (annotations && (annotations['pdfjs_internal_editor_0']?.name == "freeTextEditor" || annotations['pdfjs_internal_editor_0']?.name == "inkEditor" || annotations['pdfjs_internal_editor_0'])) {
      localStorage.setItem('isCloseButtonClicked', JSON.stringify(true));
      this.alertControllerCreate();
    }
    else if (annotations) {
      this.checkManualEdits(annotations);
    }
     else {
      this.modalController.dismiss()
     }
  }

  checkManualEdits(annotations ) {
    annotations = Object.values(annotations);
    let previousEdits =[]
    if (localStorage.getItem("previousAnotations")) {
        previousEdits = Object.values(JSON.parse(localStorage.getItem("previousAnotations")));
      }
    const changedAnnotations = annotations.filter(annotation => 
         !previousEdits.some(prev => JSON.stringify(prev) === JSON.stringify(annotation) || prev.value ==annotation.value));
    if (changedAnnotations?.length>0) {
      localStorage.setItem("isCloseButtonClicked", JSON.stringify(true));
      this.alertControllerCreate();
    }
    else {
      this.modalController.dismiss();
    }

  }
  resetDocUrl() {
    let editData ={
      "user_id":this.user.id,
      "document_id" :this.singleDocument?.id,
      'edited_document_url' : null,
      "is_reset": true
    }
      this.alertController
        .create({
          //header: this.alert_exit_title,
          message: this.i18n.t("Do you want to delete all annotations in this document?"),
          backdropDismiss: false,
          buttons: [
            {
              text: this.i18n.t("No"),
              role: 'cancel',
            },
            {
              text: this.i18n.t("Yes"),
              handler: () => {
                // eslint-disable-next-line @typescript-eslint/dot-notation
                this.spinnerService.on();
                this.libraryService.editAnnotationDoc(editData).subscribe((res)=>{
                  console.log("result link", res);
                  this.flag =true;
                  this.getCasualDocuments(this.currentClient.id, this.user.id)
                
                 }, ()=>{
                  this.spinnerService.off();
                 // this.toasterService.success(this.i18n.t(error))
                })
  
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    
     
  }
  getCasualDocuments(clientId,userId){
    let requestData = {
      'clientId': clientId,
      'userId': userId
    }
    if (requestData) {
      this.libraryService.getCasualDocuments(requestData).subscribe(documents => {
        this.appState.set('documentsModified', documents?.data)
        this.appState.set("isLibraryRefresh", true);
        documents?.data.forEach(element => {
          if(element?.id== this.singleDocument?.id) {
            this.appState.set('singleDocument', element);
            if(this.flag) {
              this.flag =false;
              this.ngAfterViewInit();
            }
            
          }
        });
        
        this.spinnerService.off()
      }, ()=>{
        this.spinnerService.off();
      })
    }
  }
  async openNotesModal() {
    if(this.isCasualDocument) {
      if (localStorage.getItem('journalNote')) {      
        this.appState.set('singleDocNote', localStorage.getItem('journalNote'))
      } else {
        this.appState.set('singleDocNote', this.singleDocument?.notes)
      }
    }
    
    let note = this.appState.get('singleDocNote')
    this.getNoteContent(note);  
    const alert =await this.alertController.create({
      header: this.i18n.t("Notes"),
      cssClass: `${this.homeUIService.isDark() ? 'dark-theme' : 'light-theme'}`,
      inputs:[
        {
          type:'textarea',
          name:'textareaValue',
          value:this.journalNote,
          cssClass:'alert-textarea'
        },
      
      ],
      buttons: [
        {
          text:this.i18n.t('Save'),
          cssClass:'custom-alert',
          handler:(alertData: AlertInput)=>{
            console.log('this.singleDocument :', this.singleDocument,alertData['textareaValue']);
            let noteText;
            noteText = alertData['textareaValue'];

            localStorage.setItem('journalNote', alertData['textareaValue']);
            console.log('noteText :', noteText);
            this.appState.set('singleDocNote', noteText)
            this.journalNote = noteText
            this.saveNote()
          }
        }
      ]
    })
    await alert.present();
  }

  refresh() {
    return new Promise(resolve => {
      this.appState.refreshMainComponent.emit()
      setTimeout(resolve, 0)
    })
  }

  async share(event, document) {
  console.log('document :', document);
  console.log('event :', event);
    event.stopPropagation()
    const PDFViewerApplication: IPDFViewerApplication = (window as any).PDFViewerApplication;
    let annotations = PDFViewerApplication?.pdfDocument?.annotationStorage?.getAll();
    if(annotations) {
      const PDFViewerApplication: IPDFViewerApplication = (window as any).PDFViewerApplication;
      const name = this.singleDocument?.link.substr(this.singleDocument?.link.lastIndexOf("/") + 1);
        const fileName = name?.split("?")[0];
        const i = await PDFViewerApplication?.pdfDocument?.saveDocument()
                    , n = new Blob([i],{
                              type: "application/pdf"
                          });
                          const url = window.URL.createObjectURL(n);
                           console.log(typeof(i),i,url);
                          const myFormData = new FormData()
                         
                          myFormData.append('file',n, fileName);
                          this.spinnerService.on();
                        this.libraryService.postAnnotationDoc(myFormData).subscribe((res)=>{
                          this.spinnerService.off();
                          let isPresignedURL = res?.data?.url.includes(this.presignedUrl)
                          console.log('isPresignedURL in if:', isPresignedURL);
                          console.log('res?.data?.url in if :', res?.data?.url);
                          console.log('document.title  in if:', document.title);

                          if(this.isMobile){
                            try {
                              this.downloadFile(res?.data?.url)
                            } catch (error) {
                              console.log('error :', error);
                            }
                          }else{
                            this.openMailTo(isPresignedURL, document.title, res?.data?.url)
                          }
                        }, ()=>{
                          this.spinnerService.off();
                        })
    }
    else {
      let link = document?.editedDocumentUrl? document?.editedDocumentUrl: document?.link
      console.log('link in else:', link);
      let isPresignedURL = link.includes(this.presignedUrl)
      console.log('isPresignedURL in else:', isPresignedURL);
      console.log('(document.title in else:', (document.title));

      if(this.isMobile){
        try {
          this.downloadFile(link)
        } catch (error) {
          console.log('error :', error);
        }
      }else{
        this.openMailTo(isPresignedURL, document.title, link)
      }
    }
   
  }

  downloadFile(url) {
    if (url.includes(".pdf")) {
      const transfer = this.transfer.create();
      let path = null;
      const name = url.substr(url.lastIndexOf("/") + 1);
      const fileName = name.split("?")[0];
      if (this.platform.is("ios")) {
        path = this.file.documentsDirectory;
      } else {
        path = this.file.dataDirectory;
      }
      transfer.download(url, this.file.dataDirectory + fileName).then((entry) => {
        this.pdfSrc = entry.toURL()
        console.log('this.pdfSrc :', this.pdfSrc);
        if (this.pdfSrc) {
          this.socialSharing.share("", "", this.pdfSrc, "")
            .then((entries) => {
              console.log('success ' + JSON.stringify(entries));
            })
            .catch((error) => {
              console.log('error ' + JSON.stringify(error));
            });
        }
      }, (error) => {
        console.log('download failed: ', error);
      });
    } else {
      this.socialSharing.share("", "", "", url)
        .then((entries) => {
          console.log('success ' + JSON.stringify(entries));
        })
        .catch((error) => {
          console.log('error ' + JSON.stringify(error));
        });
    }
  }

  openMailTo(isPresignedURL, subject, body) {
    console.log('body openMailTo:', body);
    console.log('subject openMailTo:', subject);
    console.log('isPresignedURL openMailTo:', isPresignedURL);
    if (isPresignedURL) {
      console.log('isPresignedURL :', isPresignedURL);
      location.href = 'mailto:%20' +
        '?subject=' + encodeURIComponent(subject) +
        '&body=' + encodeURIComponent(body + ' \n \n Note: This link will expire in 7 days.')

      this.cordovaService.openMail('mailto:%20' +
        '?subject=' + encodeURIComponent(subject) +
        '&body=' + encodeURIComponent(body + ' \n \n Note: This link will expire in 7 days.'))

      return true
    } else {
      console.log('else :');
      location.href = 'mailto:%20' +
        '?subject=' + encodeURIComponent(subject) +
        '&body=' + encodeURIComponent(body)

      this.cordovaService.openMail('mailto:%20' +
        '?subject=' + encodeURIComponent(subject) +
        '&body=' + encodeURIComponent(body))

      return true
    }
  }

  editNote(){
    this.isEditNote = true
  }

  saveNote(){
    this.isEditNote = false
    let postId
    if (this.isCasualDocument) {
      postId = this.singleDocument.id
      const requestData = {
        'text': [this.journalNote],
        'pathId': null,
        'contentSurveyId': null,
        'habitId': null,
        'pollId': null,
        'documentId': postId,
        'editedDocumentUrl':this.singleDocument?.editedDocumentUrl,
        'questionId': null,
        'inoutScreenId': null,
        'routineInfoId': null,
        'screenId': null
      }
      this.libraryService.save(requestData).subscribe((response) => {
        console.log('response :', response);
        this.getCasualDocuments(this.currentClient.id, this.user.id)
        this.toasterService.success(this.i18n.t("Note saved."))
      },
        (error) => {
          console.log('error :', error);
        })
    } else {
      console.log("JournalPost", this.journalPost)
      if(this.journalPost){
        postId = this.journalPost?.id

      }
      else {
        postId = this.singleDocument?.id
      }
      const requestData = {
        id: postId,
        text: this.journalNote
      }
      this.libraryService.editNote(requestData).subscribe(
        (response) => {
          console.log('response :', response);
          this.toasterService.success(this.i18n.t("Note saved."))
        },
        (error) => {
          console.log('Error for feedback ', error)
        }
      )
    }
    
  }

  viewGoogleDocument(url) {   
      if (this.cordovaService.onCordova) {
        this.cordovaService.openLinkInBrowser(url);
      } else {
        window.open(url, "_blank");
      }
  }
  async viewDocument (url) {
     if(url.match('.pdf')) {
      const transfer = this.transfer.create();
      let path = null;
      const name = url.substr(url.lastIndexOf("/") + 1);
      const fileName = name.split("?")[0];

      if (this.platform.is("ios")) {
        path = this.file.documentsDirectory;
      } else {
        path = this.file.dataDirectory;
      }

      transfer
        .download(url, path + fileName)
        .then((entry) => {
          this.fileToBase64(fileName, path.replace("/" + fileName, ""));
        })
        .catch((e) => {
          console.log("failed error", e);
        });
    }
   
  }

  async  fileToBase64(filename, filepath){
    let base64String =  await this.file.readAsDataURL(filepath, filename);
    this.pdfSrc=base64String.slice(28);
    this.Base64toArrayBuffer() 
  }

  Base64toArrayBuffer(){
    var binary_string = window.atob(this.pdfSrc);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    this.pdflink= bytes.buffer;
  }

  onScroll(event: any) {
    this.height =event.target.scrollTop;
    const toolbar =document.getElementsByClassName('m-close-arrow')[0];
    const toolbar1 =document.getElementsByClassName('button-wrap')[0];
    const toolbar3 = document.getElementsByClassName('toolbar')[0];
    const toolbar4 = document.getElementById('editorFreeTextParamsToolbar');
    const toolbar5 = document.getElementById('editorInkParamsToolbar');
    if(this.height<="44") {
      toolbar.setAttribute("style","background-color: transparent;")
      toolbar1.setAttribute("style","background-color: transparent; padding-top: 0; padding-bottom: 0; top:50");
      this.statusBar.styleLightContent();

    }
    if(this.height>="45") {
        toolbar.setAttribute("style","background-color: rgba(234,235,235,0.9); width:100%");
        this.statusBar.overlaysWebView(true);
      this.statusBar.styleDefault();
    }
    if(this.height<"410") {
      toolbar1.setAttribute("style","background-color: transparent; padding-top: 0; padding-bottom: 0; top:50");

    }
    if(this.height>="410") {
      
      toolbar.setAttribute("style","background-color: transparent ;width:30px;padding-top:72px")
      toolbar1.setAttribute("style","background-color: rgba(234,235,235,0.9); padding-top: 70px; padding-bottom: 18px; top:0; position: sticky; z-index: 99");
    }
    if(this.height<"450") {
      toolbar3?.setAttribute("style","background-color: transparent; padding-bottom: 0px; ");
      toolbar4?.setAttribute("style", "top: 35px;position: absolute;z-index: 10000;height: auto;min-width: 16px;padding: 0 6px 0 6px;margin: 4px 2px 4px 2px;font-size: 12px;line-height: 14px;text-align: left;cursor: default;")
      toolbar5?.setAttribute("style", "top: 35px;position: absolute;z-index: 10000;height: auto;min-width: 16px;padding: 0 6px 0 6px;margin: 4px 2px 4px 2px;font-size: 12px;line-height: 14px;text-align: left;cursor: default;")

    }
    if(this.height>="450") {
       
        toolbar3?.setAttribute("style","background-color: rgba(234,235,235,0.9)!important; opacity:0.9;padding-right:20px;  position:sticky; top:124px;z-index:999;")
        toolbar4?.setAttribute("style", "top: 150px;position: sticky;z-index: 10000;height: auto;min-width: 16px;padding: 0 6px 0 6px;margin: 4px 52px 4px 2px;font-size: 12px;line-height: 14px;text-align: left;cursor: default;")
        toolbar5?.setAttribute("style", "top: 150px;position: sticky;z-index: 10000;height: auto;min-width: 16px;padding: 0 6px 0 6px;margin: 4px 22px 4px 2px;font-size: 12px;line-height: 14px;text-align: left;cursor: default;")

      
    }
  }
  changeSubTextFont() {
      const element = this.contentContainer?.el;
      const specificElement = element?.querySelector('p') || element?.querySelector('h1') || element?.querySelector('h2');
      if (specificElement) {
        this.render.setStyle(specificElement, 'font-size', '15px');
        this.render.setStyle(specificElement, 'font-weight', '700');
        this.render.setStyle(specificElement, 'word-break', 'break-word');
        this.render.setStyle(specificElement, 'margin-bottom', '0px');
      }
    return true;
  }
}
