import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'first90-badge-svg',
  templateUrl: './badge-svg.component.html',
  styleUrls: ['./badge-svg.component.scss']
})
export class BadgeSvgComponent implements OnInit {
  @Input() badgeId: string
  @Input() greyedOut = false
  @Input() colour: string

  constructor() {

   }

  ngOnInit() {
    console.log("greyedOut Id ",this.greyedOut);
    
  }

}
