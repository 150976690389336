import gql from 'graphql-tag'

export const customBadgeFields = gql`
fragment customBadgeFields on CustomBadge {
    id
    badgeTitle
    colour
    imageShape
    position
    actionPoints
    awardFor
    createdAt
    updatedAt
    enabled
}
`

export const updateCustomBadge = gql`
mutation updateCustomBadge($customBadge: CustomBadgeInput!) {
  updateCustomBadge(customBadgeInput: $customBadge) {
    ...customBadgeFields
  }
}
${customBadgeFields}
`

export const findCustomBadge = gql`
query findCustomBadge($id: Int!) {
  customBadge(id: $id) {
    ...customBadgeFields
  }
}
${customBadgeFields}
`

export const listCustomBadges = gql`
query listCustomBadges($filter: FilterInput) {
  customBadges(filter: $filter) {
    ...customBadgeFields
  }
}
${customBadgeFields}
`

export const listUserCustomBadges = gql`
query listUserCustomBadges($filter: FilterInput) {
  userCustomBadges(filter: $filter) {
    id
    pathId
    userId
    customBadgeId
    customBadge {
      ...customBadgeFields
    }
    enabled
    createdAt
  }
}
${customBadgeFields}
`
