import { Base } from '../shared/base'

interface PathData {
  loginReminderEnabled: boolean
  loginReminderTime: string
  deviceRegistrationId: string
}

export class PathRegistration extends Base {
  init() {
    this._fields = ['userId', 'pathId', 'pathData', 'screensViewed', 'stepsCompleted', 'loggedInDates', 'scheduleDay', 'selectedDayPart']

    //this._defaultInput = { exclude: ['actionPointsTotal'] }
  }

  public userId: number
  public pathId: number
  public pathData: PathData
  public screensViewed: any[]
  public stepsCompleted: string
  public loggedInDates: string[]
  public scheduleDay: number
  public selectedDayPart: string
}
