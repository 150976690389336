import { trigger, state, style, transition, animate, useAnimation } from '@angular/animations';
import { Component, DoCheck, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertController, AnimationController, ModalController, Platform  } from '@ionic/angular';
import { collapseOnLeaveAnimation, expandOnEnterAnimation, fadeOutCollapseOnLeaveAnimation, slideInDownOnEnterAnimation, slideInUpAnimation, slideInUpOnEnterAnimation, slideOutDownOnLeaveAnimation, slideOutUpAnimation, slideOutUpOnLeaveAnimation } from 'angular-animations';
import { AlertSupportComponent } from 'src/app/alert-support/alert-support.component';
import { AppState } from 'src/app/app.service';
import { I18n } from 'src/app/i18n.service';
import { Path } from 'src/app/path/path.model';
import mixpanel from 'mixpanel-browser';
import { UserComponent } from 'src/app/user/user.component';
import { YourProgressComponent } from '../your-progress/your-progress.component';
import { YourPathComponent } from '../your-path/your-path.component';
import { MobileChatBoardComponent } from 'src/app/pages/mobile-chat-board/mobile-chat-board.component';
import { MyLibraryComponent } from 'src/app/my-library/my-library.component';
import { MyCommitmentsComponent } from 'src/app/my-commitments/my-commitments.component';
import { StatListComponent } from 'src/app/stat/stat-list.component';
import { UpdatedHomeComponent } from '../updated-home/updated-home.component';
import { AllPathsComponent } from 'src/app/all-paths/all-paths.component';
import { HomeUiService } from '../home-ui.service';
import { AnimationsService } from 'src/app/shared/animations.service';
import { Router } from '@angular/router';
import { FooziAiConciergeComponent } from '../foozi-ai-concierge/foozi-ai-concierge.component';
import { FooziAiConciergeService } from '../foozi-ai-concierge.service';

@Component({
  selector: 'app-hamburger-menu',
  templateUrl: './hamburger-menu.component.html',
  styleUrls: ['./hamburger-menu.component.scss'],
  animations: [slideInDownOnEnterAnimation({duration:500}),
    slideOutUpAnimation({duration:500}),
    slideInUpAnimation({duration:500}),
    slideOutDownOnLeaveAnimation({duration:500}),
    fadeOutCollapseOnLeaveAnimation({duration:500}),
    slideOutUpOnLeaveAnimation({duration:500}),
    collapseOnLeaveAnimation({duration:500}),
    slideOutUpAnimation({duration:500}),
    slideInUpOnEnterAnimation({duration:500}),
  ]
})

export class HamburgerMenuComponent implements OnInit,OnDestroy,DoCheck {
  @Output() closeModal= new EventEmitter<any>();
  @Input() isModalOpen;
  slideIn =false;
  isArrowVisible=false;
  currentPath: Path;
  menuSegment = [];
  rightstate ='in';
  isMobileWeb=false
  isMobileWebIos = false
  profile= UserComponent;
  yourProgress= YourProgressComponent;
  yourPath= YourPathComponent;
  commitment= MyCommitmentsComponent;
  community= MobileChatBoardComponent;
  library= MyLibraryComponent;
  leaderboard= StatListComponent;
  home= UpdatedHomeComponent;
  allPathsComponent = AllPathsComponent;
  allPathsCount;
  isMobile;
  webMenuSegment;
  enableFoozi;
  client;
  activeIndex: number | null = null;
  currentYear;
  constructor(private modalController: ModalController, private appState: AppState, private animationCtrl: AnimationController, private dialog: MatDialog,
    private i18n: I18n, private homeUIService: HomeUiService, private animationService: AnimationsService, private alertController: AlertController,
    public platform: Platform, private router: Router, private fooziService: FooziAiConciergeService) { 
      this.client = this.appState.get('currentClient')
      this.menuSegment = [
      
      { id: 0, title: this.i18n.t('Home'), url: '', icon: '../../../assets/home_ui/home.svg',className:'custom-modal' },
      { id: 1, title: this.i18n.t('Your progress'), url: this.yourProgress, icon: '../../../assets/home_ui/stats.svg',className:'custom-modal' },
      { id: 2, title: this.i18n.t('Your path'), url: this.yourPath, icon: '../../../assets/home_ui/your-path.svg', className:'custom-modal' },
      { id: 3, title: this.i18n.t('To-do list'), url: this.commitment, icon:'../../../assets/home_ui/todo-list.svg', className:'custom-modal' },
      { id: 4, title: this.i18n.t('Library'), url: this.library, icon: '../../../assets/home_ui/library.svg' ,className:'custom-modal'},
      { id: 5, title: this.i18n.t('Community'), url: this.community, icon: '../../../assets/home_ui/community.svg' , className:'custom-modal'},
      { id: 6, title: this.i18n.t('Leaderboard'), url: this.leaderboard, icon: '../../../assets/home_ui/leaderboard.svg', className:'custom-modal' }
    ];
    this.webMenuSegment = [     
      { id: 0, title: this.i18n.t('Home'), url: '', className:'custom-modal' },
      { id: 1, title: this.i18n.t('To-do list'), url: this.commitment, className:'desktop-todo' },
      { id: 2, title: this.i18n.t('Library'), url: this.library, className:'desktop-library-custom' },
      { id: 3, title: this.i18n.t('Community'), url: this.community, className: 'desktop-custom-modal' },
      { id: 4, title: this.i18n.t('Leaderboard'), url: this.leaderboard, className: 'desktop-cust-leaderboard' }
    ];
    this.isMobile = this.appState.get("isMobile");
  }
  enterAnimation= this.animationService.slideInDownEnterAnimation;
  leaveAnimation = this.animationService.slideOutUpLeaveAnimation;
  ngDoCheck(): void {
    this.allPathsCount =  this.appState.get('allPathCount')
    this.isModalOpen =this.appState.get('isHamburgerOpen');
    if(this.isModalOpen==false){
      this.closeHamburger();
    }

  }
  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    console.log("this.isMobileWeb value", this.isMobileWeb);
    this.setActiveIndex(0);
    this.currentYear = (new Date()).getFullYear()
    this.currentPath = this.appState.get('currentPath')
    this.allPathsCount =  this.appState.get('allPathCount')
    if(!this.allPathsCount){
         this.homeUIService.allpaths(this.client.id)
    }
    this.hideMenuButtons();
    setTimeout(() => {
          console.log("isModal open", this.isModalOpen);

    },100)
    }
   
    setActiveIndex(index: number) {
      this.activeIndex = index;
    } 
    async openComponent(comp){
      if(comp==AllPathsComponent) {
        this.appState.set("isAllPaths", true);
      }
      mixpanel.track('Hamburger-Menu', {'Hamburger Menu': comp.name == 'YourPathComponent' ? 'Your Path' : comp.name == 'YourProgressComponent' ? 'Your Progress' : comp.name == 'MyCommitmentsComponent' ? 'Commitment' : comp.name == 'MobileChatBoardComponent' ? 'Community' : comp.name == 'MyLibraryComponent' ? 'Library' : comp.name == 'StatListComponent' ? 'Leaderboard' : comp.name == 'UserComponent' ? "Profile" : comp.name == 'UpdatedHomeComponent' ? "Home" : comp.name == 'AllPathsComponent' ? 'All Path' : '' })
      this.appState.set('isHamburgerOpen',true);
      if(comp !=='') {
        this.closeHamburger();
      const modal = await this.modalController.create({
      component: comp,
      // animated: false,
      cssClass: 'custom-modal',
      enterAnimation: comp==AllPathsComponent?this.animationService.slideoutRightEnterAnimation:this.animationService.slideInLeftEnterAnimation,
      leaveAnimation: comp==AllPathsComponent?this.animationService.SlideInLeftLeaveAnimation:this.animationService.SlideOutRightLeaveAnimation
    });
    this.homeUIService.setModalStack(modal);
     await modal.present()   
    }
    else {
        this.closeHamburger(comp);
        let compon;
        this.fooziService.FooziCompStatus.subscribe((res) => {
          compon =res
        })
        console.log("modal", compon, FooziAiConciergeComponent);
        setTimeout(async () => {
          if (comp == FooziAiConciergeComponent) {
            let modal = this.homeUIService.getModalStack().pop()
            await modal.dismiss();
          }
        }, 550);

    }
    }

  async openWebComponent(comp, index, className) {
    if (comp == MyCommitmentsComponent) {
      this.appState.set('to-do-comp', true);
    }
      this.setActiveIndex(index);
      const modal = await this.modalController.create({
        component: comp,
        // animated: false,
        cssClass: className,
        enterAnimation: this.animationService.slideInLeftEnterAnimation,
        leaveAnimation: this.animationService.SlideOutRightLeaveAnimation
      });
    if (comp.name == 'MyCommitmentsComponent') {
       this.homeUIService.setModalStack(modal);
    }  
       await modal.present()
       modal.onDidDismiss().then(()=>{
        this.setActiveIndex(0);
       })
    }
  async goToQuestion() {
    this.closeHamburger();
    var supportDialog = this.dialog.open(AlertSupportComponent);
    supportDialog.afterClosed().subscribe()
  }  
    hideMenuButtons() {
     if(this.isMobile) {
      if(!this.currentPath?.enabledAttributes?.leaderboard) {
        this.menuSegment?.forEach((item,index)=>{
          if(item?.title== this.i18n.t("Leaderboard")){
            this.menuSegment.splice(index,1);
          }
        })
      }
      if(!(this.currentPath?.enabledAttributes?.community) && !(this.currentPath?.enabledAttributes?.community ==undefined) ) {
        this.menuSegment?.forEach((item,index)=>{
          if(item?.title== this.i18n.t("Community")){
            this.menuSegment.splice(index,1);
          }
        })
      }
     } 
     else {
      if(!this.currentPath?.enabledAttributes?.leaderboard) {
        this.webMenuSegment?.forEach((item,index)=>{
          if(item?.title== this.i18n.t("Leaderboard")){
            this.webMenuSegment.splice(index,1);
          }
        })
      }
      if(!(this.currentPath?.enabledAttributes?.community) && !(this.currentPath?.enabledAttributes?.community ==undefined) ) {
        this.webMenuSegment?.forEach((item,index)=>{
          if(item?.title== this.i18n.t("Community")){
            this.webMenuSegment.splice(index,1);
          }
        })
      }
     }
    
    }
    menuClick(data) {
      mixpanel.track( `Menu clicked-${data}` , {value: data})
    }
  closeHamburger(comp=null){
    this.closeModal.emit({url:comp})
  }
   closeHamModal() {
   
    this.modalController.dismiss();
  }
  canDismiss = async () => {
    if(this.isModalOpen){
      this.appState.set('isHamburgerOpen',false);
      this.isModalOpen =this.appState.get('isHamburgerOpen');
      this.closeHamburger();
      return true;
    }
    else {
      return false;
    }
  };
  isFooziOpened(val) {
    this.enableFoozi = val;
  }
  isExpand(val) {
    this.fooziService.setExpandHamburger(val);
    this.isArrowVisible=val;
    console.log("isArrowVisible", this.isArrowVisible)
  }
   ngOnDestroy() {
    this.closeHamburger();
  }
}
