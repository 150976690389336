import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-delete-post',
  templateUrl: './alert-delete-post.component.html',
  styleUrls: ['./alert-delete-post.component.scss'],
})
export class AlertDeletePostComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
