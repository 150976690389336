import {EventEmitter, Inject, Injectable} from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { Theme } from './theme.model'

import cssVars from 'css-vars-ponyfill'
import { hexToRgb, shadeColor } from '../utils.service'
import { environment } from '../../environments/environment'
import { PathGroupService } from '../path-group/path-group.service'
import { ClientService } from '../client/client.service'
import { Platform } from '@ionic/angular'
import { DomSanitizer} from '@angular/platform-browser'
import { File } from '@awesome-cordova-plugins/file/ngx'
import { FileTransfer, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx'

export interface ThemeData {
  colours?: any,
  bodyFontUrl?: { name: string, url: string },
  headerFontUrl?: { name: string, url: string },
  clientBackgroundImageUrl?: string,
  clientLogoImageUrl?: string
}

@Injectable()
export class ThemeService {
  addedFonts = {}
  rootStyle: any
  defaultPathGroup = environment.defaultPathGroupName
  public themeData: ThemeData
  public themeCreated: EventEmitter<null> = new EventEmitter<null>()

  constructor(
    @Inject(DOCUMENT) private _document: any,
    private pathGroupService: PathGroupService,
    private sanitizer: DomSanitizer,
    private clientService: ClientService,
    private platform: Platform,
    private file: File,
    private fileTransfer: FileTransfer
  ) {
    this.rootStyle = this._document.documentElement.style
  }

  private restoreTheme() {
    const storedTheme = localStorage.getItem('pathTheme')
    const res = {}
    if (!storedTheme) {
      return false
    }
    try {
      const parsed = JSON.parse(storedTheme)
      for (const i of Object.keys(parsed)) {
        if (parsed[i]) {
          res[i] = parsed[i]
        }
      }
    } catch (e) {
      console.error('error on parsing stored theme', e)
    }
    return res
  }

  public setTheme(theme: Theme) {
    const variables = {}
    for (const name in theme.cssVariables) {
      variables[name] = theme.cssVariables[name]
      this.rootStyle.setProperty(name, theme.cssVariables[name])
    }
    // LUMBA-1325
    cssVars({
      variables: variables,
      watch: true
    })

    if (theme.fonts) {
      theme.fonts.forEach(async font => {
        // if (this.addedFonts[font.name]) {
        //   return
        // }
    
        if(this.platform.is('android')) {
           this.downloadAndApplyFont(font.url, font.name)
          
        }
        else {
          this.appendStyle(`@font-face { font-family: '${font.name}'; src: url('${font.url}') }`)
        }
      })
    }
  }
  
async downloadAndApplyFont(url, fontFamily: string) {
    try {
      const name = url.substr(url.lastIndexOf("/") + 1);
      const fileName = name.split("?")[0];
      let filePath;
     
      filePath = this.file.dataDirectory ;
     url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
     url =url.changingThisBreaksApplicationSecurity;
      const fileTransfer: FileTransferObject = this.fileTransfer.create();
      await fileTransfer.download(url, filePath+ fileName).then((res)=>{
        
      });
       
      this.appendStyle(`@font-face { font-family: '${fontFamily}'; src: url('${url}') }`)
    } catch (error) {
      console.error('Error downloading or applying font:', error);
    }
  }
  
  // LUMBA-1396
  public stringifyTheme(colours, client) {
    const theme = this._composeTheme(colours, client)
    let res
    try {
      res = JSON.stringify(theme)
    } catch (e) {
      console.error('error on stringify theme', e)
    }

    return res
  }

  public _composeTheme(colours, client) {
    const theme = {
      colours: null,
      bodyFontUrl: null,
      headerFontUrl: null,
      clientBackgroundImageUrl: null,
      clientLogoImageUrl: null
    }
    if (colours) {
      theme.colours = colours
    }

    if (client) {
      if (client.bodyFontUrl) {
        const bodyFontName = `client${client.id}BodyFont`
        theme.bodyFontUrl = { name: bodyFontName, url: client.bodyFontUrl }
      }
      if (client.headerFontUrl) {
        const headerFontName = `client${client.id}HeaderFont`
        theme.headerFontUrl = { name: headerFontName, url: client.headerFontUrl }
      }
      if (client.backgroundImageUrl) {
        theme.clientBackgroundImageUrl = client.backgroundImageUrl
      } else {
        theme.clientBackgroundImageUrl = 'assets/images/default-background.jpg'
      }
      if (client.imageUrl) {
        theme.clientLogoImageUrl = client.imageUrl
      }
    }
    return theme
  }

  // DEPRECATED
  public createTheme() {
    if (this.themeData) {
      this.themeCreated.emit()
      return
    }
    const restored = this.restoreTheme()
    if (restored) {
      this.themeData = restored
      this._createTheme()
      this.themeCreated.emit()
      return
    }
  }

  // LUMBA-1428
  public apply(pathGroup, client, subDomain?) {
    this.themeData = this._composeTheme(pathGroup.colours, client)
    localStorage.setItem('pathTheme', this.stringifyTheme(pathGroup.colours, client))
    if (subDomain) {
      localStorage.setItem('subDomain', subDomain)
    }
    this._createTheme()
  }

  // LUMBA-1408
  public loadTheme() {
    const subDomain = this.getSubDomain()
    return new Promise((resolve, reject) => {
      function loadFromSubdomain(pathGroup) {
        this.clientService.getClientBySubDomain(subDomain).subscribe(client => {
          this.apply(pathGroup, client, subDomain)
          resolve(true)
        }, error => {
          console.error(error)
          resolve(true)
        })
      }

      const restored = this.restoreTheme()
      if (restored) {
        if (subDomain && localStorage.getItem('subDomain') !== subDomain) {
          loadFromSubdomain.call(this, restored)
          return
        }
        this.themeData = restored
        this._createTheme()
        resolve(true)
        return
      }
      this.pathGroupService.getPathGroupByName(this.getPathGroupUrl()).subscribe(pathGroup => {
        if (subDomain) {
          loadFromSubdomain.call(this, pathGroup)
        } else {
          this.apply(pathGroup, pathGroup.client)
          resolve(true)
        }
      }, error => {
        console.error(error)
        resolve(true)
      })
    })
  }

  private _createTheme() {
    const variables = {}
    try {
      this.themeData.colours['highlight-faded'] = hexToRgb(this.themeData.colours['highlight'], .5)
      this.themeData.colours['stat1-shaded'] = shadeColor(this.themeData.colours['stat1'], -.2)
      this.themeData.colours['stat2-shaded'] = shadeColor(this.themeData.colours['stat2'], -.2)
      this.themeData.colours['stat3-shaded'] = shadeColor(this.themeData.colours['stat3'], -.2)
      this.themeData.colours['stat4-shaded'] = shadeColor(this.themeData.colours['stat4'], -.2)
      this.themeData.colours['stat5-shaded'] = shadeColor(this.themeData.colours['stat5'], -.2)
    } catch (e) {}
    if (this.themeData.colours) {
      for (const variableName of Object.keys(this.themeData.colours)) {
        variables[`--${variableName}-color`] = this.themeData.colours[variableName]
      }
    }

    const fonts = []
    if (this.themeData.bodyFontUrl) {
      fonts.push(this.themeData.bodyFontUrl)
      variables['--body-font'] = this.themeData.bodyFontUrl.name
    }
    if (this.themeData.headerFontUrl) {
      fonts.push(this.themeData.headerFontUrl)
      variables['--header-font'] = this.themeData.headerFontUrl.name
    }

    if (this.themeData.clientBackgroundImageUrl) {
      variables['--client-background'] = `url(${this.themeData.clientBackgroundImageUrl})`
    } else { // LUMBA-1389
      variables['--client-background'] = 'url(assets/images/default-background.jpg)'
    }
    this.setTheme(new Theme(variables, fonts))
  }

  // LUMBA-1408
  public getSubDomain(): string {
    const dots = window.location.hostname.match(/\./g)
    if (!dots || dots.length <= 1) {
      return null
    }
    const match = window.location.hostname.match(/^.*?\./)
    return match ? match[0].slice(0, -1) : null
  }

  public getPathGroupUrl(): string {
    const parts = window.location.pathname.split('/')
    if (parts.length > 2) {
      const pathGroup = parts.pop()
      const action = parts.pop()
      if ((action === 'login' || action === 'signin') && pathGroup !== '') {
        return decodeURIComponent(pathGroup)
      }
    }
    return this.defaultPathGroup
  }

  appendStyle(cssString) {
    const head = this._document.head
    const style = this._document.createElement('style')
    style.type = 'text/css'
    style.appendChild(this._document.createTextNode(cssString))
    head.appendChild(style)
  }
}

// LUMBA-1408
export function ThemeServiceFactory(provider: ThemeService) {
  return () => provider.loadTheme()
}
