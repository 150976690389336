import { Component, Input, OnInit, HostListener } from '@angular/core';
import * as moment from 'moment'

import { AppState } from '../app.service'
import { Filter } from '../shared/filter'
import { Path } from '../path/path.model'

import { Document, DocumentSection } from './document.model'
import { DocumentService } from './document.service'
import { CordovaService } from '../cordova.service';
import { ActivatedRoute, Router } from '@angular/router';

const DOCUMENT_HIGHLITHED: number = 0
const DOCUMENT_PRACTICE:   number = 1
const DOCUMENT_RESORCE:    number = 2

@Component({
  selector: 'first90-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit {

  @Input() inHome: boolean = false
  @Input() enabled: boolean = true

  scheduleDay: number
  documents: Document[] = []
  highlithedDocuments: Document[] = []
  practiceDocuments:   Document[] = []
  resourceDocuments:   Document[] = []
  moreDocuments:       Document[] = []

  path: Path
  backTo: string
  isMobile: boolean;

  constructor(
    private documentService: DocumentService,
    private appState: AppState,
    private cordovaService:CordovaService,
    public route: ActivatedRoute,
    private router: Router,
  ) {
    this.path = this.appState.get('currentPath')
    this.scheduleDay = this.appState.get('currentScheduleDay')
    this.isMobile = this.appState.get('isMobile')

    this.documents = this.path.documents
    this.backTo = this.route.snapshot.queryParamMap.get('backTo')
  }

  ngOnInit() {
    const filter = new Filter()
    filter.q = { path_id_eq: this.path.id }
    this.highlithedDocuments = this.documents.filter(document => document.section === DocumentSection.Highlighted)
    this.practiceDocuments   = this.documents.filter(document => document.section === DocumentSection.Practice)
    this.resourceDocuments   = this.documents.filter(document => document.section === DocumentSection.Resource)
    this.moreDocuments       = this.documents.filter(document => document.section === DocumentSection.More)
  }
  
  @HostListener('click', ['$event'])
  onLinkClick(e) {
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        console.log('cordova click')
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        window.open(e.target.href, '_blank')
      }
    }
  }

  close() {
    if (this.backTo) {
      this.router.navigate([this.backTo])
    } else {
      this.router.navigate(['/main'])
    }
  }
}
