import { NgModule } from '@angular/core';
import { PreloadAllModules, Route, RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { FaqComponent } from './faq/faq.component';
import { OnboardingSurveyComponent } from './onboarding-survey/onboarding-survey.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { UserComponent } from './user/user.component';
import { JournalComponent } from './pages/journal/journal.component';
import { MainComponent } from './main/main.component';
import { HabitListModalComponent } from './habit-list-modal/habit-list-modal.component';
import { DocumentsPageComponent } from './pages/document/documents-page.component';
import { StatsPageComponent } from './pages/stat/stats-page.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { MyCommitmentsComponent } from './my-commitments/my-commitments.component';
import { SubRoutineInfoComponent } from './my-commitments/sub-routine-info/sub-routine-info.component';
import { SingleHabitInfoComponent } from './my-commitments/single-habit-info.component';
import { EditRoutineComponent } from './edit-routine/edit-routine.component';
import { AchievementsPageComponent } from './pages/achievement/achievements-page.component';
import { MobileChatBoardComponent } from './pages/mobile-chat-board/mobile-chat-board.component';
import { FullScreenRoutineComponent } from './full-screen-routine/full-screen-routine.component';
import { FullScreenFeedComponent } from './full-screen-feed/full-screen-feed.component';
import { FullScreenPostComponent } from './full-screen-post/full-screen-post.component';
import { DailyContentV2Component } from './daily-content-v2/daily-content-v2.component';
import { MobileWeldonescreenComponent } from './mobile-weldonescreen/mobile-weldonescreen.component';
import { WeldonescreenComponent } from './weldonescreen/weldonescreen.component';
import { WrongLoginLinkComponent } from './pages/wrong-login-link/wrong-login-link.component';
import { EndRoutineComponent } from './end-routine/end-routine.component';
import { PathSelectComponent } from './path-select/path-select.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { TestBoardComponent } from './pages/test-board/test-board.component';
import { CloseoutSurveyWelcomeComponent } from './closeout-survey/closeout-survey-welcome.component';
import { CloseoutSurveyComponent } from './closeout-survey/closeout-survey.component';
import { NotificationsSettingsComponent } from './pages/notifications-settings/notifications-settings.component';
import { RoutineCalendarComponent } from './routine-v2/routine-calendar/routine-calendar/routine-calendar.component';
import { AlertProfilePictureComponent } from './alert-profile-picture/alert-profile-picture.component';
import { AllPathsComponent } from './all-paths/all-paths.component';
import { HomeV6Component } from './pages/home-v6/home-v6.component';
import { StatListComponent } from './stat/stat-list.component';
import { DayPartSelectComponent } from './day-part-select/day-part-select.component';
import { WebFaqComponent } from './web-faq/web-faq.component';
import { AppErrorsComponent } from './app-errors/app-errors.component';
import { MyLibraryComponent } from './my-library/my-library.component';
import { FullScreenDocumentComponent } from './my-library/full-screen-document.component';
import { FullScreenStepComponent } from './my-library/full-screen-step.component';
import { FullScreenVideoComponent } from './full-screen-video/full-screen-video.component';
import { FullScreenAudioComponent } from './full-screen-audio/full-screen-audio.component';
import { VideoDetailsComponent } from './video-details/video-details.component';
import { DeletionPageComponent } from './deletion-page/deletion-page.component';
import { AlertUnlockLevelComponent } from './alert-unlock-level/alert-unlock-level.component';
import { FullScreenReflectionComponent } from './my-library/full-screen-reflection/full-screen-reflection.component';
import { YourProgressComponent } from './new-design/your-progress/your-progress.component';
import { UpdatedHomeComponent } from './new-design/updated-home/updated-home.component';
import { YourPathComponent } from './new-design/your-path/your-path.component';
import { HamburgerMenuComponent } from './new-design/hamburger-menu/hamburger-menu.component';
import { HomeSheetModalComponent } from './new-design/home-sheet-modal/home-sheet-modal.component';
import { DailyStepsContentModalComponent } from './new-design/daily-steps-content-modal/daily-steps-content-modal.component';
import { TextScreenComponent } from './text-screen/text-screen.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full', canActivate: [AuthGuardService]
  } as Route,
  {path: 'video-library', component:FullScreenVideoComponent},
  {path: 'audio-library', component:FullScreenAudioComponent},
  {path: 'video', component: VideoDetailsComponent},
  {path: '', component: SignInComponent},
  {path: 'splash', component: SignInComponent},
  {path: 'login/:pathGroupName', component: SignInComponent},
  {path: 'login/:code/:state', component: SignInComponent},
  {path: 'login', component: SignInComponent},
  {path: 'wrong-login', component: WrongLoginLinkComponent},
  {path: 'signup', component: SignUpComponent},
  {path: 'signup/:pathGroupName/:pathId', component: SignUpComponent},
  {path: 'faq', component: FaqComponent},
  {path: 'faqs', component: WebFaqComponent},
  {path: 'all-paths', component: AllPathsComponent},
  {path: 'onboarding', component: OnboardingSurveyComponent, canActivate: [AuthGuardService]},
  {path: 'open-question',component: FullScreenFeedComponent},
  {path:'open-post-feed',component:FullScreenPostComponent},
  {path: 'sublevel',component: SubRoutineInfoComponent},
  {path: 'edit-routine',component: EditRoutineComponent},
  {path:'end-routine',component: EndRoutineComponent},
  {path: 'single-habit-info',component: SingleHabitInfoComponent},
  {path: 'content/:day', component: DailyContentV2Component, canActivate: [AuthGuardService]},
  {path: 'weldone', component:WeldonescreenComponent},
  {path: 'mobileweldone', component:MobileWeldonescreenComponent},
  {path: 'path-select', component: PathSelectComponent},
  {path: 'verifyemail', component: VerifyEmailComponent},
  {path: 'test-board', component: TestBoardComponent},
  {path: "uploader", component: AlertProfilePictureComponent},
  {path: 'day-select', component: DayPartSelectComponent},
  {path: 'closeout-welcome', component: CloseoutSurveyWelcomeComponent, canActivate: [AuthGuardService]},
  {path: 'detail-document', component:FullScreenDocumentComponent},
  {path: 'detail-step', component:FullScreenStepComponent},
  {path: 'closeout', component: CloseoutSurveyComponent, canActivate: [AuthGuardService]},
  {path: 'delete-account', component: DeletionPageComponent},
  {path: 'alert-unlock-component', component: AlertUnlockLevelComponent},
  {path: 'detail-reflection', component:FullScreenReflectionComponent},
  {path: 'your-progress', component: YourProgressComponent},
  {path: 'your-path', component: YourPathComponent},
  {path:'daily-content', component: DailyStepsContentModalComponent},
  {
    path: 'home-main',
    component: MainComponent,
    canActivateChild: [AuthGuardService],
    children: [
    {path: '', component: UpdatedHomeComponent},]
  },
  {
    path: 'main',
    // component: MainComponent,
    canActivateChild: [AuthGuardService],
    children: [
    {path: '', component: UpdatedHomeComponent},
    {path: 'commitments',component: MyCommitmentsComponent},
    {path: 'documents', component: DocumentsPageComponent},
    {path: 'questions', component: MobileChatBoardComponent},
    {path: 'stats', component: StatListComponent},
    {path: 'profile', component: UserComponent},
    {path: 'achievements', component: AchievementsPageComponent},
    {path:'routine-details',component:FullScreenRoutineComponent},
    {path: 'journal', component: JournalComponent},
    {path: 'settings', component: SettingsComponent},
    {path: 'habit',component: HabitListModalComponent},
    {path: 'notifications-settings', component: NotificationsSettingsComponent},
    {path:'routine-calendar',component:RoutineCalendarComponent},
    {path: 'library', component: MyLibraryComponent},
    { path: '', loadComponent: () => import('./text-screen/text-screen.component').then(m => m.TextScreenComponent) },
    ]
  },
  {path: 'not-found', component: WrongLoginLinkComponent },
  {path: 'errors', component: AppErrorsComponent },
  {path: '**', redirectTo: '/main'},
];

declare const cordova: any
const onCordova = (typeof cordova !== 'undefined' ? true : false)

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
