import gql from 'graphql-tag';
import { replyFields } from '../reply/reply.query';

export const habitFields = gql`
fragment habitFields on Habit {
    id
    text
    preScreens
    postScreens
    actionPoints
    startDay
    endDay
    screenOrder
    isMandatory
    enabled
    updatedAt
    createdAt
    isroutine
    title
    routinelen
    eventlen
    isConvertedToRoutine
    time
    colleaguesCount
    votesCounts
    isScheduled
    isCompleteNow
    casualDocumentId
    documents {
      id
      title
      link
      notes
      editedDocumentUrl
      imageUrl
      isRecommended
      startDay
    }
    prompt
}
`


export const toggleHabit = gql`
mutation toggleHabit($habitId: Int!) {
  toggleHabit(habitId: $habitId) {
    status
  }
}
`

export const scheduleHabit = gql`
mutation scheduleHabit($habitId: Int!) {
  scheduleHabit(habitId: $habitId) {
    status
  }
}
`

export const confirmHabit = gql`
mutation confirmHabit($habitId: Int!) {
  confirmHabit(habitId: $habitId) {
    status
    actionPoints
  }
}
`

export const updateHabit = gql`
mutation updateHabit($habit: HabitInput!) {
  updateHabit(habitInput: $habit) {
    ...habitFields
  }
}
${habitFields}
`

export const findHabit = gql`
query findHabit($id: Int!) {
  habit(id: $id) {
    ...habitFields
  }
}
${habitFields}
`

export const listHabits = gql`
query listHabits($filter: FilterInput) {
  habits(filter: $filter) {
    ...habitFields
    createdAt
  }
}
${habitFields}
`

// LUMBA-1419
export const sendHabitListPdf = gql`
mutation sendHabitListPdf($pathId: Int) {
  sendHabitListPdf(pathId: $pathId) {
    status
  }
}
`

export const listPathHabits = gql`
query listPathHabits($filter: FilterInput) {
  pathHabits(filter: $filter) {
    id
    starts
    expires
    enabled
    habit {
      ...habitFields
    }
    createdAt
  }
}
${habitFields}
`

export const replyQuestion = gql`
mutation replyQuestion($habitid: Int, $text: String!, $attachmentUrl: String, $mode: String) {
  replyQuestion(questionId: $habitid, text: $text, attachmentUrl: $attachmentUrl, mode: $mode) {
    status
    actionPoints
    reply {
      ...replyFields
    }
  }
}
${replyFields}
`
